import React from "react";

const navigation = [
  { name: 'Evaly', href: "/" },
  // { name: 'Features', href: '#' },
  // { name: 'Marketplace', href: '/store' },
  // { name: 'Pricing', href: '/pricing' },
  // { name: 'Company', href: '#' },
]

function LandingHeader () {
    return (


      <div class="flex flex-row justify-between items-center mr-4 py-5 bg-gray-950"  style={{paddingTop: "20px", paddingBottom:"20px"}}> 
              <div class="mx-3 px-4 flex flex-row items-center justify-between">
                    <img
                        style={{height: "2.5rem"}}
                        src={`../../assets/tigerLabLogo.png`}
                        alt=""
                    />
                      <h
                        class="block px-2 text-3xl whitespace-nowrap w-64 font-bold text-white "
                      >
                        {"TigerLab"}
                      </h>
              </div>
              <div class="flex">
                <a class="px-6 py-2 text-lg font-medium lg:rounded-md text-white hover:text-sky-600" href="https://9c3obgz72ib.typeform.com/to/MWPyRqso" target="_blank">Beta waitlist</a>
                <a class="px-6 py-2 text-lg font-medium lg:rounded-md text-white hover:text-sky-600" href="https://calendly.com/tigerlab-ai/30min" target="_blank">Talk to us</a>
                <a class="px-6 py-2 text-lg font-medium lg:rounded-md text-white hover:text-sky-600" href="/blog" >Blog</a>
                <a class="px-6 py-2 text-lg font-medium lg:rounded-md text-white hover:text-sky-600" href="https://github.com/tigerlab-ai/tiger" target="_blank">GitHub</a>
              </div>
          </div>
    )
}
export default LandingHeader