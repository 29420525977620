import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
// import ProgressCircle from "./ProgressCircle";

const ExperimentStatBox = ({ title, map, precision, recall, map_percent=null, precision_percent=null, recall_percent=null}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
        <Box
        gridColumn="span 3"
        backgroundColor={colors.primary[400]}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width="100%" m="0 30px">
          <Box>
            <Typography variant="h5"
                fontWeight="semibold" sx={{ color: colors.grey[100],  mb:1}}>
              {title}
            </Typography>
            <Box 
              display="flex"
              alignItems="center">
                <Typography
                    variant="h6"
                    fontWeight="semibold"
                    sx={{ color: colors.grey[100], mr: 1 }}
                >
                  {"MAP:"}  
                </Typography>
                <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{ color: colors.grey[100], mr: 2  }}
                >
                  {map}  
                </Typography>
                {map_percent && <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{ color: colors.greenAccent[300] }}
                >
                  +{map_percent}  
                </Typography>}
            </Box>
            <Box 
              display="flex"
              alignItems="center">
                <Typography
                    variant="h6"
                    fontWeight="semibold"
                    sx={{ color: colors.grey[100], mr: 1 }}
                >
                  {"Precision@5:"}  
                </Typography>
                <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{ color: colors.grey[100], mr: 2 }}
                >
                  {precision}  
                </Typography>
                {precision_percent && <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{ color: colors.greenAccent[300] }}
                >
                  +{precision_percent}  
                </Typography>}
            </Box>
            <Box 
              display="flex"
              alignItems="center">
                <Typography
                    variant="h6"
                    fontWeight="semibold"
                    sx={{ color: colors.grey[100], mr: 1 }}
                >
                  {"Recall@5:"}  
                </Typography>
                <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{ color: colors.grey[100], mr: 2 }}
                >
                  {recall}  
                </Typography>

                {recall_percent && <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{ color: colors.greenAccent[300] }}
                >
                  +{recall_percent}  
                </Typography>}
            </Box>
          </Box>
        </Box>
      </Box>
  );
};

export default ExperimentStatBox;