import React, {useState, useContext} from "react";
// import {Navigate, Link} from "react-router-dom";
// import {AuthContext} from "../AuthContext"
// import evalyLogo from "../public/assets/evalyLogo.png"
import LandingHeader from "./components/landing/landingHeader"
// import api from "./api";
import axios from "axios";
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import {MdSecurity} from "react-icons/md"
import { FaDiscord } from "react-icons/fa"
import {FaGithub} from "react-icons/fa"
import {FaTwitter} from "react-icons/fa"
import {FiSearch} from "react-icons/fi"
import { FaMedium } from "react-icons/fa";
// import {AiFillYoutube} from "react-icons/ai"
// import { typeFromAST } from "graphql";

function Landing() {
//   const {authenticated} = useContext(AuthContext)
  const [username, setUsername] = useState("")
  const [joined, setJoined] = useState(false)
  
  const joinWaitlist = async (e)=>{
    e.preventDefault()
    setJoined(true)
    // TODO: check email is valid
    const response = await axios.post('https://www.evaly.ai/join_waitlist', {
      "email": username
      })
      .then(function (response) {
        console.log(response);
        setJoined(true);
      })
      .catch(function (error) {
        console.log(error);
      });
    // toast.success("Joined successfully!")
  }
  //console.log(authenticated())
    return (
    //   authenticated() ? <Navigate to="/dashboard" /> : 
      <div>
<div class="">
    <div class="relative overflow-hidden">
      <main>
        <div class="bg-gradient-to-b from-black from-80% to-gray-800 to-100% lg:pb-14 h-screen">
          <LandingHeader />
          
          <div class="mx-auto max-w-7xl lg:pl-4 h-full flex items-center pb-4">
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">
              <div class="mx-auto max-w-md px-2 sm:max-w-2xl sm:px-2 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div class="lg:py-24">
                  <h1 class="2xl:mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-6 sm:text-6xl xl:text-6xl text-left">
                    <span class="block">TigerLab: </span>
                    <span class="block">Adversarial Testing for LLMs and Chatbots</span> 
                    
                  </h1>
                  <p class="mt-3 text-xl font-medium text-gray-400 sm:mt-8 sm:text-xl lg:text-xl xl:text-xl">
                    Make AI more accessible and secure. 
                  </p>
                  <div class="mt-10 sm:mt-12">
                    {/* <form class="mt-8 " action="#" method="POST"> */}
                      {/* <input type="hidden" name="remember" value="true" /> */}
                      <div class="rounded-md shadow-sm  flex flex-cols">
                            <a class="min-w-1/3 cursor-pointer block py-3 px-4 rounded-lg shadow font-medium bg-orange-500 text-white font-semibold hover:bg-orange-600 focus:outline-none focus:ring-2 hover:no-underline focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900" href="https://9c3obgz72ib.typeform.com/to/NE3d3wz8" target="_blank">Request Evaluation</a>
                            <a class="mx-4 min-w-1/3 cursor-pointer block py-3 px-4 rounded-lg shadow font-medium bg-white text-black font-semibold hover:text-sky-600 focus:outline-none focus:ring-2 hover:no-underline focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900" href="https://github.com/tigerlab-ai/tiger" target="_blank">Learn More</a>
                          {/* </Link> */}              
                      </div>
                      {/* {joined && <div class="rounded-md shadow-sm -space-y-px flex flex-cols">
                          <p class="rounded-md bg-indigo-600 py-3 px-4 text-lg mt-3 text-white font-medium">Congratulations! 🎉 You're now on the list to be among the first to experience it. We can't wait to share more details with you soon. Your anticipation drives us forward! Stay tuned for updates, and in the meantime, feel free to connect with us on <a class="font-bold " href="https://twitter.com/Evaly_AI">Twitter @Evaly_AI</a>.</p>
                          
                        
                      </div>} */}

                    {/* </form> */}
                    {/* <p class="mt-3 text-sm text-gray-300 sm:mt-4">Join the list to be a beta tester!</p> */}
                    <p class="mt-3 text-sm text-gray-300 sm:mt-4">Find us at <a class="font-bold " href="https://twitter.com/TigerLabAI">Twitter @TigerLabAI</a> </p>
                    {/* <p class="mt-1 text-sm text-gray-300"><a class="font-bold">evaly.llm.ai@gmail.com</a></p> */}
                    {/* <p class="text-sm text-gray-300">agree to our terms of service</p> */}
                  </div>
                </div>
              </div>
              <div class="mt-6 2xl:mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative flex items-center">
                <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 rounded-full">
                  {/* <!-- Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ --> */}
                  {/* <img class="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="https://tailwindui.com/img/component-images/cloud-illustration-indigo-400.svg" alt=""/> */}
                <img 
                  // style={{height: "20rem"}}
                  class="w-min rounded-xl" src={`../../assets/safety.png`} alt=""/> 
                </div>
              </div>
            </div>
          </div>
        </div>
  
        {/* <!-- More main page content here... --> */}
      </main>
    </div>
  </div>
  
  
{/* <!-- Features --> */}
{/* <!-- This example requires Tailwind CSS v2.0+ --> */}
<div class="relative bg-white py-16 sm:py-16 lg:py-20">
    <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
      {/* <h2 class="text-base font-semibold tracking-wider text-black uppercase font-sans">Ultimate LLM solution.</h2> */}
      <p class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
        Integrate trustworthy AI solutions into your product
      </p>
      <p class="mt-5 max-w-prose mx-auto text-xl text-gray-500">
        Capture =&gt; Mitigate =&gt; SafeGuard, streamlined AI safety testing platform to protect your AI chatbots. With TigerLab, anyone can spend less time building trustworthy LLM solutions, and more time getting their products launched!      </p>
        <div class="mt-12">
        <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          <div class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center p-3 bg-orange-400 rounded-md shadow-lg">
                    {/* <!-- Heroicon name: outline/refresh --> */}
                    <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>
                  </span>
                </div>
                <h3 class="mt-8 text-xl font-medium text-gray-900 tracking-tight font-sans">TigerTune</h3>
                <p class="mt-5 text-lg text-gray-500">
                Boost performance with swift and simple models
                </p>
              </div>
            </div>
          </div>
          <div class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center p-3 text-white text-xl bg-orange-400 rounded-md shadow-lg">
                  <MdSecurity/>
                    {/* <!-- Heroicon name: outline/server --> */}
                    {/* <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01" />
                    </svg> */}
                  </span>
                </div>
                <h3 class="mt-8 text-xl font-medium text-gray-900 tracking-tight font-sans">TigerArmor</h3>
                <p class="mt-5 text-lg text-gray-500">
                Ensuring Secure and Responsible AI Interactions
                </p>
              </div>
            </div>
          </div>
          <div class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center p-3 text-white text-xl bg-orange-400 rounded-md shadow-lg">
                    {/* <!-- Heroicon name: outline/cog --> */}
                    {/* <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg> */}
                    <FiSearch/>
                  </span>
                </div>
                <h3 class="mt-8 text-xl font-medium text-gray-900 tracking-tight font-sans">TigerRAG</h3>
                <p class="mt-5 text-lg text-gray-500">
                  Enhanced Retrieval Capabilities customized for your own data
                </p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
</div>
<div class="relative overflow-hidden">
      <main>

      <div class="mx-auto max-w-6xl lg:pl-4 h-full flex items-center">
      <div class="grid grid-cols-1 lg:grid lg:grid-cols-2 lg:gap-8">
        <div class="mx-auto max-w-md px-2 sm:max-w-2xl sm:px-2 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
          <div class="lg:py-24">
            <h1 class="2xl:mt-4 text-4xl tracking-tight font-extrabold text-gray-800 sm:mt-6 sm:text-6xl xl:text-6xl text-left">
              <span class="block">Adversarial Testing</span>
              <span class="block">for LLMs and Chatbots</span> 
            </h1>
            <p class="mt-3 text-xl font-medium text-gray-400 sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
              Adversarial testing covering broad spectrum of policies, including sexual, violence, hate, minors, gender, ethnicity, medical info, etc.
            </p>
            <p class="text-xl font-medium text-gray-400 sm:text-xl lg:text-lg xl:text-xl">
             Realtime protection for fresh news/data/signals. 
            </p>
            <p class="text-xl font-medium text-gray-400 sm:text-xl lg:text-lg xl:text-xl">
              Adopt AI with confidence.
            </p>
            <p class="mt-3 text-xl font-medium text-gray-400 sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
              
            </p>
            
          </div>
        </div>
        <div class="mt-6 2xl:mt-12 lg:m-0 lg:relative flex items-center">
          <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 ">
            {/* <!-- Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ --> */}
            {/* <img class="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="https://tailwindui.com/img/component-images/cloud-illustration-indigo-400.svg" alt=""/> */}
          <img 
            // style={{width: "32rem"}}
            class="w-min rounded-lg shadow-xl" src={`../../assets/adversarialTesting.png`} alt=""/> 
          </div>
        </div>
      </div>
      
    </div>

    <div class="mx-auto max-w-6xl lg:pl-4 h-full flex items-center">
      <div class="grid grid-cols-1 gap-8 lg:grid lg:grid-cols-2">
        <div class="mt-6 2xl:mt-12 lg:m-0 lg:relative flex items-center">
          <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 ">
            {/* <!-- Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ --> */}
            {/* <img class="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="https://tailwindui.com/img/component-images/cloud-illustration-indigo-400.svg" alt=""/> */}
          <img 
            style={{width: "32rem"}}
            class="w-min rounded-lg shadow-xl" src={`../../assets/e2e_llm.png`} alt=""/> 

          </div>
        </div>
        <div class="mx-auto max-w-md px-2 sm:max-w-2xl sm:px-2 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
          <div class="lg:py-24" id="search">
            <h1 class="2xl:mt-4 text-4xl tracking-tight font-extrabold text-gray-800 sm:mt-6 sm:text-6xl xl:text-6xl text-left">
              <span class="block">Enhanced Retrieval</span>
              <span class="block">Over Your Own Data</span> 
            </h1>
            <p class=" text-xl font-medium text-gray-400 mt-3 sm:text-xl lg:text-lg xl:text-xl">
              Turn your "standalone data" + "prompts" into a "query engine". Intelligent Retrieval with high accuracy and high recalls.  
            </p>
            {/* <p class="mt-3 text-xl font-medium text-gray-400 sm:mt-8 sm:text-xl lg:text-lg xl:text-xl"> */}
            <div class="rounded-md  flex ">
                <a class="min-w-1/3 cursor-pointer block py-3 px-4 mt-3 rounded-lg shadow font-medium bg-orange-400 text-white font-semibold hover:bg-orange-500" href="https://9c3obgz72ib.typeform.com/to/R6TaImW8" target="_blank">Request Quick Demo</a>          
            </div>
            
            {/* </p> */}
            
          </div>
        </div>
      </div>
    </div>
    </main>
  </div>

  <footer class="bg-black" aria-labelledby="footerHeading">
    <h2 id="footerHeading" class="sr-only">Footer</h2>
    <div class="max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div class="xl:grid xl:grid-cols-4 xl:gap-8 mb-16">
            <div>
              <h3 class="font-semibold text-gray-200 tracking-wider uppercase font-sans">
                Product
              </h3>
              <ul class="mt-4 space-y-4 p-0">
                <li>
                  <a href="/dashboard/experiments/7JkDp2XNQl9YtZ5" class="text-lg text-gray-400 hover:text-gray-200 hover:no-underline">
                    Playground
                  </a>
                </li>
  
                <li> 
                  <a href="https://9c3obgz72ib.typeform.com/to/MWPyRqso" target="_blank" class="text-lg text-gray-400 hover:text-gray-200 hover:no-underline">
                    Beta Waitlist
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 class="font-semibold text-gray-200 tracking-wider uppercase font-sans">
                Contact US
              </h3>
              <ul class="mt-4 space-y-4 p-0">
                <li>
                  <a class="text-lg text-gray-400 hover:text-gray-200 hover:no-underline">
                    founders@tigerlab.ai
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 class="font-semibold text-gray-200 tracking-wider uppercase font-sans">
                Press
              </h3>
              <ul class="mt-4 space-y-4 p-0">
                <li>
                  <a href="https://medium.com/@tigerlab.ai/tigerarmor-ai-safety-toolkit-a-comprehensive-evaluation-of-llm-chat-models-93ccec021f83" target="_blank" class="text-lg text-gray-400 hover:text-gray-200 hover:no-underline">
                    AI Safety Report
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/posts/sentientmatters_github-tigerlab-aitiger-open-source-llm-activity-7126297025670737923-OGsq" target="_blank" class="text-lg text-gray-400 hover:text-gray-200 hover:no-underline">
                    Media featuring
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 class="font-semibold text-gray-200 tracking-wider uppercase font-sans">
                Experiments
              </h3>
              <ul class="mt-4 space-y-4 p-0">
                <li>
                  <a href="https://airtable.com/app8zluNDCNogk4Ld/shrYRW3r0gL4DgMuW/tblpLubmd8cFsbmp5" target="_blank" class="text-lg text-gray-400 hover:text-gray-200 hover:no-underline">
                    Adversarial Testing for LLama2, Mistral, GPT4
                  </a>
                </li>
                <li>
                  <a href="/dashboard/experiments/6RgPp1HNWs8UtZ4" class="text-lg text-gray-400 hover:text-gray-200 hover:no-underline">
                    E-commerce Product Retrieval
                  </a>
                </li>
                <li>
                  <a href="/dashboard/experiments/7JkDp2XNQl9YtZ5" class="text-lg text-gray-400 hover:text-gray-200 hover:no-underline">
                    Movie recommendation
                  </a>
                </li>
              </ul>
            </div>
      </div>
      <div class="flex items-center justify-between border-t border-gray-200">
          <div class="mt-8 ">
            <div class="flex flex-row items-center justify-between">
                <img
                    style={{height: "1.5rem"}}
                    src={`../../assets/tigerLabLogo.png`}
                    alt=""
                />
                <h
                  class="block px-2 text-xl whitespace-nowrap w-64 font-semibold text-white "
                >
                  {"TigerLab"}
                </h>
            </div>
            <p class="mt-2 text-base text-gray-400">
              &copy; 2023 TigerLab. 
            </p>
          </div>
          <div class="mt-8 flex">
            <a href="https://github.com/tigerlab-ai/tiger" target="_blank" class="text-white mr-2 hover:text-gray-500 text-2xl">
              <FaGithub/>
            </a>
            <a href="https://medium.com/@tigerlab.ai" target="_blank" class="pr-2 text-white hover:text-gray-500 text-2xl">
              <FaMedium />
            </a>
            <a href="https://twitter.com/TigerLabAI" target="_blank" class="pr-1 text-white hover:text-gray-500 text-2xl">
              <FaTwitter/>
            </a>
            <a href="https://discord.gg/GnwH2STv" target="_blank" class="pl-1 text-white hover:text-gray-500 text-2xl">
              <FaDiscord />
            </a>
          </div>
      </div>
    </div>
  </footer>
  

  </div>  
      
    );

}

export default Landing;
