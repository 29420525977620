import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Landing from './Landing'
import Blog from "./blog";
import { BrowserRouter, Routes, Route } from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/blog/*" element={<Blog />} />
        <Route path="/dashboard/*" element={<App />}/>
        {/* </Route> */}
      </Routes>
    {/* <ToastContainer 
        autoClose={2500} 
        pauseOnFocusLoss={false} 
        limit={3} 
        hideProgressBar 
        position="bottom-left"
        transition={Flip}
      /> */}
      
    </BrowserRouter>
  </React.StrictMode>
);
