export const mockDataMovie = [
    {
        "id": "Romantic comedy set in New York",
        "label": [
            {
                "ID": 6,
                "Title": "Starlit Paths"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 6,
                "Title": "Starlit Paths"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Action-packed car chase movie",
        "label": [
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Historical drama about the Renaissance",
        "label": [
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 38,
                "Title": "Soul's Symphony"
            },
            {
                "ID": 26,
                "Title": "Cage of Despair"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            },
            {
                "ID": 24,
                "Title": "Nebula Quest"
            },
            {
                "ID": 26,
                "Title": "Cage of Despair"
            },
            {
                "ID": 29,
                "Title": "Time's Labyrinth"
            }
        ],
        "ebrOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 38,
                "Title": "Soul's Symphony"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Horror film set in a haunted house",
        "label": [
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ]
    },
    {
        "id": "Animated adventure with talking animals",
        "label": [
            {
                "ID": 45,
                "Title": "Legacy of the Ancients"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 38,
                "Title": "Soul's Symphony"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 38,
                "Title": "Soul's Symphony"
            },
            {
                "ID": 45,
                "Title": "Legacy of the Ancients"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "War movie set during the Vietnam War",
        "label": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            },
            {
                "ID": 24,
                "Title": "Nebula Quest"
            },
            {
                "ID": 26,
                "Title": "Cage of Despair"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ]
    },
    {
        "id": "Sci-fi film about time travel",
        "label": [
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "ebrOutput": [
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Mystery thriller with a detective protagonist",
        "label": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            }
        ]
    },
    {
        "id": "Classic black and white film noir",
        "label": [
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 8,
                "Title": "Crimson Dawn"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 8,
                "Title": "Crimson Dawn"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            },
            {
                "ID": 10,
                "Title": "Celestial Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ]
    },
    {
        "id": "Family drama set during Christmas",
        "label": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            }
        ],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 38,
                "Title": "Soul's Symphony"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Superhero movie with female lead",
        "label": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ]
    },
    {
        "id": "Biographical film about a famous musician",
        "label": [
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Movie about ancient Egyptian civilization",
        "label": [
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 32,
                "Title": "Temple's Secret"
            }
        ],
        "ebrOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ]
    },
    {
        "id": "Comedy centered around high school reunion",
        "label": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 7,
                "Title": "Nightmare's Edge"
            }
        ],
        "ebrOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ]
    },
    {
        "id": "Western film set during the gold rush",
        "label": [
            {
                "ID": 29,
                "Title": "Time's Labyrinth"
            },
            {
                "ID": 24,
                "Title": "Nebula Quest"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            },
            {
                "ID": 24,
                "Title": "Nebula Quest"
            },
            {
                "ID": 26,
                "Title": "Cage of Despair"
            },
            {
                "ID": 29,
                "Title": "Time's Labyrinth"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            }
        ]
    },
    {
        "id": "Space exploration movie with aliens",
        "label": [
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            }
        ]
    },
    {
        "id": "Documentary about climate change",
        "label": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            }
        ],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Teenage drama with a love triangle",
        "label": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Fantasy film with dragons and knights",
        "label": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 8,
                "Title": "Crimson Dawn"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 8,
                "Title": "Crimson Dawn"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            },
            {
                "ID": 10,
                "Title": "Celestial Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Musical set in 1920s Paris",
        "label": [
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 6,
                "Title": "Starlit Paths"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Post-apocalyptic survival story",
        "label": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 44,
                "Title": "Wings of Freedom"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 42,
                "Title": "Moonlit Sonata"
            },
            {
                "ID": 44,
                "Title": "Wings of Freedom"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Dark comedy about a dysfunctional family",
        "label": [
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            }
        ]
    },
    {
        "id": "Animated film about underwater creatures",
        "label": [
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 38,
                "Title": "Soul's Symphony"
            }
        ],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 38,
                "Title": "Soul's Symphony"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ]
    },
    {
        "id": "Romantic drama set during World War II",
        "label": [
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 35,
                "Title": "Fury's Path"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            },
            {
                "ID": 35,
                "Title": "Fury's Path"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ]
    },
    {
        "id": "Crime thriller about a bank heist",
        "label": [
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Period drama set in Victorian England",
        "label": [
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 6,
                "Title": "Starlit Paths"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 6,
                "Title": "Starlit Paths"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            }
        ],
        "ebrOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Sports movie about an underdog team",
        "label": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 10,
                "Title": "Celestial Fate"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 8,
                "Title": "Crimson Dawn"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            },
            {
                "ID": 10,
                "Title": "Celestial Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            }
        ]
    },
    {
        "id": "Martial arts film with epic battles",
        "label": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Biographical film about a US president",
        "label": [
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Zombie apocalypse horror film",
        "label": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Movie about the civil rights movement",
        "label": [
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            },
            {
                "ID": 24,
                "Title": "Nebula Quest"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            },
            {
                "ID": 24,
                "Title": "Nebula Quest"
            },
            {
                "ID": 26,
                "Title": "Cage of Despair"
            },
            {
                "ID": 29,
                "Title": "Time's Labyrinth"
            }
        ],
        "ebrOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ]
    },
    {
        "id": "Adventure film set in the Amazon jungle",
        "label": [
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 6,
                "Title": "Starlit Paths"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ]
    },
    {
        "id": "Coming-of-age story in a small town",
        "label": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Animated fairy tale adaptation",
        "label": [
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            }
        ],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            }
        ]
    },
    {
        "id": "Slasher horror set during Halloween",
        "label": [
            {
                "ID": 7,
                "Title": "Nightmare's Edge"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 7,
                "Title": "Nightmare's Edge"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Espionage thriller with international spies",
        "label": [
            {
                "ID": 8,
                "Title": "Crimson Dawn"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 8,
                "Title": "Crimson Dawn"
            },
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Bollywood musical romance",
        "label": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Romantic comedy about online dating",
        "label": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            }
        ],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Drama about mental health issues",
        "label": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            }
        ],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 38,
                "Title": "Soul's Symphony"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            }
        ]
    },
    {
        "id": "War movie set during the ancient Rome era",
        "label": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            },
            {
                "ID": 24,
                "Title": "Nebula Quest"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Movie centered around a dance competition",
        "label": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ]
    },
    {
        "id": "Comedy about a family road trip",
        "label": [
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Animated film about robots",
        "label": [
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            }
        ],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Fantasy film about witches and wizards",
        "label": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 8,
                "Title": "Crimson Dawn"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 10,
                "Title": "Celestial Fate"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 8,
                "Title": "Crimson Dawn"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            },
            {
                "ID": 10,
                "Title": "Celestial Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Historical drama about the Titanic",
        "label": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 29,
                "Title": "Time's Labyrinth"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            },
            {
                "ID": 24,
                "Title": "Nebula Quest"
            },
            {
                "ID": 26,
                "Title": "Cage of Despair"
            },
            {
                "ID": 29,
                "Title": "Time's Labyrinth"
            }
        ],
        "ebrOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            }
        ]
    },
    {
        "id": "Psychological thriller with unexpected twists",
        "label": [
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Nature documentary about African wildlife",
        "label": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 31,
                "Title": "Frostbite"
            },
            {
                "ID": 34,
                "Title": "Forest's Echo"
            }
        ],
        "ebrOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Romantic drama about forbidden love",
        "label": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 20,
                "Title": "Lunar Eclipse"
            },
            {
                "ID": 39,
                "Title": "Vampire's Kiss"
            },
            {
                "ID": 33,
                "Title": "Ocean's Whispers"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 20,
                "Title": "Lunar Eclipse"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 33,
                "Title": "Ocean's Whispers"
            },
            {
                "ID": 39,
                "Title": "Vampire's Kiss"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 20,
                "Title": "Lunar Eclipse"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            }
        ]
    },
    {
        "id": "Movie about a futuristic dystopian society",
        "label": [
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Comedy about a group of friends on vacation",
        "label": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 38,
                "Title": "Soul's Symphony"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Sci-fi film about artificial intelligence",
        "label": [
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            }
        ],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Drama based on a true crime story",
        "label": [
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Horror film about a cursed object",
        "label": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            }
        ]
    },
    {
        "id": "Animated movie about a superhero team",
        "label": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 45,
                "Title": "Legacy of the Ancients"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Mystery thriller set in a small town",
        "label": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "War movie about medieval battles",
        "label": [
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            },
            {
                "ID": 35,
                "Title": "Fury's Path"
            }
        ],
        "ebrOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Comedy about a mistaken identity",
        "label": [
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Movie about a famous explorer's journey",
        "label": [
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 45,
                "Title": "Legacy of the Ancients"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 45,
                "Title": "Legacy of the Ancients"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ]
    },
    {
        "id": "Fantasy film set in a magical realm",
        "label": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ]
    },
    {
        "id": "Thriller about an airplane hijacking",
        "label": [
            {
                "ID": 8,
                "Title": "Crimson Dawn"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 8,
                "Title": "Crimson Dawn"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            },
            {
                "ID": 10,
                "Title": "Celestial Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Documentary about world religions",
        "label": [
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 2,
                "Title": "Mystic Skies"
            }
        ],
        "ebrOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Romantic comedy set during a wedding",
        "label": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Biographical film about a famous scientist",
        "label": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Drama about immigration challenges",
        "label": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            }
        ],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Sports movie about a famous athlete's life",
        "label": [
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Horror film set in a remote village",
        "label": [
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            }
        ]
    },
    {
        "id": "Historical drama about the American Revolution",
        "label": [
            {
                "ID": 38,
                "Title": "Soul's Symphony"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            },
            {
                "ID": 24,
                "Title": "Nebula Quest"
            },
            {
                "ID": 26,
                "Title": "Cage of Despair"
            },
            {
                "ID": 29,
                "Title": "Time's Labyrinth"
            }
        ],
        "ebrOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 38,
                "Title": "Soul's Symphony"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ]
    },
    {
        "id": "Animated film about a lost civilization",
        "label": [
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            }
        ]
    },
    {
        "id": "Musical set in modern-day New York",
        "label": [
            {
                "ID": 6,
                "Title": "Starlit Paths"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 6,
                "Title": "Starlit Paths"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Adventure movie about treasure hunting",
        "label": [
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            }
        ],
        "ebrOutput": [
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 45,
                "Title": "Legacy of the Ancients"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Drama about a strained parent-child relationship",
        "label": [
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 18,
                "Title": "Haunted Past"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Comedy about two unlikely friends",
        "label": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Movie about the challenges of old age",
        "label": [
            {
                "ID": 17,
                "Title": "Solar Winds"
            },
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            },
            {
                "ID": 17,
                "Title": "Solar Winds"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 38,
                "Title": "Soul's Symphony"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Sci-fi film about a parallel universe",
        "label": [
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            }
        ]
    },
    {
        "id": "Thriller about corporate espionage",
        "label": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Biographical film about a famous writer",
        "label": [
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Animated film about space adventures",
        "label": [
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 45,
                "Title": "Legacy of the Ancients"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            }
        ],
        "ebrOutput": [
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 45,
                "Title": "Legacy of the Ancients"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Romantic drama set during a summer vacation",
        "label": [
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "War movie about a secret mission",
        "label": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Comedy about a workplace rivalry",
        "label": [
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Documentary about the universe and stars",
        "label": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 8,
                "Title": "Crimson Dawn"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            },
            {
                "ID": 10,
                "Title": "Celestial Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Mystery thriller with a supernatural twist",
        "label": [
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            }
        ]
    },
    {
        "id": "Horror film about a group of friends on a trip",
        "label": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            }
        ]
    },
    {
        "id": "Animated fairy tale with a modern twist",
        "label": [
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 38,
                "Title": "Soul's Symphony"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 38,
                "Title": "Soul's Symphony"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            }
        ]
    },
    {
        "id": "Drama about a love story in the mountains",
        "label": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 38,
                "Title": "Soul's Symphony"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Fantasy film about elves and dwarves",
        "label": [
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 8,
                "Title": "Crimson Dawn"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            },
            {
                "ID": 10,
                "Title": "Celestial Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Sci-fi film about a human colony on Mars",
        "label": [
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Historical drama set during the French Revolution",
        "label": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 24,
                "Title": "Nebula Quest"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 15,
                "Title": "Frozen Abyss"
            },
            {
                "ID": 24,
                "Title": "Nebula Quest"
            },
            {
                "ID": 26,
                "Title": "Cage of Despair"
            },
            {
                "ID": 29,
                "Title": "Time's Labyrinth"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Comedy about siblings rivalry",
        "label": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Crime thriller about a serial killer",
        "label": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 16,
                "Title": "Midnight Chase"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 16,
                "Title": "Midnight Chase"
            }
        ]
    },
    {
        "id": "Animated film about a villain's redemption",
        "label": [
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 45,
                "Title": "Legacy of the Ancients"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Musical set in 1960s London",
        "label": [
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 6,
                "Title": "Starlit Paths"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Adventure film about an Arctic expedition",
        "label": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 8,
                "Title": "Crimson Dawn"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            },
            {
                "ID": 10,
                "Title": "Celestial Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Romantic comedy about a chance encounter",
        "label": [
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Biographical film about a famous artist",
        "label": [
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Documentary about endangered species",
        "label": [
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            }
        ],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 36,
                "Title": "Galaxy's Edge"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Drama about a single mother's struggles",
        "label": [
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 18,
                "Title": "Haunted Past"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 18,
                "Title": "Haunted Past"
            }
        ],
        "garOutput": [
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Mystery thriller set in an abandoned mansion",
        "label": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            },
            {
                "ID": 6,
                "Title": "Starlit Paths"
            },
            {
                "ID": 8,
                "Title": "Crimson Dawn"
            }
        ],
        "ebrOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Sci-fi film about an alien civilization",
        "label": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 45,
                "Title": "Legacy of the Ancients"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 8,
                "Title": "Crimson Dawn"
            },
            {
                "ID": 9,
                "Title": "Distant Horizons"
            },
            {
                "ID": 10,
                "Title": "Celestial Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 45,
                "Title": "Legacy of the Ancients"
            },
            {
                "ID": 12,
                "Title": "Inferno's Embrace"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 25,
                "Title": "Whispers in the Wind"
            }
        ]
    },
    {
        "id": "Comedy about a pet detective",
        "label": [
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "heuristicsOutput": [
            {
                "ID": 1,
                "Title": "The Great Adventure"
            },
            {
                "ID": 2,
                "Title": "Mystic Skies"
            },
            {
                "ID": 3,
                "Title": "Twilight Streets"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 5,
                "Title": "Winds of Fate"
            }
        ],
        "ebrOutput": [
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 45,
                "Title": "Legacy of the Ancients"
            },
            {
                "ID": 41,
                "Title": "Starship Odyssey"
            },
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            }
        ],
        "garOutput": [
            {
                "ID": 11,
                "Title": "Gotham's Shadows"
            },
            {
                "ID": 4,
                "Title": "Whispered Dreams"
            },
            {
                "ID": 50,
                "Title": "Ocean's Dance"
            },
            {
                "ID": 13,
                "Title": "River's Melody"
            },
            {
                "ID": 21,
                "Title": "Emerald Eyes"
            }
        ]
    }
]