import { Box, useTheme, TextField, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import {mockDataChatExperiments} from "../../data/mockData"
import { mockDataMovie } from "../../data/mockDataMovie";
import Header from "../../components/Header";
import ExperimentStatBox from "../../components/ExperimentStatBox";
import { mockDataEcommerce } from "../../data/mockDataEcommerce";
// import StatBox from "../../components/StatBox";


const Experiments = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const experimentMetadata = {
    "6RmGx3FOUp8WqY2": {
      title: "Chat with documents",
      data: mockDataChatExperiments,
    },
    "7JkDp2XNQl9YtZ5": {
      title: "Movies Recommendation",
      data: mockDataMovie,
      itemName: "Title",
      stats: {
        heuristics_map: 0.2810,
        heuristics_precision: 0.3740,
        heuristics_recall: 0.3740,
        ebr_map: 0.3880,
        ebr_precision: 0.5360,
        ebr_recall: 0.5360,
        ebr_map_percent: "38.1%",
        ebr_precision_percent: "43.3%",
        ebr_recall_percent: "43.3%",
        gar_map: 0.3995,
        gar_precision: 0.5400,
        gar_recall: 0.5400,
        gar_map_percent: "42.2%",
        gar_precision_percent: "44.4%",
        gar_recall_percent: "44.4%"
      },
    },
    "6RgPp1HNWs8UtZ4": {
      title: "E-commerce Product Retrieval",
      data: mockDataEcommerce,
      itemName: "product_name",
      stats: {
        heuristics_map: 0.0001,
        heuristics_precision: 0.0004,
        heuristics_recall: 0.00040,
        ebr_map: 0.0006,
        ebr_precision: 0.0008,
        ebr_recall: 0.0008,
        ebr_map_percent: "500%",
        ebr_precision_percent: "100%",
        ebr_recall_percent: "100%",
        gar_map: 0.0034,
        gar_precision: 0.0075,
        gar_recall: 0.0075,
        gar_map_percent: "3300%",
        gar_precision_percent: "1775%",
        gar_recall_percent: "1775%"
      },
    },
  }
  const pathnames = window.location.pathname.split("/")
  let experimentId = "6RmGx3FOUp8WqY2"
  if (pathnames.length>3 && pathnames[3].toString()) {
    experimentId = pathnames[3].toString()
  }
  console.log(experimentId)
  let columns = [
    { 
      field: "id",
      headerName: "Scenarios",
      width: 60,
      height: 60,
      flex: 1,
      renderCell: (params) => (
        <div sx={{overflow: 'hidden'}}>
            <Grid item xs={4}>
                <TextField
                    disabled
                    fullWidth
                    // id="outlined-disabled"
                    label="query"
                    margin="normal"
                    defaultValue={params.row.question}
                    multiline
                    rows={1}
                    // fullWidth
                    style = {{width: 250}} 
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    disabled
                    fullWidth
                    // id="outlined-disabled"
                    label="label"
                    margin="normal"
                    defaultValue={params.row.label}
                    multiline
                    rows={6}
                    style = {{width: 250}} 
                    // fullWidth
                />
            </Grid>
        </div>
      ),
    },
    {
      field: "ebrOutput",
      headerName: "EBR",
      flex: 1,
      renderCell: (params) => {
        return (
          <TextField
            value={params.row.ebrOutput}
            // InputProps={{ disableUnderline: true }}
            multiline
            rows={10}
            style = {{width: 250}} 
            variant="standard"
            InputProps={{
                disableUnderline: true,
            }}
          />
        )
       }
    },
    {
      field: "ragOutput",
      headerName: "RAG",
      flex: 1,
      renderCell: (params) => {
        return (
          <TextField
            value={params.row.ragOutput}
            // InputProps={{ disableUnderline: true }}
            multiline
            rows={10}
            style = {{width: 250}} 
            variant="standard"
            InputProps={{
                disableUnderline: true,
            }}
          />
        )
       }
    },
    {
        field: "garOutput",
        headerName: "GAR",
        flex: 1,
        renderCell: (params) => {
            return (
              <TextField
                value={params.row.garOutput}
                // InputProps={{ disableUnderline: true }}
                multiline
                rows={10}
                style = {{width: 250}} 
                variant="standard"
                InputProps={{
                    disableUnderline: true,
                }}
              />
            )
           }
      },
  ];

  if (experimentId==="7JkDp2XNQl9YtZ5"|| experimentId ==="6RgPp1HNWs8UtZ4") {
    console.log(experimentId)
    console.log(experimentMetadata)
    console.log(experimentId in experimentMetadata)
    let itemName = experimentMetadata[experimentId]["itemName"]
    console.log(experimentMetadata[experimentId])
    columns = [
      { 
        field: "id",
        headerName: "Scenarios",
        width: 60,
        height: 60,
        flex: 1,
        renderCell: (params) => (
          <div sx={{overflow: 'hidden'}}>
              <Grid item xs={4}>
                  <TextField
                      disabled
                      fullWidth
                      // id="outlined-disabled"
                      label="query"
                      margin="normal"
                      defaultValue={experimentId==="6RgPp1HNWs8UtZ4"?params.row.query:params.row.id}
                      multiline
                      rows={1}
                      // fullWidth
                      style = {{width: 250}} 
                  />
              </Grid>
              <Grid item xs={4}>
                  <TextField
                      disabled
                      fullWidth
                      // id="outlined-disabled"
                      label="label"
                      margin="normal"
                      defaultValue={params.row.label.map((label)=>{
                        return label[itemName]+"\n"
                      })}
                      multiline
                      rows={6}
                      style = {{width: 250}} 
                      // fullWidth
                  />
              </Grid>
          </div>
        ),
      },
      {
        field: "heuristicsOutput",
        headerName: "Heuristics",
        flex: 1,
        renderCell: (params) => {
          return (
            <div sx={{overflow: 'hidden'}}>
              {params.row.heuristicsOutput.map((movie)=>{
                return <Grid item xs={4}>
                  <p style={{fontSize: "14px"}}>{movie[itemName]}</p>
                </Grid>
              })}
           </div>
          )
         }
      },
      {
        field: "ebrOutput",
        headerName: "EBR",
        flex: 1,
        renderCell: (params) => {
          return (
            <div sx={{overflow: 'hidden'}}>
            {params.row.ebrOutput.map((movie)=>{
              return <Grid item xs={4}>
                <p style={{fontSize: "14px"}}>{movie[itemName]}</p>
              </Grid>
            })}
            </div>
          )
         }
      },
      {
          field: "garOutput",
          headerName: "GAR",
          flex: 1,
          renderCell: (params) => {
              return (
                <div sx={{overflow: 'hidden'}}>
                    {params.row.garOutput.map((movie)=>{
                      return <Grid item xs={4}>
                        <p style={{fontSize: "14px"}}>{movie[itemName]}</p>
                      </Grid>
                    })}
                </div>
              )
             }
        },
    ];
  
  }

  return (
    <Box m="20px">
      <Header title={experimentMetadata[experimentId].title} />
      {(experimentId==="7JkDp2XNQl9YtZ5" || experimentId==="6RgPp1HNWs8UtZ4") && <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
        sx={{ mb: 2,
              mr: 2, 
        }}
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={"#f3f3f6"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box width="100%" m="0 30px">
          </Box>
        </Box>
        <ExperimentStatBox 
            title={"Heuristics - Benchmark"} 
            map={experimentMetadata[experimentId].stats.heuristics_map} 
            precision={experimentMetadata[experimentId].stats.heuristics_precision} 
            recall={experimentMetadata[experimentId].stats.heuristics_recall}
        />
        <ExperimentStatBox 
            title={"EBR"} 
            map={experimentMetadata[experimentId].stats.ebr_map} 
            precision={experimentMetadata[experimentId].stats.ebr_precision} 
            recall={experimentMetadata[experimentId].stats.ebr_recall}
            map_percent={experimentMetadata[experimentId].stats.ebr_map_percent} 
            precision_percent={experimentMetadata[experimentId].stats.ebr_precision_percent} 
            recall_percent={experimentMetadata[experimentId].stats.ebr_recall_percent}
        />
        <ExperimentStatBox 
            title={"GAR"} 
            map={experimentMetadata[experimentId].stats.gar_map} 
            precision={experimentMetadata[experimentId].stats.gar_precision} 
            recall={experimentMetadata[experimentId].stats.gar_recall}
            map_percent={experimentMetadata[experimentId].stats.gar_map_percent} 
            precision_percent={experimentMetadata[experimentId].stats.gar_precision_percent} 
            recall_percent={experimentMetadata[experimentId].stats.gar_recall_percent}
        />
      </Box>}
      <Box
        // m="40px 0 0 0"
        height={(experimentId==="7JkDp2XNQl9YtZ5" || experimentId==="6RgPp1HNWs8UtZ4")?"68vh":"86vh"}
        sx={{
          "& .MuiDataGrid-root": {
            // border: "none",
          },
          "& .MuiDataGrid-cell": {
          },
          '& .MuiDataGrid-cell:hover': {
            // color: colors.primary[400],
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[400],
            fontSize: "16px",
            fontWeight: 900,
            // borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            // borderTop: "none",
            backgroundColor: colors.primary[400],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={experimentMetadata[experimentId].data} columns={columns} rowHeight={280} showCellRightBorder={true}/>
      </Box>
    </Box>
  );
};

export default Experiments;