import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import ProgressCircle from "./ProgressCircle";

const StatBox = ({ title, subtitle, icon, color=null }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="0 30px">
      <Box>
        <Box display="flex" class="flex items-center" sx={{fontSize: "small", margin:"0 0 2px 0"}}>
          {icon}
          <Typography sx={{ color: color?color:colors.grey[400], padding: "0 0 0 5px", fontSize: "12x", textTransform: "uppercase"}}>
          {subtitle}
          </Typography>
        </Box>
        <Typography
            variant="h2"
            fontWeight="bold"
            sx={{ color: colors.grey[100] }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default StatBox;
