import { useState, useEffect } from "react";
import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { mockLineData as data } from "../data/mockData";
import { mockLineDataHour as hourData } from "../data/mockData";
import api from "../api";

const LineChart = ({ isCustomLineColors = false, isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [type, setType] = useState("day")
  // const [tokenData, setTokenData] = useState([])

  // const fetchTokenUsage = async () => {
  //   const response = await api.get("/per_hour_timeseries/");
  //   setTokenData(response.data)
  //   console.log(response.data)
  // }
  // useEffect(()=>{
  //   fetchTokenUsage();
  // }, [])

  const consLegends = [
    {
      anchor: "bottom-right",
      direction: "column",
      justify: false,
      translateX: 100,
      translateY: 0,
      itemsSpacing: 0,
      itemDirection: "left-to-right",
      itemWidth: 80,
      itemHeight: 20,
      itemOpacity: 0.75,
      symbolSize: 12,
      symbolShape: "circle",
      symbolBorderColor: "rgba(0, 0, 0, .5)",
      effects: [
        {
          on: "hover",
          style: {
            itemBackground: "rgba(0, 0, 0, .03)",
            itemOpacity: 1,
          },
        },
      ],
    },
  ]
  const consTheme = {
    axis: {
      domain: {
        line: {
          stroke: colors.grey[100],
        },
      },
      legend: {
        text: {
          fill: colors.grey[100],
        },
      },
      ticks: {
        line: {
          stroke: colors.grey[100],
          strokeWidth: 1,
        },
        text: {
          fill: colors.grey[100],
        },
      },
    },
    legends: {
      text: {
        fill: colors.grey[100],
      },
    },
    tooltip: {
      container: {
        color: colors.primary[500],
      },
    },
  }

  if (type==="day") return (
    <ResponsiveLine
      data={data}
      theme={consTheme}
      colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }} // added
      data={data}
      xScale={{
        format: "%Y-%m-%d",
        precision: "day",
        type: "time",
        useUTC: false
      }}
      xFormat="time:%Y-%m-%d"
      yScale={{
        type: "linear",
        // min: "auto",
        // max: "auto",
        stacked: true,
        reverse: false,
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: "every 2 days",
        format: "%b %d",
        legend: isDashboard ? undefined : "time",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickValues: 5, // added
        tickSize: 3,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "token count", // added
        legendOffset: -40,
        legendPosition: "middle",
      }}
      enableGridX={false}
      enableGridY={false}
      pointSize={8}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      curve="catmullRom"//"monotoneX"
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      legends={consLegends}
    />

  );
  if (type==="hour") return (
    <ResponsiveLine
      data={[
        {
          id: "token",
          color: tokens("dark").greenAccent[500],
          data: hourData
        }
      ]}
      theme={consTheme}
      colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }} // added
      xScale={{
        format: "%Y-%m-%d %H:%M:%S",
        // precision: "second",
        type: "time",
        useUTC: false
      }}
      xFormat="time:%Y-%m-%d %H:%M:%S"
      yScale={{
        type: "linear",
        // min: "auto",
        // max: "auto",
        stacked: true,
        reverse: false,
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: "every 4 hours",
        format: "%Y-%m-%d %H:%M:%S",
        legend: isDashboard ? undefined : "time",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickValues: 5, // added
        tickSize: 3,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "token count", // added
        legendOffset: -40,
        legendPosition: "middle",
      }}
      enableGridX={false}
      enableGridY={false}
      pointSize={8}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      // pointSymbol={function noRefCheck(){}}
      useMesh={true}
      // curve="catmullRom"//"monotoneX"
      curve="monotoneX"
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      legends={consLegends}
    />

  );
};

export default LineChart;
