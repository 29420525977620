import React from "react";

function BlogHeader () {
    return (

      <div class="flex flex-row justify-between items-center mr-4 py-5 bg-white border-b border" style={{paddingTop: "20px", paddingBottom:"20px"}}> 
              <div class="mx-3 px-4 flex flex-row items-center justify-between">
                    <img
                        style={{height: "2.5rem"}}
                        src={`../../assets/tigerLabLogo.png`}
                        alt=""
                    />
                      <h
                        class="block px-2 text-3xl whitespace-nowrap w-64 font-bold text-gray-900 "
                      >
                        {"TigerLab"}
                      </h>
              </div>
              <div class="flex">
                <a class="px-6 py-2 text-lg font-medium lg:rounded-md text-gray-900 hover:text-sky-600" href="/">Home</a>
                <a class="px-6 py-2 text-lg font-medium lg:rounded-md text-gray-900 hover:text-sky-600" href="https://9c3obgz72ib.typeform.com/to/MWPyRqso" target="_blank">Beta waitlist</a>
                <a class="px-6 py-2 text-lg font-medium lg:rounded-md text-gray-900 hover:text-sky-600" href="https://calendly.com/tigerlab-ai/30min" target="_blank">Talk to us</a>
                <a class="px-6 py-2 text-lg font-medium lg:rounded-md text-gray-900 hover:text-sky-600" href="/blog">Blog</a>
                <a class="px-6 py-2 text-lg font-medium lg:rounded-md text-gray-900 hover:text-sky-600" href="https://github.com/tigerlab-ai/tiger" target="_blank">GitHub</a>
              </div>
          </div>
    )
}
export default BlogHeader