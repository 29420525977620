export const mockDataEcommerce = [
    {
        "id": 73,
        "label": [
            {
                "product_id": 41940,
                "product_name": "carmel 8 oz . melamine cereal bowl"
            },
            {
                "product_id": 41943,
                "product_name": "sighwith 8 '' melamine salad plate"
            },
            {
                "product_id": 13092,
                "product_name": "ring melamine 72 fl oz . serving bowl"
            },
            {
                "product_id": 33930,
                "product_name": "mceachern 9 '' melamine salad plate"
            },
            {
                "product_id": 38569,
                "product_name": "cassia certified essential oil"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 9714,
                "product_name": "mcgavock 9 '' melamine salad plate"
            },
            {
                "product_id": 33929,
                "product_name": "soileau 9 '' melamine salad plate"
            },
            {
                "product_id": 13093,
                "product_name": "melamine 128 fl oz . serving bowl"
            },
            {
                "product_id": 26648,
                "product_name": "jarl cubby bear 12 piece melamine dinnerware set , service for 4"
            },
            {
                "product_id": 33922,
                "product_name": "tuers 9 '' melamine salad plate"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 38808,
                "product_name": "edmiston firewood log rack"
            },
            {
                "product_id": 3371,
                "product_name": "heerenveen melamine 9.25 '' salad plate"
            },
            {
                "product_id": 3372,
                "product_name": "helsingor melamine 9.25 '' salad plate"
            },
            {
                "product_id": 3370,
                "product_name": "heidi melamine 8.25 '' salad plate"
            },
            {
                "product_id": 26534,
                "product_name": "joron melamine 9.25 '' salad plate"
            }
        ],
        "garOutput": [
            {
                "product_id": 16539,
                "product_name": "kleinschmidt 3 piece coffee table set"
            },
            {
                "product_id": 30134,
                "product_name": "deniece 29 '' solid wood console table"
            },
            {
                "product_id": 33791,
                "product_name": "florid lantern 6.25 '' x 7.25 '' straight edge ceramic singular subway tile"
            },
            {
                "product_id": 5965,
                "product_name": "solar powered powered led spot light"
            },
            {
                "product_id": 14443,
                "product_name": "filing cabinet parts"
            }
        ],
        "query": "certified international melamine"
    },
    {
        "id": 420,
        "label": [
            {
                "product_id": 12868,
                "product_name": "rama 7 '' x 16 '' beveled porcelain mosaic wall & floor tile"
            },
            {
                "product_id": 33818,
                "product_name": "carrara 1 '' x 3 '' marble mosaic tile"
            },
            {
                "product_id": 23613,
                "product_name": "metal glass mosaic tile"
            },
            {
                "product_id": 7773,
                "product_name": "arctic 1 '' x 2 '' seashell mosaic tile in white"
            },
            {
                "product_id": 12815,
                "product_name": "muro arriba 13 '' x 25 '' porcelain stone look tile"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 6079,
                "product_name": "athens 12 '' x 12 '' natural stone and marble mosaic tile"
            },
            {
                "product_id": 34624,
                "product_name": "12 '' x 4 '' ceramic subway tile"
            },
            {
                "product_id": 27012,
                "product_name": "fan fish scale glazed 3 '' x 4 '' ceramic mosaic tile"
            },
            {
                "product_id": 12373,
                "product_name": "trapezoid metal mosaic tile"
            },
            {
                "product_id": 27079,
                "product_name": "alaska ledger panel corner 4.5 '' x 9 '' natural stacked stone tile"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 27320,
                "product_name": "0.75 '' x 0.75 '' seashell tile"
            },
            {
                "product_id": 12350,
                "product_name": "rossmere indoor/outdoor bench cushion"
            },
            {
                "product_id": 18489,
                "product_name": "aurelie throw pillow in light blue"
            },
            {
                "product_id": 18012,
                "product_name": "aditi patio 3 piece seating group with cushions"
            },
            {
                "product_id": 27265,
                "product_name": "cobalto le leghe 3 '' x 12 '' porcelain mosaic subway wall & floor tile"
            }
        ],
        "garOutput": [
            {
                "product_id": 13976,
                "product_name": "stamped round 1-gang toggle light switch wall plate"
            },
            {
                "product_id": 20242,
                "product_name": "super mini rhombic shape sliding standard single barn door hardware kit"
            },
            {
                "product_id": 20239,
                "product_name": "super mini top mount sliding standard double barn door hardware kit"
            },
            {
                "product_id": 20238,
                "product_name": "super mini top mount sliding standard single barn door hardware kit"
            },
            {
                "product_id": 20240,
                "product_name": "super mini arrow shape sliding standard single barn door hardware kit"
            }
        ],
        "query": "tile backsplash"
    },
    {
        "id": 400,
        "label": [
            {
                "product_id": 11115,
                "product_name": "office chair \u2014\u2014ergonomic mesh chair computer chair home executive desk chair comfortable reclining swivel chair and , mid back with wheels for teens/adults ( pink )"
            },
            {
                "product_id": 2517,
                "product_name": "home office chair \u2014\u2014ergonomic mesh chair computer chair home executive desk chair comfortable reclining swivel chair and , mid back with wheels for teens/adults ( pink )"
            },
            {
                "product_id": 21190,
                "product_name": "ergonomic mesh chair computer chair home executive desk chair comfortable reclining swivel chair"
            },
            {
                "product_id": 9210,
                "product_name": "arlo swivel reclining rocking glider"
            },
            {
                "product_id": 41662,
                "product_name": "amon 38 '' wide manual rocker standard recliner"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 18258,
                "product_name": "modern electronic power recliner with 360 swivel rotation , usb charging port and footrest , brown"
            },
            {
                "product_id": 35741,
                "product_name": "standley 29.3 '' wide velvet manual swivel standard recliner"
            },
            {
                "product_id": 31588,
                "product_name": "isair swivel reclining glider"
            },
            {
                "product_id": 31267,
                "product_name": "30.5 '' wide manual glider club recliner"
            },
            {
                "product_id": 21943,
                "product_name": "k-like adjustable reclining ergonomic leather swiveling pc & racing game chair with footrest"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 30158,
                "product_name": "hershberger buffet table"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 26887,
                "product_name": "capems 65.35 '' faux leather pillow top arm sleeper"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 25179,
                "product_name": "ebron 26 '' wide polyester wingback chair"
            },
            {
                "product_id": 6913,
                "product_name": "yorkana 27 '' wide chenille wingback chair"
            },
            {
                "product_id": 28577,
                "product_name": "chico rocking chair with cushions"
            },
            {
                "product_id": 1286,
                "product_name": "silja 112 '' wide reversible sofa & chaise with ottoman"
            },
            {
                "product_id": 38491,
                "product_name": "nob hill chaise lounge"
            }
        ],
        "query": "small ladies rocker swivel recliner"
    },
    {
        "id": 281,
        "label": [
            {
                "product_id": 12208,
                "product_name": "ambesonne ships wheel tapestry , nautical patchwork pattern rope starfish sailing ship anchor and helm , fabric wall hanging decor for bedroom living room dorm , 28 '' x 23 '' , turquoise navy"
            },
            {
                "product_id": 18444,
                "product_name": "thetford ship wheel"
            },
            {
                "product_id": 29052,
                "product_name": "on ship wheel 7 piece coaster set with holder"
            },
            {
                "product_id": 544,
                "product_name": "ornamental nautical ship steering wheel home wall decor"
            },
            {
                "product_id": 3483,
                "product_name": "black/chrome pirate ship steering wheel wall d\u00e9cor"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 24366,
                "product_name": "brunk ship wheel sculpture"
            },
            {
                "product_id": 24345,
                "product_name": "suzie anchors and ship wheels single shower curtain"
            },
            {
                "product_id": 4388,
                "product_name": "harriet ship wheel square pillow cover & insert"
            },
            {
                "product_id": 18443,
                "product_name": "ship wheel wall d\u00e9cor"
            },
            {
                "product_id": 17328,
                "product_name": "wetzel ship wheel wall decor"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 20718,
                "product_name": "abramowski monitor stand"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            }
        ],
        "garOutput": [
            {
                "product_id": 13577,
                "product_name": "turquoise painted poppy indoor/outdoor throw pillow"
            },
            {
                "product_id": 21222,
                "product_name": "raami 112 fl oz . serving bowl"
            },
            {
                "product_id": 25266,
                "product_name": "collage 29.5 '' wide manual rocker standard recliner"
            },
            {
                "product_id": 39644,
                "product_name": "evas hall tree"
            },
            {
                "product_id": 33124,
                "product_name": "5 drawer chest"
            }
        ],
        "query": "brunk ship wheel"
    },
    {
        "id": 405,
        "label": [
            {
                "product_id": 19989,
                "product_name": "swivel rocker fabric recliner chair - reclining chair manual , single modern sofa home theater seating for living room"
            },
            {
                "product_id": 29489,
                "product_name": "payton 23.5 '' w leather seat waiting room chair with metal frame"
            },
            {
                "product_id": 37476,
                "product_name": "sauter 2 piece living room set"
            },
            {
                "product_id": 18787,
                "product_name": "jayapura 2 piece living room set"
            },
            {
                "product_id": 17688,
                "product_name": "pc and racing game chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 451,
                "product_name": "olin upholstered side chair"
            },
            {
                "product_id": 22838,
                "product_name": "nathalie solid wood queen anne back side chair in stone grey"
            },
            {
                "product_id": 32404,
                "product_name": "smythe 3 piece living room set"
            },
            {
                "product_id": 16796,
                "product_name": "haings upholstered parsons chair"
            },
            {
                "product_id": 23173,
                "product_name": "35 '' wide armchair"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 16981,
                "product_name": "hafsa velvet task chair"
            },
            {
                "product_id": 26887,
                "product_name": "capems 65.35 '' faux leather pillow top arm sleeper"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 25227,
                "product_name": "office ergonomic polyurethane conference chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 15818,
                "product_name": "trio square pillow cover & insert"
            },
            {
                "product_id": 41028,
                "product_name": "kathrine cobblestone decorative lumbar pillow"
            },
            {
                "product_id": 22975,
                "product_name": "casner console table"
            },
            {
                "product_id": 23899,
                "product_name": "ishani ikat throw pillow cover"
            },
            {
                "product_id": 40265,
                "product_name": "gerika 3 piece solid wood bracket shelf"
            }
        ],
        "query": "queen ann style living room chair"
    },
    {
        "id": 173,
        "label": [
            {
                "product_id": 4614,
                "product_name": "poulan 4 piece bathroom accessories set"
            },
            {
                "product_id": 41564,
                "product_name": "sleep accessories mattress pad"
            },
            {
                "product_id": 42327,
                "product_name": "accessory type"
            },
            {
                "product_id": 26291,
                "product_name": "rossland stainless steel 7 piece bathroom accessory set"
            },
            {
                "product_id": 26301,
                "product_name": "fornah 4 piece bathroom accessory set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 26289,
                "product_name": "sea foam mosaic glass/steel 8 piece bathroom accessory set"
            },
            {
                "product_id": 990,
                "product_name": "ethan 3 piece bathroom accessory set"
            },
            {
                "product_id": 34924,
                "product_name": "ludington apron front kitchen sink with accessories"
            },
            {
                "product_id": 16117,
                "product_name": "diva 2-piece bathroom accessory set"
            },
            {
                "product_id": 31191,
                "product_name": "wacousta 6 piece bathroom accessory set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 13957,
                "product_name": "galeton tv stand for tvs up to 88 ''"
            },
            {
                "product_id": 16256,
                "product_name": "vidal ultra soft & plush dinosaurs fleece throw"
            }
        ],
        "garOutput": [
            {
                "product_id": 7295,
                "product_name": "fomo bedford upholstered panel headboard"
            },
            {
                "product_id": 7292,
                "product_name": "fitzsimmons panel headboard"
            },
            {
                "product_id": 7294,
                "product_name": "cambridge upholstered panel headboard"
            },
            {
                "product_id": 16763,
                "product_name": "benedick 3 piece quilt set"
            },
            {
                "product_id": 12043,
                "product_name": "sloth fitted sheet"
            }
        ],
        "query": "bedroom accessories"
    },
    {
        "id": 272,
        "label": [
            {
                "product_id": 8956,
                "product_name": "gabilan upholstered parsons chair"
            },
            {
                "product_id": 10045,
                "product_name": "warwickshire linen queen anne back parsons chair in beige"
            },
            {
                "product_id": 40544,
                "product_name": "danise tufted velvet upholstered parsons chair"
            },
            {
                "product_id": 28710,
                "product_name": "lemelle linen upholstered parsons chair"
            },
            {
                "product_id": 36696,
                "product_name": "leia upholstered parsons chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 29581,
                "product_name": "hopkint tufted upholstered parsons chair"
            },
            {
                "product_id": 40082,
                "product_name": "moffatt upholstered parsons chair"
            },
            {
                "product_id": 39371,
                "product_name": "tiffany upholstered parsons chair in light cappucino"
            },
            {
                "product_id": 26149,
                "product_name": "galilea 24.75 '' wide polyester parsons chair"
            },
            {
                "product_id": 24705,
                "product_name": "padillo linen upholstered parsons chair in beige"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 14501,
                "product_name": "26 '' wide side chair"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 34987,
                "product_name": "shrestha upholstered parsons chair in gray"
            }
        ],
        "garOutput": [
            {
                "product_id": 30042,
                "product_name": "simon tree face"
            },
            {
                "product_id": 8152,
                "product_name": "the owl by nicklas gustafsson - wrapped canvas graphic art print"
            },
            {
                "product_id": 41276,
                "product_name": "altieri 29.1 '' wide genuine leather butterfly chair"
            },
            {
                "product_id": 21220,
                "product_name": "aalto 16 oz . cereal bowl"
            },
            {
                "product_id": 16515,
                "product_name": "i like your crown by eli halpin - wrapped canvas print"
            }
        ],
        "query": "oliver parsons"
    },
    {
        "id": 381,
        "label": [
            {
                "product_id": 8676,
                "product_name": "30 '' 900 cfm convertible wall mount range hood"
            },
            {
                "product_id": 3294,
                "product_name": "30 '' 441 cfm ducted wall mount range hood"
            },
            {
                "product_id": 19705,
                "product_name": "36 '' 1065 cfm ducted wall mount range hood"
            },
            {
                "product_id": 42471,
                "product_name": "42 '' 1200 cfm ducted wall mount range hood in stainless steel"
            },
            {
                "product_id": 7132,
                "product_name": "29.375 '' 600 cfm convertible under cabinet range hood stainless steel"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 42469,
                "product_name": "30 '' professional 1200 cfm ducted wall mount range hood in stainless steel"
            },
            {
                "product_id": 1302,
                "product_name": "30 '' 343 cfm convertible wall mount range hood"
            },
            {
                "product_id": 2942,
                "product_name": "30 '' supreme 1000 cfm ducted under cabinet range hood in stainless steel with remote control included"
            },
            {
                "product_id": 40147,
                "product_name": "30 '' 350 cfm convertible wall mount range hood"
            },
            {
                "product_id": 42490,
                "product_name": "48 '' hand crafted designer wood island hood series 700 cfm ducted range hood in walnut"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 3728,
                "product_name": "bless our home metal sign"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 42966,
                "product_name": "mershon 2-light outdoor bulkhead light"
            },
            {
                "product_id": 8227,
                "product_name": "aemilia 4 door accent cabinet"
            },
            {
                "product_id": 30594,
                "product_name": "aguie 43.31 '' steel bed frame"
            }
        ],
        "garOutput": [
            {
                "product_id": 38132,
                "product_name": "geometric teal indoor / outdoor area rug"
            },
            {
                "product_id": 35029,
                "product_name": "barney 3 piece seating group with cushions"
            },
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 13852,
                "product_name": "modern shell-shaped backrest accent chair for living room bedroom metal leg , velvet , grey"
            },
            {
                "product_id": 6995,
                "product_name": "bike/motorcycle hand air pump"
            }
        ],
        "query": "farmhouse hood vent"
    },
    {
        "id": 77,
        "label": [
            {
                "product_id": 23861,
                "product_name": "fortuna 33.5 '' wide tufted polyester armchair"
            },
            {
                "product_id": 5174,
                "product_name": "senna 31 '' wide tufted velvet armchair"
            },
            {
                "product_id": 29178,
                "product_name": "roseanna 29 '' wide armchair"
            },
            {
                "product_id": 34360,
                "product_name": "gershom 32 '' wide armchair"
            },
            {
                "product_id": 33980,
                "product_name": "joplin 20.47 '' wide armchair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 42163,
                "product_name": "nelly 24.8 '' wide genuine leather swivel armchair"
            },
            {
                "product_id": 2205,
                "product_name": "buckden 26.7 '' wide tufted linen armchair"
            },
            {
                "product_id": 1230,
                "product_name": "faux leather armchair"
            },
            {
                "product_id": 14865,
                "product_name": "31 '' wide tufted polyester armchair"
            },
            {
                "product_id": 11182,
                "product_name": "focht 20 '' wide velvet armchair"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            }
        ],
        "garOutput": [
            {
                "product_id": 42534,
                "product_name": "essonne kids chair"
            },
            {
                "product_id": 1606,
                "product_name": "gaiana 12.6 '' console table"
            },
            {
                "product_id": 4312,
                "product_name": "medium coffee table"
            },
            {
                "product_id": 18210,
                "product_name": "dane solid wood end table with storage"
            },
            {
                "product_id": 5548,
                "product_name": "keefer square cube ottoman"
            }
        ],
        "query": "sancroft armchair"
    },
    {
        "id": 448,
        "label": [
            {
                "product_id": 5018,
                "product_name": "sherburn end table"
            },
            {
                "product_id": 2974,
                "product_name": "arcola 1 drawer nightstand"
            },
            {
                "product_id": 3078,
                "product_name": "saltford twisted teak side table"
            },
            {
                "product_id": 31590,
                "product_name": "nadeau solid wood end table with storage"
            },
            {
                "product_id": 3738,
                "product_name": "rothesay solid wood side table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 1607,
                "product_name": "johanson end table"
            },
            {
                "product_id": 23541,
                "product_name": "seese end table"
            },
            {
                "product_id": 18363,
                "product_name": "ziebarth end table"
            },
            {
                "product_id": 39081,
                "product_name": "axinia side table with storage"
            },
            {
                "product_id": 243,
                "product_name": "circula low side table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 30158,
                "product_name": "hershberger buffet table"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 5959,
                "product_name": "marple 42 '' wide 2 drawer server"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 13504,
                "product_name": "teak tripod end table"
            },
            {
                "product_id": 20370,
                "product_name": "31.5 '' square fold-in-half portable folding table"
            },
            {
                "product_id": 16482,
                "product_name": "strock 10 '' w console table"
            },
            {
                "product_id": 41491,
                "product_name": "kymber round cotton pouf"
            },
            {
                "product_id": 28512,
                "product_name": "arjunveer sleeper bed tufted ottoman"
            }
        ],
        "query": "bed side table"
    },
    {
        "id": 9,
        "label": [
            {
                "product_id": 2280,
                "product_name": "mikula block coffee table with storage"
            },
            {
                "product_id": 38856,
                "product_name": "aidric coffee table"
            },
            {
                "product_id": 8423,
                "product_name": "colm 4 legs coffee table with storage"
            },
            {
                "product_id": 17594,
                "product_name": "southside frame coffee table with storage"
            },
            {
                "product_id": 39720,
                "product_name": "lucio coffee table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 601,
                "product_name": "solid wood coffee table"
            },
            {
                "product_id": 14753,
                "product_name": "jessie coffee table"
            },
            {
                "product_id": 4959,
                "product_name": "laymoune abstract coffee table"
            },
            {
                "product_id": 1264,
                "product_name": "dipietro drum coffee table with storage"
            },
            {
                "product_id": 294,
                "product_name": "aalst coffee table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8740,
                "product_name": "alessandro rectangular multi-tiered plant stand"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 8753,
                "product_name": "4 tier plant stand garden planter metal flower pot rack corner shelving indoor outdoor"
            },
            {
                "product_id": 8752,
                "product_name": "3 tier corner plant stand foldable nesting flower pot holder elegant metal plant rack for indoor outdoor garden patio3"
            }
        ],
        "garOutput": [
            {
                "product_id": 1618,
                "product_name": "outdoor polyresin propane fire pit table"
            },
            {
                "product_id": 38714,
                "product_name": "donnie rocking chair"
            },
            {
                "product_id": 20370,
                "product_name": "31.5 '' square fold-in-half portable folding table"
            },
            {
                "product_id": 28606,
                "product_name": "oris 33 '' wide velvet power lift assist standard recliner"
            },
            {
                "product_id": 2460,
                "product_name": "welton glass top coffee table"
            }
        ],
        "query": "coffee table fire pit"
    },
    {
        "id": 285,
        "label": [
            {
                "product_id": 15366,
                "product_name": "mirrored manufactured wood glass lace sliding closet door"
            },
            {
                "product_id": 6895,
                "product_name": "humphrey 56 '' - 60 '' w x 76 '' h door opening sliding semi-frameless shower door"
            },
            {
                "product_id": 31189,
                "product_name": "bi-fold top mount sliding standard single track barn door hardware kit"
            },
            {
                "product_id": 15367,
                "product_name": "glass sliding closet doors with installation hardware kit"
            },
            {
                "product_id": 13261,
                "product_name": "baldarassario glass sliding closet door with installation hardware kit"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 331,
                "product_name": "non-bypass standard single track barn door hardware kit"
            },
            {
                "product_id": 34032,
                "product_name": "felicia glass sliding closet door"
            },
            {
                "product_id": 38177,
                "product_name": "glass barn 60 '' w x 76 '' h single sliding frameless shower door"
            },
            {
                "product_id": 20235,
                "product_name": "sliding standard double door barn door hardware kit"
            },
            {
                "product_id": 34043,
                "product_name": "lucia glass french doors"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 12253,
                "product_name": "sydney kitchen cart with butcher block top"
            },
            {
                "product_id": 30594,
                "product_name": "aguie 43.31 '' steel bed frame"
            },
            {
                "product_id": 14495,
                "product_name": "twin over full bunk bed"
            },
            {
                "product_id": 32482,
                "product_name": "krause 16 '' height profile platform bed"
            },
            {
                "product_id": 42966,
                "product_name": "mershon 2-light outdoor bulkhead light"
            }
        ],
        "garOutput": [
            {
                "product_id": 23386,
                "product_name": "alignright cabinet hardware mounting kit"
            },
            {
                "product_id": 20355,
                "product_name": "thassos 4 '' x 4 '' mosaic tile"
            },
            {
                "product_id": 16408,
                "product_name": "3 '' center to center bar pull multipack"
            },
            {
                "product_id": 3224,
                "product_name": "infinity 44 '' x 65.5 '' frameless bypass sliding shower door"
            },
            {
                "product_id": 38140,
                "product_name": "durrani 2 piece kitchen canister set"
            }
        ],
        "query": "48\" sliding single track , barn door for laundry"
    },
    {
        "id": 439,
        "label": [
            {
                "product_id": 38288,
                "product_name": "natalia task chair"
            },
            {
                "product_id": 42402,
                "product_name": "colette task chair"
            },
            {
                "product_id": 30908,
                "product_name": "recliner patio chair"
            },
            {
                "product_id": 42148,
                "product_name": "mistic leather arm chair"
            },
            {
                "product_id": 752,
                "product_name": "holleman stacking side chair in clear"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 32573,
                "product_name": "cattaraugus arm chair in clear"
            },
            {
                "product_id": 27029,
                "product_name": "james mid-century dining chair"
            },
            {
                "product_id": 42386,
                "product_name": "washington 23.25 '' wide slipper chair"
            },
            {
                "product_id": 17654,
                "product_name": "marez upholstered dining chair"
            },
            {
                "product_id": 5574,
                "product_name": "forney dining chair in natural wood"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            }
        ],
        "garOutput": [
            {
                "product_id": 37312,
                "product_name": "norvin solid wood side chair"
            },
            {
                "product_id": 42439,
                "product_name": "emrecan tufted velvet side chair"
            },
            {
                "product_id": 182,
                "product_name": "caccia by luigi caccia dominioni cake / pastry server"
            },
            {
                "product_id": 24778,
                "product_name": "wallington parsons chair"
            },
            {
                "product_id": 22384,
                "product_name": "repousse cake knife"
            }
        ],
        "query": "parsons chairs"
    },
    {
        "id": 303,
        "label": [
            {
                "product_id": 14074,
                "product_name": "canadian provinces - wrapped canvas graphic art print"
            },
            {
                "product_id": 20652,
                "product_name": "wood graphic art print on canvas"
            },
            {
                "product_id": 9689,
                "product_name": "ocean waves in hawaii - 3 piece graphic art on wrapped canvas set"
            },
            {
                "product_id": 12435,
                "product_name": "hawaii napali by anderson design group framed vintage advertisement"
            },
            {
                "product_id": 11616,
                "product_name": "honolulu hawaii # 4 - unframed panoramic photograph print on canvas"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 12176,
                "product_name": "barack - graphic art print"
            },
            {
                "product_id": 37489,
                "product_name": "skyline series : honolulu , hawaii , usa on beige painting print on wrapped canvas"
            },
            {
                "product_id": 42544,
                "product_name": "graphic art canvas art"
            },
            {
                "product_id": 12170,
                "product_name": "llama in a vintage bathtub with rubber ducky by amy peterson - graphic art print"
            },
            {
                "product_id": 29887,
                "product_name": "albie hawaii sunset canvas art"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 13098,
                "product_name": "premium heavyweight 9 '' melamine salad or dessert plate"
            },
            {
                "product_id": 9761,
                "product_name": "scaggs 6 piece bathroom accessory set"
            },
            {
                "product_id": 33797,
                "product_name": "strait wood 3 '' x 12 '' ceramic subway tile in blue/black"
            }
        ],
        "garOutput": [
            {
                "product_id": 13978,
                "product_name": "classic lace 2-gang toggle light switch wall plate"
            },
            {
                "product_id": 13975,
                "product_name": "classic lace 1-gang toggle light switch wall plate"
            },
            {
                "product_id": 13972,
                "product_name": "classic beadboard 3-gang toggle light switch wall plate"
            },
            {
                "product_id": 13970,
                "product_name": "classic beadboard 1-gang toggle light switch wall plate"
            },
            {
                "product_id": 13971,
                "product_name": "classic beadboard 2-gang toggle light switch wall plate"
            }
        ],
        "query": "hawaii vintage art"
    },
    {
        "id": 30,
        "label": [
            {
                "product_id": 35455,
                "product_name": "tom tom 3 '' center to center bar pull"
            },
            {
                "product_id": 16410,
                "product_name": "3 1/2 '' center bar pull multipack"
            },
            {
                "product_id": 18950,
                "product_name": "cabinet center to center or drawer iron 2 5/8 '' center to center bin pull"
            },
            {
                "product_id": 35457,
                "product_name": "tableau square 2 1/2 '' center to center bar pull"
            },
            {
                "product_id": 31963,
                "product_name": "traditional metal pull 3 '' center to center arch pull"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 12326,
                "product_name": "edge 3 3/4 '' center to center finger pull"
            },
            {
                "product_id": 12933,
                "product_name": "spindle 3 1/2 '' center to center arch pull"
            },
            {
                "product_id": 35456,
                "product_name": "tableau round 3 '' center to center arch pull"
            },
            {
                "product_id": 19691,
                "product_name": "solid brass 3 1/2 '' center to center bar pull"
            },
            {
                "product_id": 23415,
                "product_name": "12 '' center to center bar pull"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 14106,
                "product_name": "veronica 5 drawer chest"
            },
            {
                "product_id": 21200,
                "product_name": "cutts bar with wine storage"
            },
            {
                "product_id": 17737,
                "product_name": "donovan twin daybed with trundle"
            },
            {
                "product_id": 3291,
                "product_name": "zootles drawer pull multipack"
            }
        ],
        "garOutput": [
            {
                "product_id": 32065,
                "product_name": "5 ft. w x 3 ft. d plastic horizontal tool shed"
            },
            {
                "product_id": 22443,
                "product_name": "double hamper foldable laundry basket"
            },
            {
                "product_id": 22635,
                "product_name": "oakland 11 ft. w x 7.5 ft. d plastic storage shed"
            },
            {
                "product_id": 37208,
                "product_name": "minifile storage cabinet"
            },
            {
                "product_id": 20609,
                "product_name": "deca mobile printer stand"
            }
        ],
        "query": "3 1/2 inch drawer pull"
    },
    {
        "id": 93,
        "label": [
            {
                "product_id": 33137,
                "product_name": "7-piece patio furniture set , outdoor sectional sofa couch , handwoven pe wicker rattan patio conversation set , with cushions and glass table , brown and gray"
            },
            {
                "product_id": 31666,
                "product_name": "outdoor patio furniture set , 5-piece wicker rattan sectional sofa set"
            },
            {
                "product_id": 6985,
                "product_name": "outdoor patio 7 piece rattan sectional seating group with cushions"
            },
            {
                "product_id": 15699,
                "product_name": "patio furniture round outdoor sectional sofa set rattan daybed sunbed with retractable canopy , separate seating and removable cushion ( beige )"
            },
            {
                "product_id": 17855,
                "product_name": "kennison patio sectional with cushions"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 39115,
                "product_name": "joakima 85 '' wide outdoor patio sectional with cushion"
            },
            {
                "product_id": 7973,
                "product_name": "merlin outdoor furniture 7 piece rattan sectional seating group with cushions"
            },
            {
                "product_id": 26190,
                "product_name": "jalissa teak patio sectional with cushions"
            },
            {
                "product_id": 31913,
                "product_name": "gayston 44.8 '' wide outdoor left hand facing patio sectional with cushions"
            },
            {
                "product_id": 41337,
                "product_name": "deminous patio sectional with cushions"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 13956,
                "product_name": "deauville coffee table"
            },
            {
                "product_id": 13955,
                "product_name": "erachidia coffee table"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 33604,
                "product_name": "home office mesh task chair"
            },
            {
                "product_id": 37788,
                "product_name": "home office ergonomic chair"
            },
            {
                "product_id": 22314,
                "product_name": "aadhira recliner patio chair with cushions"
            },
            {
                "product_id": 36916,
                "product_name": "pvc 48 '' * 36 '' chair floor mat office protector carpet with lip"
            },
            {
                "product_id": 42227,
                "product_name": "badrig 39.3 '' console table"
            }
        ],
        "query": "outdoor sectional dining"
    },
    {
        "id": 55,
        "label": [
            {
                "product_id": 26163,
                "product_name": "tomas chevron rug to tomas chevron dark blue/gray rug indoor / outdoor area rug"
            },
            {
                "product_id": 6193,
                "product_name": "doyle oriental teal indoor/outdoor area rug"
            },
            {
                "product_id": 7218,
                "product_name": "ellenton brown/ivory indoor/outdoor area rug"
            },
            {
                "product_id": 17870,
                "product_name": "weigand geometric teal area rug"
            },
            {
                "product_id": 798,
                "product_name": "tollette teal outdoor area rug"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 7739,
                "product_name": "heady memory foam teal area rug"
            },
            {
                "product_id": 20106,
                "product_name": "leonora bohemian textured weave floral indoor/outdoor teal area rug"
            },
            {
                "product_id": 6192,
                "product_name": "preston oriental teal indoor/outdoor area rug"
            },
            {
                "product_id": 25528,
                "product_name": "michaela geometric braided light gray indoor / outdoor area rug"
            },
            {
                "product_id": 17860,
                "product_name": "murgo morocco trellis teal indoor/outdoor area rug"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 42543,
                "product_name": "hartland abc barnyard power loomed green/red area rug"
            },
            {
                "product_id": 7839,
                "product_name": "citadel sunflower fields cheese board"
            },
            {
                "product_id": 18012,
                "product_name": "aditi patio 3 piece seating group with cushions"
            },
            {
                "product_id": 12350,
                "product_name": "rossmere indoor/outdoor bench cushion"
            },
            {
                "product_id": 25830,
                "product_name": "must love dogs 29 in . x 18 in . non-slip outdoor door mat"
            }
        ],
        "garOutput": [
            {
                "product_id": 9270,
                "product_name": "engeline kitchen mat"
            },
            {
                "product_id": 3954,
                "product_name": "geometric print outdoor square pillow cover and insert"
            },
            {
                "product_id": 30659,
                "product_name": "dichiera super soft plush luxury lightweight fleece blanket"
            },
            {
                "product_id": 12418,
                "product_name": "tollett power loom blue rug"
            },
            {
                "product_id": 25346,
                "product_name": "bofonchik merriby curtain panel"
            }
        ],
        "query": "tollette teal outdoor rug"
    },
    {
        "id": 429,
        "label": [
            {
                "product_id": 5477,
                "product_name": "socorro 6 drawer double dresser"
            },
            {
                "product_id": 25256,
                "product_name": "sqm 5 drawer dresser"
            },
            {
                "product_id": 37,
                "product_name": "2 drawer standard dresser"
            },
            {
                "product_id": 33114,
                "product_name": "abequa 5 drawer standard dresser"
            },
            {
                "product_id": 9921,
                "product_name": "carlisa 7 drawer combo dresser"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 1156,
                "product_name": "lissandra 7 drawer combo dresser"
            },
            {
                "product_id": 35198,
                "product_name": "fusion 5 drawer chest"
            },
            {
                "product_id": 8233,
                "product_name": "alsen 8-drawer dresser"
            },
            {
                "product_id": 9285,
                "product_name": "ortez 7 drawer double dresser"
            },
            {
                "product_id": 843,
                "product_name": "stonge 6 drawer double dresser"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 11055,
                "product_name": "harcrest writing desk"
            },
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 11168,
                "product_name": "sanya anchor wall hanging"
            },
            {
                "product_id": 41247,
                "product_name": "stem print by rae dunn 5 qt . tea jar"
            }
        ],
        "garOutput": [
            {
                "product_id": 23376,
                "product_name": "decorative notched 3 '' center to center bar pull"
            },
            {
                "product_id": 33974,
                "product_name": "novelty knob"
            },
            {
                "product_id": 30083,
                "product_name": "home sweet home tops wall d\u00e9cor"
            },
            {
                "product_id": 4613,
                "product_name": "manzelli wall mirror mirror"
            },
            {
                "product_id": 31417,
                "product_name": "dedrick modern vintage map single duvet cover"
            }
        ],
        "query": "gray dresser"
    },
    {
        "id": 33,
        "label": [
            {
                "product_id": 27168,
                "product_name": "black and gray montauk 6 '' x 24 '' natural stone look floor use tile"
            },
            {
                "product_id": 30236,
                "product_name": "high street random size porcelain mosaic wall & floor tile"
            },
            {
                "product_id": 27276,
                "product_name": "elegance 8 '' x 10 '' porcelain marble look wall & floor tile"
            },
            {
                "product_id": 15094,
                "product_name": "mood 24 '' x 47 '' porcelain stone look wall & floor tile"
            },
            {
                "product_id": 11458,
                "product_name": "0.8 '' x 0.8 '' porcelain penny round mosaic wall & floor tile"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 40239,
                "product_name": "urban 0.88 '' x 0.88 '' porcelain grid mosaic wall & floor tile"
            },
            {
                "product_id": 30240,
                "product_name": "party time semi-polished 12 '' x 24 '' porcelain wall & floor tile"
            },
            {
                "product_id": 35715,
                "product_name": "1 '' x 2 '' marble mosaic wall & floor tile"
            },
            {
                "product_id": 12773,
                "product_name": "retro 0.88 '' x 0.88 '' porcelain mosaic wall & floor tile"
            },
            {
                "product_id": 27172,
                "product_name": "domino 2 '' x 3 '' porcelain herringbone mosaic wall & floor tile"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 3197,
                "product_name": "double shower curtain hooks"
            },
            {
                "product_id": 39991,
                "product_name": "bathroom rain volume shower system rough-in valve"
            },
            {
                "product_id": 38224,
                "product_name": "riggins 24 '' single bathroom vanity set"
            },
            {
                "product_id": 24966,
                "product_name": "nita single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 19708,
                "product_name": "waterfall single hole bathroom faucet"
            }
        ],
        "garOutput": [
            {
                "product_id": 3978,
                "product_name": "reine 3 '' x 12 '' ceramic field tile"
            },
            {
                "product_id": 33738,
                "product_name": "lokahi brume random sized glass pearl shell mosaic tile in gold"
            },
            {
                "product_id": 33743,
                "product_name": "lokahi 0.66 '' x 0.66 '' glass pearl shell mosaic tile in white"
            },
            {
                "product_id": 26844,
                "product_name": "paris 1 '' x 3 '' porcelain brick joint mosaic tile"
            },
            {
                "product_id": 33744,
                "product_name": "lokahi 0.39 '' x 0.79 '' seashell random mosaic wall & floor tile"
            }
        ],
        "query": "non slip shower floor tile"
    },
    {
        "id": 206,
        "label": [
            {
                "product_id": 33426,
                "product_name": "grill cover - fits up to 39 ''"
            },
            {
                "product_id": 28887,
                "product_name": "gas grill cover"
            },
            {
                "product_id": 2167,
                "product_name": "grill cover - fits up to 58 ''"
            },
            {
                "product_id": 15895,
                "product_name": "ncaa grill cover - fits up to 60 ''"
            },
            {
                "product_id": 15067,
                "product_name": "pickett grill cover - fits up to 70 ''"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 36511,
                "product_name": "outdoor bbq grill cover - fits up to 73 ''"
            },
            {
                "product_id": 39237,
                "product_name": "wayfair basics bbq grill cover - fits up to 61 ''"
            },
            {
                "product_id": 34706,
                "product_name": "grill cover - fits up to 12 ''"
            },
            {
                "product_id": 36729,
                "product_name": "tuyeho grill cover - fits up to 58 ''"
            },
            {
                "product_id": 13664,
                "product_name": "cs42 grill cover - fits up to 42 ''"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 3291,
                "product_name": "zootles drawer pull multipack"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 22032,
                "product_name": "dining table with leaf"
            },
            {
                "product_id": 16370,
                "product_name": "stikki clips white 20 per pack"
            }
        ],
        "garOutput": [
            {
                "product_id": 31189,
                "product_name": "bi-fold top mount sliding standard single track barn door hardware kit"
            },
            {
                "product_id": 31188,
                "product_name": "bi-fold sliding standard single track barn door hardware kit"
            },
            {
                "product_id": 15582,
                "product_name": "jolin outdoor firewood log rack"
            },
            {
                "product_id": 37611,
                "product_name": "squire wall mounted paper towel holder"
            },
            {
                "product_id": 42824,
                "product_name": "wayfair basics\u00ae angelica 3 3/4 '' center to center bar pull"
            }
        ],
        "query": "grill cover 73"
    },
    {
        "id": 181,
        "label": [
            {
                "product_id": 35971,
                "product_name": "5.5 watt ( 60 watt equivalent ) , c11 led , dimmable light bulb , warm white ( 2700 ) e12/candelabra base"
            },
            {
                "product_id": 30193,
                "product_name": "40 watt g16.5 incandescent , dimmable light bulb , warm white ( 2700k ) e12/candelabra base"
            },
            {
                "product_id": 25188,
                "product_name": "mcswain 4 watt ( 40 watt equivalent ) b10 led dimmable light bulb warm white e12/candelabra base"
            },
            {
                "product_id": 7755,
                "product_name": "4 watt ( 40 watt equivalent ) , g16.5 led , dimmable light bulb , warm white ( 2700k ) e12/candelabra base"
            },
            {
                "product_id": 20401,
                "product_name": "4 watt ( 60 watt equivalent ) , b11 led , dimmable light bulb , e12/candelabra base"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 35980,
                "product_name": "5.5 watt ( 60 watt equivalent ) , c11 led dimmable light bulb , e12/candelabra base"
            },
            {
                "product_id": 40751,
                "product_name": "25 watt equivalent blue e12/candelabra led light bulb"
            },
            {
                "product_id": 2379,
                "product_name": "40w ( 40 watt equivalent ) g16.5 dimmable incandescent light bulb , warm white e12/candelabra base"
            },
            {
                "product_id": 7108,
                "product_name": "5 watt ( 60 watt equivalent ) , ca11 led , dimmable light bulb , warrm white ( 2700k ) e12/candelabra base"
            },
            {
                "product_id": 21983,
                "product_name": "4 watt ( 40 watt equivalent ) , b11 led , dimmable light bulb , daylight e12/candelabra base"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20085,
                "product_name": "dario 12 - light candle style wagon wheel chandelier"
            },
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 13535,
                "product_name": "pottery framed graphic art print on canvas"
            },
            {
                "product_id": 32518,
                "product_name": "elite rack"
            },
            {
                "product_id": 8573,
                "product_name": "14 '' oil powered outdoor lantern"
            }
        ],
        "garOutput": [
            {
                "product_id": 23636,
                "product_name": "shaylee 8 - light candle style empire chandelier"
            },
            {
                "product_id": 23634,
                "product_name": "shaylee 5 - light candle style empire chandelier"
            },
            {
                "product_id": 23635,
                "product_name": "shaylee 6 - light candle style empire chandelier"
            },
            {
                "product_id": 39139,
                "product_name": "ellena 2 - light brushed nickel wall sconce light"
            },
            {
                "product_id": 35336,
                "product_name": "harlan 5 - light unique / statement drum chandelier"
            }
        ],
        "query": "e12/candelabra"
    },
    {
        "id": 472,
        "label": [
            {
                "product_id": 17982,
                "product_name": "damiano pediment overdoor wall d\u00e9cor"
            },
            {
                "product_id": 41439,
                "product_name": "black gold wall d\u00e9cor"
            },
            {
                "product_id": 27720,
                "product_name": "shabby architectural window wall decor"
            },
            {
                "product_id": 37647,
                "product_name": "leaf wall decor"
            },
            {
                "product_id": 17467,
                "product_name": "solid wood window frame wall d\u00e9cor"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 5134,
                "product_name": "metal window pane design wall decor with 6 panes , black"
            },
            {
                "product_id": 32435,
                "product_name": "crossed window wall d\u00e9cor"
            },
            {
                "product_id": 7655,
                "product_name": "contemporary wall d\u00e9cor"
            },
            {
                "product_id": 21327,
                "product_name": "little neck gecko wall d\u00e9cor"
            },
            {
                "product_id": 18194,
                "product_name": "starburst wall decor"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 12350,
                "product_name": "rossmere indoor/outdoor bench cushion"
            },
            {
                "product_id": 908,
                "product_name": "chauncy rooster weathervane on wood base statue"
            },
            {
                "product_id": 34491,
                "product_name": "swipe 49.9 '' console table"
            },
            {
                "product_id": 1654,
                "product_name": "aaiyana striped sheer grommet single curtain panel"
            },
            {
                "product_id": 1657,
                "product_name": "solid color sheer grommet single curtain panel"
            }
        ],
        "garOutput": [
            {
                "product_id": 12854,
                "product_name": "marseille 18 '' x 18 '' ceramic stone look wall & floor tile"
            },
            {
                "product_id": 24025,
                "product_name": "french ribbon 1 5/8 '' h x 94 1/2 '' w x 3/4 '' d chair rail"
            },
            {
                "product_id": 26418,
                "product_name": "beckner jali end table"
            },
            {
                "product_id": 9996,
                "product_name": "colorado 36 '' x 24 '' metal wall paneling"
            },
            {
                "product_id": 6818,
                "product_name": "5.4 '' w x 48 '' l solid wood wall paneling"
            }
        ],
        "query": "window wall accent"
    },
    {
        "id": 70,
        "label": [
            {
                "product_id": 33129,
                "product_name": "dany 5 drawer double dresser"
            },
            {
                "product_id": 33132,
                "product_name": "edwige 6 drawer double dresser"
            },
            {
                "product_id": 35421,
                "product_name": "amy-jayne 6 drawer dresser"
            },
            {
                "product_id": 20367,
                "product_name": "aaron 7 drawer dresser"
            },
            {
                "product_id": 5108,
                "product_name": "hadi beveled magnifying dresser mirror"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 15787,
                "product_name": "ail\u00ecs 5 drawer dresser"
            },
            {
                "product_id": 13138,
                "product_name": "millhouse 5 drawer dresser"
            },
            {
                "product_id": 8861,
                "product_name": "anees 7 drawer dresser"
            },
            {
                "product_id": 34409,
                "product_name": "vachel 6 drawer double dresser"
            },
            {
                "product_id": 34207,
                "product_name": "carrico 5 drawer dresser"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 42966,
                "product_name": "mershon 2-light outdoor bulkhead light"
            },
            {
                "product_id": 17223,
                "product_name": "buffet storage cabinet with single glass doors and unique bell handle"
            },
            {
                "product_id": 38224,
                "product_name": "riggins 24 '' single bathroom vanity set"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 16482,
                "product_name": "strock 10 '' w console table"
            },
            {
                "product_id": 13504,
                "product_name": "teak tripod end table"
            },
            {
                "product_id": 6096,
                "product_name": "longley 5 drawer storage chest"
            },
            {
                "product_id": 20361,
                "product_name": "d\u00e9borah coffee table"
            },
            {
                "product_id": 41999,
                "product_name": "batterton 25 '' h x 24.8 '' w x 11.8 '' d storage shelves"
            }
        ],
        "query": "annex dresser"
    },
    {
        "id": 265,
        "label": [
            {
                "product_id": 6270,
                "product_name": "sumter upholstered storage sleigh bed"
            },
            {
                "product_id": 4681,
                "product_name": "taylor cove upholstered standard bed"
            },
            {
                "product_id": 39620,
                "product_name": "rennan queen upholstered platform bed"
            },
            {
                "product_id": 42937,
                "product_name": "lafever upholstered sleigh bed"
            },
            {
                "product_id": 5951,
                "product_name": "ide tufted upholstered low profile standard bed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 40193,
                "product_name": "beagan wood upholstered storage platform bed"
            },
            {
                "product_id": 5001,
                "product_name": "lavaca upholstered platform bed"
            },
            {
                "product_id": 25491,
                "product_name": "kelly upholstered storage platform bed"
            },
            {
                "product_id": 16576,
                "product_name": "jorstad upholstered standard bed"
            },
            {
                "product_id": 29474,
                "product_name": "kellett upholstered platform bed"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 25494,
                "product_name": "brittany tufted upholstered platform bed"
            },
            {
                "product_id": 40512,
                "product_name": "acamar upholstered standard bed"
            },
            {
                "product_id": 41201,
                "product_name": "belluno tufted upholstered platform bed"
            },
            {
                "product_id": 20587,
                "product_name": "frederiksborg upholstered storage platform bed"
            },
            {
                "product_id": 38279,
                "product_name": "mendez upholstered low profile standard bed"
            }
        ],
        "query": "candace wingback upholstered bed"
    },
    {
        "id": 104,
        "label": [
            {
                "product_id": 18755,
                "product_name": "prep canisters 3 piece coffee , flour , & sugar set"
            },
            {
                "product_id": 7543,
                "product_name": "1 qt . kitchen canister"
            },
            {
                "product_id": 26442,
                "product_name": "1.8 qt . canning jar"
            },
            {
                "product_id": 23550,
                "product_name": "simms 15 oz . single canister masonware sugar dispenser"
            },
            {
                "product_id": 25960,
                "product_name": "soho 1.75 qt . sugar jar"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 30260,
                "product_name": "glass mason 12 piece canning jar set"
            },
            {
                "product_id": 27612,
                "product_name": "mind reader 3 piece kitchen canister set"
            },
            {
                "product_id": 31513,
                "product_name": "newmont 3 piece kitchen canister set"
            },
            {
                "product_id": 15946,
                "product_name": "ceramic 4 piece kitchen canister set"
            },
            {
                "product_id": 15990,
                "product_name": "1.56 qt . sugar jar"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            },
            {
                "product_id": 31096,
                "product_name": "fasttrack multi purpose shelf"
            },
            {
                "product_id": 37200,
                "product_name": "3-tier removable tilted metal/wire basket"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            },
            {
                "product_id": 37201,
                "product_name": "3 piece metal/wire basket set"
            }
        ],
        "garOutput": [
            {
                "product_id": 29670,
                "product_name": "enameled 3 piece coffee , tea , & sugar jar set"
            },
            {
                "product_id": 41524,
                "product_name": "williamsburg metal wall shelf"
            },
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 15689,
                "product_name": "63.5 '' h x 36 '' w x 14.25 '' d commercial 8-tier storage shelves"
            },
            {
                "product_id": 39644,
                "product_name": "evas hall tree"
            }
        ],
        "query": "flour and sugar containers"
    },
    {
        "id": 482,
        "label": [
            {
                "product_id": 42486,
                "product_name": "30 '' 400 cfm convertible wall mount range hood in brushed 430 stainless steel"
            },
            {
                "product_id": 3293,
                "product_name": "30 '' 217 cfm convertible wall mount range hood in black painted stainless steel"
            },
            {
                "product_id": 22647,
                "product_name": "48 '' professional 900cfm ducted island range hood"
            },
            {
                "product_id": 42475,
                "product_name": "30 '' professional stainless 1200 cfm ducted wall mount range hood in brushed 430 stainless steel"
            },
            {
                "product_id": 42489,
                "product_name": "30 '' hand-crafted designer wood 400 cfm ducted wall mount range hood"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 8675,
                "product_name": "30 '' 400 cfm ducted wall mount range hood"
            },
            {
                "product_id": 32113,
                "product_name": "30 '' 41000 series ductless under cabinet range hood"
            },
            {
                "product_id": 31193,
                "product_name": "36 '' 1140 cfm ducted wall mount range hood"
            },
            {
                "product_id": 8679,
                "product_name": "29.53 '' 900 cfm convertible wall mount range hood"
            },
            {
                "product_id": 40148,
                "product_name": "30 '' 350 cfm convertible wall mount range hood"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 39991,
                "product_name": "bathroom rain volume shower system rough-in valve"
            },
            {
                "product_id": 1654,
                "product_name": "aaiyana striped sheer grommet single curtain panel"
            },
            {
                "product_id": 1657,
                "product_name": "solid color sheer grommet single curtain panel"
            },
            {
                "product_id": 38224,
                "product_name": "riggins 24 '' single bathroom vanity set"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            }
        ],
        "garOutput": [
            {
                "product_id": 3367,
                "product_name": "farrell indoor/outdoor stainless steel tension rod ceiling mount/joiner curtain mounting bracket"
            },
            {
                "product_id": 41048,
                "product_name": "56 '' abbra 3 - blade standard ceiling fan with wall control"
            },
            {
                "product_id": 26844,
                "product_name": "paris 1 '' x 3 '' porcelain brick joint mosaic tile"
            },
            {
                "product_id": 9294,
                "product_name": "granderson 2 door storage cabinet"
            },
            {
                "product_id": 26845,
                "product_name": "1 '' x 6 '' porcelain mosaic sheet tile"
            }
        ],
        "query": "48 inch kitchen hood"
    },
    {
        "id": 364,
        "label": [
            {
                "product_id": 31604,
                "product_name": "ashton counter height dining table"
            },
            {
                "product_id": 31231,
                "product_name": "ashton ridge floral bedding sham"
            },
            {
                "product_id": 41032,
                "product_name": "ashton 1 - light single geometric pendant"
            },
            {
                "product_id": 12631,
                "product_name": "ashton 6.13 '' h x 4 '' w x 2.38 '' d corbel"
            },
            {
                "product_id": 3328,
                "product_name": "ashton sham"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 42508,
                "product_name": "croscill valentina boudoir pillow 22x11 , navy"
            },
            {
                "product_id": 27479,
                "product_name": "ashton coverlet set"
            },
            {
                "product_id": 9354,
                "product_name": "ashton art & d\u00e9cor large framed painting print"
            },
            {
                "product_id": 7256,
                "product_name": "long ashton single reversible quilt"
            },
            {
                "product_id": 2947,
                "product_name": "ashton art & d\u00e9cor embellished peacock i framed painting print"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            }
        ],
        "garOutput": [
            {
                "product_id": 6699,
                "product_name": "samara wall shelf"
            },
            {
                "product_id": 11574,
                "product_name": "weidman luxury hotel hemstitch 14 '' bed skirt"
            },
            {
                "product_id": 6700,
                "product_name": "samara 2 piece wall shelf set"
            },
            {
                "product_id": 29279,
                "product_name": "astaire upholstered platform bed"
            },
            {
                "product_id": 8845,
                "product_name": "sufyaan 7 drawer dresser"
            }
        ],
        "query": "croscill ashton"
    },
    {
        "id": 326,
        "label": [
            {
                "product_id": 11055,
                "product_name": "harcrest writing desk"
            },
            {
                "product_id": 33662,
                "product_name": "finley 47 '' writing desk with hutch"
            },
            {
                "product_id": 42381,
                "product_name": "lefebre writing desk and chair set"
            },
            {
                "product_id": 8995,
                "product_name": "boucher 39.4 '' w writing desk"
            },
            {
                "product_id": 8797,
                "product_name": "rottapel writing desk"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 19502,
                "product_name": "swann study 28.3 '' writing desk and chair set"
            },
            {
                "product_id": 204,
                "product_name": "brookshire writing desk"
            },
            {
                "product_id": 30139,
                "product_name": "rainey writing desk"
            },
            {
                "product_id": 41389,
                "product_name": "desk"
            },
            {
                "product_id": 41295,
                "product_name": "thebault leaning/ladder desk"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 13957,
                "product_name": "galeton tv stand for tvs up to 88 ''"
            },
            {
                "product_id": 19717,
                "product_name": "commercial single hole bathroom faucet with drain assembly"
            }
        ],
        "garOutput": [
            {
                "product_id": 34231,
                "product_name": "cecil ladder desk"
            },
            {
                "product_id": 32251,
                "product_name": "aljay writing desk"
            },
            {
                "product_id": 36213,
                "product_name": "woodley height adjustable standing desk converter"
            },
            {
                "product_id": 25055,
                "product_name": "voorhies ladder desk"
            },
            {
                "product_id": 29348,
                "product_name": "bramblett glass l-shape desk"
            }
        ],
        "query": "bowersville ladder writing desk"
    },
    {
        "id": 306,
        "label": [
            {
                "product_id": 235,
                "product_name": "14 '' adjustable bed base with wireless remote"
            },
            {
                "product_id": 37826,
                "product_name": "kira 13 '' massaging zero gravity adjustable bed with wireless remote"
            },
            {
                "product_id": 4766,
                "product_name": "full size platform bed with adjustable trundle , gray"
            },
            {
                "product_id": 17232,
                "product_name": "janell 15.7 '' massaging zero gravity adjustable bed with wireless remote"
            },
            {
                "product_id": 30060,
                "product_name": "verge 15 '' massaging zero gravity adjustable bed with wireless remote"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 2069,
                "product_name": "mobley 15 '' zero gravity adjustable bed with wireless remote"
            },
            {
                "product_id": 233,
                "product_name": "15 '' massaging zero gravity adjustable bed with wireless remote"
            },
            {
                "product_id": 7416,
                "product_name": "14.5 '' massaging zero gravity adjustable bed with wireless remote"
            },
            {
                "product_id": 231,
                "product_name": "15 '' adjustable bed base"
            },
            {
                "product_id": 42894,
                "product_name": "zinus 14 '' adjustable bed with remote"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 2312,
                "product_name": "twin size platform bed with adjustable trundle , espresso"
            },
            {
                "product_id": 20888,
                "product_name": "mowat 41 '' wide power lift assist recliner"
            },
            {
                "product_id": 14495,
                "product_name": "twin over full bunk bed"
            },
            {
                "product_id": 36038,
                "product_name": "rimini 35.4 '' wide faux leather power lift assist recliner"
            },
            {
                "product_id": 27409,
                "product_name": "dayonna 32.3 '' wide power lift assist standard recliner"
            }
        ],
        "garOutput": [
            {
                "product_id": 30577,
                "product_name": "filebrook free standing umbrella weight"
            },
            {
                "product_id": 33615,
                "product_name": "transport 5 - shelf storage cabinet"
            },
            {
                "product_id": 21521,
                "product_name": "vintage riser mount soap dish"
            },
            {
                "product_id": 30517,
                "product_name": "kronqui bed and control hypoallergenic zippered mattress protector"
            },
            {
                "product_id": 22090,
                "product_name": "universal elastic dining chair covers 3d bubble lattice slipcover with skirt ( set of 6 ) -dining chair"
            }
        ],
        "query": "mobley zero gravity adjustable bed with wireless remote"
    },
    {
        "id": 0,
        "label": [
            {
                "product_id": 23530,
                "product_name": "pennock 37 '' wide convertible chair"
            },
            {
                "product_id": 21456,
                "product_name": "35 '' wide velvet papasan chair"
            },
            {
                "product_id": 33465,
                "product_name": "set of 2 counter height chair , 24-inch height , brown"
            },
            {
                "product_id": 13833,
                "product_name": "diannah 24 '' wide barrel chair"
            },
            {
                "product_id": 21386,
                "product_name": "kids 23 '' writing desk and chair set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 24151,
                "product_name": "santa barbara 32 '' wide tufted barrel chair"
            },
            {
                "product_id": 39125,
                "product_name": "modern new soft velvet material ergonomics accent chair living room chair bedroom chair home chair with gold legs and more stabler adjustable legs for indoor home ( pink )"
            },
            {
                "product_id": 37530,
                "product_name": "mccarley patio chair"
            },
            {
                "product_id": 5458,
                "product_name": "mabie upholstered side chair in silver"
            },
            {
                "product_id": 22536,
                "product_name": "alfredo 26 '' wide tufted velvet side chair"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 13957,
                "product_name": "galeton tv stand for tvs up to 88 ''"
            },
            {
                "product_id": 13956,
                "product_name": "deauville coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 38719,
                "product_name": "briarhill patio reclining chaise lounge with cushion"
            },
            {
                "product_id": 22002,
                "product_name": "symons task chair"
            },
            {
                "product_id": 1498,
                "product_name": "allegany bonded leather upholstered dining chair"
            },
            {
                "product_id": 5380,
                "product_name": "levin wooden rocking chair"
            },
            {
                "product_id": 28730,
                "product_name": "burnam 65 '' leather match pillow top arm loveseat"
            }
        ],
        "query": "salon chair"
    },
    {
        "id": 397,
        "label": [
            {
                "product_id": 12238,
                "product_name": "gomes tufted upholstered low profile standard bed"
            },
            {
                "product_id": 5689,
                "product_name": "grise tufted upholstered storage platform bed"
            },
            {
                "product_id": 36219,
                "product_name": "aonan tufted upholstered standard bed"
            },
            {
                "product_id": 8828,
                "product_name": "ammara tufted upholstered standard bed"
            },
            {
                "product_id": 25998,
                "product_name": "vasilisa tufted upholstered standard bed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 25996,
                "product_name": "adelphia tufted upholstered standard bed"
            },
            {
                "product_id": 36844,
                "product_name": "fenny queen tufted upholstered low profile standard bed"
            },
            {
                "product_id": 36831,
                "product_name": "dioselyn queen tufted upholstered standard bed"
            },
            {
                "product_id": 36840,
                "product_name": "hak twin tufted upholstered standard bed"
            },
            {
                "product_id": 5684,
                "product_name": "norwich tufted upholstered low profile storage platform bed"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 25557,
                "product_name": "connolly tufted upholstered low profile standard bed"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 27333,
                "product_name": "berengere 4 '' cotton futon mattress"
            },
            {
                "product_id": 27336,
                "product_name": "vintage floral japanese 4 '' cotton futon mattress"
            }
        ],
        "garOutput": [
            {
                "product_id": 28632,
                "product_name": "avak daybed with trundle"
            },
            {
                "product_id": 4569,
                "product_name": "fareedah daybed with trundle"
            },
            {
                "product_id": 17541,
                "product_name": "duax twin bed with trundle"
            },
            {
                "product_id": 8471,
                "product_name": "chapp daybed"
            },
            {
                "product_id": 2079,
                "product_name": "43 '' armless sofa bed"
            }
        ],
        "query": "maryford queen tufted bed"
    },
    {
        "id": 11,
        "label": [
            {
                "product_id": 20098,
                "product_name": "stith power loom plum rug"
            },
            {
                "product_id": 26913,
                "product_name": "hillsby garnet/navy area rug"
            },
            {
                "product_id": 41896,
                "product_name": "salvator tufted wool saffron/black area rug"
            },
            {
                "product_id": 4658,
                "product_name": "chanler hand-tufted wool brown/black area rug"
            },
            {
                "product_id": 26387,
                "product_name": "korn power loom burgundy rug"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 15500,
                "product_name": "balderas gray/gold rug"
            },
            {
                "product_id": 32078,
                "product_name": "hammett hand-tufted beige/gray area rug"
            },
            {
                "product_id": 16499,
                "product_name": "rugsotic carpets hand-knotted silk beige/black area rug"
            },
            {
                "product_id": 40113,
                "product_name": "cedric oriental ebony area rug set"
            },
            {
                "product_id": 14382,
                "product_name": "modern abstract blue ombre abrash runner"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 20085,
                "product_name": "dario 12 - light candle style wagon wheel chandelier"
            }
        ],
        "garOutput": [
            {
                "product_id": 30015,
                "product_name": "wiersma luxurious off white area rug"
            },
            {
                "product_id": 39613,
                "product_name": "moore power loom red/beige rug"
            },
            {
                "product_id": 38965,
                "product_name": "moore multi-color area rug"
            },
            {
                "product_id": 41154,
                "product_name": "lady bird natural gray indoor/outdoor area rug"
            },
            {
                "product_id": 18645,
                "product_name": "tinsel trees black fir artificial christmas tree"
            }
        ],
        "query": "ombre rug"
    },
    {
        "id": 386,
        "label": [
            {
                "product_id": 16517,
                "product_name": "braemar chaise lounge leisure chair rest sofa chaise lounge couch for indoor living room furniture home"
            },
            {
                "product_id": 23266,
                "product_name": "poplin 36 in . vanity with furniture legs , 1 door and 3 drawers on right"
            },
            {
                "product_id": 3732,
                "product_name": "outdoor 4 piece aluminum sofa conversation set , patio furniture seating group with cushions"
            },
            {
                "product_id": 33137,
                "product_name": "7-piece patio furniture set , outdoor sectional sofa couch , handwoven pe wicker rattan patio conversation set , with cushions and glass table , brown and gray"
            },
            {
                "product_id": 30132,
                "product_name": "bawtry accent chest"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 23969,
                "product_name": "patio outdoor rattan furniture 4 pieces"
            },
            {
                "product_id": 35624,
                "product_name": "kenwick protective 5 piece water resistant patio furniture cover set"
            },
            {
                "product_id": 1219,
                "product_name": "unvivesal bed and furniture riser"
            },
            {
                "product_id": 6493,
                "product_name": "4 piece patio sectional wicker rattan outdoor furniture sofa set with storage box"
            },
            {
                "product_id": 42374,
                "product_name": "haleigh bar & counter stool"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 16419,
                "product_name": "deco 3 3/4 '' center to center bar pull"
            },
            {
                "product_id": 16448,
                "product_name": "deco 3 3/4 '' center bar pull multipack"
            },
            {
                "product_id": 11055,
                "product_name": "harcrest writing desk"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 18943,
                "product_name": "sink leg"
            }
        ],
        "garOutput": [
            {
                "product_id": 33974,
                "product_name": "novelty knob"
            },
            {
                "product_id": 11675,
                "product_name": "frosted waste basket"
            },
            {
                "product_id": 19922,
                "product_name": "shonna 25.2 '' w closet rod"
            },
            {
                "product_id": 20348,
                "product_name": "aguiar stacking plastic 12.05 '' storage drawer"
            },
            {
                "product_id": 20237,
                "product_name": "standard single barn door hardware kit"
            }
        ],
        "query": "alter furniture"
    },
    {
        "id": 436,
        "label": [
            {
                "product_id": 10803,
                "product_name": "shaunte stackable twin platform bed"
            },
            {
                "product_id": 1880,
                "product_name": "marjorie full platform bed"
            },
            {
                "product_id": 17541,
                "product_name": "duax twin bed with trundle"
            },
            {
                "product_id": 38426,
                "product_name": "stig full over full solid wood standard bunk bed with trundle"
            },
            {
                "product_id": 26026,
                "product_name": "arval trundle unit"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 11383,
                "product_name": "dunleavy twin bed with trundle"
            },
            {
                "product_id": 38430,
                "product_name": "shyann staircase twin over full bunk bed with shelves"
            },
            {
                "product_id": 28884,
                "product_name": "barham cube queen murphy bed with mattress"
            },
            {
                "product_id": 34540,
                "product_name": "platform bed with adjustable trundle , gray , twin size"
            },
            {
                "product_id": 38521,
                "product_name": "bellana trundle unit"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 25557,
                "product_name": "connolly tufted upholstered low profile standard bed"
            },
            {
                "product_id": 14495,
                "product_name": "twin over full bunk bed"
            },
            {
                "product_id": 37192,
                "product_name": "wood platform bed with two drawers , full ( white )"
            },
            {
                "product_id": 10078,
                "product_name": "finbar twin over twin bunk bed"
            },
            {
                "product_id": 37189,
                "product_name": "metal bed with wood decoration \uff08twin size\uff09"
            }
        ],
        "garOutput": [
            {
                "product_id": 5520,
                "product_name": "miah 9 '' steel platform bed"
            },
            {
                "product_id": 28512,
                "product_name": "arjunveer sleeper bed tufted ottoman"
            },
            {
                "product_id": 1283,
                "product_name": "nioka bunk bed"
            },
            {
                "product_id": 6108,
                "product_name": "mccaffery 74 '' round arm sofa"
            },
            {
                "product_id": 17539,
                "product_name": "78.35 '' square arm sleeper"
            }
        ],
        "query": "full bed with trundle"
    },
    {
        "id": 383,
        "label": [
            {
                "product_id": 33008,
                "product_name": "vermiculite"
            },
            {
                "product_id": 35925,
                "product_name": "flexarm sng"
            },
            {
                "product_id": 30432,
                "product_name": "vieques"
            }
        ],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 6053,
                "product_name": "hot water dispenser"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 20085,
                "product_name": "dario 12 - light candle style wagon wheel chandelier"
            }
        ],
        "garOutput": [
            {
                "product_id": 17343,
                "product_name": "maier north carolina melamine tray"
            },
            {
                "product_id": 22635,
                "product_name": "oakland 11 ft. w x 7.5 ft. d plastic storage shed"
            },
            {
                "product_id": 11720,
                "product_name": "bagdad meditating buddha gautama amitabha in varada mudra"
            },
            {
                "product_id": 16234,
                "product_name": "firelined safe combination and electronic lock"
            },
            {
                "product_id": 42606,
                "product_name": "giannino kitchen cart"
            }
        ],
        "query": "minnestrista"
    },
    {
        "id": 117,
        "label": [
            {
                "product_id": 31258,
                "product_name": "scholl storage cabinet"
            },
            {
                "product_id": 2414,
                "product_name": "olivas 60 '' kitchen pantry"
            },
            {
                "product_id": 22626,
                "product_name": "66 '' h x 27 '' w x 15 '' d tall utility storage cabinet"
            },
            {
                "product_id": 8225,
                "product_name": "steel storage cabinet , 3 shelf metal storage cabinet with 2 adjustable shelves and lockable doors"
            },
            {
                "product_id": 39393,
                "product_name": "closet rack shelf organizer 27 pair shoe storage cabinet"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 7896,
                "product_name": "cendrillon 2 - shelf storage cabinet"
            },
            {
                "product_id": 22464,
                "product_name": "4-shelf printer stand wood filing mobile storage file cabinet with wheels"
            },
            {
                "product_id": 6086,
                "product_name": "series c 1 - shelf storage cabinet"
            },
            {
                "product_id": 41720,
                "product_name": "entryway 18 pair shoe storage cabinet"
            },
            {
                "product_id": 30179,
                "product_name": "6 - drawer storage cabinet"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8227,
                "product_name": "aemilia 4 door accent cabinet"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 21200,
                "product_name": "cutts bar with wine storage"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 22626,
                "product_name": "66 '' h x 27 '' w x 15 '' d tall utility storage cabinet"
            },
            {
                "product_id": 7261,
                "product_name": "trower 34.3 '' h x 27.9 '' w x 13.6 '' d foldable storage shelves"
            },
            {
                "product_id": 13953,
                "product_name": "elam bed risers"
            },
            {
                "product_id": 20625,
                "product_name": "rolling storage cart"
            },
            {
                "product_id": 110,
                "product_name": "west harptree 34 '' w garment rack"
            }
        ],
        "query": "tall storage cabinet"
    },
    {
        "id": 307,
        "label": [
            {
                "product_id": 13044,
                "product_name": "real touch flower tulips centerpiece in vase"
            },
            {
                "product_id": 25381,
                "product_name": "artificial orchids floral arrangement in planter"
            },
            {
                "product_id": 6905,
                "product_name": "faux roses floral arrangements and centerpiece in vase"
            },
            {
                "product_id": 25665,
                "product_name": "faux phalaenopsis orchid floral arrangement in acrylic glass vase"
            },
            {
                "product_id": 13037,
                "product_name": "18 heads roses floral arrangement and centerpiece in vase"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 3410,
                "product_name": "cymbidium orchid and artificial succulents floral arrangement in pot"
            },
            {
                "product_id": 35359,
                "product_name": "triple dancing lady silk orchids floral arrangements in pot"
            },
            {
                "product_id": 6555,
                "product_name": "peonies floral arrangements and centerpieces in glass vase"
            },
            {
                "product_id": 37796,
                "product_name": "artificial phalaenopsis orchids floral arrangement in pot"
            },
            {
                "product_id": 9499,
                "product_name": "real touch magnolia , berry & cypress mixed floral arrangement in glass vase"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 1179,
                "product_name": "real touch bouquet peonies stems"
            },
            {
                "product_id": 16454,
                "product_name": "arshen welcome daisies metal garden sign"
            },
            {
                "product_id": 9734,
                "product_name": "white orchids on monique fiberglass bowl"
            },
            {
                "product_id": 41432,
                "product_name": "happiness wall mounted calendar board"
            },
            {
                "product_id": 890,
                "product_name": "sapphira reversible quilt set"
            }
        ],
        "garOutput": [
            {
                "product_id": 15162,
                "product_name": "river pebbles"
            },
            {
                "product_id": 12100,
                "product_name": "holiday chevron sheer rod pocket single curtain panel"
            },
            {
                "product_id": 42754,
                "product_name": "ines geometric ivory/gray area rug"
            },
            {
                "product_id": 37452,
                "product_name": "aloe vera floor stem"
            },
            {
                "product_id": 24714,
                "product_name": "22 '' sunflower wreath"
            }
        ],
        "query": "real touch roses floral arrangements in glass vase"
    },
    {
        "id": 174,
        "label": [
            {
                "product_id": 5204,
                "product_name": "barrett multicolor area rug"
            },
            {
                "product_id": 9140,
                "product_name": "oloughlin geometric purple area rug"
            },
            {
                "product_id": 9138,
                "product_name": "delcastillo geometric purple area rug"
            },
            {
                "product_id": 12098,
                "product_name": "green/red/gray area rug"
            },
            {
                "product_id": 9150,
                "product_name": "rodrigue geometric purple area rug"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 9137,
                "product_name": "geometric purple area rug"
            },
            {
                "product_id": 22768,
                "product_name": "corina soccer time area rug"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 625,
                "product_name": "star wars rogue one shoretrooper\u2122 life-sized cardboard cutout"
            },
            {
                "product_id": 624,
                "product_name": "star wars rogue one moroff\u2122 life-sized cardboard cutout"
            },
            {
                "product_id": 623,
                "product_name": "star wars rogue one stormtroppers\u2122 life-sized cardboard cutout"
            },
            {
                "product_id": 622,
                "product_name": "star wars rogue one jyn erso\u2122 life-sized cardboard cutout"
            },
            {
                "product_id": 626,
                "product_name": "star wars rogue one chirrut \u00eemwe\u2122 life-sized cardboard cutout"
            }
        ],
        "garOutput": [
            {
                "product_id": 32602,
                "product_name": "popular characters star wars classic wall decal"
            },
            {
                "product_id": 3756,
                "product_name": "duggins brontosaurus dinosaur coffee mug"
            },
            {
                "product_id": 40265,
                "product_name": "gerika 3 piece solid wood bracket shelf"
            },
            {
                "product_id": 40266,
                "product_name": "gisli 2 piece solid wood tiered shelf"
            },
            {
                "product_id": 38577,
                "product_name": "fresh soap and water bath tub bathroom design - graphic art print"
            }
        ],
        "query": "star wars rug"
    },
    {
        "id": 479,
        "label": [
            {
                "product_id": 12288,
                "product_name": "wall mounted mailbox"
            },
            {
                "product_id": 7119,
                "product_name": "allux locking wall mounted mailbox"
            },
            {
                "product_id": 35026,
                "product_name": "uptown retrobox arctic post mounted mailbox"
            },
            {
                "product_id": 15780,
                "product_name": "marshall large , locking , steel , post-mount mailbox , black"
            },
            {
                "product_id": 34011,
                "product_name": "15 in x 14 in steel wall mounted mailbox"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 30569,
                "product_name": "post mounted mailbox"
            },
            {
                "product_id": 549,
                "product_name": "12 in x 5.5 in steel mail slot"
            },
            {
                "product_id": 3804,
                "product_name": "oasis locking post mounted mailbox"
            },
            {
                "product_id": 27381,
                "product_name": "emily industrial style post wall mounted mailbox"
            },
            {
                "product_id": 7846,
                "product_name": "21 door front load 4c horizontal mail center"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 36032,
                "product_name": "deeksha storage cabinet"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 14495,
                "product_name": "twin over full bunk bed"
            }
        ],
        "garOutput": [
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 27495,
                "product_name": "1-up hard cover pet brag album"
            },
            {
                "product_id": 2182,
                "product_name": "3-tier printer cart with storage shelf mobile printer stand end table home office"
            },
            {
                "product_id": 38132,
                "product_name": "geometric teal indoor / outdoor area rug"
            },
            {
                "product_id": 35981,
                "product_name": "neon strip rope lights"
            }
        ],
        "query": "garage door mail slot"
    },
    {
        "id": 18,
        "label": [
            {
                "product_id": 21983,
                "product_name": "4 watt ( 40 watt equivalent ) , b11 led , dimmable light bulb , daylight e12/candelabra base"
            },
            {
                "product_id": 33903,
                "product_name": "boornazian 4-light dimmable vanity light"
            },
            {
                "product_id": 21158,
                "product_name": "donovan bathroom fixture 4-light dimmable vanity light"
            },
            {
                "product_id": 39373,
                "product_name": "42 '' alayna 4 - blade ceiling fan with pull chain and light kit included"
            },
            {
                "product_id": 29574,
                "product_name": "steiger 4-light vanity light"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 13259,
                "product_name": "frankton bathroom 3-light dimmable vanity light"
            },
            {
                "product_id": 36178,
                "product_name": "encarnacion 4-light dimmable vanity light"
            },
            {
                "product_id": 40459,
                "product_name": "betta modern 3-light vanity light"
            },
            {
                "product_id": 40413,
                "product_name": "aurore 4 - light drum chandelier with wrought iron accents"
            },
            {
                "product_id": 19085,
                "product_name": "34 '' fennec 4 - blade caged ceiling fan with wall control and light kit included"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 25869,
                "product_name": "42 '' single bathroom vanity"
            },
            {
                "product_id": 3197,
                "product_name": "double shower curtain hooks"
            },
            {
                "product_id": 37727,
                "product_name": "villante 24 '' single bathroom vanity set"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 19729,
                "product_name": "waterfall single hole bathroom faucet with drain assembly"
            }
        ],
        "garOutput": [
            {
                "product_id": 520,
                "product_name": "1 3/8 '' length sunflower knob"
            },
            {
                "product_id": 3642,
                "product_name": "lupe 4-light dimmable vanity light"
            },
            {
                "product_id": 33273,
                "product_name": "basketweave gray powered led outdoor lantern"
            },
            {
                "product_id": 36443,
                "product_name": "3 piece metal and wood wall sconce"
            },
            {
                "product_id": 11024,
                "product_name": "skunk and bubbles ceramic night light"
            }
        ],
        "query": "chrome bathroom 4 light vanity light"
    },
    {
        "id": 337,
        "label": [
            {
                "product_id": 6707,
                "product_name": "8.75 oz . dessert bowl"
            },
            {
                "product_id": 19852,
                "product_name": "ultima thule 56 fl oz . serving bowl"
            },
            {
                "product_id": 18000,
                "product_name": "sini 12 oz . dessert bowl"
            },
            {
                "product_id": 6673,
                "product_name": "serving bowl"
            },
            {
                "product_id": 3948,
                "product_name": "ashton 1 fl oz . fruit bowl"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 21219,
                "product_name": "taika 20 oz . siimes cereal bowl"
            },
            {
                "product_id": 19846,
                "product_name": "origo 5 oz . rice bowl"
            },
            {
                "product_id": 28432,
                "product_name": "accompanist 10 oz . dessert bowl"
            },
            {
                "product_id": 15742,
                "product_name": "vernet sea turtle melamine serving bowl"
            },
            {
                "product_id": 41941,
                "product_name": "chesley 10 oz . melamine dessert bowl"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 17343,
                "product_name": "maier north carolina melamine tray"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 17342,
                "product_name": "maiden lane great lakes melamine tray"
            },
            {
                "product_id": 30921,
                "product_name": "zanna owl bluetooth speaker statue"
            }
        ],
        "garOutput": [
            {
                "product_id": 21214,
                "product_name": "miranda glass decorative bowl"
            },
            {
                "product_id": 16216,
                "product_name": "bernadotte cake server"
            },
            {
                "product_id": 21210,
                "product_name": "ultima thule 9 oz . dessert bowl"
            },
            {
                "product_id": 21541,
                "product_name": "napoleon bee 8 oz . glass goblet"
            },
            {
                "product_id": 747,
                "product_name": "rosemount chaise lounge"
            }
        ],
        "query": "iittala bowl"
    },
    {
        "id": 186,
        "label": [
            {
                "product_id": 8116,
                "product_name": "hazlewood road storage cabinet"
            },
            {
                "product_id": 16932,
                "product_name": "hanging storage cabinet & hook wall shelf"
            },
            {
                "product_id": 33189,
                "product_name": "dukene 2 - drawer storage cabinet"
            },
            {
                "product_id": 42215,
                "product_name": "3 - shelf filing storage cabinet"
            },
            {
                "product_id": 31496,
                "product_name": "howard armoire"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 35186,
                "product_name": "helsy 2 - drawer 2 - shelf filing credenza"
            },
            {
                "product_id": 37088,
                "product_name": "ekram 2 - shelf storage cabinet"
            },
            {
                "product_id": 4765,
                "product_name": "storage cabinet"
            },
            {
                "product_id": 1214,
                "product_name": "incept storage cabinet"
            },
            {
                "product_id": 9294,
                "product_name": "granderson 2 door storage cabinet"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 36032,
                "product_name": "deeksha storage cabinet"
            },
            {
                "product_id": 17223,
                "product_name": "buffet storage cabinet with single glass doors and unique bell handle"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            }
        ],
        "garOutput": [
            {
                "product_id": 39465,
                "product_name": "white 49 '' x 87 '' portable folding frame projector screen"
            },
            {
                "product_id": 23064,
                "product_name": "35 '' 4 - player oak card table"
            },
            {
                "product_id": 39475,
                "product_name": "home use kids desk and chair set with 5-layer desktop"
            },
            {
                "product_id": 39419,
                "product_name": "3d movie white 100 '' portable projection screen"
            },
            {
                "product_id": 20625,
                "product_name": "rolling storage cart"
            }
        ],
        "query": "board game storage cabinet"
    },
    {
        "id": 90,
        "label": [
            {
                "product_id": 33471,
                "product_name": "biergh patio sofa with cushions"
            },
            {
                "product_id": 14793,
                "product_name": "grandfield stance outdoor 5 piece rattan sofa seating group set with cushions"
            },
            {
                "product_id": 35602,
                "product_name": "menifee patio sofa with cushions"
            },
            {
                "product_id": 27407,
                "product_name": "bretta patio sofa with cushions"
            },
            {
                "product_id": 20925,
                "product_name": "beo 74.8 '' square arm patio sofa"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 15272,
                "product_name": "secil outdoor 5 piece sofa seating group with cushions"
            },
            {
                "product_id": 15270,
                "product_name": "aderyn outdoor 6 piece sofa seating group with cushions"
            },
            {
                "product_id": 28692,
                "product_name": "yland patio sofa with cushions"
            },
            {
                "product_id": 11390,
                "product_name": "79 '' wide patio sofa with sunbrella cushions"
            },
            {
                "product_id": 33137,
                "product_name": "7-piece patio furniture set , outdoor sectional sofa couch , handwoven pe wicker rattan patio conversation set , with cushions and glass table , brown and gray"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 18012,
                "product_name": "aditi patio 3 piece seating group with cushions"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 26887,
                "product_name": "capems 65.35 '' faux leather pillow top arm sleeper"
            },
            {
                "product_id": 12350,
                "product_name": "rossmere indoor/outdoor bench cushion"
            }
        ],
        "garOutput": [
            {
                "product_id": 253,
                "product_name": "28 '' square standard ottoman"
            },
            {
                "product_id": 21935,
                "product_name": "archambault patio sofa with cushions"
            },
            {
                "product_id": 5069,
                "product_name": "amice 102 '' wide symmetrical modular sectional"
            },
            {
                "product_id": 4980,
                "product_name": "habiba 31 '' square standard ottoman"
            },
            {
                "product_id": 37485,
                "product_name": "lizeth 58 '' linen square arm sofa bed"
            }
        ],
        "query": "filaret outdoor sofa"
    },
    {
        "id": 224,
        "label": [
            {
                "product_id": 10077,
                "product_name": "kason twin over full bunk bed"
            },
            {
                "product_id": 2110,
                "product_name": "rimstone twin over full bunk bed"
            },
            {
                "product_id": 18828,
                "product_name": "miacomet twin over full l solid wood bunk bed with bookcase and shelves"
            },
            {
                "product_id": 2111,
                "product_name": "twin over full bunk bed"
            },
            {
                "product_id": 18820,
                "product_name": "belchertown twin over twin solid wood standard bunk bed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 18840,
                "product_name": "santa barbara full over full bunk bed with trundle and 4 drawers"
            },
            {
                "product_id": 24199,
                "product_name": "ruairi twin over full bunk bed"
            },
            {
                "product_id": 18811,
                "product_name": "devante full over full bed with trundle"
            },
            {
                "product_id": 19237,
                "product_name": "radburn twin over full bunk bed"
            },
            {
                "product_id": 18836,
                "product_name": "eddy twin over full solid wood standard bunk bed with trundle by farmhouse baby & kids"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 14495,
                "product_name": "twin over full bunk bed"
            },
            {
                "product_id": 13623,
                "product_name": "2 person traditional steam sauna"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 24199,
                "product_name": "ruairi twin over full bunk bed"
            },
            {
                "product_id": 10075,
                "product_name": "hajrah full over full bunk bed with trundle"
            }
        ],
        "garOutput": [
            {
                "product_id": 9006,
                "product_name": "takako twin bed with trundle"
            },
            {
                "product_id": 2498,
                "product_name": "70 '' h pivot frameless tub door"
            },
            {
                "product_id": 18996,
                "product_name": "daybed with trundle"
            },
            {
                "product_id": 18932,
                "product_name": "hervin twin platform bed"
            },
            {
                "product_id": 8122,
                "product_name": "ferebee 22 '' wide slipper chair"
            }
        ],
        "query": "twin over full bunk beds cool desins"
    },
    {
        "id": 39,
        "label": [
            {
                "product_id": 8570,
                "product_name": "organizer wooden for earrings necklaces wall mounted jewelry holder"
            },
            {
                "product_id": 31754,
                "product_name": "stack em earrings jewelry organizer tray"
            },
            {
                "product_id": 22380,
                "product_name": "etoy over the door jewelry armoire with mirror"
            },
            {
                "product_id": 17777,
                "product_name": "davidson kitchen over cabinet door organizer"
            },
            {
                "product_id": 13993,
                "product_name": "jewelry organizer tray"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 17272,
                "product_name": "large synthetic necklace ring storage organizer jewelry box"
            },
            {
                "product_id": 31325,
                "product_name": "pro aluminum jewelry box cosmetic organizer"
            },
            {
                "product_id": 8430,
                "product_name": "cassell touch screen led light door and wall mounted jewelry armoire with mirror"
            },
            {
                "product_id": 22413,
                "product_name": "cyril home jewelry makeup organizer"
            },
            {
                "product_id": 10753,
                "product_name": "zakhar free standing jewelry armoire with mirror"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 39407,
                "product_name": "allicen 2 -drawer storage cabinet"
            },
            {
                "product_id": 9624,
                "product_name": "military challenge coin cabinet rack holder display case"
            },
            {
                "product_id": 36032,
                "product_name": "deeksha storage cabinet"
            },
            {
                "product_id": 21200,
                "product_name": "cutts bar with wine storage"
            },
            {
                "product_id": 14381,
                "product_name": "drawer large ring pull"
            }
        ],
        "garOutput": [
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 27495,
                "product_name": "1-up hard cover pet brag album"
            },
            {
                "product_id": 29670,
                "product_name": "enameled 3 piece coffee , tea , & sugar jar set"
            },
            {
                "product_id": 38132,
                "product_name": "geometric teal indoor / outdoor area rug"
            },
            {
                "product_id": 27810,
                "product_name": "sayers damask 8 piece comforter set"
            }
        ],
        "query": "door jewelry organizer"
    },
    {
        "id": 328,
        "label": [
            {
                "product_id": 23795,
                "product_name": "lovejoy dining table"
            },
            {
                "product_id": 23805,
                "product_name": "stahl butterfly leaf dining table"
            },
            {
                "product_id": 23826,
                "product_name": "haleigh 48 '' pedestal dining table"
            },
            {
                "product_id": 22684,
                "product_name": "dornan 38 '' dining table"
            },
            {
                "product_id": 5723,
                "product_name": "francesca dining table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 29093,
                "product_name": "gladyce extendable dining table"
            },
            {
                "product_id": 23670,
                "product_name": "araby trestle dining table"
            },
            {
                "product_id": 2076,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 1503,
                "product_name": "emestine extendable drop leaf dining table"
            },
            {
                "product_id": 9012,
                "product_name": "callaghan 40 '' solid wood dining table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 1029,
                "product_name": "candelario bar with wine storage"
            },
            {
                "product_id": 13956,
                "product_name": "deauville coffee table"
            },
            {
                "product_id": 13955,
                "product_name": "erachidia coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 32461,
                "product_name": "saroyan 2 piece coffee table set"
            },
            {
                "product_id": 3072,
                "product_name": "flossmoor 24.75 '' bar stool"
            },
            {
                "product_id": 31729,
                "product_name": "rosehill sled coffee table"
            },
            {
                "product_id": 40384,
                "product_name": "chaparosa 2 drawer dresser"
            },
            {
                "product_id": 35611,
                "product_name": "outdoor replacement cushion"
            }
        ],
        "query": "golub dining table"
    },
    {
        "id": 466,
        "label": [
            {
                "product_id": 41000,
                "product_name": "child climbing and rocking hors swing set"
            },
            {
                "product_id": 30477,
                "product_name": "hobbies and activities rock climber"
            },
            {
                "product_id": 6687,
                "product_name": "and swing set toddler playground rock climbing toy set"
            },
            {
                "product_id": 33685,
                "product_name": "rock climbing rocks"
            },
            {
                "product_id": 41824,
                "product_name": "5-in-1 child climbing and rocking horse climber"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 3290,
                "product_name": "plastic climbing rocks"
            },
            {
                "product_id": 17907,
                "product_name": "2-in-1 child climbing and rocking hors"
            },
            {
                "product_id": 33686,
                "product_name": "climbing rock"
            },
            {
                "product_id": 30958,
                "product_name": "child climbing and rocking hors"
            },
            {
                "product_id": 12500,
                "product_name": "textured playground rock wall climbing hand holds for kids"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 29144,
                "product_name": "mcdonald dinosaur lake canvas art"
            },
            {
                "product_id": 30477,
                "product_name": "hobbies and activities rock climber"
            },
            {
                "product_id": 8751,
                "product_name": "large kids play house heavy duty canvas cotton with floor mat for bedroom indoor outdoor"
            },
            {
                "product_id": 16256,
                "product_name": "vidal ultra soft & plush dinosaurs fleece throw"
            },
            {
                "product_id": 13858,
                "product_name": "the elephant graphic art"
            }
        ],
        "garOutput": [
            {
                "product_id": 33686,
                "product_name": "climbing rock"
            },
            {
                "product_id": 33685,
                "product_name": "rock climbing rocks"
            },
            {
                "product_id": 3290,
                "product_name": "plastic climbing rocks"
            },
            {
                "product_id": 30477,
                "product_name": "hobbies and activities rock climber"
            },
            {
                "product_id": 31712,
                "product_name": "manningtree premium umbrella kids outdoor chair"
            }
        ],
        "query": "rock climbing wall kids"
    },
    {
        "id": 76,
        "label": [
            {
                "product_id": 31747,
                "product_name": "door"
            },
            {
                "product_id": 34039,
                "product_name": "paneled wood planum sliding closet door"
            },
            {
                "product_id": 34031,
                "product_name": "felicia glass sliding closet doors"
            },
            {
                "product_id": 20238,
                "product_name": "super mini top mount sliding standard single barn door hardware kit"
            },
            {
                "product_id": 34619,
                "product_name": "paneled wood unfinished barn door without installation hardware kit"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 15367,
                "product_name": "glass sliding closet doors with installation hardware kit"
            },
            {
                "product_id": 34037,
                "product_name": "planum glass barn door with installation hardware kit"
            },
            {
                "product_id": 10066,
                "product_name": "unidoor-x 46 in . w x 34 3/8 in . d x 72 in . h hinged shower enclosure"
            },
            {
                "product_id": 29033,
                "product_name": "paneled manufactured wood and metal sliding closet doors"
            },
            {
                "product_id": 30069,
                "product_name": "sliding closet mirror door top guide"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 38224,
                "product_name": "riggins 24 '' single bathroom vanity set"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 39991,
                "product_name": "bathroom rain volume shower system rough-in valve"
            },
            {
                "product_id": 42966,
                "product_name": "mershon 2-light outdoor bulkhead light"
            }
        ],
        "garOutput": [
            {
                "product_id": 31408,
                "product_name": "henault chef hat with fork and spoon wall decal"
            },
            {
                "product_id": 33027,
                "product_name": "hiers 1 - light dimmable black armed sconce"
            },
            {
                "product_id": 9449,
                "product_name": "orpha globe single curtain rod"
            },
            {
                "product_id": 35308,
                "product_name": "atlanta core square telescoping drapery single curtain rod"
            },
            {
                "product_id": 310,
                "product_name": "roclincourt 3 legs 2 nesting tables"
            }
        ],
        "query": "46 inch closet door"
    },
    {
        "id": 156,
        "label": [
            {
                "product_id": 28479,
                "product_name": "laughlin 30 '' wide tufted papasan chair"
            },
            {
                "product_id": 34214,
                "product_name": "lovettsville 22.5 '' wide papasan chair"
            },
            {
                "product_id": 4410,
                "product_name": "chair"
            },
            {
                "product_id": 33632,
                "product_name": "eileen 28.35 '' wide velvet papasan chair"
            },
            {
                "product_id": 21146,
                "product_name": "bouirou 42 '' wide tufted papasan chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 14650,
                "product_name": "englishcombe 24 '' wide papasan chair"
            },
            {
                "product_id": 5850,
                "product_name": "eddy 45 '' wide tufted polyester papasan chair"
            },
            {
                "product_id": 28478,
                "product_name": "larson 30 '' wide papasan chair"
            },
            {
                "product_id": 14697,
                "product_name": "palmer 29.5 '' wide polyester papasan chair"
            },
            {
                "product_id": 10293,
                "product_name": "tuten 40.25 '' wide swivel papasan chair"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 27793,
                "product_name": "adjustable height standing desk"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 32482,
                "product_name": "krause 16 '' height profile platform bed"
            },
            {
                "product_id": 32575,
                "product_name": "esman arm chair in clear"
            }
        ],
        "garOutput": [
            {
                "product_id": 38487,
                "product_name": "baileyville lift top abstract coffee table"
            },
            {
                "product_id": 30443,
                "product_name": "lesperance 28.5 '' wide papasan chair"
            },
            {
                "product_id": 11191,
                "product_name": "scheel 30.25 '' bar stool"
            },
            {
                "product_id": 5932,
                "product_name": "dametta macaron wooden bead curtain tieback"
            },
            {
                "product_id": 20215,
                "product_name": "53 '' plastic rectangular fold-in-half folding table with 4 chairs"
            }
        ],
        "query": "papasan chair frame only"
    },
    {
        "id": 63,
        "label": [
            {
                "product_id": 30560,
                "product_name": "monthly wall mounted graphic calendar whiteboard"
            },
            {
                "product_id": 25421,
                "product_name": "monthly calendar portrait wall mounted grid whiteboard"
            },
            {
                "product_id": 4515,
                "product_name": "holden wall mounted calendar board"
            },
            {
                "product_id": 18646,
                "product_name": "contemporary wall mounted chalkboard"
            },
            {
                "product_id": 39385,
                "product_name": "eternal calendar/planner wall mounted bulletin board"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 35681,
                "product_name": "weekly dry erase calendar wall decal"
            },
            {
                "product_id": 11096,
                "product_name": "wyeth magnetic wall mounted chalkboard"
            },
            {
                "product_id": 36847,
                "product_name": "monthly desk pad calendar"
            },
            {
                "product_id": 18480,
                "product_name": "rustic magnetic chalk calendar and cork board"
            },
            {
                "product_id": 16233,
                "product_name": "ghent time out magnetic wall mounted calendar board"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 37177,
                "product_name": "employee time report card"
            },
            {
                "product_id": 13858,
                "product_name": "the elephant graphic art"
            },
            {
                "product_id": 36847,
                "product_name": "monthly desk pad calendar"
            },
            {
                "product_id": 6053,
                "product_name": "hot water dispenser"
            },
            {
                "product_id": 37112,
                "product_name": "ecotones monthly desk pad calendar"
            }
        ],
        "garOutput": [
            {
                "product_id": 11093,
                "product_name": "magnetic hooks dry erase board"
            },
            {
                "product_id": 40306,
                "product_name": "get organized combination calendar/planer whiteboard , 24 '' x 17.5 ''"
            },
            {
                "product_id": 33542,
                "product_name": "36 '' counter depth french door 22.2 cu . ft. smart energy star refrigerator with family hub"
            },
            {
                "product_id": 33547,
                "product_name": "family hub 36 '' 4-door french door 27.7 cu . ft. smart refrigerator"
            },
            {
                "product_id": 33541,
                "product_name": "36 '' french door 22.2 cu . ft. smart energy star counter depth refrigerator with family hub"
            }
        ],
        "query": "monthly calendar"
    },
    {
        "id": 396,
        "label": [
            {
                "product_id": 15329,
                "product_name": "stuart 8 - light sputnik sphere chandelier with crystal accents"
            },
            {
                "product_id": 25191,
                "product_name": "alyse 8 - light sputnik sphere chandelier with crystal accents"
            },
            {
                "product_id": 1430,
                "product_name": "marion 8 - light 18 '' unique/statement drum flush mount"
            },
            {
                "product_id": 24583,
                "product_name": "carmen 8 - light lantern geometric chandelier"
            },
            {
                "product_id": 1619,
                "product_name": "trista 6-light candle style globe chandelier"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 30256,
                "product_name": "hutchings 8-light candle style drum chandelier"
            },
            {
                "product_id": 24255,
                "product_name": "wyble 3 - light lantern geometric chandelier"
            },
            {
                "product_id": 31706,
                "product_name": "allegro 8-light unique / statement geometric chandelier"
            },
            {
                "product_id": 22781,
                "product_name": "stenberg 8 - light lantern linear chandelier"
            },
            {
                "product_id": 32231,
                "product_name": "creeves 5-light candle style classic / traditional chandelier"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 347,
                "product_name": "1.93 '' solar powered outdoor floating light"
            },
            {
                "product_id": 41019,
                "product_name": "incandescent dimmable light bulb , ( 2500k ) e12/candelabra base"
            },
            {
                "product_id": 20718,
                "product_name": "abramowski monitor stand"
            },
            {
                "product_id": 20085,
                "product_name": "dario 12 - light candle style wagon wheel chandelier"
            }
        ],
        "garOutput": [
            {
                "product_id": 29325,
                "product_name": "charee mirrored cube end table"
            },
            {
                "product_id": 6361,
                "product_name": "mcguinness 8-light candle style drum chandelier"
            },
            {
                "product_id": 15328,
                "product_name": "bret orb cage 3-light unique / statement globe chandelier"
            },
            {
                "product_id": 22592,
                "product_name": "kayla 8 - light candle style geometric chandelier"
            },
            {
                "product_id": 19129,
                "product_name": "swart 26.4 '' black table lamp"
            }
        ],
        "query": "alyse 8 light"
    },
    {
        "id": 345,
        "label": [
            {
                "product_id": 29470,
                "product_name": "lihua 4-light vanity light"
            },
            {
                "product_id": 24115,
                "product_name": "32 '' single bathroom vanity set with mirror"
            },
            {
                "product_id": 35499,
                "product_name": "matteo 24 '' single bathroom vanity set"
            },
            {
                "product_id": 15858,
                "product_name": "holiday 32 '' single bathroom vanity set"
            },
            {
                "product_id": 21165,
                "product_name": "kisner 2-light vanity light"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 36372,
                "product_name": "binne 60 '' double bathroom vanity set"
            },
            {
                "product_id": 8860,
                "product_name": "arisha solid wood vanity"
            },
            {
                "product_id": 37273,
                "product_name": "friedland 30.5 '' single bathroom vanity set"
            },
            {
                "product_id": 38121,
                "product_name": "knightstown 24 '' single bathroom vanity set"
            },
            {
                "product_id": 25224,
                "product_name": "nashua 72 '' double bathroom vanity set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 30826,
                "product_name": "belgr concrete propane/natural gas fire pit table"
            },
            {
                "product_id": 9761,
                "product_name": "scaggs 6 piece bathroom accessory set"
            },
            {
                "product_id": 9760,
                "product_name": "brax 2 gallon waste basket"
            },
            {
                "product_id": 1179,
                "product_name": "real touch bouquet peonies stems"
            }
        ],
        "garOutput": [
            {
                "product_id": 3741,
                "product_name": "reay nesting 2 piece serving tray set"
            },
            {
                "product_id": 11308,
                "product_name": "stancliff wall mirror"
            },
            {
                "product_id": 39910,
                "product_name": "carin coffee table"
            },
            {
                "product_id": 33992,
                "product_name": "finkle 3 tier floating shelf"
            },
            {
                "product_id": 23711,
                "product_name": "brecken wall shelf"
            }
        ],
        "query": "oriental vanity"
    },
    {
        "id": 421,
        "label": [
            {
                "product_id": 2541,
                "product_name": "welliver dining table"
            },
            {
                "product_id": 34594,
                "product_name": "conerly oval 27.6 '' dining table"
            },
            {
                "product_id": 19736,
                "product_name": "louis solid wood dining table"
            },
            {
                "product_id": 31475,
                "product_name": "ammon marble insert counter height dining table"
            },
            {
                "product_id": 947,
                "product_name": "dannie rubberwood solid wood dining table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 24363,
                "product_name": "maliyah pedestal dining table"
            },
            {
                "product_id": 34424,
                "product_name": "madero extendable dining table"
            },
            {
                "product_id": 7126,
                "product_name": "extendable solid wood dining table"
            },
            {
                "product_id": 1503,
                "product_name": "emestine extendable drop leaf dining table"
            },
            {
                "product_id": 18220,
                "product_name": "yedinak 5 piece solid wood dining set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 31143,
                "product_name": "amilliah tufted upholstered parsons chair"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            }
        ],
        "garOutput": [
            {
                "product_id": 310,
                "product_name": "roclincourt 3 legs 2 nesting tables"
            },
            {
                "product_id": 29011,
                "product_name": "silke 3 legs 2 nesting tables"
            },
            {
                "product_id": 1611,
                "product_name": "cruze 33 '' h x 10.5 '' w step bookcase"
            },
            {
                "product_id": 783,
                "product_name": "yacine sunflower tablecloth"
            },
            {
                "product_id": 42738,
                "product_name": "collingwood power loom red/black/white rug"
            }
        ],
        "query": "dining room tables"
    },
    {
        "id": 440,
        "label": [
            {
                "product_id": 38914,
                "product_name": "veneto swivel adjustable height bar stool"
            },
            {
                "product_id": 35878,
                "product_name": "rosalynn swivel counter & bar stool"
            },
            {
                "product_id": 14973,
                "product_name": "clintonpark 25 '' bar stool"
            },
            {
                "product_id": 1773,
                "product_name": "colesberry swivel bar & counter stool"
            },
            {
                "product_id": 26174,
                "product_name": "veldhoven bar & counter stool"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 22054,
                "product_name": "basche swivel adjustable height bar stool"
            },
            {
                "product_id": 19279,
                "product_name": "adona counter & bar stool"
            },
            {
                "product_id": 25834,
                "product_name": "kody bar & counter stool"
            },
            {
                "product_id": 19472,
                "product_name": "fabii counter & bar stool"
            },
            {
                "product_id": 32963,
                "product_name": "capavella bar & counter stool"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 40457,
                "product_name": "ranbir 39 '' wide velvet chesterfield chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 9398,
                "product_name": "kirsty 26 '' counter stool"
            },
            {
                "product_id": 36112,
                "product_name": "severine 30 '' bar stool"
            },
            {
                "product_id": 28551,
                "product_name": "aadvik upholstered parsons chair"
            },
            {
                "product_id": 3705,
                "product_name": "derbyshire bar & counter stool"
            },
            {
                "product_id": 25666,
                "product_name": "barahona 30 '' bar stool"
            }
        ],
        "query": "wood bar stools"
    },
    {
        "id": 310,
        "label": [
            {
                "product_id": 14370,
                "product_name": "gennessee beveled polished frameless wall mirror with hooks"
            },
            {
                "product_id": 23516,
                "product_name": "ankur wall mirror"
            },
            {
                "product_id": 27784,
                "product_name": "benzel full length mirror"
            },
            {
                "product_id": 29096,
                "product_name": "donte accent mirror"
            },
            {
                "product_id": 1018,
                "product_name": "korsen beveled magnifying dresser mirror"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 18417,
                "product_name": "straley metal with shelves bathroom/vanity mirror"
            },
            {
                "product_id": 4613,
                "product_name": "manzelli wall mirror mirror"
            },
            {
                "product_id": 40795,
                "product_name": "lesley country beveled dresser mirror"
            },
            {
                "product_id": 16659,
                "product_name": "octa mirror"
            },
            {
                "product_id": 22507,
                "product_name": "banbury modern farmhouse beveled distressed accent mirror"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            }
        ],
        "garOutput": [
            {
                "product_id": 30054,
                "product_name": "ayrika full length mirror"
            },
            {
                "product_id": 27780,
                "product_name": "maurer full length mirror"
            },
            {
                "product_id": 35830,
                "product_name": "douwina full length mirror"
            },
            {
                "product_id": 21356,
                "product_name": "electroplating laundry cart"
            },
            {
                "product_id": 6742,
                "product_name": "green drums graphic art on plaque"
            }
        ],
        "query": "meade mirror"
    },
    {
        "id": 270,
        "label": [
            {
                "product_id": 34546,
                "product_name": "low pile carpet straight edge chair mat"
            },
            {
                "product_id": 37369,
                "product_name": "hard floor straight square chair mat"
            },
            {
                "product_id": 9960,
                "product_name": "hard floor straight edge rectangular chair mat"
            },
            {
                "product_id": 34343,
                "product_name": "enfinity multipurpose 2 piece kitchen mat"
            },
            {
                "product_id": 6585,
                "product_name": "economat all day hard floor straight chair mat"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 29861,
                "product_name": "pietrzak medium pile carpet beveled chair mat"
            },
            {
                "product_id": 37383,
                "product_name": "cleartex ultimat hard floor straight edge chair mat"
            },
            {
                "product_id": 34581,
                "product_name": "leitner low pile carpet straight edge chair mat"
            },
            {
                "product_id": 14056,
                "product_name": "polycarbonate medium pile carpet straight edge chair mat"
            },
            {
                "product_id": 13192,
                "product_name": "lipped linear medium pile carpet straight edge chair mat"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 32281,
                "product_name": "metal slat back stacking side chair"
            },
            {
                "product_id": 14894,
                "product_name": "genuine leather upholstered dining chair"
            },
            {
                "product_id": 40457,
                "product_name": "ranbir 39 '' wide velvet chesterfield chair"
            },
            {
                "product_id": 38627,
                "product_name": "jurgen stool"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 27883,
                "product_name": "ailbhe 35.43 '' w rack"
            },
            {
                "product_id": 34528,
                "product_name": "bacquie metal full platform bed"
            },
            {
                "product_id": 30517,
                "product_name": "kronqui bed and control hypoallergenic zippered mattress protector"
            },
            {
                "product_id": 5518,
                "product_name": "yadira 14 '' steel platform bed"
            },
            {
                "product_id": 19302,
                "product_name": "1 5/16 '' diameter mushroom knob multipack"
            }
        ],
        "query": "edge chair mat"
    },
    {
        "id": 82,
        "label": [
            {
                "product_id": 4421,
                "product_name": "emilee 60 '' double bathroom vanity set"
            },
            {
                "product_id": 8939,
                "product_name": "cabery 30 '' single bathroom vanity set"
            },
            {
                "product_id": 6345,
                "product_name": "kao 24 '' single bathroom vanity set"
            },
            {
                "product_id": 33658,
                "product_name": "belize 35.2 '' single bathroom vanity base only"
            },
            {
                "product_id": 6004,
                "product_name": "dunamuggy 24 '' single bathroom vanity set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 15868,
                "product_name": "merrimack 48 '' single bathroom vanity set"
            },
            {
                "product_id": 6523,
                "product_name": "lisenby 71 '' double bathroom vanity set with mirror"
            },
            {
                "product_id": 35951,
                "product_name": "37 '' single bathroom vanity top in white with sink"
            },
            {
                "product_id": 15006,
                "product_name": "unique 30 '' single bathroom vanity set"
            },
            {
                "product_id": 16279,
                "product_name": "60 '' free-standing single bathroom vanity base only"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 25869,
                "product_name": "42 '' single bathroom vanity"
            },
            {
                "product_id": 35693,
                "product_name": "anello 20 '' w closet system reach-in sets"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 3197,
                "product_name": "double shower curtain hooks"
            }
        ],
        "garOutput": [
            {
                "product_id": 20231,
                "product_name": "30 '' w x 20 '' l farmhouse kitchen sink with sink grid and basket strainer"
            },
            {
                "product_id": 20230,
                "product_name": "33 '' l x 21 '' w farmhouse kitchen sink with sink grid and basket strainer"
            },
            {
                "product_id": 20229,
                "product_name": "30 '' l x 21 '' w farmhouse kitchen sink with sink grid and basket strainer"
            },
            {
                "product_id": 5748,
                "product_name": "500w light bulb"
            },
            {
                "product_id": 26473,
                "product_name": "abdinour 2 tier kitchen corner rack prep table"
            }
        ],
        "query": "bathroom vanity with counter space"
    },
    {
        "id": 101,
        "label": [
            {
                "product_id": 36151,
                "product_name": "dido 48 '' solid wood console table"
            },
            {
                "product_id": 8655,
                "product_name": "minerva 39.5 '' console table"
            },
            {
                "product_id": 4533,
                "product_name": "vitela 48 '' console table"
            },
            {
                "product_id": 26500,
                "product_name": "trinidad 48 '' console table"
            },
            {
                "product_id": 38073,
                "product_name": "leflore solid wood 2 - drawer end table with storage"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 42443,
                "product_name": "hanniah 6 - drawer end table"
            },
            {
                "product_id": 5964,
                "product_name": "hymes 2 - drawer end table"
            },
            {
                "product_id": 1605,
                "product_name": "waler 2 - drawer end table"
            },
            {
                "product_id": 38257,
                "product_name": "heenan 2 piece coffee side table"
            },
            {
                "product_id": 23875,
                "product_name": "holly springs end table with storage"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 14106,
                "product_name": "veronica 5 drawer chest"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 1093,
                "product_name": "eamon end table with storage"
            },
            {
                "product_id": 19874,
                "product_name": "emeria reversible l-shaped executive desk"
            }
        ],
        "garOutput": [
            {
                "product_id": 12913,
                "product_name": "virtue 18 '' x 18 '' porcelain spanish wall & floor tile"
            },
            {
                "product_id": 21832,
                "product_name": "bahena 34.3 '' h x 27.9 '' w x 13.6 '' d foldable storage shelves"
            },
            {
                "product_id": 41522,
                "product_name": "fromm wood table"
            },
            {
                "product_id": 22443,
                "product_name": "double hamper foldable laundry basket"
            },
            {
                "product_id": 17960,
                "product_name": "stacking owls statue"
            }
        ],
        "query": "48 in entry table with side by side drawer"
    },
    {
        "id": 485,
        "label": [
            {
                "product_id": 2271,
                "product_name": "double basin 30 '' l x 22 '' w farmhouse/apron kitchen sink"
            },
            {
                "product_id": 22740,
                "product_name": "21 '' l x 16 '' w farmhouse kitchen sink with sink grid and basket strainer"
            },
            {
                "product_id": 23269,
                "product_name": "tailor 35.5 '' l x 21.25 '' w farmhouse kitchen sink"
            },
            {
                "product_id": 23271,
                "product_name": "tailor 29.5 '' l x 21.25 '' w farmhouse kitchen sink"
            },
            {
                "product_id": 22261,
                "product_name": "dickinson 33 '' l x 22 '' w apron kitchen sink"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 22201,
                "product_name": "whitehaven 29.69 '' l x 21.69 '' w farmhouse single bowl kitchen sink"
            },
            {
                "product_id": 39027,
                "product_name": "60 '' double bathroom vanity top in white with sink"
            },
            {
                "product_id": 22195,
                "product_name": "alencon lace design on dickinson 33 '' l x 22-1/8 '' w x 8-3/4 '' under-mount kitchen sink with 4 oversize faucet holes"
            },
            {
                "product_id": 22203,
                "product_name": "whitehaven 35.5 '' l x 21.56 '' w double-bowl kitchen sink with short apron"
            },
            {
                "product_id": 22712,
                "product_name": "kore 33 '' l x 20 '' w farmhouse/apron kitchen sink with sink grid and drain assembly"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 1179,
                "product_name": "real touch bouquet peonies stems"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            }
        ],
        "garOutput": [
            {
                "product_id": 20231,
                "product_name": "30 '' w x 20 '' l farmhouse kitchen sink with sink grid and basket strainer"
            },
            {
                "product_id": 20230,
                "product_name": "33 '' l x 21 '' w farmhouse kitchen sink with sink grid and basket strainer"
            },
            {
                "product_id": 20229,
                "product_name": "30 '' l x 21 '' w farmhouse kitchen sink with sink grid and basket strainer"
            },
            {
                "product_id": 36856,
                "product_name": "stainless steel sink frame"
            },
            {
                "product_id": 3953,
                "product_name": "waterfall widespread bathroom faucet with drain assembly"
            }
        ],
        "query": "pedistole sink"
    },
    {
        "id": 113,
        "label": [
            {
                "product_id": 21976,
                "product_name": "7 watt ( 60 watt equivalent ) a19 led dimmable light bulb warm white ( 2700k ) e26/medium ( standard ) base"
            },
            {
                "product_id": 19405,
                "product_name": "black solar powered integrated led bollard light"
            },
            {
                "product_id": 1451,
                "product_name": "led under cabinet strip light"
            },
            {
                "product_id": 17269,
                "product_name": "60 watt equivalent a19 led dimmable clear glass filament light bulb e26/medium ( standard ) base ( set of 6 )"
            },
            {
                "product_id": 9992,
                "product_name": "12-light led 20 '' under cabinet strip light"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 2386,
                "product_name": "5 watt ( 60 watt equivalent ) , b11 led , dimmable light bulb , e12 candelabra base"
            },
            {
                "product_id": 29105,
                "product_name": "25 '' led 60 - bulb fairy string light"
            },
            {
                "product_id": 41035,
                "product_name": "60 watt equivalent , b11 led , dimmable light bulb , warm white ( 2700k ) e12 base"
            },
            {
                "product_id": 30859,
                "product_name": "300 - light led 9.44 '' under cabinet strip light"
            },
            {
                "product_id": 4292,
                "product_name": "brightech tree led spotlight super bright 3 light standing floor lamp , jet black"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 42965,
                "product_name": "hugh 3-light bath bar"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 14350,
                "product_name": "15-amp standard duplex outlet"
            },
            {
                "product_id": 34495,
                "product_name": "adriany task chair"
            },
            {
                "product_id": 20437,
                "product_name": "heinle 1 - light led spotlight"
            }
        ],
        "garOutput": [
            {
                "product_id": 35965,
                "product_name": "gunbox 18-light led 12 '' under cabinet light bar"
            },
            {
                "product_id": 39480,
                "product_name": "all in one clip"
            },
            {
                "product_id": 1451,
                "product_name": "led under cabinet strip light"
            },
            {
                "product_id": 39698,
                "product_name": "clivden 2 - light dimmable wall sconce"
            },
            {
                "product_id": 35992,
                "product_name": "16.4 ft led strip light , 528 leds cob bendable tape light , 2700k soft white , ul listed"
            }
        ],
        "query": "led light strip"
    },
    {
        "id": 470,
        "label": [
            {
                "product_id": 5902,
                "product_name": "cyanotype no.1 by renee w. stramel - picture frame photograph print on paper"
            },
            {
                "product_id": 19437,
                "product_name": "stone on fire by michael blanchette - picture frame photograph print on canvas"
            },
            {
                "product_id": 19489,
                "product_name": "1971 enfant by pablo picasso - floater frame print on canvas"
            },
            {
                "product_id": 19442,
                "product_name": "`` on fire in the fog '' by lois bryan framed photographic print"
            },
            {
                "product_id": 12156,
                "product_name": "feather bird paint - picture frame painting print on paper"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 9354,
                "product_name": "ashton art & d\u00e9cor large framed painting print"
            },
            {
                "product_id": 11778,
                "product_name": "11 '' x 22 '' picture frame"
            },
            {
                "product_id": 37243,
                "product_name": "camper happiness is journey framed textual art"
            },
            {
                "product_id": 11465,
                "product_name": "mondovi picture frame"
            },
            {
                "product_id": 4620,
                "product_name": "free bird mexican tiles iii - 2 piece picture frame print set on paper"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 13858,
                "product_name": "the elephant graphic art"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            }
        ],
        "garOutput": [
            {
                "product_id": 615,
                "product_name": "star wars rebels kanan jarrus cardboard standup"
            },
            {
                "product_id": 616,
                "product_name": "star wars rebels stormtrooper cardboard standup"
            },
            {
                "product_id": 4169,
                "product_name": "liverman end table"
            },
            {
                "product_id": 614,
                "product_name": "star wars rebels garazeb zeb orrelios cardboard standup"
            },
            {
                "product_id": 29149,
                "product_name": "mom and baby elephants framed painting print"
            }
        ],
        "query": "elephant framed"
    },
    {
        "id": 257,
        "label": [
            {
                "product_id": 35129,
                "product_name": "evane coffee table"
            },
            {
                "product_id": 34371,
                "product_name": "waddington lift top coffee table with storage"
            },
            {
                "product_id": 15577,
                "product_name": "helyne solid wood coffee table with storage"
            },
            {
                "product_id": 3556,
                "product_name": "teres solid wood block coffee table"
            },
            {
                "product_id": 38599,
                "product_name": "enchantment frame coffee table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 21773,
                "product_name": "brink sled coffee table"
            },
            {
                "product_id": 25972,
                "product_name": "raymundo solid wood pedestal coffee table"
            },
            {
                "product_id": 34672,
                "product_name": "joanna coffee table"
            },
            {
                "product_id": 5713,
                "product_name": "weber coffee table"
            },
            {
                "product_id": 3674,
                "product_name": "soderville coffee table with storage"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 38673,
                "product_name": "abdulsamet coffee table"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 30158,
                "product_name": "hershberger buffet table"
            }
        ],
        "garOutput": [
            {
                "product_id": 40127,
                "product_name": "lefever end table"
            },
            {
                "product_id": 20361,
                "product_name": "d\u00e9borah coffee table"
            },
            {
                "product_id": 1498,
                "product_name": "allegany bonded leather upholstered dining chair"
            },
            {
                "product_id": 30181,
                "product_name": "emmielou tufted velvet side chair"
            },
            {
                "product_id": 13504,
                "product_name": "teak tripod end table"
            }
        ],
        "query": "living room coffee table sets"
    },
    {
        "id": 78,
        "label": [
            {
                "product_id": 24497,
                "product_name": "casswell 36 '' single bathroom vanity set"
            },
            {
                "product_id": 6468,
                "product_name": "24 '' single bathroom vanity set"
            },
            {
                "product_id": 7269,
                "product_name": "cathey 36 '' single bathroom vanity set with mirror"
            },
            {
                "product_id": 8919,
                "product_name": "nekoma solid wood vanity"
            },
            {
                "product_id": 29314,
                "product_name": "phoebe chrome handles drilling floor mount 62 '' double bathroom vanity set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 19613,
                "product_name": "18 '' bathroom vanity base only"
            },
            {
                "product_id": 42027,
                "product_name": "froemming vanity"
            },
            {
                "product_id": 340,
                "product_name": "letourneau 39 '' wall-mounted single bathroom vanity set"
            },
            {
                "product_id": 6842,
                "product_name": "chestnut 60 '' double bathroom vanity set"
            },
            {
                "product_id": 21547,
                "product_name": "grayling 24 '' single bathroom vanity set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 34987,
                "product_name": "shrestha upholstered parsons chair in gray"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            }
        ],
        "garOutput": [
            {
                "product_id": 28980,
                "product_name": "4 piece coffee , tea , & sugar set"
            },
            {
                "product_id": 8159,
                "product_name": "port lincoln emily bamboo cosmetic organizer"
            },
            {
                "product_id": 11664,
                "product_name": "bolan 12.6 '' h x 13.4 '' w cube bookcase"
            },
            {
                "product_id": 37690,
                "product_name": "dezmund 24 '' single bathroom vanity set"
            },
            {
                "product_id": 6512,
                "product_name": "schull 32 '' wide polyester armchair"
            }
        ],
        "query": "dorel vanity"
    },
    {
        "id": 137,
        "label": [
            {
                "product_id": 20468,
                "product_name": "addley glass l-shape desk"
            },
            {
                "product_id": 22099,
                "product_name": "antek 86 '' x 98 '' l-shape reception desk with light panels"
            },
            {
                "product_id": 205,
                "product_name": "essinger reversible l-shape executive desk"
            },
            {
                "product_id": 17805,
                "product_name": "debron reversible l-shape executive desk"
            },
            {
                "product_id": 29401,
                "product_name": "boland writing desk"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 19903,
                "product_name": "modern and simple l-shape desk"
            },
            {
                "product_id": 9905,
                "product_name": "waretown reversible l-shape executive desk"
            },
            {
                "product_id": 4993,
                "product_name": "thorben l-shape executive desk"
            },
            {
                "product_id": 25082,
                "product_name": "masala reversible l-shape executive desk with hutch"
            },
            {
                "product_id": 15626,
                "product_name": "afanasii l-shape desk"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            },
            {
                "product_id": 20747,
                "product_name": "home office computer desk with hutch , 47 inch rustic office desk and modern writing desk with storage shelves , vintage and black legs"
            },
            {
                "product_id": 10377,
                "product_name": "avabella trestle coffee table"
            },
            {
                "product_id": 27793,
                "product_name": "adjustable height standing desk"
            }
        ],
        "garOutput": [
            {
                "product_id": 31594,
                "product_name": "adesewa printer stand"
            },
            {
                "product_id": 19130,
                "product_name": "violette desk"
            },
            {
                "product_id": 14194,
                "product_name": "simplicity design wooden office desk computer desk bedroom study table work table workstation for home office"
            },
            {
                "product_id": 17805,
                "product_name": "debron reversible l-shape executive desk"
            },
            {
                "product_id": 41346,
                "product_name": "mehpare 3 - shelf storage cabinet"
            }
        ],
        "query": "orren ellis l shaped desk"
    },
    {
        "id": 238,
        "label": [
            {
                "product_id": 30675,
                "product_name": "steward outdoor 4 piece rattan sectional seating group with cushions"
            },
            {
                "product_id": 15299,
                "product_name": "cherico outdoor 11 piece sofa seating group with cushions"
            },
            {
                "product_id": 25269,
                "product_name": "colton outdoor recliner patio chair with sunbrella cushions"
            },
            {
                "product_id": 31293,
                "product_name": "chakotay outdoor reclining chaise lounge with cushion"
            },
            {
                "product_id": 41115,
                "product_name": "faithe outdoor 3 piece seating group with cushions"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 9028,
                "product_name": "indoor/outdoor seat/back cushion"
            },
            {
                "product_id": 5645,
                "product_name": "alexandra outdoor reclining chaise lounge with cushion"
            },
            {
                "product_id": 20039,
                "product_name": "home office use soft flax outdoor seat cushion"
            },
            {
                "product_id": 16526,
                "product_name": "indoor/outdoor seat/back cushion"
            },
            {
                "product_id": 7585,
                "product_name": "waynoka outdoor furniture 3 piece rattan seating group with cushions"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 2312,
                "product_name": "twin size platform bed with adjustable trundle , espresso"
            },
            {
                "product_id": 41102,
                "product_name": "benjie 34 '' wide power lift assist standard recliner"
            },
            {
                "product_id": 20888,
                "product_name": "mowat 41 '' wide power lift assist recliner"
            },
            {
                "product_id": 30158,
                "product_name": "hershberger buffet table"
            },
            {
                "product_id": 34495,
                "product_name": "adriany task chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 38719,
                "product_name": "briarhill patio reclining chaise lounge with cushion"
            },
            {
                "product_id": 13905,
                "product_name": "alsdorf task chair"
            },
            {
                "product_id": 7811,
                "product_name": "cline bless this home anti-fatigue mat"
            },
            {
                "product_id": 7809,
                "product_name": "kole family time anti-fatigue mat"
            },
            {
                "product_id": 7815,
                "product_name": "kerry fluer de lis anti-fatigue mat"
            }
        ],
        "query": "outdoor seat/back cushion"
    },
    {
        "id": 309,
        "label": [
            {
                "product_id": 27677,
                "product_name": "align smart touchless single handle kitchen faucet with wave , power clean , duralock and reflex"
            },
            {
                "product_id": 26564,
                "product_name": "arbor pull out single handle kitchen faucet with duralock\u2122 and reflex\u2122"
            },
            {
                "product_id": 27681,
                "product_name": "nio smart touchless single handle kitchen faucet with wave , power clean , duralock and reflex"
            },
            {
                "product_id": 27678,
                "product_name": "arbor smart touchless single handle kitchen faucet with wave , power boost , power clean , duralock and reflex"
            },
            {
                "product_id": 30025,
                "product_name": "cartridge replacement kit for moen m2"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 26568,
                "product_name": "renzo single handle kitchen faucet with duralock"
            },
            {
                "product_id": 26571,
                "product_name": "arbor pull down single handle kitchen faucet with duralock and reflex"
            },
            {
                "product_id": 26617,
                "product_name": "align pull down touchless single handle kitchen faucet with motionsense and powerclean technologies"
            },
            {
                "product_id": 26572,
                "product_name": "commercial high arch spout kit"
            },
            {
                "product_id": 26618,
                "product_name": "arbor pull down touchless single handle kitchen faucet with motionsense and power clean\u2122technologies"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 1668,
                "product_name": "off center brass faucet"
            },
            {
                "product_id": 1666,
                "product_name": "single hole brass faucet"
            },
            {
                "product_id": 26564,
                "product_name": "arbor pull out single handle kitchen faucet with duralock\u2122 and reflex\u2122"
            },
            {
                "product_id": 13893,
                "product_name": "versa single hole bathroom faucet"
            },
            {
                "product_id": 5981,
                "product_name": "santo three handle deck mounted tub spout"
            }
        ],
        "query": "moen 5995 arbor one"
    },
    {
        "id": 327,
        "label": [
            {
                "product_id": 23218,
                "product_name": "purist wall-mount supply with check valve"
            },
            {
                "product_id": 23304,
                "product_name": "purist multifunction handshower with katalyst air-induction technology"
            },
            {
                "product_id": 23228,
                "product_name": "kohler pinstripe rite-temp valve trim with lever handle"
            },
            {
                "product_id": 23232,
                "product_name": "kohler artifacts rite-temp valve trim with lever handle"
            },
            {
                "product_id": 22267,
                "product_name": "purist widespread bathroom sink faucet with low cross handles and low spout"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 22272,
                "product_name": "purist 30 '' wall mounted towel bar"
            },
            {
                "product_id": 22160,
                "product_name": "purist grab bar"
            },
            {
                "product_id": 22212,
                "product_name": "purist valve trim with cross handle for transfer valve , requires valve"
            },
            {
                "product_id": 22189,
                "product_name": "purist rite-temp pressure-balancing bath and shower faucet trim with push-button diverter , 7-3/4 '' spout and cross handle , valve not included"
            },
            {
                "product_id": 23233,
                "product_name": "kohler devonshire rite-temp bath and shower trim set with slip-fit or npt spout , valve not included"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 13535,
                "product_name": "pottery framed graphic art print on canvas"
            },
            {
                "product_id": 32559,
                "product_name": "grid mirror"
            },
            {
                "product_id": 36454,
                "product_name": "aneka 17 '' table lamp"
            },
            {
                "product_id": 32547,
                "product_name": "serving platter"
            }
        ],
        "garOutput": [
            {
                "product_id": 13966,
                "product_name": "francisco drawer 5 '' center to center bar pull"
            },
            {
                "product_id": 23385,
                "product_name": "francisco 3 '' center to center bar pull multipack"
            },
            {
                "product_id": 8727,
                "product_name": "dunnstown 2 - light 14 '' caged drum semi flush mount"
            },
            {
                "product_id": 14181,
                "product_name": "jacquard woven floral room darkening grommet curtains panels"
            },
            {
                "product_id": 12720,
                "product_name": "elkay fireclay 25 '' l x 19 '' w farmhouse kitchen sink"
            }
        ],
        "query": "kohler purist brushed bronze"
    },
    {
        "id": 68,
        "label": [
            {
                "product_id": 4358,
                "product_name": "petties boat novelty divided serving dish"
            },
            {
                "product_id": 32169,
                "product_name": "flint shell platter"
            },
            {
                "product_id": 11731,
                "product_name": "beene marine sea turtle platter"
            },
            {
                "product_id": 32539,
                "product_name": "fish porcelain china chips and dip platter"
            },
            {
                "product_id": 35745,
                "product_name": "burgett octopus melamine platter"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 23102,
                "product_name": "octopus 5 piece condiment server set"
            },
            {
                "product_id": 28154,
                "product_name": "dafne fish print platter platter"
            },
            {
                "product_id": 11237,
                "product_name": "dashiell melamine platter"
            },
            {
                "product_id": 13088,
                "product_name": "tuscany melamine platter"
            },
            {
                "product_id": 6446,
                "product_name": "dilley cake platter"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 9624,
                "product_name": "military challenge coin cabinet rack holder display case"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            },
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            },
            {
                "product_id": 14106,
                "product_name": "veronica 5 drawer chest"
            },
            {
                "product_id": 20486,
                "product_name": "lockheart end table"
            }
        ],
        "garOutput": [
            {
                "product_id": 3741,
                "product_name": "reay nesting 2 piece serving tray set"
            },
            {
                "product_id": 33665,
                "product_name": "delasandro metal storage cabinet"
            },
            {
                "product_id": 33664,
                "product_name": "iaconetti storage cabinet"
            },
            {
                "product_id": 5205,
                "product_name": "choual coffee table"
            },
            {
                "product_id": 3767,
                "product_name": "anniedale nesting 3 piece serving tray"
            }
        ],
        "query": "nautical platters"
    },
    {
        "id": 72,
        "label": [
            {
                "product_id": 4586,
                "product_name": "kathleen 44 '' solid wood folding console table"
            },
            {
                "product_id": 33533,
                "product_name": "sufyan 15.5 '' console table"
            },
            {
                "product_id": 29699,
                "product_name": "47.2 '' rectangular fold-in-half adjustable folding table"
            },
            {
                "product_id": 16321,
                "product_name": "25.2 '' rectangular fold-in-half portable folding table"
            },
            {
                "product_id": 29662,
                "product_name": "square yes folding table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 31286,
                "product_name": "rectangular fold-in-half adjustable folding table"
            },
            {
                "product_id": 39460,
                "product_name": "30 '' plastic rectangular fold-in-half folding table"
            },
            {
                "product_id": 6979,
                "product_name": "3 pcs outdoor folding bistro table chairs set"
            },
            {
                "product_id": 16322,
                "product_name": "50 '' rectangular fold-in-half folding table"
            },
            {
                "product_id": 8791,
                "product_name": "55.3 '' rectangular fold-in-half folding table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 10565,
                "product_name": "askewville i love it when my wife lets me ride my dirt bike funny partner design coffee mug"
            },
            {
                "product_id": 10566,
                "product_name": "libourne funny owl drink to that owl drinking wine pun cartoon coffee mug"
            },
            {
                "product_id": 14106,
                "product_name": "veronica 5 drawer chest"
            },
            {
                "product_id": 41246,
                "product_name": "rae dunn stem print 3.5 qt . flour canister"
            },
            {
                "product_id": 20718,
                "product_name": "abramowski monitor stand"
            }
        ],
        "garOutput": [
            {
                "product_id": 40380,
                "product_name": "carmelia eeastern king bed"
            },
            {
                "product_id": 2423,
                "product_name": "grape cake knife"
            },
            {
                "product_id": 41522,
                "product_name": "fromm wood table"
            },
            {
                "product_id": 41491,
                "product_name": "kymber round cotton pouf"
            },
            {
                "product_id": 42253,
                "product_name": "laundry hamper large woven cotton rope laundry basket dirty clothes hamper for laundry or bedroom \u2013 25.6 height grey"
            }
        ],
        "query": "fold in half table"
    },
    {
        "id": 222,
        "label": [
            {
                "product_id": 42751,
                "product_name": "abernethy solid wood end table"
            },
            {
                "product_id": 8983,
                "product_name": "mervyn end table"
            },
            {
                "product_id": 15846,
                "product_name": "arsenault end table with storage"
            },
            {
                "product_id": 23787,
                "product_name": "derren end table with storage"
            },
            {
                "product_id": 24660,
                "product_name": "vikki c table nesting tables"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 4134,
                "product_name": "mader solid wood pedestal end table"
            },
            {
                "product_id": 15886,
                "product_name": "genata 1 - drawer end table and storage"
            },
            {
                "product_id": 42367,
                "product_name": "annie end table set with storage"
            },
            {
                "product_id": 14611,
                "product_name": "encinal end table"
            },
            {
                "product_id": 36385,
                "product_name": "inman end table with storage"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            },
            {
                "product_id": 29241,
                "product_name": "cleotha twin 75 '' wide tufted back convertible sofa"
            },
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            },
            {
                "product_id": 41836,
                "product_name": "liesbeth stool"
            },
            {
                "product_id": 32495,
                "product_name": "dederang 2 '' bookshelf"
            }
        ],
        "garOutput": [
            {
                "product_id": 23332,
                "product_name": "benny zona hand woven 100 % cotton 18-inch pouf ottoman , natural"
            },
            {
                "product_id": 23333,
                "product_name": "benson gobi hand woven 100 % cotton 18-inch pouf ottoman , natural"
            },
            {
                "product_id": 1524,
                "product_name": "rentz 64 '' console table"
            },
            {
                "product_id": 24638,
                "product_name": "lovins coffee table"
            },
            {
                "product_id": 16629,
                "product_name": "pletcher 2 door accent cabinet"
            }
        ],
        "query": "small loving roomtables"
    },
    {
        "id": 315,
        "label": [
            {
                "product_id": 31776,
                "product_name": "bottom hanger with adjustable cushion clips for skirt/pants"
            },
            {
                "product_id": 22333,
                "product_name": "14.15 '' black clip on"
            },
            {
                "product_id": 25228,
                "product_name": "collin 9 '' clip on lamp"
            },
            {
                "product_id": 38980,
                "product_name": "basket label clip"
            },
            {
                "product_id": 25056,
                "product_name": "glass empire lamp shade ( clip on )"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 10951,
                "product_name": "funmilola 34.7 '' clip on"
            },
            {
                "product_id": 7094,
                "product_name": "aristia clip on"
            },
            {
                "product_id": 42067,
                "product_name": "clybourn 33.46 '' black clip on with usb and outlet"
            },
            {
                "product_id": 27886,
                "product_name": "fretwell plastic hangers with clips for skirt/pants"
            },
            {
                "product_id": 7262,
                "product_name": "standard clips"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 6053,
                "product_name": "hot water dispenser"
            },
            {
                "product_id": 23045,
                "product_name": "wire 3 1/2 '' center to center appliance pull"
            },
            {
                "product_id": 16370,
                "product_name": "stikki clips white 20 per pack"
            },
            {
                "product_id": 17741,
                "product_name": "bathroom free standing toilet paper holder with reserve function"
            },
            {
                "product_id": 12282,
                "product_name": "36 '' w rack"
            }
        ],
        "garOutput": [
            {
                "product_id": 22986,
                "product_name": "turquoise succulent - wrapped canvas photograph print"
            },
            {
                "product_id": 20719,
                "product_name": "bianchina large monitor stand"
            },
            {
                "product_id": 7553,
                "product_name": "6 pair stackable shoe rack"
            },
            {
                "product_id": 18183,
                "product_name": "hamilton beach 6 qt . stay or go programmable flexcook slow cooker"
            },
            {
                "product_id": 19515,
                "product_name": "large digital led wall clock"
            }
        ],
        "query": "medium clips"
    },
    {
        "id": 79,
        "label": [
            {
                "product_id": 33684,
                "product_name": "20 ft. w x 10 ft. d greenhouse"
            },
            {
                "product_id": 30915,
                "product_name": "monticello 8 ft. w x 8 ft. d greenhouse"
            },
            {
                "product_id": 29833,
                "product_name": "mythos 6 ft. w x 4 ft. d greenhouse"
            },
            {
                "product_id": 34112,
                "product_name": "spring gardener 10 ft. w x 20 ft. d greenhouse"
            },
            {
                "product_id": 31916,
                "product_name": "grand gardener 2 clear 8 ft. w x 16 ft. d greenhouse"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 32037,
                "product_name": "monticello mojave 8.1 ft. w x 12.2 ft. d greenhouse"
            },
            {
                "product_id": 30918,
                "product_name": "monticello 8 ft. w x 20 ft. d greenhouse"
            },
            {
                "product_id": 32038,
                "product_name": "monticello mojave 8.1 ft. w x 16.3 ft. d greenhouse"
            },
            {
                "product_id": 33680,
                "product_name": "6.5 ft. w x 8 ft. d greenhouse"
            },
            {
                "product_id": 30812,
                "product_name": "plant inn 4 ft. w x 4 ft. d mini greenhouse"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 13247,
                "product_name": "square multi-tiered plant stand"
            },
            {
                "product_id": 14229,
                "product_name": "homestead dining table"
            }
        ],
        "garOutput": [
            {
                "product_id": 29833,
                "product_name": "mythos 6 ft. w x 4 ft. d greenhouse"
            },
            {
                "product_id": 42594,
                "product_name": "concord vinyl lattice panel trellis"
            },
            {
                "product_id": 16094,
                "product_name": "elite heavy-duty aluminum 8 ft. w x 12 ft. d greenhouse"
            },
            {
                "product_id": 16095,
                "product_name": "elite heavy-duty aluminum 8 ft. w x 16 ft. d greenhouse"
            },
            {
                "product_id": 16093,
                "product_name": "elite heavy-duty aluminum 8 ft. w x 8 ft. d greenhouse"
            }
        ],
        "query": "palram harmony greenhouses"
    },
    {
        "id": 211,
        "label": [
            {
                "product_id": 23452,
                "product_name": "wayfair sleep ventilated 3 '' memory foam mattress topper"
            },
            {
                "product_id": 16376,
                "product_name": "nautical chart jupiter inlet fl rectangle memory foam non-slip bath rug"
            },
            {
                "product_id": 31633,
                "product_name": "emmaleigh rectangle memory foam non-slip 2 piece bath rug set"
            },
            {
                "product_id": 21493,
                "product_name": "london memory foam bath rug"
            },
            {
                "product_id": 10027,
                "product_name": "matsumura bear memory foam bath mat"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 16768,
                "product_name": "amilya gray rug"
            },
            {
                "product_id": 21753,
                "product_name": "memory foam bath carpet"
            },
            {
                "product_id": 21751,
                "product_name": "ferrand trio x-ray flowers memory foam bath mat"
            },
            {
                "product_id": 25473,
                "product_name": "aneda gray rug"
            },
            {
                "product_id": 6048,
                "product_name": "grecian small plush rectangle memory foam non-slip 2 piece bath rug set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 10377,
                "product_name": "avabella trestle coffee table"
            },
            {
                "product_id": 42543,
                "product_name": "hartland abc barnyard power loomed green/red area rug"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 38627,
                "product_name": "jurgen stool"
            },
            {
                "product_id": 40457,
                "product_name": "ranbir 39 '' wide velvet chesterfield chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 29387,
                "product_name": "rectangle jones abstract power loom gray/red/black rug"
            },
            {
                "product_id": 6791,
                "product_name": "carraway plastic pot planter"
            },
            {
                "product_id": 41253,
                "product_name": "cockerham damask room darkening thermal grommet single curtain panel"
            },
            {
                "product_id": 39413,
                "product_name": "aking storage cabinet"
            },
            {
                "product_id": 32119,
                "product_name": "aldo 31 '' wide genuine leather butterfly chair"
            }
        ],
        "query": "memory foam rug galveston"
    },
    {
        "id": 148,
        "label": [
            {
                "product_id": 20685,
                "product_name": "3-light industrial bathroom vanity light metal wire cage wall sconce wall lamp"
            },
            {
                "product_id": 37187,
                "product_name": "industrial strength sticky-back velcro\u00ae brand fastener strips , 4 x 2"
            },
            {
                "product_id": 39646,
                "product_name": "maegan industrial coat rack"
            },
            {
                "product_id": 19128,
                "product_name": "tony 1 - light dimmable black swing arm"
            },
            {
                "product_id": 5395,
                "product_name": "callis industrial dining table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 16958,
                "product_name": "andes industrial accent mirror"
            },
            {
                "product_id": 24189,
                "product_name": "wellman pub table"
            },
            {
                "product_id": 38604,
                "product_name": "porto industrial accent mirror"
            },
            {
                "product_id": 23945,
                "product_name": "port augusta industrial 32 '' bar stool"
            },
            {
                "product_id": 11553,
                "product_name": "esson industrial craftsman wall mounted coat rack"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 30826,
                "product_name": "belgr concrete propane/natural gas fire pit table"
            },
            {
                "product_id": 6053,
                "product_name": "hot water dispenser"
            },
            {
                "product_id": 40825,
                "product_name": "gefen geometric polygon rooster figurine"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            }
        ],
        "garOutput": [
            {
                "product_id": 39003,
                "product_name": "payden leather upholstered dining chair in espresso"
            },
            {
                "product_id": 39004,
                "product_name": "caitriona leather upholstered dining chair"
            },
            {
                "product_id": 19922,
                "product_name": "shonna 25.2 '' w closet rod"
            },
            {
                "product_id": 37850,
                "product_name": "exec height adjustable standing desk converter"
            },
            {
                "product_id": 29780,
                "product_name": "bee skep hanging figurine ornament"
            }
        ],
        "query": "industrial"
    },
    {
        "id": 155,
        "label": [
            {
                "product_id": 22820,
                "product_name": "lockridge solid wood and upholstered low profile standard bed"
            },
            {
                "product_id": 42954,
                "product_name": "addiline tufted upholstered low profile storage platform bed"
            },
            {
                "product_id": 4332,
                "product_name": "peters tufted upholstered low profile platform bed"
            },
            {
                "product_id": 7097,
                "product_name": "everdina floating desk with hutch"
            },
            {
                "product_id": 16344,
                "product_name": "atalaya insta-lock adjustable 6-leg bed frame"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 25354,
                "product_name": "grijalva tufted upholstered platform bed"
            },
            {
                "product_id": 29140,
                "product_name": "winni upholstered platform bed"
            },
            {
                "product_id": 26910,
                "product_name": "devery solid wood platform bed"
            },
            {
                "product_id": 10020,
                "product_name": "spadaro platform bed"
            },
            {
                "product_id": 20342,
                "product_name": "mathews bed riser"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 32495,
                "product_name": "dederang 2 '' bookshelf"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 11055,
                "product_name": "harcrest writing desk"
            },
            {
                "product_id": 3439,
                "product_name": "modern floating 2 piece wall shelf set"
            },
            {
                "product_id": 31016,
                "product_name": "sail boats 180 thread count sheet set"
            }
        ],
        "garOutput": [
            {
                "product_id": 37238,
                "product_name": "trigg wall d\u00e9cor"
            },
            {
                "product_id": 37237,
                "product_name": "trigg ceramic planter wall decor"
            },
            {
                "product_id": 13216,
                "product_name": "34.25 '' plastic square fold-in-half folding table"
            },
            {
                "product_id": 22604,
                "product_name": "componibili- 3 element"
            },
            {
                "product_id": 7167,
                "product_name": "abbottsmoor dining chair cushion"
            }
        ],
        "query": "floating bed"
    },
    {
        "id": 176,
        "label": [
            {
                "product_id": 40432,
                "product_name": "abstract strokes sun and rain paint - wrapped canvas graphic art print"
            },
            {
                "product_id": 8154,
                "product_name": "`` mexican skull-grey '' by nicklas gustafsson graphic art on canvas"
            },
            {
                "product_id": 37491,
                "product_name": "flamingo pattern framed graphic art"
            },
            {
                "product_id": 11780,
                "product_name": "brierfield pool float canvas art"
            },
            {
                "product_id": 37965,
                "product_name": "cut out watercolour of a flower by margaret nash - picture frame graphic art print on canvas"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 3248,
                "product_name": "palm sprints flamingo graphic art"
            },
            {
                "product_id": 31126,
                "product_name": "raining color flamingo graphic art"
            },
            {
                "product_id": 39147,
                "product_name": "`` champaign gold map '' graphic art on wrapped canvas"
            },
            {
                "product_id": 38597,
                "product_name": "new goals painted inspirational by pen + paint - textual art print on canvas"
            },
            {
                "product_id": 11899,
                "product_name": "ocean inspired sketch art of an animal with long tentacles marine life undersea grey white kitchen mat"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 40825,
                "product_name": "gefen geometric polygon rooster figurine"
            },
            {
                "product_id": 29144,
                "product_name": "mcdonald dinosaur lake canvas art"
            },
            {
                "product_id": 30826,
                "product_name": "belgr concrete propane/natural gas fire pit table"
            },
            {
                "product_id": 13858,
                "product_name": "the elephant graphic art"
            },
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 21217,
                "product_name": "raami 20 oz . cereal bowl"
            },
            {
                "product_id": 181,
                "product_name": "nuovo milano by ettore sottsass cake / pastry server"
            },
            {
                "product_id": 21222,
                "product_name": "raami 112 fl oz . serving bowl"
            },
            {
                "product_id": 13974,
                "product_name": "classic beaded 1-gang toggle light switch wall plate"
            },
            {
                "product_id": 13868,
                "product_name": "hawaii - graphic art print"
            }
        ],
        "query": "mexican art"
    },
    {
        "id": 253,
        "label": [
            {
                "product_id": 21526,
                "product_name": "millennium double handle widespread bathroom faucet with pop-up drain"
            },
            {
                "product_id": 1668,
                "product_name": "off center brass faucet"
            },
            {
                "product_id": 33942,
                "product_name": "eyesaver double handle widespread eyewash faucet"
            },
            {
                "product_id": 10090,
                "product_name": "victorian single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 6429,
                "product_name": "widespread faucet bathroom faucet"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 21825,
                "product_name": "mirage single hole bathroom faucet"
            },
            {
                "product_id": 21109,
                "product_name": "widespread faucet bathroom faucet"
            },
            {
                "product_id": 6430,
                "product_name": "widespread faucet bathroom faucet"
            },
            {
                "product_id": 23152,
                "product_name": "metropolitan brass pressure-balanced tub and shower faucet with rough-in valve"
            },
            {
                "product_id": 23329,
                "product_name": "purist widespread bathroom faucet with drain assembly"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 24966,
                "product_name": "nita single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 3197,
                "product_name": "double shower curtain hooks"
            },
            {
                "product_id": 1654,
                "product_name": "aaiyana striped sheer grommet single curtain panel"
            },
            {
                "product_id": 1657,
                "product_name": "solid color sheer grommet single curtain panel"
            },
            {
                "product_id": 38224,
                "product_name": "riggins 24 '' single bathroom vanity set"
            }
        ],
        "garOutput": [
            {
                "product_id": 24448,
                "product_name": "crenata solid wood coffee table"
            },
            {
                "product_id": 32933,
                "product_name": "storage and organization carry basket"
            },
            {
                "product_id": 12767,
                "product_name": "alhama 6 '' x 13 '' porcelain stone look wall & floor tile"
            },
            {
                "product_id": 33974,
                "product_name": "novelty knob"
            },
            {
                "product_id": 22325,
                "product_name": "cook pro professional pasta spoon"
            }
        ],
        "query": "antique brass faucet"
    },
    {
        "id": 483,
        "label": [
            {
                "product_id": 2181,
                "product_name": "hoobro under-desk printer stand , 2-tier printer cart with storage shelf , printer rack with lockable wheels , metal frame , industrial style in office , home office , copier , scanner , rustic brown"
            },
            {
                "product_id": 6056,
                "product_name": "millwood pines wood serving tray with handles , rustic nesting serving trays set of 3"
            },
            {
                "product_id": 5412,
                "product_name": "piggott rustic solid wood dining table"
            },
            {
                "product_id": 40955,
                "product_name": "highcliffe rustic beveled accent mirror"
            },
            {
                "product_id": 19777,
                "product_name": "swenson rustic distressed vanity mirror"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 23624,
                "product_name": "sconset solid wood ladder back side chair in rustic aged honey"
            },
            {
                "product_id": 35276,
                "product_name": "christmas decorations old fashioned concept twigs and on rustic wood vintage graphic print & text semi-sheer rod pocket curtain panels"
            },
            {
                "product_id": 3437,
                "product_name": "industrial shoe bench coat rack , 3-tier hall tree , 3 in 1 design , rustic brown"
            },
            {
                "product_id": 18117,
                "product_name": "elegance twig branch"
            },
            {
                "product_id": 25903,
                "product_name": "artificial budding twig 20 '' wreath"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 3728,
                "product_name": "bless our home metal sign"
            },
            {
                "product_id": 3731,
                "product_name": "lovey the owl"
            },
            {
                "product_id": 41836,
                "product_name": "liesbeth stool"
            },
            {
                "product_id": 13247,
                "product_name": "square multi-tiered plant stand"
            },
            {
                "product_id": 1179,
                "product_name": "real touch bouquet peonies stems"
            }
        ],
        "garOutput": [
            {
                "product_id": 18357,
                "product_name": "mckamey 4 - light candle style drum chandelier with crystal accents"
            },
            {
                "product_id": 8299,
                "product_name": "emmaline 6 - light candle style classic / traditional chandelier"
            },
            {
                "product_id": 33891,
                "product_name": "smith 5 - light candle style classic / traditional chandelier"
            },
            {
                "product_id": 2590,
                "product_name": "peacock maidens illuminated figurine"
            },
            {
                "product_id": 22831,
                "product_name": "matilde 4 - light candle style geometric chandelier"
            }
        ],
        "query": "rustic twig"
    },
    {
        "id": 339,
        "label": [
            {
                "product_id": 1610,
                "product_name": "3 pcs folding portable zero gravity reclining lounge chairs table-beige"
            },
            {
                "product_id": 42364,
                "product_name": "cantania chair"
            },
            {
                "product_id": 6865,
                "product_name": "ripon 27 '' wide tufted lounge chair"
            },
            {
                "product_id": 40599,
                "product_name": "robey upholstered dining chair"
            },
            {
                "product_id": 4710,
                "product_name": "alleyne drafting chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 6888,
                "product_name": "exucutive chair"
            },
            {
                "product_id": 17037,
                "product_name": "raziel kids club chair"
            },
            {
                "product_id": 41664,
                "product_name": "executive chair"
            },
            {
                "product_id": 19004,
                "product_name": "raicing chair"
            },
            {
                "product_id": 18591,
                "product_name": "arm chair in green"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            }
        ],
        "garOutput": [
            {
                "product_id": 19684,
                "product_name": "denilson 32.2 '' wide power lift assist standard recliner"
            },
            {
                "product_id": 3164,
                "product_name": "ergonomic pu leather high back office chair with flip-up armrest managerial chair executive chair desk chair computer chair"
            },
            {
                "product_id": 7811,
                "product_name": "cline bless this home anti-fatigue mat"
            },
            {
                "product_id": 7809,
                "product_name": "kole family time anti-fatigue mat"
            },
            {
                "product_id": 7815,
                "product_name": "kerry fluer de lis anti-fatigue mat"
            }
        ],
        "query": "ruckus chair"
    },
    {
        "id": 75,
        "label": [
            {
                "product_id": 25224,
                "product_name": "nashua 72 '' double bathroom vanity set"
            },
            {
                "product_id": 12958,
                "product_name": "3 piece bathroom accessor set"
            },
            {
                "product_id": 17429,
                "product_name": "bohan multiple chevron 9 piece bath set"
            },
            {
                "product_id": 39021,
                "product_name": "sheffield 60 '' single bathroom vanity set"
            },
            {
                "product_id": 15156,
                "product_name": "raminez 5 piece bathroom accessory set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 26292,
                "product_name": "chelsey stainless steel 7 piece bathroom accessory set"
            },
            {
                "product_id": 18566,
                "product_name": "sickler 4 piece bathroom accessory set"
            },
            {
                "product_id": 12960,
                "product_name": "cocoa 3 piece bathroom accessor set"
            },
            {
                "product_id": 16709,
                "product_name": "ziemer 60 '' bathroom vanity set"
            },
            {
                "product_id": 3212,
                "product_name": "braylei simple elegance 3 piece solid bath set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 32518,
                "product_name": "elite rack"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            }
        ],
        "garOutput": [
            {
                "product_id": 41086,
                "product_name": "interest bathroom faucet"
            },
            {
                "product_id": 14986,
                "product_name": "metal shower curtain hooks"
            },
            {
                "product_id": 41087,
                "product_name": "itap widerpread bathroom faucet"
            },
            {
                "product_id": 15813,
                "product_name": "joleena wall mounted robe hook"
            },
            {
                "product_id": 38887,
                "product_name": "karson 30 '' single bathroom vanity set with mirror"
            }
        ],
        "query": "sheffield home bath set"
    },
    {
        "id": 427,
        "label": [
            {
                "product_id": 30232,
                "product_name": "citified 2 '' x 6 '' porcelain subway wall & floor tile"
            },
            {
                "product_id": 24087,
                "product_name": "pike brazil nut 6 '' x 48 '' straight edge spc vinyl wood look wall & floor tile"
            },
            {
                "product_id": 21663,
                "product_name": "starburst 3 '' x 8 '' ceramic subway tile"
            },
            {
                "product_id": 27145,
                "product_name": "crema herringbone arched 1 '' x 3 '' marble mosaic tile"
            },
            {
                "product_id": 15695,
                "product_name": "piazza 8 '' x 8 '' porcelain concrete look wall & floor tile"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 33869,
                "product_name": "delphi subway 3 '' x 6 '' ceramic tile"
            },
            {
                "product_id": 26993,
                "product_name": "linear glossy random sized glass wood look tile in gray"
            },
            {
                "product_id": 27092,
                "product_name": "penny porcelain penny round mosaic wall & floor tile"
            },
            {
                "product_id": 27473,
                "product_name": "luxuriant 12 '' x 16 '' metal decorative mural tile"
            },
            {
                "product_id": 15090,
                "product_name": "agenda ceramic mosaic subway tile"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 18031,
                "product_name": "clermont entertainment center for tvs up to 78 ''"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            }
        ],
        "garOutput": [
            {
                "product_id": 4021,
                "product_name": "nini abstract teal indoor / outdoor area rug"
            },
            {
                "product_id": 4020,
                "product_name": "eure abstract ivory/blue indoor / outdoor area rug"
            },
            {
                "product_id": 33791,
                "product_name": "florid lantern 6.25 '' x 7.25 '' straight edge ceramic singular subway tile"
            },
            {
                "product_id": 4024,
                "product_name": "emyrie teal indoor / outdoor area rug"
            },
            {
                "product_id": 4022,
                "product_name": "gainford beige/teal/brown indoor / outdoor area rug"
            }
        ],
        "query": "lowes tile"
    },
    {
        "id": 15,
        "label": [
            {
                "product_id": 41162,
                "product_name": "orrington 5 drawer standard dresser"
            },
            {
                "product_id": 35421,
                "product_name": "amy-jayne 6 drawer dresser"
            },
            {
                "product_id": 38860,
                "product_name": "dendron 8 drawer double dresser"
            },
            {
                "product_id": 30460,
                "product_name": "sophia 6 drawer dresser"
            },
            {
                "product_id": 5487,
                "product_name": "ghugas 5 drawer dresser chest"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 5947,
                "product_name": "essex 4 drawer dresser"
            },
            {
                "product_id": 10041,
                "product_name": "pinnix 5 drawers lingerie chest/semainier"
            },
            {
                "product_id": 30851,
                "product_name": "capricorn 7 drawer dresser with mirror"
            },
            {
                "product_id": 26043,
                "product_name": "klaudia 5 drawer chest"
            },
            {
                "product_id": 28811,
                "product_name": "alyssa 4 drawer chest"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 14106,
                "product_name": "veronica 5 drawer chest"
            },
            {
                "product_id": 4321,
                "product_name": "dimitre 3 drawer chest"
            },
            {
                "product_id": 3291,
                "product_name": "zootles drawer pull multipack"
            }
        ],
        "garOutput": [
            {
                "product_id": 32383,
                "product_name": "pull-out side mount belt rack"
            },
            {
                "product_id": 32380,
                "product_name": "deep pull-out 18 '' w x 7 '' h drawer"
            },
            {
                "product_id": 32381,
                "product_name": "deep pull-out 18 '' w x 11 '' h drawer"
            },
            {
                "product_id": 32387,
                "product_name": "0.5 '' closet rod"
            },
            {
                "product_id": 32378,
                "product_name": "2.8 '' w pull-out tie rack"
            }
        ],
        "query": "black 5 drawer dresser by guilford"
    },
    {
        "id": 153,
        "label": [
            {
                "product_id": 27848,
                "product_name": "bohemian retro colorful flowers duvet cover set"
            },
            {
                "product_id": 35016,
                "product_name": "mangino vibrant bohemian chic paisley damask medallion duvet cover set"
            },
            {
                "product_id": 41807,
                "product_name": "rodeo bohemian aztec tribal ivory rug"
            },
            {
                "product_id": 17845,
                "product_name": "2 piece macrame wall decor set"
            },
            {
                "product_id": 10271,
                "product_name": "2 piece wall sconce set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 41809,
                "product_name": "bleecker street aidan bohemian vintage floral medallion gray/blue area rug"
            },
            {
                "product_id": 24835,
                "product_name": "modern bohemian utensil crock"
            },
            {
                "product_id": 40698,
                "product_name": "humphrey southwestern gray area rug"
            },
            {
                "product_id": 4133,
                "product_name": "kazuko crackle-glaze 20 piece dinnerware set , service for 4"
            },
            {
                "product_id": 41806,
                "product_name": "rodeo virden bohemian eclectic tribal aztec power loom blue rug"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            },
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 6053,
                "product_name": "hot water dispenser"
            }
        ],
        "garOutput": [
            {
                "product_id": 35708,
                "product_name": "`` regatta at argenteuil 1874 '' by pierre-auguste renoir framed painting print on wrapped canvas"
            },
            {
                "product_id": 35707,
                "product_name": "`` the bridge at argenteuil 1874 '' by claude monet framed painting print on wrapped canvas"
            },
            {
                "product_id": 30545,
                "product_name": "deco\u2122widespread bathroom faucet"
            },
            {
                "product_id": 35698,
                "product_name": "`` mexican lookdowns '' by amy vangsgard photographic print on wrapped canvas"
            },
            {
                "product_id": 35701,
                "product_name": "`` the bridge at argenteuil '' by claude monet painting print on wrapped canvas"
            }
        ],
        "query": "bohemian"
    },
    {
        "id": 471,
        "label": [
            {
                "product_id": 27600,
                "product_name": "purtell bar stool"
            },
            {
                "product_id": 29592,
                "product_name": "counter height fabric upholstered bar stool with full back , blue , set of 2"
            },
            {
                "product_id": 42729,
                "product_name": "marceline hydraulic adjustable height swivel bar stool"
            },
            {
                "product_id": 31695,
                "product_name": "sittingbourne swivel adjustable height bar stool"
            },
            {
                "product_id": 39938,
                "product_name": "molinaro swivel solid wood adjustable height bar stool"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 34237,
                "product_name": "karly swivel solid wood adjustable height bar stool"
            },
            {
                "product_id": 28193,
                "product_name": "newville swivel adjustable height bar stool"
            },
            {
                "product_id": 38057,
                "product_name": "wilhelm height adjustable lab stool with dual wheel"
            },
            {
                "product_id": 1233,
                "product_name": "sulli height adjustable active stool"
            },
            {
                "product_id": 9747,
                "product_name": "atoka swivel adjustable height counter stool"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 27793,
                "product_name": "adjustable height standing desk"
            },
            {
                "product_id": 25143,
                "product_name": "tonsey office chair"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 32281,
                "product_name": "metal slat back stacking side chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 40214,
                "product_name": "figueroa ecocomputer monitor stand riser and desktop organizer"
            },
            {
                "product_id": 7059,
                "product_name": "high-back ergonomic office chair"
            },
            {
                "product_id": 13905,
                "product_name": "alsdorf task chair"
            },
            {
                "product_id": 42636,
                "product_name": "darhyl 34.16 '' wide faux leather power lift assist standard recliner with massager"
            },
            {
                "product_id": 7794,
                "product_name": "midnight garden comfort anti-fatigue mat"
            }
        ],
        "query": "adjustable height artist stool"
    },
    {
        "id": 19,
        "label": [
            {
                "product_id": 30096,
                "product_name": "gurney slade solid wood tv stand for tvs up to 55 ''"
            },
            {
                "product_id": 34383,
                "product_name": "aitken 3 - light shaded tiered chandelier with wood accents"
            },
            {
                "product_id": 16504,
                "product_name": "gurney slade stainless steel decorative bowl"
            },
            {
                "product_id": 1431,
                "product_name": "betti 56-light unique / statement empire chandelier"
            },
            {
                "product_id": 5123,
                "product_name": "gurney slade upholstered bench"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 17994,
                "product_name": "gurney slade recycled teak 17.7 '' placemat"
            },
            {
                "product_id": 5155,
                "product_name": "gurney slade reindeer figurine"
            },
            {
                "product_id": 2473,
                "product_name": "bellefonte 13-light shaded tiered chandelier"
            },
            {
                "product_id": 2472,
                "product_name": "bellefonte 16-light shaded tiered chandelier"
            },
            {
                "product_id": 35025,
                "product_name": "gurney slade square wool pillow cover and insert"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 14501,
                "product_name": "26 '' wide side chair"
            },
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 13870,
                "product_name": "no . 4 motorcycle - print"
            },
            {
                "product_id": 10565,
                "product_name": "askewville i love it when my wife lets me ride my dirt bike funny partner design coffee mug"
            },
            {
                "product_id": 10566,
                "product_name": "libourne funny owl drink to that owl drinking wine pun cartoon coffee mug"
            },
            {
                "product_id": 37177,
                "product_name": "employee time report card"
            },
            {
                "product_id": 26646,
                "product_name": "international harvester melamine cereal bowl"
            }
        ],
        "query": "gurney  slade 56"
    },
    {
        "id": 316,
        "label": [
            {
                "product_id": 18258,
                "product_name": "modern electronic power recliner with 360 swivel rotation , usb charging port and footrest , brown"
            },
            {
                "product_id": 32468,
                "product_name": "edmeo 40.6 '' wide velvet power standard recliner"
            },
            {
                "product_id": 16790,
                "product_name": "minogue 32 '' wide power lift assist standard recliner"
            },
            {
                "product_id": 23980,
                "product_name": "abiagael 33.2 '' wide power lift assist standard recliner"
            },
            {
                "product_id": 32081,
                "product_name": "mccandlish 31 '' wide faux leather power glider standard recliner"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 20764,
                "product_name": "anvita 32.3 '' wide power lift assist standard recliner"
            },
            {
                "product_id": 36023,
                "product_name": "hampig 33.5 '' wide power lift assist recliner"
            },
            {
                "product_id": 4003,
                "product_name": "demetress 35 '' wide power lift assist standard recliner"
            },
            {
                "product_id": 30163,
                "product_name": "capo 41 '' wide faux leather power lift assist standard recliner"
            },
            {
                "product_id": 28605,
                "product_name": "oris 30 '' wide power lift assist standard recliner with massager"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20888,
                "product_name": "mowat 41 '' wide power lift assist recliner"
            },
            {
                "product_id": 8573,
                "product_name": "14 '' oil powered outdoor lantern"
            },
            {
                "product_id": 2226,
                "product_name": "anarosa 33.4 '' wide power lift assist standard recliner with massager"
            },
            {
                "product_id": 30458,
                "product_name": "kendal shoe storage bench"
            },
            {
                "product_id": 21866,
                "product_name": "90 '' 4-burner propane/natural gas"
            }
        ],
        "garOutput": [
            {
                "product_id": 19684,
                "product_name": "denilson 32.2 '' wide power lift assist standard recliner"
            },
            {
                "product_id": 36068,
                "product_name": "caroline 31.1 '' wide power lift assist standard recliner with massager"
            },
            {
                "product_id": 27031,
                "product_name": "selbyville ergonomic office chair"
            },
            {
                "product_id": 27030,
                "product_name": "seeneytown ergonomic office chair"
            },
            {
                "product_id": 19889,
                "product_name": "dioseline 34 '' wide velvet power lift assist standard recliner"
            }
        ],
        "query": "rolande heavy duty power lift assist recliner"
    },
    {
        "id": 325,
        "label": [
            {
                "product_id": 41218,
                "product_name": "westfall chest"
            },
            {
                "product_id": 22627,
                "product_name": "lisette 37 gallon resin deck box"
            },
            {
                "product_id": 17473,
                "product_name": "140 gallon water resistant wicker deck box in gray"
            },
            {
                "product_id": 33666,
                "product_name": "120 gallon deck box"
            },
            {
                "product_id": 12097,
                "product_name": "burkhart 73.97 gallon plastic deck box"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 31098,
                "product_name": "patio chic\u2122 56 gallon resin deck box"
            },
            {
                "product_id": 40889,
                "product_name": "22 gallon plastic/resin storage bench"
            },
            {
                "product_id": 1158,
                "product_name": "kalil chest"
            },
            {
                "product_id": 7965,
                "product_name": "gosnell 2-piece outdoor patio furniture taupe , open-weave wicker love seat w warm gray cushions , storage chest , 2 turquoise lumbar pillows"
            },
            {
                "product_id": 21868,
                "product_name": "outdoor 105 gallon resin deck box"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 34495,
                "product_name": "adriany task chair"
            },
            {
                "product_id": 14495,
                "product_name": "twin over full bunk bed"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 42924,
                "product_name": "white 100 '' portable folding frame projection screen"
            },
            {
                "product_id": 9128,
                "product_name": "abstract power loom wool/polyester red area rug"
            }
        ],
        "garOutput": [
            {
                "product_id": 7553,
                "product_name": "6 pair stackable shoe rack"
            },
            {
                "product_id": 4445,
                "product_name": "3 piece kitchen canister set"
            },
            {
                "product_id": 738,
                "product_name": "runner groverson geometric blue area rug"
            },
            {
                "product_id": 739,
                "product_name": "runner groversen geometric gray area rug"
            },
            {
                "product_id": 7558,
                "product_name": "12 pair shoe rack"
            }
        ],
        "query": "outdoor waterproof chest"
    },
    {
        "id": 56,
        "label": [
            {
                "product_id": 249,
                "product_name": "chip upholstered bar & counter stool"
            },
            {
                "product_id": 29583,
                "product_name": "evendale upholstered side chair"
            },
            {
                "product_id": 13732,
                "product_name": "tomoe swivel 25 '' counter stool"
            },
            {
                "product_id": 9438,
                "product_name": "lebow counter & bar stool"
            },
            {
                "product_id": 3324,
                "product_name": "evans swivel bar & counter stool"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 14247,
                "product_name": "margie swivel bar & counter stool"
            },
            {
                "product_id": 17220,
                "product_name": "mannion velvet side chair in blue"
            },
            {
                "product_id": 15430,
                "product_name": "hartung swivel adjustable height bar stool"
            },
            {
                "product_id": 33465,
                "product_name": "set of 2 counter height chair , 24-inch height , brown"
            },
            {
                "product_id": 25591,
                "product_name": "augie metal 31 '' counter height bar stool"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 38673,
                "product_name": "abdulsamet coffee table"
            },
            {
                "product_id": 25143,
                "product_name": "tonsey office chair"
            },
            {
                "product_id": 34495,
                "product_name": "adriany task chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 25551,
                "product_name": "schwartz 27 '' wide tufted polyester wingback chair"
            },
            {
                "product_id": 19578,
                "product_name": "culbreath solid wood queen anne back side chair"
            },
            {
                "product_id": 28228,
                "product_name": "classic genuine leather upholstered dining chair"
            },
            {
                "product_id": 5174,
                "product_name": "senna 31 '' wide tufted velvet armchair"
            },
            {
                "product_id": 9742,
                "product_name": "malcolm tufted velvet upholstered wingback dining chair"
            }
        ],
        "query": "royal blue counter height chairs"
    },
    {
        "id": 355,
        "label": [
            {
                "product_id": 29940,
                "product_name": "autumn on the seine at argenteuil by claude monet - picture frame painting print on canvas"
            },
            {
                "product_id": 19432,
                "product_name": "wild poppies near argenteuil by claude monet painting print on rolled wrapped canvas"
            },
            {
                "product_id": 19443,
                "product_name": "`` argenteuil '' by claude monet framed painting print"
            },
            {
                "product_id": 8244,
                "product_name": "autumn at argenteuil by claude monet - picture frame painting print on canvas"
            },
            {
                "product_id": 35699,
                "product_name": "the bridge at argenteuil by claude monet framed painting print"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 29937,
                "product_name": "walk in the meadows at argenteuil by claude monet - picture frame painting print on canvas"
            },
            {
                "product_id": 35699,
                "product_name": "the bridge at argenteuil by claude monet framed painting print"
            },
            {
                "product_id": 35469,
                "product_name": "the promenade near argenteuil by monet painting on canvas"
            },
            {
                "product_id": 13859,
                "product_name": "regatta at argenteuil by claude monet framed painting print"
            },
            {
                "product_id": 29761,
                "product_name": "la grand rue , argenteuil sisley framed painting"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 32138,
                "product_name": "cliffland 82 '' etagere bookcase"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            }
        ],
        "garOutput": [
            {
                "product_id": 8246,
                "product_name": "red boats at argenteuil by claude monet - picture frame painting print on canvas"
            },
            {
                "product_id": 35708,
                "product_name": "`` regatta at argenteuil 1874 '' by pierre-auguste renoir framed painting print on wrapped canvas"
            },
            {
                "product_id": 29936,
                "product_name": "the garden of monet at argenteuil by claude monet picture frame painting on canvas"
            },
            {
                "product_id": 38583,
                "product_name": "eiffel tower textual art wall plaque"
            },
            {
                "product_id": 8948,
                "product_name": "zak 5 - light shaded classic / traditional chandelier"
            }
        ],
        "query": "argenteuil"
    },
    {
        "id": 214,
        "label": [
            {
                "product_id": 29260,
                "product_name": "dessus 2 - drawer nightstand in light brown"
            },
            {
                "product_id": 25129,
                "product_name": "evalisse 1 - drawer nightstand in light brown"
            },
            {
                "product_id": 41726,
                "product_name": "bae mid-century modern 3-tier wood nightstand"
            },
            {
                "product_id": 27652,
                "product_name": "arruda 2 - drawer solid wood nightstand in brown"
            },
            {
                "product_id": 7063,
                "product_name": "jayson 2 - drawer solid wood nightstand"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 8649,
                "product_name": "mcspadden 2 - drawer nightstand in light gray"
            },
            {
                "product_id": 499,
                "product_name": "larrabee 2 - drawer solid wood nightstand in black"
            },
            {
                "product_id": 27042,
                "product_name": "wellinhall 1 - drawer solid wood nightstand"
            },
            {
                "product_id": 41391,
                "product_name": "plaskitt 2 - drawer nightstand in light brown"
            },
            {
                "product_id": 27444,
                "product_name": "samons 2 - drawer nightstand in light brown"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 32808,
                "product_name": "36 '' card table"
            },
            {
                "product_id": 37554,
                "product_name": "plutarch frame coffee table"
            },
            {
                "product_id": 19250,
                "product_name": "emert beveled distressed accent mirror"
            },
            {
                "product_id": 38673,
                "product_name": "abdulsamet coffee table"
            },
            {
                "product_id": 18473,
                "product_name": "akadia block coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 19371,
                "product_name": "2 - drawer nightstand in light brown"
            },
            {
                "product_id": 5343,
                "product_name": "ceniceros wooden chairside end table"
            },
            {
                "product_id": 37148,
                "product_name": "aashis 2 - drawer end table"
            },
            {
                "product_id": 20878,
                "product_name": "eluan abstract coffee table"
            },
            {
                "product_id": 5345,
                "product_name": "julianna end table with storage"
            }
        ],
        "query": "light wood nightstand with silver accents"
    },
    {
        "id": 438,
        "label": [
            {
                "product_id": 17958,
                "product_name": "resin rock fountain"
            },
            {
                "product_id": 2389,
                "product_name": "metal changing fountain with led light"
            },
            {
                "product_id": 2229,
                "product_name": "chewey resin fengshui zen meditation fountain with light"
            },
            {
                "product_id": 29274,
                "product_name": "clarks polyresin elephant table fountain with led light"
            },
            {
                "product_id": 13618,
                "product_name": "stainless steel solar water pump submersible bore hole deep fountain"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 33228,
                "product_name": "jossa resin/fiberglass water fountain with light"
            },
            {
                "product_id": 16788,
                "product_name": "resin buddha wall fountain with light"
            },
            {
                "product_id": 5332,
                "product_name": "resin water fountain"
            },
            {
                "product_id": 411,
                "product_name": "drucker fiberglass and resin water well fountain"
            },
            {
                "product_id": 13431,
                "product_name": "lower hounsley resin modern sphere zen outdoor floor water fountain with light"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8740,
                "product_name": "alessandro rectangular multi-tiered plant stand"
            },
            {
                "product_id": 8753,
                "product_name": "4 tier plant stand garden planter metal flower pot rack corner shelving indoor outdoor"
            },
            {
                "product_id": 13247,
                "product_name": "square multi-tiered plant stand"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 5332,
                "product_name": "resin water fountain"
            },
            {
                "product_id": 2483,
                "product_name": "maio chaise lounge"
            },
            {
                "product_id": 1295,
                "product_name": "vendome chaise lounge"
            },
            {
                "product_id": 13629,
                "product_name": "bedroom led nightstand , black/white modern high gloss led lights nightstand bedside end table with 2 drawers table cabinet with 2 drawers ( black )"
            },
            {
                "product_id": 16646,
                "product_name": "modern high gloss nightstand white night stand with led light , double drawer nightstand cabinet storage bedside tables with drawer 2-tier for bedroom living room"
            }
        ],
        "query": "indoor water fountain"
    },
    {
        "id": 226,
        "label": [
            {
                "product_id": 2635,
                "product_name": "beniamino 34 '' wide faux leather power lift assist ergonomic recliner with massager"
            },
            {
                "product_id": 34377,
                "product_name": "34.25 '' wide chenille power lift assist home theater recliner with massager"
            },
            {
                "product_id": 34505,
                "product_name": "lyster 33 '' wide power lift assist standard recliner"
            },
            {
                "product_id": 38169,
                "product_name": "dhiman 34.6 '' wide velvet power lift assist standard recliner"
            },
            {
                "product_id": 32267,
                "product_name": "floribel 33.5 '' wide power lift assist standard recliner"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 7788,
                "product_name": "aryian 38.2 '' wide velvet power lift assist ergonomic recliner with massager"
            },
            {
                "product_id": 17689,
                "product_name": "29.1 '' wide power lift assist standard recliner"
            },
            {
                "product_id": 2845,
                "product_name": "hadnot 33.07 '' wide faux leather power lift assist standard recliner"
            },
            {
                "product_id": 20020,
                "product_name": "cardstone 35 '' wide power lift assist ergonomic recliner"
            },
            {
                "product_id": 25938,
                "product_name": "elizondo 27.8 '' wide leather match power lift assist ergonomic recliner"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 27793,
                "product_name": "adjustable height standing desk"
            },
            {
                "product_id": 2233,
                "product_name": "tv stand for tvs up to 50 ''"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 39073,
                "product_name": "aimey 32.3 '' wide power lift assist standard recliner"
            },
            {
                "product_id": 32482,
                "product_name": "krause 16 '' height profile platform bed"
            }
        ],
        "garOutput": [
            {
                "product_id": 19684,
                "product_name": "denilson 32.2 '' wide power lift assist standard recliner"
            },
            {
                "product_id": 36068,
                "product_name": "caroline 31.1 '' wide power lift assist standard recliner with massager"
            },
            {
                "product_id": 37154,
                "product_name": "alaxis 33 '' wide faux leather power lift assist standard recliner"
            },
            {
                "product_id": 42636,
                "product_name": "darhyl 34.16 '' wide faux leather power lift assist standard recliner with massager"
            },
            {
                "product_id": 19893,
                "product_name": "dipi 35 '' wide power lift assist standard recliner with massager"
            }
        ],
        "query": "power lift chair"
    },
    {
        "id": 241,
        "label": [
            {
                "product_id": 34557,
                "product_name": "digital frame desktop wall clock"
            },
            {
                "product_id": 15247,
                "product_name": "oversized 36 '' wall clock"
            },
            {
                "product_id": 2896,
                "product_name": "oversized kensa 30 '' wall clock"
            },
            {
                "product_id": 32184,
                "product_name": "oversized rosalinde decorative 23.5 '' wall clock"
            },
            {
                "product_id": 36752,
                "product_name": "galaxy glow in the dark poly resin clock with thermometer 14 '' wall clock"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 13269,
                "product_name": "oversized angel religious african american wall clock"
            },
            {
                "product_id": 13327,
                "product_name": "giorno wall clock"
            },
            {
                "product_id": 13333,
                "product_name": "menorca wall clock"
            },
            {
                "product_id": 37135,
                "product_name": "adalynne 15 '' wall clock"
            },
            {
                "product_id": 39884,
                "product_name": "melendez wall clock"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 19777,
                "product_name": "swenson rustic distressed vanity mirror"
            },
            {
                "product_id": 1654,
                "product_name": "aaiyana striped sheer grommet single curtain panel"
            },
            {
                "product_id": 1657,
                "product_name": "solid color sheer grommet single curtain panel"
            },
            {
                "product_id": 38224,
                "product_name": "riggins 24 '' single bathroom vanity set"
            },
            {
                "product_id": 12350,
                "product_name": "rossmere indoor/outdoor bench cushion"
            }
        ],
        "garOutput": [
            {
                "product_id": 12528,
                "product_name": "grid sliding hanging organizer"
            },
            {
                "product_id": 18899,
                "product_name": "custom life anchor wall d\u00e9cor"
            },
            {
                "product_id": 19353,
                "product_name": "wooden custom mimosa bar wall d\u00e9cor"
            },
            {
                "product_id": 310,
                "product_name": "roclincourt 3 legs 2 nesting tables"
            },
            {
                "product_id": 29011,
                "product_name": "silke 3 legs 2 nesting tables"
            }
        ],
        "query": "decorative wall clocks"
    },
    {
        "id": 414,
        "label": [
            {
                "product_id": 2119,
                "product_name": "double bathroom stainless steel wall mounted towel hook"
            },
            {
                "product_id": 7460,
                "product_name": "hkah-015 roller shower curtain hooks"
            },
            {
                "product_id": 9754,
                "product_name": "birch shower curtain hooks"
            },
            {
                "product_id": 23407,
                "product_name": "lanita coat and hat wall hook"
            },
            {
                "product_id": 13983,
                "product_name": "single prong wall mounted robe hook"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 34903,
                "product_name": "premium yin shower curtain hooks"
            },
            {
                "product_id": 27383,
                "product_name": "ilano wall mounted robe hook"
            },
            {
                "product_id": 23716,
                "product_name": "giannini large robe/coat wall hook"
            },
            {
                "product_id": 15389,
                "product_name": "ledesma over the door wall hook"
            },
            {
                "product_id": 37615,
                "product_name": "flex double shower curtain hooks"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 3197,
                "product_name": "double shower curtain hooks"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 21200,
                "product_name": "cutts bar with wine storage"
            },
            {
                "product_id": 39407,
                "product_name": "allicen 2 -drawer storage cabinet"
            },
            {
                "product_id": 35693,
                "product_name": "anello 20 '' w closet system reach-in sets"
            }
        ],
        "garOutput": [
            {
                "product_id": 10103,
                "product_name": "windemere pressure balanced tub and shower faucet trim with lever handles and monitor"
            },
            {
                "product_id": 42917,
                "product_name": "large head shower caddy"
            },
            {
                "product_id": 10121,
                "product_name": "cassidy wall shelf"
            },
            {
                "product_id": 20421,
                "product_name": "kent free standing toilet paper holder"
            },
            {
                "product_id": 21235,
                "product_name": "coral rectangular pillow cover & insert"
            }
        ],
        "query": "shower hooks"
    },
    {
        "id": 25,
        "label": [
            {
                "product_id": 2735,
                "product_name": "sunflower seeds vintage advertisement"
            },
            {
                "product_id": 28009,
                "product_name": "spring floral standing sunflower figure"
            },
            {
                "product_id": 31125,
                "product_name": "sunflower sky photographic print"
            },
            {
                "product_id": 17244,
                "product_name": "arawn sunflower 30 in . x 18 in . non-slip outdoor door mat"
            },
            {
                "product_id": 11848,
                "product_name": "simple sunflower"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 28403,
                "product_name": "22 '' polyester wreath"
            },
            {
                "product_id": 11010,
                "product_name": "sunflowers drying mat"
            },
            {
                "product_id": 770,
                "product_name": "artificial sunflower bush"
            },
            {
                "product_id": 38025,
                "product_name": "asst sunflowers printed 2 piece dish cloth set"
            },
            {
                "product_id": 2756,
                "product_name": "moten garden sunflower welcome sign wall d\u00e9cor"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 1179,
                "product_name": "real touch bouquet peonies stems"
            },
            {
                "product_id": 34073,
                "product_name": "swag"
            },
            {
                "product_id": 11542,
                "product_name": "aloe vera desktop succulent plant"
            },
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            }
        ],
        "garOutput": [
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 23947,
                "product_name": "be the light 20 '' table lamp"
            },
            {
                "product_id": 27495,
                "product_name": "1-up hard cover pet brag album"
            },
            {
                "product_id": 26781,
                "product_name": "tavernier barn fairy garden"
            },
            {
                "product_id": 6293,
                "product_name": "montano garden statue"
            }
        ],
        "query": "sunflower"
    },
    {
        "id": 425,
        "label": [
            {
                "product_id": 22844,
                "product_name": "bouler solid wood coffee table with storage"
            },
            {
                "product_id": 40557,
                "product_name": "benson floor shelf coffee table with storage"
            },
            {
                "product_id": 4255,
                "product_name": "campania floor shelf coffee table with storage"
            },
            {
                "product_id": 23862,
                "product_name": "fortuna coffee table with storage"
            },
            {
                "product_id": 1093,
                "product_name": "eamon end table with storage"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 3581,
                "product_name": "marquetta woven side end table"
            },
            {
                "product_id": 36233,
                "product_name": "puleo coffee table with storage"
            },
            {
                "product_id": 34596,
                "product_name": "tollett end table with storage"
            },
            {
                "product_id": 7184,
                "product_name": "walhill end table with storage"
            },
            {
                "product_id": 31057,
                "product_name": "olin drum coffee table with storage"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 30158,
                "product_name": "hershberger buffet table"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 17223,
                "product_name": "buffet storage cabinet with single glass doors and unique bell handle"
            }
        ],
        "garOutput": [
            {
                "product_id": 32471,
                "product_name": "epoxy wire shelving unit"
            },
            {
                "product_id": 33664,
                "product_name": "iaconetti storage cabinet"
            },
            {
                "product_id": 33665,
                "product_name": "delasandro metal storage cabinet"
            },
            {
                "product_id": 13504,
                "product_name": "teak tripod end table"
            },
            {
                "product_id": 9973,
                "product_name": "fruit vegetable wire basket set"
            }
        ],
        "query": "side table with storage"
    },
    {
        "id": 132,
        "label": [
            {
                "product_id": 2247,
                "product_name": "aadi rectangle aquarium stand"
            },
            {
                "product_id": 323,
                "product_name": "henriksen coastal 2 door bar cabinet"
            },
            {
                "product_id": 19600,
                "product_name": "30 '' cabinet bathroom vanity base only"
            },
            {
                "product_id": 38381,
                "product_name": "jett 6 - shelf storage cabinet"
            },
            {
                "product_id": 33128,
                "product_name": "delago storage cabinet"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 31866,
                "product_name": "landis office storage cabinet"
            },
            {
                "product_id": 19598,
                "product_name": "24 '' cabinet vanity base"
            },
            {
                "product_id": 35414,
                "product_name": "mirabel 6 -shelf storage cabinet"
            },
            {
                "product_id": 10405,
                "product_name": "elcid 18 - shelf storage cabinet"
            },
            {
                "product_id": 31521,
                "product_name": "lewisburg 68 '' kitchen pantry"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 3728,
                "product_name": "bless our home metal sign"
            },
            {
                "product_id": 8227,
                "product_name": "aemilia 4 door accent cabinet"
            },
            {
                "product_id": 5959,
                "product_name": "marple 42 '' wide 2 drawer server"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            }
        ],
        "garOutput": [
            {
                "product_id": 31371,
                "product_name": "ruskin extendable dining table"
            },
            {
                "product_id": 931,
                "product_name": "rooster window shutter wall d\u00e9cor"
            },
            {
                "product_id": 7501,
                "product_name": "top mount sliding standard single track barn door hardware kit"
            },
            {
                "product_id": 23266,
                "product_name": "poplin 36 in . vanity with furniture legs , 1 door and 3 drawers on right"
            },
            {
                "product_id": 31898,
                "product_name": "mesa cocktail table , heather grey"
            }
        ],
        "query": "farmhouse cabinet"
    },
    {
        "id": 232,
        "label": [
            {
                "product_id": 26211,
                "product_name": "bairdstown 5 drawer chest"
            },
            {
                "product_id": 24626,
                "product_name": "eisley 6 drawer double dresser"
            },
            {
                "product_id": 14109,
                "product_name": "dewees 6 drawer double dresser"
            },
            {
                "product_id": 25750,
                "product_name": "kirk 3 drawer dresser"
            },
            {
                "product_id": 3629,
                "product_name": "elosie 6 drawer double dresser"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 33119,
                "product_name": "abinante 6 drawer standard dresser"
            },
            {
                "product_id": 34223,
                "product_name": "jahkira 5 drawer dresser"
            },
            {
                "product_id": 34827,
                "product_name": "sastre 6 drawer standard dresser"
            },
            {
                "product_id": 30179,
                "product_name": "6 - drawer storage cabinet"
            },
            {
                "product_id": 24750,
                "product_name": "locklear 9 drawer combo dresser"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 36032,
                "product_name": "deeksha storage cabinet"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            }
        ],
        "garOutput": [
            {
                "product_id": 26458,
                "product_name": "brassfield freestanding wardrobe armoire"
            },
            {
                "product_id": 27981,
                "product_name": "spacemaker 134.5 gallon metal deck box"
            },
            {
                "product_id": 35149,
                "product_name": "ellenburg printer storage cabinet"
            },
            {
                "product_id": 1849,
                "product_name": "morrell standard bookcase"
            },
            {
                "product_id": 6384,
                "product_name": "zanders armoire with mirror sliding doors"
            }
        ],
        "query": "storage dresser"
    },
    {
        "id": 481,
        "label": [
            {
                "product_id": 12116,
                "product_name": "br\u00f6gida upholstered panel headboard"
            },
            {
                "product_id": 11819,
                "product_name": "moroccan queen upholstered panel headboard"
            },
            {
                "product_id": 34203,
                "product_name": "isobe bookcase headboard"
            },
            {
                "product_id": 33371,
                "product_name": "upholstered wingback headboard"
            },
            {
                "product_id": 11818,
                "product_name": "moroccan upholstered panel headboard"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 11814,
                "product_name": "antique twin upholstered panel headboard"
            },
            {
                "product_id": 3124,
                "product_name": "silas upholstered wingback headboard"
            },
            {
                "product_id": 35172,
                "product_name": "spark twin bookcase headboard"
            },
            {
                "product_id": 39921,
                "product_name": "christophe upholstered panel headboard"
            },
            {
                "product_id": 1948,
                "product_name": "legrand bookcase headboard"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 11055,
                "product_name": "harcrest writing desk"
            },
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 20718,
                "product_name": "abramowski monitor stand"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            }
        ],
        "garOutput": [
            {
                "product_id": 9532,
                "product_name": "tamura home throw pillow"
            },
            {
                "product_id": 1105,
                "product_name": "new haven sled coffee table"
            },
            {
                "product_id": 40076,
                "product_name": "hertford cotton throw pillow in , no fill"
            },
            {
                "product_id": 29124,
                "product_name": "delfazio twin platform bed"
            },
            {
                "product_id": 30887,
                "product_name": "rosalida twin platform bed"
            }
        ],
        "query": "emma headboard"
    },
    {
        "id": 42,
        "label": [
            {
                "product_id": 42319,
                "product_name": "enviro-child kids sofa"
            },
            {
                "product_id": 5935,
                "product_name": "basel 3 in 1 fold out monkey kids recliner"
            },
            {
                "product_id": 37090,
                "product_name": "rolled arm kids sleeper sofa"
            },
            {
                "product_id": 28590,
                "product_name": "little lux kids sofa"
            },
            {
                "product_id": 14362,
                "product_name": "czech toddler flip zebra kids sleeper"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 38496,
                "product_name": "glasgo kids sofa"
            },
            {
                "product_id": 20619,
                "product_name": "ainslie kids sofa with ottoman"
            },
            {
                "product_id": 7587,
                "product_name": "leeann kids foam sofa"
            },
            {
                "product_id": 42520,
                "product_name": "jovanni kids chair and ottoman"
            },
            {
                "product_id": 36519,
                "product_name": "spitzer kids club sofa"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 19185,
                "product_name": "erzsebet 33 '' wide power lift assist standard recliner"
            },
            {
                "product_id": 26887,
                "product_name": "capems 65.35 '' faux leather pillow top arm sleeper"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 30158,
                "product_name": "hershberger buffet table"
            }
        ],
        "garOutput": [
            {
                "product_id": 42513,
                "product_name": "forman kids recliner and ottoman with cup holder"
            },
            {
                "product_id": 38481,
                "product_name": "vonda kids recliner with ottoman storage compartment"
            },
            {
                "product_id": 5935,
                "product_name": "basel 3 in 1 fold out monkey kids recliner"
            },
            {
                "product_id": 25325,
                "product_name": "elmo 2 in 1 flip open kids foam sofa"
            },
            {
                "product_id": 25326,
                "product_name": "pixar cars 2 in 1 flip open kids foam couch"
            }
        ],
        "query": "toddler couch fold out"
    },
    {
        "id": 366,
        "label": [],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            },
            {
                "product_id": 13098,
                "product_name": "premium heavyweight 9 '' melamine salad or dessert plate"
            }
        ],
        "garOutput": [
            {
                "product_id": 33797,
                "product_name": "strait wood 3 '' x 12 '' ceramic subway tile in blue/black"
            },
            {
                "product_id": 8152,
                "product_name": "the owl by nicklas gustafsson - wrapped canvas graphic art print"
            },
            {
                "product_id": 28950,
                "product_name": "22 can playmate gripper polartherm cooler"
            },
            {
                "product_id": 37208,
                "product_name": "minifile storage cabinet"
            },
            {
                "product_id": 40322,
                "product_name": "yearly dry erase calendar whiteboard wall decal"
            }
        ],
        "query": "drudge report"
    },
    {
        "id": 412,
        "label": [
            {
                "product_id": 30596,
                "product_name": "delephine upholstered tufted upholstered low profile platform bed"
            },
            {
                "product_id": 1252,
                "product_name": "seaborn twin platform bed"
            },
            {
                "product_id": 9672,
                "product_name": "eckert solid wood low profile platform bed"
            },
            {
                "product_id": 5030,
                "product_name": "cayleen tufted upholstered standard bed"
            },
            {
                "product_id": 42372,
                "product_name": "pomfret upholstered low profile standard bed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 22654,
                "product_name": "dionn solid wood low profile platform bed"
            },
            {
                "product_id": 8951,
                "product_name": "wadley queen contemporary wood upholstered storage platform bed"
            },
            {
                "product_id": 15519,
                "product_name": "caylee low profile sleigh bed"
            },
            {
                "product_id": 31118,
                "product_name": "adeliya queen tufted upholstered standard bed"
            },
            {
                "product_id": 18987,
                "product_name": "doersten tufted upholstered low profile standard bed"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 26887,
                "product_name": "capems 65.35 '' faux leather pillow top arm sleeper"
            },
            {
                "product_id": 25227,
                "product_name": "office ergonomic polyurethane conference chair"
            },
            {
                "product_id": 27793,
                "product_name": "adjustable height standing desk"
            }
        ],
        "garOutput": [
            {
                "product_id": 32245,
                "product_name": "elington tufted upholstered platform bed"
            },
            {
                "product_id": 32412,
                "product_name": "louque queen upholstered platform bed"
            },
            {
                "product_id": 4960,
                "product_name": "baldivis 35 '' wide polyester armchair"
            },
            {
                "product_id": 33505,
                "product_name": "avia upholstered low profile four poster bed"
            },
            {
                "product_id": 29140,
                "product_name": "winni upholstered platform bed"
            }
        ],
        "query": "jennie tufted upholstered low profile platform bed"
    },
    {
        "id": 140,
        "label": [
            {
                "product_id": 27895,
                "product_name": "bodi root metal/wire basket"
            },
            {
                "product_id": 35957,
                "product_name": "oval wire basket with wooden handles"
            },
            {
                "product_id": 30512,
                "product_name": "garden metal/wire basket"
            },
            {
                "product_id": 26538,
                "product_name": "metal / wire basket"
            },
            {
                "product_id": 41508,
                "product_name": "shaunie storage organizer with wall baskets"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 9980,
                "product_name": "3 tier fruit wire basket"
            },
            {
                "product_id": 33997,
                "product_name": "stackable storage organizer metal basket"
            },
            {
                "product_id": 6670,
                "product_name": "ivanka slanted ladder wire basket"
            },
            {
                "product_id": 38417,
                "product_name": "square wire basket"
            },
            {
                "product_id": 8765,
                "product_name": "scoop stacking metal/wire basket"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 12253,
                "product_name": "sydney kitchen cart with butcher block top"
            },
            {
                "product_id": 30594,
                "product_name": "aguie 43.31 '' steel bed frame"
            },
            {
                "product_id": 32482,
                "product_name": "krause 16 '' height profile platform bed"
            },
            {
                "product_id": 2233,
                "product_name": "tv stand for tvs up to 50 ''"
            },
            {
                "product_id": 39991,
                "product_name": "bathroom rain volume shower system rough-in valve"
            }
        ],
        "garOutput": [
            {
                "product_id": 26473,
                "product_name": "abdinour 2 tier kitchen corner rack prep table"
            },
            {
                "product_id": 6672,
                "product_name": "berkey cachepot 4 piece jute basket set"
            },
            {
                "product_id": 27615,
                "product_name": "slim laundry hamper"
            },
            {
                "product_id": 7556,
                "product_name": "8 tier cabinet door organizer"
            },
            {
                "product_id": 21297,
                "product_name": "alayah 8.5 '' w stackable storage drawer"
            }
        ],
        "query": "wire basket with dividers"
    },
    {
        "id": 464,
        "label": [
            {
                "product_id": 181,
                "product_name": "nuovo milano by ettore sottsass cake / pastry server"
            },
            {
                "product_id": 3062,
                "product_name": "aliene cutlery 60 piece 18/10 stainless steel flatware set , service for 12"
            },
            {
                "product_id": 37982,
                "product_name": "viking professional 8 inch bread knife"
            },
            {
                "product_id": 13195,
                "product_name": "perrigo 6 piece cake serving set"
            },
            {
                "product_id": 30900,
                "product_name": "baby basic plastic disposable forks"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 538,
                "product_name": "a cut above cutlery pro stainless series 8 '' bread knife"
            },
            {
                "product_id": 38763,
                "product_name": "paderno world cuisine large crinkle knife"
            },
            {
                "product_id": 42143,
                "product_name": "tiara cake knife & server set"
            },
            {
                "product_id": 15928,
                "product_name": "stuyvesant marble bread knife , 8 in ."
            },
            {
                "product_id": 4081,
                "product_name": "ralph kramer finesse 72 piece 18/10 stainless steel flatware set , service for 12"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 890,
                "product_name": "sapphira reversible quilt set"
            },
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 16453,
                "product_name": "global knives pasta kitchen tongs"
            },
            {
                "product_id": 38673,
                "product_name": "abdulsamet coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 23568,
                "product_name": "hufnagel cake stand"
            },
            {
                "product_id": 32383,
                "product_name": "pull-out side mount belt rack"
            },
            {
                "product_id": 32380,
                "product_name": "deep pull-out 18 '' w x 7 '' h drawer"
            },
            {
                "product_id": 32381,
                "product_name": "deep pull-out 18 '' w x 11 '' h drawer"
            },
            {
                "product_id": 7564,
                "product_name": "decorative storage 43.98 '' h x 43.98 '' w cube bookcase"
            }
        ],
        "query": "cake cutlery"
    },
    {
        "id": 212,
        "label": [
            {
                "product_id": 15213,
                "product_name": "aydyn 5 - piece rubberwood solid wood dining set"
            },
            {
                "product_id": 40283,
                "product_name": "alingtons 5 - piece rubberwood dining set"
            },
            {
                "product_id": 7052,
                "product_name": "lavertue 5 - piece rubberwood solid wood dining set"
            },
            {
                "product_id": 8975,
                "product_name": "parishville 5 piece bar set"
            },
            {
                "product_id": 15216,
                "product_name": "ayeh 5 - piece rubberwood solid wood dining set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 15208,
                "product_name": "alyta 5 - piece rubberwood dining set"
            },
            {
                "product_id": 505,
                "product_name": "drayton patio 5 piece bistro set"
            },
            {
                "product_id": 24547,
                "product_name": "aaidan 5 piece counter height dining set"
            },
            {
                "product_id": 34347,
                "product_name": "ballenger 5 - piece dining set"
            },
            {
                "product_id": 22758,
                "product_name": "espresso topmax 5-piece bar table set , counter height bar table with 4 bar stools , bistro style bar table and stool"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 347,
                "product_name": "1.93 '' solar powered outdoor floating light"
            },
            {
                "product_id": 9766,
                "product_name": "ferrigno 6 piece bathroom accessory set"
            },
            {
                "product_id": 1447,
                "product_name": "enclosed mobile av cabinet in putty"
            },
            {
                "product_id": 21866,
                "product_name": "90 '' 4-burner propane/natural gas"
            },
            {
                "product_id": 9624,
                "product_name": "military challenge coin cabinet rack holder display case"
            }
        ],
        "garOutput": [
            {
                "product_id": 23541,
                "product_name": "seese end table"
            },
            {
                "product_id": 38447,
                "product_name": "skuli kids cotton club chair"
            },
            {
                "product_id": 24466,
                "product_name": "kids chair"
            },
            {
                "product_id": 29960,
                "product_name": "daugherty 3 piece coffee table set"
            },
            {
                "product_id": 28480,
                "product_name": "leal 30 '' wide papasan chair"
            }
        ],
        "query": "nettie 5 pc bar set"
    },
    {
        "id": 195,
        "label": [
            {
                "product_id": 6524,
                "product_name": "55 '' x 30 '' bathtub with faucet"
            },
            {
                "product_id": 33384,
                "product_name": "67 '' x 30 '' freestanding soaking fiberglass bathtub with faucet"
            },
            {
                "product_id": 33395,
                "product_name": "68 '' x 34 '' clawfoot soaking acrylic bathtub"
            },
            {
                "product_id": 30467,
                "product_name": "jaida 1-handle tub and shower faucet with valve"
            },
            {
                "product_id": 11402,
                "product_name": "67 '' x 31 '' freestanding soaking bathtub"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 11035,
                "product_name": "daphne 59 '' x 30 '' freestanding soaking fiberglass bathtub"
            },
            {
                "product_id": 39034,
                "product_name": "rachel 59 '' x 30 '' freestanding soaking bathtub"
            },
            {
                "product_id": 22249,
                "product_name": "composed freestanding bath faucet"
            },
            {
                "product_id": 39033,
                "product_name": "hannah 67 '' x 32 '' freestanding soaking bathtub"
            },
            {
                "product_id": 23121,
                "product_name": "tub and shower faucet with rough-in valve and trim"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 25869,
                "product_name": "42 '' single bathroom vanity"
            },
            {
                "product_id": 38224,
                "product_name": "riggins 24 '' single bathroom vanity set"
            },
            {
                "product_id": 24966,
                "product_name": "nita single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 3197,
                "product_name": "double shower curtain hooks"
            },
            {
                "product_id": 19777,
                "product_name": "swenson rustic distressed vanity mirror"
            }
        ],
        "garOutput": [
            {
                "product_id": 3953,
                "product_name": "waterfall widespread bathroom faucet with drain assembly"
            },
            {
                "product_id": 3952,
                "product_name": "led waterfall widespread bathroom faucet"
            },
            {
                "product_id": 4687,
                "product_name": "artificial phalaenopsis single orchid floral arrangement in planter"
            },
            {
                "product_id": 41488,
                "product_name": "aultman enameled metal 2 piece soap dish set"
            },
            {
                "product_id": 10568,
                "product_name": "zidane adorable owl mother and owl kid coffee mug"
            }
        ],
        "query": "black freestanding tub  with faucet"
    },
    {
        "id": 455,
        "label": [
            {
                "product_id": 8805,
                "product_name": "serino coat rack"
            },
            {
                "product_id": 23674,
                "product_name": "manzanola wall mounted coat rack"
            },
            {
                "product_id": 28098,
                "product_name": "ramah coat rack"
            },
            {
                "product_id": 40976,
                "product_name": "lund solid wood 8 - hook wall mounted coat rack"
            },
            {
                "product_id": 36930,
                "product_name": "moose 5 hook coat rack"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 19688,
                "product_name": "solid brass coat and hat wall hook"
            },
            {
                "product_id": 23740,
                "product_name": "faucett reclaimed wall mounted coat hook"
            },
            {
                "product_id": 19221,
                "product_name": "sodhi 5 - hook wall mounted coat rack"
            },
            {
                "product_id": 23383,
                "product_name": "decorative ball wall mounted end coat and hat hook"
            },
            {
                "product_id": 8083,
                "product_name": "duhon coat rack"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 3728,
                "product_name": "bless our home metal sign"
            },
            {
                "product_id": 3196,
                "product_name": "hang ease c type shower curtain hooks"
            },
            {
                "product_id": 21994,
                "product_name": "home decorative heart shaped metal wall sign inscribed `` home sweet home ''"
            },
            {
                "product_id": 32281,
                "product_name": "metal slat back stacking side chair"
            },
            {
                "product_id": 8227,
                "product_name": "aemilia 4 door accent cabinet"
            }
        ],
        "garOutput": [
            {
                "product_id": 11321,
                "product_name": "2 piece spoon and fork metal wall d\u00e9cor set"
            },
            {
                "product_id": 30443,
                "product_name": "lesperance 28.5 '' wide papasan chair"
            },
            {
                "product_id": 36930,
                "product_name": "moose 5 hook coat rack"
            },
            {
                "product_id": 29395,
                "product_name": "henriquez 30.75 '' wide armchair"
            },
            {
                "product_id": 23413,
                "product_name": "builders program 17 5/8 '' center to center bar pull"
            }
        ],
        "query": "deer coat hooks"
    },
    {
        "id": 22,
        "label": [
            {
                "product_id": 23741,
                "product_name": "kingsburg decorative cotton throw pillow"
            },
            {
                "product_id": 7499,
                "product_name": "ambrose cute owl decorative cotton throw pillow"
            },
            {
                "product_id": 11936,
                "product_name": "ambesonne grunge curtains , contemporary art inspiration with dots in cold colors freezing cool winter ice , window treatments 2 panel set for living room bedroom decor , 56 '' x 63 '' , pale blue grey room darkening rod pocket curtain panels"
            },
            {
                "product_id": 13363,
                "product_name": "peak inspire decorative square pillow cover & insert"
            },
            {
                "product_id": 13390,
                "product_name": "real undoubtedly decorative square pillow cover & insert"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 21037,
                "product_name": "stripe collection navy blue throw pillows - set of 2"
            },
            {
                "product_id": 534,
                "product_name": "balyon square cotton pillow cover and insert"
            },
            {
                "product_id": 13414,
                "product_name": "slick inspire decorative square pillow cover & insert"
            },
            {
                "product_id": 13374,
                "product_name": "galore light decorative square pillow cover & insert"
            },
            {
                "product_id": 1497,
                "product_name": "autry textured contemporary throw pillow"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 22762,
                "product_name": "ulmer kids cotton sleeper"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 18489,
                "product_name": "aurelie throw pillow in light blue"
            }
        ],
        "garOutput": [
            {
                "product_id": 4169,
                "product_name": "liverman end table"
            },
            {
                "product_id": 21037,
                "product_name": "stripe collection navy blue throw pillows - set of 2"
            },
            {
                "product_id": 12169,
                "product_name": "holli zollinger zodiac leo outdoor square pillow cover & insert"
            },
            {
                "product_id": 17910,
                "product_name": "meditation"
            },
            {
                "product_id": 31083,
                "product_name": "chevron wall mounted calendar board"
            }
        ],
        "query": "light and navy blue decorative pillow"
    },
    {
        "id": 428,
        "label": [
            {
                "product_id": 33620,
                "product_name": "classic series 5- shelf storage cabinet"
            },
            {
                "product_id": 25644,
                "product_name": "desandre storage cabinet"
            },
            {
                "product_id": 6331,
                "product_name": "12 pair shoe storage cabinet"
            },
            {
                "product_id": 36492,
                "product_name": "dewhurst baleare 79 '' pantry cabinet"
            },
            {
                "product_id": 1906,
                "product_name": "caines armoire"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 39411,
                "product_name": "akina storage cabinet"
            },
            {
                "product_id": 7026,
                "product_name": "secure storage cabinet"
            },
            {
                "product_id": 18783,
                "product_name": "2 door accent cabinet"
            },
            {
                "product_id": 35401,
                "product_name": "coral cape storage armoire"
            },
            {
                "product_id": 22446,
                "product_name": "modular closet shoe storage cabinet"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 5959,
                "product_name": "marple 42 '' wide 2 drawer server"
            },
            {
                "product_id": 36032,
                "product_name": "deeksha storage cabinet"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 7261,
                "product_name": "trower 34.3 '' h x 27.9 '' w x 13.6 '' d foldable storage shelves"
            },
            {
                "product_id": 287,
                "product_name": "duru 70.86 '' h x 39.37 '' w iron etagere bookcase"
            },
            {
                "product_id": 20625,
                "product_name": "rolling storage cart"
            },
            {
                "product_id": 1614,
                "product_name": "bulwell 23.5 '' w x 31.5 '' h x 12 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 8785,
                "product_name": "12 '' w x 31.5 '' h x 12 '' d free-standing bathroom cabinet"
            }
        ],
        "query": "kitchen storage cabinet"
    },
    {
        "id": 46,
        "label": [
            {
                "product_id": 18373,
                "product_name": "pasko full/queen panel headboard"
            },
            {
                "product_id": 3124,
                "product_name": "silas upholstered wingback headboard"
            },
            {
                "product_id": 37772,
                "product_name": "flickinger upholstered panel headboard"
            },
            {
                "product_id": 3083,
                "product_name": "turgeon full/queen upholstered panel headboard"
            },
            {
                "product_id": 9740,
                "product_name": "heng upholstered panel headboard"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 14815,
                "product_name": "larraine upholstered panel headboard"
            },
            {
                "product_id": 9399,
                "product_name": "findlay upholstered panel headboard"
            },
            {
                "product_id": 14854,
                "product_name": "halford upholstered panel headboard"
            },
            {
                "product_id": 33400,
                "product_name": "upholstered panel headboard"
            },
            {
                "product_id": 14805,
                "product_name": "reiner upholstered panel headboard"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 27333,
                "product_name": "berengere 4 '' cotton futon mattress"
            },
            {
                "product_id": 27336,
                "product_name": "vintage floral japanese 4 '' cotton futon mattress"
            },
            {
                "product_id": 27335,
                "product_name": "vintage floral japanese 4 '' cotton futon mattress"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            }
        ],
        "garOutput": [
            {
                "product_id": 40407,
                "product_name": "neppie square cotton pillow cover"
            },
            {
                "product_id": 17924,
                "product_name": "courtois summer indoor/outdoor throw pillow"
            },
            {
                "product_id": 30441,
                "product_name": "vintage motorcycle"
            },
            {
                "product_id": 5230,
                "product_name": "frederica cotton ikat lumbar pillow"
            },
            {
                "product_id": 3012,
                "product_name": "diamondville 20.5 '' table lamp set"
            }
        ],
        "query": "beach blue headboard"
    },
    {
        "id": 126,
        "label": [
            {
                "product_id": 21132,
                "product_name": "bellefonte upholstered dining chair"
            },
            {
                "product_id": 5565,
                "product_name": "katelyn upholstered side chair"
            },
            {
                "product_id": 14104,
                "product_name": "35 '' wide genuine leather club chair"
            },
            {
                "product_id": 1786,
                "product_name": "milton solid wood dining chair"
            },
            {
                "product_id": 22089,
                "product_name": "universal elastic dining chair covers 3d bubble lattice slipcover with skirt ( set of 4 ) -dining chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 5903,
                "product_name": "raab upholstered dining chair"
            },
            {
                "product_id": 38687,
                "product_name": "aonesty upholstered dining chair"
            },
            {
                "product_id": 17384,
                "product_name": "charlton vintage side chair"
            },
            {
                "product_id": 17383,
                "product_name": "charlton vintage upholstered side chair"
            },
            {
                "product_id": 11060,
                "product_name": "rockcale genuine leather upholstered dining chair"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 40457,
                "product_name": "ranbir 39 '' wide velvet chesterfield chair"
            },
            {
                "product_id": 31143,
                "product_name": "amilliah tufted upholstered parsons chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 602,
                "product_name": "sled coffee table"
            },
            {
                "product_id": 7167,
                "product_name": "abbottsmoor dining chair cushion"
            },
            {
                "product_id": 608,
                "product_name": "coffee table"
            },
            {
                "product_id": 609,
                "product_name": "brontes river desk"
            },
            {
                "product_id": 607,
                "product_name": "abstract coffee table"
            }
        ],
        "query": "leather dining chairs"
    },
    {
        "id": 406,
        "label": [
            {
                "product_id": 16120,
                "product_name": "33 '' energy star french door 24.7 cu . ft. refrigerator"
            },
            {
                "product_id": 10931,
                "product_name": "signature series 24 '' undercounter beverage refrigerator"
            },
            {
                "product_id": 34954,
                "product_name": "24 '' counter depth bottom freezer 12.5 cu . ft. refrigerator with ice maker"
            },
            {
                "product_id": 16139,
                "product_name": "ge smart appliances 33 '' counter depth french door 18.6 cu . ft. energy star refrigerator"
            },
            {
                "product_id": 16183,
                "product_name": "36 '' french door 27.7 cu . ft. smart energy star refrigerator with fingerprint resistant finish"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 9016,
                "product_name": "54 qt . portable refrigerator cooler"
            },
            {
                "product_id": 4607,
                "product_name": "28 '' energy star counter depth bottom freezer 16.79 cu . ft. refrigerator with auto ice maker"
            },
            {
                "product_id": 12440,
                "product_name": "80 can freestanding beverage refrigerator"
            },
            {
                "product_id": 3442,
                "product_name": "800 series 36 '' counter depth 21 cu . ft. smart energy star french door refrigerator with flex bar"
            },
            {
                "product_id": 33568,
                "product_name": "36 '' side by side 27.4 cu . ft. energy star refrigerator"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 35693,
                "product_name": "anello 20 '' w closet system reach-in sets"
            },
            {
                "product_id": 12457,
                "product_name": "linen dual cabinet laundry hamper"
            },
            {
                "product_id": 41093,
                "product_name": "maire 23.6 '' w x 31.5 '' h x 13 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 1093,
                "product_name": "eamon end table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 27495,
                "product_name": "1-up hard cover pet brag album"
            },
            {
                "product_id": 38132,
                "product_name": "geometric teal indoor / outdoor area rug"
            },
            {
                "product_id": 27530,
                "product_name": "chipley 37 '' wide faux leather power lift assist standard recliner"
            },
            {
                "product_id": 2182,
                "product_name": "3-tier printer cart with storage shelf mobile printer stand end table home office"
            }
        ],
        "query": "refrigerator with ice an water in door"
    },
    {
        "id": 131,
        "label": [
            {
                "product_id": 5994,
                "product_name": "imogen upholstered side chair in gray"
            },
            {
                "product_id": 28963,
                "product_name": "46 '' wide wingback chair"
            },
            {
                "product_id": 13004,
                "product_name": "tufted velvet side chair in white"
            },
            {
                "product_id": 38772,
                "product_name": "darville patio chair"
            },
            {
                "product_id": 6715,
                "product_name": "parryville rocking chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 38496,
                "product_name": "glasgo kids sofa"
            },
            {
                "product_id": 3164,
                "product_name": "ergonomic pu leather high back office chair with flip-up armrest managerial chair executive chair desk chair computer chair"
            },
            {
                "product_id": 10303,
                "product_name": "sleeper folding floor game chair"
            },
            {
                "product_id": 13826,
                "product_name": "aldo 30 '' wide barrel chair"
            },
            {
                "product_id": 42156,
                "product_name": "footse leather arm chair"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 31143,
                "product_name": "amilliah tufted upholstered parsons chair"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            },
            {
                "product_id": 18943,
                "product_name": "sink leg"
            },
            {
                "product_id": 8751,
                "product_name": "large kids play house heavy duty canvas cotton with floor mat for bedroom indoor outdoor"
            }
        ],
        "garOutput": [
            {
                "product_id": 19684,
                "product_name": "denilson 32.2 '' wide power lift assist standard recliner"
            },
            {
                "product_id": 38719,
                "product_name": "briarhill patio reclining chaise lounge with cushion"
            },
            {
                "product_id": 20549,
                "product_name": "cresswell armless stackable chair"
            },
            {
                "product_id": 41491,
                "product_name": "kymber round cotton pouf"
            },
            {
                "product_id": 20589,
                "product_name": "jetson kids chair"
            }
        ],
        "query": "kids chair"
    },
    {
        "id": 426,
        "label": [
            {
                "product_id": 1537,
                "product_name": "henninger 25 '' tufted round storage ottoman"
            },
            {
                "product_id": 13120,
                "product_name": "124 gallon plasic deck box"
            },
            {
                "product_id": 21200,
                "product_name": "cutts bar with wine storage"
            },
            {
                "product_id": 35880,
                "product_name": "talavera 63.38 '' h x 27.95 '' w x 13.18 '' d folding storage shelving unit"
            },
            {
                "product_id": 41325,
                "product_name": "7 ft x 4 ft metal garage shed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 1029,
                "product_name": "candelario bar with wine storage"
            },
            {
                "product_id": 29735,
                "product_name": "peacock storage cube"
            },
            {
                "product_id": 5073,
                "product_name": "bressyln 32 '' tufted rectangle standard with storage ottoman"
            },
            {
                "product_id": 13776,
                "product_name": "riverside 10 ft. w x 14 ft. d storage shed"
            },
            {
                "product_id": 8576,
                "product_name": "wine bar with wine storage"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 12696,
                "product_name": "white 163 '' portable projection screen"
            },
            {
                "product_id": 34604,
                "product_name": "metal bed"
            },
            {
                "product_id": 8753,
                "product_name": "4 tier plant stand garden planter metal flower pot rack corner shelving indoor outdoor"
            },
            {
                "product_id": 8740,
                "product_name": "alessandro rectangular multi-tiered plant stand"
            }
        ],
        "garOutput": [
            {
                "product_id": 33624,
                "product_name": "overhead garage ceiling storage rack"
            },
            {
                "product_id": 1205,
                "product_name": "outdoor 96 gallon rattan deck box"
            },
            {
                "product_id": 5845,
                "product_name": "pools yard tools porch backyard 63 gallon plastic deck box"
            },
            {
                "product_id": 20434,
                "product_name": "hardyal 2.9 gallon waste basket"
            },
            {
                "product_id": 20237,
                "product_name": "standard single barn door hardware kit"
            }
        ],
        "query": "outdoor storage"
    },
    {
        "id": 478,
        "label": [
            {
                "product_id": 9730,
                "product_name": "cesar genuine leather upholstered dining chair"
            },
            {
                "product_id": 1272,
                "product_name": "demars leather task chair"
            },
            {
                "product_id": 36824,
                "product_name": "anasophia tufted velvet upholstered side chair in gray"
            },
            {
                "product_id": 34137,
                "product_name": "leather upholstered solid wood arm chair in black"
            },
            {
                "product_id": 18432,
                "product_name": "leather upholstered arm chair in white"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 28687,
                "product_name": "office chair"
            },
            {
                "product_id": 28568,
                "product_name": "gaming chair with footrest adjustable backrest reclining leather office chair"
            },
            {
                "product_id": 34299,
                "product_name": "cheng 32.67 '' wide top grain leather barrel chair"
            },
            {
                "product_id": 39651,
                "product_name": "jepsen 37 '' wide tufted top grain leather club chair"
            },
            {
                "product_id": 39012,
                "product_name": "erissa leather upholstered dining chair in gray"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 18461,
                "product_name": "adalaina executive chair"
            },
            {
                "product_id": 18460,
                "product_name": "adala executive chair"
            },
            {
                "product_id": 32281,
                "product_name": "metal slat back stacking side chair"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 38311,
                "product_name": "modern wood hexagon 1 '' x 1 '' pvc/metal peel & stick mosaic tile"
            },
            {
                "product_id": 5844,
                "product_name": "outdoor 60 gallon wicker storage bench"
            },
            {
                "product_id": 1685,
                "product_name": "audel 30 '' tufted square storage ottoman"
            },
            {
                "product_id": 10876,
                "product_name": "timmie kitchen heart anti-fatigue mat"
            },
            {
                "product_id": 29815,
                "product_name": "tuloma wall mounted robe hook"
            }
        ],
        "query": "worn leather office chair"
    },
    {
        "id": 57,
        "label": [
            {
                "product_id": 32167,
                "product_name": "patricia rocking chair"
            },
            {
                "product_id": 5573,
                "product_name": "burchfield solid wood side chair in black"
            },
            {
                "product_id": 14225,
                "product_name": "cedar rocking chair"
            },
            {
                "product_id": 38562,
                "product_name": "mabe solid wood rocking adirondack chair"
            },
            {
                "product_id": 5380,
                "product_name": "levin wooden rocking chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 39088,
                "product_name": "aftan rocking chair"
            },
            {
                "product_id": 38563,
                "product_name": "mabe two-person solid wood rocking adirondack chair"
            },
            {
                "product_id": 18220,
                "product_name": "yedinak 5 piece solid wood dining set"
            },
            {
                "product_id": 39790,
                "product_name": "kemah twin solid wood standard bunk bed"
            },
            {
                "product_id": 33148,
                "product_name": "joyrides peacock rocker"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 1179,
                "product_name": "real touch bouquet peonies stems"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 17737,
                "product_name": "donovan twin daybed with trundle"
            },
            {
                "product_id": 41247,
                "product_name": "stem print by rae dunn 5 qt . tea jar"
            }
        ],
        "garOutput": [
            {
                "product_id": 39224,
                "product_name": "wayfair basics indoor & outdoor 2 piece rocking chair cushion set"
            },
            {
                "product_id": 10659,
                "product_name": "wanda atlantic solid wood rocking adirondack chair"
            },
            {
                "product_id": 3735,
                "product_name": "stroman rocking chair"
            },
            {
                "product_id": 19201,
                "product_name": "galesville outdoor rocking chair"
            },
            {
                "product_id": 39162,
                "product_name": "rocking chair seat/back cushion"
            }
        ],
        "query": "jordanna solid wood rocking"
    },
    {
        "id": 177,
        "label": [
            {
                "product_id": 18099,
                "product_name": "400 series storage cabinet"
            },
            {
                "product_id": 30184,
                "product_name": "mobile storage filing cabinet"
            },
            {
                "product_id": 22670,
                "product_name": "20-pair shoe storage cabinet"
            },
            {
                "product_id": 3220,
                "product_name": "4 - shelf filing storage cabinet"
            },
            {
                "product_id": 33649,
                "product_name": "jessamine storage cabinet"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 22626,
                "product_name": "66 '' h x 27 '' w x 15 '' d tall utility storage cabinet"
            },
            {
                "product_id": 32395,
                "product_name": "balido 4 doors storage cabinet"
            },
            {
                "product_id": 26881,
                "product_name": "saravia office storage cabinet"
            },
            {
                "product_id": 17216,
                "product_name": "lismore 12 pair shoe storage cabinet"
            },
            {
                "product_id": 31820,
                "product_name": "gavin 12 pair shoe storage cabinet"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8227,
                "product_name": "aemilia 4 door accent cabinet"
            },
            {
                "product_id": 5959,
                "product_name": "marple 42 '' wide 2 drawer server"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 27281,
                "product_name": "tessa solid wood dining table"
            },
            {
                "product_id": 24672,
                "product_name": "woolridge antique 2 door accent cabinet"
            },
            {
                "product_id": 23410,
                "product_name": "fouts industrial single prong wall hook"
            },
            {
                "product_id": 23407,
                "product_name": "lanita coat and hat wall hook"
            },
            {
                "product_id": 30250,
                "product_name": "heid coat rack"
            }
        ],
        "query": "rustic storage cabinet"
    },
    {
        "id": 294,
        "label": [
            {
                "product_id": 34884,
                "product_name": "classic anystream 1.5 gpm multi-function adjustable shower head"
            },
            {
                "product_id": 22248,
                "product_name": "hydrorail s-set with artifacts 2.0 gpm shower head and hand shower featuring katalyst air-induction spray"
            },
            {
                "product_id": 27224,
                "product_name": "solid brass rain handheld shower head"
            },
            {
                "product_id": 12344,
                "product_name": "vintage thermostatic pressure balanced tub and shower faucet with solid brass shower head with porcelain lever handles"
            },
            {
                "product_id": 26563,
                "product_name": "kingsley posi-temp shower less shower head"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 42247,
                "product_name": "cye handheld multi function dual shower head"
            },
            {
                "product_id": 27668,
                "product_name": "multi function handheld shower head with magnetic"
            },
            {
                "product_id": 22247,
                "product_name": "rite temp hydrorail-s kit with awaken 2.0 gpm shower head and hand shower"
            },
            {
                "product_id": 22246,
                "product_name": "forte kit , 1.75 gpm multi function handheld shower head"
            },
            {
                "product_id": 27670,
                "product_name": "engage multi-function handshower and rainshower pressured-balanced tub and shower faucet with rough-in valve and magnetix"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 19708,
                "product_name": "waterfall single hole bathroom faucet"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 19717,
                "product_name": "commercial single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 19728,
                "product_name": "lavatory single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 19729,
                "product_name": "waterfall single hole bathroom faucet with drain assembly"
            }
        ],
        "garOutput": [
            {
                "product_id": 13577,
                "product_name": "turquoise painted poppy indoor/outdoor throw pillow"
            },
            {
                "product_id": 20454,
                "product_name": "hansford 6 '' x 6 '' ceramic field tile"
            },
            {
                "product_id": 16047,
                "product_name": "flush mount lighting alexi 1-light flush mount"
            },
            {
                "product_id": 2104,
                "product_name": "icelandia 1-light wall sconce"
            },
            {
                "product_id": 36778,
                "product_name": "galilea modern coffee table"
            }
        ],
        "query": "moen multi function dual shower head"
    },
    {
        "id": 408,
        "label": [
            {
                "product_id": 27804,
                "product_name": "zumalai beautiful young girl sitting on a tree statue"
            },
            {
                "product_id": 35908,
                "product_name": "two owls figurine"
            },
            {
                "product_id": 27805,
                "product_name": "tecca 2 girls reading a book statue"
            },
            {
                "product_id": 13053,
                "product_name": "gratiano boy reading statue"
            },
            {
                "product_id": 6293,
                "product_name": "montano garden statue"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 17961,
                "product_name": "sitting buddha statue"
            },
            {
                "product_id": 1358,
                "product_name": "oboyle meditation statue"
            },
            {
                "product_id": 36668,
                "product_name": "sebastian reading child garden statue"
            },
            {
                "product_id": 27964,
                "product_name": "girl sitting on books and reading statue"
            },
            {
                "product_id": 37885,
                "product_name": "qasim cement girl statue"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 32495,
                "product_name": "dederang 2 '' bookshelf"
            },
            {
                "product_id": 32287,
                "product_name": "study time - boy reading garden statue"
            },
            {
                "product_id": 13858,
                "product_name": "the elephant graphic art"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 11055,
                "product_name": "harcrest writing desk"
            }
        ],
        "garOutput": [
            {
                "product_id": 13863,
                "product_name": "pausing in a novel - unframed print"
            },
            {
                "product_id": 42600,
                "product_name": "aleena trooper garden gnome statue"
            },
            {
                "product_id": 17660,
                "product_name": "31 '' single vanity set"
            },
            {
                "product_id": 17661,
                "product_name": "37 '' single vanity set"
            },
            {
                "product_id": 42311,
                "product_name": "cadott peacocks 2 piece garden statue set"
            }
        ],
        "query": "girl reading statue"
    },
    {
        "id": 24,
        "label": [
            {
                "product_id": 8399,
                "product_name": "newlin end table with storage"
            },
            {
                "product_id": 25681,
                "product_name": "carney sled coffee table"
            },
            {
                "product_id": 34762,
                "product_name": "kingstown marigot center coffee table"
            },
            {
                "product_id": 40107,
                "product_name": "haines end table with storage"
            },
            {
                "product_id": 23576,
                "product_name": "dilley coffee table with storage"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 8410,
                "product_name": "alina lift top coffee table with storage"
            },
            {
                "product_id": 14543,
                "product_name": "merlyn 8-piece patio wicker corner sofa with cushions , ottoman and coffee table"
            },
            {
                "product_id": 4434,
                "product_name": "madewell rattan coffee table with storage"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 1933,
                "product_name": "quiles end table with storage"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 38244,
                "product_name": "agirta extendable dining table"
            },
            {
                "product_id": 5959,
                "product_name": "marple 42 '' wide 2 drawer server"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            }
        ],
        "garOutput": [
            {
                "product_id": 14039,
                "product_name": "concordia solid wood end table"
            },
            {
                "product_id": 17776,
                "product_name": "crellin solid wood coffee table"
            },
            {
                "product_id": 33182,
                "product_name": "furniture"
            },
            {
                "product_id": 39644,
                "product_name": "evas hall tree"
            },
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            }
        ],
        "query": "wood coffee table set by storage"
    },
    {
        "id": 17,
        "label": [
            {
                "product_id": 12532,
                "product_name": "grid 48 '' w - 96 '' w closet system reach-in sets"
            },
            {
                "product_id": 36873,
                "product_name": "spengler 17.72 '' w closet organizer"
            },
            {
                "product_id": 1193,
                "product_name": "home storage and org . 69 '' w closet system"
            },
            {
                "product_id": 36336,
                "product_name": "billington 61 '' w closet system walk-in tower"
            },
            {
                "product_id": 11206,
                "product_name": "modular storage 69 '' w - 91 '' w closet system reach-in sets"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 17904,
                "product_name": "petrucci 69 '' h x 18 '' w x 53 '' d non-woven fabric clothing storage closet organizer"
            },
            {
                "product_id": 20348,
                "product_name": "aguiar stacking plastic 12.05 '' storage drawer"
            },
            {
                "product_id": 1192,
                "product_name": "home storage and org . 47 '' w closet system"
            },
            {
                "product_id": 31327,
                "product_name": "rigdon 35 '' w closet system type"
            },
            {
                "product_id": 542,
                "product_name": "waytrim fabric 4 drawers storage organizer unit easy assembly , vertical dresser storage tower for closet , bedroom , entryway , brown"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 36032,
                "product_name": "deeksha storage cabinet"
            },
            {
                "product_id": 21200,
                "product_name": "cutts bar with wine storage"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 3197,
                "product_name": "double shower curtain hooks"
            }
        ],
        "garOutput": [
            {
                "product_id": 33624,
                "product_name": "overhead garage ceiling storage rack"
            },
            {
                "product_id": 42289,
                "product_name": "wire basket set"
            },
            {
                "product_id": 11209,
                "product_name": "4 drawer storage chest"
            },
            {
                "product_id": 20348,
                "product_name": "aguiar stacking plastic 12.05 '' storage drawer"
            },
            {
                "product_id": 17577,
                "product_name": "woven 2 piece plastic basket set"
            }
        ],
        "query": "closet storage with zipper"
    },
    {
        "id": 66,
        "label": [
            {
                "product_id": 38219,
                "product_name": "14x10 solid wood pergola"
            },
            {
                "product_id": 17878,
                "product_name": "8 ft. d aluminum pergola"
            },
            {
                "product_id": 33329,
                "product_name": "yamba metal pergola with canopy"
            },
            {
                "product_id": 26400,
                "product_name": "santorini 10 ft. w x 10 ft. d vinyl pergola"
            },
            {
                "product_id": 41319,
                "product_name": "8 ft. w x 5 ft. d metal pergola with canopy"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 41450,
                "product_name": "breeze 12 ft. w x 16 ft. d solid wood pergola"
            },
            {
                "product_id": 41452,
                "product_name": "breeze 8 ft. w x 10 ft. d solid wood pergola"
            },
            {
                "product_id": 30964,
                "product_name": "10 ft. w x 12 ft. d aluminum pergola with canopy"
            },
            {
                "product_id": 41448,
                "product_name": "breeze 10 ft. w x 12 ft. d solid wood pergola"
            },
            {
                "product_id": 26402,
                "product_name": "ios modern 10 ft. w x 10 ft. d vinyl pergola"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 20085,
                "product_name": "dario 12 - light candle style wagon wheel chandelier"
            },
            {
                "product_id": 10741,
                "product_name": "caren 3-piece lattice basket terracotta pot planter set"
            },
            {
                "product_id": 8317,
                "product_name": "tufted armless chaise lounge"
            }
        ],
        "garOutput": [
            {
                "product_id": 33666,
                "product_name": "120 gallon deck box"
            },
            {
                "product_id": 29685,
                "product_name": "9.5 ft. w x 10 ft. d greenhouse"
            },
            {
                "product_id": 19353,
                "product_name": "wooden custom mimosa bar wall d\u00e9cor"
            },
            {
                "product_id": 29706,
                "product_name": "outdoor 2-tier 9.8 ft. w x 13 ft. d steel patio gazebo"
            },
            {
                "product_id": 17688,
                "product_name": "pc and racing game chair"
            }
        ],
        "query": "novara pergola"
    },
    {
        "id": 402,
        "label": [
            {
                "product_id": 33894,
                "product_name": "devries 4 -light 13 '' semi flush mount"
            },
            {
                "product_id": 25930,
                "product_name": "vitrine 1 - bulb 12.13 '' h integrated led outdoor flush mount"
            },
            {
                "product_id": 35098,
                "product_name": "webber 3 - light 11.8 '' unique/statement drum semi flush mount"
            },
            {
                "product_id": 38071,
                "product_name": "ralston 2 - light 13 '' semi flush mount"
            },
            {
                "product_id": 31705,
                "product_name": "allegro 4 - light 16 '' chandelier style drum flush mount"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 6009,
                "product_name": "grier 8-light sputnik modern linear chandelier"
            },
            {
                "product_id": 24562,
                "product_name": "saladino 1 - light 8.7 '' simple dome semi flush mount"
            },
            {
                "product_id": 26234,
                "product_name": "froiln - 4 light semi flush"
            },
            {
                "product_id": 38555,
                "product_name": "karissa mid-century modern 2-light flush mount"
            },
            {
                "product_id": 31632,
                "product_name": "farnborough 3 - light 15 '' simple drum semi flush mount"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 42102,
                "product_name": "daisy solar powered led pathway light pack"
            },
            {
                "product_id": 21866,
                "product_name": "90 '' 4-burner propane/natural gas"
            },
            {
                "product_id": 38866,
                "product_name": "aile 4 drawer standard dresser"
            },
            {
                "product_id": 38224,
                "product_name": "riggins 24 '' single bathroom vanity set"
            },
            {
                "product_id": 11024,
                "product_name": "skunk and bubbles ceramic night light"
            }
        ],
        "garOutput": [
            {
                "product_id": 39698,
                "product_name": "clivden 2 - light dimmable wall sconce"
            },
            {
                "product_id": 8190,
                "product_name": "hawtree 3-light dimmable vanity light"
            },
            {
                "product_id": 25195,
                "product_name": "mclellan 25 watt equivalent b10 led dimmable light bulb warm white e12/candelabra base"
            },
            {
                "product_id": 11557,
                "product_name": "3 3/4 '' center to center bar pull"
            },
            {
                "product_id": 4169,
                "product_name": "liverman end table"
            }
        ],
        "query": "modern farmhouse lighting semi flush mount"
    },
    {
        "id": 86,
        "label": [
            {
                "product_id": 35108,
                "product_name": "7 '' live ivy plant in pot"
            },
            {
                "product_id": 32681,
                "product_name": "echeveria in clay embellished plant in pot"
            },
            {
                "product_id": 34831,
                "product_name": "flourite succulent plant in concrete pot"
            },
            {
                "product_id": 39635,
                "product_name": "artificial aloe with tree in pot"
            },
            {
                "product_id": 32718,
                "product_name": "faux bonsai plant in pot"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 18975,
                "product_name": "artificial sea sanded ceramic aloe plant in decorative vase"
            },
            {
                "product_id": 37452,
                "product_name": "aloe vera floor stem"
            },
            {
                "product_id": 37810,
                "product_name": "jenneke 2 piece ceramic pot planter set"
            },
            {
                "product_id": 24050,
                "product_name": "6 artificial succulent set"
            },
            {
                "product_id": 11543,
                "product_name": "faux cement pineapple agave plant in planter"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 13247,
                "product_name": "square multi-tiered plant stand"
            },
            {
                "product_id": 11467,
                "product_name": "barabra black plant stand"
            },
            {
                "product_id": 41787,
                "product_name": "luffa on twig stem berry bushes"
            },
            {
                "product_id": 4689,
                "product_name": "artificial double phalaenopsis orchid floral arrangement"
            },
            {
                "product_id": 3824,
                "product_name": "woodridge 6 ft. w x 5 ft. d storage shed"
            }
        ],
        "garOutput": [
            {
                "product_id": 7706,
                "product_name": "olive harvest basket concrete pot planter"
            },
            {
                "product_id": 25286,
                "product_name": "cubi 1 - piece self-watering plastic pot planter"
            },
            {
                "product_id": 12480,
                "product_name": "uecker modern wall mirror"
            },
            {
                "product_id": 12256,
                "product_name": "planter"
            },
            {
                "product_id": 33174,
                "product_name": "abramson 2-piece ceramic pot planter"
            }
        ],
        "query": "aloe vera plant pot"
    },
    {
        "id": 31,
        "label": [
            {
                "product_id": 12017,
                "product_name": "geometric colorful room darkening rod pocket curtain panels"
            },
            {
                "product_id": 26636,
                "product_name": "arlone solid color room darkening grommet curtain panels"
            },
            {
                "product_id": 40000,
                "product_name": "primavera crushed solid sheer grommet single curtain panel"
            },
            {
                "product_id": 41253,
                "product_name": "cockerham damask room darkening thermal grommet single curtain panel"
            },
            {
                "product_id": 14557,
                "product_name": "abstract paisley semi-sheer single curtain panel"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 40783,
                "product_name": "sun n shade solstice striped room darkening grommet single curtain panel"
            },
            {
                "product_id": 16765,
                "product_name": "cremont solid sheer grommet curtain panels"
            },
            {
                "product_id": 1654,
                "product_name": "aaiyana striped sheer grommet single curtain panel"
            },
            {
                "product_id": 12108,
                "product_name": "alexzavier faux silk solid color rod pocket single curtain panel"
            },
            {
                "product_id": 3205,
                "product_name": "seaforth semi-sheer grommet single curtain panel"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 980,
                "product_name": "delato counter height pub table"
            },
            {
                "product_id": 3728,
                "product_name": "bless our home metal sign"
            },
            {
                "product_id": 41836,
                "product_name": "liesbeth stool"
            },
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            }
        ],
        "garOutput": [
            {
                "product_id": 37258,
                "product_name": "elborough geometric yellow rug"
            },
            {
                "product_id": 14555,
                "product_name": "mid century modern abstract butterfly pattern window curtains"
            },
            {
                "product_id": 14556,
                "product_name": "mid century modern jacks window curtains"
            },
            {
                "product_id": 14557,
                "product_name": "abstract paisley semi-sheer single curtain panel"
            },
            {
                "product_id": 14558,
                "product_name": "geometric semi-sheer curtain panels"
            }
        ],
        "query": "burnt orange curtains"
    },
    {
        "id": 84,
        "label": [
            {
                "product_id": 36690,
                "product_name": "breakers queen standard bed"
            },
            {
                "product_id": 34527,
                "product_name": "ashlynn metal platform bed"
            },
            {
                "product_id": 18021,
                "product_name": "skakke metal full platform bed"
            },
            {
                "product_id": 10853,
                "product_name": "doshie metal twin platform bed"
            },
            {
                "product_id": 12747,
                "product_name": "alaina canopy bed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 41219,
                "product_name": "kostemia full over full bunk bed"
            },
            {
                "product_id": 25338,
                "product_name": "jenafir twin over full solid wood standard bunk bed"
            },
            {
                "product_id": 9995,
                "product_name": "kiester crystal platform bed"
            },
            {
                "product_id": 3163,
                "product_name": "simbula metal platform bed"
            },
            {
                "product_id": 27794,
                "product_name": "metal twin platform bed"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 37189,
                "product_name": "metal bed with wood decoration \uff08twin size\uff09"
            },
            {
                "product_id": 25557,
                "product_name": "connolly tufted upholstered low profile standard bed"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 37192,
                "product_name": "wood platform bed with two drawers , full ( white )"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 738,
                "product_name": "runner groverson geometric blue area rug"
            },
            {
                "product_id": 739,
                "product_name": "runner groversen geometric gray area rug"
            },
            {
                "product_id": 4445,
                "product_name": "3 piece kitchen canister set"
            },
            {
                "product_id": 7643,
                "product_name": "arkadelphia marble end table"
            },
            {
                "product_id": 19353,
                "product_name": "wooden custom mimosa bar wall d\u00e9cor"
            }
        ],
        "query": "full metal bed rose gold"
    },
    {
        "id": 124,
        "label": [
            {
                "product_id": 34265,
                "product_name": "heston wheel coffee table with storage"
            },
            {
                "product_id": 21225,
                "product_name": "nakasa end table"
            },
            {
                "product_id": 37332,
                "product_name": "macrae sled coffee table with storage"
            },
            {
                "product_id": 37554,
                "product_name": "plutarch frame coffee table"
            },
            {
                "product_id": 13141,
                "product_name": "newmarch trestle coffee table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 13557,
                "product_name": "viviana gonzalez agate inspired coffee table"
            },
            {
                "product_id": 39834,
                "product_name": "karah coffee table with storage"
            },
            {
                "product_id": 26980,
                "product_name": "lavigne solid wood coffee table"
            },
            {
                "product_id": 26638,
                "product_name": "prism sled coffee table"
            },
            {
                "product_id": 3234,
                "product_name": "hermosillo end table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 1029,
                "product_name": "candelario bar with wine storage"
            },
            {
                "product_id": 13956,
                "product_name": "deauville coffee table"
            },
            {
                "product_id": 13955,
                "product_name": "erachidia coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 310,
                "product_name": "roclincourt 3 legs 2 nesting tables"
            },
            {
                "product_id": 29011,
                "product_name": "silke 3 legs 2 nesting tables"
            },
            {
                "product_id": 30805,
                "product_name": "rebekka 3 legs coffee table"
            },
            {
                "product_id": 16539,
                "product_name": "kleinschmidt 3 piece coffee table set"
            },
            {
                "product_id": 9449,
                "product_name": "orpha globe single curtain rod"
            }
        ],
        "query": "unique coffee tables"
    },
    {
        "id": 336,
        "label": [
            {
                "product_id": 41363,
                "product_name": "alysabeth swivel adjustable height bar stool"
            },
            {
                "product_id": 34650,
                "product_name": "goethe swivel adjustable height bar stool"
            },
            {
                "product_id": 5248,
                "product_name": "swivel rocker fabric recliner chair - reclining chair manual , single modern sofa home theater seating for living room ( smoke grey )"
            },
            {
                "product_id": 38923,
                "product_name": "troutville adjustable height swivel bar stool"
            },
            {
                "product_id": 31270,
                "product_name": "neriah swivel bar & counter stool"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 21943,
                "product_name": "k-like adjustable reclining ergonomic leather swiveling pc & racing game chair with footrest"
            },
            {
                "product_id": 6455,
                "product_name": "tunnell swivel solid wood 24.2 '' counter stool"
            },
            {
                "product_id": 25878,
                "product_name": "bocana swivel adjustable height bar stool"
            },
            {
                "product_id": 34745,
                "product_name": "island fusion 36 '' wide swivel down cushion armchair"
            },
            {
                "product_id": 11230,
                "product_name": "ruby swivel reclining glider"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 20718,
                "product_name": "abramowski monitor stand"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            },
            {
                "product_id": 12320,
                "product_name": "soft square 3 3/4 '' center to center bar pull"
            }
        ],
        "garOutput": [
            {
                "product_id": 7986,
                "product_name": "daphyne 21 '' nickel table lamp with usb"
            },
            {
                "product_id": 21159,
                "product_name": "chesapeake 1 - light 12 '' semi flush mount"
            },
            {
                "product_id": 20397,
                "product_name": "zumalai 17 '' table lamp"
            },
            {
                "product_id": 35358,
                "product_name": "turco 3 - light 15 '' shaded drum semi flush mount"
            },
            {
                "product_id": 21625,
                "product_name": "drinkard 4-light vanity light"
            }
        ],
        "query": "mystic swivel"
    },
    {
        "id": 459,
        "label": [
            {
                "product_id": 11046,
                "product_name": "ailisa valatie reversible duvet cover set"
            },
            {
                "product_id": 26095,
                "product_name": "jaden reversible floral cotton 7 piece duvet cover set"
            },
            {
                "product_id": 36272,
                "product_name": "sweeten duvet cover set"
            },
            {
                "product_id": 11042,
                "product_name": "ruched pintuck rosette duvet cover set"
            },
            {
                "product_id": 11854,
                "product_name": "object silhouette form generated tie dye effect technique image duvet cover set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 14960,
                "product_name": "gwinn reversible duvet cover set"
            },
            {
                "product_id": 22086,
                "product_name": "adaleia 3 piece full size printed duvet cover set quilt bed cover bedding set"
            },
            {
                "product_id": 41525,
                "product_name": "city scene demi beige duvet cover set , twin"
            },
            {
                "product_id": 12348,
                "product_name": "washed reversible duvet cover set"
            },
            {
                "product_id": 27827,
                "product_name": "usa chicago city skyline at night with tall buildings urban modern life american town scene duvet cover set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 16370,
                "product_name": "stikki clips white 20 per pack"
            },
            {
                "product_id": 3291,
                "product_name": "zootles drawer pull multipack"
            },
            {
                "product_id": 1179,
                "product_name": "real touch bouquet peonies stems"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 13870,
                "product_name": "no . 4 motorcycle - print"
            }
        ],
        "garOutput": [
            {
                "product_id": 19095,
                "product_name": "borzoi welcome non-slip outdoor door mat"
            },
            {
                "product_id": 19096,
                "product_name": "verden winter floral non-slip outdoor door mat"
            },
            {
                "product_id": 23038,
                "product_name": "volz power loom red rug"
            },
            {
                "product_id": 14546,
                "product_name": "greer dark tie dye octopus duvet cover set"
            },
            {
                "product_id": 14547,
                "product_name": "freeburg purple duvet cover set"
            }
        ],
        "query": "tye dye duvet cover"
    },
    {
        "id": 461,
        "label": [
            {
                "product_id": 29105,
                "product_name": "25 '' led 60 - bulb fairy string light"
            },
            {
                "product_id": 24952,
                "product_name": "48 ft led outdoor waterproof 15 string lights"
            },
            {
                "product_id": 28411,
                "product_name": "big hanging gold solar powered led outdoor lantern"
            },
            {
                "product_id": 40003,
                "product_name": "tiki 12.79 ft. 10-light novelty string light"
            },
            {
                "product_id": 21952,
                "product_name": "5m 3528 rgb led strip smd lights string lights"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 18143,
                "product_name": "operated string light"
            },
            {
                "product_id": 28777,
                "product_name": "300 light led string light"
            },
            {
                "product_id": 5855,
                "product_name": "60 light icicle lighting"
            },
            {
                "product_id": 33310,
                "product_name": "super bright 100 light string lighting"
            },
            {
                "product_id": 35241,
                "product_name": "110 light solar string lights"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20085,
                "product_name": "dario 12 - light candle style wagon wheel chandelier"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            }
        ],
        "garOutput": [
            {
                "product_id": 9219,
                "product_name": "rounds ball shower curtain hooks"
            },
            {
                "product_id": 35978,
                "product_name": "4.5 watt ( 40 watt equivalent ) , g25 incandescent , dimmable light bulb , ( 2700k ) e26/medium ( standard ) base"
            },
            {
                "product_id": 28525,
                "product_name": "5 watt incandescent dimmable light bulb"
            },
            {
                "product_id": 14296,
                "product_name": "vivian decorative shower curtain hooks"
            },
            {
                "product_id": 39501,
                "product_name": "60 watt t12 incandescent , dimmable light bulb , warm white ( 2450k ) e26 base"
            }
        ],
        "query": "luau string lights"
    },
    {
        "id": 94,
        "label": [
            {
                "product_id": 22218,
                "product_name": "vault top-mount single-bowl stainless steel kitchen sink with shortened apron-front for 36 '' cabinet"
            },
            {
                "product_id": 22199,
                "product_name": "vault 29.75 '' x 24.31 '' top mount farmhouse kitchen sink"
            },
            {
                "product_id": 2519,
                "product_name": "pekoe stainless steel 33 '' l x 22 '' w farmhouse/apron kitchen sink"
            },
            {
                "product_id": 29786,
                "product_name": "stainless steel 33 '' l x 22 '' w farmhouse kitchen sink with sink grid and basket strainer"
            },
            {
                "product_id": 23299,
                "product_name": "cairn undermount single-bowl farmhouse kitchen sink with fluted design"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 22174,
                "product_name": "kohler vault sink"
            },
            {
                "product_id": 22228,
                "product_name": "whitehaven 9.7 '' x 15.18 '' small basin rack"
            },
            {
                "product_id": 20226,
                "product_name": "33 '' l x 20 '' w farmhouse kitchen sink with sink grid and basket strainer"
            },
            {
                "product_id": 22263,
                "product_name": "alcott 25 '' l x 22 '' w x 8-5/8 '' under-mount single-bowl kitchen sink with apron-front and 5 oversize faucet holes"
            },
            {
                "product_id": 6109,
                "product_name": "36 '' x 21 '' apron kitchen sink with sink grid and basket strainer"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 14229,
                "product_name": "homestead dining table"
            },
            {
                "product_id": 3728,
                "product_name": "bless our home metal sign"
            },
            {
                "product_id": 8743,
                "product_name": "secrist free form multi-tiered plant stand"
            },
            {
                "product_id": 13957,
                "product_name": "galeton tv stand for tvs up to 88 ''"
            }
        ],
        "garOutput": [
            {
                "product_id": 20355,
                "product_name": "thassos 4 '' x 4 '' mosaic tile"
            },
            {
                "product_id": 12655,
                "product_name": "chabon 24 '' single bathroom vanity set with mirror"
            },
            {
                "product_id": 331,
                "product_name": "non-bypass standard single track barn door hardware kit"
            },
            {
                "product_id": 23393,
                "product_name": "square knob"
            },
            {
                "product_id": 23392,
                "product_name": "1 5/16 '' diameter round knob"
            }
        ],
        "query": "kohler whitehaven farmhouse kitchen sink"
    },
    {
        "id": 340,
        "label": [
            {
                "product_id": 20615,
                "product_name": "2.5 x 2 ft outdoor wooden storage cabinet with double doors -brown"
            },
            {
                "product_id": 33656,
                "product_name": "magaret 4 - shelf storage cabinet"
            },
            {
                "product_id": 8450,
                "product_name": "schott wood cabinet storage chest box organizer christmas gift free standing jewelry armoire with mirror"
            },
            {
                "product_id": 31866,
                "product_name": "landis office storage cabinet"
            },
            {
                "product_id": 2409,
                "product_name": "gioia 4 - shelf storage cabinet"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 18095,
                "product_name": "800 series storage cabinet"
            },
            {
                "product_id": 31858,
                "product_name": "james 6 - shelf storage cabinet"
            },
            {
                "product_id": 18099,
                "product_name": "400 series storage cabinet"
            },
            {
                "product_id": 34204,
                "product_name": "alveria 4 - shelf storage cabinet"
            },
            {
                "product_id": 35709,
                "product_name": "lasone storage cabinet"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 9624,
                "product_name": "military challenge coin cabinet rack holder display case"
            },
            {
                "product_id": 13957,
                "product_name": "galeton tv stand for tvs up to 88 ''"
            },
            {
                "product_id": 37364,
                "product_name": "princeton elite 6 drawer double dresser"
            }
        ],
        "garOutput": [
            {
                "product_id": 22626,
                "product_name": "66 '' h x 27 '' w x 15 '' d tall utility storage cabinet"
            },
            {
                "product_id": 13953,
                "product_name": "elam bed risers"
            },
            {
                "product_id": 21297,
                "product_name": "alayah 8.5 '' w stackable storage drawer"
            },
            {
                "product_id": 20625,
                "product_name": "rolling storage cart"
            },
            {
                "product_id": 7261,
                "product_name": "trower 34.3 '' h x 27.9 '' w x 13.6 '' d foldable storage shelves"
            }
        ],
        "query": "berenice 4 storage cabinet"
    },
    {
        "id": 182,
        "label": [
            {
                "product_id": 15102,
                "product_name": "ifnluence 1 '' x 1 '' beveled porcelain penny round mosaic tile"
            },
            {
                "product_id": 13800,
                "product_name": "apollo tile gold glass penny round mosaic tile - 12.2 '' x 12.2 '' sheet for kitchen , bathroom or wall panel ( 10-pack )"
            },
            {
                "product_id": 10666,
                "product_name": "orb 0.75 '' x 0.75 '' metal penny round mosaic tile"
            },
            {
                "product_id": 33801,
                "product_name": "bantame 12 '' x 24 '' porcelain field tile"
            },
            {
                "product_id": 12828,
                "product_name": "penny 1 '' x 1 '' porcelain penny round mosaic wall & floor tile"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 12803,
                "product_name": "retro penny 1 '' x 1 '' porcelain grid mosaic wall & floor tile"
            },
            {
                "product_id": 31288,
                "product_name": "benelux 12 '' x 24 '' porcelain field tile"
            },
            {
                "product_id": 33853,
                "product_name": "1 '' x 1 '' porcelain penny round mosaic wall & floor tile"
            },
            {
                "product_id": 29774,
                "product_name": "vulcan ceramic penny round mosaic wall & floor tile"
            },
            {
                "product_id": 29770,
                "product_name": "penny 0.8 '' x 0.8 '' porcelain mosaic floor use tile"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 9624,
                "product_name": "military challenge coin cabinet rack holder display case"
            },
            {
                "product_id": 1179,
                "product_name": "real touch bouquet peonies stems"
            },
            {
                "product_id": 22032,
                "product_name": "dining table with leaf"
            },
            {
                "product_id": 13098,
                "product_name": "premium heavyweight 9 '' melamine salad or dessert plate"
            }
        ],
        "garOutput": [
            {
                "product_id": 8801,
                "product_name": "zoller stool"
            },
            {
                "product_id": 10660,
                "product_name": "channing 1 '' x 2.5 '' marble honeycomb mosaic wall & floor tile"
            },
            {
                "product_id": 10666,
                "product_name": "orb 0.75 '' x 0.75 '' metal penny round mosaic tile"
            },
            {
                "product_id": 33768,
                "product_name": "oriental sculpture herringbone 1 '' x 3 '' marble mosaic tile in beige"
            },
            {
                "product_id": 33827,
                "product_name": "maine 3 '' x 3 '' ceramic mosaic wall & floor tile"
            }
        ],
        "query": "penny round tile"
    },
    {
        "id": 23,
        "label": [
            {
                "product_id": 26210,
                "product_name": "conteh 2 drawer end table"
            },
            {
                "product_id": 7633,
                "product_name": "ailbe wood console table"
            },
            {
                "product_id": 31253,
                "product_name": "katalina end table"
            },
            {
                "product_id": 6780,
                "product_name": "muth 32 '' solid wood console table"
            },
            {
                "product_id": 41975,
                "product_name": "abdulazis 2 - drawer end table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 18914,
                "product_name": "skinny 76 '' solid wood console table"
            },
            {
                "product_id": 3666,
                "product_name": "dmitry 2 drawer end table"
            },
            {
                "product_id": 6382,
                "product_name": "atropos low end table"
            },
            {
                "product_id": 16675,
                "product_name": "lemington end table"
            },
            {
                "product_id": 42165,
                "product_name": "brunella solid wood 2 drawer end table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 32547,
                "product_name": "serving platter"
            },
            {
                "product_id": 22032,
                "product_name": "dining table with leaf"
            },
            {
                "product_id": 5962,
                "product_name": "delois bunching coffee table"
            },
            {
                "product_id": 7839,
                "product_name": "citadel sunflower fields cheese board"
            },
            {
                "product_id": 3311,
                "product_name": "pine dining table"
            }
        ],
        "garOutput": [
            {
                "product_id": 37208,
                "product_name": "minifile storage cabinet"
            },
            {
                "product_id": 12913,
                "product_name": "virtue 18 '' x 18 '' porcelain spanish wall & floor tile"
            },
            {
                "product_id": 37177,
                "product_name": "employee time report card"
            },
            {
                "product_id": 8801,
                "product_name": "zoller stool"
            },
            {
                "product_id": 35833,
                "product_name": "game room pointer sign left"
            }
        ],
        "query": "stoneford end tables white and wood"
    },
    {
        "id": 207,
        "label": [
            {
                "product_id": 16347,
                "product_name": "faulkner panel headboard"
            },
            {
                "product_id": 8351,
                "product_name": "bridget upholstered panel headboard"
            },
            {
                "product_id": 779,
                "product_name": "chavis slat headboard"
            },
            {
                "product_id": 16632,
                "product_name": "stillwell panel headboard"
            },
            {
                "product_id": 26157,
                "product_name": "tarin panel headboard"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 17018,
                "product_name": "adan queen panel headboard"
            },
            {
                "product_id": 35305,
                "product_name": "taliesin slat headboard"
            },
            {
                "product_id": 40209,
                "product_name": "saldana slat headboard"
            },
            {
                "product_id": 15880,
                "product_name": "ajo open-frame headboard"
            },
            {
                "product_id": 3526,
                "product_name": "graham panel headboard"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 25557,
                "product_name": "connolly tufted upholstered low profile standard bed"
            },
            {
                "product_id": 32281,
                "product_name": "metal slat back stacking side chair"
            },
            {
                "product_id": 980,
                "product_name": "delato counter height pub table"
            },
            {
                "product_id": 31905,
                "product_name": "toulouse printed single shower curtain"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            }
        ],
        "garOutput": [
            {
                "product_id": 5195,
                "product_name": "float on giant inflatable"
            },
            {
                "product_id": 30745,
                "product_name": "sunflower jute throw pillow"
            },
            {
                "product_id": 40189,
                "product_name": "umberger embroidery comforter set"
            },
            {
                "product_id": 4552,
                "product_name": "modu-licious # 1"
            },
            {
                "product_id": 38375,
                "product_name": "haru 48 '' platform bed"
            }
        ],
        "query": "dull bed with shirt head board"
    },
    {
        "id": 252,
        "label": [
            {
                "product_id": 28247,
                "product_name": "misisco 23 '' wide armchair"
            },
            {
                "product_id": 19556,
                "product_name": "lawler 26.37 '' wide velvet barrel chair"
            },
            {
                "product_id": 40865,
                "product_name": "almond 26.5 '' wide polyester armchair"
            },
            {
                "product_id": 8136,
                "product_name": "modern soft velvet material green ergonomics accent chair living room chair bedroom chair home chair with gold legs and adjustable legs for indoor home"
            },
            {
                "product_id": 26979,
                "product_name": "javion 3 piece living room set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 10734,
                "product_name": "boyster 24 '' wide velvet armchair"
            },
            {
                "product_id": 34357,
                "product_name": "gaura 2 piece sleeper living room set"
            },
            {
                "product_id": 11471,
                "product_name": "27 '' wide armchair and ottoman"
            },
            {
                "product_id": 14888,
                "product_name": "althorp living history 22.25 '' wide armchair"
            },
            {
                "product_id": 22965,
                "product_name": "bryce 2 piece faux leather reclining living room set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 30158,
                "product_name": "hershberger buffet table"
            },
            {
                "product_id": 5959,
                "product_name": "marple 42 '' wide 2 drawer server"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 15446,
                "product_name": "ulibarri 6 piece rattan sectional seating group with cushions"
            },
            {
                "product_id": 19185,
                "product_name": "erzsebet 33 '' wide power lift assist standard recliner"
            }
        ],
        "garOutput": [
            {
                "product_id": 33447,
                "product_name": "mabe power loom red/gold/beige/black rug"
            },
            {
                "product_id": 18813,
                "product_name": "hailee twin platform bed"
            },
            {
                "product_id": 5879,
                "product_name": "bayard right hand facing modular corner sectional with ottoman"
            },
            {
                "product_id": 3956,
                "product_name": "hussain ship wheel rectangular cotton pillow cover & insert"
            },
            {
                "product_id": 30371,
                "product_name": "sanibel coffee table"
            }
        ],
        "query": "accent chairs living room"
    },
    {
        "id": 407,
        "label": [
            {
                "product_id": 30520,
                "product_name": "prepac storage bookcase headboard"
            },
            {
                "product_id": 1038,
                "product_name": "almada 54.75 '' h x 35.5 '' w metal etagere bookcase"
            },
            {
                "product_id": 8800,
                "product_name": "preserve 71 '' etagere bookcase"
            },
            {
                "product_id": 33673,
                "product_name": "adele standard bookcase"
            },
            {
                "product_id": 4984,
                "product_name": "efron transitional etagere bookcase"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 40587,
                "product_name": "donavan 72 '' h x 38.5 '' w steel etagere bookcase"
            },
            {
                "product_id": 32527,
                "product_name": "79.5 '' h x 18 '' w geometric bookcase"
            },
            {
                "product_id": 18544,
                "product_name": "beachworth three shelf etagere bookcase"
            },
            {
                "product_id": 1534,
                "product_name": "kranz 69 '' h x 21 '' w standard bookcase"
            },
            {
                "product_id": 31285,
                "product_name": "marny 70.8 '' x 70.8 '' w steel etagere bookcase"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 29241,
                "product_name": "cleotha twin 75 '' wide tufted back convertible sofa"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 21200,
                "product_name": "cutts bar with wine storage"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            }
        ],
        "garOutput": [
            {
                "product_id": 13504,
                "product_name": "teak tripod end table"
            },
            {
                "product_id": 20549,
                "product_name": "cresswell armless stackable chair"
            },
            {
                "product_id": 6096,
                "product_name": "longley 5 drawer storage chest"
            },
            {
                "product_id": 42713,
                "product_name": "giullia 2 piece floating shelf"
            },
            {
                "product_id": 11664,
                "product_name": "bolan 12.6 '' h x 13.4 '' w cube bookcase"
            }
        ],
        "query": "huge bookcase"
    },
    {
        "id": 5,
        "label": [
            {
                "product_id": 1701,
                "product_name": "enyia 159 '' wide reversible modular corner sectional with ottoman"
            },
            {
                "product_id": 41671,
                "product_name": "laguna 5 piece teak sofa seating group with cushions"
            },
            {
                "product_id": 28666,
                "product_name": "lavigne 167 '' wide right hand facing modular sectional with ottoman"
            },
            {
                "product_id": 14796,
                "product_name": "junius 158 '' wide reversible modular corner sectional with ottoman"
            },
            {
                "product_id": 11471,
                "product_name": "27 '' wide armchair and ottoman"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 22908,
                "product_name": "ernestine 35 '' tufted square storage ottoman"
            },
            {
                "product_id": 17498,
                "product_name": "kaisa 32 '' rectangle standard ottoman"
            },
            {
                "product_id": 3691,
                "product_name": "safira 2 piece sofa seating group with cushions"
            },
            {
                "product_id": 15268,
                "product_name": "samatha 9 piece rattan sofa seating group with cushions"
            },
            {
                "product_id": 17377,
                "product_name": "outdoor garden patio furniture 7-piece pe rattan wicker sectional cushioned sofa sets"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 26887,
                "product_name": "capems 65.35 '' faux leather pillow top arm sleeper"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 38244,
                "product_name": "agirta extendable dining table"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 19185,
                "product_name": "erzsebet 33 '' wide power lift assist standard recliner"
            }
        ],
        "garOutput": [
            {
                "product_id": 31493,
                "product_name": "chanelle 98 '' wide chenille right hand facing sleeper sofa & chaise"
            },
            {
                "product_id": 23186,
                "product_name": "podrick 64 '' pillow top arm reclining loveseat"
            },
            {
                "product_id": 23185,
                "product_name": "podrick 76 '' pillow top arm reclining loveseat"
            },
            {
                "product_id": 8432,
                "product_name": "woodbluff 31 '' round arm sofa bed"
            },
            {
                "product_id": 41491,
                "product_name": "kymber round cotton pouf"
            }
        ],
        "query": "sofa with ottoman"
    },
    {
        "id": 116,
        "label": [
            {
                "product_id": 9475,
                "product_name": "colene bubbles circle printed blackout thermal curtain panels"
            },
            {
                "product_id": 21385,
                "product_name": "kaydee solid blackout thermal grommet curtain panels"
            },
            {
                "product_id": 2670,
                "product_name": "ardmore solid blackout single curtain panel"
            },
            {
                "product_id": 11945,
                "product_name": "ambesonne asian 2 panel curtain set , japanese flowering cherry blossom symbolic coming of spring season eastern inspired , lightweight window treatment living room bedroom decor , 56 '' x 63 '' , beige rose"
            },
            {
                "product_id": 11970,
                "product_name": "ambesonne moroccan window curtains , oriental art style motifs vintage design inspirations , lightweight decorative panels set of 2 with rod pocket , 56 '' x 63 '' , plum petrol blue yellow"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 10333,
                "product_name": "effrosini floral room darkening grommet curtain panels"
            },
            {
                "product_id": 9738,
                "product_name": "concert solid color room darkening thermal rod pocket single curtain panel"
            },
            {
                "product_id": 11986,
                "product_name": "ambesonne vintage window curtains , ornamental tracery inspired swirl curl elements vertical borders with dots , lightweight decorative panels set of 2 with rod pocket , 56 '' x 63 '' , earth yellow white"
            },
            {
                "product_id": 12357,
                "product_name": "berwick linen blend solid semi-sheer rod pocket single curtain panel"
            },
            {
                "product_id": 12020,
                "product_name": "nursery room darkening rod pocket curtain panels"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 30458,
                "product_name": "kendal shoe storage bench"
            },
            {
                "product_id": 36857,
                "product_name": "u-channel clips"
            },
            {
                "product_id": 19730,
                "product_name": "vessel sink bathroom faucet with drain assembly"
            },
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 1653,
                "product_name": "agustin geometric room darkening grommet single curtain panel"
            }
        ],
        "garOutput": [
            {
                "product_id": 25060,
                "product_name": "franklin heavy duty bed frame"
            },
            {
                "product_id": 5194,
                "product_name": "float on heart inflatable"
            },
            {
                "product_id": 4552,
                "product_name": "modu-licious # 1"
            },
            {
                "product_id": 29726,
                "product_name": "barnard carpeted rubber outdoor non-slip outdoor door mat"
            },
            {
                "product_id": 5195,
                "product_name": "float on giant inflatable"
            }
        ],
        "query": "brockham solid blackout thermal grommet curtain panels"
    },
    {
        "id": 45,
        "label": [
            {
                "product_id": 8389,
                "product_name": "mid century modern 2 piece living room set"
            },
            {
                "product_id": 26000,
                "product_name": "pasquale standard configurable living room set"
            },
            {
                "product_id": 19811,
                "product_name": "lambersart furniture sets"
            },
            {
                "product_id": 33140,
                "product_name": "deven 2 piece living room set"
            },
            {
                "product_id": 1102,
                "product_name": "eric configurable living room set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 40411,
                "product_name": "ebert configurable living room set"
            },
            {
                "product_id": 36391,
                "product_name": "millen tv stand for tvs up to 60 ''"
            },
            {
                "product_id": 7861,
                "product_name": "gossage retro 2 piece standard living room set"
            },
            {
                "product_id": 11883,
                "product_name": "70s party i love the pictogram party card inspired letters duvet cover set"
            },
            {
                "product_id": 11882,
                "product_name": "old fashioned 60s 70s inspired polka dot fitted sheet"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 27333,
                "product_name": "berengere 4 '' cotton futon mattress"
            },
            {
                "product_id": 27336,
                "product_name": "vintage floral japanese 4 '' cotton futon mattress"
            },
            {
                "product_id": 27335,
                "product_name": "vintage floral japanese 4 '' cotton futon mattress"
            },
            {
                "product_id": 30826,
                "product_name": "belgr concrete propane/natural gas fire pit table"
            },
            {
                "product_id": 9761,
                "product_name": "scaggs 6 piece bathroom accessory set"
            }
        ],
        "garOutput": [
            {
                "product_id": 31371,
                "product_name": "ruskin extendable dining table"
            },
            {
                "product_id": 21763,
                "product_name": "fausley 4 piece coffee table set"
            },
            {
                "product_id": 26543,
                "product_name": "hartsock metal bread box"
            },
            {
                "product_id": 10087,
                "product_name": "dryden pressure balanced shower faucet with h2okinetic technology"
            },
            {
                "product_id": 11308,
                "product_name": "stancliff wall mirror"
            }
        ],
        "query": "70s inspired furniture"
    },
    {
        "id": 16,
        "label": [
            {
                "product_id": 18297,
                "product_name": "spectrum punch indoor/outdoor seat cushion"
            },
            {
                "product_id": 6005,
                "product_name": "pierceton 6 piece rattan sectional seating group with cushions"
            },
            {
                "product_id": 18739,
                "product_name": "cleaon 3 piece seating group with cushions"
            },
            {
                "product_id": 19786,
                "product_name": "jacobson 3 piece sectional seating group with cushions"
            },
            {
                "product_id": 20035,
                "product_name": "natural straw meditation outdoor seat cushion"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 847,
                "product_name": "mangum 5 piece sofa seating group with cushions"
            },
            {
                "product_id": 3696,
                "product_name": "safira 4 piece sofa seating group with cushions"
            },
            {
                "product_id": 7585,
                "product_name": "waynoka outdoor furniture 3 piece rattan seating group with cushions"
            },
            {
                "product_id": 20492,
                "product_name": "claunch 4 piece sectional seating group with cushions"
            },
            {
                "product_id": 30726,
                "product_name": "angilia indoor/outdoor seat cushion"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 2312,
                "product_name": "twin size platform bed with adjustable trundle , espresso"
            },
            {
                "product_id": 19249,
                "product_name": "bassett kids desk"
            },
            {
                "product_id": 20888,
                "product_name": "mowat 41 '' wide power lift assist recliner"
            },
            {
                "product_id": 30458,
                "product_name": "kendal shoe storage bench"
            }
        ],
        "garOutput": [
            {
                "product_id": 22312,
                "product_name": "fordland 23.8 '' patio bar stool with cushion"
            },
            {
                "product_id": 23940,
                "product_name": "jordan dining chair"
            },
            {
                "product_id": 18518,
                "product_name": "littleville patio chair"
            },
            {
                "product_id": 24292,
                "product_name": "orland wood bench"
            },
            {
                "product_id": 9334,
                "product_name": "whittenburg upholstered storage bench"
            }
        ],
        "query": "blk 18x18 seat cushions"
    },
    {
        "id": 458,
        "label": [
            {
                "product_id": 9032,
                "product_name": "universal elastic chair covers bubble lattice slipcover with skirt ( set of 4 )"
            },
            {
                "product_id": 17187,
                "product_name": "brazoria balloon chair"
            },
            {
                "product_id": 40457,
                "product_name": "ranbir 39 '' wide velvet chesterfield chair"
            },
            {
                "product_id": 20470,
                "product_name": "exectuive chair"
            },
            {
                "product_id": 17550,
                "product_name": "principal chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 1150,
                "product_name": "kynaston 34 '' wide balloon chair"
            },
            {
                "product_id": 308,
                "product_name": "exectuive chair"
            },
            {
                "product_id": 19987,
                "product_name": "maurertown wingback task chair"
            },
            {
                "product_id": 36824,
                "product_name": "anasophia tufted velvet upholstered side chair in gray"
            },
            {
                "product_id": 30778,
                "product_name": "kewdale office drafting chair"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 16256,
                "product_name": "vidal ultra soft & plush dinosaurs fleece throw"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 15376,
                "product_name": "esschertdesign bumble bee house"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            }
        ],
        "garOutput": [
            {
                "product_id": 27495,
                "product_name": "1-up hard cover pet brag album"
            },
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 21974,
                "product_name": "dryer 9 '' clip on table lamp"
            },
            {
                "product_id": 17776,
                "product_name": "crellin solid wood coffee table"
            },
            {
                "product_id": 32495,
                "product_name": "dederang 2 '' bookshelf"
            }
        ],
        "query": "bubble guppies chair"
    },
    {
        "id": 377,
        "label": [
            {
                "product_id": 31683,
                "product_name": "dasher upholstered bench"
            },
            {
                "product_id": 23619,
                "product_name": "bottesford upholstered bench"
            },
            {
                "product_id": 13733,
                "product_name": "supriya upholstered wood bench"
            },
            {
                "product_id": 31677,
                "product_name": "poe upholstered bench"
            },
            {
                "product_id": 26793,
                "product_name": "judah upholstered bench"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 3539,
                "product_name": "indialantic storage bench"
            },
            {
                "product_id": 33491,
                "product_name": "windrim upholstered bench"
            },
            {
                "product_id": 11293,
                "product_name": "risso upholstered bench"
            },
            {
                "product_id": 17154,
                "product_name": "haysi upholstered bench with nailhead trim"
            },
            {
                "product_id": 10391,
                "product_name": "ariyannah upholstered wood bench"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            }
        ],
        "garOutput": [
            {
                "product_id": 29483,
                "product_name": "long ashton 31.89 '' wide swivel lounge chair"
            },
            {
                "product_id": 31913,
                "product_name": "gayston 44.8 '' wide outdoor left hand facing patio sectional with cushions"
            },
            {
                "product_id": 39119,
                "product_name": "gibe 44.8 '' wide outdoor patio sectional with cushion"
            },
            {
                "product_id": 1191,
                "product_name": "outdoor furniture sofa set with large storage box"
            },
            {
                "product_id": 22068,
                "product_name": "brookhaven solid wood drum coffee table"
            }
        ],
        "query": "glinda upholstered bench"
    },
    {
        "id": 3,
        "label": [
            {
                "product_id": 104,
                "product_name": "melville bamboo feel 1800 thread count pillow case"
            },
            {
                "product_id": 3474,
                "product_name": "admer throw pillow and lumbar pillow"
            },
            {
                "product_id": 35255,
                "product_name": "whipton mongolian faux lumbar pillow"
            },
            {
                "product_id": 20822,
                "product_name": "alana goose medium feather and down cooling bed pillow"
            },
            {
                "product_id": 9545,
                "product_name": "billingsley square pillow cover & insert"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 19041,
                "product_name": "the original sleep defense system pillow protector"
            },
            {
                "product_id": 12386,
                "product_name": "abrianna 100 % brushed microfiber pillow sham"
            },
            {
                "product_id": 3907,
                "product_name": "ayesha curry embroidered ogee cotton throw pillow"
            },
            {
                "product_id": 30540,
                "product_name": "medium memory foam standard cooling body pillow"
            },
            {
                "product_id": 30279,
                "product_name": "replacement pillows outdoor lounge chair cushion"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 28774,
                "product_name": "muslin baby sleeping bag"
            },
            {
                "product_id": 890,
                "product_name": "sapphira reversible quilt set"
            },
            {
                "product_id": 27333,
                "product_name": "berengere 4 '' cotton futon mattress"
            },
            {
                "product_id": 27336,
                "product_name": "vintage floral japanese 4 '' cotton futon mattress"
            },
            {
                "product_id": 27335,
                "product_name": "vintage floral japanese 4 '' cotton futon mattress"
            }
        ],
        "garOutput": [
            {
                "product_id": 13577,
                "product_name": "turquoise painted poppy indoor/outdoor throw pillow"
            },
            {
                "product_id": 37258,
                "product_name": "elborough geometric yellow rug"
            },
            {
                "product_id": 38132,
                "product_name": "geometric teal indoor / outdoor area rug"
            },
            {
                "product_id": 8031,
                "product_name": "flaugher happy camper personalized coffee mug"
            },
            {
                "product_id": 13852,
                "product_name": "modern shell-shaped backrest accent chair for living room bedroom metal leg , velvet , grey"
            }
        ],
        "query": "turquoise pillows"
    },
    {
        "id": 443,
        "label": [
            {
                "product_id": 30337,
                "product_name": "river 4 pieces rattan patio furniture set wicker sofa cushioned sectional furniture set garden patio sofa set"
            },
            {
                "product_id": 1200,
                "product_name": "breccan patio furniture on clearance 7 piece rattan sectional seating group with cushions"
            },
            {
                "product_id": 29693,
                "product_name": "gardner 12 ft. w x 10 ft. d steel patio gazebo"
            },
            {
                "product_id": 42765,
                "product_name": "adrienne patio bar stool"
            },
            {
                "product_id": 25092,
                "product_name": "aiyah patio sectional with cushions"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 32937,
                "product_name": "wanakah patio sectional with cushions"
            },
            {
                "product_id": 31354,
                "product_name": "fairfield protective 3 piece water resistant patio furniture cover set"
            },
            {
                "product_id": 28693,
                "product_name": "riad patio sectional with cushions"
            },
            {
                "product_id": 30684,
                "product_name": "daylinn patio sofa with cushions"
            },
            {
                "product_id": 34817,
                "product_name": "havell patio sofa"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 40011,
                "product_name": "mitton pineapple statue"
            },
            {
                "product_id": 8752,
                "product_name": "3 tier corner plant stand foldable nesting flower pot holder elegant metal plant rack for indoor outdoor garden patio3"
            },
            {
                "product_id": 21938,
                "product_name": "patio furniture set outdoor 7-piece rattan seating group with cushions"
            },
            {
                "product_id": 12350,
                "product_name": "rossmere indoor/outdoor bench cushion"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 27527,
                "product_name": "4 ft. h x 3 ft. w nantucket polyethylene privacy screen"
            },
            {
                "product_id": 12106,
                "product_name": "toomsboro geometric burgundy area rug"
            },
            {
                "product_id": 29009,
                "product_name": "jamyria 110 '' wide outdoor patio sectional with cushions"
            },
            {
                "product_id": 4169,
                "product_name": "liverman end table"
            },
            {
                "product_id": 2073,
                "product_name": "delaware 23.5 '' bar stool"
            }
        ],
        "query": "patio design"
    },
    {
        "id": 60,
        "label": [
            {
                "product_id": 19969,
                "product_name": "desire 2 drawer accent chest"
            },
            {
                "product_id": 40366,
                "product_name": "mantador chest"
            },
            {
                "product_id": 34823,
                "product_name": "bargue\u00f1o accent chest"
            },
            {
                "product_id": 26045,
                "product_name": "ashle 5 drawer chest"
            },
            {
                "product_id": 28613,
                "product_name": "maggard accent chest"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 35222,
                "product_name": "olympia 5 drawer chest"
            },
            {
                "product_id": 35159,
                "product_name": "munich 5 drawer chest"
            },
            {
                "product_id": 17068,
                "product_name": "montauk 5 drawer chest"
            },
            {
                "product_id": 21682,
                "product_name": "avalon 5 drawer chest"
            },
            {
                "product_id": 40368,
                "product_name": "argyros chest"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 18488,
                "product_name": "exaucet morrow throw pillow in navy"
            },
            {
                "product_id": 7839,
                "product_name": "citadel sunflower fields cheese board"
            },
            {
                "product_id": 27389,
                "product_name": "muse bee tissue box cover"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 9734,
                "product_name": "white orchids on monique fiberglass bowl"
            }
        ],
        "garOutput": [
            {
                "product_id": 40931,
                "product_name": "dove of peace by pablo picasso - picture frame print on paper"
            },
            {
                "product_id": 2697,
                "product_name": "art glass rooster figurine"
            },
            {
                "product_id": 181,
                "product_name": "nuovo milano by ettore sottsass cake / pastry server"
            },
            {
                "product_id": 37060,
                "product_name": "sabinas patio sofa with cushions"
            },
            {
                "product_id": 34763,
                "product_name": "kingstown admiralty executive desk"
            }
        ],
        "query": "capricorn chest"
    },
    {
        "id": 110,
        "label": [
            {
                "product_id": 16666,
                "product_name": "bradford task chair"
            },
            {
                "product_id": 5244,
                "product_name": "kokkina eimer task chair"
            },
            {
                "product_id": 16461,
                "product_name": "bernstein task chair"
            },
            {
                "product_id": 14359,
                "product_name": "malonne velvet task chair"
            },
            {
                "product_id": 36154,
                "product_name": "kira faux leather task chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 29213,
                "product_name": "eiladh home office computer task chair"
            },
            {
                "product_id": 18300,
                "product_name": "deangelo homeoffice ergonomic task chair"
            },
            {
                "product_id": 42354,
                "product_name": "bret ergonomic mesh task chair"
            },
            {
                "product_id": 39082,
                "product_name": "peele velvet task chair"
            },
            {
                "product_id": 26256,
                "product_name": "office chair high back desk chair , gaming chair bonded leather , ergonomic computer chair managerial chairs and desk chair task swivel executive chairs home office chair , black"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 34987,
                "product_name": "shrestha upholstered parsons chair in gray"
            },
            {
                "product_id": 14501,
                "product_name": "26 '' wide side chair"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            }
        ],
        "garOutput": [
            {
                "product_id": 38487,
                "product_name": "baileyville lift top abstract coffee table"
            },
            {
                "product_id": 36890,
                "product_name": "whipholt 2 piece 27.5 '' patio bar stool"
            },
            {
                "product_id": 27541,
                "product_name": "faux leather reclining chair"
            },
            {
                "product_id": 7531,
                "product_name": "riser"
            },
            {
                "product_id": 18285,
                "product_name": "ergonomic task chair"
            }
        ],
        "query": "mila task chair"
    },
    {
        "id": 312,
        "label": [
            {
                "product_id": 40974,
                "product_name": "ingwar 5 - hook wall mounted coat rack in gray/black"
            },
            {
                "product_id": 29192,
                "product_name": "torrick coat wall hook"
            },
            {
                "product_id": 22420,
                "product_name": "anie coat rack"
            },
            {
                "product_id": 27361,
                "product_name": "mythinglogic garage storage system , garage organizer with baskets and hooks , sports equipment organizer for kids , ball rack , garage ball storage , sports gear storage , black , powder coated steel"
            },
            {
                "product_id": 8805,
                "product_name": "serino coat rack"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 42308,
                "product_name": "abilio coat rack"
            },
            {
                "product_id": 32044,
                "product_name": "lapo plain coat and hat hook"
            },
            {
                "product_id": 29193,
                "product_name": "vasilis twisted hat and coat wall hook"
            },
            {
                "product_id": 40253,
                "product_name": "boatwright wall mounted coat rack"
            },
            {
                "product_id": 30250,
                "product_name": "heid coat rack"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 35693,
                "product_name": "anello 20 '' w closet system reach-in sets"
            },
            {
                "product_id": 41415,
                "product_name": "emaline dresser 4 drawer storage chest"
            },
            {
                "product_id": 13835,
                "product_name": "retro console table sideboard with shutter doors , two storage drawers and bottom shelf for entryway , living room , dining room ( navy )"
            },
            {
                "product_id": 542,
                "product_name": "waytrim fabric 4 drawers storage organizer unit easy assembly , vertical dresser storage tower for closet , bedroom , entryway , brown"
            }
        ],
        "garOutput": [
            {
                "product_id": 27287,
                "product_name": "melbreak wardrobe"
            },
            {
                "product_id": 16018,
                "product_name": "rolling 50 pair shoe rack"
            },
            {
                "product_id": 40922,
                "product_name": "fennella 16 '' wall-mounted single bathroom vanity set"
            },
            {
                "product_id": 17572,
                "product_name": "60 '' w portable wardrobe"
            },
            {
                "product_id": 42289,
                "product_name": "wire basket set"
            }
        ],
        "query": "coat rack for bedroom"
    },
    {
        "id": 254,
        "label": [
            {
                "product_id": 2838,
                "product_name": "aguilar 27.5 '' wide polyester armchair"
            },
            {
                "product_id": 1117,
                "product_name": "carlene 32 '' wide velvet armchair"
            },
            {
                "product_id": 36064,
                "product_name": "ceris 26 '' wide armchair"
            },
            {
                "product_id": 17791,
                "product_name": "modern soft velvet material ergonomics accent chair living room chair bedroom chair home chair with gold legs"
            },
            {
                "product_id": 29995,
                "product_name": "ellsierose 30 '' wide armchair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 11472,
                "product_name": "biggerstaff 30 '' wide polyester armchair"
            },
            {
                "product_id": 17643,
                "product_name": "anie 29 '' w armchair"
            },
            {
                "product_id": 28676,
                "product_name": "harmon 25 '' wide barrel chair and ottoman"
            },
            {
                "product_id": 34952,
                "product_name": "holbeach 27.5 '' wide tufted armchair"
            },
            {
                "product_id": 4348,
                "product_name": "laux 29 '' wide barrel chair"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 25227,
                "product_name": "office ergonomic polyurethane conference chair"
            },
            {
                "product_id": 18460,
                "product_name": "adala executive chair"
            },
            {
                "product_id": 3837,
                "product_name": "galaz 28 '' wide polyester wingback chair"
            },
            {
                "product_id": 24934,
                "product_name": "carneys 28 '' wide polyester wingback chair"
            },
            {
                "product_id": 24935,
                "product_name": "carneys 28 '' wide polyester wingback chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 18798,
                "product_name": "liam 33.5 '' wide faux leather power lift assist standard recliner"
            },
            {
                "product_id": 29667,
                "product_name": "canyon 136 '' genuine leather pillow top arm sofa"
            },
            {
                "product_id": 42963,
                "product_name": "elegant velvet desk chair for girls women modern swivel office computer chair on wheels cute vanity chair leisure chair w/arm for home study living room"
            },
            {
                "product_id": 36061,
                "product_name": "mellon 22 '' wide polyester parsons chair"
            },
            {
                "product_id": 13703,
                "product_name": "airi task chair"
            }
        ],
        "query": "comfortable accent chair"
    },
    {
        "id": 29,
        "label": [
            {
                "product_id": 23396,
                "product_name": "1 1/4 '' diameter mushroom knob"
            },
            {
                "product_id": 33436,
                "product_name": "milton 1 1/4 '' diameter mushroom knob"
            },
            {
                "product_id": 25464,
                "product_name": "waldorf 1 3/8 '' length geometric knob"
            },
            {
                "product_id": 31951,
                "product_name": "geometric knob"
            },
            {
                "product_id": 39200,
                "product_name": "wayfair basics\u00ae 1 15/16 '' length bar knob"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 35841,
                "product_name": "britannia 1 1/4 '' diameter mushroom knob"
            },
            {
                "product_id": 35843,
                "product_name": "edwardian 1 1/4 '' diameter mushroom knob"
            },
            {
                "product_id": 42120,
                "product_name": "manchester 1 1/4 '' diameter mushroom knob"
            },
            {
                "product_id": 11567,
                "product_name": "1 3/8 '' length oval knob multipack"
            },
            {
                "product_id": 42822,
                "product_name": "expressions knob"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 3197,
                "product_name": "double shower curtain hooks"
            },
            {
                "product_id": 25869,
                "product_name": "42 '' single bathroom vanity"
            },
            {
                "product_id": 39407,
                "product_name": "allicen 2 -drawer storage cabinet"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 35693,
                "product_name": "anello 20 '' w closet system reach-in sets"
            }
        ],
        "garOutput": [
            {
                "product_id": 27495,
                "product_name": "1-up hard cover pet brag album"
            },
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 29906,
                "product_name": "oliphant shower curtain hooks"
            },
            {
                "product_id": 12362,
                "product_name": "chattanooga duvet cover set"
            },
            {
                "product_id": 27530,
                "product_name": "chipley 37 '' wide faux leather power lift assist standard recliner"
            }
        ],
        "query": "bathroom vanity knobs"
    },
    {
        "id": 317,
        "label": [
            {
                "product_id": 11637,
                "product_name": "naruka writing desk"
            },
            {
                "product_id": 8995,
                "product_name": "boucher 39.4 '' w writing desk"
            },
            {
                "product_id": 32842,
                "product_name": "l-shape desk with hutch"
            },
            {
                "product_id": 5326,
                "product_name": "fairbairn writing desk"
            },
            {
                "product_id": 28906,
                "product_name": "study reversible desk with hutch"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 18043,
                "product_name": "rhapsody writing desk"
            },
            {
                "product_id": 11049,
                "product_name": "koral 48 '' w writing desk"
            },
            {
                "product_id": 33677,
                "product_name": "courser writing desk"
            },
            {
                "product_id": 34780,
                "product_name": "bali hai 9 '' h x 53.25 '' w desk hutch"
            },
            {
                "product_id": 9588,
                "product_name": "imogene 31.5 '' w writing desk and chair set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 17737,
                "product_name": "donovan twin daybed with trundle"
            },
            {
                "product_id": 29041,
                "product_name": "gayler small office for home desk"
            }
        ],
        "garOutput": [
            {
                "product_id": 1719,
                "product_name": "daniel corner desk"
            },
            {
                "product_id": 36822,
                "product_name": "coppertino ladder desk"
            },
            {
                "product_id": 25053,
                "product_name": "veazey writing desk with bookcase"
            },
            {
                "product_id": 24901,
                "product_name": "vouni l-shape executive desk"
            },
            {
                "product_id": 8858,
                "product_name": "aguanga desk and chair set"
            }
        ],
        "query": "hulmeville writing desk with hutch"
    },
    {
        "id": 183,
        "label": [
            {
                "product_id": 15924,
                "product_name": "carmen bread box"
            },
            {
                "product_id": 31762,
                "product_name": "roll top bread box"
            },
            {
                "product_id": 692,
                "product_name": "single breadboy bread box"
            },
            {
                "product_id": 15747,
                "product_name": "buckhead galvanized bread canister"
            },
            {
                "product_id": 15917,
                "product_name": "garrin tin bread box"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 693,
                "product_name": "breadboy bread box"
            },
            {
                "product_id": 23669,
                "product_name": "hillrose bamboo extra large space saving vertical bread box"
            },
            {
                "product_id": 31798,
                "product_name": "bread buddy the original bread box"
            },
            {
                "product_id": 18567,
                "product_name": "broadmoor bread box"
            },
            {
                "product_id": 15972,
                "product_name": "stainless steel bread box"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8227,
                "product_name": "aemilia 4 door accent cabinet"
            },
            {
                "product_id": 3728,
                "product_name": "bless our home metal sign"
            },
            {
                "product_id": 7839,
                "product_name": "citadel sunflower fields cheese board"
            },
            {
                "product_id": 980,
                "product_name": "delato counter height pub table"
            },
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            }
        ],
        "garOutput": [
            {
                "product_id": 35410,
                "product_name": "agostinho storage cabinet"
            },
            {
                "product_id": 33080,
                "product_name": "6 piece schall decorative for bowls"
            },
            {
                "product_id": 19353,
                "product_name": "wooden custom mimosa bar wall d\u00e9cor"
            },
            {
                "product_id": 33447,
                "product_name": "mabe power loom red/gold/beige/black rug"
            },
            {
                "product_id": 35414,
                "product_name": "mirabel 6 -shelf storage cabinet"
            }
        ],
        "query": "farmhouse bread box"
    },
    {
        "id": 26,
        "label": [
            {
                "product_id": 14949,
                "product_name": "big joe kids small bean bag chair"
            },
            {
                "product_id": 37290,
                "product_name": "dustin 28 '' wide top grain leather club chair"
            },
            {
                "product_id": 24153,
                "product_name": "evanoff 29.5 '' wide polyester barrel chair"
            },
            {
                "product_id": 8107,
                "product_name": "danis tufted upholstered side chair in antique gold"
            },
            {
                "product_id": 39010,
                "product_name": "erisha leather upholstered dining chair in espresso"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 27020,
                "product_name": "leather upholstered arm chair in black"
            },
            {
                "product_id": 23854,
                "product_name": "azra dining chair"
            },
            {
                "product_id": 25938,
                "product_name": "elizondo 27.8 '' wide leather match power lift assist ergonomic recliner"
            },
            {
                "product_id": 34689,
                "product_name": "aoting 26.5 '' wide polyester wingback chair"
            },
            {
                "product_id": 30314,
                "product_name": "dazza executive chair"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 40457,
                "product_name": "ranbir 39 '' wide velvet chesterfield chair"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 32281,
                "product_name": "metal slat back stacking side chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 13814,
                "product_name": "altun task chair"
            },
            {
                "product_id": 18203,
                "product_name": "gallardo height adjustable lab stool"
            },
            {
                "product_id": 20695,
                "product_name": "executive chair"
            },
            {
                "product_id": 22313,
                "product_name": "fordland 24 '' patio bar stool"
            },
            {
                "product_id": 9334,
                "product_name": "whittenburg upholstered storage bench"
            }
        ],
        "query": "leather chairs"
    },
    {
        "id": 7,
        "label": [
            {
                "product_id": 37645,
                "product_name": "crebilly rustic beveled accent mirror"
            },
            {
                "product_id": 14046,
                "product_name": "rosalina full length mirror"
            },
            {
                "product_id": 33118,
                "product_name": "free standing jewelry armoire with mirror"
            },
            {
                "product_id": 38608,
                "product_name": "reflessione modern & contemporary full length mirror"
            },
            {
                "product_id": 37649,
                "product_name": "krohn square beveled/distressed accent mirror"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 31387,
                "product_name": "lundeen full length mirror"
            },
            {
                "product_id": 121,
                "product_name": "marchesini wall mirror"
            },
            {
                "product_id": 38114,
                "product_name": "bruder 48 '' single bathroom vanity set with mirror"
            },
            {
                "product_id": 38654,
                "product_name": "estefania frameless wall mirror"
            },
            {
                "product_id": 28714,
                "product_name": "rectangle french victorian venetian distressed accent mirror"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 7707,
                "product_name": "meditation bunny"
            },
            {
                "product_id": 7712,
                "product_name": "snowy owl"
            },
            {
                "product_id": 7710,
                "product_name": "square zen bowl cast stone pot planter"
            },
            {
                "product_id": 29053,
                "product_name": "dhow boat sushi tray"
            },
            {
                "product_id": 6138,
                "product_name": "island 2 piece coffee table set"
            }
        ],
        "query": "driftwood mirror"
    },
    {
        "id": 442,
        "label": [
            {
                "product_id": 31864,
                "product_name": "landers office storage cabinet"
            },
            {
                "product_id": 7403,
                "product_name": "bernardston 28.25 '' w x 48 '' h x 9 '' d solid wood free-standing over-the-toilet storage"
            },
            {
                "product_id": 6710,
                "product_name": "saponaria flammable storage cabinet"
            },
            {
                "product_id": 22753,
                "product_name": "hazard 14 '' w x 34.5 '' h x 12 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 18096,
                "product_name": "duratough 2 door storage cabinet"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 22382,
                "product_name": "williston forge 4-shelves storage cabinet file cabinet office metal cupboard with 2 adjustable shelves for bedroom living room ( black )"
            },
            {
                "product_id": 8456,
                "product_name": "mclachlan 23.5 '' w x 31.5 '' h x 11.75 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 8490,
                "product_name": "tyann 23.5 '' w x 31.5 '' h x 12 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 26303,
                "product_name": "mongo storage cabinet"
            },
            {
                "product_id": 2396,
                "product_name": "jaylin storage cabinet"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 34491,
                "product_name": "swipe 49.9 '' console table"
            },
            {
                "product_id": 41093,
                "product_name": "maire 23.6 '' w x 31.5 '' h x 13 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 10078,
                "product_name": "finbar twin over twin bunk bed"
            },
            {
                "product_id": 35693,
                "product_name": "anello 20 '' w closet system reach-in sets"
            }
        ],
        "garOutput": [
            {
                "product_id": 19531,
                "product_name": "teak block end table"
            },
            {
                "product_id": 34360,
                "product_name": "gershom 32 '' wide armchair"
            },
            {
                "product_id": 35957,
                "product_name": "oval wire basket with wooden handles"
            },
            {
                "product_id": 30083,
                "product_name": "home sweet home tops wall d\u00e9cor"
            },
            {
                "product_id": 4940,
                "product_name": "conti 5 drawer standard dresser"
            }
        ],
        "query": "above toilet cabinet"
    },
    {
        "id": 108,
        "label": [
            {
                "product_id": 3042,
                "product_name": "ayesha curry 11 '' non stick grill pan"
            },
            {
                "product_id": 3886,
                "product_name": "ayesha curry non stick cookie baking sheet set"
            },
            {
                "product_id": 3917,
                "product_name": "ayesha collection parawood cooking tool and ceramic crock set , 4-piece"
            },
            {
                "product_id": 3916,
                "product_name": "ayesha curry eyelet adult apron"
            },
            {
                "product_id": 25773,
                "product_name": "mycuisina cool kitchen 8 qts stainless steel round non-stick pro casserole with lids"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 3030,
                "product_name": "ayesha curry covered saucepot 4.5 qt . hard anodized aluminum sauce pan with lid"
            },
            {
                "product_id": 12132,
                "product_name": "2 piece spice colorful and oriental scenery with flavorful paprika chili pepper curry powder kitchen curtain set"
            },
            {
                "product_id": 29811,
                "product_name": "ozeri stone earth all-in-one apeo & pfoa-free 5.3 qt non-stick sauce pan with lid"
            },
            {
                "product_id": 3920,
                "product_name": "ayesha curry tools & gadgets 6-piece tools set"
            },
            {
                "product_id": 3028,
                "product_name": "ayesha home collection hard anodized aluminum cookware set , 11-piece"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 13957,
                "product_name": "galeton tv stand for tvs up to 88 ''"
            },
            {
                "product_id": 13956,
                "product_name": "deauville coffee table"
            },
            {
                "product_id": 13955,
                "product_name": "erachidia coffee table"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            }
        ],
        "garOutput": [
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 38132,
                "product_name": "geometric teal indoor / outdoor area rug"
            },
            {
                "product_id": 27495,
                "product_name": "1-up hard cover pet brag album"
            },
            {
                "product_id": 29670,
                "product_name": "enameled 3 piece coffee , tea , & sugar jar set"
            },
            {
                "product_id": 2182,
                "product_name": "3-tier printer cart with storage shelf mobile printer stand end table home office"
            }
        ],
        "query": "ayesha curry kitchen"
    },
    {
        "id": 37,
        "label": [
            {
                "product_id": 22054,
                "product_name": "basche swivel adjustable height bar stool"
            },
            {
                "product_id": 33103,
                "product_name": "osmund bar & counter stool"
            },
            {
                "product_id": 32586,
                "product_name": "abinav counter & bar stool"
            },
            {
                "product_id": 1371,
                "product_name": "harold swivel counter & bar stool"
            },
            {
                "product_id": 29416,
                "product_name": "minnesota swivel adjustable height bar stool"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 19558,
                "product_name": "madiera 24 '' upholstered counter bar stool"
            },
            {
                "product_id": 14626,
                "product_name": "nico modern geometric 26.25 '' patio bar stool"
            },
            {
                "product_id": 40334,
                "product_name": "kants rustic 30 '' bar stool"
            },
            {
                "product_id": 19464,
                "product_name": "janeth bar & counter stool"
            },
            {
                "product_id": 11391,
                "product_name": "seval 29.5 '' patio bar stool"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 27793,
                "product_name": "adjustable height standing desk"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 17786,
                "product_name": "30 '' bar & 24 '' counter stool"
            },
            {
                "product_id": 32281,
                "product_name": "metal slat back stacking side chair"
            },
            {
                "product_id": 18460,
                "product_name": "adala executive chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 39539,
                "product_name": "chafin bar & counter stool"
            },
            {
                "product_id": 35796,
                "product_name": "kunkel l-shape computer desk"
            },
            {
                "product_id": 37611,
                "product_name": "squire wall mounted paper towel holder"
            },
            {
                "product_id": 3072,
                "product_name": "flossmoor 24.75 '' bar stool"
            },
            {
                "product_id": 7059,
                "product_name": "high-back ergonomic office chair"
            }
        ],
        "query": "bar stool 24 inches height"
    },
    {
        "id": 158,
        "label": [
            {
                "product_id": 21953,
                "product_name": "5m 5050 rgb smd led waterproof flexible strip 300 leds + 44 key ir remote"
            },
            {
                "product_id": 21346,
                "product_name": "40 watt equivalent , b10 led , dimmable light bulb , daylight ( 5000k ) e12/candelabra base"
            },
            {
                "product_id": 19062,
                "product_name": "dimmable led 79 '' under cabinet strip light"
            },
            {
                "product_id": 23917,
                "product_name": "60 watt equivalent a19 led dimmable light bulb , ( 2700 k )"
            },
            {
                "product_id": 1238,
                "product_name": "charpentier 2 drawer nightstand"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 704,
                "product_name": "3 - drawer solid wood nightstand in light brown"
            },
            {
                "product_id": 42141,
                "product_name": "16 x magnifying glass lamp , 2 in 1 table lamp and table lamp energy-saving led lamp , super bright wheeled fluorescent lamp ( 58w ) adjustable brightness and height , used for reading , hobby , crafts , workbench ( 23-67 inches )"
            },
            {
                "product_id": 33695,
                "product_name": "6- pack , led , dimmable , energy star , ul listed , a21 super bright light bulb , warm white 2700k"
            },
            {
                "product_id": 22142,
                "product_name": "kit 2 - drawer nightstand in gray"
            },
            {
                "product_id": 33274,
                "product_name": "plastic solar powered integrated led pathway light pack"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            },
            {
                "product_id": 9624,
                "product_name": "military challenge coin cabinet rack holder display case"
            },
            {
                "product_id": 14106,
                "product_name": "veronica 5 drawer chest"
            }
        ],
        "garOutput": [
            {
                "product_id": 39480,
                "product_name": "all in one clip"
            },
            {
                "product_id": 28269,
                "product_name": "aghancrossy tv stand for tvs up to 85 ''"
            },
            {
                "product_id": 16967,
                "product_name": "martelli 2 dawer nightstand"
            },
            {
                "product_id": 29119,
                "product_name": "6 outlet 2 usb-a/1 usb-c u-power desk surge protector light switch"
            },
            {
                "product_id": 35993,
                "product_name": "16.4 ft led strip light , 528 leds cob bendable tape light , 4000k cool white , ul listed"
            }
        ],
        "query": "led nightstand"
    },
    {
        "id": 454,
        "label": [
            {
                "product_id": 5083,
                "product_name": "goodspeed gray rug"
            },
            {
                "product_id": 25473,
                "product_name": "aneda gray rug"
            },
            {
                "product_id": 9075,
                "product_name": "focus 35 beige area rug"
            },
            {
                "product_id": 3133,
                "product_name": "melusine gray rug"
            },
            {
                "product_id": 318,
                "product_name": "23.6 '' x 23.6 '' multi level loop carpet tile"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 31215,
                "product_name": "alicia carpet blue area rug"
            },
            {
                "product_id": 25707,
                "product_name": "sulien gray rug"
            },
            {
                "product_id": 16500,
                "product_name": "rugsotic carpets hand-knotted silk gray area rug"
            },
            {
                "product_id": 7060,
                "product_name": "premium geometric green/brown area rug"
            },
            {
                "product_id": 31218,
                "product_name": "alicia red area carpet rug"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 18012,
                "product_name": "aditi patio 3 piece seating group with cushions"
            },
            {
                "product_id": 12350,
                "product_name": "rossmere indoor/outdoor bench cushion"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 1925,
                "product_name": "mayna shag bright ivory rug"
            },
            {
                "product_id": 738,
                "product_name": "runner groverson geometric blue area rug"
            },
            {
                "product_id": 739,
                "product_name": "runner groversen geometric gray area rug"
            },
            {
                "product_id": 16768,
                "product_name": "amilya gray rug"
            },
            {
                "product_id": 16769,
                "product_name": "amilyah gray rug"
            }
        ],
        "query": "carpet 5x6"
    },
    {
        "id": 118,
        "label": [
            {
                "product_id": 20993,
                "product_name": "aqua and grey sloth collection sloth body pillowcase"
            },
            {
                "product_id": 20977,
                "product_name": "woodland pals body pillowcase"
            },
            {
                "product_id": 21039,
                "product_name": "sweet jojo designs body pillow case"
            },
            {
                "product_id": 23112,
                "product_name": "snaresbrook pillowcase"
            },
            {
                "product_id": 2011,
                "product_name": "fiber body pillow"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 26731,
                "product_name": "alwyn home full body pillow insert without cover , soft long breathable long side sleeper fluffy bed pillow for adults , down alternative pillow for side and back sleeper , 20x54 inch white color"
            },
            {
                "product_id": 13589,
                "product_name": "donella pillowcase"
            },
            {
                "product_id": 42745,
                "product_name": "cartierville sherpa pillowcase"
            },
            {
                "product_id": 21078,
                "product_name": "rustic patch arrow print body pillow case"
            },
            {
                "product_id": 5664,
                "product_name": "newfane leopard animal print fleece pillowcase"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 529,
                "product_name": "tanvir square pillow cover & insert"
            },
            {
                "product_id": 530,
                "product_name": "henri square pillow cover & insert"
            },
            {
                "product_id": 25557,
                "product_name": "connolly tufted upholstered low profile standard bed"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 22011,
                "product_name": "darnetha 70.5 '' faux leather pillow top arm convertible sleeper sofa bed"
            },
            {
                "product_id": 16763,
                "product_name": "benedick 3 piece quilt set"
            },
            {
                "product_id": 19452,
                "product_name": "sloucher pillowcase"
            },
            {
                "product_id": 37788,
                "product_name": "home office ergonomic chair"
            },
            {
                "product_id": 187,
                "product_name": "maur kids club chair"
            }
        ],
        "query": "body pillow case"
    },
    {
        "id": 114,
        "label": [
            {
                "product_id": 5525,
                "product_name": "minatare tufted upholstered low profile storage platform bed"
            },
            {
                "product_id": 39634,
                "product_name": "elisamarie wingback linen upholstered standard bed"
            },
            {
                "product_id": 36045,
                "product_name": "aahil metal platform bed"
            },
            {
                "product_id": 29276,
                "product_name": "dalia platform bed"
            },
            {
                "product_id": 30123,
                "product_name": "amily tufted twin upholstered platform bed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 17708,
                "product_name": "thornaby upholstered standard bed"
            },
            {
                "product_id": 38503,
                "product_name": "henry bunk bed with storage"
            },
            {
                "product_id": 8823,
                "product_name": "cieran tufted upholstered storage platform bed"
            },
            {
                "product_id": 26811,
                "product_name": "summersville upholstered platform bed"
            },
            {
                "product_id": 26737,
                "product_name": "lila platform bed"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 30458,
                "product_name": "kendal shoe storage bench"
            },
            {
                "product_id": 17223,
                "product_name": "buffet storage cabinet with single glass doors and unique bell handle"
            }
        ],
        "garOutput": [
            {
                "product_id": 15875,
                "product_name": "montgomery twin daybed"
            },
            {
                "product_id": 40375,
                "product_name": "roi king upholstered platform bed"
            },
            {
                "product_id": 8689,
                "product_name": "behka tufted upholstered low profile standard bed"
            },
            {
                "product_id": 33510,
                "product_name": "cobblefield upholstered low profile standard bed"
            },
            {
                "product_id": 35096,
                "product_name": "barden platform bed"
            }
        ],
        "query": "geralyn upholstered storage platform bed"
    },
    {
        "id": 269,
        "label": [
            {
                "product_id": 18969,
                "product_name": "94 '' square arm sofa with reversible cushions"
            },
            {
                "product_id": 24703,
                "product_name": "joliet 4 piece sofa seating group with cushions"
            },
            {
                "product_id": 5726,
                "product_name": "constance teak patio sofa with cushions"
            },
            {
                "product_id": 32189,
                "product_name": "montford 76.55 '' wide outdoor teak patio sofa with cushions"
            },
            {
                "product_id": 3501,
                "product_name": "fulgentas 3 piece sofa seating group with cushions"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 16985,
                "product_name": "chambliss 4 piece teak sofa seating group with cushions"
            },
            {
                "product_id": 14844,
                "product_name": "reanna patio sofa with cushions"
            },
            {
                "product_id": 28691,
                "product_name": "hamilton teak patio sofa with cushions"
            },
            {
                "product_id": 3691,
                "product_name": "safira 2 piece sofa seating group with cushions"
            },
            {
                "product_id": 3635,
                "product_name": "englewood loveseat with cushions"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 10377,
                "product_name": "avabella trestle coffee table"
            },
            {
                "product_id": 22032,
                "product_name": "dining table with leaf"
            }
        ],
        "garOutput": [
            {
                "product_id": 42534,
                "product_name": "essonne kids chair"
            },
            {
                "product_id": 8114,
                "product_name": "anzell 2 door storage cabinet"
            },
            {
                "product_id": 42535,
                "product_name": "espere kids sofa"
            },
            {
                "product_id": 5147,
                "product_name": "mucella solid coffee table"
            },
            {
                "product_id": 5311,
                "product_name": "whyalla wooden kitchen cart"
            }
        ],
        "query": "donaldson teak couch"
    },
    {
        "id": 432,
        "label": [
            {
                "product_id": 32143,
                "product_name": "pineville kitchen island"
            },
            {
                "product_id": 17544,
                "product_name": "cribbs kitchen island with butcher block"
            },
            {
                "product_id": 12254,
                "product_name": "sydney kitchen island"
            },
            {
                "product_id": 25963,
                "product_name": "kitchen island iron"
            },
            {
                "product_id": 42047,
                "product_name": "meigs furniture kitchen island with butcher block top"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 2713,
                "product_name": "almira kitchen island"
            },
            {
                "product_id": 41402,
                "product_name": "edalyn kitchen island with butcher block top"
            },
            {
                "product_id": 3541,
                "product_name": "apoloniusz modern kitchen island with wood top"
            },
            {
                "product_id": 17847,
                "product_name": "matus butcher block kitchen cart"
            },
            {
                "product_id": 1466,
                "product_name": "wenlock kitchen cart"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 11055,
                "product_name": "harcrest writing desk"
            },
            {
                "product_id": 13247,
                "product_name": "square multi-tiered plant stand"
            }
        ],
        "garOutput": [
            {
                "product_id": 18899,
                "product_name": "custom life anchor wall d\u00e9cor"
            },
            {
                "product_id": 16763,
                "product_name": "benedick 3 piece quilt set"
            },
            {
                "product_id": 18230,
                "product_name": "kyra rolling kitchen cart with solid wood top"
            },
            {
                "product_id": 3759,
                "product_name": "kingsbridge freestanding sports rack"
            },
            {
                "product_id": 33080,
                "product_name": "6 piece schall decorative for bowls"
            }
        ],
        "query": "butcher block island"
    },
    {
        "id": 144,
        "label": [
            {
                "product_id": 3467,
                "product_name": "jeremy bamboo slat/seagrass natural area rug"
            },
            {
                "product_id": 15145,
                "product_name": "trisler hand-tufted gray/beige area rug"
            },
            {
                "product_id": 6182,
                "product_name": "pranav southwestern teal indoor / outdoor area rug"
            },
            {
                "product_id": 40097,
                "product_name": "margarita paisley green indoor/outdoor area rug"
            },
            {
                "product_id": 7243,
                "product_name": "gosim embossed rectangle memory foam non-slip 2 piece bath rug set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 15500,
                "product_name": "balderas gray/gold rug"
            },
            {
                "product_id": 23790,
                "product_name": "adelmis hand-woven taupe area rug"
            },
            {
                "product_id": 24296,
                "product_name": "horasan power loom green/plum rug"
            },
            {
                "product_id": 13169,
                "product_name": "rosana owl printed area rug"
            },
            {
                "product_id": 4441,
                "product_name": "prather oriental power loom red area rug"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 9128,
                "product_name": "abstract power loom wool/polyester red area rug"
            },
            {
                "product_id": 42543,
                "product_name": "hartland abc barnyard power loomed green/red area rug"
            },
            {
                "product_id": 7839,
                "product_name": "citadel sunflower fields cheese board"
            },
            {
                "product_id": 27333,
                "product_name": "berengere 4 '' cotton futon mattress"
            },
            {
                "product_id": 27336,
                "product_name": "vintage floral japanese 4 '' cotton futon mattress"
            }
        ],
        "garOutput": [
            {
                "product_id": 16495,
                "product_name": "rugsotic carpets hand-tufted wool magenta area rug"
            },
            {
                "product_id": 30015,
                "product_name": "wiersma luxurious off white area rug"
            },
            {
                "product_id": 26389,
                "product_name": "conroy plum rug"
            },
            {
                "product_id": 39613,
                "product_name": "moore power loom red/beige rug"
            },
            {
                "product_id": 38965,
                "product_name": "moore multi-color area rug"
            }
        ],
        "query": "rug plum"
    },
    {
        "id": 362,
        "label": [
            {
                "product_id": 37872,
                "product_name": "shuford eagle wall decor"
            },
            {
                "product_id": 3381,
                "product_name": "metallic disc iii wall decor"
            },
            {
                "product_id": 10280,
                "product_name": "decorative metal wall mirror"
            },
            {
                "product_id": 38398,
                "product_name": "moon glass decorative bowl in gold"
            },
            {
                "product_id": 20806,
                "product_name": "champagne circle metal wall decor"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 27720,
                "product_name": "shabby architectural window wall decor"
            },
            {
                "product_id": 19381,
                "product_name": "metal flower wall d\u00e9cor"
            },
            {
                "product_id": 2488,
                "product_name": "climber wall decor"
            },
            {
                "product_id": 12632,
                "product_name": "wave 3d decorative 19.63 '' x 19.63 '' 3d wall panel in white"
            },
            {
                "product_id": 4380,
                "product_name": "anchor design wall d\u00e9cor"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 34471,
                "product_name": "home sweet home framed wall d\u00e9cor"
            },
            {
                "product_id": 33911,
                "product_name": "spode home blue indigo 16 piece dinnerware set , service for 4"
            },
            {
                "product_id": 10377,
                "product_name": "avabella trestle coffee table"
            },
            {
                "product_id": 3729,
                "product_name": "hedvige save the bees table runner"
            },
            {
                "product_id": 29241,
                "product_name": "cleotha twin 75 '' wide tufted back convertible sofa"
            }
        ],
        "garOutput": [
            {
                "product_id": 13577,
                "product_name": "turquoise painted poppy indoor/outdoor throw pillow"
            },
            {
                "product_id": 12479,
                "product_name": "fransen peacock feathers throw pillow"
            },
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 37258,
                "product_name": "elborough geometric yellow rug"
            },
            {
                "product_id": 6567,
                "product_name": "minute mosaix mosaic tile"
            }
        ],
        "query": "brushed gold wall decor"
    },
    {
        "id": 388,
        "label": [
            {
                "product_id": 19565,
                "product_name": "aidann patio sectional with cushions"
            },
            {
                "product_id": 8133,
                "product_name": "guiscardo 90.9 '' wide outdoor wicker left hand facing patio sectional with cushions"
            },
            {
                "product_id": 33592,
                "product_name": "6-piece patio furniture set corner sofa set pe rattan wicker outdoor garden sectional sofa chair"
            },
            {
                "product_id": 4305,
                "product_name": "giavanni 41.3 '' wide outdoor patio sectional with cushions"
            },
            {
                "product_id": 14649,
                "product_name": "zaina outdoor 8 piece teak sectional seating group with cushions"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 252,
                "product_name": "jibe outdoor patio sectional with cushions"
            },
            {
                "product_id": 6985,
                "product_name": "outdoor patio 7 piece rattan sectional seating group with cushions"
            },
            {
                "product_id": 39117,
                "product_name": "joal 86.2 '' wide outdoor patio sectional with cushion"
            },
            {
                "product_id": 9863,
                "product_name": "dansk 7-piece modular outdoor conversational furniture set , wicker sectional sofas w/ cover"
            },
            {
                "product_id": 29671,
                "product_name": "outdoor patio furniture set 5-piece wicker rattan sectional sofa set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8751,
                "product_name": "large kids play house heavy duty canvas cotton with floor mat for bedroom indoor outdoor"
            },
            {
                "product_id": 42543,
                "product_name": "hartland abc barnyard power loomed green/red area rug"
            },
            {
                "product_id": 32518,
                "product_name": "elite rack"
            },
            {
                "product_id": 30477,
                "product_name": "hobbies and activities rock climber"
            },
            {
                "product_id": 23045,
                "product_name": "wire 3 1/2 '' center to center appliance pull"
            }
        ],
        "garOutput": [
            {
                "product_id": 37258,
                "product_name": "elborough geometric yellow rug"
            },
            {
                "product_id": 23332,
                "product_name": "benny zona hand woven 100 % cotton 18-inch pouf ottoman , natural"
            },
            {
                "product_id": 23333,
                "product_name": "benson gobi hand woven 100 % cotton 18-inch pouf ottoman , natural"
            },
            {
                "product_id": 40127,
                "product_name": "lefever end table"
            },
            {
                "product_id": 9604,
                "product_name": "balmorhea farmhouse farm life rooster figurine"
            }
        ],
        "query": "outdoor sectional doning"
    },
    {
        "id": 288,
        "label": [
            {
                "product_id": 31145,
                "product_name": "platinum shield outdoor rectangle water resistant patio table cover"
            },
            {
                "product_id": 35625,
                "product_name": "burgoon protective 3 piece water resistant patio furniture cover set"
            },
            {
                "product_id": 21116,
                "product_name": "joola dual function indoor/outdoor waterproof table tennis table cover for ping pong tables and patio furniture"
            },
            {
                "product_id": 7660,
                "product_name": "jaylon water resistant patio chair cover with 4 year warranty"
            },
            {
                "product_id": 30836,
                "product_name": "metcalfe patio heater cover - fits up to 33 ''"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 32741,
                "product_name": "water resistant 9 piece patio furniture cover set"
            },
            {
                "product_id": 28890,
                "product_name": "outdoor furniture patio round table cover waterproof uv resistant round dining table set cover striped"
            },
            {
                "product_id": 32739,
                "product_name": "water resistant 6 piece patio furniture cover set"
            },
            {
                "product_id": 9808,
                "product_name": "water resistant patio table cover with 7 year warranty"
            },
            {
                "product_id": 15056,
                "product_name": "outdoor water resistant patio dining set cover"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 12350,
                "product_name": "rossmere indoor/outdoor bench cushion"
            },
            {
                "product_id": 8752,
                "product_name": "3 tier corner plant stand foldable nesting flower pot holder elegant metal plant rack for indoor outdoor garden patio3"
            },
            {
                "product_id": 8740,
                "product_name": "alessandro rectangular multi-tiered plant stand"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 6492,
                "product_name": "ginelie 113.36 '' wide outdoor wicker u-shaped patio sectional with cushions"
            },
            {
                "product_id": 22068,
                "product_name": "brookhaven solid wood drum coffee table"
            },
            {
                "product_id": 34373,
                "product_name": "antke patio rattan seating group with cushions"
            },
            {
                "product_id": 3648,
                "product_name": "jarreth indoor/outdoor sunbrella chaise lounge cushion"
            },
            {
                "product_id": 7997,
                "product_name": "classical firepit coffee table"
            }
        ],
        "query": "round outdoor patio cover"
    },
    {
        "id": 259,
        "label": [
            {
                "product_id": 25687,
                "product_name": "orion tray top end table"
            },
            {
                "product_id": 41976,
                "product_name": "bradleigh 2 - drawer end table"
            },
            {
                "product_id": 26201,
                "product_name": "alvis cross legs end table"
            },
            {
                "product_id": 5346,
                "product_name": "ledyard wooden chairside end table"
            },
            {
                "product_id": 749,
                "product_name": "weddington end table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 2854,
                "product_name": "salas end table with storage"
            },
            {
                "product_id": 35296,
                "product_name": "pittard end table with storage"
            },
            {
                "product_id": 19219,
                "product_name": "ashwood c end table"
            },
            {
                "product_id": 14611,
                "product_name": "encinal end table"
            },
            {
                "product_id": 16927,
                "product_name": "mastrangelo end table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 5959,
                "product_name": "marple 42 '' wide 2 drawer server"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 25317,
                "product_name": "7 piece outdoor patio furniture set"
            },
            {
                "product_id": 8741,
                "product_name": "hassan free form multi-tiered plant stand"
            }
        ],
        "garOutput": [
            {
                "product_id": 29670,
                "product_name": "enameled 3 piece coffee , tea , & sugar jar set"
            },
            {
                "product_id": 30542,
                "product_name": "rest polyfill body pillow"
            },
            {
                "product_id": 3068,
                "product_name": "havsa anti-fatigue mat"
            },
            {
                "product_id": 3067,
                "product_name": "havaalani anti-fatigue mat"
            },
            {
                "product_id": 21100,
                "product_name": "hench masina anti-fatigue mat"
            }
        ],
        "query": "nursery end table"
    },
    {
        "id": 202,
        "label": [
            {
                "product_id": 32607,
                "product_name": "mybedding mattress pad"
            },
            {
                "product_id": 4806,
                "product_name": "2 '' gel memory foam mattress topper"
            },
            {
                "product_id": 37194,
                "product_name": "metal bed frame full with headboard and footboard , noise-free and anti-slip mattress foundation , platform bed frame no box spring needed"
            },
            {
                "product_id": 7012,
                "product_name": "grindstaff hypoallergenic rayon fitted down alternative mattress pad"
            },
            {
                "product_id": 39196,
                "product_name": "wayfair sleep 3 '' gel memory foam mattress topper"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 23455,
                "product_name": "4 '' gel and aloe infused memory foam mattress topper"
            },
            {
                "product_id": 37190,
                "product_name": "topmax upholstered platform bed frame mattress foundation with plywood slat support and tufted headboard , full - dark grey"
            },
            {
                "product_id": 3215,
                "product_name": "aarzo 2 '' memory foam mattress topper"
            },
            {
                "product_id": 5742,
                "product_name": "233 thread count top fiber mattress pad"
            },
            {
                "product_id": 24856,
                "product_name": "norwich 2 '' polyester mattress topper"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 14490,
                "product_name": "murrieta 51 '' genuine leather tuxedo arm loveseat"
            },
            {
                "product_id": 15678,
                "product_name": "bed frame"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 24858,
                "product_name": "naglee polyester mattress pad"
            },
            {
                "product_id": 35640,
                "product_name": "audra folding wood bed slats"
            },
            {
                "product_id": 18170,
                "product_name": "classic waterproof mattress pad"
            },
            {
                "product_id": 2053,
                "product_name": "gosney microfiber mattress pad"
            },
            {
                "product_id": 24856,
                "product_name": "norwich 2 '' polyester mattress topper"
            }
        ],
        "query": "full mattress padded liner"
    },
    {
        "id": 434,
        "label": [
            {
                "product_id": 24254,
                "product_name": "wyble 2 - light lantern geometric chandelier"
            },
            {
                "product_id": 15324,
                "product_name": "kylan 4 - light 15 '' chandelier style drum semi flush mount"
            },
            {
                "product_id": 40406,
                "product_name": "kabir 9-light shaded chandelier tiered chandelier"
            },
            {
                "product_id": 8698,
                "product_name": "radnor 3 - light unique / statement empire chandelier"
            },
            {
                "product_id": 17285,
                "product_name": "berneau 4 - light lantern square chandelier"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 20091,
                "product_name": "maggiemae 8 - light lantern geometric chandelier"
            },
            {
                "product_id": 20600,
                "product_name": "paquette 5 - light 22 '' semi flush mount"
            },
            {
                "product_id": 38876,
                "product_name": "colgan 4-light bath bar"
            },
            {
                "product_id": 25100,
                "product_name": "machen 7 - light statement tiered chandelier"
            },
            {
                "product_id": 20564,
                "product_name": "paquette 5 - light shaded drum chandelier"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 19717,
                "product_name": "commercial single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 3197,
                "product_name": "double shower curtain hooks"
            },
            {
                "product_id": 13957,
                "product_name": "galeton tv stand for tvs up to 88 ''"
            },
            {
                "product_id": 19729,
                "product_name": "waterfall single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 39407,
                "product_name": "allicen 2 -drawer storage cabinet"
            }
        ],
        "garOutput": [
            {
                "product_id": 33604,
                "product_name": "home office mesh task chair"
            },
            {
                "product_id": 13814,
                "product_name": "altun task chair"
            },
            {
                "product_id": 20719,
                "product_name": "bianchina large monitor stand"
            },
            {
                "product_id": 16763,
                "product_name": "benedick 3 piece quilt set"
            },
            {
                "product_id": 6546,
                "product_name": "owston 39.37 '' console table"
            }
        ],
        "query": "bathroom lighting"
    },
    {
        "id": 141,
        "label": [
            {
                "product_id": 17881,
                "product_name": "46 '' wood full podium with storage"
            },
            {
                "product_id": 13661,
                "product_name": "ul level 8 ballistic resistant podium"
            },
            {
                "product_id": 37033,
                "product_name": "locking key cabinet , 72-key , brushed aluminum , 11 3/4 x 4 5/8 x 15 3/4"
            },
            {
                "product_id": 8053,
                "product_name": "ml series 47.5 '' wood with wheels with storage"
            },
            {
                "product_id": 29598,
                "product_name": "aristocrat floor full podium"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 26881,
                "product_name": "saravia office storage cabinet"
            },
            {
                "product_id": 398,
                "product_name": "46.5 '' plastic full podium with wheels and storage"
            },
            {
                "product_id": 15645,
                "product_name": "43 '' plastic full podium"
            },
            {
                "product_id": 15646,
                "product_name": "47 '' plastic full podium"
            },
            {
                "product_id": 15639,
                "product_name": "46 '' plastic full podium"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 17223,
                "product_name": "buffet storage cabinet with single glass doors and unique bell handle"
            },
            {
                "product_id": 27793,
                "product_name": "adjustable height standing desk"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 20486,
                "product_name": "lockheart end table"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 13629,
                "product_name": "bedroom led nightstand , black/white modern high gloss led lights nightstand bedside end table with 2 drawers table cabinet with 2 drawers ( black )"
            },
            {
                "product_id": 16646,
                "product_name": "modern high gloss nightstand white night stand with led light , double drawer nightstand cabinet storage bedside tables with drawer 2-tier for bedroom living room"
            },
            {
                "product_id": 20368,
                "product_name": "hinnenkamp 7 drawer dresser"
            },
            {
                "product_id": 5845,
                "product_name": "pools yard tools porch backyard 63 gallon plastic deck box"
            },
            {
                "product_id": 8437,
                "product_name": "jessica 24 pair shoe storage cabinet"
            }
        ],
        "query": "podium with locking cabinet"
    },
    {
        "id": 358,
        "label": [
            {
                "product_id": 6376,
                "product_name": "loeffler modern & contemporary full length mirror"
            },
            {
                "product_id": 30055,
                "product_name": "ronan simple oversized thin aluminum alloy modern full length mirror"
            },
            {
                "product_id": 40946,
                "product_name": "donohoo on the door full length mirror"
            },
            {
                "product_id": 32002,
                "product_name": "chancee full length mirror"
            },
            {
                "product_id": 32369,
                "product_name": "pullout closet modern & contemporary vanity mirror"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 4698,
                "product_name": "bowenvale wall mirror"
            },
            {
                "product_id": 32559,
                "product_name": "grid mirror"
            },
            {
                "product_id": 34030,
                "product_name": "lucia glass sliding closet door"
            },
            {
                "product_id": 30069,
                "product_name": "sliding closet mirror door top guide"
            },
            {
                "product_id": 26276,
                "product_name": "galveston sliding barn door wood window wall accent mirror"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 35693,
                "product_name": "anello 20 '' w closet system reach-in sets"
            },
            {
                "product_id": 31905,
                "product_name": "toulouse printed single shower curtain"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 25869,
                "product_name": "42 '' single bathroom vanity"
            },
            {
                "product_id": 21200,
                "product_name": "cutts bar with wine storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 41523,
                "product_name": "lavendon farmhouse/country distressed accent mirror"
            },
            {
                "product_id": 33974,
                "product_name": "novelty knob"
            },
            {
                "product_id": 13504,
                "product_name": "teak tripod end table"
            },
            {
                "product_id": 28871,
                "product_name": "amerigo over the door jewelry armoire with mirror"
            },
            {
                "product_id": 12247,
                "product_name": "boykin wardrobe armoire"
            }
        ],
        "query": "sliding closet mirror"
    },
    {
        "id": 67,
        "label": [
            {
                "product_id": 10280,
                "product_name": "decorative metal wall mirror"
            },
            {
                "product_id": 30970,
                "product_name": "hamo contemporary wall mirror"
            },
            {
                "product_id": 3357,
                "product_name": "rustic distressed accent mirror"
            },
            {
                "product_id": 32559,
                "product_name": "grid mirror"
            },
            {
                "product_id": 6265,
                "product_name": "thornbury oval bevel frameless wall mirror"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 33158,
                "product_name": "reddy rustic beveled accent mirror"
            },
            {
                "product_id": 19768,
                "product_name": "cord modern farmhouse bathroom/vanity mirror"
            },
            {
                "product_id": 432,
                "product_name": "metal mirror waterfall fountain with light"
            },
            {
                "product_id": 40477,
                "product_name": "contemporary wall mirror"
            },
            {
                "product_id": 4697,
                "product_name": "akridge mirror"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            }
        ],
        "garOutput": [
            {
                "product_id": 16234,
                "product_name": "firelined safe combination and electronic lock"
            },
            {
                "product_id": 15231,
                "product_name": "strickler 18 '' wall clock"
            },
            {
                "product_id": 25907,
                "product_name": "1-light carpathian wall sconce"
            },
            {
                "product_id": 15225,
                "product_name": "gutha garden 14 '' wall clock"
            },
            {
                "product_id": 21222,
                "product_name": "raami 112 fl oz . serving bowl"
            }
        ],
        "query": "osgood mirror"
    },
    {
        "id": 196,
        "label": [
            {
                "product_id": 30849,
                "product_name": "capricorn 5 drawer chest"
            },
            {
                "product_id": 21679,
                "product_name": "kenton 6 drawer double dresser"
            },
            {
                "product_id": 2699,
                "product_name": "regan 5 drawer accent chest"
            },
            {
                "product_id": 37102,
                "product_name": "gastineau 4 drawer chest"
            },
            {
                "product_id": 374,
                "product_name": "nessa 5 drawer chest"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 39757,
                "product_name": "augie 5 drawer chest"
            },
            {
                "product_id": 28812,
                "product_name": "alyssa mid century modern 6 drawer double dresser"
            },
            {
                "product_id": 25022,
                "product_name": "haven kids \u2019 5 drawer chest"
            },
            {
                "product_id": 26046,
                "product_name": "ashtin 5 drawer chest"
            },
            {
                "product_id": 19970,
                "product_name": "taylor 5 drawer accent chest"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 16370,
                "product_name": "stikki clips white 20 per pack"
            },
            {
                "product_id": 22032,
                "product_name": "dining table with leaf"
            },
            {
                "product_id": 23045,
                "product_name": "wire 3 1/2 '' center to center appliance pull"
            },
            {
                "product_id": 13758,
                "product_name": "kisner server"
            }
        ],
        "garOutput": [
            {
                "product_id": 7407,
                "product_name": "ocampo mahogany entrance console table"
            },
            {
                "product_id": 36782,
                "product_name": "amara wall shelf"
            },
            {
                "product_id": 6579,
                "product_name": "charlie 6 drawer double dresser"
            },
            {
                "product_id": 2787,
                "product_name": "milian 6 drawer double dresser"
            },
            {
                "product_id": 9891,
                "product_name": "stambaugh 44 '' console table"
            }
        ],
        "query": "chabely 5 draw chest"
    },
    {
        "id": 169,
        "label": [
            {
                "product_id": 32812,
                "product_name": "wine sideboard"
            },
            {
                "product_id": 3716,
                "product_name": "dylana 32 '' wide server"
            },
            {
                "product_id": 10246,
                "product_name": "credenza"
            },
            {
                "product_id": 40116,
                "product_name": "gayle 31 '' wide server"
            },
            {
                "product_id": 8509,
                "product_name": "jusino 46 '' wide 2 drawer buffet table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 3675,
                "product_name": "greggs tv stand for tvs up to 58 ''"
            },
            {
                "product_id": 14748,
                "product_name": "25 '' wide server"
            },
            {
                "product_id": 2200,
                "product_name": "geeske 35.43 '' wide 1 drawer server"
            },
            {
                "product_id": 20855,
                "product_name": "lorraine 48 '' wide 2 drawer acacia wood server"
            },
            {
                "product_id": 17056,
                "product_name": "thummepalli 39.96 '' wide server"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 1029,
                "product_name": "candelario bar with wine storage"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 2449,
                "product_name": "alberta 15 bottle wine bar"
            }
        ],
        "garOutput": [
            {
                "product_id": 10014,
                "product_name": "malek arm chair in brown/yellow"
            },
            {
                "product_id": 41176,
                "product_name": "heid wine rack"
            },
            {
                "product_id": 23568,
                "product_name": "hufnagel cake stand"
            },
            {
                "product_id": 7463,
                "product_name": "willena sea life melamine platter"
            },
            {
                "product_id": 30178,
                "product_name": "devonda upholstered parsons chair"
            }
        ],
        "query": "coffee bar buffet"
    },
    {
        "id": 368,
        "label": [
            {
                "product_id": 343,
                "product_name": "multi-functional manual noodle machine dumpling skin maker kitchen equipment ."
            },
            {
                "product_id": 37806,
                "product_name": "baking tool-4 piece set"
            },
            {
                "product_id": 19864,
                "product_name": "taika coupe pasta bowl"
            },
            {
                "product_id": 11344,
                "product_name": "cookinex stainless steel pasta spoon"
            },
            {
                "product_id": 3900,
                "product_name": "ayesha curry bar essential tool set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 38765,
                "product_name": "paderno world cuisine spaghetti alla chitarra maker"
            },
            {
                "product_id": 98,
                "product_name": "bar tool set"
            },
            {
                "product_id": 7478,
                "product_name": "cooks innovations 2-in-1 pasta and sauce serving tongs"
            },
            {
                "product_id": 38217,
                "product_name": "lavo home stainless steel 5 quart colander"
            },
            {
                "product_id": 7121,
                "product_name": "chef craft 23-piece nylon select kitchen tool and gadget utensil set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 15086,
                "product_name": "napoleon cake knife"
            },
            {
                "product_id": 10334,
                "product_name": "electric fully automatic pasta and ramen noodle maker machine 9 noodle shapes to choose for restaurant and kitchen"
            },
            {
                "product_id": 16453,
                "product_name": "global knives pasta kitchen tongs"
            },
            {
                "product_id": 7839,
                "product_name": "citadel sunflower fields cheese board"
            },
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            }
        ],
        "garOutput": [
            {
                "product_id": 19869,
                "product_name": "teema tiimi dessert bowl"
            },
            {
                "product_id": 19868,
                "product_name": "teema tiimi 11 oz . rice bowl"
            },
            {
                "product_id": 7886,
                "product_name": "pasta scoop"
            },
            {
                "product_id": 38763,
                "product_name": "paderno world cuisine large crinkle knife"
            },
            {
                "product_id": 33909,
                "product_name": "spode christmas tree bake loaf pan"
            }
        ],
        "query": "pasta tools"
    },
    {
        "id": 198,
        "label": [
            {
                "product_id": 38325,
                "product_name": "blur cake plate"
            },
            {
                "product_id": 5623,
                "product_name": "buyse cake stand"
            },
            {
                "product_id": 37460,
                "product_name": "sherell luxury mirror top cake stand"
            },
            {
                "product_id": 38535,
                "product_name": "artesano cake stand"
            },
            {
                "product_id": 17557,
                "product_name": "acrylic two tone cake platter"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 6436,
                "product_name": "hilburn dome cake stand"
            },
            {
                "product_id": 32208,
                "product_name": "davida cake stand"
            },
            {
                "product_id": 7321,
                "product_name": "rothsville pedestal cake stand"
            },
            {
                "product_id": 41936,
                "product_name": "hastings catering cake melamine platter"
            },
            {
                "product_id": 6439,
                "product_name": "coutu adjustable cake stand"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 16453,
                "product_name": "global knives pasta kitchen tongs"
            },
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            },
            {
                "product_id": 7903,
                "product_name": "coops & feathers treat wire basket"
            },
            {
                "product_id": 11154,
                "product_name": "shalanda 8 oz . dessert bowl"
            },
            {
                "product_id": 32548,
                "product_name": "dublin 6 in 1 server cake stand"
            }
        ],
        "garOutput": [
            {
                "product_id": 32790,
                "product_name": "schemerdine wall rack"
            },
            {
                "product_id": 10541,
                "product_name": "mccullum funny cute goat mountain climbing or rock climbing cartoon coffee mug"
            },
            {
                "product_id": 36778,
                "product_name": "galilea modern coffee table"
            },
            {
                "product_id": 23568,
                "product_name": "hufnagel cake stand"
            },
            {
                "product_id": 4651,
                "product_name": "feldt proud peacocks coir 28 in . x 16 in . non-slip outdoor door mat"
            }
        ],
        "query": "cake plates with tops"
    },
    {
        "id": 231,
        "label": [
            {
                "product_id": 21100,
                "product_name": "hench masina anti-fatigue mat"
            },
            {
                "product_id": 20182,
                "product_name": "jamesport spring bloom premium anti-fatigue mat"
            },
            {
                "product_id": 20195,
                "product_name": "aleishia anti-fatigue mat"
            },
            {
                "product_id": 10913,
                "product_name": "distressed boho standing gray anti-fatigue mat"
            },
            {
                "product_id": 20145,
                "product_name": "fleckenstein cushioned anti-fatigue mat"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 24774,
                "product_name": "hard floor straight rectangular chair mat"
            },
            {
                "product_id": 20192,
                "product_name": "christenberry anti-fatigue mat"
            },
            {
                "product_id": 10920,
                "product_name": "graceton anti-fatigue mat"
            },
            {
                "product_id": 31481,
                "product_name": "michalski hand painted anti-fatigue mat"
            },
            {
                "product_id": 27859,
                "product_name": "slypnos rectangular pvc hard floor straight chair mat"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 9142,
                "product_name": "dark purple area rug"
            },
            {
                "product_id": 20168,
                "product_name": "stephens grateful hearts gather anti-fatigue mat"
            },
            {
                "product_id": 20170,
                "product_name": "stevens home sweet home anti-fatigue mat"
            },
            {
                "product_id": 20169,
                "product_name": "stephenson grateful thankful bless anti-fatigue mat"
            },
            {
                "product_id": 20167,
                "product_name": "steiner faith family friends anti-fatigue mat"
            }
        ],
        "garOutput": [
            {
                "product_id": 25796,
                "product_name": "rozella anti-fatigue mat"
            },
            {
                "product_id": 19170,
                "product_name": "anette salon anti-fatigue mat"
            },
            {
                "product_id": 7811,
                "product_name": "cline bless this home anti-fatigue mat"
            },
            {
                "product_id": 7809,
                "product_name": "kole family time anti-fatigue mat"
            },
            {
                "product_id": 7815,
                "product_name": "kerry fluer de lis anti-fatigue mat"
            }
        ],
        "query": "anti fatigue mat"
    },
    {
        "id": 109,
        "label": [
            {
                "product_id": 4088,
                "product_name": "bonney oriental sky blue/light gray/white/navy area rug"
            },
            {
                "product_id": 4860,
                "product_name": "radbroke oriental multicolor area rug"
            },
            {
                "product_id": 26931,
                "product_name": "hillsby oriental dark blue/teal area rug"
            },
            {
                "product_id": 26138,
                "product_name": "hillsby floral multicolor area rug"
            },
            {
                "product_id": 4112,
                "product_name": "arianna oriental blue/light gray area rug"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 38970,
                "product_name": "ashville oriental multicolor area rug"
            },
            {
                "product_id": 22295,
                "product_name": "angenetta power loom red/khaki rug"
            },
            {
                "product_id": 9095,
                "product_name": "oh oriental machine made power loom wool/polyester black/red/beige area rug"
            },
            {
                "product_id": 2618,
                "product_name": "clarence oriental beige rug"
            },
            {
                "product_id": 26118,
                "product_name": "hillsby abstract blue/orange/pink area rug"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 9128,
                "product_name": "abstract power loom wool/polyester red area rug"
            },
            {
                "product_id": 42543,
                "product_name": "hartland abc barnyard power loomed green/red area rug"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 22986,
                "product_name": "turquoise succulent - wrapped canvas photograph print"
            },
            {
                "product_id": 11781,
                "product_name": "little arrow design co unicorn pool float paper print"
            },
            {
                "product_id": 26105,
                "product_name": "tia solid coffee table"
            },
            {
                "product_id": 33791,
                "product_name": "florid lantern 6.25 '' x 7.25 '' straight edge ceramic singular subway tile"
            },
            {
                "product_id": 3472,
                "product_name": "chair pad cushion"
            }
        ],
        "query": "hillsby oriental rug"
    },
    {
        "id": 233,
        "label": [
            {
                "product_id": 40533,
                "product_name": "marilyn 5 - light candle style classic/traditional chandelier"
            },
            {
                "product_id": 37063,
                "product_name": "procyon 36-in integrated led bathroom lighting fixture"
            },
            {
                "product_id": 24579,
                "product_name": "hephzibah 4 - light candle style drum chandelier"
            },
            {
                "product_id": 33892,
                "product_name": "lauritzen 5 - light kitchen island linear pendant"
            },
            {
                "product_id": 5955,
                "product_name": "orellana 4 - light unique / statement tiered chandelier"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 29077,
                "product_name": "naomi 4 - light unique geometric chandelier with wood accents"
            },
            {
                "product_id": 2465,
                "product_name": "liebert 4 - light candle style wagon wheel chandelier"
            },
            {
                "product_id": 21623,
                "product_name": "andrews 1 - light single globe pendant with crystal accents"
            },
            {
                "product_id": 20784,
                "product_name": "nigan 1 light wallchieres"
            },
            {
                "product_id": 2682,
                "product_name": "gabby 3 - light unique globe chandelier"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 11850,
                "product_name": "pi creative art where the passion flower grows i single shower curtain"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 29150,
                "product_name": "medrano dinosaur portrait - triceratops paper print"
            },
            {
                "product_id": 30477,
                "product_name": "hobbies and activities rock climber"
            },
            {
                "product_id": 20085,
                "product_name": "dario 12 - light candle style wagon wheel chandelier"
            }
        ],
        "garOutput": [
            {
                "product_id": 39027,
                "product_name": "60 '' double bathroom vanity top in white with sink"
            },
            {
                "product_id": 39026,
                "product_name": "48 '' single bathroom vanity top in white with sink"
            },
            {
                "product_id": 39028,
                "product_name": "60 '' single bathroom vanity top in white with sink"
            },
            {
                "product_id": 39025,
                "product_name": "60 '' single bathroom vanity top with sink"
            },
            {
                "product_id": 39029,
                "product_name": "48 '' single bathroom vanity top with sink"
            }
        ],
        "query": "kelly clarkson light fixtures"
    },
    {
        "id": 145,
        "label": [
            {
                "product_id": 33725,
                "product_name": "curved handle 3 '' center to center arch pull multipack"
            },
            {
                "product_id": 23360,
                "product_name": "crystal lace 3 '' center to center handle bar pull"
            },
            {
                "product_id": 23434,
                "product_name": "3 3/4 '' center to center bar pull"
            },
            {
                "product_id": 19318,
                "product_name": "anwick center to center 5 '' center to center bar pull"
            },
            {
                "product_id": 23404,
                "product_name": "taryn cabinet ring pull"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 23385,
                "product_name": "francisco 3 '' center to center bar pull multipack"
            },
            {
                "product_id": 37978,
                "product_name": "san michele 5 '' center to center bar pull"
            },
            {
                "product_id": 30863,
                "product_name": "distressed hardware 5 '' center to center arch pull"
            },
            {
                "product_id": 19319,
                "product_name": "anwick center to center 6 5/16 '' center to center bar pull"
            },
            {
                "product_id": 31965,
                "product_name": "5 1/16 '' center to center arch pull handle"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 3728,
                "product_name": "bless our home metal sign"
            },
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 4553,
                "product_name": "modu-licious chest"
            },
            {
                "product_id": 31713,
                "product_name": "mcgonigal bamboo 4 piece bathroom accessory set"
            },
            {
                "product_id": 41521,
                "product_name": "bee magnet"
            },
            {
                "product_id": 38734,
                "product_name": "whiskey bar street sign wall d\u00e9cor"
            },
            {
                "product_id": 6411,
                "product_name": "preside base"
            }
        ],
        "query": "liberty hardware francisco"
    },
    {
        "id": 248,
        "label": [
            {
                "product_id": 16520,
                "product_name": "rotating surge protector wall mounted power strip"
            },
            {
                "product_id": 25501,
                "product_name": "migdalia surge protector power strip cord"
            },
            {
                "product_id": 9525,
                "product_name": "6-outlet pivot plug surge protector wall mounted power strip"
            },
            {
                "product_id": 9524,
                "product_name": "6-outlet surge protector power strip"
            },
            {
                "product_id": 16396,
                "product_name": "anstett 3-outlet surge protector cord"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 9526,
                "product_name": "8-outlet surge protector power strip"
            },
            {
                "product_id": 35972,
                "product_name": "litedge wi-fi accessible smart power strip socket plate"
            },
            {
                "product_id": 16521,
                "product_name": "surge protectors wall mounted power strip"
            },
            {
                "product_id": 35646,
                "product_name": "jaxson hypoallergenic zippered mattress protector"
            },
            {
                "product_id": 3721,
                "product_name": "felt furniture pads for hardwood floors - 3mm thick self adhesive non slip furniture pads , 140 pieces various sizes floor protectors for furniture ( dark brown )"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20718,
                "product_name": "abramowski monitor stand"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 31992,
                "product_name": "decorative valet rod"
            },
            {
                "product_id": 17412,
                "product_name": "olivas solid sheer rod pocket single curtain panel"
            },
            {
                "product_id": 347,
                "product_name": "1.93 '' solar powered outdoor floating light"
            }
        ],
        "garOutput": [
            {
                "product_id": 40275,
                "product_name": "15-amp afci outlet"
            },
            {
                "product_id": 37183,
                "product_name": "direct plug-in surge protector wall mounted outlet"
            },
            {
                "product_id": 40276,
                "product_name": "outlet"
            },
            {
                "product_id": 37181,
                "product_name": "direct plug-in surge protector wall mounted power strip"
            },
            {
                "product_id": 405,
                "product_name": "surgearrest surge protector power strip"
            }
        ],
        "query": "surge protector"
    },
    {
        "id": 152,
        "label": [
            {
                "product_id": 28541,
                "product_name": "palma upholstered dining chair"
            },
            {
                "product_id": 2867,
                "product_name": "arden lane mid back tufted task chair"
            },
            {
                "product_id": 20578,
                "product_name": "lozano upholstered dining chair"
            },
            {
                "product_id": 7095,
                "product_name": "brinly casual lift office task chair"
            },
            {
                "product_id": 28839,
                "product_name": "estate porch rocking chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 28443,
                "product_name": "melker upholstered parsons chair in cream"
            },
            {
                "product_id": 39096,
                "product_name": "landgrove tufted velvet side chair"
            },
            {
                "product_id": 12409,
                "product_name": "office chair"
            },
            {
                "product_id": 31914,
                "product_name": "aeyla linen side chair in gray"
            },
            {
                "product_id": 30682,
                "product_name": "cleckheaton spring patio chair with cushions"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 32281,
                "product_name": "metal slat back stacking side chair"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 16981,
                "product_name": "hafsa velvet task chair"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 18579,
                "product_name": "patara side chair"
            },
            {
                "product_id": 18592,
                "product_name": "capri 19 '' wide side chair"
            },
            {
                "product_id": 18596,
                "product_name": "dervish 29.5 '' bar stool"
            },
            {
                "product_id": 18595,
                "product_name": "dervish counter & bar stool"
            },
            {
                "product_id": 38719,
                "product_name": "briarhill patio reclining chaise lounge with cushion"
            }
        ],
        "query": "wishbone chair"
    },
    {
        "id": 401,
        "label": [
            {
                "product_id": 38149,
                "product_name": "4.5 '' h glass bell pendant shade ( clip on ) in clear"
            },
            {
                "product_id": 34629,
                "product_name": "6 '' h glass novelty lamp shade"
            },
            {
                "product_id": 31111,
                "product_name": "5.25 '' h x 2.25 '' w glass bell lamp shade in clear"
            },
            {
                "product_id": 41143,
                "product_name": "5 '' h glass bell lamp shade in white"
            },
            {
                "product_id": 473,
                "product_name": "4.75 '' h glass bell pendant shade ( screw on ) in clear"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 23496,
                "product_name": "11 '' glass lamp shade"
            },
            {
                "product_id": 1820,
                "product_name": "4.75 '' h glass bell ceiling fan fitter shade ( screw on ) in white/green"
            },
            {
                "product_id": 26215,
                "product_name": "4.5 '' h glass bell ceiling fan fitter shade ( screw on ) in white"
            },
            {
                "product_id": 22729,
                "product_name": "12 '' w glass empire pendant shade ( screw on )"
            },
            {
                "product_id": 34293,
                "product_name": "5.75 '' h glass empire pendant shade"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 36454,
                "product_name": "aneka 17 '' table lamp"
            },
            {
                "product_id": 34102,
                "product_name": "mott street sled coffee table"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 36449,
                "product_name": "kisner 3 - light pool table linear pendant"
            }
        ],
        "garOutput": [
            {
                "product_id": 33791,
                "product_name": "florid lantern 6.25 '' x 7.25 '' straight edge ceramic singular subway tile"
            },
            {
                "product_id": 4169,
                "product_name": "liverman end table"
            },
            {
                "product_id": 20456,
                "product_name": "3 '' x 6 '' glass subway tile"
            },
            {
                "product_id": 5775,
                "product_name": "tray white plug-in led outdoor floating light"
            },
            {
                "product_id": 5773,
                "product_name": "16 '' h x 16 '' w x 16 '' d cube white chargeable led outdoor floating light"
            }
        ],
        "query": "glass lsmp shades"
    },
    {
        "id": 392,
        "label": [
            {
                "product_id": 26236,
                "product_name": "analog quartz tabletop clock"
            },
            {
                "product_id": 13346,
                "product_name": "tatte wall clock"
            },
            {
                "product_id": 22498,
                "product_name": "oversized vinci 24 '' wall clock"
            },
            {
                "product_id": 39341,
                "product_name": "pineapple scroll 13.5 '' wall clock"
            },
            {
                "product_id": 32849,
                "product_name": "fancy crystals peacock wall clock"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 2663,
                "product_name": "decorative wall clock"
            },
            {
                "product_id": 41063,
                "product_name": "mantelpiece clock"
            },
            {
                "product_id": 39332,
                "product_name": "solstice thermometer clock"
            },
            {
                "product_id": 13335,
                "product_name": "montmarte wall clock"
            },
            {
                "product_id": 19292,
                "product_name": "decorative quartz ivana wall clock"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 14106,
                "product_name": "veronica 5 drawer chest"
            },
            {
                "product_id": 13858,
                "product_name": "the elephant graphic art"
            }
        ],
        "garOutput": [
            {
                "product_id": 17394,
                "product_name": "chad wool beige/black area rug"
            },
            {
                "product_id": 17395,
                "product_name": "chad beige/black area rug"
            },
            {
                "product_id": 32933,
                "product_name": "storage and organization carry basket"
            },
            {
                "product_id": 2498,
                "product_name": "70 '' h pivot frameless tub door"
            },
            {
                "product_id": 14467,
                "product_name": "frieling zenker loaf pan"
            }
        ],
        "query": "china clock"
    },
    {
        "id": 83,
        "label": [
            {
                "product_id": 29594,
                "product_name": "on the move i/ii by karen moehr - 2 piece wrapped canvas painting print set"
            },
            {
                "product_id": 31581,
                "product_name": "corrida ii wall art on wrapped canvas"
            },
            {
                "product_id": 28360,
                "product_name": "blue waves abstract - wall art on wrapped canvas"
            },
            {
                "product_id": 40432,
                "product_name": "abstract strokes sun and rain paint - wrapped canvas graphic art print"
            },
            {
                "product_id": 12264,
                "product_name": "rural sunset ii by august grove - wrapped canvas graphic art print"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 10650,
                "product_name": "flower girl mexican art colorful 1-gang duplex outlet wall plate"
            },
            {
                "product_id": 8111,
                "product_name": "cafe terrace at night by vincent van gogh - print on canvas"
            },
            {
                "product_id": 7922,
                "product_name": "picture framed print on wood"
            },
            {
                "product_id": 41683,
                "product_name": "a premium impression sunrise graphic art print multi-piece image on wrapped canvas"
            },
            {
                "product_id": 35088,
                "product_name": "natural prints i - wrapped canvas graphic art print"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 29144,
                "product_name": "mcdonald dinosaur lake canvas art"
            },
            {
                "product_id": 40825,
                "product_name": "gefen geometric polygon rooster figurine"
            },
            {
                "product_id": 30826,
                "product_name": "belgr concrete propane/natural gas fire pit table"
            },
            {
                "product_id": 27320,
                "product_name": "0.75 '' x 0.75 '' seashell tile"
            },
            {
                "product_id": 9761,
                "product_name": "scaggs 6 piece bathroom accessory set"
            }
        ],
        "garOutput": [
            {
                "product_id": 27722,
                "product_name": "classic architectural window wall decor"
            },
            {
                "product_id": 27721,
                "product_name": "rustic architectural window wall decor"
            },
            {
                "product_id": 37238,
                "product_name": "trigg wall d\u00e9cor"
            },
            {
                "product_id": 37237,
                "product_name": "trigg ceramic planter wall decor"
            },
            {
                "product_id": 29395,
                "product_name": "henriquez 30.75 '' wide armchair"
            }
        ],
        "query": "wall art fiji"
    },
    {
        "id": 374,
        "label": [
            {
                "product_id": 41478,
                "product_name": "ackerman 16 '' bed frame"
            },
            {
                "product_id": 19820,
                "product_name": "hermosillo solid wood platform bed frame"
            },
            {
                "product_id": 32641,
                "product_name": "aayanah bed frame"
            },
            {
                "product_id": 1325,
                "product_name": "aieshia 34.6 '' steel bed frame"
            },
            {
                "product_id": 2052,
                "product_name": "higbee 14 '' steel bed frame"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 41157,
                "product_name": "adjustable massage bed frame base wireless remote usb ports and led light"
            },
            {
                "product_id": 16345,
                "product_name": "ashbury insta-lock bed frame"
            },
            {
                "product_id": 37190,
                "product_name": "topmax upholstered platform bed frame mattress foundation with plywood slat support and tufted headboard , full - dark grey"
            },
            {
                "product_id": 16643,
                "product_name": "durham 18 '' steel bed frame"
            },
            {
                "product_id": 34580,
                "product_name": "joyce adjustable-size bed frame"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 35693,
                "product_name": "anello 20 '' w closet system reach-in sets"
            },
            {
                "product_id": 10078,
                "product_name": "finbar twin over twin bunk bed"
            },
            {
                "product_id": 34491,
                "product_name": "swipe 49.9 '' console table"
            },
            {
                "product_id": 34374,
                "product_name": "alderson 3 drawer combo dresser"
            }
        ],
        "garOutput": [
            {
                "product_id": 19922,
                "product_name": "shonna 25.2 '' w closet rod"
            },
            {
                "product_id": 13577,
                "product_name": "turquoise painted poppy indoor/outdoor throw pillow"
            },
            {
                "product_id": 35611,
                "product_name": "outdoor replacement cushion"
            },
            {
                "product_id": 139,
                "product_name": "strausstown oh sweetie single comforter"
            },
            {
                "product_id": 24208,
                "product_name": "ambrie floating desk with hutch"
            }
        ],
        "query": "sinks bed frame"
    },
    {
        "id": 166,
        "label": [
            {
                "product_id": 10613,
                "product_name": "peacock feather 1-gang toggle light switch wall plate"
            },
            {
                "product_id": 35086,
                "product_name": "cool feathers ii - wrapped canvas graphic art print"
            },
            {
                "product_id": 11303,
                "product_name": "large vintage fern illustrations framed botanical prints wall art | set of 2 , 19 \u201d x 25.5 \u201d each"
            },
            {
                "product_id": 6525,
                "product_name": "elfin by art lionse photographic print on canvas"
            },
            {
                "product_id": 13464,
                "product_name": "beautiful paris eiffel tower under blue sky 5 piece wall art on wrapped canvas set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 41684,
                "product_name": "a premium chapelton at argenteuil graphic art print multi-piece image on wrapped canvas"
            },
            {
                "product_id": 6348,
                "product_name": "summer 5 graphic art on wrapped canvas"
            },
            {
                "product_id": 689,
                "product_name": "autumn trees i by conrad knutsen framed painting print"
            },
            {
                "product_id": 11768,
                "product_name": "stained glass floral art wall clock"
            },
            {
                "product_id": 13451,
                "product_name": "feather ii graphic art on wrapped canvas"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 40825,
                "product_name": "gefen geometric polygon rooster figurine"
            },
            {
                "product_id": 29144,
                "product_name": "mcdonald dinosaur lake canvas art"
            },
            {
                "product_id": 25830,
                "product_name": "must love dogs 29 in . x 18 in . non-slip outdoor door mat"
            },
            {
                "product_id": 9761,
                "product_name": "scaggs 6 piece bathroom accessory set"
            },
            {
                "product_id": 13535,
                "product_name": "pottery framed graphic art print on canvas"
            }
        ],
        "garOutput": [
            {
                "product_id": 33664,
                "product_name": "iaconetti storage cabinet"
            },
            {
                "product_id": 33665,
                "product_name": "delasandro metal storage cabinet"
            },
            {
                "product_id": 25950,
                "product_name": "abernethy sailboat single curtain panel"
            },
            {
                "product_id": 25957,
                "product_name": "gilus cherry blossom bamboo sheer single curtain panel"
            },
            {
                "product_id": 25953,
                "product_name": "abella natural beaded solid color single curtain panel"
            }
        ],
        "query": "feather wall art"
    },
    {
        "id": 334,
        "label": [
            {
                "product_id": 15179,
                "product_name": "abrahante butterfly leaf rubberwood solid wood dining set"
            },
            {
                "product_id": 15183,
                "product_name": "haan butterfly leaf rubberwood solid wood dining set"
            },
            {
                "product_id": 15203,
                "product_name": "alysse butterfly leaf rubberwood solid wood dining set"
            },
            {
                "product_id": 15212,
                "product_name": "alyx butterfly leaf rubberwood solid wood dining set"
            },
            {
                "product_id": 15189,
                "product_name": "asynjur butterfly leaf rubberwood solid wood dining set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 15202,
                "product_name": "alyssah butterfly leaf solid wood dining set"
            },
            {
                "product_id": 15186,
                "product_name": "haderslev butterfly leaf rubberwood solid wood dining set"
            },
            {
                "product_id": 15211,
                "product_name": "alyworth butterfly leaf solid wood dining set"
            },
            {
                "product_id": 15180,
                "product_name": "abraheem butterfly leaf rubberwood solid wood dining set"
            },
            {
                "product_id": 15194,
                "product_name": "butterfly leaf rubberwood solid wood dining set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 1585,
                "product_name": "lulette decorative tin toy duckling"
            },
            {
                "product_id": 29942,
                "product_name": "17 '' artificial aloe succulent in pot"
            },
            {
                "product_id": 1583,
                "product_name": "lulette decorative tin toy pecking chick"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 15376,
                "product_name": "esschertdesign bumble bee house"
            }
        ],
        "garOutput": [
            {
                "product_id": 7897,
                "product_name": "cendrillon 1 - drawer filing storage cabinet"
            },
            {
                "product_id": 22986,
                "product_name": "turquoise succulent - wrapped canvas photograph print"
            },
            {
                "product_id": 33666,
                "product_name": "120 gallon deck box"
            },
            {
                "product_id": 4445,
                "product_name": "3 piece kitchen canister set"
            },
            {
                "product_id": 16326,
                "product_name": "39 '' rectangular fold-in-half portable folding table"
            }
        ],
        "query": "itchington butterfly"
    },
    {
        "id": 213,
        "label": [
            {
                "product_id": 37925,
                "product_name": "amada vessel sink single hole bathroom faucet"
            },
            {
                "product_id": 22184,
                "product_name": "purist deck mount kitchen sink faucet"
            },
            {
                "product_id": 26571,
                "product_name": "arbor pull down single handle kitchen faucet with duralock and reflex"
            },
            {
                "product_id": 26573,
                "product_name": "arbor touchless single handle kitchen faucet"
            },
            {
                "product_id": 23124,
                "product_name": "victorian high arch double handle kitchen faucet with side spray"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 23324,
                "product_name": "tone touchless pull-down single-handle kitchen sink faucet"
            },
            {
                "product_id": 22255,
                "product_name": "elate single-handle bathroom sink faucet"
            },
            {
                "product_id": 23295,
                "product_name": "graze touchless pull-down kitchen sink faucet with three-function sprayhead"
            },
            {
                "product_id": 3054,
                "product_name": "kitchen faucet with pull down sprayer single handle kitchen sink faucets solid brass taps"
            },
            {
                "product_id": 38780,
                "product_name": "single handle vessel sink bathroom faucet"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 13623,
                "product_name": "2 person traditional steam sauna"
            },
            {
                "product_id": 21866,
                "product_name": "90 '' 4-burner propane/natural gas"
            },
            {
                "product_id": 15648,
                "product_name": "amilyah single picture frame"
            },
            {
                "product_id": 12440,
                "product_name": "80 can freestanding beverage refrigerator"
            },
            {
                "product_id": 15640,
                "product_name": "fixturedisplays single picture frame"
            }
        ],
        "garOutput": [
            {
                "product_id": 25286,
                "product_name": "cubi 1 - piece self-watering plastic pot planter"
            },
            {
                "product_id": 22695,
                "product_name": "oletto pull down single handle kitchen faucet with quickdock"
            },
            {
                "product_id": 20012,
                "product_name": "instant hot & cold water dispenser"
            },
            {
                "product_id": 21303,
                "product_name": "mason jar foaming soap dispenser"
            },
            {
                "product_id": 37028,
                "product_name": "anran ombre black/gray area rug"
            }
        ],
        "query": "kraus kitchen sink faucet oletto kpf 2820 sfs"
    },
    {
        "id": 74,
        "label": [
            {
                "product_id": 41537,
                "product_name": "serta perfect sleeper 15 '' plush pillow top hybrid mattress"
            },
            {
                "product_id": 41573,
                "product_name": "serta icomfort 11 '' firm gel memory foam mattress and adjustable base set"
            },
            {
                "product_id": 41532,
                "product_name": "serta perfect sleeper 12 '' plush pillow top hybrid mattress and box spring set"
            },
            {
                "product_id": 11579,
                "product_name": "sealy response performance 12 '' innerspring mattress"
            },
            {
                "product_id": 41574,
                "product_name": "serta icomfort 14 '' plush gel memory foam mattress and adjustable base set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 41580,
                "product_name": "serta perfect sleeper kleinmon 10 '' firm innerspring mattress and box spring set"
            },
            {
                "product_id": 41585,
                "product_name": "serta icomfort 200 13 '' plush hybrid mattress"
            },
            {
                "product_id": 34845,
                "product_name": "beautyrest platinum 16 '' firm pillow top hybrid mattress and box spring set"
            },
            {
                "product_id": 41598,
                "product_name": "serta icomfort 3000 15 '' plush hybrid mattress and box spring set"
            },
            {
                "product_id": 18166,
                "product_name": "serta suite dreams 12 '' plush innerspring mattress"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 14501,
                "product_name": "26 '' wide side chair"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 35090,
                "product_name": "coast to coast sepia ii - wrapped canvas graphic art print on canvas"
            },
            {
                "product_id": 35089,
                "product_name": "coast to coast sepia i - wrapped canvas graphic art print on canvas"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            }
        ],
        "garOutput": [
            {
                "product_id": 42895,
                "product_name": "hybrid spring 10 '' medium pillow top mattress"
            },
            {
                "product_id": 41558,
                "product_name": "serta perfect sleeper 14 '' firm hybrid mattress and box spring set"
            },
            {
                "product_id": 41559,
                "product_name": "serta perfect sleeper standale ii 15 '' firm pillow top hybrid mattress and box spring set"
            },
            {
                "product_id": 41560,
                "product_name": "serta standale perfect sleeper 14 '' plush hybrid mattress and box spring set"
            },
            {
                "product_id": 41557,
                "product_name": "serta perfect sleeper 14 '' ultra plush hybrid mattress"
            }
        ],
        "query": "serta kleinmon"
    },
    {
        "id": 302,
        "label": [
            {
                "product_id": 23457,
                "product_name": "bamboo charcoal and aloe 2 '' memory foam mattress topper"
            },
            {
                "product_id": 7033,
                "product_name": "2 '' memory foam mattress topper"
            },
            {
                "product_id": 39265,
                "product_name": "wayfair sleep 6 '' medium gel memory foam mattress"
            },
            {
                "product_id": 1987,
                "product_name": "14 '' medium memory foam mattress"
            },
            {
                "product_id": 2004,
                "product_name": "wayfair sleep\u2122 6 '' medium memory foam mattress"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 36900,
                "product_name": "beatriz queen 12 '' medium memory foam mattress"
            },
            {
                "product_id": 7048,
                "product_name": "wayfair sleep 4 '' memory foam mattress topper"
            },
            {
                "product_id": 19993,
                "product_name": "imaginarium 12 '' medium memory foam mattress"
            },
            {
                "product_id": 3215,
                "product_name": "aarzo 2 '' memory foam mattress topper"
            },
            {
                "product_id": 23485,
                "product_name": "3 '' lavender and aloe infused memory foam mattress topper"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 27333,
                "product_name": "berengere 4 '' cotton futon mattress"
            },
            {
                "product_id": 27336,
                "product_name": "vintage floral japanese 4 '' cotton futon mattress"
            }
        ],
        "garOutput": [
            {
                "product_id": 35646,
                "product_name": "jaxson hypoallergenic zippered mattress protector"
            },
            {
                "product_id": 30517,
                "product_name": "kronqui bed and control hypoallergenic zippered mattress protector"
            },
            {
                "product_id": 2010,
                "product_name": "bed risers kit"
            },
            {
                "product_id": 529,
                "product_name": "tanvir square pillow cover & insert"
            },
            {
                "product_id": 530,
                "product_name": "henri square pillow cover & insert"
            }
        ],
        "query": "mattress foam topper queen"
    },
    {
        "id": 416,
        "label": [
            {
                "product_id": 7476,
                "product_name": "jaxom block coffee table with storage"
            },
            {
                "product_id": 28761,
                "product_name": "hytop floor shelf coffee table with storage"
            },
            {
                "product_id": 39709,
                "product_name": "akrati coffee table"
            },
            {
                "product_id": 4665,
                "product_name": "araceli coffee table"
            },
            {
                "product_id": 32617,
                "product_name": "paradise retreat coffee table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 34096,
                "product_name": "daeschle coffee table"
            },
            {
                "product_id": 13735,
                "product_name": "matthieu coffee table"
            },
            {
                "product_id": 37412,
                "product_name": "henkil solid wood coffee table"
            },
            {
                "product_id": 1872,
                "product_name": "denning coffee table with storage"
            },
            {
                "product_id": 2591,
                "product_name": "carlton 4 piece coffee table set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 13956,
                "product_name": "deauville coffee table"
            },
            {
                "product_id": 13955,
                "product_name": "erachidia coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 38132,
                "product_name": "geometric teal indoor / outdoor area rug"
            },
            {
                "product_id": 32380,
                "product_name": "deep pull-out 18 '' w x 7 '' h drawer"
            },
            {
                "product_id": 32381,
                "product_name": "deep pull-out 18 '' w x 11 '' h drawer"
            },
            {
                "product_id": 32383,
                "product_name": "pull-out side mount belt rack"
            }
        ],
        "query": "wayfair coffee table"
    },
    {
        "id": 235,
        "label": [
            {
                "product_id": 9758,
                "product_name": "viola 2 gallon waste basket"
            },
            {
                "product_id": 19226,
                "product_name": "wicker 3 gallon waste basket"
            },
            {
                "product_id": 26299,
                "product_name": "fornah 8 piece bathroom accessory set"
            },
            {
                "product_id": 24791,
                "product_name": "lavonia 4 piece bathroom accessory set"
            },
            {
                "product_id": 984,
                "product_name": "jovanny 0.5 gallon waste basket"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 19229,
                "product_name": "dobbs 2 gallon waste basket"
            },
            {
                "product_id": 37621,
                "product_name": "woodrow 2 gallon open waste basket"
            },
            {
                "product_id": 6823,
                "product_name": "janette 5 piece bathroom accessory set"
            },
            {
                "product_id": 26287,
                "product_name": "majestic hammered decorative high-quality stainless steel 2 gallon waste basket"
            },
            {
                "product_id": 9776,
                "product_name": "3.2 gallon waste basket"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 36032,
                "product_name": "deeksha storage cabinet"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 35693,
                "product_name": "anello 20 '' w closet system reach-in sets"
            },
            {
                "product_id": 5959,
                "product_name": "marple 42 '' wide 2 drawer server"
            },
            {
                "product_id": 19717,
                "product_name": "commercial single hole bathroom faucet with drain assembly"
            }
        ],
        "garOutput": [
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 27495,
                "product_name": "1-up hard cover pet brag album"
            },
            {
                "product_id": 11055,
                "product_name": "harcrest writing desk"
            },
            {
                "product_id": 18943,
                "product_name": "sink leg"
            },
            {
                "product_id": 32495,
                "product_name": "dederang 2 '' bookshelf"
            }
        ],
        "query": "bathroom wastebasket"
    },
    {
        "id": 119,
        "label": [
            {
                "product_id": 13572,
                "product_name": "bolognese welcome non-slip outdoor door mat"
            },
            {
                "product_id": 4456,
                "product_name": "hurlock welcome non-slip outdoor door mat"
            },
            {
                "product_id": 16098,
                "product_name": "connally welcome poppy flowers natural coir 30 in . x 18 in . non-slip outdoor door mat"
            },
            {
                "product_id": 36654,
                "product_name": "asian zen dragon garden welcome statue"
            },
            {
                "product_id": 36294,
                "product_name": "stenger welcome non-slip outdoor door mat"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 812,
                "product_name": "fairview rectangle welcome flower painted vinyl back painting 29 in . x 18 in . non-slip outdoor door mat"
            },
            {
                "product_id": 19931,
                "product_name": "brayden street sign childrens name room sign indoor outdoor 18 inch wide"
            },
            {
                "product_id": 19345,
                "product_name": "refined wine bar custom bar sign wall d\u00e9cor"
            },
            {
                "product_id": 19939,
                "product_name": "william street sign childrens name room sign indoor outdoor 18 inch wide"
            },
            {
                "product_id": 11480,
                "product_name": "gemini zodiac sign lumbar pillow"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 30356,
                "product_name": "saxonburg farm house welcome sign"
            },
            {
                "product_id": 30359,
                "product_name": "caskey family welcome sign"
            },
            {
                "product_id": 30358,
                "product_name": "saylorville home sweet home welcome sign"
            },
            {
                "product_id": 30354,
                "product_name": "savageville city life welcome sign"
            },
            {
                "product_id": 30353,
                "product_name": "saunemin town home welcome sign"
            }
        ],
        "garOutput": [
            {
                "product_id": 17466,
                "product_name": "vancleave metal reversible welcome porch sign"
            },
            {
                "product_id": 26235,
                "product_name": "targhee pig calendar figurine"
            },
            {
                "product_id": 30083,
                "product_name": "home sweet home tops wall d\u00e9cor"
            },
            {
                "product_id": 30581,
                "product_name": "cardona 5 piece corner shelf"
            },
            {
                "product_id": 20814,
                "product_name": "merry christmas camper 2-sided burlap 18 x 13 in . flag set"
            }
        ],
        "query": "welcome sign"
    },
    {
        "id": 275,
        "label": [
            {
                "product_id": 31065,
                "product_name": "annatone 36 '' wide tufted square cocktail ottoman with storage"
            },
            {
                "product_id": 40163,
                "product_name": "garonzik faux leather round storage ottoman"
            },
            {
                "product_id": 2420,
                "product_name": "chelbi 24 '' tufted rectangle standard ottoman"
            },
            {
                "product_id": 19646,
                "product_name": "br\u00fcnhild 32 '' rectangle standard ottoman"
            },
            {
                "product_id": 27709,
                "product_name": "kriskros 16 '' square standard ottoman"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 20675,
                "product_name": "hoppe 43 '' tufted rectangle standard ottoman"
            },
            {
                "product_id": 4979,
                "product_name": "gordin 36 '' square standard ottoman"
            },
            {
                "product_id": 5040,
                "product_name": "olmitz 24 '' rectangle standard ottoman"
            },
            {
                "product_id": 18193,
                "product_name": "adilet 16 '' tufted standard ottoman"
            },
            {
                "product_id": 34216,
                "product_name": "hojanovice 18 '' wide square footstool ottoman"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 39991,
                "product_name": "bathroom rain volume shower system rough-in valve"
            },
            {
                "product_id": 1215,
                "product_name": "incept 2 door storage cabinet"
            },
            {
                "product_id": 19643,
                "product_name": "rectangular console table"
            },
            {
                "product_id": 34834,
                "product_name": "ambrose console table"
            },
            {
                "product_id": 30594,
                "product_name": "aguie 43.31 '' steel bed frame"
            }
        ],
        "garOutput": [
            {
                "product_id": 4898,
                "product_name": "hepatique upholstered tufted storage ottoman"
            },
            {
                "product_id": 1875,
                "product_name": "russ 103.5 '' wide linen sectional with ottoman"
            },
            {
                "product_id": 33664,
                "product_name": "iaconetti storage cabinet"
            },
            {
                "product_id": 33665,
                "product_name": "delasandro metal storage cabinet"
            },
            {
                "product_id": 1614,
                "product_name": "bulwell 23.5 '' w x 31.5 '' h x 12 '' d free-standing bathroom cabinet"
            }
        ],
        "query": "hinged square ottoman"
    },
    {
        "id": 367,
        "label": [
            {
                "product_id": 35597,
                "product_name": "merlyn swivel patio chair with cushions"
            },
            {
                "product_id": 21860,
                "product_name": "kids outdoor adirondack chair"
            },
            {
                "product_id": 8137,
                "product_name": "plastic chair for dining room , outdoor chair plastic chair"
            },
            {
                "product_id": 30628,
                "product_name": "bondi patio chair with cushions"
            },
            {
                "product_id": 7933,
                "product_name": "avo outdoor patio chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 30899,
                "product_name": "sky lounge patio chair"
            },
            {
                "product_id": 29322,
                "product_name": "pergamon outdoor lounge chair with cushion"
            },
            {
                "product_id": 35574,
                "product_name": "tegan swivel patio chair with cushions"
            },
            {
                "product_id": 37270,
                "product_name": "ossu outdoor rocking chair with cushions"
            },
            {
                "product_id": 29678,
                "product_name": "deluxe swivel patio chair with cushions"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 30158,
                "product_name": "hershberger buffet table"
            },
            {
                "product_id": 32281,
                "product_name": "metal slat back stacking side chair"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            }
        ],
        "garOutput": [
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 39644,
                "product_name": "evas hall tree"
            },
            {
                "product_id": 17776,
                "product_name": "crellin solid wood coffee table"
            },
            {
                "product_id": 27495,
                "product_name": "1-up hard cover pet brag album"
            },
            {
                "product_id": 29670,
                "product_name": "enameled 3 piece coffee , tea , & sugar jar set"
            }
        ],
        "query": "wooden chair outdoor"
    },
    {
        "id": 452,
        "label": [
            {
                "product_id": 40109,
                "product_name": "mikayla metal scroll daybed"
            },
            {
                "product_id": 19825,
                "product_name": "geralynn daybed"
            },
            {
                "product_id": 2515,
                "product_name": "denine daybed"
            },
            {
                "product_id": 15553,
                "product_name": "wicker daybed with mattress"
            },
            {
                "product_id": 19826,
                "product_name": "redus daybed with trundle"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 842,
                "product_name": "pattonsburg daybed with trundle"
            },
            {
                "product_id": 21897,
                "product_name": "eyad daybed"
            },
            {
                "product_id": 25514,
                "product_name": "vintage daybed with trundle"
            },
            {
                "product_id": 19827,
                "product_name": "redus daybed"
            },
            {
                "product_id": 6856,
                "product_name": "hollandsworth queen day bed with trundle"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 13623,
                "product_name": "2 person traditional steam sauna"
            },
            {
                "product_id": 10075,
                "product_name": "hajrah full over full bunk bed with trundle"
            },
            {
                "product_id": 26396,
                "product_name": "bahir anti-fatigue mat"
            },
            {
                "product_id": 21866,
                "product_name": "90 '' 4-burner propane/natural gas"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 34346,
                "product_name": "novotny twin metal daybed"
            },
            {
                "product_id": 2082,
                "product_name": "38.2 '' armless sofa bed"
            },
            {
                "product_id": 8720,
                "product_name": "latitude run\u00ae floor couch and sofa fabric folding chaise lounge ( navy blue )"
            },
            {
                "product_id": 1611,
                "product_name": "cruze 33 '' h x 10.5 '' w step bookcase"
            },
            {
                "product_id": 25845,
                "product_name": "liberty 18 '' wide square pouf ottoman"
            }
        ],
        "query": "day bed indian"
    },
    {
        "id": 346,
        "label": [
            {
                "product_id": 2685,
                "product_name": "mischa tv tray table with stand"
            },
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            },
            {
                "product_id": 31538,
                "product_name": "milton 2 piece folding tv tray table set"
            },
            {
                "product_id": 6056,
                "product_name": "millwood pines wood serving tray with handles , rustic nesting serving trays set of 3"
            },
            {
                "product_id": 24314,
                "product_name": "2 piece solid sliver metal accent tray set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 22513,
                "product_name": "alvis tray top nesting tables"
            },
            {
                "product_id": 6437,
                "product_name": "elisha gold leaf serving tray"
            },
            {
                "product_id": 36469,
                "product_name": "millete 3 piece tray set"
            },
            {
                "product_id": 17070,
                "product_name": "perma-nest plant tray"
            },
            {
                "product_id": 3779,
                "product_name": "metal 2 piece nesting serving tray set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 41432,
                "product_name": "happiness wall mounted calendar board"
            },
            {
                "product_id": 18943,
                "product_name": "sink leg"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 7525,
                "product_name": "nesting end table"
            },
            {
                "product_id": 21832,
                "product_name": "bahena 34.3 '' h x 27.9 '' w x 13.6 '' d foldable storage shelves"
            },
            {
                "product_id": 30601,
                "product_name": "geesche floating desk with hutch"
            },
            {
                "product_id": 32926,
                "product_name": "sundale monitor stand"
            },
            {
                "product_id": 41522,
                "product_name": "fromm wood table"
            }
        ],
        "query": "nesting tray set"
    },
    {
        "id": 249,
        "label": [
            {
                "product_id": 37832,
                "product_name": "marseille 3 piece bistro dining set"
            },
            {
                "product_id": 28407,
                "product_name": "rixensart outdoor 3 piece bistro set"
            },
            {
                "product_id": 11103,
                "product_name": "3 pieces patio pe rattan conversation chair set , outdoor furniture set with water-proof cushion & coffee table for garden , backyard and porch"
            },
            {
                "product_id": 2101,
                "product_name": "dining room table set \u2013 7-piece dining table with dining room chairs \u2013 elegant glass top and pu leather , metal legs \u2013 stable dining table set for 6 \u2013 modern design suitable for any home"
            },
            {
                "product_id": 12379,
                "product_name": "eleanore decor 53 '' 8 - player poker table with chairs"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 1360,
                "product_name": "pleasant view 3 - piece counter height rubberwood solid wood dining set"
            },
            {
                "product_id": 34766,
                "product_name": "kingstown sienna bistro dining table"
            },
            {
                "product_id": 7434,
                "product_name": "fritts 5 piece bistro set"
            },
            {
                "product_id": 19750,
                "product_name": "a-37 coffee table in , matching coffee table"
            },
            {
                "product_id": 22560,
                "product_name": "azelle 6 piece bistro set with umbrella"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 1029,
                "product_name": "candelario bar with wine storage"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            }
        ],
        "garOutput": [
            {
                "product_id": 39910,
                "product_name": "carin coffee table"
            },
            {
                "product_id": 3705,
                "product_name": "derbyshire bar & counter stool"
            },
            {
                "product_id": 16539,
                "product_name": "kleinschmidt 3 piece coffee table set"
            },
            {
                "product_id": 1866,
                "product_name": "heurich 59 '' wide buffet table"
            },
            {
                "product_id": 41309,
                "product_name": "rea solid wood drum coffee table"
            }
        ],
        "query": "bistro table and chairs"
    },
    {
        "id": 329,
        "label": [
            {
                "product_id": 29070,
                "product_name": "bannruod metal tension and inner curtain tension rod"
            },
            {
                "product_id": 32291,
                "product_name": "buderus single curtain rod"
            },
            {
                "product_id": 36972,
                "product_name": "dinkins single curtain rod"
            },
            {
                "product_id": 32301,
                "product_name": "tavernier decorative spring tension single curtain rod"
            },
            {
                "product_id": 481,
                "product_name": "silliman single curtain rod"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 23911,
                "product_name": "ayub drapery double curtain rod"
            },
            {
                "product_id": 37224,
                "product_name": "coretto adjustable 0.5 '' tension rod"
            },
            {
                "product_id": 34972,
                "product_name": "wayfair basics solid max blackout thermal rod pocket single curtain panel"
            },
            {
                "product_id": 37218,
                "product_name": "chroma adjustable 0.875 '' tension rod"
            },
            {
                "product_id": 32297,
                "product_name": "matias single curtain rod"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 20718,
                "product_name": "abramowski monitor stand"
            },
            {
                "product_id": 41246,
                "product_name": "rae dunn stem print 3.5 qt . flour canister"
            },
            {
                "product_id": 12320,
                "product_name": "soft square 3 3/4 '' center to center bar pull"
            },
            {
                "product_id": 16048,
                "product_name": "flush mount lighting etta 1-light 11 '' semi flush mount"
            }
        ],
        "garOutput": [
            {
                "product_id": 4704,
                "product_name": "fastener clip connector"
            },
            {
                "product_id": 13818,
                "product_name": "4.5 '' w - 5 '' w closet rod"
            },
            {
                "product_id": 32932,
                "product_name": "elevated rolling laundry & utility cart"
            },
            {
                "product_id": 5932,
                "product_name": "dametta macaron wooden bead curtain tieback"
            },
            {
                "product_id": 37869,
                "product_name": "mobile printer stand with casters"
            }
        ],
        "query": "wayfair tension rod"
    },
    {
        "id": 376,
        "label": [
            {
                "product_id": 18088,
                "product_name": "rhapsody coffee table"
            },
            {
                "product_id": 6321,
                "product_name": "presley glass top coffee table with drawer"
            },
            {
                "product_id": 36110,
                "product_name": "kirsty coffee table with storage"
            },
            {
                "product_id": 13649,
                "product_name": "cullompt fabric coffee table"
            },
            {
                "product_id": 12219,
                "product_name": "mcsweeney coffee table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 10729,
                "product_name": "voorhees 4 piece coffee table set"
            },
            {
                "product_id": 7182,
                "product_name": "noriega coffee table with storage"
            },
            {
                "product_id": 7475,
                "product_name": "anaejah block coffee table with storage"
            },
            {
                "product_id": 17632,
                "product_name": "alameda solid wood coffee table with storage"
            },
            {
                "product_id": 16592,
                "product_name": "huseman 3 piece coffee table set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 13956,
                "product_name": "deauville coffee table"
            },
            {
                "product_id": 13955,
                "product_name": "erachidia coffee table"
            },
            {
                "product_id": 1029,
                "product_name": "candelario bar with wine storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 32286,
                "product_name": "orchid arrangement in 17 \u201d metal gold planter"
            },
            {
                "product_id": 33818,
                "product_name": "carrara 1 '' x 3 '' marble mosaic tile"
            },
            {
                "product_id": 30393,
                "product_name": "nautical boat ship steering wheel wall d\u00e9cor"
            },
            {
                "product_id": 4455,
                "product_name": "maura trio pineapple rust welcome outdoor door mat"
            },
            {
                "product_id": 1947,
                "product_name": "pencil green fir artificial christmas tree with 350 clear/white lights"
            }
        ],
        "query": "island estate coffee table"
    },
    {
        "id": 375,
        "label": [
            {
                "product_id": 2881,
                "product_name": "salina l-shape executive desk and chair set"
            },
            {
                "product_id": 9617,
                "product_name": "kids chair desk with storage bin"
            },
            {
                "product_id": 30420,
                "product_name": "alcaraz task chair"
            },
            {
                "product_id": 18299,
                "product_name": "bembry task chair"
            },
            {
                "product_id": 2321,
                "product_name": "schmidt task chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 22147,
                "product_name": "kiro desk and chair set"
            },
            {
                "product_id": 25309,
                "product_name": "harland task chair"
            },
            {
                "product_id": 29650,
                "product_name": "watervale task chair"
            },
            {
                "product_id": 29873,
                "product_name": "blithedale task chair"
            },
            {
                "product_id": 1147,
                "product_name": "medley executive desk with chair"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 38673,
                "product_name": "abdulsamet coffee table"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 32281,
                "product_name": "metal slat back stacking side chair"
            },
            {
                "product_id": 25143,
                "product_name": "tonsey office chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 29214,
                "product_name": "high-level office gaming chair"
            },
            {
                "product_id": 11181,
                "product_name": "henrika upholstered arm chair dining chair in driftwood"
            },
            {
                "product_id": 21713,
                "product_name": "voluntown velvet side chair in blue"
            },
            {
                "product_id": 36890,
                "product_name": "whipholt 2 piece 27.5 '' patio bar stool"
            },
            {
                "product_id": 38487,
                "product_name": "baileyville lift top abstract coffee table"
            }
        ],
        "query": "pastel pink desk chair"
    },
    {
        "id": 418,
        "label": [
            {
                "product_id": 18620,
                "product_name": "modern l-shaped desk"
            },
            {
                "product_id": 4453,
                "product_name": "sadie desk with hutch"
            },
            {
                "product_id": 16600,
                "product_name": "emiliano desk"
            },
            {
                "product_id": 28056,
                "product_name": "ezoza l-shape desk"
            },
            {
                "product_id": 18040,
                "product_name": "palisade writing desk"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 4991,
                "product_name": "lystra l-shape executive desk"
            },
            {
                "product_id": 27331,
                "product_name": "friedman bunk bed with bookcase and desk"
            },
            {
                "product_id": 25726,
                "product_name": "orion 28.3 '' console table"
            },
            {
                "product_id": 30098,
                "product_name": "boatright writing desk"
            },
            {
                "product_id": 15450,
                "product_name": "home office writing desk"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 27793,
                "product_name": "adjustable height standing desk"
            },
            {
                "product_id": 30158,
                "product_name": "hershberger buffet table"
            }
        ],
        "garOutput": [
            {
                "product_id": 42713,
                "product_name": "giullia 2 piece floating shelf"
            },
            {
                "product_id": 20348,
                "product_name": "aguiar stacking plastic 12.05 '' storage drawer"
            },
            {
                "product_id": 20231,
                "product_name": "30 '' w x 20 '' l farmhouse kitchen sink with sink grid and basket strainer"
            },
            {
                "product_id": 20230,
                "product_name": "33 '' l x 21 '' w farmhouse kitchen sink with sink grid and basket strainer"
            },
            {
                "product_id": 20229,
                "product_name": "30 '' l x 21 '' w farmhouse kitchen sink with sink grid and basket strainer"
            }
        ],
        "query": "writing desk"
    },
    {
        "id": 36,
        "label": [
            {
                "product_id": 31724,
                "product_name": "bamboo hamper"
            },
            {
                "product_id": 32163,
                "product_name": "edward bamboo cabinet laundry hamper"
            },
            {
                "product_id": 8415,
                "product_name": "rectangle bamboo hamper laundry basket"
            },
            {
                "product_id": 38020,
                "product_name": "sedgwick lattice rectangle laundry hamper set"
            },
            {
                "product_id": 15975,
                "product_name": "bamboo rectangular hamper"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 20285,
                "product_name": "collapsible ultra slim utility laundry basket set"
            },
            {
                "product_id": 27772,
                "product_name": "laundry sorter basket on wheels"
            },
            {
                "product_id": 25173,
                "product_name": "double laundry hamper with lid and wheels"
            },
            {
                "product_id": 24492,
                "product_name": "rolling wicker laundry basket"
            },
            {
                "product_id": 39167,
                "product_name": "wayfair basics rolling laundry hamper"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 30594,
                "product_name": "aguie 43.31 '' steel bed frame"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 21711,
                "product_name": "3-tier utility rolling cart"
            },
            {
                "product_id": 37189,
                "product_name": "metal bed with wood decoration \uff08twin size\uff09"
            },
            {
                "product_id": 2233,
                "product_name": "tv stand for tvs up to 50 ''"
            }
        ],
        "garOutput": [
            {
                "product_id": 29714,
                "product_name": "garden plastic/metal storage shed"
            },
            {
                "product_id": 20625,
                "product_name": "rolling storage cart"
            },
            {
                "product_id": 22626,
                "product_name": "66 '' h x 27 '' w x 15 '' d tall utility storage cabinet"
            },
            {
                "product_id": 36873,
                "product_name": "spengler 17.72 '' w closet organizer"
            },
            {
                "product_id": 22011,
                "product_name": "darnetha 70.5 '' faux leather pillow top arm convertible sleeper sofa bed"
            }
        ],
        "query": "laundry basket with wheels"
    },
    {
        "id": 199,
        "label": [
            {
                "product_id": 13365,
                "product_name": "inviting vigorous square throw pillow"
            },
            {
                "product_id": 35921,
                "product_name": "xanti graphic throw pillow"
            },
            {
                "product_id": 40076,
                "product_name": "hertford cotton throw pillow in , no fill"
            },
            {
                "product_id": 35255,
                "product_name": "whipton mongolian faux lumbar pillow"
            },
            {
                "product_id": 18288,
                "product_name": "kinser throw pillow"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 18101,
                "product_name": "bracken guppy outdoor throw pillow"
            },
            {
                "product_id": 11213,
                "product_name": "zen velvet throw pillow"
            },
            {
                "product_id": 9532,
                "product_name": "tamura home throw pillow"
            },
            {
                "product_id": 21044,
                "product_name": "marble throw pillow"
            },
            {
                "product_id": 21747,
                "product_name": "thijmen hydrangea bunch floral throw pillow"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 25557,
                "product_name": "connolly tufted upholstered low profile standard bed"
            },
            {
                "product_id": 27333,
                "product_name": "berengere 4 '' cotton futon mattress"
            },
            {
                "product_id": 27336,
                "product_name": "vintage floral japanese 4 '' cotton futon mattress"
            },
            {
                "product_id": 27335,
                "product_name": "vintage floral japanese 4 '' cotton futon mattress"
            },
            {
                "product_id": 22761,
                "product_name": "paisley kids sleeper"
            }
        ],
        "garOutput": [
            {
                "product_id": 32598,
                "product_name": "sunflower bouquet sunflowers stem"
            },
            {
                "product_id": 21974,
                "product_name": "dryer 9 '' clip on table lamp"
            },
            {
                "product_id": 27495,
                "product_name": "1-up hard cover pet brag album"
            },
            {
                "product_id": 27810,
                "product_name": "sayers damask 8 piece comforter set"
            },
            {
                "product_id": 29241,
                "product_name": "cleotha twin 75 '' wide tufted back convertible sofa"
            }
        ],
        "query": "blue faux throw with pillows"
    },
    {
        "id": 139,
        "label": [
            {
                "product_id": 21459,
                "product_name": "ahuja rectangular throw pillow"
            },
            {
                "product_id": 39215,
                "product_name": "wayfair basics\u00ae square throw pillow"
            },
            {
                "product_id": 9559,
                "product_name": "dulin scorpio zodiac sign throw pillow"
            },
            {
                "product_id": 4148,
                "product_name": "ilene square cotton throw pillow"
            },
            {
                "product_id": 16889,
                "product_name": "pittenger soft luxury square throw pillow"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 25610,
                "product_name": "cotton chevron throw pillow"
            },
            {
                "product_id": 1738,
                "product_name": "weymouth indoor/outdoor throw pillow"
            },
            {
                "product_id": 38030,
                "product_name": "fairley cotton 4 piece throw pillow cover set"
            },
            {
                "product_id": 35921,
                "product_name": "xanti graphic throw pillow"
            },
            {
                "product_id": 9061,
                "product_name": "square throw pillow"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 890,
                "product_name": "sapphira reversible quilt set"
            },
            {
                "product_id": 28774,
                "product_name": "muslin baby sleeping bag"
            },
            {
                "product_id": 27333,
                "product_name": "berengere 4 '' cotton futon mattress"
            },
            {
                "product_id": 27336,
                "product_name": "vintage floral japanese 4 '' cotton futon mattress"
            },
            {
                "product_id": 27335,
                "product_name": "vintage floral japanese 4 '' cotton futon mattress"
            }
        ],
        "garOutput": [
            {
                "product_id": 5983,
                "product_name": "tibetan lamb throw pillow"
            },
            {
                "product_id": 2348,
                "product_name": "verden callan reversible comforter set"
            },
            {
                "product_id": 41818,
                "product_name": "southwestern dark blue area rug"
            },
            {
                "product_id": 41817,
                "product_name": "southwestern blush area rug"
            },
            {
                "product_id": 18687,
                "product_name": "bromford border 30 in . x 18 in . non-slip outdoor door mat"
            }
        ],
        "query": "auburn throw pillows"
    },
    {
        "id": 357,
        "label": [
            {
                "product_id": 28785,
                "product_name": "3-panel free standing pet gate"
            },
            {
                "product_id": 2251,
                "product_name": "diana steel pressure mounted pet gate"
            },
            {
                "product_id": 9972,
                "product_name": "free standing pet gate"
            },
            {
                "product_id": 41137,
                "product_name": "3 panel 36 inch freestanding foldable pet gate , wooden free standing pet gate for dogs , black"
            },
            {
                "product_id": 14123,
                "product_name": "playpen yard exercise barrier free standing pet gate"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 28784,
                "product_name": "4-panel scalloped free standing pet gate"
            },
            {
                "product_id": 20375,
                "product_name": "2 panel configurable free standing pet gate"
            },
            {
                "product_id": 23210,
                "product_name": "mesh free standing pet gate"
            },
            {
                "product_id": 41674,
                "product_name": "dewitt free standing pet gate"
            },
            {
                "product_id": 41134,
                "product_name": "racine free standing pet gate"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8754,
                "product_name": "bamboo plant flower stand rack 2 tier corner tall flower bench holder indoor"
            },
            {
                "product_id": 40011,
                "product_name": "mitton pineapple statue"
            },
            {
                "product_id": 8752,
                "product_name": "3 tier corner plant stand foldable nesting flower pot holder elegant metal plant rack for indoor outdoor garden patio3"
            },
            {
                "product_id": 42966,
                "product_name": "mershon 2-light outdoor bulkhead light"
            },
            {
                "product_id": 8740,
                "product_name": "alessandro rectangular multi-tiered plant stand"
            }
        ],
        "garOutput": [
            {
                "product_id": 41675,
                "product_name": "dickson step over free standing pet gate"
            },
            {
                "product_id": 30657,
                "product_name": "prairie view industries threshold ramp"
            },
            {
                "product_id": 27455,
                "product_name": "pet bowl"
            },
            {
                "product_id": 15622,
                "product_name": "personalized distressed look bar and grill metal wall d\u00e9cor"
            },
            {
                "product_id": 15623,
                "product_name": "personalized welcome to the camper metal wall d\u00e9cor"
            }
        ],
        "query": "tall free standing pet gate"
    },
    {
        "id": 229,
        "label": [
            {
                "product_id": 17360,
                "product_name": "axor starck classic single hole tall vessel sink bathroom faucet with drain assembly"
            },
            {
                "product_id": 21515,
                "product_name": "restoration mini widespread bathroom sink faucet with brass pop-up"
            },
            {
                "product_id": 37673,
                "product_name": "commercial single hole bathroom faucet"
            },
            {
                "product_id": 19713,
                "product_name": "waterfall single hole bathroom faucet"
            },
            {
                "product_id": 2334,
                "product_name": "dfi single hole bathroom faucet"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 37687,
                "product_name": "widespread bathroom deck mounted faucet with optional drain assembly"
            },
            {
                "product_id": 26576,
                "product_name": "wynford moentrol faucet trim with metal handle"
            },
            {
                "product_id": 21829,
                "product_name": "circular widespread bathroom faucet with drain assembly"
            },
            {
                "product_id": 23133,
                "product_name": "nustudio single handle faucet"
            },
            {
                "product_id": 33383,
                "product_name": "58 '' x 30 '' freestanding soaking fiberglass bathtub with faucet"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 19717,
                "product_name": "commercial single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 19708,
                "product_name": "waterfall single hole bathroom faucet"
            },
            {
                "product_id": 19718,
                "product_name": "commercial vessel sink bathroom faucet with drain assembly"
            },
            {
                "product_id": 24966,
                "product_name": "nita single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 19728,
                "product_name": "lavatory single hole bathroom faucet with drain assembly"
            }
        ],
        "garOutput": [
            {
                "product_id": 17459,
                "product_name": "1 '' square knob multipack"
            },
            {
                "product_id": 36856,
                "product_name": "stainless steel sink frame"
            },
            {
                "product_id": 6428,
                "product_name": "single hole faucet bathroom faucet"
            },
            {
                "product_id": 27258,
                "product_name": "brick random sized 0.6 '' x 1 '' mosaic tile"
            },
            {
                "product_id": 10282,
                "product_name": "faucet handle"
            }
        ],
        "query": "waterfall faucet"
    },
    {
        "id": 278,
        "label": [
            {
                "product_id": 2981,
                "product_name": "23.62 '' iron play top floor bird case with perch"
            },
            {
                "product_id": 38534,
                "product_name": "toys small"
            },
            {
                "product_id": 21009,
                "product_name": "sunflower toy box"
            },
            {
                "product_id": 2252,
                "product_name": "elton parakeet park playground"
            },
            {
                "product_id": 19841,
                "product_name": "warnick 11.8 '' dome top travel bird cage with perch"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 30693,
                "product_name": "round roof bird starter kit"
            },
            {
                "product_id": 19840,
                "product_name": "brockett 17 '' flat top travel bird cage with perch"
            },
            {
                "product_id": 648,
                "product_name": "the \u201c o '' parrot play stand"
            },
            {
                "product_id": 35675,
                "product_name": "olivia play top parrot bird cage"
            },
            {
                "product_id": 1583,
                "product_name": "lulette decorative tin toy pecking chick"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 1583,
                "product_name": "lulette decorative tin toy pecking chick"
            },
            {
                "product_id": 29736,
                "product_name": "dinosaur storage bin"
            },
            {
                "product_id": 1585,
                "product_name": "lulette decorative tin toy duckling"
            },
            {
                "product_id": 1584,
                "product_name": "lulette decorative tin toy bird"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            }
        ],
        "garOutput": [
            {
                "product_id": 21188,
                "product_name": "magic carpet swing set"
            },
            {
                "product_id": 30549,
                "product_name": "slimcat automatic feeder"
            },
            {
                "product_id": 8999,
                "product_name": "4-in-1 child climbing rocking horse straight"
            },
            {
                "product_id": 11551,
                "product_name": "blocksy mini"
            },
            {
                "product_id": 30692,
                "product_name": "bretz round roof bird cage"
            }
        ],
        "query": "parakeet toy"
    },
    {
        "id": 279,
        "label": [
            {
                "product_id": 79,
                "product_name": "bestar versatile 105w queen murphy bed with shelving unit ( 103w ) in deep grey"
            },
            {
                "product_id": 36219,
                "product_name": "aonan tufted upholstered standard bed"
            },
            {
                "product_id": 17735,
                "product_name": "aileen tufted upholstered low profile platform bed"
            },
            {
                "product_id": 39007,
                "product_name": "geffken tufted upholstered platform bed"
            },
            {
                "product_id": 17753,
                "product_name": "atagun queen tufted upholstered low profile four poster bed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 37096,
                "product_name": "eupheemia twin upholstered tufted platform bed"
            },
            {
                "product_id": 20251,
                "product_name": "behudin queen tufted upholstered standard bed"
            },
            {
                "product_id": 24133,
                "product_name": "anneri tufted upholstered low profile platform bed"
            },
            {
                "product_id": 5051,
                "product_name": "brochu tufted upholstered standard bed"
            },
            {
                "product_id": 27660,
                "product_name": "defeis tufted upholstered low profile storage platform bed"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 16348,
                "product_name": "lethe 2 piece crib bedding set"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            }
        ],
        "garOutput": [
            {
                "product_id": 30517,
                "product_name": "kronqui bed and control hypoallergenic zippered mattress protector"
            },
            {
                "product_id": 37517,
                "product_name": "10 \u201d bed leg system"
            },
            {
                "product_id": 37518,
                "product_name": "7 \u201d bed leg system"
            },
            {
                "product_id": 14955,
                "product_name": "space saver tailored 21 '' bed skirt"
            },
            {
                "product_id": 35611,
                "product_name": "outdoor replacement cushion"
            }
        ],
        "query": "marlon tufted queen bed"
    },
    {
        "id": 59,
        "label": [
            {
                "product_id": 26602,
                "product_name": "genta lx shower faucet lever handle"
            },
            {
                "product_id": 32526,
                "product_name": "nantucket single cylinder passage ( hall & closet ) door knob"
            },
            {
                "product_id": 22282,
                "product_name": "purist valve trim with lever handle for volume control valve"
            },
            {
                "product_id": 41646,
                "product_name": "custom broadway hall-closet and bed-bath lever with kinsler trim"
            },
            {
                "product_id": 23267,
                "product_name": "purist widespread faucet with drain assembly low lever handles and low gooseneck spout"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 23254,
                "product_name": "kohler alteo rite-temp valve trim with lever handle"
            },
            {
                "product_id": 10123,
                "product_name": "cassidy standard lever"
            },
            {
                "product_id": 20295,
                "product_name": "callan bennett keyed door lever"
            },
            {
                "product_id": 23261,
                "product_name": "kohler archer rite-temp valve trim with lever handle"
            },
            {
                "product_id": 23258,
                "product_name": "kohler purist rite-temp valve trim with lever handle"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 6053,
                "product_name": "hot water dispenser"
            },
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 20707,
                "product_name": "white/green fir artificial christmas tree"
            },
            {
                "product_id": 19107,
                "product_name": "hotpoint 3.8 cu . ft. top load washer in white"
            },
            {
                "product_id": 20748,
                "product_name": "mid-century 5 piece wooden dining set with 4 padded dining chairs , kitchen table set for small spaces , gray"
            },
            {
                "product_id": 29376,
                "product_name": "sagitta leather upholstered arm chair"
            },
            {
                "product_id": 16139,
                "product_name": "ge smart appliances 33 '' counter depth french door 18.6 cu . ft. energy star refrigerator"
            }
        ],
        "query": "broadway lever"
    },
    {
        "id": 111,
        "label": [
            {
                "product_id": 10193,
                "product_name": "trinsic wall mounted bathroom faucet"
            },
            {
                "product_id": 10174,
                "product_name": "trinsic\u00ae single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 10209,
                "product_name": "handle gasket"
            },
            {
                "product_id": 10207,
                "product_name": "trinsic wall mounted tub spout trim"
            },
            {
                "product_id": 6606,
                "product_name": "trinsic double handle wall mounted tub spout trim"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 10185,
                "product_name": "trinsic\u00ae bathroom contemporary touch soap dispenser"
            },
            {
                "product_id": 12343,
                "product_name": "magellan diverter tub and shower faucet with rough-in valve with metal lever handles and posi-temp"
            },
            {
                "product_id": 26581,
                "product_name": "arris transfer faucet trim with lever handle"
            },
            {
                "product_id": 10118,
                "product_name": "trinsic single hole bathroom faucet"
            },
            {
                "product_id": 26582,
                "product_name": "align faucet trim with lever handle"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 8573,
                "product_name": "14 '' oil powered outdoor lantern"
            },
            {
                "product_id": 347,
                "product_name": "1.93 '' solar powered outdoor floating light"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            }
        ],
        "garOutput": [
            {
                "product_id": 3315,
                "product_name": "rostrum 44 '' solid wood console table"
            },
            {
                "product_id": 3313,
                "product_name": "forest end table"
            },
            {
                "product_id": 3314,
                "product_name": "void 44 '' solid wood console table"
            },
            {
                "product_id": 37208,
                "product_name": "minifile storage cabinet"
            },
            {
                "product_id": 13864,
                "product_name": "thinking outside the box by wilbur pierce - advertisements print"
            }
        ],
        "query": "delta trinsic"
    },
    {
        "id": 242,
        "label": [
            {
                "product_id": 37820,
                "product_name": "sueann 31.9 '' wide tufted full grain leather swivel lounge chair and ottoman"
            },
            {
                "product_id": 31877,
                "product_name": "adelphi 23 '' wide armchair"
            },
            {
                "product_id": 5029,
                "product_name": "nornton 30 '' wide armchair"
            },
            {
                "product_id": 6403,
                "product_name": "ellayne tufted faux leather dining chair ( set of 2 ) , dark brown"
            },
            {
                "product_id": 21446,
                "product_name": "calanthe 34 '' wide genuine leather club chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 18092,
                "product_name": "levinson 34 '' wide genuine leather manual wing chair recliner"
            },
            {
                "product_id": 29546,
                "product_name": "cilley 23 '' wide armchair"
            },
            {
                "product_id": 3091,
                "product_name": "sheffield 27 '' wide tufted top grain leather barrel chair"
            },
            {
                "product_id": 39722,
                "product_name": "alannis 27 '' wide armchair"
            },
            {
                "product_id": 37290,
                "product_name": "dustin 28 '' wide top grain leather club chair"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 3837,
                "product_name": "galaz 28 '' wide polyester wingback chair"
            },
            {
                "product_id": 24934,
                "product_name": "carneys 28 '' wide polyester wingback chair"
            },
            {
                "product_id": 24935,
                "product_name": "carneys 28 '' wide polyester wingback chair"
            },
            {
                "product_id": 3368,
                "product_name": "galloway 28 '' wide polyester wingback chair"
            },
            {
                "product_id": 18460,
                "product_name": "adala executive chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 36890,
                "product_name": "whipholt 2 piece 27.5 '' patio bar stool"
            },
            {
                "product_id": 35611,
                "product_name": "outdoor replacement cushion"
            },
            {
                "product_id": 3472,
                "product_name": "chair pad cushion"
            },
            {
                "product_id": 11189,
                "product_name": "amjed swivel adjustable height bar stool"
            },
            {
                "product_id": 11188,
                "product_name": "amjed swivel adjustable height bar stool"
            }
        ],
        "query": "accent leather chair"
    },
    {
        "id": 384,
        "label": [
            {
                "product_id": 35228,
                "product_name": "heavy duty tablecloth"
            },
            {
                "product_id": 2518,
                "product_name": "ivy bronx indoor outdoor patio round fitted vinyl tablecloth , flannel backing , elastic edge , waterproof wipeable plastic cover , white marble pattern for 5-seat table of 36-42 \u2019 \u2019 diameter"
            },
            {
                "product_id": 24647,
                "product_name": "parthenia hemstitched tablecloth"
            },
            {
                "product_id": 38031,
                "product_name": "dingman patchwork plastic/vinyl tablecloth"
            },
            {
                "product_id": 38029,
                "product_name": "vanhouten check vinyl tablecloth"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 11913,
                "product_name": "ships wheel tablecloth , image of ship is in framing of steering wheel adventure nautical theme artwork , rectangular table cover for dining room kitchen decor , 52 '' x 70 '' , brown blue"
            },
            {
                "product_id": 38033,
                "product_name": "doher vinyl tablecloth"
            },
            {
                "product_id": 11388,
                "product_name": "farrah folding plastic dining table"
            },
            {
                "product_id": 1677,
                "product_name": "everyday tablecloth"
            },
            {
                "product_id": 16662,
                "product_name": "rhiannon dining table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 30158,
                "product_name": "hershberger buffet table"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 38673,
                "product_name": "abdulsamet coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 4846,
                "product_name": "momea indoor/outdoor bench cushion"
            },
            {
                "product_id": 2518,
                "product_name": "ivy bronx indoor outdoor patio round fitted vinyl tablecloth , flannel backing , elastic edge , waterproof wipeable plastic cover , white marble pattern for 5-seat table of 36-42 \u2019 \u2019 diameter"
            },
            {
                "product_id": 35663,
                "product_name": "indoor/outdoor chair pad cushion"
            },
            {
                "product_id": 40122,
                "product_name": "jeremiah contemporary multi-colored area rug"
            },
            {
                "product_id": 1829,
                "product_name": "weather resistant indoor/outdoor bench cushion"
            }
        ],
        "query": "dining table vinyl cloth"
    },
    {
        "id": 6,
        "label": [
            {
                "product_id": 19247,
                "product_name": "clayburgh arm chair in clear"
            },
            {
                "product_id": 29488,
                "product_name": "abel curved arm chair in clear"
            },
            {
                "product_id": 25147,
                "product_name": "coverly king louis back stacking side chair"
            },
            {
                "product_id": 15439,
                "product_name": "fellsburg linen upholstered parsons chair"
            },
            {
                "product_id": 752,
                "product_name": "holleman stacking side chair in clear"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 39562,
                "product_name": "tovin side chair"
            },
            {
                "product_id": 26804,
                "product_name": "eudora stacking side chair in clear"
            },
            {
                "product_id": 33512,
                "product_name": "zeferino side chair in clear"
            },
            {
                "product_id": 28921,
                "product_name": "specter chiavari chair"
            },
            {
                "product_id": 32173,
                "product_name": "falmouth patio chair with cushions"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 25143,
                "product_name": "tonsey office chair"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 34495,
                "product_name": "adriany task chair"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 32281,
                "product_name": "metal slat back stacking side chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 26416,
                "product_name": "menara 8 '' x 9 '' cement patterned wall & floor tile"
            },
            {
                "product_id": 33791,
                "product_name": "florid lantern 6.25 '' x 7.25 '' straight edge ceramic singular subway tile"
            },
            {
                "product_id": 15124,
                "product_name": "riverbank 19.63 '' x 19.63 '' 3d wall panel in white"
            },
            {
                "product_id": 23899,
                "product_name": "ishani ikat throw pillow cover"
            },
            {
                "product_id": 35308,
                "product_name": "atlanta core square telescoping drapery single curtain rod"
            }
        ],
        "query": "acrylic clear chair"
    },
    {
        "id": 353,
        "label": [
            {
                "product_id": 37516,
                "product_name": "white chapel playhouse"
            },
            {
                "product_id": 18941,
                "product_name": "rope bridge play tunnel"
            },
            {
                "product_id": 34146,
                "product_name": "neat & tidy cottage ii\u2122"
            },
            {
                "product_id": 34148,
                "product_name": "neighborhood fun center\u2122"
            },
            {
                "product_id": 29722,
                "product_name": "70 '' x 55 '' outdoor polyester playhouse with kitchen"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 21859,
                "product_name": "disney frozen arendelle 53.46 '' x 49.61 '' solid wood playhouse"
            },
            {
                "product_id": 2965,
                "product_name": "kids play tent for girls boys princess playhouse pink castle play tent children fairy tale play tent indoor outdoor with carry bag pink"
            },
            {
                "product_id": 34152,
                "product_name": "all around playtime patio"
            },
            {
                "product_id": 21616,
                "product_name": "craftsman playhouse"
            },
            {
                "product_id": 34149,
                "product_name": "sweetheart playhouse"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            }
        ],
        "garOutput": [
            {
                "product_id": 27199,
                "product_name": "princeton 6 person traditional steam sauna in cedar"
            },
            {
                "product_id": 10789,
                "product_name": "adalberto tv stand for tvs up to 65 ''"
            },
            {
                "product_id": 747,
                "product_name": "rosemount chaise lounge"
            },
            {
                "product_id": 17132,
                "product_name": "ellenton trestle coffee table with storage"
            },
            {
                "product_id": 6251,
                "product_name": "good morning sunshine rooster tin wall d\u00e9cor"
            }
        ],
        "query": "pennfield playhouse"
    },
    {
        "id": 338,
        "label": [
            {
                "product_id": 35791,
                "product_name": "channel rocking chair"
            },
            {
                "product_id": 34117,
                "product_name": "ta rocking chair"
            },
            {
                "product_id": 10693,
                "product_name": "allerton slat rocking chair"
            },
            {
                "product_id": 24279,
                "product_name": "legette rocking chair"
            },
            {
                "product_id": 30400,
                "product_name": "vineyard garden patio chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 33587,
                "product_name": "esra rocking chair glider"
            },
            {
                "product_id": 14657,
                "product_name": "lincolnwood rocking chair"
            },
            {
                "product_id": 30404,
                "product_name": "rocker jefferson rocking chair"
            },
            {
                "product_id": 15264,
                "product_name": "siemens outdoor spring rocking chair with cushions"
            },
            {
                "product_id": 14594,
                "product_name": "crossen solid wood rocking adirondack chair"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 3728,
                "product_name": "bless our home metal sign"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 8227,
                "product_name": "aemilia 4 door accent cabinet"
            },
            {
                "product_id": 17786,
                "product_name": "30 '' bar & 24 '' counter stool"
            },
            {
                "product_id": 5959,
                "product_name": "marple 42 '' wide 2 drawer server"
            }
        ],
        "garOutput": [
            {
                "product_id": 17221,
                "product_name": "eloyd rocking chair"
            },
            {
                "product_id": 15057,
                "product_name": "giovani swing chair"
            },
            {
                "product_id": 28842,
                "product_name": "polywood\u00ae traditional porch rocking chair"
            },
            {
                "product_id": 20095,
                "product_name": "haupt vine and border textured weave teal indoor/outdoor area rug"
            },
            {
                "product_id": 38735,
                "product_name": "weber spirit\u00ae e-210 2-burner propane gas grill"
            }
        ],
        "query": "mahone porch rocking chair"
    },
    {
        "id": 159,
        "label": [
            {
                "product_id": 25479,
                "product_name": "coldwell meditation sculpture"
            },
            {
                "product_id": 14075,
                "product_name": "let that sh * * go buddhist-meditation yoga-buddha 15 oz . all purpose wine glass"
            },
            {
                "product_id": 40002,
                "product_name": "estinville meditating statue"
            },
            {
                "product_id": 28408,
                "product_name": "martines buddha statue yoga meditation zen statue decor"
            },
            {
                "product_id": 17961,
                "product_name": "sitting buddha statue"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 33160,
                "product_name": "eichorn meditating elephant figurine"
            },
            {
                "product_id": 42616,
                "product_name": "galan meditation buddha statue"
            },
            {
                "product_id": 12910,
                "product_name": "galgano meditating buddha statue"
            },
            {
                "product_id": 11721,
                "product_name": "carelton feng shui zen meditating japanese jizo cat monk on lotus throne figurine"
            },
            {
                "product_id": 19397,
                "product_name": "meditating buddha with pedestal patio fountain with led light"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 13858,
                "product_name": "the elephant graphic art"
            },
            {
                "product_id": 30826,
                "product_name": "belgr concrete propane/natural gas fire pit table"
            },
            {
                "product_id": 11055,
                "product_name": "harcrest writing desk"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 36472,
                "product_name": "denio resin indoor water fountain with light"
            },
            {
                "product_id": 36475,
                "product_name": "howse resin fountain fountain with light"
            },
            {
                "product_id": 5673,
                "product_name": "8 person traditional steam sauna"
            },
            {
                "product_id": 5672,
                "product_name": "4 - person outdoor traditional steam sauna"
            },
            {
                "product_id": 5666,
                "product_name": "4 - person indoor steam sauna"
            }
        ],
        "query": "meditation"
    },
    {
        "id": 150,
        "label": [
            {
                "product_id": 15661,
                "product_name": "rubber dumbbell"
            },
            {
                "product_id": 14208,
                "product_name": "rubber encased hex dumbbell , 25lb pair"
            },
            {
                "product_id": 14210,
                "product_name": "rubber encased hex dumbbell , 20lb pair"
            },
            {
                "product_id": 42292,
                "product_name": "adjustable dumbbells barbell"
            },
            {
                "product_id": 19006,
                "product_name": "dumbbell"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 12046,
                "product_name": "fitness indoor/outdoor polka dots lumbar pillow cover"
            },
            {
                "product_id": 4767,
                "product_name": "30kg adjustable dumbbell set barbell exercise fitness equipment for building body weight loss"
            },
            {
                "product_id": 15662,
                "product_name": "neoprene coated dumbbell"
            },
            {
                "product_id": 14205,
                "product_name": "prctz adjustable dumbbell , 25 lbs ( 12 kgs ) /prctz 55 lbs ( 25 kgs ) single"
            },
            {
                "product_id": 27387,
                "product_name": "dumbbell / barbell"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 3196,
                "product_name": "hang ease c type shower curtain hooks"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            },
            {
                "product_id": 980,
                "product_name": "delato counter height pub table"
            },
            {
                "product_id": 16256,
                "product_name": "vidal ultra soft & plush dinosaurs fleece throw"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            }
        ],
        "garOutput": [
            {
                "product_id": 9584,
                "product_name": "garage mountain bicycle hoist ceiling mounted bike rack"
            },
            {
                "product_id": 22392,
                "product_name": "beveled semi-circle chair mat"
            },
            {
                "product_id": 19006,
                "product_name": "dumbbell"
            },
            {
                "product_id": 22463,
                "product_name": "hard floor straight rectangular chair mat"
            },
            {
                "product_id": 30657,
                "product_name": "prairie view industries threshold ramp"
            }
        ],
        "query": "dumbbells"
    },
    {
        "id": 178,
        "label": [
            {
                "product_id": 27209,
                "product_name": "patterson nordic 6 person traditional steam sauna"
            },
            {
                "product_id": 27195,
                "product_name": "grayson 4 person indoor traditional steam sauna in cedar"
            },
            {
                "product_id": 16385,
                "product_name": "copenhagen 3 person traditional steam sauna"
            },
            {
                "product_id": 27204,
                "product_name": "bucket , ladle , thermometer set"
            },
            {
                "product_id": 25283,
                "product_name": "3 person traditional steam sauna"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 1411,
                "product_name": "5 person traditional steam sauna"
            },
            {
                "product_id": 25791,
                "product_name": "medical 4 full spectrum 2 person far infrared sauna"
            },
            {
                "product_id": 1407,
                "product_name": "rejuvenator 2 - person indoor traditional steam sauna"
            },
            {
                "product_id": 5673,
                "product_name": "8 person traditional steam sauna"
            },
            {
                "product_id": 1415,
                "product_name": "toule stove room sauna heater"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 1179,
                "product_name": "real touch bouquet peonies stems"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 32598,
                "product_name": "sunflower bouquet sunflowers stem"
            },
            {
                "product_id": 11055,
                "product_name": "harcrest writing desk"
            }
        ],
        "garOutput": [
            {
                "product_id": 2182,
                "product_name": "3-tier printer cart with storage shelf mobile printer stand end table home office"
            },
            {
                "product_id": 33124,
                "product_name": "5 drawer chest"
            },
            {
                "product_id": 33123,
                "product_name": "wide storage 5 drawer dresser"
            },
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 35029,
                "product_name": "barney 3 piece seating group with cushions"
            }
        ],
        "query": "almost heaven sauna"
    },
    {
        "id": 385,
        "label": [
            {
                "product_id": 17003,
                "product_name": "pericles 50th birthday wish jar"
            },
            {
                "product_id": 10252,
                "product_name": "urn"
            },
            {
                "product_id": 18727,
                "product_name": "witter urn"
            },
            {
                "product_id": 32721,
                "product_name": "lensing urn"
            },
            {
                "product_id": 4526,
                "product_name": "katie 1 - light single urn pendant"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 32682,
                "product_name": "orchids flowering in urn"
            },
            {
                "product_id": 8,
                "product_name": "erith obliqui urn"
            },
            {
                "product_id": 29915,
                "product_name": "zev garden and peacock porcelain urn"
            },
            {
                "product_id": 19395,
                "product_name": "coalport resin pedestal peacock and urns outdoor fountain"
            },
            {
                "product_id": 20805,
                "product_name": "barcino urn"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 34840,
                "product_name": "elysium elephant picture frame"
            },
            {
                "product_id": 34997,
                "product_name": "jarom zen sun bouncy wind chime"
            },
            {
                "product_id": 1179,
                "product_name": "real touch bouquet peonies stems"
            },
            {
                "product_id": 16348,
                "product_name": "lethe 2 piece crib bedding set"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            }
        ],
        "garOutput": [
            {
                "product_id": 22626,
                "product_name": "66 '' h x 27 '' w x 15 '' d tall utility storage cabinet"
            },
            {
                "product_id": 33027,
                "product_name": "hiers 1 - light dimmable black armed sconce"
            },
            {
                "product_id": 13953,
                "product_name": "elam bed risers"
            },
            {
                "product_id": 7261,
                "product_name": "trower 34.3 '' h x 27.9 '' w x 13.6 '' d foldable storage shelves"
            },
            {
                "product_id": 4445,
                "product_name": "3 piece kitchen canister set"
            }
        ],
        "query": "mom urn"
    },
    {
        "id": 185,
        "label": [
            {
                "product_id": 37388,
                "product_name": "kodak 100 \u201d projection screen | portable lightweight white 16:9 projector screen , adjustable tripod stand & storage carry bag | adjustable height & easy assembly for home , office , school & church"
            },
            {
                "product_id": 21727,
                "product_name": "all classic lunch bag"
            },
            {
                "product_id": 15043,
                "product_name": "fully insulated strap lunch bag"
            },
            {
                "product_id": 11463,
                "product_name": "insulated picnic cooler , service for 2"
            },
            {
                "product_id": 39492,
                "product_name": "happy camper 2 person tent with carry bag"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 28823,
                "product_name": "magellan lunch insulated picnic cooler , service for 1"
            },
            {
                "product_id": 42548,
                "product_name": "doggies lunch bag"
            },
            {
                "product_id": 30697,
                "product_name": "julia insulated lunch bag"
            },
            {
                "product_id": 38413,
                "product_name": "lunch tote , strawberries"
            },
            {
                "product_id": 10284,
                "product_name": "14/16/20/22 feet indoor and outdoor inflatable blow up mega movie projector screen with carry bag for front projection"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 18943,
                "product_name": "sink leg"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            },
            {
                "product_id": 21200,
                "product_name": "cutts bar with wine storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 30696,
                "product_name": "floral insulated lunch bag"
            },
            {
                "product_id": 16019,
                "product_name": "1.25 qt . kitchen canister set"
            },
            {
                "product_id": 26267,
                "product_name": "scoville bread box"
            },
            {
                "product_id": 36424,
                "product_name": "everyday coffee , sugar , flour kitchen canister set"
            },
            {
                "product_id": 32772,
                "product_name": "tramontina gourmet stainless steel colander"
            }
        ],
        "query": "lunch bag"
    },
    {
        "id": 276,
        "label": [
            {
                "product_id": 26501,
                "product_name": "hankins l-shape desk with hutch"
            },
            {
                "product_id": 32987,
                "product_name": "aolany computer desk with hutch"
            },
            {
                "product_id": 29409,
                "product_name": "z\u00e4nger l-shape writing desk"
            },
            {
                "product_id": 4877,
                "product_name": "bransford desk with hutch"
            },
            {
                "product_id": 72,
                "product_name": "hansel height adjustable l-shape standing desk"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 20067,
                "product_name": "asel l-shape desk"
            },
            {
                "product_id": 17805,
                "product_name": "debron reversible l-shape executive desk"
            },
            {
                "product_id": 24901,
                "product_name": "vouni l-shape executive desk"
            },
            {
                "product_id": 41980,
                "product_name": "chrystyan l-shape executive desk"
            },
            {
                "product_id": 31248,
                "product_name": "edick writing desk with hutch"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 20747,
                "product_name": "home office computer desk with hutch , 47 inch rustic office desk and modern writing desk with storage shelves , vintage and black legs"
            },
            {
                "product_id": 29052,
                "product_name": "on ship wheel 7 piece coaster set with holder"
            },
            {
                "product_id": 11055,
                "product_name": "harcrest writing desk"
            },
            {
                "product_id": 9209,
                "product_name": "colby 6 drawer double dresser"
            }
        ],
        "garOutput": [
            {
                "product_id": 17548,
                "product_name": "fiqirete reversible l-shaped executive desk"
            },
            {
                "product_id": 20939,
                "product_name": "devereaux desk with hutch"
            },
            {
                "product_id": 2181,
                "product_name": "hoobro under-desk printer stand , 2-tier printer cart with storage shelf , printer rack with lockable wheels , metal frame , industrial style in office , home office , copier , scanner , rustic brown"
            },
            {
                "product_id": 32835,
                "product_name": "patchin modern l-shaped desk"
            },
            {
                "product_id": 19102,
                "product_name": "study computer ladder desk"
            }
        ],
        "query": "chaves l-shape floating desk with hutch"
    },
    {
        "id": 10,
        "label": [
            {
                "product_id": 4332,
                "product_name": "peters tufted upholstered low profile platform bed"
            },
            {
                "product_id": 24127,
                "product_name": "duffield upholstered low profile platform bed"
            },
            {
                "product_id": 1052,
                "product_name": "kidwelly king standard bed"
            },
            {
                "product_id": 4762,
                "product_name": "andreaz queen four poster platform bed"
            },
            {
                "product_id": 26802,
                "product_name": "elberta upholstered sleigh bed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 39669,
                "product_name": "pauletta platform bed"
            },
            {
                "product_id": 40370,
                "product_name": "tolna california king bed"
            },
            {
                "product_id": 17753,
                "product_name": "atagun queen tufted upholstered low profile four poster bed"
            },
            {
                "product_id": 5053,
                "product_name": "treadaway king standard bed"
            },
            {
                "product_id": 39843,
                "product_name": "dorinda king solid wood low profile platform bed"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 2089,
                "product_name": "elzadie queen four poster bed"
            },
            {
                "product_id": 25557,
                "product_name": "connolly tufted upholstered low profile standard bed"
            },
            {
                "product_id": 38673,
                "product_name": "abdulsamet coffee table"
            },
            {
                "product_id": 29241,
                "product_name": "cleotha twin 75 '' wide tufted back convertible sofa"
            }
        ],
        "garOutput": [
            {
                "product_id": 13504,
                "product_name": "teak tripod end table"
            },
            {
                "product_id": 42439,
                "product_name": "emrecan tufted velvet side chair"
            },
            {
                "product_id": 30181,
                "product_name": "emmielou tufted velvet side chair"
            },
            {
                "product_id": 41491,
                "product_name": "kymber round cotton pouf"
            },
            {
                "product_id": 42713,
                "product_name": "giullia 2 piece floating shelf"
            }
        ],
        "query": "king poster bed"
    },
    {
        "id": 373,
        "label": [
            {
                "product_id": 4414,
                "product_name": "dunn outdoor club patio chair with cushions"
            },
            {
                "product_id": 7095,
                "product_name": "brinly casual lift office task chair"
            },
            {
                "product_id": 14679,
                "product_name": "fitchett 37 '' wide tufted velvet club chair"
            },
            {
                "product_id": 35362,
                "product_name": "doerr upholstered side chair in cranberry red"
            },
            {
                "product_id": 33368,
                "product_name": "oss modern kids chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 4348,
                "product_name": "laux 29 '' wide barrel chair"
            },
            {
                "product_id": 42153,
                "product_name": "moppe leather arm chair"
            },
            {
                "product_id": 7783,
                "product_name": "buna 26.6 '' wide side chair"
            },
            {
                "product_id": 17253,
                "product_name": "21.5 '' wide armchair"
            },
            {
                "product_id": 25264,
                "product_name": "sawyer recliner patio chair with cushion"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 22829,
                "product_name": "aphina 29 '' wide linen wingback chair"
            },
            {
                "product_id": 9761,
                "product_name": "scaggs 6 piece bathroom accessory set"
            }
        ],
        "garOutput": [
            {
                "product_id": 2277,
                "product_name": "33.5 '' wide faux leather power lift assist standard recliner"
            },
            {
                "product_id": 29758,
                "product_name": "parachute 8 piece rattan sectional seating group with cushions"
            },
            {
                "product_id": 8122,
                "product_name": "ferebee 22 '' wide slipper chair"
            },
            {
                "product_id": 8121,
                "product_name": "ferebee 22.5 '' wide slipper chair"
            },
            {
                "product_id": 36061,
                "product_name": "mellon 22 '' wide polyester parsons chair"
            }
        ],
        "query": "togo chair"
    },
    {
        "id": 103,
        "label": [
            {
                "product_id": 23113,
                "product_name": "fabio eclectic bohemian patchwork kitchen mat"
            },
            {
                "product_id": 18404,
                "product_name": "spillman anti fatigue mat"
            },
            {
                "product_id": 20169,
                "product_name": "stephenson grateful thankful bless anti-fatigue mat"
            },
            {
                "product_id": 20832,
                "product_name": "rene comfort heavy duty standing 2 piece anti-fatigue mat set"
            },
            {
                "product_id": 20160,
                "product_name": "thad anti-fatigue mat"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 20185,
                "product_name": "abtal anti-fatigue mat"
            },
            {
                "product_id": 20191,
                "product_name": "elmsall anti-fatigue mat"
            },
            {
                "product_id": 37239,
                "product_name": "anti-fatigue comfort floor mat kitchen mat"
            },
            {
                "product_id": 20835,
                "product_name": "jermaine comfort heavy duty standing 2 piece anti-fatigue mat set"
            },
            {
                "product_id": 7838,
                "product_name": "elvan sunflower fields comfort anti-fatigue mat"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 17073,
                "product_name": "centerport cone basket 14 '' plastic hanging planter"
            },
            {
                "product_id": 20844,
                "product_name": "gabryella anti-fatigue mat"
            },
            {
                "product_id": 20843,
                "product_name": "delbarton anti-fatigue mat"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            },
            {
                "product_id": 7882,
                "product_name": "47 '' w garment racks"
            }
        ],
        "garOutput": [
            {
                "product_id": 7811,
                "product_name": "cline bless this home anti-fatigue mat"
            },
            {
                "product_id": 7809,
                "product_name": "kole family time anti-fatigue mat"
            },
            {
                "product_id": 7815,
                "product_name": "kerry fluer de lis anti-fatigue mat"
            },
            {
                "product_id": 7800,
                "product_name": "salcido ocean fantasy anti-fatigue mat"
            },
            {
                "product_id": 7817,
                "product_name": "tressa boho tile anti-fatigue mat"
            }
        ],
        "query": "kitchen anti fatigue mats"
    },
    {
        "id": 81,
        "label": [
            {
                "product_id": 24211,
                "product_name": "adalard reversible l-shape executive desk"
            },
            {
                "product_id": 29433,
                "product_name": "camron l-shape executive desk"
            },
            {
                "product_id": 19363,
                "product_name": "clarington glass l-shape desk"
            },
            {
                "product_id": 35796,
                "product_name": "kunkel l-shape computer desk"
            },
            {
                "product_id": 23682,
                "product_name": "hildegarde l-shape desk"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 30320,
                "product_name": "modern reversible l-shape desk"
            },
            {
                "product_id": 18205,
                "product_name": "gamache l-shape desk"
            },
            {
                "product_id": 33647,
                "product_name": "joanna 3 piece configurable l-shape writing desk with hutch"
            },
            {
                "product_id": 23715,
                "product_name": "l-shape desk with hutch"
            },
            {
                "product_id": 22098,
                "product_name": "antek 130 '' x 98 '' l-shape reception desk with light panels , composition 2"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            },
            {
                "product_id": 20747,
                "product_name": "home office computer desk with hutch , 47 inch rustic office desk and modern writing desk with storage shelves , vintage and black legs"
            }
        ],
        "garOutput": [
            {
                "product_id": 32461,
                "product_name": "saroyan 2 piece coffee table set"
            },
            {
                "product_id": 7531,
                "product_name": "riser"
            },
            {
                "product_id": 38487,
                "product_name": "baileyville lift top abstract coffee table"
            },
            {
                "product_id": 2852,
                "product_name": "carine desk"
            },
            {
                "product_id": 33791,
                "product_name": "florid lantern 6.25 '' x 7.25 '' straight edge ceramic singular subway tile"
            }
        ],
        "query": "orren ellis l shape desk"
    },
    {
        "id": 38,
        "label": [
            {
                "product_id": 41670,
                "product_name": "lucey patio chair"
            },
            {
                "product_id": 39971,
                "product_name": "outdoor patio rocking glider , swing loveseat patio bench chair for 2 persons , patio wrought steel iron frame rocker chair set with cushion for garden , backyard , porch , red"
            },
            {
                "product_id": 19788,
                "product_name": "camren 2 piece teak patio chair"
            },
            {
                "product_id": 3452,
                "product_name": "decastro stacking patio dining chair with cushion"
            },
            {
                "product_id": 26716,
                "product_name": "truong patio chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 472,
                "product_name": "mitchem adirondack patio chair with cushions"
            },
            {
                "product_id": 33982,
                "product_name": "victorine patio chair"
            },
            {
                "product_id": 21608,
                "product_name": "dalia patio chair with cushions"
            },
            {
                "product_id": 9350,
                "product_name": "armas modern outdoor wicker swivel club patio chair with cushions"
            },
            {
                "product_id": 29710,
                "product_name": "patio chair with cushions"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 22002,
                "product_name": "symons task chair"
            },
            {
                "product_id": 5380,
                "product_name": "levin wooden rocking chair"
            },
            {
                "product_id": 19365,
                "product_name": "velvet task chair"
            },
            {
                "product_id": 38719,
                "product_name": "briarhill patio reclining chaise lounge with cushion"
            },
            {
                "product_id": 8096,
                "product_name": "lengby 34.6 '' wide tufted club chair"
            }
        ],
        "query": "calvin patio chair"
    },
    {
        "id": 398,
        "label": [
            {
                "product_id": 14234,
                "product_name": "donna 1 - light led powered wallchiere"
            },
            {
                "product_id": 33695,
                "product_name": "6- pack , led , dimmable , energy star , ul listed , a21 super bright light bulb , warm white 2700k"
            },
            {
                "product_id": 2938,
                "product_name": "mount led vaporproof light"
            },
            {
                "product_id": 27999,
                "product_name": "16.5w g13 frosted led light bulb"
            },
            {
                "product_id": 34586,
                "product_name": "super bright light base 20 lighting accessory"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 33310,
                "product_name": "super bright 100 light string lighting"
            },
            {
                "product_id": 17014,
                "product_name": "indimar 1-light walichiere"
            },
            {
                "product_id": 4295,
                "product_name": "brightech sky led torchiere super bright standing touch sensor floor lamp , black"
            },
            {
                "product_id": 15390,
                "product_name": "evelots compact magnetic swivel hook super bright blue power source led flashlight"
            },
            {
                "product_id": 20783,
                "product_name": "donatina 1 light wallchieres"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 18143,
                "product_name": "operated string light"
            },
            {
                "product_id": 17412,
                "product_name": "olivas solid sheer rod pocket single curtain panel"
            },
            {
                "product_id": 32598,
                "product_name": "sunflower bouquet sunflowers stem"
            },
            {
                "product_id": 1179,
                "product_name": "real touch bouquet peonies stems"
            },
            {
                "product_id": 12427,
                "product_name": "beautiful realistic artificial tulips floral arrangements"
            }
        ],
        "garOutput": [
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 27495,
                "product_name": "1-up hard cover pet brag album"
            },
            {
                "product_id": 35981,
                "product_name": "neon strip rope lights"
            },
            {
                "product_id": 23947,
                "product_name": "be the light 20 '' table lamp"
            },
            {
                "product_id": 29670,
                "product_name": "enameled 3 piece coffee , tea , & sugar jar set"
            }
        ],
        "query": "super bright lights"
    },
    {
        "id": 168,
        "label": [
            {
                "product_id": 5986,
                "product_name": "winthrop l-shaped mobile printer stand"
            },
            {
                "product_id": 39981,
                "product_name": "baca mobile printer stand"
            },
            {
                "product_id": 31594,
                "product_name": "adesewa printer stand"
            },
            {
                "product_id": 36318,
                "product_name": "harvel printer stand"
            },
            {
                "product_id": 2297,
                "product_name": "bradley 3 tier printer stand"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 42651,
                "product_name": "eastgate mobile printer stand"
            },
            {
                "product_id": 37869,
                "product_name": "mobile printer stand with casters"
            },
            {
                "product_id": 22464,
                "product_name": "4-shelf printer stand wood filing mobile storage file cabinet with wheels"
            },
            {
                "product_id": 21992,
                "product_name": "home office printer stand"
            },
            {
                "product_id": 27625,
                "product_name": "printer stand with wheels"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 29041,
                "product_name": "gayler small office for home desk"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 14106,
                "product_name": "veronica 5 drawer chest"
            }
        ],
        "garOutput": [
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 35981,
                "product_name": "neon strip rope lights"
            },
            {
                "product_id": 38132,
                "product_name": "geometric teal indoor / outdoor area rug"
            },
            {
                "product_id": 7457,
                "product_name": "eiffel tower canvas art"
            },
            {
                "product_id": 2182,
                "product_name": "3-tier printer cart with storage shelf mobile printer stand end table home office"
            }
        ],
        "query": "printers"
    },
    {
        "id": 456,
        "label": [
            {
                "product_id": 21398,
                "product_name": "durango 4 piece recessed wall shelf with adjustable shelves"
            },
            {
                "product_id": 21369,
                "product_name": "feltonville wall shelf"
            },
            {
                "product_id": 8779,
                "product_name": "alanis wood wall shelf"
            },
            {
                "product_id": 21371,
                "product_name": "quesnel wall shelf"
            },
            {
                "product_id": 39387,
                "product_name": "2 piece wall shelf set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 22495,
                "product_name": "stines 2 piece wall shelf set"
            },
            {
                "product_id": 10419,
                "product_name": "retro wave wall shelf"
            },
            {
                "product_id": 29558,
                "product_name": "hogue glass wall shelf"
            },
            {
                "product_id": 33161,
                "product_name": "matik wall shelf"
            },
            {
                "product_id": 15774,
                "product_name": "bloq wall shelf"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 13957,
                "product_name": "galeton tv stand for tvs up to 88 ''"
            },
            {
                "product_id": 27320,
                "product_name": "0.75 '' x 0.75 '' seashell tile"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 20486,
                "product_name": "lockheart end table"
            }
        ],
        "garOutput": [
            {
                "product_id": 33665,
                "product_name": "delasandro metal storage cabinet"
            },
            {
                "product_id": 33664,
                "product_name": "iaconetti storage cabinet"
            },
            {
                "product_id": 1614,
                "product_name": "bulwell 23.5 '' w x 31.5 '' h x 12 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 13953,
                "product_name": "elam bed risers"
            },
            {
                "product_id": 35039,
                "product_name": "feeline plant stand"
            }
        ],
        "query": "wall design shelf"
    },
    {
        "id": 304,
        "label": [
            {
                "product_id": 40538,
                "product_name": "crayton 6 - light shaded drum chandelier"
            },
            {
                "product_id": 27153,
                "product_name": "calacatta cressa 3 '' x 6 '' marble look wall & floor tile"
            },
            {
                "product_id": 35435,
                "product_name": "gulzar 6 drawer double dresser"
            },
            {
                "product_id": 8817,
                "product_name": "jehova 6 drawer chest"
            },
            {
                "product_id": 21598,
                "product_name": "eliana 6 drawer double dresser"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 27179,
                "product_name": "domino 3 '' x 6 '' ceramic patterned wall & floor tile"
            },
            {
                "product_id": 35598,
                "product_name": "menifee 9 piece sectional seating group with cushions"
            },
            {
                "product_id": 26213,
                "product_name": "donaghy 6 drawer chest"
            },
            {
                "product_id": 33995,
                "product_name": "peytin 6 piece ice bucket wine bar tool set"
            },
            {
                "product_id": 19083,
                "product_name": "96 '' hfc 6 - blade standard ceiling fan"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20718,
                "product_name": "abramowski monitor stand"
            },
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 26781,
                "product_name": "tavernier barn fairy garden"
            }
        ],
        "garOutput": [
            {
                "product_id": 25213,
                "product_name": "industrial laundry hamper"
            },
            {
                "product_id": 30385,
                "product_name": "1006 rad sportz deluxe heavy duty freestanding sports rack"
            },
            {
                "product_id": 30386,
                "product_name": "1236 rad sportz freestanding sports rack"
            },
            {
                "product_id": 30645,
                "product_name": "39 '' wide power lift assist standard recliner"
            },
            {
                "product_id": 28519,
                "product_name": "maxwell twin over full bunk bed"
            }
        ],
        "query": "merlyn 6"
    },
    {
        "id": 201,
        "label": [
            {
                "product_id": 22690,
                "product_name": "elie double wall mounted robe hook"
            },
            {
                "product_id": 10138,
                "product_name": "trinsic 18 '' wall mounted towel bar"
            },
            {
                "product_id": 6597,
                "product_name": "pivotal wall mounted towel hook"
            },
            {
                "product_id": 22272,
                "product_name": "purist 30 '' wall mounted towel bar"
            },
            {
                "product_id": 21179,
                "product_name": "sidney matte black towel hook"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 2124,
                "product_name": "wall mounted towel hook"
            },
            {
                "product_id": 10129,
                "product_name": "trinsic double handle deck mounted roman tub faucet trim"
            },
            {
                "product_id": 26612,
                "product_name": "glyde wall mounted robe hook"
            },
            {
                "product_id": 33944,
                "product_name": "neo wall mounted double mounted robe hook"
            },
            {
                "product_id": 10130,
                "product_name": "trinsic double handle deck mounted roman tub faucet trim with handshower"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 36449,
                "product_name": "kisner 3 - light pool table linear pendant"
            },
            {
                "product_id": 32518,
                "product_name": "elite rack"
            },
            {
                "product_id": 10349,
                "product_name": "eira trestle coffee table"
            },
            {
                "product_id": 32654,
                "product_name": "bumble bee napkin ring"
            },
            {
                "product_id": 6212,
                "product_name": "hues farmhouse / country accent mirror"
            }
        ],
        "garOutput": [
            {
                "product_id": 31170,
                "product_name": "old country roses 6 '' bread and butter plate"
            },
            {
                "product_id": 4553,
                "product_name": "modu-licious chest"
            },
            {
                "product_id": 32654,
                "product_name": "bumble bee napkin ring"
            },
            {
                "product_id": 6327,
                "product_name": "hillpoint pineapple oval waste basket"
            },
            {
                "product_id": 10426,
                "product_name": "tioga towel ring"
            }
        ],
        "query": "delta trinsic double towel hook in champagne bronze"
    },
    {
        "id": 290,
        "label": [
            {
                "product_id": 2923,
                "product_name": "zeb 72 '' double bathroom vanity base only"
            },
            {
                "product_id": 9406,
                "product_name": "dussault 72 '' double bathroom vanity set"
            },
            {
                "product_id": 13996,
                "product_name": "furlow 48 '' double bathroom vanity set"
            },
            {
                "product_id": 10765,
                "product_name": "merrimack 60 '' double bathroom vanity set"
            },
            {
                "product_id": 25870,
                "product_name": "carnol 72 '' wall-mounted double bathroom vanity set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 15829,
                "product_name": "seigle 36 '' single vanity set"
            },
            {
                "product_id": 21553,
                "product_name": "emiliano 72 '' double bathroom vanity set"
            },
            {
                "product_id": 10766,
                "product_name": "merrimack 72 '' double bathroom vanity set"
            },
            {
                "product_id": 38198,
                "product_name": "creline 60 '' double bathroom vanity set"
            },
            {
                "product_id": 9873,
                "product_name": "gadbois vanity"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            },
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 11055,
                "product_name": "harcrest writing desk"
            }
        ],
        "garOutput": [
            {
                "product_id": 39003,
                "product_name": "payden leather upholstered dining chair in espresso"
            },
            {
                "product_id": 39004,
                "product_name": "caitriona leather upholstered dining chair"
            },
            {
                "product_id": 7563,
                "product_name": "decorative storage 30 '' h x 43.98 '' w cube bookcase"
            },
            {
                "product_id": 4024,
                "product_name": "emyrie teal indoor / outdoor area rug"
            },
            {
                "product_id": 4022,
                "product_name": "gainford beige/teal/brown indoor / outdoor area rug"
            }
        ],
        "query": "mission double vanity"
    },
    {
        "id": 321,
        "label": [
            {
                "product_id": 32078,
                "product_name": "hammett hand-tufted beige/gray area rug"
            },
            {
                "product_id": 30986,
                "product_name": "schurman abstract tufted wool/cotton beige area rug"
            },
            {
                "product_id": 15147,
                "product_name": "lisdale tufted magenta area rug"
            },
            {
                "product_id": 262,
                "product_name": "jakat gray rug"
            },
            {
                "product_id": 14134,
                "product_name": "tacconi border hand-tufted wool magenta/pink area rug"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 2127,
                "product_name": "brannan shag pink/blue area rug"
            },
            {
                "product_id": 38524,
                "product_name": "raquel tufted rug"
            },
            {
                "product_id": 7221,
                "product_name": "dunbar hand-tufted wool multi-colored area rug"
            },
            {
                "product_id": 11660,
                "product_name": "bensonhurst shag gray area rug"
            },
            {
                "product_id": 3139,
                "product_name": "willilams casual traditional hand-tufted wool ivory area rug"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 41836,
                "product_name": "liesbeth stool"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 20347,
                "product_name": "dildy platform bed frame"
            },
            {
                "product_id": 27333,
                "product_name": "berengere 4 '' cotton futon mattress"
            },
            {
                "product_id": 27336,
                "product_name": "vintage floral japanese 4 '' cotton futon mattress"
            }
        ],
        "garOutput": [
            {
                "product_id": 14041,
                "product_name": "karval anti-fatigue mat"
            },
            {
                "product_id": 14042,
                "product_name": "davidson anti-fatigue mat"
            },
            {
                "product_id": 40677,
                "product_name": "regner power loom yellow/black rug"
            },
            {
                "product_id": 28779,
                "product_name": "sabahat ombre blue area rug"
            },
            {
                "product_id": 35224,
                "product_name": "milltown geometric multi-colored indoor / outdoor area rug"
            }
        ],
        "query": "hussain handmade tufted magenta rug"
    },
    {
        "id": 453,
        "label": [
            {
                "product_id": 24412,
                "product_name": "lorraine tv stand for tvs up to 60 ''"
            },
            {
                "product_id": 35432,
                "product_name": "sahale tv stand for tvs up to 70 ''"
            },
            {
                "product_id": 2148,
                "product_name": "hegins tv stand"
            },
            {
                "product_id": 39783,
                "product_name": "glenn tv stand for tvs up to 78 ''"
            },
            {
                "product_id": 39864,
                "product_name": "keiko tv stand for tvs up to 65 ''"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 28819,
                "product_name": "rooney floating tv stand for tvs up to 85 ''"
            },
            {
                "product_id": 23857,
                "product_name": "deville tv stand for tvs up to 65 ''"
            },
            {
                "product_id": 4505,
                "product_name": "lonaconing tv stand for tvs up to 48 ''"
            },
            {
                "product_id": 24297,
                "product_name": "lorraine tv stand for tvs up to 55 '' with electric fireplace included"
            },
            {
                "product_id": 13245,
                "product_name": "belora tv stand for tvs up to 50 ''"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 26646,
                "product_name": "international harvester melamine cereal bowl"
            }
        ],
        "garOutput": [
            {
                "product_id": 6772,
                "product_name": "residential mid century modern style passage door lever"
            },
            {
                "product_id": 42113,
                "product_name": "oxford 1 3/8 '' diameter mushroom knob"
            },
            {
                "product_id": 21435,
                "product_name": "deboer staggered 6 shelf geometric bookcase"
            },
            {
                "product_id": 35420,
                "product_name": "acencion bookcase headboard"
            },
            {
                "product_id": 29634,
                "product_name": "31 '' wide tufted polyester armchair"
            }
        ],
        "query": "midcentury tv unit"
    },
    {
        "id": 197,
        "label": [
            {
                "product_id": 21865,
                "product_name": "avalon kids 41.6 '' writing desk with hutch and chair set"
            },
            {
                "product_id": 24327,
                "product_name": "abella kids writing desk"
            },
            {
                "product_id": 17416,
                "product_name": "willette kids study desk"
            },
            {
                "product_id": 1187,
                "product_name": "flor kids 20 '' writing desk and two chair set"
            },
            {
                "product_id": 34666,
                "product_name": "lynde height adjustable standing desk"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 9587,
                "product_name": "lindsey 25 '' w writing desk and chair set"
            },
            {
                "product_id": 17616,
                "product_name": "kids desk and chair set , height adjustable school children study table with arc-shaped crossbar , lamp , bookstand , wood tilting tabletop , drawer , furniture for 3-15 years old student"
            },
            {
                "product_id": 42049,
                "product_name": "lindall 39.4 '' w writing desk with hutch and chair set"
            },
            {
                "product_id": 17617,
                "product_name": "kids desk and chair set , height adjustable school children study table with led lamp , bookstand , wood tilting tabletop , drawer storage , for 3-15 years old students"
            },
            {
                "product_id": 32685,
                "product_name": "rolling height adjustable standing desk"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 32495,
                "product_name": "dederang 2 '' bookshelf"
            },
            {
                "product_id": 29041,
                "product_name": "gayler small office for home desk"
            },
            {
                "product_id": 25455,
                "product_name": "nordic ware santa \u2019 s sleigh non-stick loaf pan"
            },
            {
                "product_id": 16348,
                "product_name": "lethe 2 piece crib bedding set"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            }
        ],
        "garOutput": [
            {
                "product_id": 14341,
                "product_name": "manufactured wood adjustable height open front desk"
            },
            {
                "product_id": 38433,
                "product_name": "bisa kids study 36 '' writing desk and chair set"
            },
            {
                "product_id": 508,
                "product_name": "35.9 '' rectangular folding table"
            },
            {
                "product_id": 16119,
                "product_name": "owls nameplates"
            },
            {
                "product_id": 38001,
                "product_name": "flack 26 '' w writing desk and chair set"
            }
        ],
        "query": "desk for kids tjat ate 10 year old"
    },
    {
        "id": 89,
        "label": [
            {
                "product_id": 7486,
                "product_name": "wellington solar powered led fence post cap"
            },
            {
                "product_id": 3427,
                "product_name": "60 led solar wall light w/motion sensor 270\u00b0 super bright ( set of 2 )"
            },
            {
                "product_id": 30029,
                "product_name": "solar powered fence post cap"
            },
            {
                "product_id": 40012,
                "product_name": "pineapple garden solar powered pathway light"
            },
            {
                "product_id": 33275,
                "product_name": "color-changing led solar hanging lights ( 4 pack )"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 7487,
                "product_name": "fairmont solar powered led fence post cap"
            },
            {
                "product_id": 24414,
                "product_name": "solar powered led spot light pack"
            },
            {
                "product_id": 30028,
                "product_name": "solar powered stainless steel wall light"
            },
            {
                "product_id": 30030,
                "product_name": "black solar powered integrated led pathway light"
            },
            {
                "product_id": 28415,
                "product_name": "low voltage solar powered integrated led fence post cap pack"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 12293,
                "product_name": "holtz purple area rug"
            },
            {
                "product_id": 6302,
                "product_name": "belvedere pineapple figurine"
            },
            {
                "product_id": 32598,
                "product_name": "sunflower bouquet sunflowers stem"
            },
            {
                "product_id": 35468,
                "product_name": "seashell 1 '' x 1 '' mosaic tile in white"
            },
            {
                "product_id": 16515,
                "product_name": "i like your crown by eli halpin - wrapped canvas print"
            }
        ],
        "garOutput": [
            {
                "product_id": 9583,
                "product_name": "camper night light"
            },
            {
                "product_id": 17475,
                "product_name": "welcome porch sign wall d\u00e9cor"
            },
            {
                "product_id": 30017,
                "product_name": "18.5 '' solar powered integrated led lawn ornament"
            },
            {
                "product_id": 20919,
                "product_name": "10 '' lantern"
            },
            {
                "product_id": 19187,
                "product_name": "jairo round multi-tiered plant stand"
            }
        ],
        "query": "whimsical solar lights"
    },
    {
        "id": 146,
        "label": [
            {
                "product_id": 39051,
                "product_name": "daria 59 '' single bathroom vanity base only"
            },
            {
                "product_id": 7747,
                "product_name": "galveston 36 '' single bathroom vanity base only"
            },
            {
                "product_id": 33660,
                "product_name": "24 '' free-standing single bathroom vanity base only"
            },
            {
                "product_id": 33658,
                "product_name": "belize 35.2 '' single bathroom vanity base only"
            },
            {
                "product_id": 19609,
                "product_name": "24 '' bathroom vanity base only"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 35184,
                "product_name": "farnel 2 door accent cabinet"
            },
            {
                "product_id": 23158,
                "product_name": "aneira full cabinet 36 '' single bathroom vanity set"
            },
            {
                "product_id": 3144,
                "product_name": "letourneau 31 '' wall-mounted single bathroom vanity set"
            },
            {
                "product_id": 7750,
                "product_name": "galveston 30 '' single bathroom vanity base only"
            },
            {
                "product_id": 19613,
                "product_name": "18 '' bathroom vanity base only"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 5959,
                "product_name": "marple 42 '' wide 2 drawer server"
            },
            {
                "product_id": 36032,
                "product_name": "deeksha storage cabinet"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 21200,
                "product_name": "cutts bar with wine storage"
            },
            {
                "product_id": 3197,
                "product_name": "double shower curtain hooks"
            }
        ],
        "garOutput": [
            {
                "product_id": 34858,
                "product_name": "slide-a-shelf made to fit standard out shelf , full extension with soft close , choice of custom size and solid wood front"
            },
            {
                "product_id": 18241,
                "product_name": "3 - shelf storage cabinet"
            },
            {
                "product_id": 2079,
                "product_name": "43 '' armless sofa bed"
            },
            {
                "product_id": 4218,
                "product_name": "alef 37 '' kitchen pantry"
            },
            {
                "product_id": 16319,
                "product_name": "35.1 '' rectangular fold-in-half portable folding table"
            }
        ],
        "query": "laundry vanity cabinet"
    },
    {
        "id": 164,
        "label": [
            {
                "product_id": 9863,
                "product_name": "dansk 7-piece modular outdoor conversational furniture set , wicker sectional sofas w/ cover"
            },
            {
                "product_id": 41405,
                "product_name": "jasiir 3 piece sofa seating group with cushions"
            },
            {
                "product_id": 19372,
                "product_name": "3 piece rocking patio furniture set"
            },
            {
                "product_id": 14743,
                "product_name": "outdoor 4 piece sofa seating group with cushions"
            },
            {
                "product_id": 5198,
                "product_name": "vartavar modern furniture sets"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 7973,
                "product_name": "merlin outdoor furniture 7 piece rattan sectional seating group with cushions"
            },
            {
                "product_id": 14648,
                "product_name": "isa outdoor 2 piece sofa seating group with cushions"
            },
            {
                "product_id": 14667,
                "product_name": "mowery modern outdoor patio chair"
            },
            {
                "product_id": 35298,
                "product_name": "burruss 83.5 '' wide outdoor reversible patio sectional"
            },
            {
                "product_id": 14542,
                "product_name": "merlyn 6- piece outdoor garden patio furniture"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 13956,
                "product_name": "deauville coffee table"
            },
            {
                "product_id": 13955,
                "product_name": "erachidia coffee table"
            },
            {
                "product_id": 13957,
                "product_name": "galeton tv stand for tvs up to 88 ''"
            }
        ],
        "garOutput": [
            {
                "product_id": 33027,
                "product_name": "hiers 1 - light dimmable black armed sconce"
            },
            {
                "product_id": 40265,
                "product_name": "gerika 3 piece solid wood bracket shelf"
            },
            {
                "product_id": 40266,
                "product_name": "gisli 2 piece solid wood tiered shelf"
            },
            {
                "product_id": 310,
                "product_name": "roclincourt 3 legs 2 nesting tables"
            },
            {
                "product_id": 29011,
                "product_name": "silke 3 legs 2 nesting tables"
            }
        ],
        "query": "modern outdoor furniture"
    },
    {
        "id": 251,
        "label": [
            {
                "product_id": 15962,
                "product_name": "3 piece kitchen canister set"
            },
            {
                "product_id": 32791,
                "product_name": "otto 3 piece coffee , tea & sugar jar set"
            },
            {
                "product_id": 18755,
                "product_name": "prep canisters 3 piece coffee , flour , & sugar set"
            },
            {
                "product_id": 28829,
                "product_name": "airscape\u00ae kilo 1.87 qt . coffee jar"
            },
            {
                "product_id": 14304,
                "product_name": "marble look 2 qt . kitchen canister"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 31774,
                "product_name": "hot/cold thermal 5-qt food storage container"
            },
            {
                "product_id": 27573,
                "product_name": "2 piece canister set"
            },
            {
                "product_id": 17478,
                "product_name": "4 piece coffee , tea and sugar jar"
            },
            {
                "product_id": 31393,
                "product_name": "0.44 qt . sugar jar"
            },
            {
                "product_id": 16299,
                "product_name": "mr. coffee 3 piece kitchen canister set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            },
            {
                "product_id": 38673,
                "product_name": "abdulsamet coffee table"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 12440,
                "product_name": "80 can freestanding beverage refrigerator"
            },
            {
                "product_id": 980,
                "product_name": "delato counter height pub table"
            }
        ],
        "garOutput": [
            {
                "product_id": 310,
                "product_name": "roclincourt 3 legs 2 nesting tables"
            },
            {
                "product_id": 29011,
                "product_name": "silke 3 legs 2 nesting tables"
            },
            {
                "product_id": 39463,
                "product_name": "woodoaks coffee table"
            },
            {
                "product_id": 39443,
                "product_name": "36 pair shoe storage cabinet"
            },
            {
                "product_id": 34346,
                "product_name": "novotny twin metal daybed"
            }
        ],
        "query": "coffee container"
    },
    {
        "id": 147,
        "label": [
            {
                "product_id": 15777,
                "product_name": "drummer boy - picture frame textual art print"
            },
            {
                "product_id": 41686,
                "product_name": "a premium ocean breeze graphic art print multi-piece image on wrapped canvas"
            },
            {
                "product_id": 4520,
                "product_name": "charcoal feathers iii by beverly fuller - picture frame painting print on paper"
            },
            {
                "product_id": 26773,
                "product_name": "ahart drum end table"
            },
            {
                "product_id": 3001,
                "product_name": "magdalen 5 - light lantern drum chandelier"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 12159,
                "product_name": "snare drum - wrapped canvas print"
            },
            {
                "product_id": 40930,
                "product_name": "camel by pablo picasso - picture frame print on paper"
            },
            {
                "product_id": 37304,
                "product_name": "celaya solid wood drum coffee table"
            },
            {
                "product_id": 37253,
                "product_name": "alliant international university picture frame"
            },
            {
                "product_id": 4677,
                "product_name": "collection of memories - 3 piece multi-piece image print on canvas"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 18943,
                "product_name": "sink leg"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            },
            {
                "product_id": 14501,
                "product_name": "26 '' wide side chair"
            },
            {
                "product_id": 16370,
                "product_name": "stikki clips white 20 per pack"
            }
        ],
        "garOutput": [
            {
                "product_id": 27495,
                "product_name": "1-up hard cover pet brag album"
            },
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 35981,
                "product_name": "neon strip rope lights"
            },
            {
                "product_id": 38132,
                "product_name": "geometric teal indoor / outdoor area rug"
            },
            {
                "product_id": 27810,
                "product_name": "sayers damask 8 piece comforter set"
            }
        ],
        "query": "drum picture"
    },
    {
        "id": 2,
        "label": [
            {
                "product_id": 16256,
                "product_name": "vidal ultra soft & plush dinosaurs fleece throw"
            },
            {
                "product_id": 35711,
                "product_name": "dinosaur kingdom growth chart"
            },
            {
                "product_id": 11075,
                "product_name": "mangano watercolor dinosaurs jurassic wall sticker"
            },
            {
                "product_id": 11735,
                "product_name": "doremus larger furry bones rex the tyrannosaurus and dinosaur doll voodoo skeleton"
            },
            {
                "product_id": 27515,
                "product_name": "stegosaurus canvas art"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 18868,
                "product_name": "hollywood baby boy dinosaurs nursery 12 piece crib bedding set"
            },
            {
                "product_id": 12165,
                "product_name": "dinosaur green & blue - graphic art print"
            },
            {
                "product_id": 42516,
                "product_name": "yasmine comforter set"
            },
            {
                "product_id": 13636,
                "product_name": "mishti dinosaurs 4 piece toddler bedding set"
            },
            {
                "product_id": 28472,
                "product_name": "nisswa dinosaur reversible comforter set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 30826,
                "product_name": "belgr concrete propane/natural gas fire pit table"
            }
        ],
        "garOutput": [
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 27495,
                "product_name": "1-up hard cover pet brag album"
            },
            {
                "product_id": 38132,
                "product_name": "geometric teal indoor / outdoor area rug"
            },
            {
                "product_id": 29152,
                "product_name": "all about dinosaurs paper print"
            },
            {
                "product_id": 2232,
                "product_name": "patio rocking chair"
            }
        ],
        "query": "dinosaur"
    },
    {
        "id": 92,
        "label": [
            {
                "product_id": 6749,
                "product_name": "tedder 45 '' console table"
            },
            {
                "product_id": 41838,
                "product_name": "gracia console table"
            },
            {
                "product_id": 14969,
                "product_name": "corriveau transitional console table"
            },
            {
                "product_id": 6481,
                "product_name": "aren 45.3 '' console table"
            },
            {
                "product_id": 15243,
                "product_name": "oisin 50 '' console table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 22832,
                "product_name": "sadie 45.7 '' console table"
            },
            {
                "product_id": 39919,
                "product_name": "laith 45 '' console table"
            },
            {
                "product_id": 17290,
                "product_name": "grand hall console table"
            },
            {
                "product_id": 5049,
                "product_name": "baptiste 45 '' console table"
            },
            {
                "product_id": 1961,
                "product_name": "hadenson 28.25 '' console table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 38244,
                "product_name": "agirta extendable dining table"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 20625,
                "product_name": "rolling storage cart"
            },
            {
                "product_id": 2222,
                "product_name": "eulises bar with wine storage"
            },
            {
                "product_id": 738,
                "product_name": "runner groverson geometric blue area rug"
            },
            {
                "product_id": 739,
                "product_name": "runner groversen geometric gray area rug"
            },
            {
                "product_id": 22847,
                "product_name": "jahred floating desk with hutch"
            }
        ],
        "query": "antique silver entry table"
    },
    {
        "id": 422,
        "label": [
            {
                "product_id": 40669,
                "product_name": "tetbury 22.25 '' wide wingback chair"
            },
            {
                "product_id": 10237,
                "product_name": "mullinix velvet task chair"
            },
            {
                "product_id": 40082,
                "product_name": "moffatt upholstered parsons chair"
            },
            {
                "product_id": 11669,
                "product_name": "manzelli 28 '' wide tufted papasan chair"
            },
            {
                "product_id": 33300,
                "product_name": "ravena desk and chair set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 3092,
                "product_name": "sheldon 29 '' wide tufted top grain leather club chair"
            },
            {
                "product_id": 28820,
                "product_name": "alunna genuine leather upholstered dining chair"
            },
            {
                "product_id": 39086,
                "product_name": "drelan rocking chair"
            },
            {
                "product_id": 368,
                "product_name": "aguilera 26 '' wide top grain leather lounge chair"
            },
            {
                "product_id": 18591,
                "product_name": "arm chair in green"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20486,
                "product_name": "lockheart end table"
            },
            {
                "product_id": 16108,
                "product_name": "paride stool"
            },
            {
                "product_id": 11244,
                "product_name": "effervescence task chair"
            },
            {
                "product_id": 29041,
                "product_name": "gayler small office for home desk"
            },
            {
                "product_id": 321,
                "product_name": "office reversible l-shape desk"
            }
        ],
        "garOutput": [
            {
                "product_id": 10372,
                "product_name": "bontemps ergonomic executive chair"
            },
            {
                "product_id": 16295,
                "product_name": "oberon ergonomic executive chair"
            },
            {
                "product_id": 36048,
                "product_name": "veston 35.4 '' wide faux leather power lift assist standard recliner"
            },
            {
                "product_id": 16296,
                "product_name": "barron task chair"
            },
            {
                "product_id": 18204,
                "product_name": "galen height adjustable lab stool"
            }
        ],
        "query": "ergonomic chair"
    },
    {
        "id": 69,
        "label": [
            {
                "product_id": 14262,
                "product_name": "ornate iron wall sconce"
            },
            {
                "product_id": 39136,
                "product_name": "ellemaie 1 - light brushed nickel wall sconce light"
            },
            {
                "product_id": 6474,
                "product_name": "larkin 2-light dimmable led black bath sconce"
            },
            {
                "product_id": 39308,
                "product_name": "sconce 2 wall shelf set"
            },
            {
                "product_id": 15467,
                "product_name": "pillar wall sconce in antique copper"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 2103,
                "product_name": "jewel alluvial 1-light wall sconce"
            },
            {
                "product_id": 40458,
                "product_name": "betta modern 1-light wall armed sconce"
            },
            {
                "product_id": 1640,
                "product_name": "2 piece metal wall sconce set"
            },
            {
                "product_id": 1519,
                "product_name": "coghill 1-light wall sconce"
            },
            {
                "product_id": 5400,
                "product_name": "crafted wall candleholder metal and glass sconce"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 32034,
                "product_name": "control-r wi-fi module demand with push button and temperature sensor"
            },
            {
                "product_id": 18039,
                "product_name": "european renaissance ii computer desk"
            },
            {
                "product_id": 20437,
                "product_name": "heinle 1 - light led spotlight"
            },
            {
                "product_id": 32033,
                "product_name": "control-r wi-fi module with push button temperature sensor and pump"
            },
            {
                "product_id": 14350,
                "product_name": "15-amp standard duplex outlet"
            }
        ],
        "garOutput": [
            {
                "product_id": 16519,
                "product_name": "surge protector wall mounted power strip"
            },
            {
                "product_id": 28074,
                "product_name": "huntley power strip clamp desk mount copy stand"
            },
            {
                "product_id": 26489,
                "product_name": "4 '' strips tv backlight led 80 '' under cabinet tape light"
            },
            {
                "product_id": 31703,
                "product_name": "aviles 12 '' led under cabinet strip light"
            },
            {
                "product_id": 6734,
                "product_name": "1-outlet surge protector with 2 usb port"
            }
        ],
        "query": "wall sconce with usb port"
    },
    {
        "id": 457,
        "label": [
            {
                "product_id": 10521,
                "product_name": "northern lights over south 1-gang toggle light switch wall plate"
            },
            {
                "product_id": 13979,
                "product_name": "classic beaded 3-gang toggle light switch wall plate"
            },
            {
                "product_id": 42842,
                "product_name": "arabinda 1-gang duplex outlet wall plate"
            },
            {
                "product_id": 10492,
                "product_name": "eighth note music 1-gang toggle light switch wall plate"
            },
            {
                "product_id": 26430,
                "product_name": "hand hammered 2-gang toggle light switch wall plate"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 10624,
                "product_name": "sandy 2-gang toggle light switch wall plate"
            },
            {
                "product_id": 10511,
                "product_name": "president barack pop art 1-gang toggle light switch wall plate"
            },
            {
                "product_id": 10630,
                "product_name": "fun musical notes records art - music 2-gang toggle light switch wall plate"
            },
            {
                "product_id": 10570,
                "product_name": "switch music notes on piano keys 1-gang toggle light switch wall plate"
            },
            {
                "product_id": 5753,
                "product_name": "arboresque 1-gang toggle light switch wall plate"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 42966,
                "product_name": "mershon 2-light outdoor bulkhead light"
            },
            {
                "product_id": 21866,
                "product_name": "90 '' 4-burner propane/natural gas"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 9624,
                "product_name": "military challenge coin cabinet rack holder display case"
            }
        ],
        "garOutput": [
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 27495,
                "product_name": "1-up hard cover pet brag album"
            },
            {
                "product_id": 35981,
                "product_name": "neon strip rope lights"
            },
            {
                "product_id": 29142,
                "product_name": "crab with bubbles night light"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            }
        ],
        "query": "5 gang light switch"
    },
    {
        "id": 123,
        "label": [
            {
                "product_id": 11290,
                "product_name": "47.25 '' console table"
            },
            {
                "product_id": 9323,
                "product_name": "mcpherson coffee table"
            },
            {
                "product_id": 4434,
                "product_name": "madewell rattan coffee table with storage"
            },
            {
                "product_id": 8657,
                "product_name": "pennels coffee table"
            },
            {
                "product_id": 1524,
                "product_name": "rentz 64 '' console table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 13554,
                "product_name": "coco de paris flamingo heart coffee table"
            },
            {
                "product_id": 27987,
                "product_name": "tamlyn coffee table"
            },
            {
                "product_id": 29372,
                "product_name": "abstract 2 bunching tables"
            },
            {
                "product_id": 31894,
                "product_name": "mcnear 36 '' solid wood console table"
            },
            {
                "product_id": 17825,
                "product_name": "52 '' 6 - player poker table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 1029,
                "product_name": "candelario bar with wine storage"
            },
            {
                "product_id": 31143,
                "product_name": "amilliah tufted upholstered parsons chair"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 13956,
                "product_name": "deauville coffee table"
            },
            {
                "product_id": 13955,
                "product_name": "erachidia coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 2330,
                "product_name": "accessory"
            },
            {
                "product_id": 36011,
                "product_name": "beer gets 41 mpg fact funny us made aluminum novelty bar pub wall d\u00e9cor"
            },
            {
                "product_id": 33666,
                "product_name": "120 gallon deck box"
            },
            {
                "product_id": 20491,
                "product_name": "contemporary wall mounted dry erase board"
            },
            {
                "product_id": 35165,
                "product_name": "tassio 5 drawer chest"
            }
        ],
        "query": "entrance table"
    },
    {
        "id": 361,
        "label": [
            {
                "product_id": 9546,
                "product_name": "kisner home sweet home throw pillow"
            },
            {
                "product_id": 34479,
                "product_name": "kisner canno teak wood decorative bowl"
            },
            {
                "product_id": 37411,
                "product_name": "kisner 2 drawer nightstand"
            },
            {
                "product_id": 23205,
                "product_name": "kisner geometric table runner"
            },
            {
                "product_id": 36449,
                "product_name": "kisner 3 - light pool table linear pendant"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 36509,
                "product_name": "kisner shoreline collage coastal nautical impressions garden 2-sided burlap 18.5 x 13 in . flag set"
            },
            {
                "product_id": 9153,
                "product_name": "kisner geometric black area rug"
            },
            {
                "product_id": 28125,
                "product_name": "kisner dolphins figurine"
            },
            {
                "product_id": 30206,
                "product_name": "kisner height adjustable standing desk converter"
            },
            {
                "product_id": 11111,
                "product_name": "kisner 5 - piece drop leaf dining set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 14501,
                "product_name": "26 '' wide side chair"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            }
        ],
        "garOutput": [
            {
                "product_id": 27199,
                "product_name": "princeton 6 person traditional steam sauna in cedar"
            },
            {
                "product_id": 27198,
                "product_name": "pinnacle 4 person traditional steam sauna in cedar"
            },
            {
                "product_id": 33230,
                "product_name": "gaither zen meditation resin fountain with light"
            },
            {
                "product_id": 2392,
                "product_name": "godbey natural stone bubbling fountain"
            },
            {
                "product_id": 411,
                "product_name": "drucker fiberglass and resin water well fountain"
            }
        ],
        "query": "kisner"
    },
    {
        "id": 96,
        "label": [
            {
                "product_id": 39351,
                "product_name": "established bar 3-line wall d\u00e9cor"
            },
            {
                "product_id": 27720,
                "product_name": "shabby architectural window wall decor"
            },
            {
                "product_id": 5135,
                "product_name": "metal window pane design wall decor with 20 panes , black"
            },
            {
                "product_id": 18416,
                "product_name": "textured metal wall d\u00e9cor"
            },
            {
                "product_id": 31479,
                "product_name": "metal wine wall d\u00e9cor"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 27347,
                "product_name": "rustic wooden bar sign wall decor"
            },
            {
                "product_id": 13730,
                "product_name": "golden metal wall decor"
            },
            {
                "product_id": 40027,
                "product_name": "square decorative carved floral wall d\u00e9cor"
            },
            {
                "product_id": 19343,
                "product_name": "whiskey distillery personalized bar sign wall d\u00e9cor"
            },
            {
                "product_id": 25156,
                "product_name": "have a beautiful day metal wall d\u00e9cor"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 13956,
                "product_name": "deauville coffee table"
            },
            {
                "product_id": 13955,
                "product_name": "erachidia coffee table"
            },
            {
                "product_id": 13957,
                "product_name": "galeton tv stand for tvs up to 88 ''"
            },
            {
                "product_id": 1029,
                "product_name": "candelario bar with wine storage"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            }
        ],
        "garOutput": [
            {
                "product_id": 19353,
                "product_name": "wooden custom mimosa bar wall d\u00e9cor"
            },
            {
                "product_id": 18899,
                "product_name": "custom life anchor wall d\u00e9cor"
            },
            {
                "product_id": 7451,
                "product_name": "7 bottle wall mounted wine bottle & glass rack in white"
            },
            {
                "product_id": 29048,
                "product_name": "mateer pineapple storage decor"
            },
            {
                "product_id": 42551,
                "product_name": "hoppe upholstered panel headboard"
            }
        ],
        "query": "bar room wall decor"
    },
    {
        "id": 143,
        "label": [
            {
                "product_id": 28960,
                "product_name": "47.5 '' wide tufted wingback chair"
            },
            {
                "product_id": 4781,
                "product_name": "osterhout 28 '' wide tufted wingback chair"
            },
            {
                "product_id": 4743,
                "product_name": "lauretta 27.5 '' wide velvet wingback chair"
            },
            {
                "product_id": 22121,
                "product_name": "cerna 29.5 '' wide tufted wingback chair"
            },
            {
                "product_id": 15465,
                "product_name": "26.6 '' wide wingback chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 9742,
                "product_name": "malcolm tufted velvet upholstered wingback dining chair"
            },
            {
                "product_id": 22664,
                "product_name": "33 '' wide tufted wingback chair"
            },
            {
                "product_id": 15305,
                "product_name": "adelline 28.7 '' wide polyester wingback chair"
            },
            {
                "product_id": 23749,
                "product_name": "ivo 30 '' wide tufted wingback chair"
            },
            {
                "product_id": 28955,
                "product_name": "31.5 '' wide slipcovered wingback chair"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 16981,
                "product_name": "hafsa velvet task chair"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 20888,
                "product_name": "mowat 41 '' wide power lift assist recliner"
            },
            {
                "product_id": 7299,
                "product_name": "bolivar upholstered dining chair"
            },
            {
                "product_id": 32281,
                "product_name": "metal slat back stacking side chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 8122,
                "product_name": "ferebee 22 '' wide slipper chair"
            },
            {
                "product_id": 8121,
                "product_name": "ferebee 22.5 '' wide slipper chair"
            },
            {
                "product_id": 23620,
                "product_name": "blaize upholstered parsons chair"
            },
            {
                "product_id": 3164,
                "product_name": "ergonomic pu leather high back office chair with flip-up armrest managerial chair executive chair desk chair computer chair"
            },
            {
                "product_id": 13504,
                "product_name": "teak tripod end table"
            }
        ],
        "query": "queen wingback chair"
    },
    {
        "id": 243,
        "label": [
            {
                "product_id": 22511,
                "product_name": "lofgren round multi-tiered plant stand"
            },
            {
                "product_id": 41840,
                "product_name": "cullum square multi-tiered plant stand"
            },
            {
                "product_id": 36582,
                "product_name": "the kristopher goddess eset pedestal plant stand"
            },
            {
                "product_id": 13791,
                "product_name": "bridgit plant stand"
            },
            {
                "product_id": 27427,
                "product_name": "multi layer flower higher and lower plant stand"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 39470,
                "product_name": "krieger square multi-tiered plant stand"
            },
            {
                "product_id": 2980,
                "product_name": "keila round muti-tiered plant stand"
            },
            {
                "product_id": 42230,
                "product_name": "bowne 6 tier wood plant stand"
            },
            {
                "product_id": 19187,
                "product_name": "jairo round multi-tiered plant stand"
            },
            {
                "product_id": 38691,
                "product_name": "abeeda crystal plant stand"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8740,
                "product_name": "alessandro rectangular multi-tiered plant stand"
            },
            {
                "product_id": 13247,
                "product_name": "square multi-tiered plant stand"
            },
            {
                "product_id": 8753,
                "product_name": "4 tier plant stand garden planter metal flower pot rack corner shelving indoor outdoor"
            },
            {
                "product_id": 8754,
                "product_name": "bamboo plant flower stand rack 2 tier corner tall flower bench holder indoor"
            },
            {
                "product_id": 8752,
                "product_name": "3 tier corner plant stand foldable nesting flower pot holder elegant metal plant rack for indoor outdoor garden patio3"
            }
        ],
        "garOutput": [
            {
                "product_id": 1614,
                "product_name": "bulwell 23.5 '' w x 31.5 '' h x 12 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 33664,
                "product_name": "iaconetti storage cabinet"
            },
            {
                "product_id": 33665,
                "product_name": "delasandro metal storage cabinet"
            },
            {
                "product_id": 42592,
                "product_name": "wadhurst 49 '' w x 24 '' d vinyl arbor"
            },
            {
                "product_id": 35035,
                "product_name": "fedorko plant stand"
            }
        ],
        "query": "indoor multi tiered plant stand"
    },
    {
        "id": 370,
        "label": [
            {
                "product_id": 18919,
                "product_name": "aftan geometric purple/gray area rug"
            },
            {
                "product_id": 9138,
                "product_name": "delcastillo geometric purple area rug"
            },
            {
                "product_id": 30305,
                "product_name": "ajmail geometric purple/gray area rug"
            }
        ],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "product_id": 13956,
                "product_name": "deauville coffee table"
            },
            {
                "product_id": 13955,
                "product_name": "erachidia coffee table"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 42543,
                "product_name": "hartland abc barnyard power loomed green/red area rug"
            },
            {
                "product_id": 16256,
                "product_name": "vidal ultra soft & plush dinosaurs fleece throw"
            }
        ],
        "garOutput": [
            {
                "product_id": 21359,
                "product_name": "pvc home-use protective hard floor straight chair mat"
            },
            {
                "product_id": 1608,
                "product_name": "stool"
            },
            {
                "product_id": 36917,
                "product_name": "pvc chairmat floor protector desk carpet chair mat"
            },
            {
                "product_id": 4007,
                "product_name": "metal/wire basket"
            },
            {
                "product_id": 12106,
                "product_name": "toomsboro geometric burgundy area rug"
            }
        ],
        "query": "rug for teen room"
    },
    {
        "id": 424,
        "label": [
            {
                "product_id": 163,
                "product_name": "teddy stitch coma inducer comforter set"
            },
            {
                "product_id": 40189,
                "product_name": "umberger embroidery comforter set"
            },
            {
                "product_id": 27483,
                "product_name": "adrienne blue reversible floral cotton 7 piece comforter set"
            },
            {
                "product_id": 153,
                "product_name": "rigby like butta coma inducer comforter set"
            },
            {
                "product_id": 2745,
                "product_name": "aurelia single reversible coverlet"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 13177,
                "product_name": "alder reversible comforter set"
            },
            {
                "product_id": 11530,
                "product_name": "kent youngstrom abstract 2 piece comforter set"
            },
            {
                "product_id": 17312,
                "product_name": "bitia sherpa single comforter"
            },
            {
                "product_id": 9450,
                "product_name": "lucinda quilt set"
            },
            {
                "product_id": 144,
                "product_name": "lennie really dogg coma inducer comforter set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 16256,
                "product_name": "vidal ultra soft & plush dinosaurs fleece throw"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 42543,
                "product_name": "hartland abc barnyard power loomed green/red area rug"
            },
            {
                "product_id": 27333,
                "product_name": "berengere 4 '' cotton futon mattress"
            }
        ],
        "garOutput": [
            {
                "product_id": 2182,
                "product_name": "3-tier printer cart with storage shelf mobile printer stand end table home office"
            },
            {
                "product_id": 14379,
                "product_name": "shoe rack , 5-tier shoe storage unit flat & slant adjustable shoe organizer shelf for 16 pairs , durable and stable , for entryway , hallway , closet , dorm room , industrial , rustic brown"
            },
            {
                "product_id": 13609,
                "product_name": "iveta abolina floral 3 piece comforter set"
            },
            {
                "product_id": 13511,
                "product_name": "iveta abolina rose comforter set"
            },
            {
                "product_id": 23972,
                "product_name": "sanni 28.2 '' w x 11.8 '' h wall mounted bathroom shelf"
            }
        ],
        "query": "wayfair comforters"
    },
    {
        "id": 266,
        "label": [
            {
                "product_id": 8012,
                "product_name": "evangely tufted velvet side chair"
            },
            {
                "product_id": 9492,
                "product_name": "home office chair \u2014\u2014ergonomic mesh chair computer chair home executive desk chair comfortable reclining swivel chair and , mid back with wheels for teens/adults ( pink )"
            },
            {
                "product_id": 11247,
                "product_name": "peral task chair"
            },
            {
                "product_id": 8725,
                "product_name": "kalum tufted velvet upholstered side chair"
            },
            {
                "product_id": 28204,
                "product_name": "waynesville velvet task chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 8858,
                "product_name": "aguanga desk and chair set"
            },
            {
                "product_id": 2881,
                "product_name": "salina l-shape executive desk and chair set"
            },
            {
                "product_id": 35205,
                "product_name": "annexe task chair"
            },
            {
                "product_id": 13754,
                "product_name": "dowland 26 '' wide velvet wingback chair"
            },
            {
                "product_id": 5780,
                "product_name": "clintonville solid wood writing desk with chair set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 38673,
                "product_name": "abdulsamet coffee table"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 3837,
                "product_name": "galaz 28 '' wide polyester wingback chair"
            },
            {
                "product_id": 24934,
                "product_name": "carneys 28 '' wide polyester wingback chair"
            },
            {
                "product_id": 24935,
                "product_name": "carneys 28 '' wide polyester wingback chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 14840,
                "product_name": "castiel tufted velvet upholstered parsons chair"
            },
            {
                "product_id": 25588,
                "product_name": "catt 2 - drawer end table"
            },
            {
                "product_id": 14625,
                "product_name": "yorba 31.5 '' wide tufted velvet armchair"
            },
            {
                "product_id": 23695,
                "product_name": "state line velvet executive chair"
            },
            {
                "product_id": 40117,
                "product_name": "alford 29.5 '' wide polyester wingback chair"
            }
        ],
        "query": "champagne velvet desk chair"
    },
    {
        "id": 97,
        "label": [
            {
                "product_id": 15557,
                "product_name": "isolda power loom green/teal indoor/outdoor use rug"
            },
            {
                "product_id": 4656,
                "product_name": "rosabel power loom red/beige/orange rug"
            },
            {
                "product_id": 41822,
                "product_name": "fairmont huntington marble border power loom ivory rug"
            },
            {
                "product_id": 40651,
                "product_name": "bastioni power loom dark brown/red rug"
            },
            {
                "product_id": 40203,
                "product_name": "owlswick power loom red/beige/gray rug"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 41893,
                "product_name": "power loom dark blue/aqua indoor/outdoor rug"
            },
            {
                "product_id": 41854,
                "product_name": "oliver power loom teal/white rug"
            },
            {
                "product_id": 4486,
                "product_name": "putnam power loom red/navy rug"
            },
            {
                "product_id": 40834,
                "product_name": "mercier power loom ivory/red/blue rug"
            },
            {
                "product_id": 6724,
                "product_name": "hyeon power loom red/ivory/black rug"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 20718,
                "product_name": "abramowski monitor stand"
            },
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 20085,
                "product_name": "dario 12 - light candle style wagon wheel chandelier"
            },
            {
                "product_id": 347,
                "product_name": "1.93 '' solar powered outdoor floating light"
            }
        ],
        "garOutput": [
            {
                "product_id": 32338,
                "product_name": "11 '' live succulent plant in pot"
            },
            {
                "product_id": 14421,
                "product_name": "live zamioculcas plant in pot"
            },
            {
                "product_id": 14420,
                "product_name": "live zamioculcas plant in pot"
            },
            {
                "product_id": 37146,
                "product_name": "15 '' live flowering plant in pot"
            },
            {
                "product_id": 6790,
                "product_name": "barcus recycled plastic pot planter"
            }
        ],
        "query": "regner power loom red"
    },
    {
        "id": 389,
        "label": [
            {
                "product_id": 30466,
                "product_name": "jaida widespread bathroom faucet with drain assembly"
            },
            {
                "product_id": 30292,
                "product_name": "widespread bathroom faucet with drain assembly"
            },
            {
                "product_id": 11440,
                "product_name": "bathroom faucet"
            },
            {
                "product_id": 10190,
                "product_name": "trinsic\u00ae bathroom single hole bathroom faucet with drain assembly and diamond seal technology"
            },
            {
                "product_id": 39485,
                "product_name": "abby widespread bathroom faucet with drain assembly"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 26589,
                "product_name": "wynford single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 26426,
                "product_name": "wall mounted bathroom faucet"
            },
            {
                "product_id": 17531,
                "product_name": "donostia single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 25867,
                "product_name": "matherne single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 21108,
                "product_name": "brass single hole bathroom faucet"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 19718,
                "product_name": "commercial vessel sink bathroom faucet with drain assembly"
            },
            {
                "product_id": 19717,
                "product_name": "commercial single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 39407,
                "product_name": "allicen 2 -drawer storage cabinet"
            },
            {
                "product_id": 19731,
                "product_name": "widespread bathroom faucet with drain assembly"
            },
            {
                "product_id": 19730,
                "product_name": "vessel sink bathroom faucet with drain assembly"
            }
        ],
        "garOutput": [
            {
                "product_id": 6430,
                "product_name": "widespread faucet bathroom faucet"
            },
            {
                "product_id": 23127,
                "product_name": "restoration centerset bathroom faucet with drain assembly"
            },
            {
                "product_id": 10282,
                "product_name": "faucet handle"
            },
            {
                "product_id": 21063,
                "product_name": "marble single shower curtain"
            },
            {
                "product_id": 22696,
                "product_name": "arlo vessel sink bathroom faucet with drain assembly"
            }
        ],
        "query": "brush nickel faucet bathroom"
    },
    {
        "id": 122,
        "label": [
            {
                "product_id": 37284,
                "product_name": "willer standard bed"
            },
            {
                "product_id": 17698,
                "product_name": "kurt tufted low profile standard bed"
            },
            {
                "product_id": 9884,
                "product_name": "hollenbeck king tufted low profile standard bed"
            },
            {
                "product_id": 24268,
                "product_name": "jorgensen platform bed"
            },
            {
                "product_id": 9408,
                "product_name": "elifnaz upholstered platform bed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 38912,
                "product_name": "aparajita storage platform bed"
            },
            {
                "product_id": 14242,
                "product_name": "dodge platform bed"
            },
            {
                "product_id": 5918,
                "product_name": "vannatta upholstered platform bed"
            },
            {
                "product_id": 29466,
                "product_name": "hanley heavy duty bed frame"
            },
            {
                "product_id": 6899,
                "product_name": "mntsur king low profile standard bed"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 28234,
                "product_name": "1 drawer nightstand"
            },
            {
                "product_id": 29241,
                "product_name": "cleotha twin 75 '' wide tufted back convertible sofa"
            },
            {
                "product_id": 38673,
                "product_name": "abdulsamet coffee table"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 25557,
                "product_name": "connolly tufted upholstered low profile standard bed"
            }
        ],
        "garOutput": [
            {
                "product_id": 38375,
                "product_name": "haru 48 '' platform bed"
            },
            {
                "product_id": 38276,
                "product_name": "portis upholstered panel headboard"
            },
            {
                "product_id": 42303,
                "product_name": "plain end table"
            },
            {
                "product_id": 25939,
                "product_name": "abril 10 '' firm gel memory foam mattress"
            },
            {
                "product_id": 2061,
                "product_name": "winsor 4.5 '' plush innerspring mattress"
            }
        ],
        "query": "king size bed"
    },
    {
        "id": 184,
        "label": [
            {
                "product_id": 16990,
                "product_name": "4 qt . kitchen canister"
            },
            {
                "product_id": 11147,
                "product_name": "gloucester 4 piece kitchen canister set"
            },
            {
                "product_id": 5889,
                "product_name": "tia 3 piece kitchen canister set"
            },
            {
                "product_id": 24494,
                "product_name": "ceramic 3 piece kitchen canister set with air-tight lids"
            },
            {
                "product_id": 36425,
                "product_name": "marble 3 piece kitchen canister set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 26286,
                "product_name": "nusteel etched antique copper 1 qt stainless steel canister , beautiful food storage container for kitchen counter , tea , sugar , coffee , caddy , flour canister with rubber seal lid,3f8186bb6a85496d994047891c168af6"
            },
            {
                "product_id": 593,
                "product_name": "tuscania : tuscania canister zucchero ( sugar )"
            },
            {
                "product_id": 36501,
                "product_name": "hammered square 3 piece kitchen canister set"
            },
            {
                "product_id": 23617,
                "product_name": "1 qt . kitchen canister"
            },
            {
                "product_id": 3348,
                "product_name": "montana jar 8 qt . kitchen canister"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 12440,
                "product_name": "80 can freestanding beverage refrigerator"
            },
            {
                "product_id": 3824,
                "product_name": "woodridge 6 ft. w x 5 ft. d storage shed"
            },
            {
                "product_id": 1179,
                "product_name": "real touch bouquet peonies stems"
            },
            {
                "product_id": 16370,
                "product_name": "stikki clips white 20 per pack"
            },
            {
                "product_id": 4321,
                "product_name": "dimitre 3 drawer chest"
            }
        ],
        "garOutput": [
            {
                "product_id": 26443,
                "product_name": "4 qt . canning jar"
            },
            {
                "product_id": 26444,
                "product_name": "2 piece canning jar set"
            },
            {
                "product_id": 26442,
                "product_name": "1.8 qt . canning jar"
            },
            {
                "product_id": 20549,
                "product_name": "cresswell armless stackable chair"
            },
            {
                "product_id": 9973,
                "product_name": "fruit vegetable wire basket set"
            }
        ],
        "query": "sugar canister"
    },
    {
        "id": 205,
        "label": [
            {
                "product_id": 25473,
                "product_name": "aneda gray rug"
            },
            {
                "product_id": 2127,
                "product_name": "brannan shag pink/blue area rug"
            },
            {
                "product_id": 2163,
                "product_name": "round galinka geometric shag pink/orange/purple area rug"
            },
            {
                "product_id": 2559,
                "product_name": "handmade-shag lavender area rug"
            },
            {
                "product_id": 24374,
                "product_name": "napfle geometric shag dark gray/white area rug"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 15530,
                "product_name": "ines ivory area rug"
            },
            {
                "product_id": 32657,
                "product_name": "debellis shag dark gray area rug"
            },
            {
                "product_id": 10862,
                "product_name": "geometric shag dark gray area rug"
            },
            {
                "product_id": 2136,
                "product_name": "round floral shag pink/blue area rug"
            },
            {
                "product_id": 20028,
                "product_name": "alicya shag dark gray rug"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 30536,
                "product_name": "36 '' h x 28 '' w x 0.3 '' d chair rail"
            },
            {
                "product_id": 15660,
                "product_name": "shutter/door accessory"
            },
            {
                "product_id": 33684,
                "product_name": "20 ft. w x 10 ft. d greenhouse"
            },
            {
                "product_id": 30535,
                "product_name": "peel and stick instant panel wainscoting 28 '' h x 28 '' w x 0.3 '' d chair rail"
            }
        ],
        "garOutput": [
            {
                "product_id": 6199,
                "product_name": "indus power loom red/navy/yellow rug"
            },
            {
                "product_id": 4847,
                "product_name": "hussain tufted magenta rug"
            },
            {
                "product_id": 26128,
                "product_name": "hillsby oriental beige/light gray area rug"
            },
            {
                "product_id": 26138,
                "product_name": "hillsby floral multicolor area rug"
            },
            {
                "product_id": 26119,
                "product_name": "hillsby oriental teal/navy area rug"
            }
        ],
        "query": "ines shag rug w003096809"
    },
    {
        "id": 250,
        "label": [
            {
                "product_id": 10724,
                "product_name": "hermila 3 piece multi area rug set"
            },
            {
                "product_id": 17911,
                "product_name": "quoc abstract blue area rug"
            },
            {
                "product_id": 7220,
                "product_name": "dunbar hand-tufted wool multi-color area rug"
            },
            {
                "product_id": 778,
                "product_name": "georgina hand-braided multi-color area rug"
            },
            {
                "product_id": 31030,
                "product_name": "sandford handwoven cotton multi-colored rug"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 16768,
                "product_name": "amilya gray rug"
            },
            {
                "product_id": 12098,
                "product_name": "green/red/gray area rug"
            },
            {
                "product_id": 28712,
                "product_name": "bourgault cream multi-colored area rug"
            },
            {
                "product_id": 21693,
                "product_name": "multi area rug"
            },
            {
                "product_id": 34801,
                "product_name": "plum scene 2 multi area rug"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 9128,
                "product_name": "abstract power loom wool/polyester red area rug"
            },
            {
                "product_id": 42543,
                "product_name": "hartland abc barnyard power loomed green/red area rug"
            },
            {
                "product_id": 7839,
                "product_name": "citadel sunflower fields cheese board"
            },
            {
                "product_id": 27320,
                "product_name": "0.75 '' x 0.75 '' seashell tile"
            },
            {
                "product_id": 12350,
                "product_name": "rossmere indoor/outdoor bench cushion"
            }
        ],
        "garOutput": [
            {
                "product_id": 31551,
                "product_name": "wilcox floral beige area rug set"
            },
            {
                "product_id": 4660,
                "product_name": "chanler hand-tufted wool black/white area rug"
            },
            {
                "product_id": 41867,
                "product_name": "burnstad abstract navy/aqua/light gray/white area rug"
            },
            {
                "product_id": 7218,
                "product_name": "ellenton brown/ivory indoor/outdoor area rug"
            },
            {
                "product_id": 40817,
                "product_name": "yolo power loom red/gray rug"
            }
        ],
        "query": "multi color rug"
    },
    {
        "id": 298,
        "label": [
            {
                "product_id": 41883,
                "product_name": "abilene 20 '' wide rectangle abstract pouf ottoman"
            },
            {
                "product_id": 5866,
                "product_name": "odin 20 '' wide round pouf ottoman"
            },
            {
                "product_id": 36130,
                "product_name": "leachville pouf"
            },
            {
                "product_id": 41491,
                "product_name": "kymber round cotton pouf"
            },
            {
                "product_id": 21408,
                "product_name": "mika 16 '' wide square pouf ottoman"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 30996,
                "product_name": "rohini neutral pouf"
            },
            {
                "product_id": 34201,
                "product_name": "vishvanath 18 '' square standard ottoman"
            },
            {
                "product_id": 23555,
                "product_name": "sosebee pouf ottoman"
            },
            {
                "product_id": 25845,
                "product_name": "liberty 18 '' wide square pouf ottoman"
            },
            {
                "product_id": 5117,
                "product_name": "heeter 14 '' round geometric pouf ottoman"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 41836,
                "product_name": "liesbeth stool"
            },
            {
                "product_id": 42543,
                "product_name": "hartland abc barnyard power loomed green/red area rug"
            },
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            },
            {
                "product_id": 38627,
                "product_name": "jurgen stool"
            },
            {
                "product_id": 890,
                "product_name": "sapphira reversible quilt set"
            }
        ],
        "garOutput": [
            {
                "product_id": 272,
                "product_name": "shah oriental hand knotted wool plum/black area rug"
            },
            {
                "product_id": 271,
                "product_name": "shah oriental hand knotted wool plum/ivory area rug"
            },
            {
                "product_id": 6387,
                "product_name": "arriaga hand-tufted magenta area rug"
            },
            {
                "product_id": 40872,
                "product_name": "brooks cement pot planter"
            },
            {
                "product_id": 41815,
                "product_name": "portland geometric gray area rug"
            }
        ],
        "query": "small woven pouf"
    },
    {
        "id": 291,
        "label": [
            {
                "product_id": 24839,
                "product_name": "music row wood medallion 2 door accent cabinet"
            },
            {
                "product_id": 34477,
                "product_name": "yusuf 2 door accent cabinet"
            },
            {
                "product_id": 6953,
                "product_name": "4 door accent cabinet"
            },
            {
                "product_id": 15417,
                "product_name": "alioth 1 door accent cabinet"
            },
            {
                "product_id": 17823,
                "product_name": "ione 2 door accent cabinet"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 8225,
                "product_name": "steel storage cabinet , 3 shelf metal storage cabinet with 2 adjustable shelves and lockable doors"
            },
            {
                "product_id": 24697,
                "product_name": "bever 2 door accent cabinet"
            },
            {
                "product_id": 25520,
                "product_name": "cache 2 door accent cabinet"
            },
            {
                "product_id": 26353,
                "product_name": "ennis 2 door accent cabinet"
            },
            {
                "product_id": 33606,
                "product_name": "classic series 2 door storage cabinet"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 38244,
                "product_name": "agirta extendable dining table"
            },
            {
                "product_id": 17223,
                "product_name": "buffet storage cabinet with single glass doors and unique bell handle"
            },
            {
                "product_id": 1654,
                "product_name": "aaiyana striped sheer grommet single curtain panel"
            },
            {
                "product_id": 1657,
                "product_name": "solid color sheer grommet single curtain panel"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 33664,
                "product_name": "iaconetti storage cabinet"
            },
            {
                "product_id": 33665,
                "product_name": "delasandro metal storage cabinet"
            },
            {
                "product_id": 31825,
                "product_name": "ostrowski 15 pair shoe storage cabinet"
            },
            {
                "product_id": 4533,
                "product_name": "vitela 48 '' console table"
            },
            {
                "product_id": 1614,
                "product_name": "bulwell 23.5 '' w x 31.5 '' h x 12 '' d free-standing bathroom cabinet"
            }
        ],
        "query": "front door cabinet with doors"
    },
    {
        "id": 343,
        "label": [
            {
                "product_id": 11046,
                "product_name": "ailisa valatie reversible duvet cover set"
            },
            {
                "product_id": 27864,
                "product_name": "alapaha purple reversible 3 piece duvet cover set"
            },
            {
                "product_id": 30436,
                "product_name": "sterrett single reversible duvet cover"
            },
            {
                "product_id": 31245,
                "product_name": "ulloa comforter set"
            },
            {
                "product_id": 1574,
                "product_name": "mcmurray reversible coverlet set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 27845,
                "product_name": "3 piece duvet cover set"
            },
            {
                "product_id": 3301,
                "product_name": "kevin reversible comforter set"
            },
            {
                "product_id": 30203,
                "product_name": "ayomide safari duvet set"
            },
            {
                "product_id": 32502,
                "product_name": "jade comforter set"
            },
            {
                "product_id": 20999,
                "product_name": "navy blue and pink watercolor floral 5 piece crib bedding set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 3729,
                "product_name": "hedvige save the bees table runner"
            },
            {
                "product_id": 29241,
                "product_name": "cleotha twin 75 '' wide tufted back convertible sofa"
            },
            {
                "product_id": 890,
                "product_name": "sapphira reversible quilt set"
            },
            {
                "product_id": 38673,
                "product_name": "abdulsamet coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 31207,
                "product_name": "tomblin luxury duvet cover set"
            },
            {
                "product_id": 21154,
                "product_name": "lykes comforter set"
            },
            {
                "product_id": 21647,
                "product_name": "all season microfiber down alternative comforter"
            },
            {
                "product_id": 29171,
                "product_name": "croghan reversible comforter set"
            },
            {
                "product_id": 7238,
                "product_name": "nonna 100 % cotton flannel pillowcase"
            }
        ],
        "query": "navy and peach bedding"
    },
    {
        "id": 293,
        "label": [
            {
                "product_id": 15374,
                "product_name": "trendsetter hard floor straight rectangle chair mat"
            },
            {
                "product_id": 37378,
                "product_name": "ecotex high pile carpet straight rectangular chair mat"
            },
            {
                "product_id": 42922,
                "product_name": "hard floor beveled semi-circle chair mat"
            },
            {
                "product_id": 37369,
                "product_name": "hard floor straight square chair mat"
            },
            {
                "product_id": 21201,
                "product_name": "premium heavy-duty furniture felt pad protectors for hardwood ( pack of 76 )"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 6586,
                "product_name": "medium pile carpet beveled edge chair mat"
            },
            {
                "product_id": 37367,
                "product_name": "hard floor nonagon chair mat"
            },
            {
                "product_id": 27622,
                "product_name": "comfort floor anti-fatigue mat"
            },
            {
                "product_id": 27859,
                "product_name": "slypnos rectangular pvc hard floor straight chair mat"
            },
            {
                "product_id": 39425,
                "product_name": "hard floor beveled rectangular chair mat"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 17073,
                "product_name": "centerport cone basket 14 '' plastic hanging planter"
            }
        ],
        "garOutput": [
            {
                "product_id": 35611,
                "product_name": "outdoor replacement cushion"
            },
            {
                "product_id": 2178,
                "product_name": "kara folding 2 piece tray table set"
            },
            {
                "product_id": 2179,
                "product_name": "alison folding 4 piece tray table set"
            },
            {
                "product_id": 31189,
                "product_name": "bi-fold top mount sliding standard single track barn door hardware kit"
            },
            {
                "product_id": 31188,
                "product_name": "bi-fold sliding standard single track barn door hardware kit"
            }
        ],
        "query": "wood floor protector mat"
    },
    {
        "id": 387,
        "label": [
            {
                "product_id": 7512,
                "product_name": "pacheco self-watering plastic elevated planter"
            },
            {
                "product_id": 5594,
                "product_name": "happy planter planter , set of 2 ( large , medium ) , cedar"
            },
            {
                "product_id": 5583,
                "product_name": "happy planter planter , 12 '' x 12 '' x 11.2 '' , antique beige"
            },
            {
                "product_id": 32402,
                "product_name": "gethsemane concrete pot planter"
            },
            {
                "product_id": 5585,
                "product_name": "happy planter planter , 11 '' x 11 '' x 10.8 '' , pigeon grey"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 30527,
                "product_name": "celestia self-watering plastic elevated planter"
            },
            {
                "product_id": 7264,
                "product_name": "metal cachepot"
            },
            {
                "product_id": 19390,
                "product_name": "mesut 2-piece mgo pot planter set"
            },
            {
                "product_id": 7510,
                "product_name": "owen self-watering plastic elevated planter"
            },
            {
                "product_id": 32398,
                "product_name": "artimacormick concrete pot planter"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 34604,
                "product_name": "metal bed"
            },
            {
                "product_id": 12253,
                "product_name": "sydney kitchen cart with butcher block top"
            },
            {
                "product_id": 7143,
                "product_name": "benton shoe storage bench"
            },
            {
                "product_id": 42924,
                "product_name": "white 100 '' portable folding frame projection screen"
            },
            {
                "product_id": 17073,
                "product_name": "centerport cone basket 14 '' plastic hanging planter"
            }
        ],
        "garOutput": [
            {
                "product_id": 33665,
                "product_name": "delasandro metal storage cabinet"
            },
            {
                "product_id": 33664,
                "product_name": "iaconetti storage cabinet"
            },
            {
                "product_id": 29704,
                "product_name": "souderton self-watering plastic elevated planter"
            },
            {
                "product_id": 21740,
                "product_name": "ledya adjustable 0.63 '' curtain rod"
            },
            {
                "product_id": 19842,
                "product_name": "delk 24 '' w x 9 '' h x 10 '' d wall mounted bathroom shelves"
            }
        ],
        "query": "self enclosed planters"
    },
    {
        "id": 431,
        "label": [
            {
                "product_id": 26314,
                "product_name": "alexandria wood drawers/shoe storage bench"
            },
            {
                "product_id": 32345,
                "product_name": "wysocki 12 pair shoe storage cabinet"
            },
            {
                "product_id": 24447,
                "product_name": "donovan cubby storage bench"
            },
            {
                "product_id": 26766,
                "product_name": "pepin upholstered storage bench"
            },
            {
                "product_id": 9948,
                "product_name": "gatton solid wood shoe storage bench"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 8474,
                "product_name": "entryway padded 10 pair shoe storage bench"
            },
            {
                "product_id": 36864,
                "product_name": "27 pair shoe rack"
            },
            {
                "product_id": 5019,
                "product_name": "lara faux leather shelves storage bench"
            },
            {
                "product_id": 3786,
                "product_name": "shoe storage bench"
            },
            {
                "product_id": 33111,
                "product_name": "9 pair shoe storage bench"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8753,
                "product_name": "4 tier plant stand garden planter metal flower pot rack corner shelving indoor outdoor"
            },
            {
                "product_id": 8754,
                "product_name": "bamboo plant flower stand rack 2 tier corner tall flower bench holder indoor"
            },
            {
                "product_id": 8740,
                "product_name": "alessandro rectangular multi-tiered plant stand"
            },
            {
                "product_id": 19708,
                "product_name": "waterfall single hole bathroom faucet"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 20719,
                "product_name": "bianchina large monitor stand"
            },
            {
                "product_id": 9987,
                "product_name": "43 '' w closet system"
            },
            {
                "product_id": 13814,
                "product_name": "altun task chair"
            },
            {
                "product_id": 18899,
                "product_name": "custom life anchor wall d\u00e9cor"
            },
            {
                "product_id": 36921,
                "product_name": "4 ft. h x 2 ft. w screen series web metal privacy screen"
            }
        ],
        "query": "shoe bench entryway"
    },
    {
        "id": 125,
        "label": [
            {
                "product_id": 4335,
                "product_name": "gabouray tufted upholstered low profile storage platform bed"
            },
            {
                "product_id": 1943,
                "product_name": "swanley tufted upholstered low profile standard bed"
            },
            {
                "product_id": 13652,
                "product_name": "geralyn tufted upholstered storage platform bed"
            },
            {
                "product_id": 17699,
                "product_name": "misael tufted low profile standard bed"
            },
            {
                "product_id": 18710,
                "product_name": "aykhan 36.1 '' bed frame"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 11633,
                "product_name": "flemington bed frame"
            },
            {
                "product_id": 40514,
                "product_name": "ackles standard bed"
            },
            {
                "product_id": 17720,
                "product_name": "beckville low profile standard bed"
            },
            {
                "product_id": 17069,
                "product_name": "greenport solid wood platform bed"
            },
            {
                "product_id": 7170,
                "product_name": "forreston low profile four poster bed"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 15678,
                "product_name": "bed frame"
            },
            {
                "product_id": 37193,
                "product_name": "metal and wood bed frame with headboard and footboard , full size platform bed , no box spring needed , easy to assemble ( black )"
            },
            {
                "product_id": 32482,
                "product_name": "krause 16 '' height profile platform bed"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 37195,
                "product_name": "wood platform bed with headboard , wood slat , queen"
            }
        ],
        "garOutput": [
            {
                "product_id": 1979,
                "product_name": "adjustable bed frame"
            },
            {
                "product_id": 21235,
                "product_name": "coral rectangular pillow cover & insert"
            },
            {
                "product_id": 21234,
                "product_name": "sweet outdoor rectangular pillow cover & insert"
            },
            {
                "product_id": 23413,
                "product_name": "builders program 17 5/8 '' center to center bar pull"
            },
            {
                "product_id": 11189,
                "product_name": "amjed swivel adjustable height bar stool"
            }
        ],
        "query": "boho bed frame"
    },
    {
        "id": 295,
        "label": [
            {
                "product_id": 1276,
                "product_name": "misael end table"
            },
            {
                "product_id": 33094,
                "product_name": "parente end table with storage"
            },
            {
                "product_id": 32897,
                "product_name": "aahil end table with storage"
            },
            {
                "product_id": 23514,
                "product_name": "indira swivel end table"
            },
            {
                "product_id": 30504,
                "product_name": "chairside end table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 23787,
                "product_name": "derren end table with storage"
            },
            {
                "product_id": 16711,
                "product_name": "hanish end table"
            },
            {
                "product_id": 34425,
                "product_name": "shreffler solid wood end table with storage"
            },
            {
                "product_id": 1963,
                "product_name": "steuben end table with storage"
            },
            {
                "product_id": 24177,
                "product_name": "brockman barrel end table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 31143,
                "product_name": "amilliah tufted upholstered parsons chair"
            },
            {
                "product_id": 30158,
                "product_name": "hershberger buffet table"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            }
        ],
        "garOutput": [
            {
                "product_id": 29241,
                "product_name": "cleotha twin 75 '' wide tufted back convertible sofa"
            },
            {
                "product_id": 31143,
                "product_name": "amilliah tufted upholstered parsons chair"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 38673,
                "product_name": "abdulsamet coffee table"
            },
            {
                "product_id": 20543,
                "product_name": "dowitcher bar with wine storage"
            }
        ],
        "query": "end table between recliners"
    },
    {
        "id": 227,
        "label": [
            {
                "product_id": 11719,
                "product_name": "beom owl of bibliography"
            },
            {
                "product_id": 6438,
                "product_name": "herculaneum ceramic faux grain owl"
            },
            {
                "product_id": 32545,
                "product_name": "owl 36 oz . pitcher"
            },
            {
                "product_id": 25538,
                "product_name": "guillen large owl statue"
            },
            {
                "product_id": 33453,
                "product_name": "manolla owl 24 oz . earthenware teapot"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 42614,
                "product_name": "amirah owl 22 '' table lamp"
            },
            {
                "product_id": 39879,
                "product_name": "owl tree star wall decal"
            },
            {
                "product_id": 24826,
                "product_name": "owl spoon rest"
            },
            {
                "product_id": 16787,
                "product_name": "hanging baby owlets on a branch"
            },
            {
                "product_id": 3730,
                "product_name": "pinecone owl pair figurine"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 1583,
                "product_name": "lulette decorative tin toy pecking chick"
            },
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 15376,
                "product_name": "esschertdesign bumble bee house"
            },
            {
                "product_id": 1584,
                "product_name": "lulette decorative tin toy bird"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            }
        ],
        "garOutput": [
            {
                "product_id": 38567,
                "product_name": "cinnamon certified essential oil"
            },
            {
                "product_id": 2361,
                "product_name": "5 lbs . feeder"
            },
            {
                "product_id": 29811,
                "product_name": "ozeri stone earth all-in-one apeo & pfoa-free 5.3 qt non-stick sauce pan with lid"
            },
            {
                "product_id": 33187,
                "product_name": "snugsoft imperial bed mattress cover"
            },
            {
                "product_id": 14585,
                "product_name": "international large coffee prokeeper 128 oz . food storage container"
            }
        ],
        "query": "owl"
    },
    {
        "id": 409,
        "label": [
            {
                "product_id": 29661,
                "product_name": "friant ergonomic task chair"
            },
            {
                "product_id": 34988,
                "product_name": "magreb velvet upholstered parsons chair in olive"
            },
            {
                "product_id": 9731,
                "product_name": "strine velvet side chair in blue"
            },
            {
                "product_id": 24010,
                "product_name": "bar salon task chair"
            },
            {
                "product_id": 1604,
                "product_name": "lenka 27 '' wide velvet wingback chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 40041,
                "product_name": "montenegro 45 '' wide club chair"
            },
            {
                "product_id": 4308,
                "product_name": "adeline upholstered solid wood dining chair"
            },
            {
                "product_id": 7465,
                "product_name": "hair salon chair"
            },
            {
                "product_id": 34494,
                "product_name": "adrianny task chair"
            },
            {
                "product_id": 6409,
                "product_name": "black leather chair with padded arms"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 38627,
                "product_name": "jurgen stool"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            }
        ],
        "garOutput": [
            {
                "product_id": 24638,
                "product_name": "lovins coffee table"
            },
            {
                "product_id": 7531,
                "product_name": "riser"
            },
            {
                "product_id": 13577,
                "product_name": "turquoise painted poppy indoor/outdoor throw pillow"
            },
            {
                "product_id": 26329,
                "product_name": "longe coffee table with storage"
            },
            {
                "product_id": 32383,
                "product_name": "pull-out side mount belt rack"
            }
        ],
        "query": "teal chair"
    },
    {
        "id": 223,
        "label": [
            {
                "product_id": 1620,
                "product_name": "radiance bellissima 3 3/4 '' center to center arch pull"
            },
            {
                "product_id": 40703,
                "product_name": "river 3 '' center to center bar pull"
            },
            {
                "product_id": 1628,
                "product_name": "river 5 '' center to center bar pull"
            },
            {
                "product_id": 1624,
                "product_name": "long island 3 3/4 '' center to center bar pull"
            },
            {
                "product_id": 1626,
                "product_name": "carraway 3 3/4 '' center to center bar pull"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 1622,
                "product_name": "radiance karlovy 3 3/4 '' center to center arch pull"
            },
            {
                "product_id": 1629,
                "product_name": "river 1 3/16 '' length square knob"
            },
            {
                "product_id": 40704,
                "product_name": "radiance portofino 3 3/4 '' center to center arch pull"
            },
            {
                "product_id": 42583,
                "product_name": "aqui di zen 1 1/2 '' square knob"
            },
            {
                "product_id": 1627,
                "product_name": "river 3 3/4 '' center to center handle bar pull"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 22032,
                "product_name": "dining table with leaf"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 347,
                "product_name": "1.93 '' solar powered outdoor floating light"
            }
        ],
        "garOutput": [
            {
                "product_id": 833,
                "product_name": "polished flame agate freeform from madagascar ( 2.4 lbs )"
            },
            {
                "product_id": 42087,
                "product_name": "yorkshire solar owl 3 piece statue set"
            },
            {
                "product_id": 26392,
                "product_name": "meditation gift set smudge kit"
            },
            {
                "product_id": 13015,
                "product_name": "desktop zen garden with thai buddha figurine"
            },
            {
                "product_id": 1629,
                "product_name": "river 1 3/16 '' length square knob"
            }
        ],
        "query": "wisdom stone river 3-3/4"
    },
    {
        "id": 129,
        "label": [
            {
                "product_id": 12618,
                "product_name": "ashford 24 '' h x 24 '' w x 1/2 '' d molded classic wainscot wall panel"
            },
            {
                "product_id": 12565,
                "product_name": "reeded acanthus 1 3/4 '' h x 94 1/2 '' w x 1 1/8 '' d leaf panel moulding"
            },
            {
                "product_id": 12595,
                "product_name": "dentil 7 '' h x 94 1/2 '' w x 1 5/8 '' d panel moulding"
            },
            {
                "product_id": 12541,
                "product_name": "sequential 1 3/8 '' h x 94 1/2 '' w x 3/8 '' d panel moulding"
            },
            {
                "product_id": 9836,
                "product_name": "high density polyurethane , primed white 3 5/8 '' h x 78 '' w x 7/8 '' d chair rail"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 15112,
                "product_name": "pvc decorative wainscot cap 3 '' h x 96 '' w x 1 '' d moulding"
            },
            {
                "product_id": 12600,
                "product_name": "reeded 1 7/8 '' h x 96 1/8 '' w x 3/4 '' d panel moulding"
            },
            {
                "product_id": 7116,
                "product_name": "ebern designs paris canvas decor eiffel tower framed pictures housewarming decor gifts ideas stylish home decor french wall decor paris wall art eiffel tower modern artwork ready to hang picture"
            },
            {
                "product_id": 12538,
                "product_name": "standard 1 1/8 '' h x 96 '' w x 5/8 '' d panel molding"
            },
            {
                "product_id": 12605,
                "product_name": "ashford 16 '' h x 8 '' w x 1/2 '' d molded classic wainscot wall panel"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 30826,
                "product_name": "belgr concrete propane/natural gas fire pit table"
            },
            {
                "product_id": 11055,
                "product_name": "harcrest writing desk"
            },
            {
                "product_id": 18943,
                "product_name": "sink leg"
            },
            {
                "product_id": 16048,
                "product_name": "flush mount lighting etta 1-light 11 '' semi flush mount"
            },
            {
                "product_id": 6053,
                "product_name": "hot water dispenser"
            }
        ],
        "garOutput": [
            {
                "product_id": 1633,
                "product_name": "bead board 1.6 ft. x 1.6 ft. glue-up styrofoam ceiling tile in white"
            },
            {
                "product_id": 12512,
                "product_name": "pilton 19.7 '' x 19.7 '' vinylwall paneling in white"
            },
            {
                "product_id": 3269,
                "product_name": "richland pineapple whole on basketweave aluminum wall d\u00e9cor"
            },
            {
                "product_id": 3270,
                "product_name": "richland modern pineapple whole cut on basketweave wall d\u00e9cor"
            },
            {
                "product_id": 802,
                "product_name": "wooden spoon metal wall d\u00e9cor"
            }
        ],
        "query": "wainscoting ideas"
    },
    {
        "id": 411,
        "label": [
            {
                "product_id": 32848,
                "product_name": "shaunna wall clock"
            },
            {
                "product_id": 9352,
                "product_name": "thorin 20 '' wall clock"
            },
            {
                "product_id": 34,
                "product_name": "oversized osiris wall clock"
            },
            {
                "product_id": 13318,
                "product_name": "ampere wall clock"
            },
            {
                "product_id": 36739,
                "product_name": "clock wine time with thermometer"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 34094,
                "product_name": "cyndra oversized industrial loft metal 30 '' wall clock"
            },
            {
                "product_id": 15247,
                "product_name": "oversized 36 '' wall clock"
            },
            {
                "product_id": 6245,
                "product_name": "thermometer and hygrometer clock"
            },
            {
                "product_id": 38622,
                "product_name": "isak 6 '' wall clock"
            },
            {
                "product_id": 42529,
                "product_name": "eclectic cloudy wall clock"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 42966,
                "product_name": "mershon 2-light outdoor bulkhead light"
            },
            {
                "product_id": 3728,
                "product_name": "bless our home metal sign"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            }
        ],
        "garOutput": [
            {
                "product_id": 20746,
                "product_name": "10 ft outdoor patio umbrella solar powered led lighted sun shade market waterproof 8 ribs umbrella with crank and cross base for garden deck backyard pool shade outside deck swimming pool -tan"
            },
            {
                "product_id": 38222,
                "product_name": "seatonville 24 '' single bathroom vanity set"
            },
            {
                "product_id": 36029,
                "product_name": "nese velvet task chair"
            },
            {
                "product_id": 25293,
                "product_name": "ocean monthly magnetic calendar board"
            },
            {
                "product_id": 37154,
                "product_name": "alaxis 33 '' wide faux leather power lift assist standard recliner"
            }
        ],
        "query": "outdoor clock"
    },
    {
        "id": 282,
        "label": [
            {
                "product_id": 40935,
                "product_name": "grattan cabinet narrow beveled wall mirror"
            },
            {
                "product_id": 40564,
                "product_name": "burnished beveled accent mirror"
            },
            {
                "product_id": 23515,
                "product_name": "rashedah wall mirror"
            },
            {
                "product_id": 34694,
                "product_name": "beveled ivory/black wall mirror"
            },
            {
                "product_id": 28268,
                "product_name": "zampa beveled wall mirror"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 22516,
                "product_name": "kate and laurel calter large elongated octagon frame wall mirror , 25.5 x 37.5 , modern & contemporary beveled"
            },
            {
                "product_id": 4698,
                "product_name": "bowenvale wall mirror"
            },
            {
                "product_id": 38656,
                "product_name": "anaelle modern & contemporary beveled frameless bathroom mirror"
            },
            {
                "product_id": 26778,
                "product_name": "akeema slim modern beveled venetian wall mirror"
            },
            {
                "product_id": 40516,
                "product_name": "midway tall arched scalloped wall mirror"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 19777,
                "product_name": "swenson rustic distressed vanity mirror"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 13957,
                "product_name": "galeton tv stand for tvs up to 88 ''"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 42966,
                "product_name": "mershon 2-light outdoor bulkhead light"
            }
        ],
        "garOutput": [
            {
                "product_id": 38510,
                "product_name": "lydia 7 drawer dresser"
            },
            {
                "product_id": 26031,
                "product_name": "52 '' w writing desk with hutch"
            },
            {
                "product_id": 1145,
                "product_name": "batisa vanity"
            },
            {
                "product_id": 8919,
                "product_name": "nekoma solid wood vanity"
            },
            {
                "product_id": 21535,
                "product_name": "mulhem modern beveled distressed accent mirror"
            }
        ],
        "query": "grantola wall mirror"
    },
    {
        "id": 322,
        "label": [
            {
                "product_id": 34313,
                "product_name": "gray cotton duvet cover"
            },
            {
                "product_id": 34068,
                "product_name": "adamson geometric maze reversible duvet cover set"
            },
            {
                "product_id": 26969,
                "product_name": "azemmour reversible geometric cotton 7 piece duvet cover set"
            },
            {
                "product_id": 17623,
                "product_name": "borrero duvet cover set"
            },
            {
                "product_id": 10318,
                "product_name": "weather report snow duvet cover set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 11864,
                "product_name": "60s 70s pop art inspired circles vintage design art duvet cover set"
            },
            {
                "product_id": 27838,
                "product_name": "graffiti grunge art wall several creepy underground city urban landscape print duvet cover set"
            },
            {
                "product_id": 13172,
                "product_name": "mountain reversible duvet cover set"
            },
            {
                "product_id": 35013,
                "product_name": "alysa watercolor windflower painted duvet cover set"
            },
            {
                "product_id": 11879,
                "product_name": "pink and grey duvet cover set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 16256,
                "product_name": "vidal ultra soft & plush dinosaurs fleece throw"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 16370,
                "product_name": "stikki clips white 20 per pack"
            },
            {
                "product_id": 27333,
                "product_name": "berengere 4 '' cotton futon mattress"
            },
            {
                "product_id": 27336,
                "product_name": "vintage floral japanese 4 '' cotton futon mattress"
            }
        ],
        "garOutput": [
            {
                "product_id": 2020,
                "product_name": "bigley throw pillow insert"
            },
            {
                "product_id": 5453,
                "product_name": "adra twin daybed with trundle"
            },
            {
                "product_id": 18687,
                "product_name": "bromford border 30 in . x 18 in . non-slip outdoor door mat"
            },
            {
                "product_id": 3472,
                "product_name": "chair pad cushion"
            },
            {
                "product_id": 31031,
                "product_name": "bauder twin or smaller 41.7 '' convertible sofa"
            }
        ],
        "query": "cream queen duvet cover"
    },
    {
        "id": 486,
        "label": [
            {
                "product_id": 196,
                "product_name": "indoor bench cushion"
            },
            {
                "product_id": 27334,
                "product_name": "indoor/outdoor seat cushion"
            },
            {
                "product_id": 9648,
                "product_name": "outdoor seat cushion"
            },
            {
                "product_id": 39174,
                "product_name": "indoor & outdoor bench cushion"
            },
            {
                "product_id": 9633,
                "product_name": "leala texture outdoor seat/back cushion"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 4121,
                "product_name": "indoor/outdoor bench cushion"
            },
            {
                "product_id": 18297,
                "product_name": "spectrum punch indoor/outdoor seat cushion"
            },
            {
                "product_id": 30446,
                "product_name": "outdoor bench cushion"
            },
            {
                "product_id": 30746,
                "product_name": "earleville indoor/outdoor bench cushion"
            },
            {
                "product_id": 30281,
                "product_name": "classic indoor/outdoor bench cushion"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 14106,
                "product_name": "veronica 5 drawer chest"
            },
            {
                "product_id": 37255,
                "product_name": "simple trousers rack"
            },
            {
                "product_id": 22032,
                "product_name": "dining table with leaf"
            },
            {
                "product_id": 26396,
                "product_name": "bahir anti-fatigue mat"
            },
            {
                "product_id": 17737,
                "product_name": "donovan twin daybed with trundle"
            }
        ],
        "garOutput": [
            {
                "product_id": 38719,
                "product_name": "briarhill patio reclining chaise lounge with cushion"
            },
            {
                "product_id": 8416,
                "product_name": "cushioned floor game chair"
            },
            {
                "product_id": 13216,
                "product_name": "34.25 '' plastic square fold-in-half folding table"
            },
            {
                "product_id": 41491,
                "product_name": "kymber round cotton pouf"
            },
            {
                "product_id": 13504,
                "product_name": "teak tripod end table"
            }
        ],
        "query": "54 in bench cushion"
    },
    {
        "id": 284,
        "label": [
            {
                "product_id": 1501,
                "product_name": "aveline 23 '' table lamp"
            },
            {
                "product_id": 42141,
                "product_name": "16 x magnifying glass lamp , 2 in 1 table lamp and table lamp energy-saving led lamp , super bright wheeled fluorescent lamp ( 58w ) adjustable brightness and height , used for reading , hobby , crafts , workbench ( 23-67 inches )"
            },
            {
                "product_id": 6129,
                "product_name": "habib 30.25 '' standard lamp"
            },
            {
                "product_id": 20373,
                "product_name": "azerion 13 '' white lamp"
            },
            {
                "product_id": 9744,
                "product_name": "camacho industrial 72 '' tree floor lamp"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 42608,
                "product_name": "pineapple 23.5 '' table lamp"
            },
            {
                "product_id": 36461,
                "product_name": "aneka 8.25 '' table lamp"
            },
            {
                "product_id": 14368,
                "product_name": "southerland 18 '' table lamp"
            },
            {
                "product_id": 714,
                "product_name": "adelphie 28.5 '' white table lamp"
            },
            {
                "product_id": 9297,
                "product_name": "ashton 30 '' table lamp"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            }
        ],
        "garOutput": [
            {
                "product_id": 33027,
                "product_name": "hiers 1 - light dimmable black armed sconce"
            },
            {
                "product_id": 19127,
                "product_name": "dustin 16.9 '' gold arched table lamp set"
            },
            {
                "product_id": 21345,
                "product_name": "4 watt ( 40 watt equivalent ) , candle led dimmable light bulb , warm white e12/candelabra base"
            },
            {
                "product_id": 21350,
                "product_name": "40 watt equivalent , g16.5 led , dimmable light bulb , warm white ( 2700k ) e12/candelabra base"
            },
            {
                "product_id": 38571,
                "product_name": "italian designed owl essential oil diffuser"
            }
        ],
        "query": "family lamp"
    },
    {
        "id": 347,
        "label": [
            {
                "product_id": 36184,
                "product_name": "mcmillian console table"
            },
            {
                "product_id": 36826,
                "product_name": "dartagnon 43 '' console table"
            },
            {
                "product_id": 32358,
                "product_name": "damion crystal console table"
            },
            {
                "product_id": 5260,
                "product_name": "ediline wooden console table"
            },
            {
                "product_id": 19644,
                "product_name": "artisan console table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 4935,
                "product_name": "sanaa 16 '' console table"
            },
            {
                "product_id": 2657,
                "product_name": "backadine 36 '' console table"
            },
            {
                "product_id": 7163,
                "product_name": "broughton console table"
            },
            {
                "product_id": 1724,
                "product_name": "soule 42 '' console table"
            },
            {
                "product_id": 4934,
                "product_name": "tullamore 12 '' console table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 30158,
                "product_name": "hershberger buffet table"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 29341,
                "product_name": "magdaleno pub dining table"
            },
            {
                "product_id": 3235,
                "product_name": "orndorff 44 '' wide tufted papasan chair"
            },
            {
                "product_id": 14142,
                "product_name": "creeve extendable dining table"
            },
            {
                "product_id": 19898,
                "product_name": "aashay coffee table"
            },
            {
                "product_id": 19899,
                "product_name": "aashdon coffee table"
            }
        ],
        "query": "olive green console table"
    },
    {
        "id": 476,
        "label": [
            {
                "product_id": 8381,
                "product_name": "abril tufted solid wood and upholstered low profile platform bed"
            },
            {
                "product_id": 18384,
                "product_name": "boden tufted upholstered standard bed"
            },
            {
                "product_id": 32201,
                "product_name": "schroeder upholstered platform bed"
            },
            {
                "product_id": 39315,
                "product_name": "tufted upholstered low profile storage platform bed"
            },
            {
                "product_id": 9884,
                "product_name": "hollenbeck king tufted low profile standard bed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 29559,
                "product_name": "horsley upholstered storage platform bed"
            },
            {
                "product_id": 14699,
                "product_name": "breazeale bed frame"
            },
            {
                "product_id": 14353,
                "product_name": "delphis queen tufted upholstered low profile standard bed"
            },
            {
                "product_id": 9621,
                "product_name": "nick jr. paw toddler bed"
            },
            {
                "product_id": 27661,
                "product_name": "abrianna upholstered low profile storage platform bed"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 12350,
                "product_name": "rossmere indoor/outdoor bench cushion"
            }
        ],
        "garOutput": [
            {
                "product_id": 13577,
                "product_name": "turquoise painted poppy indoor/outdoor throw pillow"
            },
            {
                "product_id": 37258,
                "product_name": "elborough geometric yellow rug"
            },
            {
                "product_id": 33124,
                "product_name": "5 drawer chest"
            },
            {
                "product_id": 33123,
                "product_name": "wide storage 5 drawer dresser"
            },
            {
                "product_id": 6411,
                "product_name": "preside base"
            }
        ],
        "query": "hardwood beds"
    },
    {
        "id": 359,
        "label": [
            {
                "product_id": 12543,
                "product_name": "springtime 11 3/8 '' h x 96 '' w x 8 1/8 '' d crown moulding"
            },
            {
                "product_id": 16200,
                "product_name": "modern glass 36 '' energy star french door 27.8 cu . ft. smart refrigerator"
            },
            {
                "product_id": 12638,
                "product_name": "raymond 7 3/8 '' h x 96 '' w x 9 1/8 '' d crown molding"
            },
            {
                "product_id": 30699,
                "product_name": "ivory french pattern tumbled 16 '' x 24 '' travertine random mosaic wall & floor tile"
            },
            {
                "product_id": 7116,
                "product_name": "ebern designs paris canvas decor eiffel tower framed pictures housewarming decor gifts ideas stylish home decor french wall decor paris wall art eiffel tower modern artwork ready to hang picture"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 15120,
                "product_name": "pvc casing 3 1/2 '' h x 96 '' w x 5/8 '' d moulding"
            },
            {
                "product_id": 3450,
                "product_name": "800 series 21 cu . ft. smart energy star counter depth french door refrigerator with flexbar"
            },
            {
                "product_id": 15113,
                "product_name": "pvc 2 7/8 '' h x 96 '' w x 1/2 '' d moulding"
            },
            {
                "product_id": 22648,
                "product_name": "36 '' french door 26.1 cu . ft. smart energy star refrigerator with interior ice maker"
            },
            {
                "product_id": 16123,
                "product_name": "ge profile smart appliances 36 '' french door 27.8 cu . ft. smart energy star refrigerator with hands-free autofill"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 19247,
                "product_name": "clayburgh arm chair in clear"
            },
            {
                "product_id": 9128,
                "product_name": "abstract power loom wool/polyester red area rug"
            },
            {
                "product_id": 27320,
                "product_name": "0.75 '' x 0.75 '' seashell tile"
            },
            {
                "product_id": 32559,
                "product_name": "grid mirror"
            },
            {
                "product_id": 24263,
                "product_name": "lund solid wood corner tv stand for tvs up to 70 ''"
            }
        ],
        "garOutput": [
            {
                "product_id": 9996,
                "product_name": "colorado 36 '' x 24 '' metal wall paneling"
            },
            {
                "product_id": 29927,
                "product_name": "birch fleur-de-lis 3 '' h x 2.75 '' w x 0.22 '' d onlay scroll"
            },
            {
                "product_id": 24025,
                "product_name": "french ribbon 1 5/8 '' h x 94 1/2 '' w x 3/4 '' d chair rail"
            },
            {
                "product_id": 24027,
                "product_name": "plain 3 1/2 '' h x 96 '' w x 1 '' d chair rail"
            },
            {
                "product_id": 24026,
                "product_name": "decorative 7 7/8 '' h x 96 '' w x 1 '' d frieze molding"
            }
        ],
        "query": "french molding"
    },
    {
        "id": 433,
        "label": [
            {
                "product_id": 42564,
                "product_name": "corringham canopy standard bed"
            },
            {
                "product_id": 26026,
                "product_name": "arval trundle unit"
            },
            {
                "product_id": 4760,
                "product_name": "alyciana daybed"
            },
            {
                "product_id": 6236,
                "product_name": "momence daybed with trundle"
            },
            {
                "product_id": 16791,
                "product_name": "burkett twin daybed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 15591,
                "product_name": "paniz daybed"
            },
            {
                "product_id": 35120,
                "product_name": "tiara twin daybed with trundle"
            },
            {
                "product_id": 10824,
                "product_name": "mcadams daybed"
            },
            {
                "product_id": 22942,
                "product_name": "tensho-kan daybed"
            },
            {
                "product_id": 17737,
                "product_name": "donovan twin daybed with trundle"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 26641,
                "product_name": "melamine horses paint serving tray"
            },
            {
                "product_id": 41246,
                "product_name": "rae dunn stem print 3.5 qt . flour canister"
            },
            {
                "product_id": 25455,
                "product_name": "nordic ware santa \u2019 s sleigh non-stick loaf pan"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 14379,
                "product_name": "shoe rack , 5-tier shoe storage unit flat & slant adjustable shoe organizer shelf for 16 pairs , durable and stable , for entryway , hallway , closet , dorm room , industrial , rustic brown"
            }
        ],
        "garOutput": [
            {
                "product_id": 20622,
                "product_name": "34.5 '' steel bed frame"
            },
            {
                "product_id": 34540,
                "product_name": "platform bed with adjustable trundle , gray , twin size"
            },
            {
                "product_id": 32253,
                "product_name": "dovenby daybed with trundle"
            },
            {
                "product_id": 18612,
                "product_name": "nowakowski twin bed with trundle"
            },
            {
                "product_id": 18847,
                "product_name": "cross frame captains daybed with drawers and trundle"
            }
        ],
        "query": "trundle daybed"
    },
    {
        "id": 112,
        "label": [
            {
                "product_id": 32719,
                "product_name": "alderete pineapple"
            },
            {
                "product_id": 32196,
                "product_name": "navi pineapple 17 oz . stemless wine glass"
            },
            {
                "product_id": 9806,
                "product_name": "2 piece natural fiber pineapple set"
            },
            {
                "product_id": 3265,
                "product_name": "silver glass stem pineapple"
            },
            {
                "product_id": 8026,
                "product_name": "tebikerei pineapple personalized polyester 18 x 12 in . garden flag"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 25976,
                "product_name": "hospitality pineapple door knocker"
            },
            {
                "product_id": 12024,
                "product_name": "pineapples 18.5 '' placemat"
            },
            {
                "product_id": 12909,
                "product_name": "6 piece decorative pineapple set"
            },
            {
                "product_id": 40866,
                "product_name": "sinkler pineapple sculpture"
            },
            {
                "product_id": 5758,
                "product_name": "pineapple 1-gang toggle light switch wall plate"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 13247,
                "product_name": "square multi-tiered plant stand"
            },
            {
                "product_id": 15086,
                "product_name": "napoleon cake knife"
            },
            {
                "product_id": 1179,
                "product_name": "real touch bouquet peonies stems"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 11544,
                "product_name": "cotner mango"
            }
        ],
        "garOutput": [
            {
                "product_id": 18183,
                "product_name": "hamilton beach 6 qt . stay or go programmable flexcook slow cooker"
            },
            {
                "product_id": 18749,
                "product_name": "chef 3 piece kitchen canister set"
            },
            {
                "product_id": 18750,
                "product_name": "two handled chalkboard kitchen canister"
            },
            {
                "product_id": 18751,
                "product_name": "84 qt . coffee jar"
            },
            {
                "product_id": 18748,
                "product_name": "faceted 3 piece kitchen canister set"
            }
        ],
        "query": "pineapple"
    },
    {
        "id": 180,
        "label": [
            {
                "product_id": 35929,
                "product_name": "vlachavas outdoor patio sofa with cushions"
            },
            {
                "product_id": 7684,
                "product_name": "daiyaan patio reclining chaise lounge with cushion"
            },
            {
                "product_id": 9626,
                "product_name": "jade texture outdoor seat cushion"
            },
            {
                "product_id": 9629,
                "product_name": "adelia texture outdoor chaise lounge cushion"
            },
            {
                "product_id": 7618,
                "product_name": "ivy bronx foldable patio rattan chaise lounge chair w/5 back positions white cushion"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 30761,
                "product_name": "osian outdoor indoor/outdoor bench cushion"
            },
            {
                "product_id": 32189,
                "product_name": "montford 76.55 '' wide outdoor teak patio sofa with cushions"
            },
            {
                "product_id": 30764,
                "product_name": "barbay lounge chair cushion"
            },
            {
                "product_id": 28874,
                "product_name": "v-shaped sectional sofa cover , outdoor waterproof patio furniture cover , heavy duty patio furniture lounge set cover"
            },
            {
                "product_id": 21931,
                "product_name": "achaz patio reclining chaise lounge with cushion"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 12350,
                "product_name": "rossmere indoor/outdoor bench cushion"
            },
            {
                "product_id": 38673,
                "product_name": "abdulsamet coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 3472,
                "product_name": "chair pad cushion"
            },
            {
                "product_id": 21235,
                "product_name": "coral rectangular pillow cover & insert"
            },
            {
                "product_id": 21234,
                "product_name": "sweet outdoor rectangular pillow cover & insert"
            },
            {
                "product_id": 35611,
                "product_name": "outdoor replacement cushion"
            },
            {
                "product_id": 41028,
                "product_name": "kathrine cobblestone decorative lumbar pillow"
            }
        ],
        "query": "outdoor lounge cushions"
    },
    {
        "id": 301,
        "label": [
            {
                "product_id": 23385,
                "product_name": "francisco 3 '' center to center bar pull multipack"
            },
            {
                "product_id": 39234,
                "product_name": "wayfair basics\u00ae cabinet 5 1/16 '' center bar pull multipack"
            },
            {
                "product_id": 20701,
                "product_name": "sullivan cabinet 3 3/4 '' center to center bar pull"
            },
            {
                "product_id": 35842,
                "product_name": "sanctuary half circle open 3 1/2 '' center to center arch pull"
            },
            {
                "product_id": 18951,
                "product_name": "cast iron cabinet center to center drawer 3 1/4 '' center to center bin pull"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 35869,
                "product_name": "channing 3 3/4 '' center to center cup pull"
            },
            {
                "product_id": 23412,
                "product_name": "mission cabinet 2 1/2 '' center to center arch pull"
            },
            {
                "product_id": 13422,
                "product_name": "cabinet door handles drawer 6 4/5 '' center bar pull multipack"
            },
            {
                "product_id": 18955,
                "product_name": "cabinet center to center or drawer 2 3/4 '' center to center bin pull"
            },
            {
                "product_id": 12742,
                "product_name": "thatcher cabinet 3 '' center to center bar pull"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 9624,
                "product_name": "military challenge coin cabinet rack holder display case"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            },
            {
                "product_id": 21200,
                "product_name": "cutts bar with wine storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 7167,
                "product_name": "abbottsmoor dining chair cushion"
            },
            {
                "product_id": 11321,
                "product_name": "2 piece spoon and fork metal wall d\u00e9cor set"
            },
            {
                "product_id": 1620,
                "product_name": "radiance bellissima 3 3/4 '' center to center arch pull"
            },
            {
                "product_id": 9219,
                "product_name": "rounds ball shower curtain hooks"
            },
            {
                "product_id": 25857,
                "product_name": "petrick canine camper sportable tent pet soft-sided pet crate"
            }
        ],
        "query": "circle cabinet pulls"
    },
    {
        "id": 360,
        "label": [
            {
                "product_id": 39437,
                "product_name": "aleiny outdoor patio 2 piece rattan sectional sofa set with cushions"
            },
            {
                "product_id": 29398,
                "product_name": "martello 101 '' wide velvet left hand facing sofa & chaise"
            },
            {
                "product_id": 30337,
                "product_name": "river 4 pieces rattan patio furniture set wicker sofa cushioned sectional furniture set garden patio sofa set"
            },
            {
                "product_id": 25080,
                "product_name": "newberg 92 '' wide right hand facing modular sofa & chaise with ottoman"
            },
            {
                "product_id": 40139,
                "product_name": "roughton 103 '' wide sofa & chaise with ottoman"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 10835,
                "product_name": "shelton 83 '' wide velvet reversible sleeper sofa & chaise"
            },
            {
                "product_id": 23968,
                "product_name": "jemima 115 '' wide velvet reversible sleeper sofa & chaise"
            },
            {
                "product_id": 28645,
                "product_name": "samual 123.5 '' wide reversible modular corner sectional with ottoman"
            },
            {
                "product_id": 9863,
                "product_name": "dansk 7-piece modular outdoor conversational furniture set , wicker sectional sofas w/ cover"
            },
            {
                "product_id": 24070,
                "product_name": "abdul-rafay 99 '' wide microfiber/microsuede right hand facing sleeper sofa & chaise"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 14229,
                "product_name": "homestead dining table"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            }
        ],
        "garOutput": [
            {
                "product_id": 42002,
                "product_name": "21 pair stackable shoe rack"
            },
            {
                "product_id": 20706,
                "product_name": "5 tier steel 50 pair shoe rack"
            },
            {
                "product_id": 35611,
                "product_name": "outdoor replacement cushion"
            },
            {
                "product_id": 16539,
                "product_name": "kleinschmidt 3 piece coffee table set"
            },
            {
                "product_id": 7896,
                "product_name": "cendrillon 2 - shelf storage cabinet"
            }
        ],
        "query": "huntsville sectional sofa"
    },
    {
        "id": 323,
        "label": [
            {
                "product_id": 5860,
                "product_name": "aatif modern & contemporary full length mirror"
            },
            {
                "product_id": 38870,
                "product_name": "aoto contemporary wall mirror"
            },
            {
                "product_id": 30057,
                "product_name": "ajhar aluminum alloy full length mirror"
            },
            {
                "product_id": 33106,
                "product_name": "mohsin full screen wall mounted jewelry armoire with mirror"
            },
            {
                "product_id": 40944,
                "product_name": "petronia on the door full length mirror"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 40936,
                "product_name": "barossa on the door full length mirror"
            },
            {
                "product_id": 13425,
                "product_name": "lagasse rectangular beveled full length mirror"
            },
            {
                "product_id": 27778,
                "product_name": "abingdon modern and contemporary full length mirror"
            },
            {
                "product_id": 25886,
                "product_name": "kenner full length mirror"
            },
            {
                "product_id": 40939,
                "product_name": "toader on the door full length mirror"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 37730,
                "product_name": "castaner 24 '' single bathroom vanity set"
            },
            {
                "product_id": 17737,
                "product_name": "donovan twin daybed with trundle"
            },
            {
                "product_id": 37976,
                "product_name": "argenteuil , 1875 by claude monet painting print on wrapped canvas"
            },
            {
                "product_id": 22032,
                "product_name": "dining table with leaf"
            }
        ],
        "garOutput": [
            {
                "product_id": 37613,
                "product_name": "hub leaning full length mirror"
            },
            {
                "product_id": 24390,
                "product_name": "fryman brushed classic rustic distressed wall mirror"
            },
            {
                "product_id": 30054,
                "product_name": "ayrika full length mirror"
            },
            {
                "product_id": 15760,
                "product_name": "elevate bathroom / vanity mirror"
            },
            {
                "product_id": 25950,
                "product_name": "abernethy sailboat single curtain panel"
            }
        ],
        "query": "hub leaning full length mirror"
    },
    {
        "id": 468,
        "label": [
            {
                "product_id": 13332,
                "product_name": "bilbao wall clock"
            },
            {
                "product_id": 34549,
                "product_name": "high visibility led wall clock"
            },
            {
                "product_id": 34557,
                "product_name": "digital frame desktop wall clock"
            },
            {
                "product_id": 13278,
                "product_name": "landan wall clock"
            },
            {
                "product_id": 26240,
                "product_name": "jaylen 21.65 '' wall clock"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 11768,
                "product_name": "stained glass floral art wall clock"
            },
            {
                "product_id": 11148,
                "product_name": "14 '' wall clock"
            },
            {
                "product_id": 15240,
                "product_name": "oversized gilded gears 24 '' wall clock"
            },
            {
                "product_id": 37131,
                "product_name": "all weather 13 '' wall clock"
            },
            {
                "product_id": 13309,
                "product_name": "tiptree wall clock"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 3728,
                "product_name": "bless our home metal sign"
            },
            {
                "product_id": 20718,
                "product_name": "abramowski monitor stand"
            },
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 10565,
                "product_name": "askewville i love it when my wife lets me ride my dirt bike funny partner design coffee mug"
            },
            {
                "product_id": 10566,
                "product_name": "libourne funny owl drink to that owl drinking wine pun cartoon coffee mug"
            }
        ],
        "garOutput": [
            {
                "product_id": 23556,
                "product_name": "starrett 7 '' wall clock"
            },
            {
                "product_id": 34549,
                "product_name": "high visibility led wall clock"
            },
            {
                "product_id": 33961,
                "product_name": "oversized attapulgus diy creative design 32 '' wall clock"
            },
            {
                "product_id": 31711,
                "product_name": "shelia wall clock"
            },
            {
                "product_id": 24142,
                "product_name": "hackett 11 '' wall clock"
            }
        ],
        "query": "glow in the dark silent wall clock"
    },
    {
        "id": 352,
        "label": [
            {
                "product_id": 15851,
                "product_name": "amarillo ii by megan meagher painting print on canvas"
            },
            {
                "product_id": 36488,
                "product_name": "amarillo coffee table with storage"
            },
            {
                "product_id": 6744,
                "product_name": "amarillo - print on wood"
            },
            {
                "product_id": 30507,
                "product_name": "amarillo stair tread"
            },
            {
                "product_id": 30349,
                "product_name": "amarillo 5.5 '' saucer"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 8883,
                "product_name": "amarillo armoire"
            },
            {
                "product_id": 30346,
                "product_name": "polish pottery amarillo coffee mug with saucer"
            },
            {
                "product_id": 41117,
                "product_name": "amarillo rose wood and cast iron park bench"
            },
            {
                "product_id": 36958,
                "product_name": "amarillo 65.5 torchiere floor lamp"
            },
            {
                "product_id": 17656,
                "product_name": "amarillo 3 - light sputnik modern linear chandelier"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 17343,
                "product_name": "maier north carolina melamine tray"
            },
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            }
        ],
        "garOutput": [
            {
                "product_id": 13872,
                "product_name": "estey organ company graphic art"
            },
            {
                "product_id": 38567,
                "product_name": "cinnamon certified essential oil"
            },
            {
                "product_id": 29706,
                "product_name": "outdoor 2-tier 9.8 ft. w x 13 ft. d steel patio gazebo"
            },
            {
                "product_id": 573,
                "product_name": "5 - hook freestanding coat rack"
            },
            {
                "product_id": 38573,
                "product_name": "copaiba certified essential oil"
            }
        ],
        "query": "amarillo"
    },
    {
        "id": 237,
        "label": [
            {
                "product_id": 18419,
                "product_name": "cobden 107 '' wide left hand facing modular sectional"
            },
            {
                "product_id": 34865,
                "product_name": "120 '' wide left hand facing modular corner sectional"
            },
            {
                "product_id": 7687,
                "product_name": "steinway 103 '' wide sofa & chaise"
            },
            {
                "product_id": 42080,
                "product_name": "al\u00eddio 132 '' wide microfiber/microsuede left hand facing modular sectional with ottoman"
            },
            {
                "product_id": 8874,
                "product_name": "ewall 130 '' wide chenille left hand facing corner sectional"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 18967,
                "product_name": "chelsea 120 '' wide symmetrical modular corner sectional"
            },
            {
                "product_id": 24071,
                "product_name": "abdul-raheem 98 '' wide microfiber/microsuede symmetrical modular corner sectional with ottoman"
            },
            {
                "product_id": 36811,
                "product_name": "monarrez 150 '' wide symmetrical modular corner sectional"
            },
            {
                "product_id": 9673,
                "product_name": "liguria 146.5 '' wide reversible modular sofa & chaise"
            },
            {
                "product_id": 42082,
                "product_name": "alberdina 146.5 '' wide modular sectional"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 9766,
                "product_name": "ferrigno 6 piece bathroom accessory set"
            },
            {
                "product_id": 29041,
                "product_name": "gayler small office for home desk"
            },
            {
                "product_id": 347,
                "product_name": "1.93 '' solar powered outdoor floating light"
            },
            {
                "product_id": 17737,
                "product_name": "donovan twin daybed with trundle"
            },
            {
                "product_id": 208,
                "product_name": "tekin 2 - drawer nightstand in light brown"
            }
        ],
        "garOutput": [
            {
                "product_id": 7020,
                "product_name": "breagh twin platform bed"
            },
            {
                "product_id": 36962,
                "product_name": "meza 39.5 '' solid wood console table"
            },
            {
                "product_id": 26307,
                "product_name": "boughton 57.87 '' console table"
            },
            {
                "product_id": 36961,
                "product_name": "westcott console table"
            },
            {
                "product_id": 7014,
                "product_name": "asmahan twin platform bed"
            }
        ],
        "query": "cloud modular sectional"
    },
    {
        "id": 379,
        "label": [
            {
                "product_id": 34313,
                "product_name": "gray cotton duvet cover"
            },
            {
                "product_id": 11752,
                "product_name": "duvet cover set"
            },
            {
                "product_id": 11804,
                "product_name": "minimalist iii duvet cover set"
            },
            {
                "product_id": 27846,
                "product_name": "2 piece duvet cover set"
            },
            {
                "product_id": 13183,
                "product_name": "reversible abstract duvet cover"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 11681,
                "product_name": "woltjen duvet cover set"
            },
            {
                "product_id": 11765,
                "product_name": "white floral 2 piece duvet cover set"
            },
            {
                "product_id": 25079,
                "product_name": "malmberg single duvet cover set"
            },
            {
                "product_id": 38312,
                "product_name": "single reversible duvet cover"
            },
            {
                "product_id": 11764,
                "product_name": "nature modern and contemporary duvet cover set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 347,
                "product_name": "1.93 '' solar powered outdoor floating light"
            },
            {
                "product_id": 11850,
                "product_name": "pi creative art where the passion flower grows i single shower curtain"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            }
        ],
        "garOutput": [
            {
                "product_id": 4553,
                "product_name": "modu-licious chest"
            },
            {
                "product_id": 32380,
                "product_name": "deep pull-out 18 '' w x 7 '' h drawer"
            },
            {
                "product_id": 32381,
                "product_name": "deep pull-out 18 '' w x 11 '' h drawer"
            },
            {
                "product_id": 31417,
                "product_name": "dedrick modern vintage map single duvet cover"
            },
            {
                "product_id": 32383,
                "product_name": "pull-out side mount belt rack"
            }
        ],
        "query": "urban outfitters duvet"
    },
    {
        "id": 165,
        "label": [
            {
                "product_id": 9670,
                "product_name": "arroyo-munoz 54.5 '' h x 13.75 '' w x 13 '' d storage shelf"
            },
            {
                "product_id": 5934,
                "product_name": "brayson 26 '' h x 23 '' w x 10 '' d shelving unit"
            },
            {
                "product_id": 35880,
                "product_name": "talavera 63.38 '' h x 27.95 '' w x 13.18 '' d folding storage shelving unit"
            },
            {
                "product_id": 7559,
                "product_name": "shelftrack 60 '' h x 48 '' w x 12 '' d storage rack"
            },
            {
                "product_id": 4759,
                "product_name": "kitchen pantry cabinet with 6 adjustable shelves"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 3783,
                "product_name": "large plastic kitchen saver 3 container food storage set"
            },
            {
                "product_id": 18918,
                "product_name": "72 '' h x 48 '' w x 18 '' d rivet 5 shelf shelving unit starter"
            },
            {
                "product_id": 37643,
                "product_name": "3-tier mesh wire basket rolling cart kitchen storage cart with wheels shelving"
            },
            {
                "product_id": 31508,
                "product_name": "fulton kitchen cart with stainless steel top"
            },
            {
                "product_id": 6573,
                "product_name": "ez2 82.5 '' h x 36.5 '' w x 25 '' d shelving unit"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 36032,
                "product_name": "deeksha storage cabinet"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            },
            {
                "product_id": 8741,
                "product_name": "hassan free form multi-tiered plant stand"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 39877,
                "product_name": "wilmington steelwares 12 piece assorted kitchen utensil set"
            },
            {
                "product_id": 31886,
                "product_name": "pyrex easy grab 1.5 qt . loaf dish"
            },
            {
                "product_id": 31888,
                "product_name": "prepware 6 oz . dessert bowl"
            },
            {
                "product_id": 31887,
                "product_name": "pyrex easy grab 1.5 qt . loaf dish with plastic cover"
            },
            {
                "product_id": 36903,
                "product_name": "marlena d\u00e9cor wall shelf"
            }
        ],
        "query": "kitchen storage shelves"
    },
    {
        "id": 136,
        "label": [
            {
                "product_id": 38923,
                "product_name": "troutville adjustable height swivel bar stool"
            },
            {
                "product_id": 22020,
                "product_name": "sariyah swivel bar & counter stool"
            },
            {
                "product_id": 18467,
                "product_name": "bogue stool"
            },
            {
                "product_id": 7940,
                "product_name": "alesia stool"
            },
            {
                "product_id": 1955,
                "product_name": "powe solid wood bar & counter stool"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 1951,
                "product_name": "mccauley bar & counter stool"
            },
            {
                "product_id": 15882,
                "product_name": "wellings stool"
            },
            {
                "product_id": 22356,
                "product_name": "iberville stool"
            },
            {
                "product_id": 2626,
                "product_name": "hedgerley stool"
            },
            {
                "product_id": 9272,
                "product_name": "greer 29 '' swivel bar stool"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 25557,
                "product_name": "connolly tufted upholstered low profile standard bed"
            },
            {
                "product_id": 38627,
                "product_name": "jurgen stool"
            },
            {
                "product_id": 32281,
                "product_name": "metal slat back stacking side chair"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 32008,
                "product_name": "allersoft allergy relief hypoallergenic mattress protector set"
            },
            {
                "product_id": 33670,
                "product_name": "gildan computer riser 2-tier wood monitor stand"
            },
            {
                "product_id": 37885,
                "product_name": "qasim cement girl statue"
            },
            {
                "product_id": 31308,
                "product_name": "aeva-louise anti-fatigue mat"
            },
            {
                "product_id": 33124,
                "product_name": "5 drawer chest"
            }
        ],
        "query": "black fluffy stool"
    },
    {
        "id": 200,
        "label": [
            {
                "product_id": 12864,
                "product_name": "edredon 12 '' x 12 '' ceramic stone look wall & floor tile"
            },
            {
                "product_id": 27069,
                "product_name": "napa 12 '' x 24 '' ceramic stone look wall & floor tile"
            },
            {
                "product_id": 12791,
                "product_name": "royalty floret 18 '' x 18 '' ceramic patterned wall & floor tile"
            },
            {
                "product_id": 26987,
                "product_name": "squares 1 '' x 1 '' glass mosaic tile in glossy blue/green"
            },
            {
                "product_id": 27190,
                "product_name": "portico herringbone 1 '' x 3 '' ceramic mosaic tile in pearl/beige"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 35468,
                "product_name": "seashell 1 '' x 1 '' mosaic tile in white"
            },
            {
                "product_id": 15106,
                "product_name": "motif 4 '' x 12 '' beveled ceramic patterned tile"
            },
            {
                "product_id": 21920,
                "product_name": "mediterranean 4 '' x 4 '' ceramic peacocks decorative tile in blue"
            },
            {
                "product_id": 12880,
                "product_name": "esamo 1.74 '' x 1.88 '' glass fish scale mosaic wall & floor tile"
            },
            {
                "product_id": 27081,
                "product_name": "gridscale 12 '' x 24 '' ceramic concrete look wall & floor tile"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 27320,
                "product_name": "0.75 '' x 0.75 '' seashell tile"
            },
            {
                "product_id": 7839,
                "product_name": "citadel sunflower fields cheese board"
            },
            {
                "product_id": 12350,
                "product_name": "rossmere indoor/outdoor bench cushion"
            },
            {
                "product_id": 32547,
                "product_name": "serving platter"
            },
            {
                "product_id": 908,
                "product_name": "chauncy rooster weathervane on wood base statue"
            }
        ],
        "garOutput": [
            {
                "product_id": 33791,
                "product_name": "florid lantern 6.25 '' x 7.25 '' straight edge ceramic singular subway tile"
            },
            {
                "product_id": 33839,
                "product_name": "siene 5.2 '' x 11 '' ceramic subway tile"
            },
            {
                "product_id": 33844,
                "product_name": "countryside sliced round 1 '' x 2 '' natural stone pebble tile"
            },
            {
                "product_id": 33807,
                "product_name": "2 '' x 8 '' marble subway tile in travertine"
            },
            {
                "product_id": 13974,
                "product_name": "classic beaded 1-gang toggle light switch wall plate"
            }
        ],
        "query": "ceramic tile sea shell"
    },
    {
        "id": 262,
        "label": [
            {
                "product_id": 8490,
                "product_name": "tyann 23.5 '' w x 31.5 '' h x 12 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 14324,
                "product_name": "lucee 9 '' w x 31.5 '' h x 9 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 30913,
                "product_name": "helen 15.75 '' w x 29.25 '' h x 11.75 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 32342,
                "product_name": "nina 22.88 '' w x 19.88 '' h x 7.88 '' d wall mounted bathroom cabinet"
            },
            {
                "product_id": 17614,
                "product_name": "freestanding storage cabinet , bathroom slim tower cabinet , narrow tall cabinet with doors and adjustable shelves for living room , kitchen , entrance , bedroom , oak color"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 32344,
                "product_name": "nina 15.75 '' w x 35.5 '' h x 11.88 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 32343,
                "product_name": "nina 23.63 '' w x 27.56 '' h x 11.81 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 14694,
                "product_name": "macarthur 17 '' w x 30.25 '' h x 13.5 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 7595,
                "product_name": "amany 12 '' w x 31.5 '' h x 12 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 14204,
                "product_name": "carlisle 25 '' w x 30 '' h wall mounted cabinet"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 25869,
                "product_name": "42 '' single bathroom vanity"
            },
            {
                "product_id": 5959,
                "product_name": "marple 42 '' wide 2 drawer server"
            },
            {
                "product_id": 3197,
                "product_name": "double shower curtain hooks"
            },
            {
                "product_id": 19717,
                "product_name": "commercial single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 36032,
                "product_name": "deeksha storage cabinet"
            }
        ],
        "garOutput": [
            {
                "product_id": 22626,
                "product_name": "66 '' h x 27 '' w x 15 '' d tall utility storage cabinet"
            },
            {
                "product_id": 20625,
                "product_name": "rolling storage cart"
            },
            {
                "product_id": 7261,
                "product_name": "trower 34.3 '' h x 27.9 '' w x 13.6 '' d foldable storage shelves"
            },
            {
                "product_id": 2404,
                "product_name": "wayfair basics\u00ae springboro 74 '' h x 36 '' w x 15 '' d storage cabinet"
            },
            {
                "product_id": 1614,
                "product_name": "bulwell 23.5 '' w x 31.5 '' h x 12 '' d free-standing bathroom cabinet"
            }
        ],
        "query": "bathroom freestanding cabinet"
    },
    {
        "id": 236,
        "label": [
            {
                "product_id": 26186,
                "product_name": "brandt floral beige/gray area rug"
            },
            {
                "product_id": 38131,
                "product_name": "runner agoritsa geometric gray area rug"
            },
            {
                "product_id": 40114,
                "product_name": "cedric abstract navy blue area rug set"
            },
            {
                "product_id": 4663,
                "product_name": "mclea abstract brown/gray area rug"
            },
            {
                "product_id": 9952,
                "product_name": "rug shape alapaha floral ivory area rug set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 34554,
                "product_name": "shawnna 3 piece bath rug set"
            },
            {
                "product_id": 4097,
                "product_name": "katie damask cream/light gray area rug"
            },
            {
                "product_id": 12426,
                "product_name": "burslem rectangle memory foam non-slip 2 piece bath rug set"
            },
            {
                "product_id": 23889,
                "product_name": "radley flatweave ocean/champagne abstract indoor/outdoor area rug"
            },
            {
                "product_id": 26906,
                "product_name": "mcalmont pink area rug"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 37189,
                "product_name": "metal bed with wood decoration \uff08twin size\uff09"
            },
            {
                "product_id": 12350,
                "product_name": "rossmere indoor/outdoor bench cushion"
            },
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            },
            {
                "product_id": 908,
                "product_name": "chauncy rooster weathervane on wood base statue"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 40113,
                "product_name": "cedric oriental ebony area rug set"
            },
            {
                "product_id": 35223,
                "product_name": "audrey 3 piece geometric brown/moss area rug set"
            },
            {
                "product_id": 26919,
                "product_name": "hillsby oriental polypropylene navy area rug"
            },
            {
                "product_id": 4028,
                "product_name": "easterling chair pad cushion"
            },
            {
                "product_id": 7243,
                "product_name": "gosim embossed rectangle memory foam non-slip 2 piece bath rug set"
            }
        ],
        "query": "3 piece rug set with runners"
    },
    {
        "id": 115,
        "label": [
            {
                "product_id": 41075,
                "product_name": "standing metal peacock statue"
            },
            {
                "product_id": 43,
                "product_name": "resa majolica style peacock table vase"
            },
            {
                "product_id": 36609,
                "product_name": "art deco peacock sculptural 29 '' torchiere lamp set"
            },
            {
                "product_id": 5231,
                "product_name": "white peacock on black - print on canvas"
            },
            {
                "product_id": 18663,
                "product_name": "elledge peacock sidelight window decals"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 27497,
                "product_name": "hofmann peacock umbrella stand"
            },
            {
                "product_id": 11739,
                "product_name": "amy-jo iridescent peacock with beautiful train feathers figurine"
            },
            {
                "product_id": 10613,
                "product_name": "peacock feather 1-gang toggle light switch wall plate"
            },
            {
                "product_id": 21920,
                "product_name": "mediterranean 4 '' x 4 '' ceramic peacocks decorative tile in blue"
            },
            {
                "product_id": 6660,
                "product_name": "handpainted anemones and peacock feathers floral semi-sheer thermal rod pocket single curtain panel"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            }
        ],
        "garOutput": [
            {
                "product_id": 2697,
                "product_name": "art glass rooster figurine"
            },
            {
                "product_id": 25478,
                "product_name": "gunnell calla lily tree ceramic sculpture"
            },
            {
                "product_id": 29053,
                "product_name": "dhow boat sushi tray"
            },
            {
                "product_id": 593,
                "product_name": "tuscania : tuscania canister zucchero ( sugar )"
            },
            {
                "product_id": 29781,
                "product_name": "rooster hanging figurine ornament"
            }
        ],
        "query": "peacock"
    },
    {
        "id": 120,
        "label": [
            {
                "product_id": 30218,
                "product_name": "moraga outdoor pillow cover and insert"
            },
            {
                "product_id": 9535,
                "product_name": "tomah come and sit with me indoor/outdoor lumbar pillow"
            },
            {
                "product_id": 25057,
                "product_name": "state throw pillow"
            },
            {
                "product_id": 927,
                "product_name": "poultry cotton throw pillow"
            },
            {
                "product_id": 41028,
                "product_name": "kathrine cobblestone decorative lumbar pillow"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 13385,
                "product_name": "real effervescent decorative square pillow cover & insert"
            },
            {
                "product_id": 34075,
                "product_name": "taopi decorative plush polyester 20 '' x 48 '' body pillow"
            },
            {
                "product_id": 2898,
                "product_name": "jill soft hypoallergenic medium polyester body pillow"
            },
            {
                "product_id": 11334,
                "product_name": "ashton 350 thread count 100 % cotton sateen pillow cases"
            },
            {
                "product_id": 21077,
                "product_name": "rustic patch moose body pillow case"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 18488,
                "product_name": "exaucet morrow throw pillow in navy"
            },
            {
                "product_id": 18489,
                "product_name": "aurelie throw pillow in light blue"
            },
            {
                "product_id": 3196,
                "product_name": "hang ease c type shower curtain hooks"
            },
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            }
        ],
        "garOutput": [
            {
                "product_id": 17394,
                "product_name": "chad wool beige/black area rug"
            },
            {
                "product_id": 17395,
                "product_name": "chad beige/black area rug"
            },
            {
                "product_id": 26235,
                "product_name": "targhee pig calendar figurine"
            },
            {
                "product_id": 13015,
                "product_name": "desktop zen garden with thai buddha figurine"
            },
            {
                "product_id": 15162,
                "product_name": "river pebbles"
            }
        ],
        "query": "zodiac pillow"
    },
    {
        "id": 341,
        "label": [
            {
                "product_id": 24946,
                "product_name": "fashion and glam gold my amethyst angel wings feathers - wrapped canvas graphic art print"
            },
            {
                "product_id": 12192,
                "product_name": "barack by dayat banggai - graphic art print"
            },
            {
                "product_id": 13451,
                "product_name": "feather ii graphic art on wrapped canvas"
            },
            {
                "product_id": 17378,
                "product_name": "historix 1881 oahu hawaii vintage map - 24x36 inch vintage map of oahu hawaii wall art - map of hawaii oahu poster - survey of oahu hawaiian islands - old map oahu - historic oahu print ( 2 sizes )"
            },
            {
                "product_id": 41686,
                "product_name": "a premium ocean breeze graphic art print multi-piece image on wrapped canvas"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 39147,
                "product_name": "`` champaign gold map '' graphic art on wrapped canvas"
            },
            {
                "product_id": 6348,
                "product_name": "summer 5 graphic art on wrapped canvas"
            },
            {
                "product_id": 27516,
                "product_name": "ancient earth roamers canvas art"
            },
            {
                "product_id": 40860,
                "product_name": "neutral arrangement i by emma scarvey - wrapped canvas painting print"
            },
            {
                "product_id": 11619,
                "product_name": "low angle view of skyscrapers , honolulu , hawaii usa 2010 by panoramic images - wrapped canvas photograph print"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 40825,
                "product_name": "gefen geometric polygon rooster figurine"
            },
            {
                "product_id": 29144,
                "product_name": "mcdonald dinosaur lake canvas art"
            },
            {
                "product_id": 13858,
                "product_name": "the elephant graphic art"
            },
            {
                "product_id": 13535,
                "product_name": "pottery framed graphic art print on canvas"
            },
            {
                "product_id": 32287,
                "product_name": "study time - boy reading garden statue"
            }
        ],
        "garOutput": [
            {
                "product_id": 24355,
                "product_name": "regatta at argenteuil by claude monet oil painting print"
            },
            {
                "product_id": 36921,
                "product_name": "4 ft. h x 2 ft. w screen series web metal privacy screen"
            },
            {
                "product_id": 36919,
                "product_name": "4 ft. h x 2 ft. w screen series star metal privacy screen"
            },
            {
                "product_id": 22527,
                "product_name": "corkboard/bulletin board"
            },
            {
                "product_id": 15124,
                "product_name": "riverbank 19.63 '' x 19.63 '' 3d wall panel in white"
            }
        ],
        "query": "canvas map art"
    },
    {
        "id": 228,
        "label": [
            {
                "product_id": 17813,
                "product_name": "giselle bumble bee d\u00e9cor"
            },
            {
                "product_id": 23348,
                "product_name": "currahee honey bee theme , bee kind , bee yourself 4-piece set paper print"
            },
            {
                "product_id": 33449,
                "product_name": "cosmos gifts bee hive with plate salt and pepper set"
            },
            {
                "product_id": 886,
                "product_name": "45 '' cafe curtain"
            },
            {
                "product_id": 20767,
                "product_name": "juicy couture queen bee 3-piece twin microfiber sheet set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 15376,
                "product_name": "esschertdesign bumble bee house"
            },
            {
                "product_id": 22770,
                "product_name": "kasota honey bee tea infuser mug set"
            },
            {
                "product_id": 24777,
                "product_name": "napoleon bee 9 oz . coffee mug"
            },
            {
                "product_id": 10452,
                "product_name": "nempnett thrubwell queen bee worker bee and drone bee on mint hexagons coffee mug"
            },
            {
                "product_id": 10535,
                "product_name": "pretty honey bees around bee hive on honeycomb background porcelain decorative plate"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 5196,
                "product_name": "home sweet home dog wall d\u00e9cor"
            },
            {
                "product_id": 34997,
                "product_name": "jarom zen sun bouncy wind chime"
            },
            {
                "product_id": 34987,
                "product_name": "shrestha upholstered parsons chair in gray"
            },
            {
                "product_id": 20718,
                "product_name": "abramowski monitor stand"
            },
            {
                "product_id": 34998,
                "product_name": "topher bee-flower wind chime"
            }
        ],
        "garOutput": [
            {
                "product_id": 12913,
                "product_name": "virtue 18 '' x 18 '' porcelain spanish wall & floor tile"
            },
            {
                "product_id": 12450,
                "product_name": "hello honey sunflowers sassafras switch 22 '' x 10 '' non-slip outdoor door mat"
            },
            {
                "product_id": 25226,
                "product_name": "folding swivel wheel grocery utility carts"
            },
            {
                "product_id": 4687,
                "product_name": "artificial phalaenopsis single orchid floral arrangement in planter"
            },
            {
                "product_id": 8031,
                "product_name": "flaugher happy camper personalized coffee mug"
            }
        ],
        "query": "bee"
    },
    {
        "id": 394,
        "label": [
            {
                "product_id": 40429,
                "product_name": "schroeders 3 legs coffee table with storage"
            },
            {
                "product_id": 26165,
                "product_name": "adams coffee table"
            },
            {
                "product_id": 39760,
                "product_name": "arthurson 3 legs coffee table"
            },
            {
                "product_id": 19750,
                "product_name": "a-37 coffee table in , matching coffee table"
            },
            {
                "product_id": 1308,
                "product_name": "shaker coffee table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 34894,
                "product_name": "bernhardt coffee table"
            },
            {
                "product_id": 13649,
                "product_name": "cullompt fabric coffee table"
            },
            {
                "product_id": 34420,
                "product_name": "vrtanes cross legs cocktail table"
            },
            {
                "product_id": 20899,
                "product_name": "christelle premium material block coffee table"
            },
            {
                "product_id": 24849,
                "product_name": "3 piece coffee table set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 38673,
                "product_name": "abdulsamet coffee table"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 18473,
                "product_name": "akadia block coffee table"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 23413,
                "product_name": "builders program 17 5/8 '' center to center bar pull"
            },
            {
                "product_id": 5879,
                "product_name": "bayard right hand facing modular corner sectional with ottoman"
            },
            {
                "product_id": 3672,
                "product_name": "soderville 2 door accent cabinet"
            },
            {
                "product_id": 28477,
                "product_name": "larkin 30 '' wide velvet papasan chair"
            },
            {
                "product_id": 13214,
                "product_name": "33.5 '' square folding table"
            }
        ],
        "query": "gray leather cocktail table"
    },
    {
        "id": 330,
        "label": [
            {
                "product_id": 42788,
                "product_name": "staats 2 piece tiered shelf"
            },
            {
                "product_id": 15298,
                "product_name": "branchburg outdoor 10 piece sofa seating group with cushions"
            },
            {
                "product_id": 15297,
                "product_name": "venedy outdoor 9 piece sofa seating group with cushions"
            },
            {
                "product_id": 15285,
                "product_name": "chiasson outdoor 7 piece sofa seating group with cushions"
            },
            {
                "product_id": 7672,
                "product_name": "colorado 5 piece sofa seating group with cushions"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 9939,
                "product_name": "fragoso 2 piece pine solid wood floating shelf"
            },
            {
                "product_id": 26204,
                "product_name": "abasi 2 piece pine solid wood bracket shelf"
            },
            {
                "product_id": 14685,
                "product_name": "fording outdoor 7 piece sofa seating group with cushions"
            },
            {
                "product_id": 22631,
                "product_name": "2 - piece resin hanging planter set"
            },
            {
                "product_id": 3439,
                "product_name": "modern floating 2 piece wall shelf set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            },
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 347,
                "product_name": "1.93 '' solar powered outdoor floating light"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            }
        ],
        "garOutput": [
            {
                "product_id": 21693,
                "product_name": "multi area rug"
            },
            {
                "product_id": 21221,
                "product_name": "taika 16 oz . siimes pasta bowl"
            },
            {
                "product_id": 21216,
                "product_name": "taika 10 oz . siimes cereal bowl"
            },
            {
                "product_id": 21219,
                "product_name": "taika 20 oz . siimes cereal bowl"
            },
            {
                "product_id": 28560,
                "product_name": "gnomes of toad hollow merlin the garden gnome statue"
            }
        ],
        "query": "kari 2 piece"
    },
    {
        "id": 127,
        "label": [
            {
                "product_id": 26227,
                "product_name": "hazeline bar with wine storage"
            },
            {
                "product_id": 42687,
                "product_name": "cendrillon wood storage 12 bottle floor wine cabinet"
            },
            {
                "product_id": 32786,
                "product_name": "kiesel retro rolling bar cart"
            },
            {
                "product_id": 23566,
                "product_name": "heffernan 47.25 '' wide sideboard"
            },
            {
                "product_id": 19296,
                "product_name": "braddy home bar with wine storage"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 10137,
                "product_name": "lahara 24 '' wall mounted towel bar"
            },
            {
                "product_id": 939,
                "product_name": "prejean bar with wine storage"
            },
            {
                "product_id": 8461,
                "product_name": "baarde bar"
            },
            {
                "product_id": 5826,
                "product_name": "bangert 38 '' wide wood server"
            },
            {
                "product_id": 20371,
                "product_name": "gelatt bar with wine storage"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 1029,
                "product_name": "candelario bar with wine storage"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 13956,
                "product_name": "deauville coffee table"
            },
            {
                "product_id": 13955,
                "product_name": "erachidia coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 25182,
                "product_name": "cockfosters 71 '' kitchen pantry"
            },
            {
                "product_id": 16539,
                "product_name": "kleinschmidt 3 piece coffee table set"
            },
            {
                "product_id": 36011,
                "product_name": "beer gets 41 mpg fact funny us made aluminum novelty bar pub wall d\u00e9cor"
            },
            {
                "product_id": 18183,
                "product_name": "hamilton beach 6 qt . stay or go programmable flexcook slow cooker"
            },
            {
                "product_id": 7897,
                "product_name": "cendrillon 1 - drawer filing storage cabinet"
            }
        ],
        "query": "wine bar"
    },
    {
        "id": 289,
        "label": [
            {
                "product_id": 5078,
                "product_name": "susana comforter set"
            },
            {
                "product_id": 39218,
                "product_name": "wayfair basics\u00ae comforter set"
            },
            {
                "product_id": 25360,
                "product_name": "longpoint reversible comforter set"
            },
            {
                "product_id": 171,
                "product_name": "charon single comforter"
            },
            {
                "product_id": 17313,
                "product_name": "bittany sherpa single comforter"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 144,
                "product_name": "lennie really dogg coma inducer comforter set"
            },
            {
                "product_id": 8972,
                "product_name": "viqueque 9 piece comforter set"
            },
            {
                "product_id": 18339,
                "product_name": "mccoy pleat 3 piece comforter set"
            },
            {
                "product_id": 192,
                "product_name": "alexsa solid reversible comforter set"
            },
            {
                "product_id": 15219,
                "product_name": "summerhouse by barclay butera comforter set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 347,
                "product_name": "1.93 '' solar powered outdoor floating light"
            },
            {
                "product_id": 32239,
                "product_name": "laskowski crystal 3 drawer nightstand"
            },
            {
                "product_id": 30477,
                "product_name": "hobbies and activities rock climber"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 13623,
                "product_name": "2 person traditional steam sauna"
            }
        ],
        "garOutput": [
            {
                "product_id": 1981,
                "product_name": "comfort body pillowcase"
            },
            {
                "product_id": 30541,
                "product_name": "12 '' medium firm memory foam mattress"
            },
            {
                "product_id": 33908,
                "product_name": "grand award silver cushion 12.5 '' medium gel memory foam mattress and box spring set"
            },
            {
                "product_id": 1398,
                "product_name": "allergy protection polyfill body medium support pillow"
            },
            {
                "product_id": 2053,
                "product_name": "gosney microfiber mattress pad"
            }
        ],
        "query": "coma inducer comforter king set"
    },
    {
        "id": 403,
        "label": [
            {
                "product_id": 27422,
                "product_name": "patio watcher firewood rack log rack with 4 tools firewood storage log holder for indoor outdoor backyard fireplace heavy duty steel black"
            },
            {
                "product_id": 32475,
                "product_name": "25 compartment glass rack"
            },
            {
                "product_id": 32523,
                "product_name": "36u deep s1 rack"
            },
            {
                "product_id": 7075,
                "product_name": "opticlean\u2122 9 compartment glass rack"
            },
            {
                "product_id": 32520,
                "product_name": "s1 rack"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 3601,
                "product_name": "sorrento solid wood 4 - hook wall mounted coat rack"
            },
            {
                "product_id": 30566,
                "product_name": "dyar log rack"
            },
            {
                "product_id": 15582,
                "product_name": "jolin outdoor firewood log rack"
            },
            {
                "product_id": 32517,
                "product_name": "g1 series gangable rack"
            },
            {
                "product_id": 20,
                "product_name": "jenks firewood log rack"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 27793,
                "product_name": "adjustable height standing desk"
            },
            {
                "product_id": 35693,
                "product_name": "anello 20 '' w closet system reach-in sets"
            },
            {
                "product_id": 8227,
                "product_name": "aemilia 4 door accent cabinet"
            }
        ],
        "garOutput": [
            {
                "product_id": 27495,
                "product_name": "1-up hard cover pet brag album"
            },
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 12282,
                "product_name": "36 '' w rack"
            },
            {
                "product_id": 29670,
                "product_name": "enameled 3 piece coffee , tea , & sugar jar set"
            },
            {
                "product_id": 31992,
                "product_name": "decorative valet rod"
            }
        ],
        "query": "wood rack wide"
    },
    {
        "id": 107,
        "label": [
            {
                "product_id": 32176,
                "product_name": "falmouth 4 piece rattan sectional seating group with cushions"
            },
            {
                "product_id": 28875,
                "product_name": "rand 6 piece rattan seating group with cushions"
            },
            {
                "product_id": 22104,
                "product_name": "premium steel patio bistro set , folding outdoor patio furniture sets , 3 piece patio set of foldable patio table and chairs , yellow"
            },
            {
                "product_id": 41928,
                "product_name": "adriam patio 3 piece sofa seating group with cushions"
            },
            {
                "product_id": 19264,
                "product_name": "melilani outdoor 3 piece seating group with cushions"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 35613,
                "product_name": "fernando protective 6 piece water resistant patio furniture cover set"
            },
            {
                "product_id": 8974,
                "product_name": "paxtonville 3 piece conversation set with cushions"
            },
            {
                "product_id": 11242,
                "product_name": "3 piece rattan seating group with cushions"
            },
            {
                "product_id": 29677,
                "product_name": "lutie 9 piece rattan sectional seating group with cushions"
            },
            {
                "product_id": 27218,
                "product_name": "outdoor patio rattan furniture sets mfw outdoor sectional sofa with table and cushions for porch backyard garden poolside balcony ( 3 pcs brown )"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 29241,
                "product_name": "cleotha twin 75 '' wide tufted back convertible sofa"
            },
            {
                "product_id": 890,
                "product_name": "sapphira reversible quilt set"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 7605,
                "product_name": "ayala 3 piece rattan seating group with cushions"
            },
            {
                "product_id": 16763,
                "product_name": "benedick 3 piece quilt set"
            },
            {
                "product_id": 13814,
                "product_name": "altun task chair"
            },
            {
                "product_id": 22313,
                "product_name": "fordland 24 '' patio bar stool"
            },
            {
                "product_id": 13201,
                "product_name": "replacement seat cushion"
            }
        ],
        "query": "front porch furniture sets"
    },
    {
        "id": 363,
        "label": [
            {
                "product_id": 41285,
                "product_name": "hounslow 2 piece coffee table set"
            },
            {
                "product_id": 21927,
                "product_name": "sheffield coffee table"
            },
            {
                "product_id": 26941,
                "product_name": "abba solid wood cross legs coffee table"
            },
            {
                "product_id": 34950,
                "product_name": "kimberline coffee table"
            },
            {
                "product_id": 34496,
                "product_name": "picacho coffee table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 36701,
                "product_name": "sansa coffee table"
            },
            {
                "product_id": 31057,
                "product_name": "olin drum coffee table with storage"
            },
            {
                "product_id": 34510,
                "product_name": "noker coffee table"
            },
            {
                "product_id": 40435,
                "product_name": "dinkins coffee table with storage"
            },
            {
                "product_id": 13649,
                "product_name": "cullompt fabric coffee table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 13956,
                "product_name": "deauville coffee table"
            },
            {
                "product_id": 13955,
                "product_name": "erachidia coffee table"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            }
        ],
        "garOutput": [
            {
                "product_id": 5144,
                "product_name": "minahil square multi-tiered plant stand"
            },
            {
                "product_id": 18210,
                "product_name": "dane solid wood end table with storage"
            },
            {
                "product_id": 1606,
                "product_name": "gaiana 12.6 '' console table"
            },
            {
                "product_id": 34533,
                "product_name": "huyet coffee table"
            },
            {
                "product_id": 6344,
                "product_name": "huling coffee table"
            }
        ],
        "query": "fortunat coffee table"
    },
    {
        "id": 319,
        "label": [
            {
                "product_id": 37230,
                "product_name": "flip solid wood 5 - hook wall mounted coat rack"
            },
            {
                "product_id": 8653,
                "product_name": "leming 23.62 '' w x 7.49 '' h x 9.45 '' d wall mounted bathroom shelves"
            },
            {
                "product_id": 20040,
                "product_name": "belmond wall shelf"
            },
            {
                "product_id": 14310,
                "product_name": "23.6 '' w x 5.12 '' h x 4.7 '' d wall mounted bathroom shelves"
            },
            {
                "product_id": 32990,
                "product_name": "billy 20 '' w x 24 '' h x 7 '' d wall mounted bathroom cabinet"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 8181,
                "product_name": "iso wall shelf"
            },
            {
                "product_id": 37356,
                "product_name": "landis 16 '' w x 60 '' h bathroom shelf"
            },
            {
                "product_id": 21375,
                "product_name": "humaira wall shelf"
            },
            {
                "product_id": 40818,
                "product_name": "bradfield 8 bottle wall mounted wine bottle and glass rack in natural"
            },
            {
                "product_id": 9279,
                "product_name": "roberts 20 '' w x 25 '' h x 7 '' d wall mounted bathroom cabinet"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 25869,
                "product_name": "42 '' single bathroom vanity"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 20543,
                "product_name": "dowitcher bar with wine storage"
            },
            {
                "product_id": 21200,
                "product_name": "cutts bar with wine storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 40259,
                "product_name": "mary-grace 3 piece bracket shelf set"
            },
            {
                "product_id": 38487,
                "product_name": "baileyville lift top abstract coffee table"
            },
            {
                "product_id": 32461,
                "product_name": "saroyan 2 piece coffee table set"
            },
            {
                "product_id": 2285,
                "product_name": "outdoor 4 ft. 2 in . w x 2 ft. 5 in . d horizontal storage shed"
            },
            {
                "product_id": 40922,
                "product_name": "fennella 16 '' wall-mounted single bathroom vanity set"
            }
        ],
        "query": "landis wall mounted bathroom shelves"
    },
    {
        "id": 133,
        "label": [
            {
                "product_id": 24356,
                "product_name": "faulkner 2 - bulb outdoor bulkhead light"
            },
            {
                "product_id": 41034,
                "product_name": "shinault oil rubbed bronze 1 -bulb 17.32 '' h outdoor hanging lantern"
            },
            {
                "product_id": 41145,
                "product_name": "dan outdoor barn light"
            },
            {
                "product_id": 36258,
                "product_name": "bevin 2 -bulb 14 '' h outdoor hanging lantern"
            },
            {
                "product_id": 38641,
                "product_name": "rubix 2-light outdoor bulkhead light"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 11435,
                "product_name": "pursley 13.39 '' h outdoor armed sconce"
            },
            {
                "product_id": 12431,
                "product_name": "1 -bulb outdoor bulkhead light"
            },
            {
                "product_id": 24802,
                "product_name": "catalie 1 - bulb outdoor bulkhead light"
            },
            {
                "product_id": 4402,
                "product_name": "mccluskey 2 - bulb outdoor bulkhead light"
            },
            {
                "product_id": 5720,
                "product_name": "devonte led 2 - light outdoor flush mount"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 3197,
                "product_name": "double shower curtain hooks"
            },
            {
                "product_id": 13956,
                "product_name": "deauville coffee table"
            },
            {
                "product_id": 13955,
                "product_name": "erachidia coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 39501,
                "product_name": "60 watt t12 incandescent , dimmable light bulb , warm white ( 2450k ) e26 base"
            },
            {
                "product_id": 23636,
                "product_name": "shaylee 8 - light candle style empire chandelier"
            },
            {
                "product_id": 23634,
                "product_name": "shaylee 5 - light candle style empire chandelier"
            },
            {
                "product_id": 23635,
                "product_name": "shaylee 6 - light candle style empire chandelier"
            },
            {
                "product_id": 4169,
                "product_name": "liverman end table"
            }
        ],
        "query": "outdoor light fixtures"
    },
    {
        "id": 399,
        "label": [
            {
                "product_id": 35666,
                "product_name": "dirt bikes wall decal"
            },
            {
                "product_id": 34028,
                "product_name": "terrian mini electric quad bike"
            },
            {
                "product_id": 32622,
                "product_name": "dirt bike rider wall decal"
            },
            {
                "product_id": 18942,
                "product_name": "y-bike go kart"
            },
            {
                "product_id": 9602,
                "product_name": "dirt devil bd830015 quick flip 12v cordless bagless handheld vacuum"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 8767,
                "product_name": "hero bicycle wall mount bike rack"
            },
            {
                "product_id": 1223,
                "product_name": "cartoon balance bike tricycle"
            },
            {
                "product_id": 9077,
                "product_name": "dirt bike 1 brown area rug"
            },
            {
                "product_id": 10534,
                "product_name": "3drose we gon na ride or what cool atv quad bike offroad action potholder"
            },
            {
                "product_id": 9576,
                "product_name": "hoist ceiling mounted sports rack"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 32518,
                "product_name": "elite rack"
            },
            {
                "product_id": 21866,
                "product_name": "90 '' 4-burner propane/natural gas"
            },
            {
                "product_id": 30477,
                "product_name": "hobbies and activities rock climber"
            },
            {
                "product_id": 20324,
                "product_name": "marquez bed riser"
            },
            {
                "product_id": 24198,
                "product_name": "standard bunk bed by mason & marbles"
            }
        ],
        "garOutput": [
            {
                "product_id": 1223,
                "product_name": "cartoon balance bike tricycle"
            },
            {
                "product_id": 30387,
                "product_name": "stalwart flip up folding wall mounted sports rack"
            },
            {
                "product_id": 33666,
                "product_name": "120 gallon deck box"
            },
            {
                "product_id": 9981,
                "product_name": "arlmont & co. 4 bike stand rack with storage \u2013 metal floor bicycle \u2013 great for parking road , mountain , hybrid or kids bikes \u2013 garage organizer - helmet - sports storage station - black"
            },
            {
                "product_id": 22011,
                "product_name": "darnetha 70.5 '' faux leather pillow top arm convertible sleeper sofa bed"
            }
        ],
        "query": "12v dirt bike"
    },
    {
        "id": 44,
        "label": [
            {
                "product_id": 30467,
                "product_name": "jaida 1-handle tub and shower faucet with valve"
            },
            {
                "product_id": 10131,
                "product_name": "trinsic thermostatic shower faucet with h2okinetic technology"
            },
            {
                "product_id": 26559,
                "product_name": "moen\u00ae shower head volume control valve"
            },
            {
                "product_id": 14574,
                "product_name": "artemisa volume control complete shower system with rough-in valve"
            },
            {
                "product_id": 39993,
                "product_name": "ceiling mount thermostatic volume control complete shower system with rough-in valve"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 2338,
                "product_name": "high pressure multi function dual shower head"
            },
            {
                "product_id": 41236,
                "product_name": "rainfall pressure balanced shower faucet with rough-in valve"
            },
            {
                "product_id": 23265,
                "product_name": "purist rite-temp pressure-balancing shower faucet trim with lever handle , valve not included"
            },
            {
                "product_id": 220,
                "product_name": "anti-clog high-pressure 6-inch multi function dual shower head"
            },
            {
                "product_id": 22247,
                "product_name": "rite temp hydrorail-s kit with awaken 2.0 gpm shower head and hand shower"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 24966,
                "product_name": "nita single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 3197,
                "product_name": "double shower curtain hooks"
            },
            {
                "product_id": 39991,
                "product_name": "bathroom rain volume shower system rough-in valve"
            },
            {
                "product_id": 41097,
                "product_name": "c-shaped shower curtain hooks"
            },
            {
                "product_id": 31905,
                "product_name": "toulouse printed single shower curtain"
            }
        ],
        "garOutput": [
            {
                "product_id": 21472,
                "product_name": "flip 45 '' w x 56 '' h pivot frameless tub door"
            },
            {
                "product_id": 26699,
                "product_name": "tempered glass circular vessel bathroom sink"
            },
            {
                "product_id": 6430,
                "product_name": "widespread faucet bathroom faucet"
            },
            {
                "product_id": 9219,
                "product_name": "rounds ball shower curtain hooks"
            },
            {
                "product_id": 17691,
                "product_name": "wayfair basics\u00ae shower curtain hooks"
            }
        ],
        "query": "chrome shower head and valve"
    },
    {
        "id": 447,
        "label": [
            {
                "product_id": 6380,
                "product_name": "miruna 63 '' wide wood sideboard"
            },
            {
                "product_id": 39099,
                "product_name": "kitchen storage sideboard and buffet server cabinet-brown color"
            },
            {
                "product_id": 26940,
                "product_name": "ottinger 47.24 '' wide server"
            },
            {
                "product_id": 39355,
                "product_name": "jessenia 75 '' wide pine wood sideboard"
            },
            {
                "product_id": 27475,
                "product_name": "server"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 16699,
                "product_name": "weisgerber contemporary buffet table"
            },
            {
                "product_id": 41228,
                "product_name": "mcfarlane bar with wine storage"
            },
            {
                "product_id": 39688,
                "product_name": "dinapoli china cabinet"
            },
            {
                "product_id": 35508,
                "product_name": "albermarle sideboard"
            },
            {
                "product_id": 37863,
                "product_name": "resler larkin 3 door buffet table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 38244,
                "product_name": "agirta extendable dining table"
            }
        ],
        "garOutput": [
            {
                "product_id": 26181,
                "product_name": "whittier coffee table with storage"
            },
            {
                "product_id": 7531,
                "product_name": "riser"
            },
            {
                "product_id": 7988,
                "product_name": "newport 3 '' center to center bar pull"
            },
            {
                "product_id": 41257,
                "product_name": "silk solid color room darkening thermal rod pocket single curtain panel"
            },
            {
                "product_id": 13590,
                "product_name": "paisleys pillowcase"
            }
        ],
        "query": "tall buffet cabinet"
    },
    {
        "id": 65,
        "label": [
            {
                "product_id": 36605,
                "product_name": "dagobert with gifts garden gnome statue"
            },
            {
                "product_id": 21263,
                "product_name": "ellenson ceramic gnome garden statue"
            },
            {
                "product_id": 11516,
                "product_name": "ant fairy garden 2 piece"
            },
            {
                "product_id": 36598,
                "product_name": "birdy and spader the garden gnome statue"
            },
            {
                "product_id": 14037,
                "product_name": "gnome with led firefly watering garden statue"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 12443,
                "product_name": "knucklehead the harley-davidson gnome statue"
            },
            {
                "product_id": 11711,
                "product_name": "pocola miniature enchanted fairy garden"
            },
            {
                "product_id": 42618,
                "product_name": "elianna solar bird rocking chair gnome statue"
            },
            {
                "product_id": 21337,
                "product_name": "amaras fairy garden metal garden stake"
            },
            {
                "product_id": 36564,
                "product_name": "the sunflower fairy statue"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 13247,
                "product_name": "square multi-tiered plant stand"
            },
            {
                "product_id": 37705,
                "product_name": "mystic gnome treehouse outdoor decor garden light"
            },
            {
                "product_id": 40011,
                "product_name": "mitton pineapple statue"
            },
            {
                "product_id": 3731,
                "product_name": "lovey the owl"
            },
            {
                "product_id": 16782,
                "product_name": "owl couple with welcome sign ( hi-line exclusive )"
            }
        ],
        "garOutput": [
            {
                "product_id": 19593,
                "product_name": "marrero zipline playscape modular sofa kids foam"
            },
            {
                "product_id": 12240,
                "product_name": "cullens solar powered led deck light pack"
            },
            {
                "product_id": 3756,
                "product_name": "duggins brontosaurus dinosaur coffee mug"
            },
            {
                "product_id": 2394,
                "product_name": "choute polystone fountain"
            },
            {
                "product_id": 42562,
                "product_name": "carwile pink owl quilt set"
            }
        ],
        "query": "gnome fairy garden"
    },
    {
        "id": 287,
        "label": [
            {
                "product_id": 35618,
                "product_name": "claire protective 8 piece water resistant patio furniture cover set"
            },
            {
                "product_id": 31350,
                "product_name": "fairfield 8 piece water resistant patio furniture cover set"
            },
            {
                "product_id": 35623,
                "product_name": "kenwick protective 3 piece water resistant patio furniture cover set"
            },
            {
                "product_id": 32738,
                "product_name": "water resistant 5 piece patio furniture cover set"
            },
            {
                "product_id": 35601,
                "product_name": "menifee water resistant patio furniture cover set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 33425,
                "product_name": "water resistant breathable patio dining set cover"
            },
            {
                "product_id": 31144,
                "product_name": "titanium shield outdoor round water resistant patio dining set cover"
            },
            {
                "product_id": 31353,
                "product_name": "fairfield protective water resistant patio furniture set cover"
            },
            {
                "product_id": 15052,
                "product_name": "square heavy duty patio sofa cover"
            },
            {
                "product_id": 36730,
                "product_name": "rebrilliant heavy duty outdoor patio round table cover with 2 year warranty"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 12350,
                "product_name": "rossmere indoor/outdoor bench cushion"
            },
            {
                "product_id": 16256,
                "product_name": "vidal ultra soft & plush dinosaurs fleece throw"
            },
            {
                "product_id": 17073,
                "product_name": "centerport cone basket 14 '' plastic hanging planter"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 38031,
                "product_name": "dingman patchwork plastic/vinyl tablecloth"
            },
            {
                "product_id": 38035,
                "product_name": "freeman vinyl tablecloth"
            },
            {
                "product_id": 38034,
                "product_name": "dingle vinyl tablecloth"
            },
            {
                "product_id": 38033,
                "product_name": "doher vinyl tablecloth"
            },
            {
                "product_id": 38032,
                "product_name": "jodi striped plastic/vinyl tablecloth"
            }
        ],
        "query": "cover set for outdoor furniture"
    },
    {
        "id": 85,
        "label": [
            {
                "product_id": 28215,
                "product_name": "nora\u00ae 12 '' medium memory foam mattress"
            },
            {
                "product_id": 11578,
                "product_name": "sealy response essentials 8.5 '' firm tight top mattress"
            },
            {
                "product_id": 32483,
                "product_name": "11 '' medium innerspring mattress"
            },
            {
                "product_id": 39259,
                "product_name": "wayfair sleep 14 '' plush innerspring mattress"
            },
            {
                "product_id": 1970,
                "product_name": "7 '' medium memory foam mattress"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 7042,
                "product_name": "8 '' plush memory foam mattress"
            },
            {
                "product_id": 41526,
                "product_name": "serta 7 '' firm innerspring mattress"
            },
            {
                "product_id": 1978,
                "product_name": "shaylee 7 '' firm innerspring mattress"
            },
            {
                "product_id": 8202,
                "product_name": "wayfair sleep 8 '' firm memory foam mattress"
            },
            {
                "product_id": 2003,
                "product_name": "wayfair sleep 8 '' medium memory foam mattress"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 32482,
                "product_name": "krause 16 '' height profile platform bed"
            },
            {
                "product_id": 39991,
                "product_name": "bathroom rain volume shower system rough-in valve"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 14495,
                "product_name": "twin over full bunk bed"
            },
            {
                "product_id": 18887,
                "product_name": "schwanke twin over full bunk bed by harriet bee"
            }
        ],
        "garOutput": [
            {
                "product_id": 2182,
                "product_name": "3-tier printer cart with storage shelf mobile printer stand end table home office"
            },
            {
                "product_id": 13701,
                "product_name": "arcata solid wood stacking side chair"
            },
            {
                "product_id": 13609,
                "product_name": "iveta abolina floral 3 piece comforter set"
            },
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 38375,
                "product_name": "haru 48 '' platform bed"
            }
        ],
        "query": "3/4 size mattress"
    },
    {
        "id": 246,
        "label": [
            {
                "product_id": 16816,
                "product_name": "french damask shower curtain"
            },
            {
                "product_id": 10308,
                "product_name": "colorado single shower curtain"
            },
            {
                "product_id": 23988,
                "product_name": "clarkstown single shower curtain"
            },
            {
                "product_id": 6678,
                "product_name": "jourdain shower curtain"
            },
            {
                "product_id": 23540,
                "product_name": "schubert 15 piece geometric bath set + hooks"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 27372,
                "product_name": "thad floral single shower curtain"
            },
            {
                "product_id": 34561,
                "product_name": "escarpita heavy grommet plastic solid shower curtain liner"
            },
            {
                "product_id": 31221,
                "product_name": "atherstone i single shower curtain"
            },
            {
                "product_id": 9752,
                "product_name": "rodas vinyl shower curtain"
            },
            {
                "product_id": 15394,
                "product_name": "vinyl striped shower curtain set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 3197,
                "product_name": "double shower curtain hooks"
            },
            {
                "product_id": 31905,
                "product_name": "toulouse printed single shower curtain"
            },
            {
                "product_id": 1654,
                "product_name": "aaiyana striped sheer grommet single curtain panel"
            },
            {
                "product_id": 1657,
                "product_name": "solid color sheer grommet single curtain panel"
            },
            {
                "product_id": 22128,
                "product_name": "head zero-jets shower faucet with rough-in valve"
            }
        ],
        "garOutput": [
            {
                "product_id": 10480,
                "product_name": "addison letter a victorian with peacock hiding hand towel"
            },
            {
                "product_id": 10479,
                "product_name": "pattern peacock art hand towel"
            },
            {
                "product_id": 10481,
                "product_name": "bolton pretty rose bees pattern hand towel"
            },
            {
                "product_id": 10478,
                "product_name": "william morris peacock and dragon hand towel"
            },
            {
                "product_id": 33551,
                "product_name": "7.2 cu . ft. gas dryer with sensor dry"
            }
        ],
        "query": "clear shower curtain"
    },
    {
        "id": 188,
        "label": [
            {
                "product_id": 3605,
                "product_name": "beautiful patterned metal flocking birds wall decor"
            },
            {
                "product_id": 36005,
                "product_name": "room rules hours of operation metal sign funny kids bedroom man cave decor"
            },
            {
                "product_id": 929,
                "product_name": "agyen decorative single hat and double coat wall hook"
            },
            {
                "product_id": 34737,
                "product_name": "dinosaur wall decor"
            },
            {
                "product_id": 18194,
                "product_name": "starburst wall decor"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 25904,
                "product_name": "decorative twig and wire cone pocket wall d\u00e9cor"
            },
            {
                "product_id": 27721,
                "product_name": "rustic architectural window wall decor"
            },
            {
                "product_id": 13730,
                "product_name": "golden metal wall decor"
            },
            {
                "product_id": 25439,
                "product_name": "barfuss round wall decor"
            },
            {
                "product_id": 35276,
                "product_name": "christmas decorations old fashioned concept twigs and on rustic wood vintage graphic print & text semi-sheer rod pocket curtain panels"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 34471,
                "product_name": "home sweet home framed wall d\u00e9cor"
            },
            {
                "product_id": 33911,
                "product_name": "spode home blue indigo 16 piece dinnerware set , service for 4"
            },
            {
                "product_id": 18919,
                "product_name": "aftan geometric purple/gray area rug"
            },
            {
                "product_id": 41968,
                "product_name": "3.5 '' x 35.4 '' reclaimed peel and stick solid wood wall paneling"
            },
            {
                "product_id": 37436,
                "product_name": "mchugh pineapple sculpture"
            }
        ],
        "garOutput": [
            {
                "product_id": 41689,
                "product_name": "mod pods i - graphic art print"
            },
            {
                "product_id": 33791,
                "product_name": "florid lantern 6.25 '' x 7.25 '' straight edge ceramic singular subway tile"
            },
            {
                "product_id": 5224,
                "product_name": "hurst abstract braided cotton multicolor area rug"
            },
            {
                "product_id": 12372,
                "product_name": "penny round 12 '' x 11 '' metal mosaic tile"
            },
            {
                "product_id": 29048,
                "product_name": "mateer pineapple storage decor"
            }
        ],
        "query": "bedroom wall decor floral, multicolored with some teal (prints)"
    },
    {
        "id": 160,
        "label": [
            {
                "product_id": 40429,
                "product_name": "schroeders 3 legs coffee table with storage"
            },
            {
                "product_id": 36425,
                "product_name": "marble 3 piece kitchen canister set"
            },
            {
                "product_id": 14304,
                "product_name": "marble look 2 qt . kitchen canister"
            },
            {
                "product_id": 33871,
                "product_name": "veil 1 '' x 1 '' marble mosaic tile"
            },
            {
                "product_id": 37497,
                "product_name": "obadiah tiled marble electric fireplace"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 35716,
                "product_name": "marble honeycomb mosaic wall & floor tile"
            },
            {
                "product_id": 33780,
                "product_name": "reflect 4 '' x 12 '' beveled marble splitface tile in dark gray"
            },
            {
                "product_id": 24091,
                "product_name": "albuquerqua 3 '' x 6 '' porcelain marble look wall & floor tile"
            },
            {
                "product_id": 33778,
                "product_name": "stowe herringbone 1 '' x 3 '' marble mosaic tile in gray"
            },
            {
                "product_id": 10919,
                "product_name": "marble pattern standing anti fatigue mat"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 32547,
                "product_name": "serving platter"
            },
            {
                "product_id": 27320,
                "product_name": "0.75 '' x 0.75 '' seashell tile"
            },
            {
                "product_id": 12350,
                "product_name": "rossmere indoor/outdoor bench cushion"
            },
            {
                "product_id": 17327,
                "product_name": "6 '' decorative ship wheel with starfish"
            },
            {
                "product_id": 34834,
                "product_name": "ambrose console table"
            }
        ],
        "garOutput": [
            {
                "product_id": 15162,
                "product_name": "river pebbles"
            },
            {
                "product_id": 33909,
                "product_name": "spode christmas tree bake loaf pan"
            },
            {
                "product_id": 10661,
                "product_name": "dorian 1 '' x 4 '' beveled glass brick joint mosaic tile"
            },
            {
                "product_id": 10662,
                "product_name": "casia roya chevron mosaic tile"
            },
            {
                "product_id": 24088,
                "product_name": "slate 32 '' x 32 '' porcelain concrete look wall & floor tile"
            }
        ],
        "query": "marble"
    },
    {
        "id": 12,
        "label": [
            {
                "product_id": 12962,
                "product_name": "aneeqa the mughal paich daar hand-painted dinner fork"
            },
            {
                "product_id": 13472,
                "product_name": "6 piece sunburst decorative metal sculpture hanging wall d\u00e9cor set"
            },
            {
                "product_id": 32053,
                "product_name": "wolly meadow wall d\u00e9cor"
            },
            {
                "product_id": 27341,
                "product_name": "architectural window wall decor"
            },
            {
                "product_id": 3250,
                "product_name": "gold wall decor"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 1354,
                "product_name": "utensil 3 piece wall d\u00e9cor set"
            },
            {
                "product_id": 21341,
                "product_name": "yusra wall decor"
            },
            {
                "product_id": 2477,
                "product_name": "bolindale 20 piece 18/10 stainless steel flatware set , service for 4"
            },
            {
                "product_id": 39878,
                "product_name": "wilmington steelwares 9 piece cooking spoon set"
            },
            {
                "product_id": 2627,
                "product_name": "champagne gold resin wall decor"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 29241,
                "product_name": "cleotha twin 75 '' wide tufted back convertible sofa"
            },
            {
                "product_id": 13956,
                "product_name": "deauville coffee table"
            },
            {
                "product_id": 13955,
                "product_name": "erachidia coffee table"
            },
            {
                "product_id": 29346,
                "product_name": "bloss extendable dining table"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 40259,
                "product_name": "mary-grace 3 piece bracket shelf set"
            },
            {
                "product_id": 33791,
                "product_name": "florid lantern 6.25 '' x 7.25 '' straight edge ceramic singular subway tile"
            },
            {
                "product_id": 16539,
                "product_name": "kleinschmidt 3 piece coffee table set"
            },
            {
                "product_id": 22986,
                "product_name": "turquoise succulent - wrapped canvas photograph print"
            },
            {
                "product_id": 5160,
                "product_name": "cansu block coffee table with storage"
            }
        ],
        "query": "large spoon and fork wall decor"
    },
    {
        "id": 35,
        "label": [
            {
                "product_id": 11204,
                "product_name": "cubeicals 3 pair shoe storage bench"
            },
            {
                "product_id": 31820,
                "product_name": "gavin 12 pair shoe storage cabinet"
            },
            {
                "product_id": 22408,
                "product_name": "chesmore 12 pair shoe rack"
            },
            {
                "product_id": 1766,
                "product_name": "sybil shoe storage bench"
            },
            {
                "product_id": 16263,
                "product_name": "gawu30srbh gladiator wire slatwall shoe rack"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 42746,
                "product_name": "truax 12-pair white shoe storage cabinet"
            },
            {
                "product_id": 20819,
                "product_name": "industrial rustic 12 pair shoe rack"
            },
            {
                "product_id": 39155,
                "product_name": "24 pair shoe rack"
            },
            {
                "product_id": 23885,
                "product_name": "18 pair shoe storage cabinet"
            },
            {
                "product_id": 18963,
                "product_name": "9 pair shoe rack"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 2233,
                "product_name": "tv stand for tvs up to 50 ''"
            },
            {
                "product_id": 12696,
                "product_name": "white 163 '' portable projection screen"
            },
            {
                "product_id": 17223,
                "product_name": "buffet storage cabinet with single glass doors and unique bell handle"
            },
            {
                "product_id": 14495,
                "product_name": "twin over full bunk bed"
            },
            {
                "product_id": 8754,
                "product_name": "bamboo plant flower stand rack 2 tier corner tall flower bench holder indoor"
            }
        ],
        "garOutput": [
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 29670,
                "product_name": "enameled 3 piece coffee , tea , & sugar jar set"
            },
            {
                "product_id": 41444,
                "product_name": "8 ft. w x 4 ft. d solid wood lean-to storage shed"
            },
            {
                "product_id": 39644,
                "product_name": "evas hall tree"
            },
            {
                "product_id": 2182,
                "product_name": "3-tier printer cart with storage shelf mobile printer stand end table home office"
            }
        ],
        "query": "enclosed shoe rack"
    },
    {
        "id": 28,
        "label": [
            {
                "product_id": 25532,
                "product_name": "spoon lady wood wall d\u00e9cor"
            },
            {
                "product_id": 11030,
                "product_name": "rooster watercolor wall d\u00e9cor"
            },
            {
                "product_id": 24622,
                "product_name": "animal art figurine"
            },
            {
                "product_id": 16286,
                "product_name": "blue tail rooster throw pillow"
            },
            {
                "product_id": 28146,
                "product_name": "rooster glass art"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 28093,
                "product_name": "lina wooden pineapple decoration"
            },
            {
                "product_id": 12208,
                "product_name": "ambesonne ships wheel tapestry , nautical patchwork pattern rope starfish sailing ship anchor and helm , fabric wall hanging decor for bedroom living room dorm , 28 '' x 23 '' , turquoise navy"
            },
            {
                "product_id": 38593,
                "product_name": "turrell dinosaurs decorative plaque ( set of 3 )"
            },
            {
                "product_id": 9239,
                "product_name": "vintage rooster wooden wall d\u00e9cor"
            },
            {
                "product_id": 37597,
                "product_name": "lidded pineapple decorative storage jar"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 11850,
                "product_name": "pi creative art where the passion flower grows i single shower curtain"
            },
            {
                "product_id": 11168,
                "product_name": "sanya anchor wall hanging"
            }
        ],
        "garOutput": [
            {
                "product_id": 13974,
                "product_name": "classic beaded 1-gang toggle light switch wall plate"
            },
            {
                "product_id": 40843,
                "product_name": "4 piece rooster shelf sitter figurine set"
            },
            {
                "product_id": 5095,
                "product_name": "falko rooster figurine"
            },
            {
                "product_id": 13977,
                "product_name": "classic beaded 2-gang toggle light switch wall plate"
            },
            {
                "product_id": 13979,
                "product_name": "classic beaded 3-gang toggle light switch wall plate"
            }
        ],
        "query": "rooster decor"
    },
    {
        "id": 171,
        "label": [
            {
                "product_id": 8209,
                "product_name": "classic brands cool gel 10 '' medium mattress"
            },
            {
                "product_id": 39251,
                "product_name": "wayfair sleep 12 '' medium firm memory foam mattress"
            },
            {
                "product_id": 17390,
                "product_name": "osborn 6 '' medium gel memory foam mattress"
            },
            {
                "product_id": 19374,
                "product_name": "12in . medium gel memory foam mattress queen"
            },
            {
                "product_id": 41549,
                "product_name": "sleeptrue 12.5 '' plush hybrid mattress"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 8202,
                "product_name": "wayfair sleep 8 '' firm memory foam mattress"
            },
            {
                "product_id": 2016,
                "product_name": "10 '' medium gel infused memory foam mattress"
            },
            {
                "product_id": 42910,
                "product_name": "zinus 10 '' medium memory foam mattress"
            },
            {
                "product_id": 24335,
                "product_name": "always cool 10 '' medium hybrid mattress"
            },
            {
                "product_id": 8018,
                "product_name": "14 '' firm gel memory foam mattress and box spring"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 1179,
                "product_name": "real touch bouquet peonies stems"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 15376,
                "product_name": "esschertdesign bumble bee house"
            },
            {
                "product_id": 16256,
                "product_name": "vidal ultra soft & plush dinosaurs fleece throw"
            },
            {
                "product_id": 27333,
                "product_name": "berengere 4 '' cotton futon mattress"
            }
        ],
        "garOutput": [
            {
                "product_id": 7415,
                "product_name": "9 '' zero gravity adjustable bed with wireless remote"
            },
            {
                "product_id": 32825,
                "product_name": "mattress in a box 10 '' medium memory foam mattress"
            },
            {
                "product_id": 23490,
                "product_name": "lucid comfort collection 8 \u201d medium gel memory foam mattress"
            },
            {
                "product_id": 23460,
                "product_name": "wayfair sleep 8 '' medium firm innerspring mattress"
            },
            {
                "product_id": 32826,
                "product_name": "sealy 12 '' medium memory foam mattress"
            }
        ],
        "query": "nectar queen mattress"
    },
    {
        "id": 142,
        "label": [
            {
                "product_id": 20248,
                "product_name": "alyea kitchen cart with solid wood"
            },
            {
                "product_id": 36371,
                "product_name": "oak park kitchen island"
            },
            {
                "product_id": 13807,
                "product_name": "folkes kitchen cart solid wood"
            },
            {
                "product_id": 18413,
                "product_name": "langley kitchen cart with mdf top"
            },
            {
                "product_id": 20249,
                "product_name": "coran kitchen cart with solid wood"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 18230,
                "product_name": "kyra rolling kitchen cart with solid wood top"
            },
            {
                "product_id": 39988,
                "product_name": "elian kitchen cart"
            },
            {
                "product_id": 33987,
                "product_name": "3-tier fruit stand & wall mount kitchen storage foldable metal/wire basket"
            },
            {
                "product_id": 5295,
                "product_name": "auton modish dual tone wooden kitchen cart"
            },
            {
                "product_id": 5334,
                "product_name": "cleo wooden kitchen cart"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 5959,
                "product_name": "marple 42 '' wide 2 drawer server"
            },
            {
                "product_id": 38244,
                "product_name": "agirta extendable dining table"
            },
            {
                "product_id": 8740,
                "product_name": "alessandro rectangular multi-tiered plant stand"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 38158,
                "product_name": "herlane 15 '' console table"
            },
            {
                "product_id": 31307,
                "product_name": "rolling storage utility cart"
            },
            {
                "product_id": 13504,
                "product_name": "teak tripod end table"
            },
            {
                "product_id": 24595,
                "product_name": "debby bar & counter stool"
            },
            {
                "product_id": 3741,
                "product_name": "reay nesting 2 piece serving tray set"
            }
        ],
        "query": "kitchen wooden stand"
    },
    {
        "id": 390,
        "label": [
            {
                "product_id": 1881,
                "product_name": "sarver indoor/outdoor seat/back cushion"
            },
            {
                "product_id": 1170,
                "product_name": "mccay outdoor seat/back cushion"
            },
            {
                "product_id": 34918,
                "product_name": "outdoor replacement seat/back canvas cushion"
            },
            {
                "product_id": 16526,
                "product_name": "indoor/outdoor seat/back cushion"
            },
            {
                "product_id": 16526,
                "product_name": "indoor/outdoor seat/back cushion"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 35611,
                "product_name": "outdoor replacement cushion"
            },
            {
                "product_id": 41018,
                "product_name": "outdoor sunbrella contour dining chair cushion"
            },
            {
                "product_id": 20120,
                "product_name": "outdoor seat/back cushion"
            },
            {
                "product_id": 1827,
                "product_name": "indoor/outdoor replacement cushion set"
            },
            {
                "product_id": 3416,
                "product_name": "indoor/outdoor seat/back cushion"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 17073,
                "product_name": "centerport cone basket 14 '' plastic hanging planter"
            },
            {
                "product_id": 7028,
                "product_name": "entryway 6 pair shoe storage bench"
            },
            {
                "product_id": 13423,
                "product_name": "cabinet door handles drawer 3 3/4 '' center bar pull multipack"
            },
            {
                "product_id": 19249,
                "product_name": "bassett kids desk"
            },
            {
                "product_id": 42924,
                "product_name": "white 100 '' portable folding frame projection screen"
            }
        ],
        "garOutput": [
            {
                "product_id": 4137,
                "product_name": "natazhat bohemian bedroom decor boho wall decal"
            },
            {
                "product_id": 27883,
                "product_name": "ailbhe 35.43 '' w rack"
            },
            {
                "product_id": 30219,
                "product_name": "norris plush velvet throw pillow"
            },
            {
                "product_id": 37258,
                "product_name": "elborough geometric yellow rug"
            },
            {
                "product_id": 16047,
                "product_name": "flush mount lighting alexi 1-light flush mount"
            }
        ],
        "query": "cushion replacement insert"
    },
    {
        "id": 474,
        "label": [
            {
                "product_id": 25224,
                "product_name": "nashua 72 '' double bathroom vanity set"
            },
            {
                "product_id": 10811,
                "product_name": "bleeker 24 '' single bathroom vanity set"
            },
            {
                "product_id": 40067,
                "product_name": "hibbitts 36 `` single bathroom vanity base only"
            },
            {
                "product_id": 6844,
                "product_name": "chestnut 72 '' double bathroom vanity"
            },
            {
                "product_id": 1438,
                "product_name": "winscombe 36 '' single bathroom vanity set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 29563,
                "product_name": "caywood 47.2 '' single bathroom vanity set"
            },
            {
                "product_id": 6523,
                "product_name": "lisenby 71 '' double bathroom vanity set with mirror"
            },
            {
                "product_id": 38335,
                "product_name": "almondsbury 23.6 '' w x 48 '' h x 12.8 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 19722,
                "product_name": "dfi commercial brushed nickel waterfall bathroom sink faucets single handle hole deck mount lavatory"
            },
            {
                "product_id": 36359,
                "product_name": "bergin 30 '' single bathroom vanity"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 25869,
                "product_name": "42 '' single bathroom vanity"
            },
            {
                "product_id": 37727,
                "product_name": "villante 24 '' single bathroom vanity set"
            },
            {
                "product_id": 38224,
                "product_name": "riggins 24 '' single bathroom vanity set"
            },
            {
                "product_id": 19717,
                "product_name": "commercial single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 39991,
                "product_name": "bathroom rain volume shower system rough-in valve"
            }
        ],
        "garOutput": [
            {
                "product_id": 27610,
                "product_name": "housekeeper supply metal/wire basket"
            },
            {
                "product_id": 17444,
                "product_name": "5 '' center to center bar pull"
            },
            {
                "product_id": 17447,
                "product_name": "multipack 5 '' center to center bar pull"
            },
            {
                "product_id": 17445,
                "product_name": "6 5/16 center to center bar pull"
            },
            {
                "product_id": 17448,
                "product_name": "7.56 '' center to center bar pull"
            }
        ],
        "query": "48 inch bathroom vanity with trough sink"
    },
    {
        "id": 225,
        "label": [
            {
                "product_id": 1216,
                "product_name": "30 '' x 18 '' ceramic albert bierstadt landscape painting decorative mural tile 422 : 6 '' x 6 '' ( set of 15 )"
            },
            {
                "product_id": 38630,
                "product_name": "holden removable peel and stick wallpaper panel"
            },
            {
                "product_id": 6422,
                "product_name": "18 '' x 108 '' peel and stick vinyl wall paneling in light gray"
            },
            {
                "product_id": 6423,
                "product_name": "18 '' x 108 '' peel and stick vinyl wall paneling in gray"
            },
            {
                "product_id": 6424,
                "product_name": "chelsea 10 '' x 10 '' peel and stick vinyl wall paneling"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 40305,
                "product_name": "subway carrara 10 '' x 10 '' resin peel & stick backsplash panel in white/gray"
            },
            {
                "product_id": 26279,
                "product_name": "naveen shiplap 36 '' l x 20.5 '' w wallpaper panel"
            },
            {
                "product_id": 35682,
                "product_name": "musselwhite moroccan marble peel and stick wallpaper panel"
            },
            {
                "product_id": 38637,
                "product_name": "pakarad distressed peel and stick wallpaper panel"
            },
            {
                "product_id": 38631,
                "product_name": "sofiya removable peel and stick wallpaper panel"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 1653,
                "product_name": "agustin geometric room darkening grommet single curtain panel"
            },
            {
                "product_id": 1654,
                "product_name": "aaiyana striped sheer grommet single curtain panel"
            },
            {
                "product_id": 1657,
                "product_name": "solid color sheer grommet single curtain panel"
            },
            {
                "product_id": 27320,
                "product_name": "0.75 '' x 0.75 '' seashell tile"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            }
        ],
        "garOutput": [
            {
                "product_id": 11371,
                "product_name": "6 '' reclaimed solid wood wall paneling"
            },
            {
                "product_id": 14184,
                "product_name": "12 '' x 12 '' level loop carpet tile"
            },
            {
                "product_id": 11370,
                "product_name": "3.50 '' x 46.25 '' barnwood wall paneling"
            },
            {
                "product_id": 9838,
                "product_name": "polystyrene , primed white 1 7/8 '' h x 78 '' w x 3/8 '' d chair rail"
            },
            {
                "product_id": 25149,
                "product_name": "memory foam pad indoor dining chair cushion"
            }
        ],
        "query": "white splashproof shiplap wallpaper"
    },
    {
        "id": 95,
        "label": [
            {
                "product_id": 35296,
                "product_name": "pittard end table with storage"
            },
            {
                "product_id": 34550,
                "product_name": "portable bedside end table"
            },
            {
                "product_id": 40924,
                "product_name": "fagerlund 2 - drawer end table"
            },
            {
                "product_id": 25084,
                "product_name": "mote floor shelf end table with storage"
            },
            {
                "product_id": 5294,
                "product_name": "kranzo wooden end table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 40550,
                "product_name": "crosby end table with storage"
            },
            {
                "product_id": 24019,
                "product_name": "girardville slate recliner end table"
            },
            {
                "product_id": 8332,
                "product_name": "chantrell solid wood tv stand for tvs up to 78 ''"
            },
            {
                "product_id": 42498,
                "product_name": "piper end table"
            },
            {
                "product_id": 35056,
                "product_name": "haskins end table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8753,
                "product_name": "4 tier plant stand garden planter metal flower pot rack corner shelving indoor outdoor"
            },
            {
                "product_id": 1029,
                "product_name": "candelario bar with wine storage"
            },
            {
                "product_id": 12696,
                "product_name": "white 163 '' portable projection screen"
            },
            {
                "product_id": 8754,
                "product_name": "bamboo plant flower stand rack 2 tier corner tall flower bench holder indoor"
            },
            {
                "product_id": 2449,
                "product_name": "alberta 15 bottle wine bar"
            }
        ],
        "garOutput": [
            {
                "product_id": 13504,
                "product_name": "teak tripod end table"
            },
            {
                "product_id": 40127,
                "product_name": "lefever end table"
            },
            {
                "product_id": 20370,
                "product_name": "31.5 '' square fold-in-half portable folding table"
            },
            {
                "product_id": 20361,
                "product_name": "d\u00e9borah coffee table"
            },
            {
                "product_id": 38158,
                "product_name": "herlane 15 '' console table"
            }
        ],
        "query": "entertainment stand end table"
    },
    {
        "id": 51,
        "label": [
            {
                "product_id": 17598,
                "product_name": "midfield 6 drawer dresser"
            },
            {
                "product_id": 26040,
                "product_name": "aneke 5 drawer chest"
            },
            {
                "product_id": 37861,
                "product_name": "abbotstown 5 drawer standard dresser"
            },
            {
                "product_id": 11352,
                "product_name": "remer 7 - drawer double dresser in white"
            },
            {
                "product_id": 11232,
                "product_name": "fairway 6 drawer double dresser"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 26225,
                "product_name": "rowsley storage 6 drawer double dresser"
            },
            {
                "product_id": 26251,
                "product_name": "harnden 7 drawer dresser"
            },
            {
                "product_id": 33132,
                "product_name": "edwige 6 drawer double dresser"
            },
            {
                "product_id": 22143,
                "product_name": "aarron 7 drawer dresser"
            },
            {
                "product_id": 2661,
                "product_name": "erwan 5 drawer standard dresser/chest"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 14106,
                "product_name": "veronica 5 drawer chest"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 4321,
                "product_name": "dimitre 3 drawer chest"
            },
            {
                "product_id": 36032,
                "product_name": "deeksha storage cabinet"
            }
        ],
        "garOutput": [
            {
                "product_id": 26012,
                "product_name": "escarcega 7 drawer combo dresser"
            },
            {
                "product_id": 6579,
                "product_name": "charlie 6 drawer double dresser"
            },
            {
                "product_id": 29007,
                "product_name": "kuehn armoire"
            },
            {
                "product_id": 9891,
                "product_name": "stambaugh 44 '' console table"
            },
            {
                "product_id": 8114,
                "product_name": "anzell 2 door storage cabinet"
            }
        ],
        "query": "kohen 5 drawer dresser"
    },
    {
        "id": 244,
        "label": [
            {
                "product_id": 15684,
                "product_name": "getulio 74.8 '' square arm sleeper"
            },
            {
                "product_id": 39071,
                "product_name": "gittens 56.5 '' recessed arm sleeper"
            },
            {
                "product_id": 11085,
                "product_name": "giacomo convertible sleeper loveseat"
            },
            {
                "product_id": 41667,
                "product_name": "perlman 58 '' recessed arm sofa bed with reversible cushions"
            },
            {
                "product_id": 1207,
                "product_name": "bartlomie 74.02 '' linen armless sleeper"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 9675,
                "product_name": "kaya 76.5 '' flared arm sleeper"
            },
            {
                "product_id": 26332,
                "product_name": "stalbridge 71.5 '' armless sofa bed"
            },
            {
                "product_id": 37475,
                "product_name": "pepperell 81 '' linen square arm sofa bed"
            },
            {
                "product_id": 27019,
                "product_name": "pola 90 '' velvet square arm sleeper"
            },
            {
                "product_id": 31121,
                "product_name": "felisita 74.8 '' linen round arm sofa"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 26887,
                "product_name": "capems 65.35 '' faux leather pillow top arm sleeper"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 1325,
                "product_name": "aieshia 34.6 '' steel bed frame"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 25557,
                "product_name": "connolly tufted upholstered low profile standard bed"
            }
        ],
        "garOutput": [
            {
                "product_id": 6108,
                "product_name": "mccaffery 74 '' round arm sofa"
            },
            {
                "product_id": 41464,
                "product_name": "holguin convertible sofa"
            },
            {
                "product_id": 17539,
                "product_name": "78.35 '' square arm sleeper"
            },
            {
                "product_id": 15670,
                "product_name": "urban trundle unit"
            },
            {
                "product_id": 34357,
                "product_name": "gaura 2 piece sleeper living room set"
            }
        ],
        "query": "pull out sleeper loveseat"
    },
    {
        "id": 413,
        "label": [
            {
                "product_id": 19570,
                "product_name": "aidean patio sectional with cushions"
            },
            {
                "product_id": 19568,
                "product_name": "aiddan patio sectional with cushions"
            },
            {
                "product_id": 17856,
                "product_name": "patio sofa with cushions"
            },
            {
                "product_id": 21935,
                "product_name": "archambault patio sofa with cushions"
            },
            {
                "product_id": 39380,
                "product_name": "cristalyn outdoor 6 piece rattan sectional seating group with cushions"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 22452,
                "product_name": "francys 3 piece patio sofa seating group with cushions"
            },
            {
                "product_id": 9189,
                "product_name": "joacima 77.2 '' wide outdoor right hand facing patio sectional with cushions"
            },
            {
                "product_id": 2968,
                "product_name": "addiego patio sofa with cushions"
            },
            {
                "product_id": 32662,
                "product_name": "montford teak patio sofa with cushions"
            },
            {
                "product_id": 5327,
                "product_name": "contemporary patio sectional with cushions"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 18012,
                "product_name": "aditi patio 3 piece seating group with cushions"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 34594,
                "product_name": "conerly oval 27.6 '' dining table"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 26887,
                "product_name": "capems 65.35 '' faux leather pillow top arm sleeper"
            }
        ],
        "garOutput": [
            {
                "product_id": 33521,
                "product_name": "doly yland patio sofa with cushions"
            },
            {
                "product_id": 1572,
                "product_name": "elroy 32 '' wide tufted wingback chair and ottoman"
            },
            {
                "product_id": 15259,
                "product_name": "sarris conversation 3 piece seating group with cushions"
            },
            {
                "product_id": 40354,
                "product_name": "veals patio sectional with cushions"
            },
            {
                "product_id": 40355,
                "product_name": "rannie patio sectional with cushions"
            }
        ],
        "query": "burruss patio sectional with cushions"
    },
    {
        "id": 371,
        "label": [
            {
                "product_id": 33982,
                "product_name": "victorine patio chair"
            },
            {
                "product_id": 20470,
                "product_name": "exectuive chair"
            },
            {
                "product_id": 2600,
                "product_name": "barrowman tufted upholstered side chair in metallic platinum"
            },
            {
                "product_id": 23950,
                "product_name": "tufted velvet upholstered side chair"
            },
            {
                "product_id": 20699,
                "product_name": "ergonomic task chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 30398,
                "product_name": "nautical reclining chaise lounge"
            },
            {
                "product_id": 308,
                "product_name": "exectuive chair"
            },
            {
                "product_id": 31115,
                "product_name": "fahad 29.53 '' wide cowhide lounge chair"
            },
            {
                "product_id": 18576,
                "product_name": "pala chair"
            },
            {
                "product_id": 28597,
                "product_name": "pabai chair"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 38627,
                "product_name": "jurgen stool"
            },
            {
                "product_id": 32281,
                "product_name": "metal slat back stacking side chair"
            },
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 980,
                "product_name": "delato counter height pub table"
            }
        ],
        "garOutput": [
            {
                "product_id": 13504,
                "product_name": "teak tripod end table"
            },
            {
                "product_id": 34534,
                "product_name": "computer desk , walnut"
            },
            {
                "product_id": 5095,
                "product_name": "falko rooster figurine"
            },
            {
                "product_id": 41491,
                "product_name": "kymber round cotton pouf"
            },
            {
                "product_id": 19531,
                "product_name": "teak block end table"
            }
        ],
        "query": "milk cow chair"
    },
    {
        "id": 179,
        "label": [
            {
                "product_id": 16523,
                "product_name": "sp 20 amp interrupter"
            },
            {
                "product_id": 16523,
                "product_name": "sp 20 amp interrupter"
            }
        ],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "product_id": 16370,
                "product_name": "stikki clips white 20 per pack"
            },
            {
                "product_id": 6053,
                "product_name": "hot water dispenser"
            },
            {
                "product_id": 10671,
                "product_name": "59 '' closet rod"
            },
            {
                "product_id": 18943,
                "product_name": "sink leg"
            },
            {
                "product_id": 3941,
                "product_name": "modern standard single barn door hardware kit"
            }
        ],
        "garOutput": [
            {
                "product_id": 7896,
                "product_name": "cendrillon 2 - shelf storage cabinet"
            },
            {
                "product_id": 14443,
                "product_name": "filing cabinet parts"
            },
            {
                "product_id": 19922,
                "product_name": "shonna 25.2 '' w closet rod"
            },
            {
                "product_id": 7897,
                "product_name": "cendrillon 1 - drawer filing storage cabinet"
            },
            {
                "product_id": 12169,
                "product_name": "holli zollinger zodiac leo outdoor square pillow cover & insert"
            }
        ],
        "query": "promo codes or discounts"
    },
    {
        "id": 41,
        "label": [
            {
                "product_id": 32383,
                "product_name": "pull-out side mount belt rack"
            },
            {
                "product_id": 2639,
                "product_name": "sharpe 46.1 '' w clothes rack with rod storage shelf"
            },
            {
                "product_id": 32373,
                "product_name": "pull-out pants rack with full-extension"
            },
            {
                "product_id": 10675,
                "product_name": "jewelry organizer with bracelet rod ( farmhouse white )"
            },
            {
                "product_id": 27852,
                "product_name": "47.5 '' w with 4 shelves and 2 hanging rods garment rack"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 10409,
                "product_name": "freyja 9.75 '' w closet rod"
            },
            {
                "product_id": 12530,
                "product_name": "grid 7 hook hanging organizer"
            },
            {
                "product_id": 21722,
                "product_name": "lipsey wardrobe/bedroom rail lift pull-down hanging organizer"
            },
            {
                "product_id": 32379,
                "product_name": "2 '' w pull-out side mount tie rack"
            },
            {
                "product_id": 10415,
                "product_name": "valet rod"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 39407,
                "product_name": "allicen 2 -drawer storage cabinet"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 36032,
                "product_name": "deeksha storage cabinet"
            },
            {
                "product_id": 19708,
                "product_name": "waterfall single hole bathroom faucet"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 23409,
                "product_name": "niobrara pilltop coat and hat wall hook"
            },
            {
                "product_id": 27287,
                "product_name": "melbreak wardrobe"
            },
            {
                "product_id": 31824,
                "product_name": "10 pair hanging shoe organizer"
            },
            {
                "product_id": 4532,
                "product_name": "kenzie armoire"
            },
            {
                "product_id": 31093,
                "product_name": "configurations belt rack"
            }
        ],
        "query": "closet pull out valet rod"
    },
    {
        "id": 380,
        "label": [
            {
                "product_id": 384,
                "product_name": "bird playground parakeet play stand cockatiel play gym conure perch parrot playstand with nesting box feeder cups and ladder"
            },
            {
                "product_id": 11424,
                "product_name": "squngee deluxe squirrel feeder"
            },
            {
                "product_id": 30550,
                "product_name": "digital two meal feeder"
            },
            {
                "product_id": 12441,
                "product_name": "sunflower decorative bird feeder"
            },
            {
                "product_id": 6175,
                "product_name": "adjustable feeder - med - graphite"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 27450,
                "product_name": "automatic feeder"
            },
            {
                "product_id": 6025,
                "product_name": "pet cat dog automatic feeders dispenser automatic gravity feeders bottle stand gray"
            },
            {
                "product_id": 11099,
                "product_name": "steel sunflower decorative bird feeder"
            },
            {
                "product_id": 27634,
                "product_name": "gravity automatic water dish"
            },
            {
                "product_id": 30713,
                "product_name": "errol 28 lbs feeder"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 20718,
                "product_name": "abramowski monitor stand"
            },
            {
                "product_id": 25968,
                "product_name": "500 lbs . capacity hand truck dolly"
            },
            {
                "product_id": 3196,
                "product_name": "hang ease c type shower curtain hooks"
            },
            {
                "product_id": 31990,
                "product_name": "closet rod"
            }
        ],
        "garOutput": [
            {
                "product_id": 32932,
                "product_name": "elevated rolling laundry & utility cart"
            },
            {
                "product_id": 11350,
                "product_name": "collapsible laundry dirty clothes hamper"
            },
            {
                "product_id": 26267,
                "product_name": "scoville bread box"
            },
            {
                "product_id": 9973,
                "product_name": "fruit vegetable wire basket set"
            },
            {
                "product_id": 6672,
                "product_name": "berkey cachepot 4 piece jute basket set"
            }
        ],
        "query": "gravity feeder"
    },
    {
        "id": 209,
        "label": [
            {
                "product_id": 33715,
                "product_name": "cabinet 4 '' center to center bar pull multipack"
            },
            {
                "product_id": 35941,
                "product_name": "square door handle/pull"
            },
            {
                "product_id": 1627,
                "product_name": "river 3 3/4 '' center to center handle bar pull"
            },
            {
                "product_id": 24226,
                "product_name": "2 3/16 '' center to center finger pull"
            },
            {
                "product_id": 7994,
                "product_name": "origins 4 '' center to center bar pull"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 11405,
                "product_name": "21 '' center to center finger pull"
            },
            {
                "product_id": 11555,
                "product_name": "straight 5 1/16 '' center to center bar pull"
            },
            {
                "product_id": 17446,
                "product_name": "8 3/4 '' center to center bar pull"
            },
            {
                "product_id": 6993,
                "product_name": "square cabinet 12 1/2 '' center to center bar pull"
            },
            {
                "product_id": 20701,
                "product_name": "sullivan cabinet 3 3/4 '' center to center bar pull"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 39991,
                "product_name": "bathroom rain volume shower system rough-in valve"
            },
            {
                "product_id": 9200,
                "product_name": "mirrored console table"
            },
            {
                "product_id": 36451,
                "product_name": "aneka 7 '' table lamp"
            },
            {
                "product_id": 19246,
                "product_name": "coughlin 47 '' console table"
            },
            {
                "product_id": 32559,
                "product_name": "grid mirror"
            }
        ],
        "garOutput": [
            {
                "product_id": 41649,
                "product_name": "custom broadway hall-closet and bed-bath lever with century trim"
            },
            {
                "product_id": 41650,
                "product_name": "custom broadway hall-closet and bed-bath lever with collins trim"
            },
            {
                "product_id": 41646,
                "product_name": "custom broadway hall-closet and bed-bath lever with kinsler trim"
            },
            {
                "product_id": 3735,
                "product_name": "stroman rocking chair"
            },
            {
                "product_id": 24317,
                "product_name": "tustin 30 '' bar stool"
            }
        ],
        "query": "door pulls bronze matte 4 inch"
    },
    {
        "id": 286,
        "label": [
            {
                "product_id": 29517,
                "product_name": "deserie 3 piece wall shelf set"
            },
            {
                "product_id": 10418,
                "product_name": "bathroom wall shelf"
            },
            {
                "product_id": 2117,
                "product_name": "double roll stainless steel wall mount toilet paper holder with phone shelf"
            },
            {
                "product_id": 36206,
                "product_name": "klemm 2 industrial pipe wall shelf"
            },
            {
                "product_id": 18497,
                "product_name": "lodewijk bath foldable design wall shelf"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 36506,
                "product_name": "mccurley two tier wall shelf"
            },
            {
                "product_id": 30875,
                "product_name": "contemporary iii wall shelf"
            },
            {
                "product_id": 30147,
                "product_name": "wall shelf"
            },
            {
                "product_id": 34406,
                "product_name": "aberdeen 3 piece circle mango wood accent shelf"
            },
            {
                "product_id": 31738,
                "product_name": "akeeyla wall shelf"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 9209,
                "product_name": "colby 6 drawer double dresser"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 14229,
                "product_name": "homestead dining table"
            },
            {
                "product_id": 41069,
                "product_name": "home is where our herd is wall d\u00e9cor"
            }
        ],
        "garOutput": [
            {
                "product_id": 22986,
                "product_name": "turquoise succulent - wrapped canvas photograph print"
            },
            {
                "product_id": 7531,
                "product_name": "riser"
            },
            {
                "product_id": 2178,
                "product_name": "kara folding 2 piece tray table set"
            },
            {
                "product_id": 2179,
                "product_name": "alison folding 4 piece tray table set"
            },
            {
                "product_id": 7897,
                "product_name": "cendrillon 1 - drawer filing storage cabinet"
            }
        ],
        "query": "hitchcock mid-century wall shelf"
    },
    {
        "id": 258,
        "label": [
            {
                "product_id": 13660,
                "product_name": "3 piece counter height pub table set"
            },
            {
                "product_id": 980,
                "product_name": "delato counter height pub table"
            },
            {
                "product_id": 16084,
                "product_name": "stella 3 piece dining table set , wooden kitchen table set with wine rack and metal frame , small dining room table and 2 chairs set for breakfast , home , kitchen studio ( 1x 32inch table + 2x chairs )"
            },
            {
                "product_id": 26390,
                "product_name": "skylark pub table set"
            },
            {
                "product_id": 29724,
                "product_name": "5 pcs rattan wicker bar table chair set , 4 bar chairs and 1 wood grain top bar table mixed brown"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 22758,
                "product_name": "espresso topmax 5-piece bar table set , counter height bar table with 4 bar stools , bistro style bar table and stool"
            },
            {
                "product_id": 31498,
                "product_name": "apul counter height pub table"
            },
            {
                "product_id": 31222,
                "product_name": "bradenton square counter height pub table"
            },
            {
                "product_id": 11003,
                "product_name": "clitherall 3 - piece counter height dining set"
            },
            {
                "product_id": 15894,
                "product_name": "holland bar stool 36 '' l x 36 '' w square rounded table top"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 1029,
                "product_name": "candelario bar with wine storage"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 30158,
                "product_name": "hershberger buffet table"
            }
        ],
        "garOutput": [
            {
                "product_id": 20370,
                "product_name": "31.5 '' square fold-in-half portable folding table"
            },
            {
                "product_id": 13504,
                "product_name": "teak tripod end table"
            },
            {
                "product_id": 39941,
                "product_name": "kurth industrial rectangular dining table"
            },
            {
                "product_id": 34400,
                "product_name": "emestina rustic dining table"
            },
            {
                "product_id": 3705,
                "product_name": "derbyshire bar & counter stool"
            }
        ],
        "query": "breakfast bar table"
    },
    {
        "id": 404,
        "label": [
            {
                "product_id": 38239,
                "product_name": "darcio indoor/outdoor dining chair cushion"
            },
            {
                "product_id": 17096,
                "product_name": "rotan 32 '' wide down cushion wingback chair"
            },
            {
                "product_id": 9632,
                "product_name": "texture outdoor bench cushion"
            },
            {
                "product_id": 24369,
                "product_name": "barcomb outdoor 5 piece sectional seating group with cushions"
            },
            {
                "product_id": 14785,
                "product_name": "garrow 75.5 '' wide outdoor patio sofas with cushions"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 32171,
                "product_name": "falmouth 70 '' wide outdoor wicker loveseat with cushions"
            },
            {
                "product_id": 1171,
                "product_name": "mccay outdoor loveseat cushion"
            },
            {
                "product_id": 33628,
                "product_name": "hanwell rocking chair with cushions"
            },
            {
                "product_id": 30445,
                "product_name": "outdoor dining chair cushion"
            },
            {
                "product_id": 202,
                "product_name": "chair indoor seat cushion"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 31143,
                "product_name": "amilliah tufted upholstered parsons chair"
            },
            {
                "product_id": 26887,
                "product_name": "capems 65.35 '' faux leather pillow top arm sleeper"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            }
        ],
        "garOutput": [
            {
                "product_id": 18798,
                "product_name": "liam 33.5 '' wide faux leather power lift assist standard recliner"
            },
            {
                "product_id": 3648,
                "product_name": "jarreth indoor/outdoor sunbrella chaise lounge cushion"
            },
            {
                "product_id": 36041,
                "product_name": "anu ? a chaise lounge with cushion"
            },
            {
                "product_id": 37154,
                "product_name": "alaxis 33 '' wide faux leather power lift assist standard recliner"
            },
            {
                "product_id": 6492,
                "product_name": "ginelie 113.36 '' wide outdoor wicker u-shaped patio sectional with cushions"
            }
        ],
        "query": "large cushion outdoor chair"
    },
    {
        "id": 4,
        "label": [
            {
                "product_id": 29700,
                "product_name": "petros recliner patio chair with cushions"
            },
            {
                "product_id": 5488,
                "product_name": "ajete 42 '' wide manual standard recliner"
            },
            {
                "product_id": 1073,
                "product_name": "pakswith 40.55 '' wide top grain leather chair and a half"
            },
            {
                "product_id": 24876,
                "product_name": "dacorey 35 '' wide manual standard recliner"
            },
            {
                "product_id": 17679,
                "product_name": "jentoft 61.42 '' wide manual standard recliner"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 19889,
                "product_name": "dioseline 34 '' wide velvet power lift assist standard recliner"
            },
            {
                "product_id": 41953,
                "product_name": "annapaula 31.5 '' wide manual standard recliner with massager"
            },
            {
                "product_id": 16598,
                "product_name": "amann 50 '' wide manual standard recliner"
            },
            {
                "product_id": 12380,
                "product_name": "power home theater recliner individual seating"
            },
            {
                "product_id": 3396,
                "product_name": "seeker rocking recliner"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 31143,
                "product_name": "amilliah tufted upholstered parsons chair"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 26887,
                "product_name": "capems 65.35 '' faux leather pillow top arm sleeper"
            }
        ],
        "garOutput": [
            {
                "product_id": 19684,
                "product_name": "denilson 32.2 '' wide power lift assist standard recliner"
            },
            {
                "product_id": 23186,
                "product_name": "podrick 64 '' pillow top arm reclining loveseat"
            },
            {
                "product_id": 23185,
                "product_name": "podrick 76 '' pillow top arm reclining loveseat"
            },
            {
                "product_id": 37073,
                "product_name": "doraly 37 '' wide faux leather power lift assist standard recliner with massager"
            },
            {
                "product_id": 17222,
                "product_name": "ingmari 35 '' wide manual glider standard recliner"
            }
        ],
        "query": "chair and a half recliner"
    },
    {
        "id": 260,
        "label": [
            {
                "product_id": 34593,
                "product_name": "retractable side awning"
            },
            {
                "product_id": 5675,
                "product_name": "motorized retractable slope patio awning"
            },
            {
                "product_id": 29679,
                "product_name": "manual retraction slope patio awning"
            },
            {
                "product_id": 17874,
                "product_name": "12 ft. w x 10 ft. d metal standard patio awning"
            },
            {
                "product_id": 1400,
                "product_name": "13 ft. w x 10 ft. d fabric retractable standard patio awning"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 41300,
                "product_name": "patio 118 '' retractable side awning"
            },
            {
                "product_id": 5670,
                "product_name": "aleko 118 '' w x 96 '' d fabric manual retraction slope patio awning"
            },
            {
                "product_id": 41357,
                "product_name": "manual retraction side awning in brown"
            },
            {
                "product_id": 41291,
                "product_name": "retraction side awning in brown"
            },
            {
                "product_id": 36875,
                "product_name": "118 '' d side awning in coffee"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 32482,
                "product_name": "krause 16 '' height profile platform bed"
            },
            {
                "product_id": 19249,
                "product_name": "bassett kids desk"
            },
            {
                "product_id": 14495,
                "product_name": "twin over full bunk bed"
            },
            {
                "product_id": 1653,
                "product_name": "agustin geometric room darkening grommet single curtain panel"
            },
            {
                "product_id": 37195,
                "product_name": "wood platform bed with headboard , wood slat , queen"
            }
        ],
        "garOutput": [
            {
                "product_id": 21740,
                "product_name": "ledya adjustable 0.63 '' curtain rod"
            },
            {
                "product_id": 40892,
                "product_name": "4 ft. h x 2 ft. w vinyl privacy screen"
            },
            {
                "product_id": 29711,
                "product_name": "144 '' w x 120 '' d retraction patio awning"
            },
            {
                "product_id": 34593,
                "product_name": "retractable side awning"
            },
            {
                "product_id": 41300,
                "product_name": "patio 118 '' retractable side awning"
            }
        ],
        "query": "retractable side awning"
    },
    {
        "id": 437,
        "label": [
            {
                "product_id": 23645,
                "product_name": "lockard kitchen island set"
            },
            {
                "product_id": 6480,
                "product_name": "elet kitchen island"
            },
            {
                "product_id": 8165,
                "product_name": "haslingden kitchen island set with granite top"
            },
            {
                "product_id": 29158,
                "product_name": "sereno kitchen island with butcher block top"
            },
            {
                "product_id": 36697,
                "product_name": "drolet kitchen cart solid wood"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 2437,
                "product_name": "gourmet counter height pub table"
            },
            {
                "product_id": 16003,
                "product_name": "clements kitchen island with granite top"
            },
            {
                "product_id": 796,
                "product_name": "carrolltown kitchen island"
            },
            {
                "product_id": 29341,
                "product_name": "magdaleno pub dining table"
            },
            {
                "product_id": 9783,
                "product_name": "moser kitchen island with faux marble top"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 38244,
                "product_name": "agirta extendable dining table"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 17223,
                "product_name": "buffet storage cabinet with single glass doors and unique bell handle"
            },
            {
                "product_id": 30158,
                "product_name": "hershberger buffet table"
            }
        ],
        "garOutput": [
            {
                "product_id": 32461,
                "product_name": "saroyan 2 piece coffee table set"
            },
            {
                "product_id": 2222,
                "product_name": "eulises bar with wine storage"
            },
            {
                "product_id": 9987,
                "product_name": "43 '' w closet system"
            },
            {
                "product_id": 22314,
                "product_name": "aadhira recliner patio chair with cushions"
            },
            {
                "product_id": 21234,
                "product_name": "sweet outdoor rectangular pillow cover & insert"
            }
        ],
        "query": "kitchen islands with seating"
    },
    {
        "id": 100,
        "label": [
            {
                "product_id": 39508,
                "product_name": "deyo upholstered platform bed"
            },
            {
                "product_id": 20604,
                "product_name": "kayden tufted upholstered low profile standard bed"
            },
            {
                "product_id": 42939,
                "product_name": "bentsworth upholstered low profile sleigh bed"
            },
            {
                "product_id": 29408,
                "product_name": "shekhar wooden full upholstered standard bed"
            },
            {
                "product_id": 41962,
                "product_name": "queen tufted standard bed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 24049,
                "product_name": "goldsmith upholstered storage platform bed"
            },
            {
                "product_id": 17155,
                "product_name": "jarratt upholstered standard bed"
            },
            {
                "product_id": 32261,
                "product_name": "vanalstyne queen upholstered low profile four poster bed"
            },
            {
                "product_id": 10736,
                "product_name": "sieber tufted upholstered low profile standard bed"
            },
            {
                "product_id": 4681,
                "product_name": "taylor cove upholstered standard bed"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 40626,
                "product_name": "rosemead velvet chaise lounge"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 14894,
                "product_name": "genuine leather upholstered dining chair"
            },
            {
                "product_id": 37192,
                "product_name": "wood platform bed with two drawers , full ( white )"
            }
        ],
        "garOutput": [
            {
                "product_id": 32245,
                "product_name": "elington tufted upholstered platform bed"
            },
            {
                "product_id": 32412,
                "product_name": "louque queen upholstered platform bed"
            },
            {
                "product_id": 37936,
                "product_name": "kachig king upholstered platform bed"
            },
            {
                "product_id": 41770,
                "product_name": "credle tufted low profile standard bed"
            },
            {
                "product_id": 41201,
                "product_name": "belluno tufted upholstered platform bed"
            }
        ],
        "query": "tufted upholstered bed diamond"
    },
    {
        "id": 230,
        "label": [
            {
                "product_id": 7994,
                "product_name": "origins 4 '' center to center bar pull"
            },
            {
                "product_id": 19748,
                "product_name": "fon-62 gold"
            },
            {
                "product_id": 42620,
                "product_name": "roimata resin golden hands accent tabletop fountain with light"
            },
            {
                "product_id": 18148,
                "product_name": "aden awards night gold star runner black/gold"
            },
            {
                "product_id": 33150,
                "product_name": "cowart gold metal wall mirror"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 31870,
                "product_name": "german silver gold agate throw pillow"
            },
            {
                "product_id": 32063,
                "product_name": "gray gold venetian"
            },
            {
                "product_id": 7644,
                "product_name": "telscombe tiered stand"
            },
            {
                "product_id": 14187,
                "product_name": "northport rose gold baroque picture frame"
            },
            {
                "product_id": 17460,
                "product_name": "european knurled solid steel bar pull"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 10349,
                "product_name": "eira trestle coffee table"
            },
            {
                "product_id": 14448,
                "product_name": "broder stool"
            },
            {
                "product_id": 16370,
                "product_name": "stikki clips white 20 per pack"
            },
            {
                "product_id": 31617,
                "product_name": "wilkes star wars yoda u.s. stamp sheet wood picture frame"
            }
        ],
        "garOutput": [
            {
                "product_id": 22384,
                "product_name": "repousse cake knife"
            },
            {
                "product_id": 26235,
                "product_name": "targhee pig calendar figurine"
            },
            {
                "product_id": 39884,
                "product_name": "melendez wall clock"
            },
            {
                "product_id": 15162,
                "product_name": "river pebbles"
            },
            {
                "product_id": 15150,
                "product_name": "orion 12 '' x 12 '' natural stone pebbles mosaic wall & floor tile"
            }
        ],
        "query": "gold"
    },
    {
        "id": 417,
        "label": [
            {
                "product_id": 25337,
                "product_name": "mccarter twin over full solid wood standard bunk bed"
            },
            {
                "product_id": 23814,
                "product_name": "tufted upholstered platform bed"
            },
            {
                "product_id": 34922,
                "product_name": "turbocharged twin car bed"
            },
            {
                "product_id": 25044,
                "product_name": "dauberville queen tufted upholstered platform bed"
            },
            {
                "product_id": 10838,
                "product_name": "galvan junior metal twin low loft bed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 25009,
                "product_name": "valentina upholstered twin daybed with trundle"
            },
            {
                "product_id": 27322,
                "product_name": "amelia twin loft bed with drawers and bookcase"
            },
            {
                "product_id": 41926,
                "product_name": "ashpreet twin standard bed with trundle"
            },
            {
                "product_id": 24205,
                "product_name": "platform bed"
            },
            {
                "product_id": 13237,
                "product_name": "brinkley 14 '' steel platform bed"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 10078,
                "product_name": "finbar twin over twin bunk bed"
            },
            {
                "product_id": 37192,
                "product_name": "wood platform bed with two drawers , full ( white )"
            },
            {
                "product_id": 15678,
                "product_name": "bed frame"
            },
            {
                "product_id": 14495,
                "product_name": "twin over full bunk bed"
            },
            {
                "product_id": 37195,
                "product_name": "wood platform bed with headboard , wood slat , queen"
            }
        ],
        "garOutput": [
            {
                "product_id": 42882,
                "product_name": "vosburgh solid wood platform bed"
            },
            {
                "product_id": 5519,
                "product_name": "zoe 18 '' wire-grid bed frame"
            },
            {
                "product_id": 16344,
                "product_name": "atalaya insta-lock adjustable 6-leg bed frame"
            },
            {
                "product_id": 37776,
                "product_name": "annancy 42.9 '' steel bed frame"
            },
            {
                "product_id": 29466,
                "product_name": "hanley heavy duty bed frame"
            }
        ],
        "query": "twin bed frame"
    },
    {
        "id": 216,
        "label": [
            {
                "product_id": 28373,
                "product_name": "new york 2-gang toggle light switch wall plate"
            },
            {
                "product_id": 13973,
                "product_name": "classic beaded 2-gang toggle light switch / rocker combination wall plate"
            },
            {
                "product_id": 10596,
                "product_name": "music note vector art 1-gang toggle light switch wall plate"
            },
            {
                "product_id": 13973,
                "product_name": "classic beaded 2-gang toggle light switch / rocker combination wall plate"
            },
            {
                "product_id": 10610,
                "product_name": "light dots 1-gang toggle light switch wall plate"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 42839,
                "product_name": "mulholland 2-gang toggle light switch wall plate"
            },
            {
                "product_id": 10600,
                "product_name": "lips singing music notes 1-gang toggle light switch wall plate"
            },
            {
                "product_id": 10520,
                "product_name": "atom 1-gang toggle light switch wall plate"
            },
            {
                "product_id": 23590,
                "product_name": "adorne 2-gang duplex outlet wall plate"
            },
            {
                "product_id": 10612,
                "product_name": "light bulb with glowing high light effect 1-gang toggle light switch wall plate"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 9624,
                "product_name": "military challenge coin cabinet rack holder display case"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            }
        ],
        "garOutput": [
            {
                "product_id": 35993,
                "product_name": "16.4 ft led strip light , 528 leds cob bendable tape light , 4000k cool white , ul listed"
            },
            {
                "product_id": 35992,
                "product_name": "16.4 ft led strip light , 528 leds cob bendable tape light , 2700k soft white , ul listed"
            },
            {
                "product_id": 30083,
                "product_name": "home sweet home tops wall d\u00e9cor"
            },
            {
                "product_id": 42312,
                "product_name": "ceiling fan canoies"
            },
            {
                "product_id": 23591,
                "product_name": "adorne 1-gang rocker wall plate"
            }
        ],
        "query": "music plug wall plate"
    },
    {
        "id": 172,
        "label": [
            {
                "product_id": 19487,
                "product_name": "dance of youth and petit fleurs by pablo picasso - picture frame painting print set on paper"
            },
            {
                "product_id": 4702,
                "product_name": "tapestry picasso box cushion futon slipcover"
            },
            {
                "product_id": 40931,
                "product_name": "dove of peace by pablo picasso - picture frame print on paper"
            },
            {
                "product_id": 19478,
                "product_name": "dove of peace by pablo picasso - drawing print print"
            },
            {
                "product_id": 19476,
                "product_name": "la petit fleurs by pablo picasso - wrapped canvas painting print"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 39145,
                "product_name": "the pigeon with green peas by pablo picasso painting print on wrapped canvas"
            },
            {
                "product_id": 19490,
                "product_name": "dream by pablo picasso - floater frame print on canvas"
            },
            {
                "product_id": 37426,
                "product_name": "ortis picasso inspired indoor/outdoor cotton throw pillow"
            },
            {
                "product_id": 12150,
                "product_name": "landscape with two figures by pablo picasso - painting print"
            },
            {
                "product_id": 10464,
                "product_name": "holst picasso painting bowl of fruit and guitar hand towel"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 30826,
                "product_name": "belgr concrete propane/natural gas fire pit table"
            },
            {
                "product_id": 13858,
                "product_name": "the elephant graphic art"
            },
            {
                "product_id": 29144,
                "product_name": "mcdonald dinosaur lake canvas art"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            }
        ],
        "garOutput": [
            {
                "product_id": 40934,
                "product_name": "by pablo picasso - 4 piece picture frame drawing print set on paper"
            },
            {
                "product_id": 40932,
                "product_name": "le chien ( the dog ) by pablo picasso - picture frame print on paper"
            },
            {
                "product_id": 29761,
                "product_name": "la grand rue , argenteuil sisley framed painting"
            },
            {
                "product_id": 8152,
                "product_name": "the owl by nicklas gustafsson - wrapped canvas graphic art print"
            },
            {
                "product_id": 13861,
                "product_name": "regatta at argenteuil by claude monet painting print on wrapped canvas"
            }
        ],
        "query": "picasso"
    },
    {
        "id": 98,
        "label": [
            {
                "product_id": 7266,
                "product_name": "60 '' arnos 5 - blade standard ceiling fan"
            },
            {
                "product_id": 29388,
                "product_name": "62 '' 3 - blade standard ceiling fan with pull chain"
            },
            {
                "product_id": 41908,
                "product_name": "ceiling mount 110 cfm energy star bathroom fan with light"
            },
            {
                "product_id": 1797,
                "product_name": "4.5 '' h glass bell ceiling fan fitter shade ( screw on ) in caramel"
            },
            {
                "product_id": 10974,
                "product_name": "52 '' raynham 5 - blade flush mount ceiling fan with pull chain"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 16023,
                "product_name": "52 '' divisadero 5 - blade standard ceiling fan with remote control and light kit included"
            },
            {
                "product_id": 10969,
                "product_name": "52 '' theron 5 - blade standard ceiling fan with light kit included"
            },
            {
                "product_id": 17027,
                "product_name": "52 '' barys 4 - blade outdoor standard ceiling fan with wall control and light kit included"
            },
            {
                "product_id": 39373,
                "product_name": "42 '' alayna 4 - blade ceiling fan with pull chain and light kit included"
            },
            {
                "product_id": 7267,
                "product_name": "52 '' tenbury 5 - blade standard ceiling fan"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 13957,
                "product_name": "galeton tv stand for tvs up to 88 ''"
            },
            {
                "product_id": 22032,
                "product_name": "dining table with leaf"
            },
            {
                "product_id": 15640,
                "product_name": "fixturedisplays single picture frame"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            }
        ],
        "garOutput": [
            {
                "product_id": 2104,
                "product_name": "icelandia 1-light wall sconce"
            },
            {
                "product_id": 12320,
                "product_name": "soft square 3 3/4 '' center to center bar pull"
            },
            {
                "product_id": 30227,
                "product_name": "common core 24 '' x 24 '' porcelain metal look wall & floor tile"
            },
            {
                "product_id": 26838,
                "product_name": "all steel safe with dual-lock"
            },
            {
                "product_id": 14075,
                "product_name": "let that sh * * go buddhist-meditation yoga-buddha 15 oz . all purpose wine glass"
            }
        ],
        "query": "gracie oaks 62 oller 14 ceiling fan"
    },
    {
        "id": 296,
        "label": [
            {
                "product_id": 28611,
                "product_name": "aben twin platform bed"
            },
            {
                "product_id": 27305,
                "product_name": "bolin platform bed"
            },
            {
                "product_id": 4338,
                "product_name": "scarlett upholstered low profile platform bed"
            },
            {
                "product_id": 7014,
                "product_name": "asmahan twin platform bed"
            },
            {
                "product_id": 30891,
                "product_name": "dorotheus twin platform bed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 26322,
                "product_name": "pompano twin platform bed with trundle"
            },
            {
                "product_id": 17731,
                "product_name": "forsan tufted upholstered low profile platform bed"
            },
            {
                "product_id": 30890,
                "product_name": "dorotheia twin platform bed"
            },
            {
                "product_id": 18824,
                "product_name": "prasanna house twin platform bed with trundle"
            },
            {
                "product_id": 18814,
                "product_name": "charge twin storage platform bed"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 42966,
                "product_name": "mershon 2-light outdoor bulkhead light"
            },
            {
                "product_id": 12696,
                "product_name": "white 163 '' portable projection screen"
            },
            {
                "product_id": 8754,
                "product_name": "bamboo plant flower stand rack 2 tier corner tall flower bench holder indoor"
            },
            {
                "product_id": 3824,
                "product_name": "woodridge 6 ft. w x 5 ft. d storage shed"
            },
            {
                "product_id": 32482,
                "product_name": "krause 16 '' height profile platform bed"
            }
        ],
        "garOutput": [
            {
                "product_id": 20622,
                "product_name": "34.5 '' steel bed frame"
            },
            {
                "product_id": 28512,
                "product_name": "arjunveer sleeper bed tufted ottoman"
            },
            {
                "product_id": 8122,
                "product_name": "ferebee 22 '' wide slipper chair"
            },
            {
                "product_id": 8121,
                "product_name": "ferebee 22.5 '' wide slipper chair"
            },
            {
                "product_id": 22034,
                "product_name": "inko twin over full bunk bed"
            }
        ],
        "query": "zakariyah twin platform"
    },
    {
        "id": 219,
        "label": [
            {
                "product_id": 6038,
                "product_name": "8500 series open towel ring"
            },
            {
                "product_id": 10423,
                "product_name": "beresford wall mounted towel bar"
            },
            {
                "product_id": 13990,
                "product_name": "maxted towel ring"
            },
            {
                "product_id": 1669,
                "product_name": "24 '' wall mounted multi rod towel rack"
            },
            {
                "product_id": 10406,
                "product_name": "beresford wall mounted towel ring"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 10427,
                "product_name": "bayer towel ring"
            },
            {
                "product_id": 10680,
                "product_name": "wall mounted swing towel bar - silver stainless steel bath towel rod arm , bathroom/kitchen swivel towel rack hanger holder organizer , folding space saver towel rail ( 4 bar black )"
            },
            {
                "product_id": 17944,
                "product_name": "musa wall mounted towel ring"
            },
            {
                "product_id": 22276,
                "product_name": "finial 24 '' wall mounted towel bar"
            },
            {
                "product_id": 10425,
                "product_name": "beresford 18 '' wall mounted towel bar"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 32518,
                "product_name": "elite rack"
            },
            {
                "product_id": 13098,
                "product_name": "premium heavyweight 9 '' melamine salad or dessert plate"
            },
            {
                "product_id": 13623,
                "product_name": "2 person traditional steam sauna"
            }
        ],
        "garOutput": [
            {
                "product_id": 40735,
                "product_name": "p-trap lavatory kit with valves and risers"
            },
            {
                "product_id": 31984,
                "product_name": "metal 4 - hook wall mounted coat rack"
            },
            {
                "product_id": 18558,
                "product_name": "round folding laundry hamper"
            },
            {
                "product_id": 28514,
                "product_name": "rebrilliant rolling laundry hamper"
            },
            {
                "product_id": 25060,
                "product_name": "franklin heavy duty bed frame"
            }
        ],
        "query": "trinaic towel rod"
    },
    {
        "id": 61,
        "label": [
            {
                "product_id": 26981,
                "product_name": "lawley solid wood bar & counter stool"
            },
            {
                "product_id": 33002,
                "product_name": "yanique bar & counter stool"
            },
            {
                "product_id": 24377,
                "product_name": "hinton solid wood bar & counter stool"
            },
            {
                "product_id": 3684,
                "product_name": "clermont rattan counter & bar stool"
            },
            {
                "product_id": 37357,
                "product_name": "mack swivel solid wood 24 '' counter stool"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 6505,
                "product_name": "angelia solid wood bar stool"
            },
            {
                "product_id": 17462,
                "product_name": "overstreet rustic pub table"
            },
            {
                "product_id": 7646,
                "product_name": "barnard 29 '' bar stool"
            },
            {
                "product_id": 24675,
                "product_name": "dunwoody 24 '' bar stool"
            },
            {
                "product_id": 7638,
                "product_name": "lisdale metal accent stool"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 980,
                "product_name": "delato counter height pub table"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            },
            {
                "product_id": 10377,
                "product_name": "avabella trestle coffee table"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            }
        ],
        "garOutput": [
            {
                "product_id": 20454,
                "product_name": "hansford 6 '' x 6 '' ceramic field tile"
            },
            {
                "product_id": 23027,
                "product_name": "agnes 24 '' velvet round solid cocktail ottoman"
            },
            {
                "product_id": 10541,
                "product_name": "mccullum funny cute goat mountain climbing or rock climbing cartoon coffee mug"
            },
            {
                "product_id": 2182,
                "product_name": "3-tier printer cart with storage shelf mobile printer stand end table home office"
            },
            {
                "product_id": 8031,
                "product_name": "flaugher happy camper personalized coffee mug"
            }
        ],
        "query": "overstreet rustic pub stools"
    },
    {
        "id": 47,
        "label": [
            {
                "product_id": 34086,
                "product_name": "studiocuisine 5.75 '' w x 9.5 '' l non-stick carbon steel loaf pan"
            },
            {
                "product_id": 3933,
                "product_name": "ayesha collection nonstick bakeware cake pan and cookie pan set , 3-piece , copper"
            },
            {
                "product_id": 6642,
                "product_name": "demdaco made for you loaf pan"
            },
            {
                "product_id": 2107,
                "product_name": "anolon advanced loaf pan"
            },
            {
                "product_id": 32623,
                "product_name": "staub cast iron non-stick loaf pan"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 4068,
                "product_name": "berghoff international scala non-stick loaf pan"
            },
            {
                "product_id": 25480,
                "product_name": "laprade bermuda ceramic loaf pan"
            },
            {
                "product_id": 38399,
                "product_name": "vietri loaf pan"
            },
            {
                "product_id": 41990,
                "product_name": "loaf pan"
            },
            {
                "product_id": 7547,
                "product_name": "circulon non-stick 6 cup mini loaf pan"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 38627,
                "product_name": "jurgen stool"
            },
            {
                "product_id": 890,
                "product_name": "sapphira reversible quilt set"
            },
            {
                "product_id": 7839,
                "product_name": "citadel sunflower fields cheese board"
            },
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            },
            {
                "product_id": 32169,
                "product_name": "flint shell platter"
            }
        ],
        "garOutput": [
            {
                "product_id": 37614,
                "product_name": "3 piece dima mirror set"
            },
            {
                "product_id": 28978,
                "product_name": "versailles 4 piece kitchen canister set"
            },
            {
                "product_id": 11321,
                "product_name": "2 piece spoon and fork metal wall d\u00e9cor set"
            },
            {
                "product_id": 39463,
                "product_name": "woodoaks coffee table"
            },
            {
                "product_id": 7447,
                "product_name": "23.5 '' w x 31.5 '' h x 12 '' d free-standing bathroom cabinet"
            }
        ],
        "query": "porcelain loaf pan"
    },
    {
        "id": 32,
        "label": [
            {
                "product_id": 21689,
                "product_name": "crescent 3 drawer combo dresser"
            },
            {
                "product_id": 37932,
                "product_name": "herschel 6 drawer double dresser"
            },
            {
                "product_id": 35138,
                "product_name": "tassio 6 drawer double dresser"
            },
            {
                "product_id": 5948,
                "product_name": "essex 6 drawer double dresser"
            },
            {
                "product_id": 35157,
                "product_name": "gravity 6 drawer double dresser"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 7915,
                "product_name": "westerleigh 4 drawer chest"
            },
            {
                "product_id": 8231,
                "product_name": "towns 5 drawer lingerie chest"
            },
            {
                "product_id": 21762,
                "product_name": "kennell 6 drawer standard dresser"
            },
            {
                "product_id": 13745,
                "product_name": "bridgeview 6 drawer dresser"
            },
            {
                "product_id": 32216,
                "product_name": "cain 6 drawer double dresser"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 41836,
                "product_name": "liesbeth stool"
            },
            {
                "product_id": 38995,
                "product_name": "roco 23 '' wide armchair"
            },
            {
                "product_id": 10377,
                "product_name": "avabella trestle coffee table"
            },
            {
                "product_id": 32547,
                "product_name": "serving platter"
            },
            {
                "product_id": 3729,
                "product_name": "hedvige save the bees table runner"
            }
        ],
        "garOutput": [
            {
                "product_id": 16768,
                "product_name": "amilya gray rug"
            },
            {
                "product_id": 16769,
                "product_name": "amilyah gray rug"
            },
            {
                "product_id": 16770,
                "product_name": "amire non slip backing blue rug"
            },
            {
                "product_id": 33715,
                "product_name": "cabinet 4 '' center to center bar pull multipack"
            },
            {
                "product_id": 33027,
                "product_name": "hiers 1 - light dimmable black armed sconce"
            }
        ],
        "query": "dark gray dresser"
    },
    {
        "id": 271,
        "label": [
            {
                "product_id": 27428,
                "product_name": "three door flower plant stand"
            },
            {
                "product_id": 15581,
                "product_name": "braniya plant stand"
            },
            {
                "product_id": 8431,
                "product_name": "bobby flower cart multi-tiered plant stand"
            },
            {
                "product_id": 4537,
                "product_name": "berens floor flower stand"
            },
            {
                "product_id": 695,
                "product_name": "bandeira plant stand"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 27438,
                "product_name": "byrant plant stand"
            },
            {
                "product_id": 35033,
                "product_name": "fedja plant stand"
            },
            {
                "product_id": 345,
                "product_name": "berrier plant stand"
            },
            {
                "product_id": 19457,
                "product_name": "hoskins plant stand"
            },
            {
                "product_id": 21948,
                "product_name": "brandelynn plant stand"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 13247,
                "product_name": "square multi-tiered plant stand"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 1179,
                "product_name": "real touch bouquet peonies stems"
            },
            {
                "product_id": 8754,
                "product_name": "bamboo plant flower stand rack 2 tier corner tall flower bench holder indoor"
            },
            {
                "product_id": 27333,
                "product_name": "berengere 4 '' cotton futon mattress"
            }
        ],
        "garOutput": [
            {
                "product_id": 29915,
                "product_name": "zev garden and peacock porcelain urn"
            },
            {
                "product_id": 5095,
                "product_name": "falko rooster figurine"
            },
            {
                "product_id": 17394,
                "product_name": "chad wool beige/black area rug"
            },
            {
                "product_id": 17395,
                "product_name": "chad beige/black area rug"
            },
            {
                "product_id": 13504,
                "product_name": "teak tripod end table"
            }
        ],
        "query": "chinese flower stand"
    },
    {
        "id": 332,
        "label": [
            {
                "product_id": 5284,
                "product_name": "gladsaxe ash burl coffee table"
            },
            {
                "product_id": 2459,
                "product_name": "saoirse coffee table"
            },
            {
                "product_id": 25596,
                "product_name": "arcturus frame coffee table"
            },
            {
                "product_id": 640,
                "product_name": "hassan nesting 2 piece coffee table set"
            },
            {
                "product_id": 20902,
                "product_name": "lummus coffee table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 3498,
                "product_name": "adelajde coffee table with storage"
            },
            {
                "product_id": 20620,
                "product_name": "cadien solid wood coffee table with storage"
            },
            {
                "product_id": 34239,
                "product_name": "reiban coffee table"
            },
            {
                "product_id": 34235,
                "product_name": "coyne coffee table"
            },
            {
                "product_id": 25849,
                "product_name": "lataif solid wood block coffee table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 14229,
                "product_name": "homestead dining table"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 11055,
                "product_name": "harcrest writing desk"
            },
            {
                "product_id": 10377,
                "product_name": "avabella trestle coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 23541,
                "product_name": "seese end table"
            },
            {
                "product_id": 8992,
                "product_name": "leos solid wood coffee table with storage"
            },
            {
                "product_id": 41329,
                "product_name": "alarins solid wood coffee table with storage"
            },
            {
                "product_id": 7451,
                "product_name": "7 bottle wall mounted wine bottle & glass rack in white"
            },
            {
                "product_id": 5781,
                "product_name": "mastandrea square multi-tiered plant stand"
            }
        ],
        "query": "attleboro drum coffee table"
    },
    {
        "id": 203,
        "label": [
            {
                "product_id": 11582,
                "product_name": "frigidaire series 6.7 cu . ft. gas dryer with electronic controls"
            },
            {
                "product_id": 16147,
                "product_name": "4.2 cu . ft. top load agitator washer and 6.2 cu . ft. electric dryer"
            },
            {
                "product_id": 16179,
                "product_name": "4.4 cu . ft. washer and 5.9 cu . ft. gas dryer laundry center"
            },
            {
                "product_id": 16171,
                "product_name": "ge appliances smart 7.8 cu . ft. electric dryer with powersteam and washer link"
            },
            {
                "product_id": 16150,
                "product_name": "4.2 cu . ft. top load agitator washer and 7.2 cu . ft. gas dryer"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 16140,
                "product_name": "4.2 cu . ft. top load washer"
            },
            {
                "product_id": 33565,
                "product_name": "5.4 cu . ft. top load washer and 7.4 cu . ft. electric dryer"
            },
            {
                "product_id": 16169,
                "product_name": "4.8 cu . ft. top load washer and 7.4 cu . ft. electric dryer"
            },
            {
                "product_id": 4609,
                "product_name": "17.6 cu . ft. front load washer and 11 cu . ft electric dryer"
            },
            {
                "product_id": 16149,
                "product_name": "4.2 cu . ft. top load agitator washer and 6.2 cu . ft. gas dryer"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 32451,
                "product_name": "40w e12 dimmable led candle light bulb"
            },
            {
                "product_id": 21866,
                "product_name": "90 '' 4-burner propane/natural gas"
            },
            {
                "product_id": 36857,
                "product_name": "u-channel clips"
            },
            {
                "product_id": 12440,
                "product_name": "80 can freestanding beverage refrigerator"
            },
            {
                "product_id": 32033,
                "product_name": "control-r wi-fi module with push button temperature sensor and pump"
            }
        ],
        "garOutput": [
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 20718,
                "product_name": "abramowski monitor stand"
            },
            {
                "product_id": 34997,
                "product_name": "jarom zen sun bouncy wind chime"
            },
            {
                "product_id": 27495,
                "product_name": "1-up hard cover pet brag album"
            },
            {
                "product_id": 32687,
                "product_name": "tervis great outdoors colorful camper insulated tumbler"
            }
        ],
        "query": "ge top loading washer 4.5"
    },
    {
        "id": 435,
        "label": [
            {
                "product_id": 35032,
                "product_name": "izidora 48 '' w closet storage rack"
            },
            {
                "product_id": 2180,
                "product_name": "coat rack shoe bench set entryway shoe rack coat hat bag hooks organizer vintage"
            },
            {
                "product_id": 7558,
                "product_name": "12 pair shoe rack"
            },
            {
                "product_id": 14321,
                "product_name": "46 '' w closet system"
            },
            {
                "product_id": 38344,
                "product_name": "5-tier storage 18 pair shoe rack"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 17216,
                "product_name": "lismore 12 pair shoe storage cabinet"
            },
            {
                "product_id": 39449,
                "product_name": "jiron 49.2 '' w portable clothes storage non-woven fabric organizer closet"
            },
            {
                "product_id": 33110,
                "product_name": "isenhour 55.1 '' w closet storage organizer portable wardrobe"
            },
            {
                "product_id": 39394,
                "product_name": "weekes 17.7 '' w rod closet wire clothes and garment rack"
            },
            {
                "product_id": 41718,
                "product_name": "entryway 9 pair shoe storage cabinet"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 36032,
                "product_name": "deeksha storage cabinet"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 21200,
                "product_name": "cutts bar with wine storage"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 35693,
                "product_name": "anello 20 '' w closet system reach-in sets"
            }
        ],
        "garOutput": [
            {
                "product_id": 33665,
                "product_name": "delasandro metal storage cabinet"
            },
            {
                "product_id": 33664,
                "product_name": "iaconetti storage cabinet"
            },
            {
                "product_id": 1614,
                "product_name": "bulwell 23.5 '' w x 31.5 '' h x 12 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 7261,
                "product_name": "trower 34.3 '' h x 27.9 '' w x 13.6 '' d foldable storage shelves"
            },
            {
                "product_id": 31825,
                "product_name": "ostrowski 15 pair shoe storage cabinet"
            }
        ],
        "query": "shoe closet"
    },
    {
        "id": 27,
        "label": [
            {
                "product_id": 27701,
                "product_name": "edith sweet home 30 '' x 18 '' non-slip outdoor door mat"
            },
            {
                "product_id": 42682,
                "product_name": "burlington lavender flower 30 in . x 18 in . non-slip outdoor door mat"
            },
            {
                "product_id": 35752,
                "product_name": "seaside geometric teal/green/tan indoor / outdoor area rug"
            },
            {
                "product_id": 12994,
                "product_name": "kiesel elipse outdoor door mat"
            },
            {
                "product_id": 23623,
                "product_name": "deann outdoor door mat"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 29200,
                "product_name": "stephenie welcome 30 in . x 18 in . non-slip outdoor door mat"
            },
            {
                "product_id": 31165,
                "product_name": "mercersville welcome 30 in . x 18 in . outdoor door mat"
            },
            {
                "product_id": 3507,
                "product_name": "crompton outdoor door mat"
            },
            {
                "product_id": 24518,
                "product_name": "fagan welcome pineapple 30 in . x 18 in . non-slip outdoor door mat"
            },
            {
                "product_id": 28595,
                "product_name": "berhley welcome 34 in . x 21.5 in . outdoor door mat"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 12350,
                "product_name": "rossmere indoor/outdoor bench cushion"
            },
            {
                "product_id": 8752,
                "product_name": "3 tier corner plant stand foldable nesting flower pot holder elegant metal plant rack for indoor outdoor garden patio3"
            },
            {
                "product_id": 37828,
                "product_name": "heidelberg free form multi-tiered plant stand"
            }
        ],
        "garOutput": [
            {
                "product_id": 27495,
                "product_name": "1-up hard cover pet brag album"
            },
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 38132,
                "product_name": "geometric teal indoor / outdoor area rug"
            },
            {
                "product_id": 27810,
                "product_name": "sayers damask 8 piece comforter set"
            },
            {
                "product_id": 17776,
                "product_name": "crellin solid wood coffee table"
            }
        ],
        "query": "outdoor welcome rug"
    },
    {
        "id": 382,
        "label": [
            {
                "product_id": 4275,
                "product_name": "series edition freestanding hot , cold , and room temperature electric water cooler"
            },
            {
                "product_id": 6053,
                "product_name": "hot water dispenser"
            },
            {
                "product_id": 28230,
                "product_name": "vespera hot water dispenser"
            },
            {
                "product_id": 20016,
                "product_name": "instant hot water dispenser"
            },
            {
                "product_id": 2920,
                "product_name": "avalon countertop hot , cold , and room temperature electric water cooler"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 29733,
                "product_name": "thermo speed hot water dispenser"
            },
            {
                "product_id": 2917,
                "product_name": "avalon freestanding hot and cold electric water cooler"
            },
            {
                "product_id": 11378,
                "product_name": "cuisinart coffee plus\u2122 12 cup programmable coffeemaker plus hot water system"
            },
            {
                "product_id": 28231,
                "product_name": "zemora hot water dispenser"
            },
            {
                "product_id": 20013,
                "product_name": "invite\u2122 touch instant hot water dispenser"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 13626,
                "product_name": "24 '' h x 19.3 '' w x 3.5 '' d storage rack"
            },
            {
                "product_id": 2449,
                "product_name": "alberta 15 bottle wine bar"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 19718,
                "product_name": "commercial vessel sink bathroom faucet with drain assembly"
            }
        ],
        "garOutput": [
            {
                "product_id": 26060,
                "product_name": "midea 6.5 qt . slow cooker"
            },
            {
                "product_id": 3945,
                "product_name": "berndes kitchen tools large ladle"
            },
            {
                "product_id": 522,
                "product_name": "360 cookware gourmet slow cooker"
            },
            {
                "product_id": 26699,
                "product_name": "tempered glass circular vessel bathroom sink"
            },
            {
                "product_id": 27317,
                "product_name": "elite by maxi-matic 3 qt . gourmet slow cooker"
            }
        ],
        "query": "counter top one cup hot water dispenser"
    },
    {
        "id": 234,
        "label": [
            {
                "product_id": 11548,
                "product_name": "orchids floral arrangement in vase"
            },
            {
                "product_id": 9163,
                "product_name": "orchids floral arrangement in decorative vase"
            },
            {
                "product_id": 28166,
                "product_name": "artificial orchids floral arrangements in basket"
            },
            {
                "product_id": 32164,
                "product_name": "phalaenopsis orchids floral arrangement in pot"
            },
            {
                "product_id": 28167,
                "product_name": "orchids floral arrangements in pot"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 9734,
                "product_name": "white orchids on monique fiberglass bowl"
            },
            {
                "product_id": 1824,
                "product_name": "phalaenopsis orchid floral arrangement in pot"
            },
            {
                "product_id": 25378,
                "product_name": "artificial phalaenopsis orchids floral arrangement in vase"
            },
            {
                "product_id": 25396,
                "product_name": "phalaenopsis artificial orchids floral arrangement in planter"
            },
            {
                "product_id": 36680,
                "product_name": "phalaenopsis orchids floral arrangements in vase"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 9734,
                "product_name": "white orchids on monique fiberglass bowl"
            },
            {
                "product_id": 4689,
                "product_name": "artificial double phalaenopsis orchid floral arrangement"
            },
            {
                "product_id": 13247,
                "product_name": "square multi-tiered plant stand"
            },
            {
                "product_id": 11542,
                "product_name": "aloe vera desktop succulent plant"
            },
            {
                "product_id": 1179,
                "product_name": "real touch bouquet peonies stems"
            }
        ],
        "garOutput": [
            {
                "product_id": 12442,
                "product_name": "happy day sunflowers 2-sided polyester 18 x 13 in . garden flag"
            },
            {
                "product_id": 8027,
                "product_name": "happy campers personalized burlap 18 x 12 in . garden flag"
            },
            {
                "product_id": 37793,
                "product_name": "artificial orchid stem"
            },
            {
                "product_id": 4540,
                "product_name": "wood branch"
            },
            {
                "product_id": 28603,
                "product_name": "amarillo 1.25 '' x 1.25 '' natural stone mosaic wall & floor tile"
            }
        ],
        "query": "artificial orchid arrangement"
    },
    {
        "id": 264,
        "label": [
            {
                "product_id": 11260,
                "product_name": "kula orange throw pillow"
            },
            {
                "product_id": 4655,
                "product_name": "gerling square throw pillow"
            },
            {
                "product_id": 37427,
                "product_name": "hospers 3d vertical lines throw pillow"
            },
            {
                "product_id": 1851,
                "product_name": "newsome linen throw pillow"
            },
            {
                "product_id": 35313,
                "product_name": "leesville bolster pillow"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 12065,
                "product_name": "ambesonne turquoise throw pillow cushion cover , heart shape rain droplets on crystal clear window glass pure love valentines romantic , decorative square accent pillow case , 40 '' x 40 '' , blue"
            },
            {
                "product_id": 32656,
                "product_name": "askerby cotton feather 20 '' throw pillow"
            },
            {
                "product_id": 13380,
                "product_name": "real agree decorative square pillow cover & insert"
            },
            {
                "product_id": 31474,
                "product_name": "gephart 57 '' chenille pillow top arm reclining loveseat"
            },
            {
                "product_id": 40560,
                "product_name": "rectangular velvet throw pillow"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 1179,
                "product_name": "real touch bouquet peonies stems"
            }
        ],
        "garOutput": [
            {
                "product_id": 38719,
                "product_name": "briarhill patio reclining chaise lounge with cushion"
            },
            {
                "product_id": 23186,
                "product_name": "podrick 64 '' pillow top arm reclining loveseat"
            },
            {
                "product_id": 23185,
                "product_name": "podrick 76 '' pillow top arm reclining loveseat"
            },
            {
                "product_id": 37826,
                "product_name": "kira 13 '' massaging zero gravity adjustable bed with wireless remote"
            },
            {
                "product_id": 25939,
                "product_name": "abril 10 '' firm gel memory foam mattress"
            }
        ],
        "query": "cortez pillow"
    },
    {
        "id": 292,
        "label": [
            {
                "product_id": 15221,
                "product_name": "hebrides decorative pillow square pillow cover & insert"
            },
            {
                "product_id": 13391,
                "product_name": "matchless light decorative square pillow cover & insert"
            },
            {
                "product_id": 13401,
                "product_name": "real generous decorative square pillow cover & insert"
            },
            {
                "product_id": 2841,
                "product_name": "criss pillow cover & insert"
            },
            {
                "product_id": 18509,
                "product_name": "seales rectangular pillow cover and insert"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 21241,
                "product_name": "white angels indoor/outdoor , removable cover pillow , set of 2 large & 1 lumbar pillow"
            },
            {
                "product_id": 9536,
                "product_name": "cogdill grateful thankful blessed indoor/outdoor lumbar pillow"
            },
            {
                "product_id": 23741,
                "product_name": "kingsburg decorative cotton throw pillow"
            },
            {
                "product_id": 21250,
                "product_name": "lemon frosting indoor/outdoor , removable cover pillow , set of 2 large & 1 lumbar pillow"
            },
            {
                "product_id": 13403,
                "product_name": "colorful effervescent decorative square pillow cover & insert"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 1654,
                "product_name": "aaiyana striped sheer grommet single curtain panel"
            },
            {
                "product_id": 1657,
                "product_name": "solid color sheer grommet single curtain panel"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 18489,
                "product_name": "aurelie throw pillow in light blue"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            }
        ],
        "garOutput": [
            {
                "product_id": 12512,
                "product_name": "pilton 19.7 '' x 19.7 '' vinylwall paneling in white"
            },
            {
                "product_id": 33974,
                "product_name": "novelty knob"
            },
            {
                "product_id": 8286,
                "product_name": "pineapple fabric"
            },
            {
                "product_id": 20461,
                "product_name": "pantheon 24 '' x 24 '' porcelain field tile"
            },
            {
                "product_id": 20460,
                "product_name": "pantheon natural 24 '' x 24 '' porcelain field tile"
            }
        ],
        "query": "decorative white pillow"
    },
    {
        "id": 163,
        "label": [
            {
                "product_id": 26654,
                "product_name": "adama dual arm freestanding monitor stand height adjustable 2 screen desk mount"
            },
            {
                "product_id": 30206,
                "product_name": "kisner height adjustable standing desk converter"
            },
            {
                "product_id": 11002,
                "product_name": "height adjustable standing desk converter"
            },
            {
                "product_id": 27606,
                "product_name": "desk organizer set"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 37050,
                "product_name": "monitor riser height adjustable standing desk converter"
            },
            {
                "product_id": 6203,
                "product_name": "black fixed desktop mount holds up to 110 lbs"
            },
            {
                "product_id": 42076,
                "product_name": "bamboo riser storage organizer laptop desktop container monitor stand"
            },
            {
                "product_id": 37389,
                "product_name": "monitor articulating stand screen universal desk mount"
            },
            {
                "product_id": 11460,
                "product_name": "raven vanguard series gaming black multi screen desktop mount led holds up to 66 lbs"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 27793,
                "product_name": "adjustable height standing desk"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 24389,
                "product_name": "corwin sideboard"
            },
            {
                "product_id": 1215,
                "product_name": "incept 2 door storage cabinet"
            }
        ],
        "garOutput": [
            {
                "product_id": 37851,
                "product_name": "dual-monitor arm 180 degree compact cubicle spaces height adjustable universal 2 screen pole mount"
            },
            {
                "product_id": 17807,
                "product_name": "modern l-shape desk"
            },
            {
                "product_id": 20719,
                "product_name": "bianchina large monitor stand"
            },
            {
                "product_id": 32199,
                "product_name": "dual arm universal desk mount"
            },
            {
                "product_id": 17611,
                "product_name": "home office height adjustable standing desk with hutch"
            }
        ],
        "query": "dual monitor stand"
    },
    {
        "id": 415,
        "label": [
            {
                "product_id": 19111,
                "product_name": "freestanding toilet paper holder with natural wood storage"
            },
            {
                "product_id": 18547,
                "product_name": "whittenburg bath tissue reserve"
            },
            {
                "product_id": 7252,
                "product_name": "augustine free standing toilet paper roll holder"
            },
            {
                "product_id": 26584,
                "product_name": "wynford wall mounted toilet paper holder"
            },
            {
                "product_id": 30475,
                "product_name": "contempra wall mount toilet paper holder"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 37217,
                "product_name": "teardrop freestanding toilet paper holder with reserve"
            },
            {
                "product_id": 20421,
                "product_name": "kent free standing toilet paper holder"
            },
            {
                "product_id": 17979,
                "product_name": "manius free standing trophey toilet paper holder"
            },
            {
                "product_id": 33404,
                "product_name": "ventura two-post wall mounted toilet paper holder"
            },
            {
                "product_id": 10009,
                "product_name": "700 series wall mount toilet paper holder"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 2233,
                "product_name": "tv stand for tvs up to 50 ''"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 8754,
                "product_name": "bamboo plant flower stand rack 2 tier corner tall flower bench holder indoor"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 36032,
                "product_name": "deeksha storage cabinet"
            }
        ],
        "garOutput": [
            {
                "product_id": 33664,
                "product_name": "iaconetti storage cabinet"
            },
            {
                "product_id": 33665,
                "product_name": "delasandro metal storage cabinet"
            },
            {
                "product_id": 1614,
                "product_name": "bulwell 23.5 '' w x 31.5 '' h x 12 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 9973,
                "product_name": "fruit vegetable wire basket set"
            },
            {
                "product_id": 13216,
                "product_name": "34.25 '' plastic square fold-in-half folding table"
            }
        ],
        "query": "toilet paper stand"
    },
    {
        "id": 138,
        "label": [
            {
                "product_id": 10052,
                "product_name": "unidoor 24 '' w x 72 '' h hinged frameless shower door with clearmax\u2122 technology"
            },
            {
                "product_id": 34030,
                "product_name": "lucia glass sliding closet door"
            },
            {
                "product_id": 37907,
                "product_name": "elan 68 '' w x 74 '' h single sliding frameless shower door with rollerdisk\u2122 technology"
            },
            {
                "product_id": 10057,
                "product_name": "aqua 48 '' w x 58 '' h hinged semi-frameless tub door"
            },
            {
                "product_id": 10056,
                "product_name": "aqua ultra 47.75 '' w x 58 '' h hinged frameless tub door"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 37906,
                "product_name": "elan 44-48 '' w x 74 '' h single sliding frameless shower door with rollerdisk\u2122 technology"
            },
            {
                "product_id": 22193,
                "product_name": "fluence 52 '' x 63 '' bypass bath door with cleancoat\u00ae technology"
            },
            {
                "product_id": 21472,
                "product_name": "flip 45 '' w x 56 '' h pivot frameless tub door"
            },
            {
                "product_id": 39631,
                "product_name": "glass panel door audio cabinet"
            },
            {
                "product_id": 37546,
                "product_name": "kinkade xl 35.25 '' w x 80 '' h hinged frameless shower door"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 41093,
                "product_name": "maire 23.6 '' w x 31.5 '' h x 13 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 17223,
                "product_name": "buffet storage cabinet with single glass doors and unique bell handle"
            },
            {
                "product_id": 3197,
                "product_name": "double shower curtain hooks"
            },
            {
                "product_id": 19708,
                "product_name": "waterfall single hole bathroom faucet"
            },
            {
                "product_id": 38224,
                "product_name": "riggins 24 '' single bathroom vanity set"
            }
        ],
        "garOutput": [
            {
                "product_id": 12480,
                "product_name": "uecker modern wall mirror"
            },
            {
                "product_id": 30528,
                "product_name": "lantern marble arabesque mosaic subway wall & floor tile"
            },
            {
                "product_id": 20421,
                "product_name": "kent free standing toilet paper holder"
            },
            {
                "product_id": 30529,
                "product_name": "marble honeycomb mosaic subway wall & floor tile"
            },
            {
                "product_id": 30530,
                "product_name": "daisy field flower marble novelty mosaic subway wall & floor tile"
            }
        ],
        "query": "glass doors for bath"
    },
    {
        "id": 62,
        "label": [
            {
                "product_id": 8049,
                "product_name": "crock-pot slow cooker"
            },
            {
                "product_id": 18187,
                "product_name": "7-qt . stay or go slow cooker"
            },
            {
                "product_id": 8047,
                "product_name": "crock-pot 7 qt . slow cooker"
            },
            {
                "product_id": 33339,
                "product_name": "narita 1.5 qt . electric floral patterned slow cooker"
            },
            {
                "product_id": 8048,
                "product_name": "crock-pot 8 qt . slow cooker"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 42316,
                "product_name": "betty crocker 5-quart oval slow cooker with travel bag"
            },
            {
                "product_id": 27315,
                "product_name": "elite by maxi-matic 2-quart gourmet slow cooker"
            },
            {
                "product_id": 28369,
                "product_name": "nesco 1.5 qt . analog slow cooker"
            },
            {
                "product_id": 18183,
                "product_name": "hamilton beach 6 qt . stay or go programmable flexcook slow cooker"
            },
            {
                "product_id": 27318,
                "product_name": "elite by maxi-matic 3.5 qt . slow cooker with locking lid"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 21866,
                "product_name": "90 '' 4-burner propane/natural gas"
            },
            {
                "product_id": 12440,
                "product_name": "80 can freestanding beverage refrigerator"
            },
            {
                "product_id": 13620,
                "product_name": "portable food storage container"
            },
            {
                "product_id": 32451,
                "product_name": "40w e12 dimmable led candle light bulb"
            },
            {
                "product_id": 8573,
                "product_name": "14 '' oil powered outdoor lantern"
            }
        ],
        "garOutput": [
            {
                "product_id": 27316,
                "product_name": "elite by maxi-matic 2.5 qt . platinum triple stock slow cookers"
            },
            {
                "product_id": 7892,
                "product_name": "cuisinox super elite pasta spoon"
            },
            {
                "product_id": 7524,
                "product_name": "zing oval slow cooker"
            },
            {
                "product_id": 27317,
                "product_name": "elite by maxi-matic 3 qt . gourmet slow cooker"
            },
            {
                "product_id": 7520,
                "product_name": "zing 4 qt . oval slow cooker"
            }
        ],
        "query": "7qt slow cooker"
    },
    {
        "id": 135,
        "label": [
            {
                "product_id": 27896,
                "product_name": "perrysburg 37 '' wide manual wall hugger standard recliner"
            },
            {
                "product_id": 31618,
                "product_name": "condron 57.5 '' chenille pillow top arm loveseat"
            },
            {
                "product_id": 19072,
                "product_name": "apolonija 62 '' chenille pillow top arm reclining loveseat"
            },
            {
                "product_id": 23189,
                "product_name": "alliser 87 '' square arm reclining sofa"
            },
            {
                "product_id": 30978,
                "product_name": "hagan 50 '' linen square arm loveseat"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 23009,
                "product_name": "talia 72 '' square arm loveseat"
            },
            {
                "product_id": 23965,
                "product_name": "cisbrough 64.17 '' pillow top arm reclining loveseat"
            },
            {
                "product_id": 13760,
                "product_name": "fiecke 65 '' recessed arm loveseat"
            },
            {
                "product_id": 31487,
                "product_name": "sellars 76.5 '' pillow top arm reclining loveseat"
            },
            {
                "product_id": 29493,
                "product_name": "obryant 73 '' faux leather square arm loveseat"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 38673,
                "product_name": "abdulsamet coffee table"
            },
            {
                "product_id": 26887,
                "product_name": "capems 65.35 '' faux leather pillow top arm sleeper"
            }
        ],
        "garOutput": [
            {
                "product_id": 2178,
                "product_name": "kara folding 2 piece tray table set"
            },
            {
                "product_id": 2179,
                "product_name": "alison folding 4 piece tray table set"
            },
            {
                "product_id": 32971,
                "product_name": "lorentzen 33 '' console table"
            },
            {
                "product_id": 22545,
                "product_name": "channing 27 '' wide manual wing chair recliner"
            },
            {
                "product_id": 40988,
                "product_name": "braeden 101.2 '' wide linen reversible modular sofa & chaise with ottoman"
            }
        ],
        "query": "low profile loveseat recliner"
    },
    {
        "id": 128,
        "label": [
            {
                "product_id": 22671,
                "product_name": "action coffee table"
            },
            {
                "product_id": 17632,
                "product_name": "alameda solid wood coffee table with storage"
            },
            {
                "product_id": 20336,
                "product_name": "hallett 3 piece coffee table set"
            },
            {
                "product_id": 14754,
                "product_name": "claire coffee table"
            },
            {
                "product_id": 21571,
                "product_name": "pullman block 1 coffee table with storage"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 16679,
                "product_name": "glastonbury coffee table"
            },
            {
                "product_id": 7475,
                "product_name": "anaejah block coffee table with storage"
            },
            {
                "product_id": 30593,
                "product_name": "bartron solid wood coffee table with storage"
            },
            {
                "product_id": 18069,
                "product_name": "melange corrina coffee table"
            },
            {
                "product_id": 5690,
                "product_name": "colne 2 piece coffee table set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 38244,
                "product_name": "agirta extendable dining table"
            },
            {
                "product_id": 30158,
                "product_name": "hershberger buffet table"
            }
        ],
        "garOutput": [
            {
                "product_id": 13504,
                "product_name": "teak tripod end table"
            },
            {
                "product_id": 38158,
                "product_name": "herlane 15 '' console table"
            },
            {
                "product_id": 20549,
                "product_name": "cresswell armless stackable chair"
            },
            {
                "product_id": 24746,
                "product_name": "chugwater 48 '' console table"
            },
            {
                "product_id": 17162,
                "product_name": "lovette desk"
            }
        ],
        "query": "block coffee table"
    },
    {
        "id": 463,
        "label": [
            {
                "product_id": 7437,
                "product_name": "delway 5 piece bistro set"
            },
            {
                "product_id": 28879,
                "product_name": "alegre round 2 - person 21 '' long bistro set"
            },
            {
                "product_id": 30336,
                "product_name": "merlyn 6-piece patio furniture set corner sofa set with thick removable cushions"
            },
            {
                "product_id": 30337,
                "product_name": "river 4 pieces rattan patio furniture set wicker sofa cushioned sectional furniture set garden patio sofa set"
            },
            {
                "product_id": 30610,
                "product_name": "guthrie 3 pieces patio set outdoor wicker patio furniture sets modern set rattan chair conversation sets with coffee table for yard and bistro"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 3172,
                "product_name": "3 piece bistro set"
            },
            {
                "product_id": 27940,
                "product_name": "sartre 3 piece bistro set"
            },
            {
                "product_id": 7616,
                "product_name": "breakwater bay 5pcs patio set sectional rattan wicker furniture set w/ red cushion"
            },
            {
                "product_id": 38713,
                "product_name": "leen 3 piece bistro set"
            },
            {
                "product_id": 442,
                "product_name": "davisson floral outdoor 3-piece bistro set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 18012,
                "product_name": "aditi patio 3 piece seating group with cushions"
            },
            {
                "product_id": 2449,
                "product_name": "alberta 15 bottle wine bar"
            },
            {
                "product_id": 8752,
                "product_name": "3 tier corner plant stand foldable nesting flower pot holder elegant metal plant rack for indoor outdoor garden patio3"
            },
            {
                "product_id": 22032,
                "product_name": "dining table with leaf"
            }
        ],
        "garOutput": [
            {
                "product_id": 21234,
                "product_name": "sweet outdoor rectangular pillow cover & insert"
            },
            {
                "product_id": 21235,
                "product_name": "coral rectangular pillow cover & insert"
            },
            {
                "product_id": 22011,
                "product_name": "darnetha 70.5 '' faux leather pillow top arm convertible sleeper sofa bed"
            },
            {
                "product_id": 14976,
                "product_name": "craftsman crosshead"
            },
            {
                "product_id": 32953,
                "product_name": "anglesey abstract coffee table"
            }
        ],
        "query": "bistro sets patio"
    },
    {
        "id": 8,
        "label": [
            {
                "product_id": 22611,
                "product_name": "welcome sign"
            },
            {
                "product_id": 9663,
                "product_name": "welcome home sign wall d\u00e9cor"
            },
            {
                "product_id": 2760,
                "product_name": "bless this home wisdom sign wall d\u00e9cor"
            },
            {
                "product_id": 30559,
                "product_name": "home sign wall d\u00e9cor"
            },
            {
                "product_id": 30044,
                "product_name": "brantford love forever infinity symbol beautiful steel home wall sign"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 30045,
                "product_name": "swavar home sweet home word laser cut steel wall sign"
            },
            {
                "product_id": 8990,
                "product_name": "home sweet home sign wall d\u00e9cor"
            },
            {
                "product_id": 19930,
                "product_name": "beware of bee keeper rustic sign rustic distressed vintage look sign funny home decor"
            },
            {
                "product_id": 5446,
                "product_name": "cursive word amigos/amigas sign wall d\u00e9cor"
            },
            {
                "product_id": 33321,
                "product_name": "sherwinn welcome stain signs and plaques"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 3728,
                "product_name": "bless our home metal sign"
            },
            {
                "product_id": 30358,
                "product_name": "saylorville home sweet home welcome sign"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            }
        ],
        "garOutput": [
            {
                "product_id": 783,
                "product_name": "yacine sunflower tablecloth"
            },
            {
                "product_id": 21799,
                "product_name": "6 piece cactus aloe agave succulent in pot set"
            },
            {
                "product_id": 4457,
                "product_name": "hursten welcome indoor / outdoor door mat"
            },
            {
                "product_id": 39470,
                "product_name": "krieger square multi-tiered plant stand"
            },
            {
                "product_id": 3756,
                "product_name": "duggins brontosaurus dinosaur coffee mug"
            }
        ],
        "query": "home sweet home sign"
    },
    {
        "id": 331,
        "label": [
            {
                "product_id": 29452,
                "product_name": "rachna upholstered platform bed"
            },
            {
                "product_id": 18860,
                "product_name": "dupuy upholstered platform bed"
            },
            {
                "product_id": 13652,
                "product_name": "geralyn tufted upholstered storage platform bed"
            },
            {
                "product_id": 33515,
                "product_name": "lukasz tufted upholstered standard bed"
            },
            {
                "product_id": 17708,
                "product_name": "thornaby upholstered standard bed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 31427,
                "product_name": "lanphear upholstered storage platform bed"
            },
            {
                "product_id": 11071,
                "product_name": "park avenue tufted upholstered low profile platform bed"
            },
            {
                "product_id": 10846,
                "product_name": "calloway upholstered low profile storage platform bed"
            },
            {
                "product_id": 7302,
                "product_name": "brighton tufted upholstered platform bed"
            },
            {
                "product_id": 25494,
                "product_name": "brittany tufted upholstered platform bed"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 12169,
                "product_name": "holli zollinger zodiac leo outdoor square pillow cover & insert"
            },
            {
                "product_id": 32383,
                "product_name": "pull-out side mount belt rack"
            },
            {
                "product_id": 32380,
                "product_name": "deep pull-out 18 '' w x 7 '' h drawer"
            },
            {
                "product_id": 32381,
                "product_name": "deep pull-out 18 '' w x 11 '' h drawer"
            },
            {
                "product_id": 32387,
                "product_name": "0.5 '' closet rod"
            }
        ],
        "query": "upholstered girls bed"
    },
    {
        "id": 451,
        "label": [
            {
                "product_id": 4738,
                "product_name": "janiya tufted platform bed"
            },
            {
                "product_id": 17836,
                "product_name": "tufted upholstered low profile platform bed"
            },
            {
                "product_id": 4734,
                "product_name": "castilla tufted upholstered low profile standard bed"
            },
            {
                "product_id": 5001,
                "product_name": "lavaca upholstered platform bed"
            },
            {
                "product_id": 40329,
                "product_name": "hoskinson tufted upholstered platform bed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 1100,
                "product_name": "pineview upholstered standard bed"
            },
            {
                "product_id": 13645,
                "product_name": "sanders tufted platform bed"
            },
            {
                "product_id": 39908,
                "product_name": "marlon tufted upholstered standard bed"
            },
            {
                "product_id": 26790,
                "product_name": "amaia velvet upholstered platform bed"
            },
            {
                "product_id": 17609,
                "product_name": "emanuell queen tufted low profile platform bed"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 16348,
                "product_name": "lethe 2 piece crib bedding set"
            },
            {
                "product_id": 14106,
                "product_name": "veronica 5 drawer chest"
            },
            {
                "product_id": 2089,
                "product_name": "elzadie queen four poster bed"
            }
        ],
        "garOutput": [
            {
                "product_id": 32412,
                "product_name": "louque queen upholstered platform bed"
            },
            {
                "product_id": 42534,
                "product_name": "essonne kids chair"
            },
            {
                "product_id": 32893,
                "product_name": "nutt juvenile dinosaur sheet set"
            },
            {
                "product_id": 30887,
                "product_name": "rosalida twin platform bed"
            },
            {
                "product_id": 25986,
                "product_name": "mailiah platform bed"
            }
        ],
        "query": "aiden black velvet bed"
    },
    {
        "id": 64,
        "label": [
            {
                "product_id": 4984,
                "product_name": "efron transitional etagere bookcase"
            },
            {
                "product_id": 7658,
                "product_name": "bella industrial etagere bookcase"
            },
            {
                "product_id": 28424,
                "product_name": "dawud 72.44 '' h x 24 '' w metal ladder bookcase"
            },
            {
                "product_id": 11657,
                "product_name": "albarado 40.3 '' h x 25.5 '' w etagere bookcase"
            },
            {
                "product_id": 2708,
                "product_name": "oliver 74.5 '' h x 26 '' w etagere bookcase"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 1092,
                "product_name": "carolina etagere bookcase"
            },
            {
                "product_id": 34255,
                "product_name": "svante 44 '' h x 47.2 '' w steel etagere bookcase"
            },
            {
                "product_id": 4158,
                "product_name": "anton etagere bookcase"
            },
            {
                "product_id": 8799,
                "product_name": "preserve 38 '' etagere bookcase"
            },
            {
                "product_id": 14512,
                "product_name": "bette etagere bookcase"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 14501,
                "product_name": "26 '' wide side chair"
            },
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 34987,
                "product_name": "shrestha upholstered parsons chair in gray"
            },
            {
                "product_id": 14106,
                "product_name": "veronica 5 drawer chest"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            }
        ],
        "garOutput": [
            {
                "product_id": 246,
                "product_name": "real good chair"
            },
            {
                "product_id": 24200,
                "product_name": "leander velvet task chair"
            },
            {
                "product_id": 11721,
                "product_name": "carelton feng shui zen meditating japanese jizo cat monk on lotus throne figurine"
            },
            {
                "product_id": 32720,
                "product_name": "drumack coffee table"
            },
            {
                "product_id": 2187,
                "product_name": "fashion casual lift chair office work chair beauty salon chair"
            }
        ],
        "query": "zachary 72.5"
    },
    {
        "id": 305,
        "label": [
            {
                "product_id": 41019,
                "product_name": "incandescent dimmable light bulb , ( 2500k ) e12/candelabra base"
            },
            {
                "product_id": 20401,
                "product_name": "4 watt ( 60 watt equivalent ) , b11 led , dimmable light bulb , e12/candelabra base"
            },
            {
                "product_id": 21981,
                "product_name": "60 watt equivalent , ca11 led , dimmable light bulb , warm white ( 3000k ) e12/candelabra base"
            },
            {
                "product_id": 34465,
                "product_name": "1 - bulb 9.5 '' h outdoor bulkhead light"
            },
            {
                "product_id": 32593,
                "product_name": "1-light led"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 1363,
                "product_name": "gillian 3 - bulb 20 '' h outdoor hanging lantern"
            },
            {
                "product_id": 3584,
                "product_name": "kyra 1 - bulb 18 '' h outdoor wall lantern"
            },
            {
                "product_id": 30194,
                "product_name": "60 watt equivalent philips hue , a19 led smart , dimmable light bulb , warm white ( 2700k ) e26/medium ( standard ) base"
            },
            {
                "product_id": 21969,
                "product_name": "15.5 watt ( 100 watt equivalent ) , a19 led , dimmable light bulb e26/medium ( standard ) base"
            },
            {
                "product_id": 1646,
                "product_name": "4w e12/candelabra led light bulb"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 23592,
                "product_name": "mack 1 - light simple circle led flush mount"
            },
            {
                "product_id": 28766,
                "product_name": "34 '' caselli 3 - blade retractable blades ceiling fan with remote control and light kit included"
            },
            {
                "product_id": 23918,
                "product_name": "fulcrum battery-operated led under cabinet puck light"
            },
            {
                "product_id": 33008,
                "product_name": "vermiculite"
            },
            {
                "product_id": 13840,
                "product_name": "pink solar powered pathway light"
            }
        ],
        "query": "ligth bulb"
    },
    {
        "id": 14,
        "label": [
            {
                "product_id": 30891,
                "product_name": "dorotheus twin platform bed"
            },
            {
                "product_id": 41709,
                "product_name": "anner tufted low profile upholstered standard bed"
            },
            {
                "product_id": 38513,
                "product_name": "maurice full loft bed with desk and bookcase"
            },
            {
                "product_id": 17784,
                "product_name": "sofa bed adjustable folding futon sofa video gaming sofa lounge sofa with two pillows-light brown"
            },
            {
                "product_id": 31923,
                "product_name": "adisynne twin tufted upholstered platform bed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 24922,
                "product_name": "hoelscher murphy bed"
            },
            {
                "product_id": 28721,
                "product_name": "portsmouth upholstered bed"
            },
            {
                "product_id": 18317,
                "product_name": "anatole tufted upholstered storage platform bed"
            },
            {
                "product_id": 2018,
                "product_name": "bigler bed frame"
            },
            {
                "product_id": 29452,
                "product_name": "rachna upholstered platform bed"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 29241,
                "product_name": "cleotha twin 75 '' wide tufted back convertible sofa"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            }
        ],
        "garOutput": [
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 27495,
                "product_name": "1-up hard cover pet brag album"
            },
            {
                "product_id": 29670,
                "product_name": "enameled 3 piece coffee , tea , & sugar jar set"
            },
            {
                "product_id": 27810,
                "product_name": "sayers damask 8 piece comforter set"
            },
            {
                "product_id": 38132,
                "product_name": "geometric teal indoor / outdoor area rug"
            }
        ],
        "query": "beds that have leds"
    },
    {
        "id": 157,
        "label": [
            {
                "product_id": 8930,
                "product_name": "willia 173 '' wide linen left hand facing modular sofa & chaise"
            },
            {
                "product_id": 24070,
                "product_name": "abdul-rafay 99 '' wide microfiber/microsuede right hand facing sleeper sofa & chaise"
            },
            {
                "product_id": 27548,
                "product_name": "jarrett 112 '' wide sofa & chaise"
            },
            {
                "product_id": 31024,
                "product_name": "aiden 103 '' wide sofa & chaise with ottoman"
            },
            {
                "product_id": 19560,
                "product_name": "thach 108 '' wide velvet sofa & chaise"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 24067,
                "product_name": "eduarte 86 '' wide reversible sleeper sofa & chaise"
            },
            {
                "product_id": 41112,
                "product_name": "cissell 131 '' wide reversible sofa & chaise with ottoman"
            },
            {
                "product_id": 30261,
                "product_name": "zella reader kids chaise lounge"
            },
            {
                "product_id": 28529,
                "product_name": "maselek 104.8 '' wide right hand facing modular sofa & chaise"
            },
            {
                "product_id": 8317,
                "product_name": "tufted armless chaise lounge"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 40626,
                "product_name": "rosemead velvet chaise lounge"
            },
            {
                "product_id": 890,
                "product_name": "sapphira reversible quilt set"
            },
            {
                "product_id": 3837,
                "product_name": "galaz 28 '' wide polyester wingback chair"
            },
            {
                "product_id": 2552,
                "product_name": "beauvale damask blackout rod pocket single curtain panel"
            },
            {
                "product_id": 34103,
                "product_name": "29 '' wide tufted lounge chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 12986,
                "product_name": "fredericktown 96 '' wide reversible sofa & chaise with ottoman"
            },
            {
                "product_id": 23864,
                "product_name": "kajus 32 '' wide polyester lounge chair"
            },
            {
                "product_id": 39571,
                "product_name": "aparna parsons upholstered dining chair"
            },
            {
                "product_id": 39910,
                "product_name": "carin coffee table"
            },
            {
                "product_id": 22972,
                "product_name": "southampton 3 piece pub table set"
            }
        ],
        "query": "velvet chaise"
    },
    {
        "id": 40,
        "label": [
            {
                "product_id": 33019,
                "product_name": "serta works ergonomic executive chair"
            },
            {
                "product_id": 38662,
                "product_name": "baguia executive chair"
            },
            {
                "product_id": 5504,
                "product_name": "amalga executive chair"
            },
            {
                "product_id": 5499,
                "product_name": "pauling executive chair"
            },
            {
                "product_id": 9492,
                "product_name": "home office chair \u2014\u2014ergonomic mesh chair computer chair home executive desk chair comfortable reclining swivel chair and , mid back with wheels for teens/adults ( pink )"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 2864,
                "product_name": "soft sense high back executive chair"
            },
            {
                "product_id": 22131,
                "product_name": "leather ergonomic office executive chair"
            },
            {
                "product_id": 28912,
                "product_name": "essentials high-back executive chair"
            },
            {
                "product_id": 41092,
                "product_name": "barrister executive chair"
            },
            {
                "product_id": 10371,
                "product_name": "bowfield ergonomic executive chair"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 34987,
                "product_name": "shrestha upholstered parsons chair in gray"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            },
            {
                "product_id": 37939,
                "product_name": "windgap block coffee table"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            }
        ],
        "garOutput": [
            {
                "product_id": 8096,
                "product_name": "lengby 34.6 '' wide tufted club chair"
            },
            {
                "product_id": 22002,
                "product_name": "symons task chair"
            },
            {
                "product_id": 1273,
                "product_name": "demetra genuine leather task chair"
            },
            {
                "product_id": 4748,
                "product_name": "priyah 29 '' wide tufted polyester wingback chair"
            },
            {
                "product_id": 4960,
                "product_name": "baldivis 35 '' wide polyester armchair"
            }
        ],
        "query": "elegant executive chair"
    },
    {
        "id": 446,
        "label": [
            {
                "product_id": 30593,
                "product_name": "bartron solid wood coffee table with storage"
            },
            {
                "product_id": 41362,
                "product_name": "dominee solid wood coffee table with storage"
            },
            {
                "product_id": 40273,
                "product_name": "card dining table"
            },
            {
                "product_id": 9869,
                "product_name": "ebern designs 3-piece wood dining room round table & chairs set w/ steel frame , built-in wine rack - black/silver"
            },
            {
                "product_id": 14892,
                "product_name": "noda extendable dining table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 17036,
                "product_name": "prange counter height dining table"
            },
            {
                "product_id": 7624,
                "product_name": "breakwater bay console table drawer shelves sofa accent table entryway hallway black"
            },
            {
                "product_id": 42639,
                "product_name": "albom43.3 '' console table"
            },
            {
                "product_id": 17006,
                "product_name": "cabravale 4 legs coffee table"
            },
            {
                "product_id": 18773,
                "product_name": "36 '' chess & backgammon table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 1029,
                "product_name": "candelario bar with wine storage"
            },
            {
                "product_id": 31143,
                "product_name": "amilliah tufted upholstered parsons chair"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 38673,
                "product_name": "abdulsamet coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 13504,
                "product_name": "teak tripod end table"
            },
            {
                "product_id": 11339,
                "product_name": "tonks folding metal 3 piece bistro table set"
            },
            {
                "product_id": 13951,
                "product_name": "48 '' urn"
            },
            {
                "product_id": 2437,
                "product_name": "gourmet counter height pub table"
            },
            {
                "product_id": 19531,
                "product_name": "teak block end table"
            }
        ],
        "query": "card table"
    },
    {
        "id": 410,
        "label": [
            {
                "product_id": 13624,
                "product_name": "judith swimming pool light"
            },
            {
                "product_id": 28361,
                "product_name": "nurxiovo 20ft inflatable outdoor movie screen , mega theater projection screen with 350w blower strings stakes & storage bag for backyard movie parties pool lawn event , movie projector screen ( 20ft )"
            },
            {
                "product_id": 32075,
                "product_name": "pool float drink relax wall d\u00e9cor"
            },
            {
                "product_id": 20918,
                "product_name": "floating water lantern"
            },
            {
                "product_id": 41344,
                "product_name": "275.6 '' l x 39.4 '' w x 3.9 '' vinyl mat"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 14937,
                "product_name": "petz alligator float inflatables"
            },
            {
                "product_id": 10471,
                "product_name": "it is summertime for this squirrel who is floating on an inner tube in a pool with a beach umbrella pillow cover"
            },
            {
                "product_id": 13840,
                "product_name": "pink solar powered pathway light"
            },
            {
                "product_id": 41065,
                "product_name": "ozella floating crocodile head statue"
            },
            {
                "product_id": 5772,
                "product_name": "ball plug-in led outdoor floating light"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8753,
                "product_name": "4 tier plant stand garden planter metal flower pot rack corner shelving indoor outdoor"
            },
            {
                "product_id": 8751,
                "product_name": "large kids play house heavy duty canvas cotton with floor mat for bedroom indoor outdoor"
            },
            {
                "product_id": 8740,
                "product_name": "alessandro rectangular multi-tiered plant stand"
            },
            {
                "product_id": 13247,
                "product_name": "square multi-tiered plant stand"
            },
            {
                "product_id": 8754,
                "product_name": "bamboo plant flower stand rack 2 tier corner tall flower bench holder indoor"
            }
        ],
        "garOutput": [
            {
                "product_id": 27997,
                "product_name": "floatation device nekdoodle"
            },
            {
                "product_id": 37238,
                "product_name": "trigg wall d\u00e9cor"
            },
            {
                "product_id": 37237,
                "product_name": "trigg ceramic planter wall decor"
            },
            {
                "product_id": 21277,
                "product_name": "dube stripe welcome burlap sassafras switch 22 x 10 non-slip outdoor door mat"
            },
            {
                "product_id": 8373,
                "product_name": "lily slipcovered chaise lounge"
            }
        ],
        "query": "pool floats"
    },
    {
        "id": 220,
        "label": [
            {
                "product_id": 39239,
                "product_name": "wayfair basics 1800 series 4-piece sheet set"
            },
            {
                "product_id": 11840,
                "product_name": "dinosaur fitted sheet"
            },
            {
                "product_id": 12026,
                "product_name": "artificial satellite sheet set"
            },
            {
                "product_id": 17885,
                "product_name": "highlights hooting owls hidden pictures queen sheet set"
            },
            {
                "product_id": 39495,
                "product_name": "olive kids dinosaur land 205 thread count 100 % cotton sheet set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 12031,
                "product_name": "sheet set paisley sheet set"
            },
            {
                "product_id": 12049,
                "product_name": "seashells sheet set"
            },
            {
                "product_id": 7239,
                "product_name": "nipote 1800 thread count sheet set"
            },
            {
                "product_id": 42244,
                "product_name": "wayfair sleep 300 thread count 100 % cotton percale sheet set"
            },
            {
                "product_id": 26258,
                "product_name": "mellanni striped bed sheet set 4 piece"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 17737,
                "product_name": "donovan twin daybed with trundle"
            },
            {
                "product_id": 26646,
                "product_name": "international harvester melamine cereal bowl"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            }
        ],
        "garOutput": [
            {
                "product_id": 9813,
                "product_name": "sheet set"
            },
            {
                "product_id": 21058,
                "product_name": "skylar 200 thread count damask 100 % cotton body pillow case"
            },
            {
                "product_id": 20993,
                "product_name": "aqua and grey sloth collection sloth body pillowcase"
            },
            {
                "product_id": 6535,
                "product_name": "outdoor garden patio furniture 6-piece pe rattan wicker sectional cushioned sofa sets with 2 pillows and coffee table , beige cushion"
            },
            {
                "product_id": 21077,
                "product_name": "rustic patch moose body pillow case"
            }
        ],
        "query": "sheets for twinxl"
    },
    {
        "id": 283,
        "label": [
            {
                "product_id": 26180,
                "product_name": "gaia driftwood solid wood dining table"
            },
            {
                "product_id": 7271,
                "product_name": "gwen solid wood coffee table with storage"
            },
            {
                "product_id": 1872,
                "product_name": "denning coffee table with storage"
            },
            {
                "product_id": 32897,
                "product_name": "aahil end table with storage"
            },
            {
                "product_id": 18729,
                "product_name": "targhee mango solid wood dining table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 36385,
                "product_name": "inman end table with storage"
            },
            {
                "product_id": 7141,
                "product_name": "ariella end table with storage"
            },
            {
                "product_id": 1731,
                "product_name": "hugh end table with storage"
            },
            {
                "product_id": 1958,
                "product_name": "randel wedge end table"
            },
            {
                "product_id": 3608,
                "product_name": "sandiford plastic side table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 11055,
                "product_name": "harcrest writing desk"
            },
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            }
        ],
        "garOutput": [
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 27495,
                "product_name": "1-up hard cover pet brag album"
            },
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 20718,
                "product_name": "abramowski monitor stand"
            },
            {
                "product_id": 11055,
                "product_name": "harcrest writing desk"
            }
        ],
        "query": "gaia driftwood table"
    },
    {
        "id": 423,
        "label": [
            {
                "product_id": 36770,
                "product_name": "caron wall shelf"
            },
            {
                "product_id": 27850,
                "product_name": "supreme 59.1 '' h x 23.6 '' w x 13.8 '' d 5- tier storage rack shelving unit"
            },
            {
                "product_id": 10416,
                "product_name": "wall shelf"
            },
            {
                "product_id": 30147,
                "product_name": "wall shelf"
            },
            {
                "product_id": 39647,
                "product_name": "cabrillo industrial wall shelf"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 1885,
                "product_name": "ben wall shelf"
            },
            {
                "product_id": 28865,
                "product_name": "villalpando 8.66 '' w x 14.17 '' h x 8.66 '' d wall mounted bathroom shelves"
            },
            {
                "product_id": 40253,
                "product_name": "boatwright wall mounted coat rack"
            },
            {
                "product_id": 30147,
                "product_name": "wall shelf"
            },
            {
                "product_id": 14200,
                "product_name": "juan wall shelf"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            },
            {
                "product_id": 36032,
                "product_name": "deeksha storage cabinet"
            },
            {
                "product_id": 21200,
                "product_name": "cutts bar with wine storage"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 34858,
                "product_name": "slide-a-shelf made to fit standard out shelf , full extension with soft close , choice of custom size and solid wood front"
            },
            {
                "product_id": 738,
                "product_name": "runner groverson geometric blue area rug"
            },
            {
                "product_id": 739,
                "product_name": "runner groversen geometric gray area rug"
            },
            {
                "product_id": 21229,
                "product_name": "canister in white kitchen canister"
            },
            {
                "product_id": 33666,
                "product_name": "120 gallon deck box"
            }
        ],
        "query": "wall shelves"
    },
    {
        "id": 462,
        "label": [
            {
                "product_id": 20746,
                "product_name": "10 ft outdoor patio umbrella solar powered led lighted sun shade market waterproof 8 ribs umbrella with crank and cross base for garden deck backyard pool shade outside deck swimming pool -tan"
            }
        ],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "product_id": 17737,
                "product_name": "donovan twin daybed with trundle"
            },
            {
                "product_id": 17741,
                "product_name": "bathroom free standing toilet paper holder with reserve function"
            },
            {
                "product_id": 32482,
                "product_name": "krause 16 '' height profile platform bed"
            },
            {
                "product_id": 34604,
                "product_name": "metal bed"
            },
            {
                "product_id": 6053,
                "product_name": "hot water dispenser"
            }
        ],
        "garOutput": [
            {
                "product_id": 10567,
                "product_name": "crown keep calm and love honey bees wine bag carrier"
            },
            {
                "product_id": 25182,
                "product_name": "cockfosters 71 '' kitchen pantry"
            },
            {
                "product_id": 32801,
                "product_name": "leap\u00ae high-back leather desk chair"
            },
            {
                "product_id": 28868,
                "product_name": "sabastian 43 '' platform bed"
            },
            {
                "product_id": 16326,
                "product_name": "39 '' rectangular fold-in-half portable folding table"
            }
        ],
        "query": "large bases"
    },
    {
        "id": 342,
        "label": [
            {
                "product_id": 11277,
                "product_name": "jozwiak 26.5 '' counter stool"
            },
            {
                "product_id": 6693,
                "product_name": "counter bar stool"
            },
            {
                "product_id": 27598,
                "product_name": "alexie 25 '' counter stool"
            },
            {
                "product_id": 24717,
                "product_name": "avah bar & counter stool"
            },
            {
                "product_id": 20380,
                "product_name": "henn 26 '' counter stool"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 35809,
                "product_name": "braxton counter & bar stool"
            },
            {
                "product_id": 19558,
                "product_name": "madiera 24 '' upholstered counter bar stool"
            },
            {
                "product_id": 39825,
                "product_name": "margr industrial 26 '' counter stool"
            },
            {
                "product_id": 34118,
                "product_name": "cadrea upholstered counter & bar stool"
            },
            {
                "product_id": 26825,
                "product_name": "whorton counter & bar stool"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            }
        ],
        "garOutput": [
            {
                "product_id": 9318,
                "product_name": "albrecht counter & bar swivel stool"
            },
            {
                "product_id": 34653,
                "product_name": "reedley counter stool"
            },
            {
                "product_id": 36112,
                "product_name": "severine 30 '' bar stool"
            },
            {
                "product_id": 3705,
                "product_name": "derbyshire bar & counter stool"
            },
            {
                "product_id": 39539,
                "product_name": "chafin bar & counter stool"
            }
        ],
        "query": "brendon counter stool"
    },
    {
        "id": 240,
        "label": [
            {
                "product_id": 38587,
                "product_name": "distressed canadian cities black and white skinny - wrapped canvas textual art print"
            },
            {
                "product_id": 31583,
                "product_name": "guitar and soul wall art on wrapped canvas"
            },
            {
                "product_id": 19426,
                "product_name": "paris eiffel tower by preston - photographic print on canvas"
            },
            {
                "product_id": 13584,
                "product_name": "modern art paris eiffel tower splashes graphic art"
            },
            {
                "product_id": 13465,
                "product_name": "black and white view of paris eiffel tower 5 piece wall art on wrapped canvas set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 9692,
                "product_name": "vegetation near eiffel tower - 3 piece wall art on wrapped canvas set"
            },
            {
                "product_id": 13457,
                "product_name": "pop art paris eiffel tower graphic art on wrapped canvas"
            },
            {
                "product_id": 13581,
                "product_name": "city art paris eiffel tower graphic art on wrapped canvas"
            },
            {
                "product_id": 13869,
                "product_name": "the murdered marat - print"
            },
            {
                "product_id": 9688,
                "product_name": "grayscale eiffel tower - photograph print on canvas"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 28024,
                "product_name": "led lighted famous eiffel tower paris france at night photographic print on canvas"
            },
            {
                "product_id": 29144,
                "product_name": "mcdonald dinosaur lake canvas art"
            },
            {
                "product_id": 29761,
                "product_name": "la grand rue , argenteuil sisley framed painting"
            },
            {
                "product_id": 29150,
                "product_name": "medrano dinosaur portrait - triceratops paper print"
            },
            {
                "product_id": 40825,
                "product_name": "gefen geometric polygon rooster figurine"
            }
        ],
        "garOutput": [
            {
                "product_id": 29515,
                "product_name": "abstract wall d\u00e9cor"
            },
            {
                "product_id": 22986,
                "product_name": "turquoise succulent - wrapped canvas photograph print"
            },
            {
                "product_id": 42738,
                "product_name": "collingwood power loom red/black/white rug"
            },
            {
                "product_id": 34386,
                "product_name": "6 '' x 12 '' marble field tile in blue argentino"
            },
            {
                "product_id": 20322,
                "product_name": "morrissey glass top 3 legs end table"
            }
        ],
        "query": "eiffel tower wall art"
    },
    {
        "id": 210,
        "label": [
            {
                "product_id": 41273,
                "product_name": "larkan 66.9 '' faux leather rolled arm loveseat"
            },
            {
                "product_id": 378,
                "product_name": "farryn 26 '' wide top grain leather lounge chair"
            },
            {
                "product_id": 379,
                "product_name": "adela 84.5 '' velvet square arm sofa"
            },
            {
                "product_id": 6783,
                "product_name": "fall 81 '' velvet square arm sofa bed"
            },
            {
                "product_id": 40410,
                "product_name": "gye 88.5 '' velvet tuxedo arm sofa"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 17891,
                "product_name": "melanson patio 5 piece rattan sofa seating group with cushions"
            },
            {
                "product_id": 29044,
                "product_name": "rangeworthy 67 '' genuine leather tuxedo arm loveseat"
            },
            {
                "product_id": 8717,
                "product_name": "alexia 4 piece sofa seating group"
            },
            {
                "product_id": 355,
                "product_name": "aarush 26 '' wide top grain leather lounge chair"
            },
            {
                "product_id": 33539,
                "product_name": "westbourne leather upholstered arm chair in black"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 26887,
                "product_name": "capems 65.35 '' faux leather pillow top arm sleeper"
            },
            {
                "product_id": 11469,
                "product_name": "tiemann 30 '' wide polyester armchair"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 40626,
                "product_name": "rosemead velvet chaise lounge"
            },
            {
                "product_id": 19185,
                "product_name": "erzsebet 33 '' wide power lift assist standard recliner"
            }
        ],
        "garOutput": [
            {
                "product_id": 41353,
                "product_name": "emrick 24 '' wide tufted barrel chair"
            },
            {
                "product_id": 19818,
                "product_name": "ingulu 76 '' wide reversible modular sofa & chaise"
            },
            {
                "product_id": 18700,
                "product_name": "colten 75 '' linen square arm loveseat"
            },
            {
                "product_id": 41455,
                "product_name": "warrick 2 piece living room set"
            },
            {
                "product_id": 10800,
                "product_name": "philo 32.5 '' wide club chair"
            }
        ],
        "query": "love seat wide faux leather tuxedo arm sofa"
    },
    {
        "id": 215,
        "label": [
            {
                "product_id": 5058,
                "product_name": "enderlin king standard bed"
            },
            {
                "product_id": 40379,
                "product_name": "litchville king bed"
            },
            {
                "product_id": 39291,
                "product_name": "14 '' firm gel memory foam mattress and adjustable base set"
            },
            {
                "product_id": 9388,
                "product_name": "sinquefield king standard bed"
            },
            {
                "product_id": 34573,
                "product_name": "8 piece adjustable bed riser set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 5067,
                "product_name": "apollo california king upholstered platform bed"
            },
            {
                "product_id": 2019,
                "product_name": "wayfair sleep\u2122 6 '' firm innerspring mattress"
            },
            {
                "product_id": 39287,
                "product_name": "12 '' plush gel memory foam mattress and adjustable base set"
            },
            {
                "product_id": 9885,
                "product_name": "apresio king low profile standard bed"
            },
            {
                "product_id": 12415,
                "product_name": "29 '' zero gravity adjustable bed with wireless remote"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 2312,
                "product_name": "twin size platform bed with adjustable trundle , espresso"
            },
            {
                "product_id": 15678,
                "product_name": "bed frame"
            },
            {
                "product_id": 14495,
                "product_name": "twin over full bunk bed"
            },
            {
                "product_id": 10078,
                "product_name": "finbar twin over twin bunk bed"
            },
            {
                "product_id": 37196,
                "product_name": "modern linen curved upholstered platform bed , solid wood frame , nailhead trim ( king )"
            }
        ],
        "garOutput": [
            {
                "product_id": 18316,
                "product_name": "modern upholstered platform bed"
            },
            {
                "product_id": 18317,
                "product_name": "anatole tufted upholstered storage platform bed"
            },
            {
                "product_id": 7037,
                "product_name": "4 '' gel memory foam mattress topper"
            },
            {
                "product_id": 18318,
                "product_name": "reinaldo studded winged upholstered storage platform bed"
            },
            {
                "product_id": 3077,
                "product_name": "laforet decorative cotton lumbar pillow"
            }
        ],
        "query": "king adjustable bed including mattress"
    },
    {
        "id": 300,
        "label": [
            {
                "product_id": 15144,
                "product_name": "hedgerley magenta area rug"
            },
            {
                "product_id": 9104,
                "product_name": "geometric machine made power loom wool and polyester green/red area rug"
            },
            {
                "product_id": 10867,
                "product_name": "bohemian distressed geometric cream area rug"
            },
            {
                "product_id": 26894,
                "product_name": "brandt floral navy blue area rug"
            },
            {
                "product_id": 18476,
                "product_name": "ameswood flokati purple area rug"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 32108,
                "product_name": "munroe tribal botemir oriental hand-knotted wool cream/brown area rug"
            },
            {
                "product_id": 17873,
                "product_name": "abstract teal blue indoor / outdoor area rug"
            },
            {
                "product_id": 22913,
                "product_name": "shondra 3 piece gray/brown area rug set"
            },
            {
                "product_id": 41906,
                "product_name": "eagean geometric teal indoor / outdoor area rug"
            },
            {
                "product_id": 15533,
                "product_name": "catharine geometric aqua/grey outdoor area rug"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 18919,
                "product_name": "aftan geometric purple/gray area rug"
            },
            {
                "product_id": 27333,
                "product_name": "berengere 4 '' cotton futon mattress"
            },
            {
                "product_id": 27336,
                "product_name": "vintage floral japanese 4 '' cotton futon mattress"
            },
            {
                "product_id": 27335,
                "product_name": "vintage floral japanese 4 '' cotton futon mattress"
            },
            {
                "product_id": 42543,
                "product_name": "hartland abc barnyard power loomed green/red area rug"
            }
        ],
        "garOutput": [
            {
                "product_id": 4101,
                "product_name": "katie oriental cream/navy area rug"
            },
            {
                "product_id": 37603,
                "product_name": "gassaway multi area rug"
            },
            {
                "product_id": 41867,
                "product_name": "burnstad abstract navy/aqua/light gray/white area rug"
            },
            {
                "product_id": 4661,
                "product_name": "chanler hand-tufted wool beige/black area rug"
            },
            {
                "product_id": 12099,
                "product_name": "red/green/gray area rug"
            }
        ],
        "query": "animal print handmade tufted wool beige/black area rug by allmodern"
    },
    {
        "id": 444,
        "label": [
            {
                "product_id": 22104,
                "product_name": "premium steel patio bistro set , folding outdoor patio furniture sets , 3 piece patio set of foldable patio table and chairs , yellow"
            },
            {
                "product_id": 5837,
                "product_name": "tripp outdoor patio chair with sunbrella cushions"
            },
            {
                "product_id": 30773,
                "product_name": "make it rain cerulean indoor/outdoor chaise lounge cushion"
            },
            {
                "product_id": 21612,
                "product_name": "grimsley 28 '' wide tufted polyester lounge chair"
            },
            {
                "product_id": 30676,
                "product_name": "loch reclining chaise lounge set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 36616,
                "product_name": "giant king crab sculptural lounge chair"
            },
            {
                "product_id": 23588,
                "product_name": "lounger patio chair"
            },
            {
                "product_id": 34062,
                "product_name": "alois chaise lounge set with cushion and table"
            },
            {
                "product_id": 38655,
                "product_name": "tallulah down outdoor seat/back cushion"
            },
            {
                "product_id": 22304,
                "product_name": "kelston mills elegant sun lounger set with cushion"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 12350,
                "product_name": "rossmere indoor/outdoor bench cushion"
            },
            {
                "product_id": 31143,
                "product_name": "amilliah tufted upholstered parsons chair"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 7605,
                "product_name": "ayala 3 piece rattan seating group with cushions"
            },
            {
                "product_id": 25149,
                "product_name": "memory foam pad indoor dining chair cushion"
            },
            {
                "product_id": 35612,
                "product_name": "fernando protective 3 piece water resistant patio furniture cover set"
            },
            {
                "product_id": 8471,
                "product_name": "chapp daybed"
            },
            {
                "product_id": 31353,
                "product_name": "fairfield protective water resistant patio furniture set cover"
            }
        ],
        "query": "outdoor lounge chair"
    },
    {
        "id": 255,
        "label": [
            {
                "product_id": 9617,
                "product_name": "kids chair desk with storage bin"
            },
            {
                "product_id": 665,
                "product_name": "gilden 31.5 '' writing desk and chair set"
            },
            {
                "product_id": 5252,
                "product_name": "fahlbusch desk and chair set"
            },
            {
                "product_id": 22579,
                "product_name": "arima desk and chair set"
            },
            {
                "product_id": 18278,
                "product_name": "blanchet 23.6 '' w writing desk and chair set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 2879,
                "product_name": "cyra desk and chair set"
            },
            {
                "product_id": 40348,
                "product_name": "lainey counter height chair set"
            },
            {
                "product_id": 34009,
                "product_name": "elsje kids desk / activity chair"
            },
            {
                "product_id": 41211,
                "product_name": "dellia counter height chair ( set of 2 )"
            },
            {
                "product_id": 37757,
                "product_name": "reversible l-shape desk and chair set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 31143,
                "product_name": "amilliah tufted upholstered parsons chair"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            }
        ],
        "garOutput": [
            {
                "product_id": 22011,
                "product_name": "darnetha 70.5 '' faux leather pillow top arm convertible sleeper sofa bed"
            },
            {
                "product_id": 738,
                "product_name": "runner groverson geometric blue area rug"
            },
            {
                "product_id": 739,
                "product_name": "runner groversen geometric gray area rug"
            },
            {
                "product_id": 9449,
                "product_name": "orpha globe single curtain rod"
            },
            {
                "product_id": 35308,
                "product_name": "atlanta core square telescoping drapery single curtain rod"
            }
        ],
        "query": "desk and chair set"
    },
    {
        "id": 475,
        "label": [
            {
                "product_id": 42097,
                "product_name": "dreyfus iron truck"
            },
            {
                "product_id": 42099,
                "product_name": "old style pickup truck"
            },
            {
                "product_id": 8484,
                "product_name": "beaudin 4 piece rattan multiple chairs seating group with cushions"
            },
            {
                "product_id": 42094,
                "product_name": "rimsdale iron truck"
            },
            {
                "product_id": 6709,
                "product_name": "cacapon 2 sided solid truck"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 10242,
                "product_name": "stock truck"
            },
            {
                "product_id": 678,
                "product_name": "stack hand truck dolly"
            },
            {
                "product_id": 38390,
                "product_name": "550 lb . capacity hand truck"
            },
            {
                "product_id": 16211,
                "product_name": "metal antique truck"
            },
            {
                "product_id": 5637,
                "product_name": "legros metal and wood truck"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 9624,
                "product_name": "military challenge coin cabinet rack holder display case"
            }
        ],
        "garOutput": [
            {
                "product_id": 24063,
                "product_name": "zackery nesting serving pedestal 3 piece tray set"
            },
            {
                "product_id": 32068,
                "product_name": "resin rattan 4 drawer storage chest"
            },
            {
                "product_id": 2557,
                "product_name": "castle solid wood coffee table"
            },
            {
                "product_id": 16497,
                "product_name": "rugsotic carpets hand-knotted orange area rug"
            },
            {
                "product_id": 39413,
                "product_name": "aking storage cabinet"
            }
        ],
        "query": "rattan truck"
    },
    {
        "id": 445,
        "label": [
            {
                "product_id": 2937,
                "product_name": "kehlani 73.75 '' wide reversible sofa & chaise"
            },
            {
                "product_id": 17094,
                "product_name": "quitaque 112 '' wide left hand facing sofa & chaise"
            },
            {
                "product_id": 4549,
                "product_name": "paramount 102 '' wide sofa & chaise"
            },
            {
                "product_id": 9307,
                "product_name": "morada one left recessed arm chaise lounge"
            },
            {
                "product_id": 1686,
                "product_name": "angelica chaise lounge"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 14474,
                "product_name": "water resistant patio chaise lounge cover"
            },
            {
                "product_id": 5145,
                "product_name": "amaanat tufted armless chaise lounge"
            },
            {
                "product_id": 21930,
                "product_name": "achary outdoor reclining chaise lounge with cushion"
            },
            {
                "product_id": 8441,
                "product_name": "romero zero gravity reclining chaise lounge"
            },
            {
                "product_id": 14719,
                "product_name": "cape chaise lounge"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 15446,
                "product_name": "ulibarri 6 piece rattan sectional seating group with cushions"
            },
            {
                "product_id": 26887,
                "product_name": "capems 65.35 '' faux leather pillow top arm sleeper"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 19185,
                "product_name": "erzsebet 33 '' wide power lift assist standard recliner"
            }
        ],
        "garOutput": [
            {
                "product_id": 22745,
                "product_name": "pelchat 53 '' wide reversible sofa & chaise with ottoman"
            },
            {
                "product_id": 22747,
                "product_name": "brucelee tufted velvet side chair"
            },
            {
                "product_id": 29789,
                "product_name": "berlinville patio sofa"
            },
            {
                "product_id": 34357,
                "product_name": "gaura 2 piece sleeper living room set"
            },
            {
                "product_id": 34356,
                "product_name": "gattaca 2 piece sleeper living room set"
            }
        ],
        "query": "chaise lounge couch"
    },
    {
        "id": 308,
        "label": [
            {
                "product_id": 17270,
                "product_name": "60 watt equivalent b11 led dimmable led edison candle light bulb e12 base"
            },
            {
                "product_id": 21350,
                "product_name": "40 watt equivalent , g16.5 led , dimmable light bulb , warm white ( 2700k ) e12/candelabra base"
            },
            {
                "product_id": 99,
                "product_name": "solar powered integrated led outdoor floor lamp"
            },
            {
                "product_id": 21952,
                "product_name": "5m 3528 rgb led strip smd lights string lights"
            },
            {
                "product_id": 5965,
                "product_name": "solar powered powered led spot light"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 2197,
                "product_name": "10m bluetooth music 5050 rgb led strip light waterproof smartphone app control"
            },
            {
                "product_id": 2192,
                "product_name": "2m 2835rgb led strip lights smart home wifi strip light app control waterproof"
            },
            {
                "product_id": 30215,
                "product_name": "60 watt , g30 , incandescent dimmable light bulb , warm white ( 2700k ) e26/medium ( standard ) base"
            },
            {
                "product_id": 16243,
                "product_name": "bronze solar powered integrated led pathway light pack"
            },
            {
                "product_id": 21967,
                "product_name": "5 watt ( 40 watt equivalent ) , b10 led , dimmable light bulb , warm white ( 2700k ) e12/candelabra ( standard ) base"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 41787,
                "product_name": "luffa on twig stem berry bushes"
            },
            {
                "product_id": 10349,
                "product_name": "eira trestle coffee table"
            },
            {
                "product_id": 41246,
                "product_name": "rae dunn stem print 3.5 qt . flour canister"
            },
            {
                "product_id": 41247,
                "product_name": "stem print by rae dunn 5 qt . tea jar"
            },
            {
                "product_id": 37177,
                "product_name": "employee time report card"
            }
        ],
        "garOutput": [
            {
                "product_id": 35992,
                "product_name": "16.4 ft led strip light , 528 leds cob bendable tape light , 2700k soft white , ul listed"
            },
            {
                "product_id": 39480,
                "product_name": "all in one clip"
            },
            {
                "product_id": 28000,
                "product_name": "16.5w g13 led light bulb"
            },
            {
                "product_id": 40754,
                "product_name": "25 watt equivalent pink e12/candelabra led light bulb"
            },
            {
                "product_id": 40753,
                "product_name": "25 watt equivalent orange e12/candelabra led light bulb"
            }
        ],
        "query": "led 60"
    },
    {
        "id": 356,
        "label": [
            {
                "product_id": 28629,
                "product_name": "guisasola twin tufted upholstered platform bed"
            },
            {
                "product_id": 31880,
                "product_name": "lieberum metal platform bed"
            },
            {
                "product_id": 27382,
                "product_name": "reeder queen tufted low profile platform bed"
            },
            {
                "product_id": 5693,
                "product_name": "swineford tufted upholstered low profile storage platform bed"
            },
            {
                "product_id": 27653,
                "product_name": "paralimni end table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 35145,
                "product_name": "flam queen platform bed"
            },
            {
                "product_id": 8223,
                "product_name": "gutierres king upholstered platform bed"
            },
            {
                "product_id": 3078,
                "product_name": "saltford twisted teak side table"
            },
            {
                "product_id": 14806,
                "product_name": "chadwick tufted button upholstered platform bed"
            },
            {
                "product_id": 799,
                "product_name": "makenzie folding wooden side table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 30158,
                "product_name": "hershberger buffet table"
            },
            {
                "product_id": 37195,
                "product_name": "wood platform bed with headboard , wood slat , queen"
            },
            {
                "product_id": 37192,
                "product_name": "wood platform bed with two drawers , full ( white )"
            },
            {
                "product_id": 10078,
                "product_name": "finbar twin over twin bunk bed"
            },
            {
                "product_id": 38244,
                "product_name": "agirta extendable dining table"
            }
        ],
        "garOutput": [
            {
                "product_id": 187,
                "product_name": "maur kids club chair"
            },
            {
                "product_id": 33248,
                "product_name": "andrie kitchen island set"
            },
            {
                "product_id": 22011,
                "product_name": "darnetha 70.5 '' faux leather pillow top arm convertible sleeper sofa bed"
            },
            {
                "product_id": 8395,
                "product_name": "denchev chaise lounge"
            },
            {
                "product_id": 35189,
                "product_name": "arlen platform bed with 3 drawers"
            }
        ],
        "query": "platform bed side table"
    },
    {
        "id": 273,
        "label": [
            {
                "product_id": 20411,
                "product_name": "paulina shower caddy"
            },
            {
                "product_id": 17588,
                "product_name": "bamboo shower caddy"
            },
            {
                "product_id": 31830,
                "product_name": "delia ultra constant tension bathroom shower caddy"
            },
            {
                "product_id": 31799,
                "product_name": "castello free standing shower caddy"
            },
            {
                "product_id": 13816,
                "product_name": "townson shower caddy"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 14979,
                "product_name": "bennett 26.5 '' w x 32.25 '' h x 13 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 10424,
                "product_name": "free standing 2 roll toilet tissue stand"
            },
            {
                "product_id": 2892,
                "product_name": "salina 31.49 '' w x 62.95 '' h x 15.74 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 40694,
                "product_name": "lukens 3 tier shower caddy"
            },
            {
                "product_id": 8456,
                "product_name": "mclachlan 23.5 '' w x 31.5 '' h x 11.75 '' d free-standing bathroom cabinet"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 38224,
                "product_name": "riggins 24 '' single bathroom vanity set"
            },
            {
                "product_id": 19717,
                "product_name": "commercial single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 19729,
                "product_name": "waterfall single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 39991,
                "product_name": "bathroom rain volume shower system rough-in valve"
            },
            {
                "product_id": 19708,
                "product_name": "waterfall single hole bathroom faucet"
            }
        ],
        "garOutput": [
            {
                "product_id": 22688,
                "product_name": "stelios wall mounted towel hook"
            },
            {
                "product_id": 31805,
                "product_name": "3-tier shower caddy"
            },
            {
                "product_id": 16304,
                "product_name": "splashables shower caddy"
            },
            {
                "product_id": 20461,
                "product_name": "pantheon 24 '' x 24 '' porcelain field tile"
            },
            {
                "product_id": 20460,
                "product_name": "pantheon natural 24 '' x 24 '' porcelain field tile"
            }
        ],
        "query": "stainless steel free standing shower caddy"
    },
    {
        "id": 204,
        "label": [
            {
                "product_id": 31637,
                "product_name": "iron wall sconce candle holder"
            },
            {
                "product_id": 38874,
                "product_name": "annville 1-light wall sconce"
            },
            {
                "product_id": 35991,
                "product_name": "dimmable antique edison st64 incandescent bulbs , 2200k amber light , e26 base 60w vintage squirrel cage filament light bulb for pendant , chandelier , lantern , wall sconce"
            },
            {
                "product_id": 42634,
                "product_name": "peacock tall iron wall sconce"
            },
            {
                "product_id": 2102,
                "product_name": "singularity 1-light wall sconce"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 24801,
                "product_name": "cavan contemporary 1-light wall sconce"
            },
            {
                "product_id": 8711,
                "product_name": "2 piece tall metal candlestick set"
            },
            {
                "product_id": 8731,
                "product_name": "dorothea industrial 1-light armed sconce"
            },
            {
                "product_id": 5719,
                "product_name": "conovan 1-light wall sconce"
            },
            {
                "product_id": 20683,
                "product_name": "retro antique vintage rustic lantern lamp wall sconce light garden yard outdoor"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 12441,
                "product_name": "sunflower decorative bird feeder"
            },
            {
                "product_id": 25829,
                "product_name": "starfish chips and dip platter"
            },
            {
                "product_id": 32210,
                "product_name": "biggs drum cage 3 piece wall shelf set"
            },
            {
                "product_id": 24250,
                "product_name": "rodrigue 6 - light candle style drum chandelier"
            },
            {
                "product_id": 33455,
                "product_name": "mayall rooster"
            }
        ],
        "garOutput": [
            {
                "product_id": 31950,
                "product_name": "traditional bronze pull 8 '' center to center bar pull"
            },
            {
                "product_id": 9604,
                "product_name": "balmorhea farmhouse farm life rooster figurine"
            },
            {
                "product_id": 22786,
                "product_name": "eiffel tower by sue schlabach graphic art on canvas"
            },
            {
                "product_id": 21650,
                "product_name": "sliced floating shelf"
            },
            {
                "product_id": 4169,
                "product_name": "liverman end table"
            }
        ],
        "query": "fleur de lis living candle wall sconce bronze"
    },
    {
        "id": 162,
        "label": [
            {
                "product_id": 30635,
                "product_name": "gaastra patio chair"
            },
            {
                "product_id": 16966,
                "product_name": "weesner upholstered dining chair"
            },
            {
                "product_id": 8362,
                "product_name": "connery upholstered parsons chair"
            },
            {
                "product_id": 13209,
                "product_name": "kelleia patio chair"
            },
            {
                "product_id": 20026,
                "product_name": "ginata salon beauty drafting chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 11292,
                "product_name": "lindsey velvet upholstered side chair"
            },
            {
                "product_id": 32172,
                "product_name": "falmouth armless patio chair with cushions"
            },
            {
                "product_id": 16794,
                "product_name": "pina 33.5 '' wide armchair"
            },
            {
                "product_id": 26148,
                "product_name": "kelsi upholstered parsons chair in blue"
            },
            {
                "product_id": 10368,
                "product_name": "bonnameade ergonomic executive chair"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 42324,
                "product_name": "loftis 27.6 '' wide velvet wingback chair"
            },
            {
                "product_id": 12351,
                "product_name": "rossmere indoor/outdoor dining chair cushion"
            },
            {
                "product_id": 38673,
                "product_name": "abdulsamet coffee table"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 890,
                "product_name": "sapphira reversible quilt set"
            }
        ],
        "garOutput": [
            {
                "product_id": 19922,
                "product_name": "shonna 25.2 '' w closet rod"
            },
            {
                "product_id": 24638,
                "product_name": "lovins coffee table"
            },
            {
                "product_id": 32383,
                "product_name": "pull-out side mount belt rack"
            },
            {
                "product_id": 37258,
                "product_name": "elborough geometric yellow rug"
            },
            {
                "product_id": 7531,
                "product_name": "riser"
            }
        ],
        "query": "turquoise chair"
    },
    {
        "id": 43,
        "label": [
            {
                "product_id": 26154,
                "product_name": "kellaman drum end table"
            },
            {
                "product_id": 8836,
                "product_name": "anleigh end table with storage"
            },
            {
                "product_id": 20503,
                "product_name": "butner end table"
            },
            {
                "product_id": 37653,
                "product_name": "alameda end table"
            },
            {
                "product_id": 34975,
                "product_name": "joslin solid wood lift top block coffee table with storage"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 1268,
                "product_name": "mosquera 2 - drawer end table"
            },
            {
                "product_id": 19373,
                "product_name": "mahaffie end table with storage"
            },
            {
                "product_id": 37298,
                "product_name": "winooski root ball end table"
            },
            {
                "product_id": 34511,
                "product_name": "noni 2 - drawer end table"
            },
            {
                "product_id": 37414,
                "product_name": "matton solid wood coffee table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 38673,
                "product_name": "abdulsamet coffee table"
            },
            {
                "product_id": 5962,
                "product_name": "delois bunching coffee table"
            },
            {
                "product_id": 41836,
                "product_name": "liesbeth stool"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 10377,
                "product_name": "avabella trestle coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 13257,
                "product_name": "erf , inc. square 35 '' l x 35 '' w table"
            },
            {
                "product_id": 22264,
                "product_name": "metal frame for use with tahoe bathroom sink"
            },
            {
                "product_id": 14039,
                "product_name": "concordia solid wood end table"
            },
            {
                "product_id": 7139,
                "product_name": "musgrove executive chair"
            },
            {
                "product_id": 2772,
                "product_name": "crowl solid wood block coffee table"
            }
        ],
        "query": "solid teak end table"
    },
    {
        "id": 221,
        "label": [
            {
                "product_id": 36288,
                "product_name": "richwood 3 piece bar height dining set"
            },
            {
                "product_id": 25058,
                "product_name": "weatherholt 5 - piece counter height dining set"
            },
            {
                "product_id": 24849,
                "product_name": "3 piece coffee table set"
            },
            {
                "product_id": 1330,
                "product_name": "donegal 3 - piece counter height drop leaf solid oak dining set"
            },
            {
                "product_id": 214,
                "product_name": "guillot dining table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 16660,
                "product_name": "gibson dining table"
            },
            {
                "product_id": 26150,
                "product_name": "giana paisley upholstered dining chair"
            },
            {
                "product_id": 11388,
                "product_name": "farrah folding plastic dining table"
            },
            {
                "product_id": 8321,
                "product_name": "weller 30 '' dining table"
            },
            {
                "product_id": 34753,
                "product_name": "bonaire dining table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 31143,
                "product_name": "amilliah tufted upholstered parsons chair"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 15446,
                "product_name": "ulibarri 6 piece rattan sectional seating group with cushions"
            },
            {
                "product_id": 30158,
                "product_name": "hershberger buffet table"
            }
        ],
        "garOutput": [
            {
                "product_id": 13504,
                "product_name": "teak tripod end table"
            },
            {
                "product_id": 37760,
                "product_name": "albion 3 - piece dining set"
            },
            {
                "product_id": 514,
                "product_name": "48 '' rectangular portable folding table"
            },
            {
                "product_id": 41491,
                "product_name": "kymber round cotton pouf"
            },
            {
                "product_id": 20370,
                "product_name": "31.5 '' square fold-in-half portable folding table"
            }
        ],
        "query": "small space dining table and chairs sets"
    },
    {
        "id": 193,
        "label": [
            {
                "product_id": 21888,
                "product_name": "ebern designs irram 18 minimalist arc table lamp w/ 2.0 amp usb charging port , honey beige shade + 7w bulb ( eco-friendly , 3000k warm white ) , black finish"
            },
            {
                "product_id": 25921,
                "product_name": "videira florale 22.5 '' table lamp"
            },
            {
                "product_id": 33242,
                "product_name": "mclea 19 '' bedside table lamp"
            },
            {
                "product_id": 16537,
                "product_name": "kwong 29 '' table lamp set"
            },
            {
                "product_id": 3304,
                "product_name": "31 '' cream table lamp"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 25609,
                "product_name": "jaxxon 27 '' table lamp"
            },
            {
                "product_id": 36461,
                "product_name": "aneka 8.25 '' table lamp"
            },
            {
                "product_id": 6561,
                "product_name": "tarbell 22 '' lamp"
            },
            {
                "product_id": 5655,
                "product_name": "fayzan 23 '' white table lamp"
            },
            {
                "product_id": 17098,
                "product_name": "fults 27.5 '' table lamp"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 3728,
                "product_name": "bless our home metal sign"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            }
        ],
        "garOutput": [
            {
                "product_id": 15252,
                "product_name": "tajana 17 ft. outdoor led 50-light fairy string light"
            },
            {
                "product_id": 13629,
                "product_name": "bedroom led nightstand , black/white modern high gloss led lights nightstand bedside end table with 2 drawers table cabinet with 2 drawers ( black )"
            },
            {
                "product_id": 16646,
                "product_name": "modern high gloss nightstand white night stand with led light , double drawer nightstand cabinet storage bedside tables with drawer 2-tier for bedroom living room"
            },
            {
                "product_id": 9415,
                "product_name": "folcroft wooden console table"
            },
            {
                "product_id": 1173,
                "product_name": "8.5 '' outdoor lantern"
            }
        ],
        "query": "belchertown table lamp"
    },
    {
        "id": 314,
        "label": [
            {
                "product_id": 21059,
                "product_name": "stag body pillow case"
            },
            {
                "product_id": 35747,
                "product_name": "tempur-medium memory foam body pillow"
            },
            {
                "product_id": 23548,
                "product_name": "siegrist microbead body pillow cover"
            },
            {
                "product_id": 39173,
                "product_name": "wayfair basics\u00ae cotton body pillow protector"
            },
            {
                "product_id": 22,
                "product_name": "miles body pillow case"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 11475,
                "product_name": "pillowcase"
            },
            {
                "product_id": 39179,
                "product_name": "polyester body medium support pillow"
            },
            {
                "product_id": 23464,
                "product_name": "meacham polyester / polyfill body pillow"
            },
            {
                "product_id": 1398,
                "product_name": "allergy protection polyfill body medium support pillow"
            },
            {
                "product_id": 23547,
                "product_name": "siegrist animal print soft microbead body pillow case"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 15807,
                "product_name": "barth cotton body medium support pillow"
            },
            {
                "product_id": 25557,
                "product_name": "connolly tufted upholstered low profile standard bed"
            },
            {
                "product_id": 14106,
                "product_name": "veronica 5 drawer chest"
            }
        ],
        "garOutput": [
            {
                "product_id": 27495,
                "product_name": "1-up hard cover pet brag album"
            },
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 29670,
                "product_name": "enameled 3 piece coffee , tea , & sugar jar set"
            },
            {
                "product_id": 38132,
                "product_name": "geometric teal indoor / outdoor area rug"
            },
            {
                "product_id": 27810,
                "product_name": "sayers damask 8 piece comforter set"
            }
        ],
        "query": "body pillow and case"
    },
    {
        "id": 263,
        "label": [
            {
                "product_id": 23057,
                "product_name": "alberton adjustable 0.63 ''"
            },
            {
                "product_id": 17142,
                "product_name": "new hartford single curtain rod"
            },
            {
                "product_id": 4774,
                "product_name": "annabel adjustable 72 '' to 144 '' 1 '' single curtain rod"
            },
            {
                "product_id": 9449,
                "product_name": "orpha globe single curtain rod"
            },
            {
                "product_id": 34447,
                "product_name": "decorative spring tension single curtain tension rod"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 4768,
                "product_name": "sonoma room darkening wrap curtain single rod"
            },
            {
                "product_id": 8680,
                "product_name": "is-haq striped semi-sheer rod pocket single curtain panel"
            },
            {
                "product_id": 13679,
                "product_name": "illinois prism single curtain rod"
            },
            {
                "product_id": 36966,
                "product_name": "sweitzer single curtain rod"
            },
            {
                "product_id": 37624,
                "product_name": "adjustable 0.75 '' double curtain rod"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 21200,
                "product_name": "cutts bar with wine storage"
            },
            {
                "product_id": 3197,
                "product_name": "double shower curtain hooks"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 1654,
                "product_name": "aaiyana striped sheer grommet single curtain panel"
            },
            {
                "product_id": 1657,
                "product_name": "solid color sheer grommet single curtain panel"
            }
        ],
        "garOutput": [
            {
                "product_id": 15594,
                "product_name": "parkey 23.62 '' w x 62.99 '' h x 8.27 '' d over-the-toilet storage"
            },
            {
                "product_id": 15592,
                "product_name": "parkerson 23.62 '' w x 62.99 '' h x 8.27 '' d over-the-toilet storage"
            },
            {
                "product_id": 738,
                "product_name": "runner groverson geometric blue area rug"
            },
            {
                "product_id": 739,
                "product_name": "runner groversen geometric gray area rug"
            },
            {
                "product_id": 36873,
                "product_name": "spengler 17.72 '' w closet organizer"
            }
        ],
        "query": "small curtain rods"
    },
    {
        "id": 105,
        "label": [
            {
                "product_id": 30019,
                "product_name": "120inch hd projector screen 16:9 home cinema theater projection portable screen"
            },
            {
                "product_id": 14191,
                "product_name": "jumbo projector screen"
            },
            {
                "product_id": 12698,
                "product_name": "yardmaster2 white portable folding frame projection screen"
            },
            {
                "product_id": 10683,
                "product_name": "premier electric projection screen"
            },
            {
                "product_id": 27856,
                "product_name": "excelvan white 100 '' manual projection screen"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 27863,
                "product_name": "excelvan 100 '' portable projection screen"
            },
            {
                "product_id": 12699,
                "product_name": "designer cut white 135 '' diagonal portable projection screen"
            },
            {
                "product_id": 36885,
                "product_name": "white 80 '' portable projection screen"
            },
            {
                "product_id": 13255,
                "product_name": "white portable inflatable projection screen"
            },
            {
                "product_id": 12705,
                "product_name": "yard master series outdoor white electric wall projection screen"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 12696,
                "product_name": "white 163 '' portable projection screen"
            },
            {
                "product_id": 8740,
                "product_name": "alessandro rectangular multi-tiered plant stand"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 8751,
                "product_name": "large kids play house heavy duty canvas cotton with floor mat for bedroom indoor outdoor"
            }
        ],
        "garOutput": [
            {
                "product_id": 12712,
                "product_name": "diy wall 3 series white portable wall projection screen"
            },
            {
                "product_id": 13504,
                "product_name": "teak tripod end table"
            },
            {
                "product_id": 33664,
                "product_name": "iaconetti storage cabinet"
            },
            {
                "product_id": 33665,
                "product_name": "delasandro metal storage cabinet"
            },
            {
                "product_id": 12690,
                "product_name": "yardmaster2 white portable folding frame projector screen"
            }
        ],
        "query": "outdoor movie screen"
    },
    {
        "id": 53,
        "label": [
            {
                "product_id": 5347,
                "product_name": "taub wooden console table"
            },
            {
                "product_id": 20933,
                "product_name": "malachi 44 '' console table"
            },
            {
                "product_id": 6801,
                "product_name": "parman root console table"
            },
            {
                "product_id": 3868,
                "product_name": "madison console table"
            },
            {
                "product_id": 41348,
                "product_name": "delvontae 19.7 '' solid wood console table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 38690,
                "product_name": "abdurrahim console table"
            },
            {
                "product_id": 29436,
                "product_name": "casas 48.5 '' console table"
            },
            {
                "product_id": 2904,
                "product_name": "aria console table"
            },
            {
                "product_id": 5424,
                "product_name": "ghita wooden console table"
            },
            {
                "product_id": 7407,
                "product_name": "ocampo mahogany entrance console table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 30158,
                "product_name": "hershberger buffet table"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            }
        ],
        "garOutput": [
            {
                "product_id": 12913,
                "product_name": "virtue 18 '' x 18 '' porcelain spanish wall & floor tile"
            },
            {
                "product_id": 15621,
                "product_name": "halethorpe rock climbing throw pillow cover"
            },
            {
                "product_id": 35992,
                "product_name": "16.4 ft led strip light , 528 leds cob bendable tape light , 2700k soft white , ul listed"
            },
            {
                "product_id": 35993,
                "product_name": "16.4 ft led strip light , 528 leds cob bendable tape light , 4000k cool white , ul listed"
            },
            {
                "product_id": 23392,
                "product_name": "1 5/16 '' diameter round knob"
            }
        ],
        "query": "carolyn console table"
    },
    {
        "id": 395,
        "label": [
            {
                "product_id": 41783,
                "product_name": "lilly-anne 49.4 '' kitchen pantry"
            },
            {
                "product_id": 31532,
                "product_name": "scholl storage cabinet"
            },
            {
                "product_id": 31258,
                "product_name": "scholl storage cabinet"
            },
            {
                "product_id": 42279,
                "product_name": "kitchen pantry organizer upper wire basket set"
            },
            {
                "product_id": 24056,
                "product_name": "easy essentials pantry bread box and divided 169 oz . food storage container"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 26546,
                "product_name": "mint pantry hardaway 4 piece stainless steel colander set"
            },
            {
                "product_id": 25183,
                "product_name": "cockfosters 71 '' kitchen pantry"
            },
            {
                "product_id": 18246,
                "product_name": "bryaunna 34 '' kitchen pantry"
            },
            {
                "product_id": 26545,
                "product_name": "mint pantry fragoza mango wood pineapple cutting board"
            },
            {
                "product_id": 31532,
                "product_name": "scholl storage cabinet"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 34251,
                "product_name": "janne waste basket"
            },
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 13758,
                "product_name": "kisner server"
            },
            {
                "product_id": 11055,
                "product_name": "harcrest writing desk"
            }
        ],
        "garOutput": [
            {
                "product_id": 37258,
                "product_name": "elborough geometric yellow rug"
            },
            {
                "product_id": 17910,
                "product_name": "meditation"
            },
            {
                "product_id": 20456,
                "product_name": "3 '' x 6 '' glass subway tile"
            },
            {
                "product_id": 12865,
                "product_name": "symbals 14 '' x 14 '' porcelain concrete look wall & floor tile"
            },
            {
                "product_id": 4169,
                "product_name": "liverman end table"
            }
        ],
        "query": "pantry grey"
    },
    {
        "id": 1,
        "label": [
            {
                "product_id": 37746,
                "product_name": "union rustic nesting coffee tables , set of 3 end side tables modern furniture decor table sets , sturdy and easy assembly , accent furniture in home office"
            },
            {
                "product_id": 34211,
                "product_name": "dolcie abstract coffee table"
            },
            {
                "product_id": 36140,
                "product_name": "guyapi coffee table"
            },
            {
                "product_id": 16609,
                "product_name": "gundrath wooden coffee table"
            },
            {
                "product_id": 5355,
                "product_name": "belhaven wooden coffee table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 38673,
                "product_name": "abdulsamet coffee table"
            },
            {
                "product_id": 34533,
                "product_name": "huyet coffee table"
            },
            {
                "product_id": 40662,
                "product_name": "meilani coffee table"
            },
            {
                "product_id": 602,
                "product_name": "sled coffee table"
            },
            {
                "product_id": 1434,
                "product_name": "hearne 2 piece configurable coffee table set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 3729,
                "product_name": "hedvige save the bees table runner"
            },
            {
                "product_id": 29241,
                "product_name": "cleotha twin 75 '' wide tufted back convertible sofa"
            },
            {
                "product_id": 34495,
                "product_name": "adriany task chair"
            },
            {
                "product_id": 38673,
                "product_name": "abdulsamet coffee table"
            },
            {
                "product_id": 38991,
                "product_name": "clausen dining table"
            }
        ],
        "garOutput": [
            {
                "product_id": 19103,
                "product_name": "study ladder desk"
            },
            {
                "product_id": 37746,
                "product_name": "union rustic nesting coffee tables , set of 3 end side tables modern furniture decor table sets , sturdy and easy assembly , accent furniture in home office"
            },
            {
                "product_id": 23758,
                "product_name": "double chaise lounge sofa floor couch"
            },
            {
                "product_id": 310,
                "product_name": "roclincourt 3 legs 2 nesting tables"
            },
            {
                "product_id": 29011,
                "product_name": "silke 3 legs 2 nesting tables"
            }
        ],
        "query": "smart coffee table"
    },
    {
        "id": 430,
        "label": [
            {
                "product_id": 29366,
                "product_name": "phoebe handles drilling floor mount 38 '' single right bathroom vanity set"
            },
            {
                "product_id": 3493,
                "product_name": "newtown 72 '' double bathroom vanity set"
            },
            {
                "product_id": 7269,
                "product_name": "cathey 36 '' single bathroom vanity set with mirror"
            },
            {
                "product_id": 3807,
                "product_name": "whitten rustic 20 '' single bathroom vanity set"
            },
            {
                "product_id": 19600,
                "product_name": "30 '' cabinet bathroom vanity base only"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 32096,
                "product_name": "shaker cabinet 36 '' single bathroom vanity base only"
            },
            {
                "product_id": 42973,
                "product_name": "freitas 73 '' double bathroom vanity set"
            },
            {
                "product_id": 14849,
                "product_name": "prestige 23 '' single bathroom vanity base only"
            },
            {
                "product_id": 2441,
                "product_name": "alexis 27 '' single bathroom vanity set"
            },
            {
                "product_id": 14862,
                "product_name": "harvest 34.5 '' single bathroom vanity set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 25869,
                "product_name": "42 '' single bathroom vanity"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 38224,
                "product_name": "riggins 24 '' single bathroom vanity set"
            },
            {
                "product_id": 37727,
                "product_name": "villante 24 '' single bathroom vanity set"
            },
            {
                "product_id": 20543,
                "product_name": "dowitcher bar with wine storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 32674,
                "product_name": "free square base toilet paper holder"
            },
            {
                "product_id": 11522,
                "product_name": "arched square single hole vessel sink bathroom faucet"
            },
            {
                "product_id": 40735,
                "product_name": "p-trap lavatory kit with valves and risers"
            },
            {
                "product_id": 10015,
                "product_name": "13.25 '' w x 32 '' h x 20.24 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 33974,
                "product_name": "novelty knob"
            }
        ],
        "query": "30 inch bathroom vanity"
    },
    {
        "id": 469,
        "label": [
            {
                "product_id": 15317,
                "product_name": "72 '' h x 48 '' w rack"
            },
            {
                "product_id": 38228,
                "product_name": "slat bike hook wall mounted bike rack"
            },
            {
                "product_id": 7559,
                "product_name": "shelftrack 60 '' h x 48 '' w x 12 '' d storage rack"
            },
            {
                "product_id": 32531,
                "product_name": "macedonia freestanding bike rack"
            },
            {
                "product_id": 9432,
                "product_name": "floyd metal storage 21 bottle floor wine bottle rack"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 42171,
                "product_name": "7-shelf steel ball wall mounted sports rack"
            },
            {
                "product_id": 40262,
                "product_name": "aylsham wall mounted sports rack"
            },
            {
                "product_id": 9001,
                "product_name": "weight storage dumbbell rack"
            },
            {
                "product_id": 16202,
                "product_name": "large multi-player wooden display stand flower stand storage rack suitable for garden lawn corner living room balcony courtyard"
            },
            {
                "product_id": 2639,
                "product_name": "sharpe 46.1 '' w clothes rack with rod storage shelf"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 8753,
                "product_name": "4 tier plant stand garden planter metal flower pot rack corner shelving indoor outdoor"
            },
            {
                "product_id": 17223,
                "product_name": "buffet storage cabinet with single glass doors and unique bell handle"
            },
            {
                "product_id": 2233,
                "product_name": "tv stand for tvs up to 50 ''"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 1608,
                "product_name": "stool"
            },
            {
                "product_id": 5844,
                "product_name": "outdoor 60 gallon wicker storage bench"
            },
            {
                "product_id": 9298,
                "product_name": "fleischmann resin deck box"
            },
            {
                "product_id": 8437,
                "product_name": "jessica 24 pair shoe storage cabinet"
            },
            {
                "product_id": 16019,
                "product_name": "1.25 qt . kitchen canister set"
            }
        ],
        "query": "garage sports storage rack"
    },
    {
        "id": 49,
        "label": [
            {
                "product_id": 33380,
                "product_name": "medea wall mounted robe hook"
            },
            {
                "product_id": 317,
                "product_name": "wall mounted towel hook"
            },
            {
                "product_id": 22689,
                "product_name": "elie wall mounted towel hook"
            },
            {
                "product_id": 32732,
                "product_name": "beslagsboden square design single wall mounted hook"
            },
            {
                "product_id": 26609,
                "product_name": "genta wall mounted single robe hook"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 15768,
                "product_name": "studio wall mounted robe hook"
            },
            {
                "product_id": 5628,
                "product_name": "roesch wall mounted robe hook"
            },
            {
                "product_id": 5978,
                "product_name": "kubic single wall mount robe hook"
            },
            {
                "product_id": 21363,
                "product_name": "feltman wall mounted robe hook"
            },
            {
                "product_id": 26612,
                "product_name": "glyde wall mounted robe hook"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 13758,
                "product_name": "kisner server"
            },
            {
                "product_id": 6211,
                "product_name": "hues bar & counter stool"
            },
            {
                "product_id": 347,
                "product_name": "1.93 '' solar powered outdoor floating light"
            },
            {
                "product_id": 4552,
                "product_name": "modu-licious # 1"
            }
        ],
        "garOutput": [
            {
                "product_id": 22443,
                "product_name": "double hamper foldable laundry basket"
            },
            {
                "product_id": 27883,
                "product_name": "ailbhe 35.43 '' w rack"
            },
            {
                "product_id": 38375,
                "product_name": "haru 48 '' platform bed"
            },
            {
                "product_id": 33109,
                "product_name": "isaacs 59 '' w clothes closet portable wardrobe"
            },
            {
                "product_id": 34528,
                "product_name": "bacquie metal full platform bed"
            }
        ],
        "query": "moen matte black hooks"
    },
    {
        "id": 80,
        "label": [
            {
                "product_id": 23773,
                "product_name": "williams 4 drawer chest"
            },
            {
                "product_id": 15784,
                "product_name": "ailin 7 drawer dresser"
            },
            {
                "product_id": 17591,
                "product_name": "showalter 7 drawer dresser"
            },
            {
                "product_id": 18870,
                "product_name": "evanoff 7 drawer dresser with mirror"
            },
            {
                "product_id": 22092,
                "product_name": "kennedy 7 drawer double dresser"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 27656,
                "product_name": "anyrie 7 drawer dresser"
            },
            {
                "product_id": 30848,
                "product_name": "capricorn 7 drawer dresser"
            },
            {
                "product_id": 26012,
                "product_name": "escarcega 7 drawer combo dresser"
            },
            {
                "product_id": 4487,
                "product_name": "mowgli 8 drawer double dresser"
            },
            {
                "product_id": 8893,
                "product_name": "aristodemos 7 drawer dresser"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 41787,
                "product_name": "luffa on twig stem berry bushes"
            },
            {
                "product_id": 33719,
                "product_name": "lift assist power reclining heated full body massage chair"
            },
            {
                "product_id": 41246,
                "product_name": "rae dunn stem print 3.5 qt . flour canister"
            },
            {
                "product_id": 41247,
                "product_name": "stem print by rae dunn 5 qt . tea jar"
            },
            {
                "product_id": 33720,
                "product_name": "lift assist power reclining heated full body massage chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 13504,
                "product_name": "teak tripod end table"
            },
            {
                "product_id": 6096,
                "product_name": "longley 5 drawer storage chest"
            },
            {
                "product_id": 35957,
                "product_name": "oval wire basket with wooden handles"
            },
            {
                "product_id": 33974,
                "product_name": "novelty knob"
            },
            {
                "product_id": 26235,
                "product_name": "targhee pig calendar figurine"
            }
        ],
        "query": "7 draw white dresser"
    },
    {
        "id": 208,
        "label": [
            {
                "product_id": 42974,
                "product_name": "freshour 55 '' single bathroom vanity set"
            },
            {
                "product_id": 26019,
                "product_name": "avance vanity"
            },
            {
                "product_id": 14998,
                "product_name": "voss 36 '' single bathroom vanity set"
            },
            {
                "product_id": 32101,
                "product_name": "sophie cabinet 36 '' single bathroom vanity base only"
            },
            {
                "product_id": 17522,
                "product_name": "24 '' single vanity bathroom vanity base only"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 32104,
                "product_name": "anabele vanity"
            },
            {
                "product_id": 1665,
                "product_name": "36 '' single modern bathroom vanity set"
            },
            {
                "product_id": 1438,
                "product_name": "winscombe 36 '' single bathroom vanity set"
            },
            {
                "product_id": 32191,
                "product_name": "fawkes 48 '' single bathroom vanity set"
            },
            {
                "product_id": 7751,
                "product_name": "galveston 36 '' left drawer single bathroom vanity base only"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 42614,
                "product_name": "amirah owl 22 '' table lamp"
            },
            {
                "product_id": 16515,
                "product_name": "i like your crown by eli halpin - wrapped canvas print"
            },
            {
                "product_id": 20718,
                "product_name": "abramowski monitor stand"
            },
            {
                "product_id": 28024,
                "product_name": "led lighted famous eiffel tower paris france at night photographic print on canvas"
            }
        ],
        "garOutput": [
            {
                "product_id": 11036,
                "product_name": "ariel 36 '' single bathroom vanity set"
            },
            {
                "product_id": 22349,
                "product_name": "portree 30 '' single bathroom vanity"
            },
            {
                "product_id": 38804,
                "product_name": "angeles 60 '' double bathroom vanity set"
            },
            {
                "product_id": 14009,
                "product_name": "ertis 24 '' single bathroom vanity set"
            },
            {
                "product_id": 1461,
                "product_name": "sumner 36 '' transitional single bathroom vanity set"
            }
        ],
        "query": "fawkes 36\" blue vanity"
    },
    {
        "id": 34,
        "label": [
            {
                "product_id": 24188,
                "product_name": "wellman adjustable height swivel bar stool"
            },
            {
                "product_id": 22602,
                "product_name": "smatrik 25.5 '' patio bar stool"
            },
            {
                "product_id": 13146,
                "product_name": "conde bar & counter stool"
            },
            {
                "product_id": 5403,
                "product_name": "havant wooden counter height bar stool"
            },
            {
                "product_id": 2264,
                "product_name": "cian 30 '' bar stool"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 28533,
                "product_name": "lanham counter & bar stool"
            },
            {
                "product_id": 11420,
                "product_name": "carreras wood ladder back bar & counter stool"
            },
            {
                "product_id": 19466,
                "product_name": "delpha bar & counter stool"
            },
            {
                "product_id": 23663,
                "product_name": "meriden bar & counter stool"
            },
            {
                "product_id": 12494,
                "product_name": "28.5 '' patio bar stool"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 32281,
                "product_name": "metal slat back stacking side chair"
            },
            {
                "product_id": 18460,
                "product_name": "adala executive chair"
            },
            {
                "product_id": 30158,
                "product_name": "hershberger buffet table"
            },
            {
                "product_id": 31143,
                "product_name": "amilliah tufted upholstered parsons chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 2437,
                "product_name": "gourmet counter height pub table"
            },
            {
                "product_id": 3164,
                "product_name": "ergonomic pu leather high back office chair with flip-up armrest managerial chair executive chair desk chair computer chair"
            },
            {
                "product_id": 23620,
                "product_name": "blaize upholstered parsons chair"
            },
            {
                "product_id": 24958,
                "product_name": "lowell leather upholstered dining chair"
            },
            {
                "product_id": 34653,
                "product_name": "reedley counter stool"
            }
        ],
        "query": "bar stool with backrest"
    },
    {
        "id": 267,
        "label": [
            {
                "product_id": 33796,
                "product_name": "strait 3 '' x 12 '' ceramic subway tile"
            },
            {
                "product_id": 12898,
                "product_name": "seni orleans angulo 10 '' x 10 '' porcelain patterned subway wall & floor tile"
            },
            {
                "product_id": 37094,
                "product_name": "2 '' x 4 '' mosaic subway wall & floor tile"
            },
            {
                "product_id": 30341,
                "product_name": "keyondria 3 '' x 12 '' glass brick look subway tile"
            },
            {
                "product_id": 27074,
                "product_name": "urbano 4 \u201d x 12 \u201d ceramic subway tile"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 33770,
                "product_name": "catalina 3 '' x 12 '' ceramic subway tile"
            },
            {
                "product_id": 40727,
                "product_name": "premium hand painted 3 '' x 9 '' glass subway tile"
            },
            {
                "product_id": 27178,
                "product_name": "bianco dolomite 4 '' x 12 '' polished marble subway tile"
            },
            {
                "product_id": 12842,
                "product_name": "proyecto 4 '' x 8 '' ceramic subway wall & floor tile"
            },
            {
                "product_id": 38544,
                "product_name": "2 '' x 4 '' porcelain mosaic subway tile"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 27320,
                "product_name": "0.75 '' x 0.75 '' seashell tile"
            },
            {
                "product_id": 16108,
                "product_name": "paride stool"
            },
            {
                "product_id": 27265,
                "product_name": "cobalto le leghe 3 '' x 12 '' porcelain mosaic subway wall & floor tile"
            },
            {
                "product_id": 27264,
                "product_name": "bronzo 4 '' x 12 '' porcelain subway tile"
            },
            {
                "product_id": 7839,
                "product_name": "citadel sunflower fields cheese board"
            }
        ],
        "garOutput": [
            {
                "product_id": 24087,
                "product_name": "pike brazil nut 6 '' x 48 '' straight edge spc vinyl wood look wall & floor tile"
            },
            {
                "product_id": 29767,
                "product_name": "academy porcelain random mosaic wall & floor tile"
            },
            {
                "product_id": 27116,
                "product_name": "california gold 6 '' x 24 '' slate stacked stone tile"
            },
            {
                "product_id": 27118,
                "product_name": "gold rush 6 '' x 24 '' slate stacked stone tile"
            },
            {
                "product_id": 29914,
                "product_name": "penny marble mosaic floor use tile"
            }
        ],
        "query": "dolomite subway tile"
    },
    {
        "id": 372,
        "label": [
            {
                "product_id": 20313,
                "product_name": "callan fairfield privacy door knob"
            },
            {
                "product_id": 20296,
                "product_name": "callan silona keyed door lever"
            },
            {
                "product_id": 20307,
                "product_name": "callan newport single dummy door lever"
            },
            {
                "product_id": 16597,
                "product_name": "callan end table"
            },
            {
                "product_id": 20298,
                "product_name": "callan lexington privacy door lever"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 20312,
                "product_name": "callan carlyle privacy door knob"
            },
            {
                "product_id": 20299,
                "product_name": "callan newport passage door lever"
            },
            {
                "product_id": 16596,
                "product_name": "callan coffee table"
            },
            {
                "product_id": 20293,
                "product_name": "callan silona single dummy door lever"
            },
            {
                "product_id": 20314,
                "product_name": "callan brayden privacy door knob"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 17343,
                "product_name": "maier north carolina melamine tray"
            },
            {
                "product_id": 10349,
                "product_name": "eira trestle coffee table"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            }
        ],
        "garOutput": [
            {
                "product_id": 13864,
                "product_name": "thinking outside the box by wilbur pierce - advertisements print"
            },
            {
                "product_id": 3315,
                "product_name": "rostrum 44 '' solid wood console table"
            },
            {
                "product_id": 3313,
                "product_name": "forest end table"
            },
            {
                "product_id": 3314,
                "product_name": "void 44 '' solid wood console table"
            },
            {
                "product_id": 31989,
                "product_name": "garage storage"
            }
        ],
        "query": "verden callan"
    },
    {
        "id": 91,
        "label": [
            {
                "product_id": 9401,
                "product_name": "ivonne dining table"
            },
            {
                "product_id": 36116,
                "product_name": "jossi dining table"
            },
            {
                "product_id": 1503,
                "product_name": "emestine extendable drop leaf dining table"
            },
            {
                "product_id": 40830,
                "product_name": "bonas rubberwood solid wood dining table"
            },
            {
                "product_id": 42250,
                "product_name": "dulcie dining table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 4948,
                "product_name": "strock extendable dining table"
            },
            {
                "product_id": 2678,
                "product_name": "staas dining table"
            },
            {
                "product_id": 8473,
                "product_name": "pennside counter height dining table"
            },
            {
                "product_id": 23734,
                "product_name": "chambord 27 '' solid wood dining table"
            },
            {
                "product_id": 9339,
                "product_name": "smithton dining table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 1029,
                "product_name": "candelario bar with wine storage"
            },
            {
                "product_id": 13956,
                "product_name": "deauville coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 13504,
                "product_name": "teak tripod end table"
            },
            {
                "product_id": 6096,
                "product_name": "longley 5 drawer storage chest"
            },
            {
                "product_id": 20549,
                "product_name": "cresswell armless stackable chair"
            },
            {
                "product_id": 42713,
                "product_name": "giullia 2 piece floating shelf"
            },
            {
                "product_id": 19531,
                "product_name": "teak block end table"
            }
        ],
        "query": "industrial pipe dining  table"
    },
    {
        "id": 344,
        "label": [
            {
                "product_id": 14602,
                "product_name": "knitted pouf"
            },
            {
                "product_id": 25749,
                "product_name": "gianluca 50 '' tufted rectangle storage ottoman"
            },
            {
                "product_id": 18193,
                "product_name": "adilet 16 '' tufted standard ottoman"
            },
            {
                "product_id": 31239,
                "product_name": "darell 36 '' wide tufted round storage ottoman"
            },
            {
                "product_id": 6510,
                "product_name": "kirkus tufted ottoman"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 18257,
                "product_name": "ivey 13.75 '' wide tufted square footstool ottoman"
            },
            {
                "product_id": 22541,
                "product_name": "iconium 16.3 '' wide round storage ottoman"
            },
            {
                "product_id": 5014,
                "product_name": "almarosa 43.75 '' tufted rectangle standard ottoman"
            },
            {
                "product_id": 2848,
                "product_name": "palasota queen tufted solid wood and upholstered standard bed"
            },
            {
                "product_id": 10382,
                "product_name": "houchin 23 '' tufted round standard ottoman"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 40626,
                "product_name": "rosemead velvet chaise lounge"
            },
            {
                "product_id": 37192,
                "product_name": "wood platform bed with two drawers , full ( white )"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 25557,
                "product_name": "connolly tufted upholstered low profile standard bed"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            }
        ],
        "garOutput": [
            {
                "product_id": 28512,
                "product_name": "arjunveer sleeper bed tufted ottoman"
            },
            {
                "product_id": 6515,
                "product_name": "beyla tufted armless chaise lounge"
            },
            {
                "product_id": 38719,
                "product_name": "briarhill patio reclining chaise lounge with cushion"
            },
            {
                "product_id": 5014,
                "product_name": "almarosa 43.75 '' tufted rectangle standard ottoman"
            },
            {
                "product_id": 37776,
                "product_name": "annancy 42.9 '' steel bed frame"
            }
        ],
        "query": "ottoman bed queen"
    },
    {
        "id": 460,
        "label": [
            {
                "product_id": 322,
                "product_name": "leda 3d wardrobe armoire"
            },
            {
                "product_id": 8883,
                "product_name": "amarillo armoire"
            },
            {
                "product_id": 18412,
                "product_name": "leonid wardrobe armoire"
            },
            {
                "product_id": 26183,
                "product_name": "casandria armoire"
            },
            {
                "product_id": 6273,
                "product_name": "welty tv armoire"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 17561,
                "product_name": "36 '' w wardrobe"
            },
            {
                "product_id": 31258,
                "product_name": "scholl storage cabinet"
            },
            {
                "product_id": 25865,
                "product_name": "note 2 door armoire"
            },
            {
                "product_id": 306,
                "product_name": "lamberton cargo armoire"
            },
            {
                "product_id": 6231,
                "product_name": "auroara armoire"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 21200,
                "product_name": "cutts bar with wine storage"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 14106,
                "product_name": "veronica 5 drawer chest"
            }
        ],
        "garOutput": [
            {
                "product_id": 40407,
                "product_name": "neppie square cotton pillow cover"
            },
            {
                "product_id": 1787,
                "product_name": "justine dining table"
            },
            {
                "product_id": 14531,
                "product_name": "defalco upholstered platform bed"
            },
            {
                "product_id": 6939,
                "product_name": "arwin grey oak 6-drawer dresser"
            },
            {
                "product_id": 41216,
                "product_name": "peaslee 3 bunching coffee table"
            }
        ],
        "query": "small wardrobe grey"
    },
    {
        "id": 52,
        "label": [
            {
                "product_id": 7997,
                "product_name": "classical firepit coffee table"
            },
            {
                "product_id": 32614,
                "product_name": "yogyakarta coffee table"
            },
            {
                "product_id": 11796,
                "product_name": "marta coffee table"
            },
            {
                "product_id": 40429,
                "product_name": "schroeders 3 legs coffee table with storage"
            },
            {
                "product_id": 17114,
                "product_name": "ranchester coffee table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 19878,
                "product_name": "deyshaun trestle coffee table"
            },
            {
                "product_id": 11790,
                "product_name": "bohemian coffee table"
            },
            {
                "product_id": 12250,
                "product_name": "alaina 3 piece coffee table set"
            },
            {
                "product_id": 16825,
                "product_name": "chattahoochee two-tier coffee table"
            },
            {
                "product_id": 40981,
                "product_name": "audley solid wood 2 piece coffee table set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            },
            {
                "product_id": 13956,
                "product_name": "deauville coffee table"
            },
            {
                "product_id": 13955,
                "product_name": "erachidia coffee table"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            }
        ],
        "garOutput": [
            {
                "product_id": 29341,
                "product_name": "magdaleno pub dining table"
            },
            {
                "product_id": 3072,
                "product_name": "flossmoor 24.75 '' bar stool"
            },
            {
                "product_id": 17029,
                "product_name": "port morris counter height dining table"
            },
            {
                "product_id": 34480,
                "product_name": "bechtold solid wood cross legs end table"
            },
            {
                "product_id": 5351,
                "product_name": "ramiro wooden end table"
            }
        ],
        "query": "westling coffee table"
    },
    {
        "id": 351,
        "label": [
            {
                "product_id": 37731,
                "product_name": "annelore 24 '' single bathroom vanity set"
            },
            {
                "product_id": 21547,
                "product_name": "grayling 24 '' single bathroom vanity set"
            },
            {
                "product_id": 8939,
                "product_name": "cabery 30 '' single bathroom vanity set"
            },
            {
                "product_id": 35501,
                "product_name": "alayah 24 \u201d single bathroom vanity set"
            },
            {
                "product_id": 10760,
                "product_name": "deina 42 '' single bathroom vanity set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 15027,
                "product_name": "goodlow 30 '' single bathroom vanity set"
            },
            {
                "product_id": 29534,
                "product_name": "havel 30 '' single bathroom vanity"
            },
            {
                "product_id": 2928,
                "product_name": "24 '' single bathroom vanity set"
            },
            {
                "product_id": 39585,
                "product_name": "deatherage 24 '' single bathroom vanity set"
            },
            {
                "product_id": 15004,
                "product_name": "goodin 30 '' single bathroom vanity set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 3197,
                "product_name": "double shower curtain hooks"
            },
            {
                "product_id": 25869,
                "product_name": "42 '' single bathroom vanity"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 37727,
                "product_name": "villante 24 '' single bathroom vanity set"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 41498,
                "product_name": "vintage metal bathroom wall sign with distressed finish wall d\u00e9cor"
            },
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 39644,
                "product_name": "evas hall tree"
            },
            {
                "product_id": 2182,
                "product_name": "3-tier printer cart with storage shelf mobile printer stand end table home office"
            },
            {
                "product_id": 10541,
                "product_name": "mccullum funny cute goat mountain climbing or rock climbing cartoon coffee mug"
            }
        ],
        "query": "white bathroom vanity black hardware"
    },
    {
        "id": 268,
        "label": [
            {
                "product_id": 28435,
                "product_name": "chambly resin free standing umbrella base"
            },
            {
                "product_id": 2794,
                "product_name": "nigol resin free standing umbrella base"
            },
            {
                "product_id": 2792,
                "product_name": "kentworth resin free standing umbrella base"
            },
            {
                "product_id": 32921,
                "product_name": "alberti resin free standing umbrella base"
            },
            {
                "product_id": 31227,
                "product_name": "hamptonburgh resin free standing umbrella base"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 38722,
                "product_name": "aarni resin free standing umbrella base"
            },
            {
                "product_id": 24417,
                "product_name": "half round concrete free standing umbrella base"
            },
            {
                "product_id": 2201,
                "product_name": "manitowoc market outdoor resin free standing umbrella base"
            },
            {
                "product_id": 8716,
                "product_name": "konover resin free standing umbrella base"
            },
            {
                "product_id": 40211,
                "product_name": "colageo resin free standing umbrella base"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 11850,
                "product_name": "pi creative art where the passion flower grows i single shower curtain"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            }
        ],
        "garOutput": [
            {
                "product_id": 32719,
                "product_name": "alderete pineapple"
            },
            {
                "product_id": 22406,
                "product_name": "glenaire 31.5 '' h x 13.77 '' w x 9.84 '' d 4 shelf wire shelving unit"
            },
            {
                "product_id": 11512,
                "product_name": "ncaa tufted rug"
            },
            {
                "product_id": 22425,
                "product_name": "sheree cast aluminum 3 piece bistro set"
            },
            {
                "product_id": 41452,
                "product_name": "breeze 8 ft. w x 10 ft. d solid wood pergola"
            }
        ],
        "query": "betty resin free standing umbrella"
    },
    {
        "id": 245,
        "label": [
            {
                "product_id": 13029,
                "product_name": "nautical anchor compass wall d\u00e9cor"
            },
            {
                "product_id": 11090,
                "product_name": "cross anchor wall d\u00e9cor"
            },
            {
                "product_id": 25904,
                "product_name": "decorative twig and wire cone pocket wall d\u00e9cor"
            },
            {
                "product_id": 12449,
                "product_name": "pineapple door decor"
            },
            {
                "product_id": 29827,
                "product_name": "nature series greenhouse anchor kit"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 37237,
                "product_name": "trigg ceramic planter wall decor"
            },
            {
                "product_id": 11955,
                "product_name": "ambesonne anchor 2 panel curtain set , ocean inspired zigzag backdrop with pale color maritime anchor and dots summertime , lightweight window treatment living room bedroom decor , 56 '' x 63 '' , seafoam teal"
            },
            {
                "product_id": 30088,
                "product_name": "ship wheel and anchor bookends"
            },
            {
                "product_id": 27347,
                "product_name": "rustic wooden bar sign wall decor"
            },
            {
                "product_id": 4382,
                "product_name": "coastal double wall d\u00e9cor"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            },
            {
                "product_id": 10099,
                "product_name": "universal showering components multi function handheld shower head"
            },
            {
                "product_id": 10218,
                "product_name": "lift rod and finial"
            },
            {
                "product_id": 10219,
                "product_name": "assy spout"
            }
        ],
        "garOutput": [
            {
                "product_id": 33787,
                "product_name": "pier 4 '' x 12 '' straight edge ceramic singular subway tile"
            },
            {
                "product_id": 33788,
                "product_name": "pier 4 '' x 12 '' ceramic subway tile in warm deco"
            },
            {
                "product_id": 32470,
                "product_name": "durdham park console table"
            },
            {
                "product_id": 4169,
                "product_name": "liverman end table"
            },
            {
                "product_id": 33770,
                "product_name": "catalina 3 '' x 12 '' ceramic subway tile"
            }
        ],
        "query": "anchor decor"
    },
    {
        "id": 13,
        "label": [
            {
                "product_id": 42812,
                "product_name": "4 ft. h x 3 ft. w wilmington privacy screen"
            },
            {
                "product_id": 24047,
                "product_name": "replacement privacy panel for opp 10x10 ft. gazebo with one side privacy panel"
            },
            {
                "product_id": 11268,
                "product_name": "carrasquillo folding screen 4 panel 70.25 '' room divider"
            },
            {
                "product_id": 42814,
                "product_name": "4 ft. h x 3 ft. w huntersville privacy screen"
            },
            {
                "product_id": 34045,
                "product_name": "kelp metal privacy screen"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 12514,
                "product_name": "2 ft. h x 4 ft. w metal privacy screen"
            },
            {
                "product_id": 29352,
                "product_name": "knitted composite privacy screen"
            },
            {
                "product_id": 12505,
                "product_name": "2 ft. h x 2 ft. w polyethylene privacy screen"
            },
            {
                "product_id": 5943,
                "product_name": "5 ft. h x 3 ft. w classic decorative composite privacy screen"
            },
            {
                "product_id": 3993,
                "product_name": "non-adhesive privacy window film"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8740,
                "product_name": "alessandro rectangular multi-tiered plant stand"
            },
            {
                "product_id": 8752,
                "product_name": "3 tier corner plant stand foldable nesting flower pot holder elegant metal plant rack for indoor outdoor garden patio3"
            },
            {
                "product_id": 12350,
                "product_name": "rossmere indoor/outdoor bench cushion"
            },
            {
                "product_id": 8753,
                "product_name": "4 tier plant stand garden planter metal flower pot rack corner shelving indoor outdoor"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 42202,
                "product_name": "motorized retraction side awning in black"
            },
            {
                "product_id": 20461,
                "product_name": "pantheon 24 '' x 24 '' porcelain field tile"
            },
            {
                "product_id": 20460,
                "product_name": "pantheon natural 24 '' x 24 '' porcelain field tile"
            },
            {
                "product_id": 20462,
                "product_name": "pantheon 24 '' x 48 '' porcelain stone look wall & floor tile"
            },
            {
                "product_id": 26844,
                "product_name": "paris 1 '' x 3 '' porcelain brick joint mosaic tile"
            }
        ],
        "query": "outdoor privacy wall"
    },
    {
        "id": 320,
        "label": [
            {
                "product_id": 35123,
                "product_name": "ulysses full bookcase headboard"
            },
            {
                "product_id": 26078,
                "product_name": "westhoff bookcase headboard"
            },
            {
                "product_id": 1898,
                "product_name": "hayman king bookcase headboard"
            },
            {
                "product_id": 6690,
                "product_name": "gilbertson bed with 3 drawers and trundle"
            },
            {
                "product_id": 2416,
                "product_name": "grantville bookcase headboard"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 38520,
                "product_name": "abdi twin bookcase headboard"
            },
            {
                "product_id": 30521,
                "product_name": "prepac fremont full/queen bookcase headboard in espresso"
            },
            {
                "product_id": 36325,
                "product_name": "theis bookcase headboard"
            },
            {
                "product_id": 40541,
                "product_name": "thelonius upholstered panel headboard"
            },
            {
                "product_id": 35202,
                "product_name": "little treasures twin bookcase headboard"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 30158,
                "product_name": "hershberger buffet table"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 30520,
                "product_name": "prepac storage bookcase headboard"
            },
            {
                "product_id": 1612,
                "product_name": "cruzito 549 '' h x 10.5 '' w step bookcase"
            },
            {
                "product_id": 1611,
                "product_name": "cruze 33 '' h x 10.5 '' w step bookcase"
            },
            {
                "product_id": 31258,
                "product_name": "scholl storage cabinet"
            },
            {
                "product_id": 35171,
                "product_name": "queen bookcase headboard"
            }
        ],
        "query": "blaylock bookcase headboard"
    },
    {
        "id": 88,
        "label": [
            {
                "product_id": 41837,
                "product_name": "antoinette chaise lounge"
            },
            {
                "product_id": 18359,
                "product_name": "beverly chaise lounge"
            },
            {
                "product_id": 24959,
                "product_name": "tower place upholstered arm chair in rose-gold"
            },
            {
                "product_id": 11,
                "product_name": "rococo circular chaise lounge"
            },
            {
                "product_id": 41277,
                "product_name": "iancarlo chesterfield chaise lounge"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 6015,
                "product_name": "sorrento chaise lounge"
            },
            {
                "product_id": 14758,
                "product_name": "daniels tufted square arms chaise lounge"
            },
            {
                "product_id": 21692,
                "product_name": "anne chaise lounge"
            },
            {
                "product_id": 19744,
                "product_name": "paul chaise lounge"
            },
            {
                "product_id": 19582,
                "product_name": "malibu crest chaise lounge"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 1179,
                "product_name": "real touch bouquet peonies stems"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 11850,
                "product_name": "pi creative art where the passion flower grows i single shower curtain"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 22032,
                "product_name": "dining table with leaf"
            }
        ],
        "garOutput": [
            {
                "product_id": 8916,
                "product_name": "flavian upholstered parsons chair"
            },
            {
                "product_id": 8915,
                "product_name": "flavian 26.5 '' counter stool"
            },
            {
                "product_id": 28443,
                "product_name": "melker upholstered parsons chair in cream"
            },
            {
                "product_id": 28444,
                "product_name": "mellery upholstered parsons chair in cream"
            },
            {
                "product_id": 41465,
                "product_name": "thayer chaise lounge"
            }
        ],
        "query": "rose gold lounge"
    },
    {
        "id": 393,
        "label": [
            {
                "product_id": 33564,
                "product_name": "5.4 cu . ft. top load washer and 7.4 cu . ft. gas dryer"
            },
            {
                "product_id": 16150,
                "product_name": "4.2 cu . ft. top load agitator washer and 7.2 cu . ft. gas dryer"
            },
            {
                "product_id": 17426,
                "product_name": "char-broil performance series 5-burner propane gas grill with side burner and cabinet"
            },
            {
                "product_id": 16127,
                "product_name": "7.5 cu . ft. gas dryer"
            },
            {
                "product_id": 16166,
                "product_name": "4.6 cu . ft. top load washer and 7.2 cu . ft. gas dryer"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 21866,
                "product_name": "90 '' 4-burner propane/natural gas"
            },
            {
                "product_id": 33554,
                "product_name": "7.5 cu . ft. smart gas dryer with steam sanitize+"
            },
            {
                "product_id": 16146,
                "product_name": "4.5 cu . ft. top load agitator washer and 7.2 cu . ft. gas dryer"
            },
            {
                "product_id": 28520,
                "product_name": "dolcho steel propane gas fire pit"
            },
            {
                "product_id": 33550,
                "product_name": "7.4 cu . ft. gas dryer with sensor dry"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 24966,
                "product_name": "nita single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 32451,
                "product_name": "40w e12 dimmable led candle light bulb"
            },
            {
                "product_id": 21866,
                "product_name": "90 '' 4-burner propane/natural gas"
            },
            {
                "product_id": 12440,
                "product_name": "80 can freestanding beverage refrigerator"
            },
            {
                "product_id": 12253,
                "product_name": "sydney kitchen cart with butcher block top"
            }
        ],
        "garOutput": [
            {
                "product_id": 34620,
                "product_name": "tayama induction cooker with cooking pot"
            },
            {
                "product_id": 7086,
                "product_name": "light bulb"
            },
            {
                "product_id": 26889,
                "product_name": "gas-fired propane convection utility heater"
            },
            {
                "product_id": 20228,
                "product_name": "32 '' l x 20 '' w farmhouse kitchen sink with sink grid and basket strainer"
            },
            {
                "product_id": 20227,
                "product_name": "30 '' l x 20 '' w farmhouse kitchen sink with sink grid and basket strainer"
            }
        ],
        "query": "propane gas dryer"
    },
    {
        "id": 277,
        "label": [
            {
                "product_id": 8239,
                "product_name": "wona 27.5 '' patio bar stool"
            },
            {
                "product_id": 21891,
                "product_name": "badham 26 '' patio bar stool"
            },
            {
                "product_id": 39972,
                "product_name": "3 piece outdoor bistro set all-weather table and chairs metal frame modern patio furniture for small space deck , lawn , porch garden , black"
            },
            {
                "product_id": 22861,
                "product_name": "cublington outdoor 7 piece patio set"
            },
            {
                "product_id": 27600,
                "product_name": "purtell bar stool"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 11394,
                "product_name": "goggins 29.5 '' patio bar stool"
            },
            {
                "product_id": 7157,
                "product_name": "goberd 24 '' bar stools"
            },
            {
                "product_id": 34057,
                "product_name": "jenkinson 29.1 '' patio bar stool"
            },
            {
                "product_id": 7611,
                "product_name": "latitude run\u00ae 8pcs rattan patio conversation set outdoor furniture set w/ turquoise cushions"
            },
            {
                "product_id": 11103,
                "product_name": "3 pieces patio pe rattan conversation chair set , outdoor furniture set with water-proof cushion & coffee table for garden , backyard and porch"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8753,
                "product_name": "4 tier plant stand garden planter metal flower pot rack corner shelving indoor outdoor"
            },
            {
                "product_id": 1029,
                "product_name": "candelario bar with wine storage"
            },
            {
                "product_id": 15446,
                "product_name": "ulibarri 6 piece rattan sectional seating group with cushions"
            },
            {
                "product_id": 18012,
                "product_name": "aditi patio 3 piece seating group with cushions"
            },
            {
                "product_id": 8740,
                "product_name": "alessandro rectangular multi-tiered plant stand"
            }
        ],
        "garOutput": [
            {
                "product_id": 7605,
                "product_name": "ayala 3 piece rattan seating group with cushions"
            },
            {
                "product_id": 24292,
                "product_name": "orland wood bench"
            },
            {
                "product_id": 17382,
                "product_name": "heaweego 88 gallon wicker cabinet deck box in gray"
            },
            {
                "product_id": 24840,
                "product_name": "amende 122 '' wide wicker patio sectional with cushions"
            },
            {
                "product_id": 8718,
                "product_name": "chaise lounge floor sofa"
            }
        ],
        "query": "barstool patio sets"
    },
    {
        "id": 167,
        "label": [
            {
                "product_id": 11177,
                "product_name": "oneridge zoomie kids retro happy camper green , aqua and grey 3 piece mini crib bedding set - comforter and two fitted mini crib sheets 3 piece crib bedding set"
            },
            {
                "product_id": 27587,
                "product_name": "bossett flower shop camper"
            },
            {
                "product_id": 9249,
                "product_name": "camper wall mount"
            },
            {
                "product_id": 27559,
                "product_name": "melange bamboo dinner plate set ( campers paradise ) | shatter-proof and chip-resistant bamboo dinner plates"
            },
            {
                "product_id": 857,
                "product_name": "dubon-claros camper throw pillow"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 23345,
                "product_name": "zeckser campfire coffee mug"
            },
            {
                "product_id": 11180,
                "product_name": "2 piece imaginary travels narwhal and camper christmas holiday shaped ornament set"
            },
            {
                "product_id": 11014,
                "product_name": "greatest adventure camper 2-sided polyester 40 x 28 in . house flag"
            },
            {
                "product_id": 8027,
                "product_name": "happy campers personalized burlap 18 x 12 in . garden flag"
            },
            {
                "product_id": 9245,
                "product_name": "camper woodsy shaped magnet"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            }
        ],
        "garOutput": [
            {
                "product_id": 26551,
                "product_name": "inflatable lounger reclining camping chair"
            },
            {
                "product_id": 8718,
                "product_name": "chaise lounge floor sofa"
            },
            {
                "product_id": 22011,
                "product_name": "darnetha 70.5 '' faux leather pillow top arm convertible sleeper sofa bed"
            },
            {
                "product_id": 2082,
                "product_name": "38.2 '' armless sofa bed"
            },
            {
                "product_id": 8720,
                "product_name": "latitude run\u00ae floor couch and sofa fabric folding chaise lounge ( navy blue )"
            }
        ],
        "query": "camper"
    },
    {
        "id": 333,
        "label": [
            {
                "product_id": 26546,
                "product_name": "mint pantry hardaway 4 piece stainless steel colander set"
            },
            {
                "product_id": 6014,
                "product_name": "brayden studio shultz 3 piece stainless steel food strainer set"
            },
            {
                "product_id": 28379,
                "product_name": "nu steel stainless steel colander"
            },
            {
                "product_id": 17586,
                "product_name": "stainless steel dish rack"
            },
            {
                "product_id": 22699,
                "product_name": "kraus kore stainless steel 3 piece colander set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 8606,
                "product_name": "cuisinart 3 piece stainless steel strainer set"
            },
            {
                "product_id": 22319,
                "product_name": "cook pro pineapple 3 piece stainless steel colander set"
            },
            {
                "product_id": 16387,
                "product_name": "swirl stainless steel colander"
            },
            {
                "product_id": 18405,
                "product_name": "homeries silicone\\t 3 piece colander set"
            },
            {
                "product_id": 22717,
                "product_name": "kraus stainless steel over the sink strainer"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            }
        ],
        "garOutput": [
            {
                "product_id": 16019,
                "product_name": "1.25 qt . kitchen canister set"
            },
            {
                "product_id": 21228,
                "product_name": "charlton home\u00ae iron display riser & stand"
            },
            {
                "product_id": 30897,
                "product_name": "ozen kitchen shelving rack"
            },
            {
                "product_id": 12985,
                "product_name": "trevin 2 piece melamine tray set"
            },
            {
                "product_id": 6357,
                "product_name": "valera 28 oz . pineapple rice bowl"
            }
        ],
        "query": "stainless steel colander set"
    },
    {
        "id": 480,
        "label": [
            {
                "product_id": 40258,
                "product_name": "2 piece adoria hanging picture frame set"
            },
            {
                "product_id": 7116,
                "product_name": "ebern designs paris canvas decor eiffel tower framed pictures housewarming decor gifts ideas stylish home decor french wall decor paris wall art eiffel tower modern artwork ready to hang picture"
            },
            {
                "product_id": 40171,
                "product_name": "giddings family theme wall hanging 8 opening photo sockets picture frame"
            }
        ],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "product_id": 41432,
                "product_name": "happiness wall mounted calendar board"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 32495,
                "product_name": "dederang 2 '' bookshelf"
            },
            {
                "product_id": 29241,
                "product_name": "cleotha twin 75 '' wide tufted back convertible sofa"
            },
            {
                "product_id": 890,
                "product_name": "sapphira reversible quilt set"
            }
        ],
        "garOutput": [
            {
                "product_id": 40820,
                "product_name": "5 piece akiana round modern & contemporary accent mirror set"
            },
            {
                "product_id": 6818,
                "product_name": "5.4 '' w x 48 '' l solid wood wall paneling"
            },
            {
                "product_id": 11308,
                "product_name": "stancliff wall mirror"
            },
            {
                "product_id": 22986,
                "product_name": "turquoise succulent - wrapped canvas photograph print"
            },
            {
                "product_id": 24355,
                "product_name": "regatta at argenteuil by claude monet oil painting print"
            }
        ],
        "query": "pictures to hang over fireplace"
    },
    {
        "id": 134,
        "label": [
            {
                "product_id": 37285,
                "product_name": "gwyneth etagere bookcase"
            },
            {
                "product_id": 1849,
                "product_name": "morrell standard bookcase"
            },
            {
                "product_id": 21778,
                "product_name": "camrynstandard bookcase"
            },
            {
                "product_id": 23643,
                "product_name": "thomaston 56.25 '' h x 20 '' w ladder bookcase"
            },
            {
                "product_id": 34276,
                "product_name": "malarie 4 shelf etagere bookcase"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 17407,
                "product_name": "delphine 35 '' bookcase"
            },
            {
                "product_id": 1948,
                "product_name": "legrand bookcase headboard"
            },
            {
                "product_id": 12387,
                "product_name": "tallent 22.1 '' h x 13.3 '' w etagere bookcase"
            },
            {
                "product_id": 36226,
                "product_name": "augustus 25.62 '' h x 60 '' w etagere bookcase"
            },
            {
                "product_id": 1243,
                "product_name": "bedard etagere bookcase"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 37192,
                "product_name": "wood platform bed with two drawers , full ( white )"
            }
        ],
        "garOutput": [
            {
                "product_id": 13504,
                "product_name": "teak tripod end table"
            },
            {
                "product_id": 6096,
                "product_name": "longley 5 drawer storage chest"
            },
            {
                "product_id": 42713,
                "product_name": "giullia 2 piece floating shelf"
            },
            {
                "product_id": 40127,
                "product_name": "lefever end table"
            },
            {
                "product_id": 16658,
                "product_name": "tildon wall shelf"
            }
        ],
        "query": "black hanging bookshelf"
    },
    {
        "id": 311,
        "label": [
            {
                "product_id": 29612,
                "product_name": "35.5 '' wide velvet armchair"
            },
            {
                "product_id": 42323,
                "product_name": "velvet task chair grey"
            },
            {
                "product_id": 26812,
                "product_name": "shela 26.75 '' velvet cocktail ottoman"
            },
            {
                "product_id": 18672,
                "product_name": "balone velvet solid blackout thermal pinch pleat single curtain panel"
            },
            {
                "product_id": 14146,
                "product_name": "25.62 '' wide velvet armchair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 39124,
                "product_name": "modern new soft velvet material ergonomics accent chair living room chair bedroom chair home chair with gold legs and more stabler adjustable legs for indoor home ( yellow )"
            },
            {
                "product_id": 20384,
                "product_name": "westbourne 26.75 '' wide velvet armchair"
            },
            {
                "product_id": 32464,
                "product_name": "dwinnell 36.6 '' wide velvet power lift assist standard recliner"
            },
            {
                "product_id": 10326,
                "product_name": "haakenson velvet arm chair in blue"
            },
            {
                "product_id": 31490,
                "product_name": "worrall 62 '' velvet pillow top arm reclining loveseat"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 1179,
                "product_name": "real touch bouquet peonies stems"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 4552,
                "product_name": "modu-licious # 1"
            }
        ],
        "garOutput": [
            {
                "product_id": 37208,
                "product_name": "minifile storage cabinet"
            },
            {
                "product_id": 4552,
                "product_name": "modu-licious # 1"
            },
            {
                "product_id": 28063,
                "product_name": "aloha welcome 30 in . x 18 in . non-slip outdoor door mat"
            },
            {
                "product_id": 28062,
                "product_name": "aloha hello 30 in . x 18 in . non-slip outdoor door mat"
            },
            {
                "product_id": 28064,
                "product_name": "aloha rainbow 30 in . x 18 in . non-slip outdoor door mat"
            }
        ],
        "query": "odum velvet"
    },
    {
        "id": 467,
        "label": [
            {
                "product_id": 11957,
                "product_name": "ambesonne anchor 2 panel curtain set , romantic nautical composition with blue anchors and little red hearts , lightweight window treatment living room bedroom decor , 56 '' x 63 '' , baby blue dark blue red"
            },
            {
                "product_id": 11944,
                "product_name": "alixton mexican curtains , traditional latin american art design with natural inspirations flowers and birds , window treatments 2 panel set for living room bedroom decor , 56 '' x 63 '' , multicolor"
            },
            {
                "product_id": 11951,
                "product_name": "ambesonne navy blue curtains , antique baroque damask inspired motifs abstract flowers ornamental victorian garden , window treatments 2 panel set for living room bedroom decor , 56 '' x 95 '' , blue"
            },
            {
                "product_id": 27489,
                "product_name": "magellan gathered floral room darkening single curtain panel"
            },
            {
                "product_id": 14928,
                "product_name": "caulkins damask room darkening rod pocket single curtain panel"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 11596,
                "product_name": "lavinia paradise 100 % cotton floral room darkening rod pocket single curtain panel"
            },
            {
                "product_id": 30276,
                "product_name": "jacobean 100 % cotton floral room darkening thermal grommet curtain panels"
            },
            {
                "product_id": 11960,
                "product_name": "ambesonne surfboard 2 panel curtain set , aloha hawaii live free ocean water sports inspired pattern coastal inspirations , lightweight window treatment living room bedroom decor , 56 '' x 63 '' , multicolor"
            },
            {
                "product_id": 31023,
                "product_name": "kaster lined tailored floral room darkening rod pocket single curtain panel"
            },
            {
                "product_id": 41258,
                "product_name": "100 % cotton floral room darkening thermal rod pocket single curtain panel"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 13957,
                "product_name": "galeton tv stand for tvs up to 88 ''"
            },
            {
                "product_id": 1179,
                "product_name": "real touch bouquet peonies stems"
            },
            {
                "product_id": 25830,
                "product_name": "must love dogs 29 in . x 18 in . non-slip outdoor door mat"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            }
        ],
        "garOutput": [
            {
                "product_id": 32383,
                "product_name": "pull-out side mount belt rack"
            },
            {
                "product_id": 28043,
                "product_name": "sunflowers floral centerpieces in pot"
            },
            {
                "product_id": 7457,
                "product_name": "eiffel tower canvas art"
            },
            {
                "product_id": 12869,
                "product_name": "rama 7 '' x 16 '' porcelain wood look wall & floor tile"
            },
            {
                "product_id": 32380,
                "product_name": "deep pull-out 18 '' w x 7 '' h drawer"
            }
        ],
        "query": "town & country living curtains"
    },
    {
        "id": 324,
        "label": [
            {
                "product_id": 15913,
                "product_name": "bamboo laundry hamper"
            },
            {
                "product_id": 41971,
                "product_name": "eli 3 shelf utility cart"
            },
            {
                "product_id": 9384,
                "product_name": "wide load smart utility cart"
            },
            {
                "product_id": 32391,
                "product_name": "pullout laundry hamper"
            },
            {
                "product_id": 41997,
                "product_name": "slim 3-tier laundry cart"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 22461,
                "product_name": "wood and metal kitchen utility cart"
            },
            {
                "product_id": 12318,
                "product_name": "3 tier slim rolling multi purpose utility laundry cart"
            },
            {
                "product_id": 4967,
                "product_name": "3 bag metal frame laundry utility cart"
            },
            {
                "product_id": 19224,
                "product_name": "chase utility laundry cart"
            },
            {
                "product_id": 41998,
                "product_name": "supreme 5-tier utility cart"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 28908,
                "product_name": "dezavion manufactured wood kitchen island"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            }
        ],
        "garOutput": [
            {
                "product_id": 33664,
                "product_name": "iaconetti storage cabinet"
            },
            {
                "product_id": 33665,
                "product_name": "delasandro metal storage cabinet"
            },
            {
                "product_id": 11350,
                "product_name": "collapsible laundry dirty clothes hamper"
            },
            {
                "product_id": 41998,
                "product_name": "supreme 5-tier utility cart"
            },
            {
                "product_id": 9973,
                "product_name": "fruit vegetable wire basket set"
            }
        ],
        "query": "chase utility laundry cart"
    },
    {
        "id": 247,
        "label": [
            {
                "product_id": 34956,
                "product_name": "nepal global print geometric blackout thermal grommet single curtain panel"
            },
            {
                "product_id": 34327,
                "product_name": "natisha solid sheer rod pocket single curtain"
            },
            {
                "product_id": 40646,
                "product_name": "tamela polyester striped single shower curtain"
            },
            {
                "product_id": 4538,
                "product_name": "spangles pvc circles beaded curtain"
            },
            {
                "product_id": 3178,
                "product_name": "sharece star fish beach bamboo beaded single curtain panel"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 12016,
                "product_name": "geometric mixed mosaic vivid nature inspired kids girls hippie contrast design room darkening rod pocket curtain panels set of 2 room darkening rod pocket curtain panels"
            },
            {
                "product_id": 16765,
                "product_name": "cremont solid sheer grommet curtain panels"
            },
            {
                "product_id": 34968,
                "product_name": "allegory abstract blackout thermal grommet single curtain panel"
            },
            {
                "product_id": 34562,
                "product_name": "escarpita mildew resist plastic solid shower curtain liner"
            },
            {
                "product_id": 39214,
                "product_name": "wayfair basics chevron blackout thermal grommet single curtain panel"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 890,
                "product_name": "sapphira reversible quilt set"
            },
            {
                "product_id": 31905,
                "product_name": "toulouse printed single shower curtain"
            },
            {
                "product_id": 38627,
                "product_name": "jurgen stool"
            },
            {
                "product_id": 2552,
                "product_name": "beauvale damask blackout rod pocket single curtain panel"
            },
            {
                "product_id": 1655,
                "product_name": "affric lined and interlined floral room darkening grommet single curtain panel"
            }
        ],
        "garOutput": [
            {
                "product_id": 37857,
                "product_name": "liebert solid semi-sheer tab top single curtain panel"
            },
            {
                "product_id": 34929,
                "product_name": "clayhatchee distressed accent mirror"
            },
            {
                "product_id": 25660,
                "product_name": "rotteck geometric room darkening grommet curtain panels"
            },
            {
                "product_id": 26091,
                "product_name": "breanna solid semi-sheer outdoor grommet curtain panels"
            },
            {
                "product_id": 15837,
                "product_name": "abraham industrial accent mirror"
            }
        ],
        "query": "beaded curtains"
    },
    {
        "id": 54,
        "label": [
            {
                "product_id": 30638,
                "product_name": "wayfair sleep firm down blend support pillow"
            },
            {
                "product_id": 24340,
                "product_name": "wayfair sleep polyester plush bed pillow"
            },
            {
                "product_id": 39272,
                "product_name": "wayfair sleep 9 '' plush hybrid mattress"
            },
            {
                "product_id": 39257,
                "product_name": "wayfair sleep 12 '' plush innerspring mattress"
            },
            {
                "product_id": 24855,
                "product_name": "wayfair sleep 3 '' down alternative mattress topper"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 39276,
                "product_name": "wayfair sleep 10.5 '' medium hybrid mattress"
            },
            {
                "product_id": 2002,
                "product_name": "wayfair sleep waterproof down alternative mattress pad"
            },
            {
                "product_id": 34048,
                "product_name": "resort sleep safe zipcover hypoallergenic waterproof mattress protector"
            },
            {
                "product_id": 5526,
                "product_name": "wayfair sleep 12 '' medium memory foam mattress"
            },
            {
                "product_id": 15603,
                "product_name": "angeland terry cloth hypoallergenic waterproof mattress cover"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 11055,
                "product_name": "harcrest writing desk"
            },
            {
                "product_id": 11168,
                "product_name": "sanya anchor wall hanging"
            },
            {
                "product_id": 41246,
                "product_name": "rae dunn stem print 3.5 qt . flour canister"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 20718,
                "product_name": "abramowski monitor stand"
            }
        ],
        "garOutput": [
            {
                "product_id": 36493,
                "product_name": "naturepedic cotton fitted protector and waterproof mattress pad"
            },
            {
                "product_id": 30517,
                "product_name": "kronqui bed and control hypoallergenic zippered mattress protector"
            },
            {
                "product_id": 26733,
                "product_name": "alwyn home full body pillow insert with removable zippered rayon from bamboo cover , soft long breathable side sleeper fluffy bed pillows for adults , down alternative pillow , 20x54 inch white"
            },
            {
                "product_id": 25117,
                "product_name": "metson polyester mattress pad"
            },
            {
                "product_id": 34434,
                "product_name": "touch zippered hypoallergenic mattress protector"
            }
        ],
        "query": "wayfair sleep zippered"
    },
    {
        "id": 369,
        "label": [
            {
                "product_id": 6449,
                "product_name": "mesa solid wood tv stand for tvs up to 65 ''"
            },
            {
                "product_id": 2088,
                "product_name": "goncalves tv stand for tvs up to 60 ''"
            },
            {
                "product_id": 19259,
                "product_name": "deleo corner tv stand for tvs up to 40 ''"
            },
            {
                "product_id": 36489,
                "product_name": "amarillo tv stand for tvs up to 75 ''"
            },
            {
                "product_id": 40650,
                "product_name": "didier tv stand for tvs up to 88 ''"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 26161,
                "product_name": "whittier tv stand for tvs up to 65 '' with electric fireplace included"
            },
            {
                "product_id": 29061,
                "product_name": "jovani tv stand for tvs up to 75 ''"
            },
            {
                "product_id": 2141,
                "product_name": "connett tv stand"
            },
            {
                "product_id": 26866,
                "product_name": "carnes corner tv stand for tvs up to 55 ''"
            },
            {
                "product_id": 31047,
                "product_name": "gosport solid wood tv stand for tvs up to 80 ''"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 2233,
                "product_name": "tv stand for tvs up to 50 ''"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 19531,
                "product_name": "teak block end table"
            },
            {
                "product_id": 30083,
                "product_name": "home sweet home tops wall d\u00e9cor"
            },
            {
                "product_id": 26714,
                "product_name": "metal and glass wall sconce"
            },
            {
                "product_id": 29665,
                "product_name": "schema trigono stool"
            },
            {
                "product_id": 17341,
                "product_name": "mahurin maryland crab melamine platter"
            }
        ],
        "query": "wrought tv stand"
    },
    {
        "id": 50,
        "label": [
            {
                "product_id": 25878,
                "product_name": "bocana swivel adjustable height bar stool"
            },
            {
                "product_id": 23845,
                "product_name": "amundi tufted upholstered side chair"
            },
            {
                "product_id": 35800,
                "product_name": "simone 26.2 '' wide tufted velvet lounge chair and ottoman"
            },
            {
                "product_id": 38063,
                "product_name": "klein 25 '' wide tufted polyester slipper chair"
            },
            {
                "product_id": 16804,
                "product_name": "micky 31 '' wide tufted polyester armchair and ottoman"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 28479,
                "product_name": "laughlin 30 '' wide tufted papasan chair"
            },
            {
                "product_id": 22562,
                "product_name": "clemence task chair"
            },
            {
                "product_id": 6287,
                "product_name": "patterson 22.1 '' wide tufted swivel armchair"
            },
            {
                "product_id": 40599,
                "product_name": "robey upholstered dining chair"
            },
            {
                "product_id": 8096,
                "product_name": "lengby 34.6 '' wide tufted club chair"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 3837,
                "product_name": "galaz 28 '' wide polyester wingback chair"
            },
            {
                "product_id": 18460,
                "product_name": "adala executive chair"
            },
            {
                "product_id": 18461,
                "product_name": "adalaina executive chair"
            },
            {
                "product_id": 25227,
                "product_name": "office ergonomic polyurethane conference chair"
            },
            {
                "product_id": 40626,
                "product_name": "rosemead velvet chaise lounge"
            }
        ],
        "garOutput": [
            {
                "product_id": 6945,
                "product_name": "artane 30 '' tufted rectangle standard with storage ottoman"
            },
            {
                "product_id": 23939,
                "product_name": "anund upholstered arm chair in black"
            },
            {
                "product_id": 22837,
                "product_name": "andren 27 '' wide polyester wingback chair"
            },
            {
                "product_id": 42252,
                "product_name": "erdemir end table"
            },
            {
                "product_id": 23195,
                "product_name": "arkadelphia 28 '' wide armchair"
            }
        ],
        "query": "tufted chair with gold legs"
    },
    {
        "id": 175,
        "label": [
            {
                "product_id": 23851,
                "product_name": "fina rectangular wooden planter bench"
            },
            {
                "product_id": 40026,
                "product_name": "boligee 2 - piece iron pot planter set"
            },
            {
                "product_id": 37458,
                "product_name": "phalaenopsis orchid flower arrangements in planter"
            },
            {
                "product_id": 34061,
                "product_name": "reider elaina self-watering plastic pot planter"
            },
            {
                "product_id": 6552,
                "product_name": "caila square basket wicker pot planter"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 32398,
                "product_name": "artimacormick concrete pot planter"
            },
            {
                "product_id": 11312,
                "product_name": "thruxton 2 - piece 100 % iron statue planter set"
            },
            {
                "product_id": 24490,
                "product_name": "ivan rattan cachepot"
            },
            {
                "product_id": 41318,
                "product_name": "trym 3 - piece water hyacinth , steel , and plastic pot planter set"
            },
            {
                "product_id": 28404,
                "product_name": "hallum resin statue planter"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 7839,
                "product_name": "citadel sunflower fields cheese board"
            },
            {
                "product_id": 13247,
                "product_name": "square multi-tiered plant stand"
            },
            {
                "product_id": 3824,
                "product_name": "woodridge 6 ft. w x 5 ft. d storage shed"
            },
            {
                "product_id": 1585,
                "product_name": "lulette decorative tin toy duckling"
            },
            {
                "product_id": 37201,
                "product_name": "3 piece metal/wire basket set"
            }
        ],
        "garOutput": [
            {
                "product_id": 9973,
                "product_name": "fruit vegetable wire basket set"
            },
            {
                "product_id": 20549,
                "product_name": "cresswell armless stackable chair"
            },
            {
                "product_id": 37238,
                "product_name": "trigg wall d\u00e9cor"
            },
            {
                "product_id": 37237,
                "product_name": "trigg ceramic planter wall decor"
            },
            {
                "product_id": 15162,
                "product_name": "river pebbles"
            }
        ],
        "query": "basket planter"
    },
    {
        "id": 191,
        "label": [
            {
                "product_id": 19143,
                "product_name": "donnadio plant stand"
            },
            {
                "product_id": 16079,
                "product_name": "kaci rectangular multi-tiered plant stand"
            },
            {
                "product_id": 27428,
                "product_name": "three door flower plant stand"
            },
            {
                "product_id": 18280,
                "product_name": "natacha plant stand"
            },
            {
                "product_id": 8746,
                "product_name": "wardlow rectangular multi-tiered plant stand"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 7405,
                "product_name": "fenimore rectangular multi-tiered plant stand"
            },
            {
                "product_id": 22511,
                "product_name": "lofgren round multi-tiered plant stand"
            },
            {
                "product_id": 4904,
                "product_name": "rutz square multi-tiered plant stand"
            },
            {
                "product_id": 41364,
                "product_name": "ferland square multi-tiered plant stand"
            },
            {
                "product_id": 19208,
                "product_name": "sherbrook plant stand"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 11467,
                "product_name": "barabra black plant stand"
            },
            {
                "product_id": 41787,
                "product_name": "luffa on twig stem berry bushes"
            },
            {
                "product_id": 4689,
                "product_name": "artificial double phalaenopsis orchid floral arrangement"
            },
            {
                "product_id": 13247,
                "product_name": "square multi-tiered plant stand"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            }
        ],
        "garOutput": [
            {
                "product_id": 22626,
                "product_name": "66 '' h x 27 '' w x 15 '' d tall utility storage cabinet"
            },
            {
                "product_id": 9981,
                "product_name": "arlmont & co. 4 bike stand rack with storage \u2013 metal floor bicycle \u2013 great for parking road , mountain , hybrid or kids bikes \u2013 garage organizer - helmet - sports storage station - black"
            },
            {
                "product_id": 19115,
                "product_name": "perrault portable rolling rack"
            },
            {
                "product_id": 36011,
                "product_name": "beer gets 41 mpg fact funny us made aluminum novelty bar pub wall d\u00e9cor"
            },
            {
                "product_id": 20236,
                "product_name": "sliding single bypass double door barn door hardware kit"
            }
        ],
        "query": "biycicle plant stands"
    },
    {
        "id": 484,
        "label": [
            {
                "product_id": 37991,
                "product_name": "breville nespresso vertuoplus deluxe coffee & espresso single-serve machine"
            },
            {
                "product_id": 37995,
                "product_name": "breville caf\u00e9 roma coffee & espresso maker"
            },
            {
                "product_id": 37988,
                "product_name": "nespresso creatista espresso machine by breville"
            },
            {
                "product_id": 37990,
                "product_name": "nespresso vertuoplus bundle pod espresso machine by breville"
            },
            {
                "product_id": 4821,
                "product_name": "black + decker single serve coffee maker"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 25488,
                "product_name": "moccamaster 10-cup kbgt coffee maker"
            },
            {
                "product_id": 34077,
                "product_name": "capresso coffee burr grinder"
            },
            {
                "product_id": 25487,
                "product_name": "moccamaster 10-cup coffee maker"
            },
            {
                "product_id": 37996,
                "product_name": "breville nespresso essenza mini single-serve machine"
            },
            {
                "product_id": 27753,
                "product_name": "nespresso coffee & espresso maker"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 347,
                "product_name": "1.93 '' solar powered outdoor floating light"
            },
            {
                "product_id": 32654,
                "product_name": "bumble bee napkin ring"
            },
            {
                "product_id": 13519,
                "product_name": "cancer zodiac throw pillow"
            },
            {
                "product_id": 13098,
                "product_name": "premium heavyweight 9 '' melamine salad or dessert plate"
            },
            {
                "product_id": 20324,
                "product_name": "marquez bed riser"
            }
        ],
        "garOutput": [
            {
                "product_id": 21466,
                "product_name": "nespresso espresso maker and aeroccino milk frother - kes0504"
            },
            {
                "product_id": 13242,
                "product_name": "euro cuisine handheld milk frother"
            },
            {
                "product_id": 11378,
                "product_name": "cuisinart coffee plus\u2122 12 cup programmable coffeemaker plus hot water system"
            },
            {
                "product_id": 37986,
                "product_name": "breville vertuo coffee and espresso maker"
            },
            {
                "product_id": 37985,
                "product_name": "nespresso breville vertuo coffee and espresso maker"
            }
        ],
        "query": "nespresso vertuo next premium by breville with aeroccino"
    },
    {
        "id": 189,
        "label": [
            {
                "product_id": 9971,
                "product_name": "double laundry hamper"
            },
            {
                "product_id": 32391,
                "product_name": "pullout laundry hamper"
            },
            {
                "product_id": 41520,
                "product_name": "seagrass basket"
            },
            {
                "product_id": 17273,
                "product_name": "3 tier removable laundry basket rolling laundry cart"
            },
            {
                "product_id": 32140,
                "product_name": "hand woven laundry hamper"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 993,
                "product_name": "prochaska 1.32 gallon waste basket"
            },
            {
                "product_id": 27615,
                "product_name": "slim laundry hamper"
            },
            {
                "product_id": 9976,
                "product_name": "cloth laundry hamper"
            },
            {
                "product_id": 42645,
                "product_name": "laundry hamper with cork lid"
            },
            {
                "product_id": 39166,
                "product_name": "collapsible laundry hamper"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 37201,
                "product_name": "3 piece metal/wire basket set"
            },
            {
                "product_id": 12457,
                "product_name": "linen dual cabinet laundry hamper"
            },
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 21200,
                "product_name": "cutts bar with wine storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 7564,
                "product_name": "decorative storage 43.98 '' h x 43.98 '' w cube bookcase"
            },
            {
                "product_id": 7561,
                "product_name": "decorative storage 43.98 '' h x 16.02 '' w cube bookcase"
            },
            {
                "product_id": 7562,
                "product_name": "decorative storage 30 '' h x 30 '' w cube bookcase"
            },
            {
                "product_id": 7563,
                "product_name": "decorative storage 30 '' h x 43.98 '' w cube bookcase"
            },
            {
                "product_id": 42646,
                "product_name": "foldable laundry basket"
            }
        ],
        "query": "big basket for dirty cloths"
    },
    {
        "id": 170,
        "label": [
            {
                "product_id": 2229,
                "product_name": "chewey resin fengshui zen meditation fountain with light"
            },
            {
                "product_id": 13015,
                "product_name": "desktop zen garden with thai buddha figurine"
            },
            {
                "product_id": 11227,
                "product_name": "resin zen fountain with led light"
            },
            {
                "product_id": 24995,
                "product_name": "zen rain i photographic print"
            },
            {
                "product_id": 13095,
                "product_name": "zen asian soup spoon"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 34943,
                "product_name": "kieninger latitude the zen den 14 '' lumbar pillow cover and insert pillow"
            },
            {
                "product_id": 41004,
                "product_name": "pepin resin cascading sphere zen water fountain"
            },
            {
                "product_id": 13438,
                "product_name": "hanlon resin cascading zen meditation fountain with light"
            },
            {
                "product_id": 4631,
                "product_name": "the zen garden - wrapped canvas graphic art print"
            },
            {
                "product_id": 31928,
                "product_name": "table bonsai tree in pot"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            }
        ],
        "garOutput": [
            {
                "product_id": 19303,
                "product_name": "oversized 3 '' length rectangle knob multipack"
            },
            {
                "product_id": 20746,
                "product_name": "10 ft outdoor patio umbrella solar powered led lighted sun shade market waterproof 8 ribs umbrella with crank and cross base for garden deck backyard pool shade outside deck swimming pool -tan"
            },
            {
                "product_id": 27948,
                "product_name": "dolder grand full platform bed"
            },
            {
                "product_id": 7794,
                "product_name": "midnight garden comfort anti-fatigue mat"
            },
            {
                "product_id": 7793,
                "product_name": "tropical comfort foliage anti-fatigue mat"
            }
        ],
        "query": "zen"
    },
    {
        "id": 58,
        "label": [
            {
                "product_id": 33700,
                "product_name": "chantant 6-light unique / statement geometric chandelier"
            },
            {
                "product_id": 31334,
                "product_name": "littleville 9-light shaded chandelier tiered chandelier"
            },
            {
                "product_id": 26116,
                "product_name": "coyle 4 - light unique geometric chandelier"
            },
            {
                "product_id": 42767,
                "product_name": "keener 5 - light shaded classic chandelier"
            },
            {
                "product_id": 24170,
                "product_name": "roush 8-light candle style classic chandelier"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 15074,
                "product_name": "huldrik 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 23634,
                "product_name": "shaylee 5 - light candle style empire chandelier"
            },
            {
                "product_id": 22084,
                "product_name": "rennes 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 3010,
                "product_name": "alyssandra 12 - light unique wagon wheel chandelier with wrought iron accents"
            },
            {
                "product_id": 2998,
                "product_name": "destin 5 - light unique geometric chandelier"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 17223,
                "product_name": "buffet storage cabinet with single glass doors and unique bell handle"
            },
            {
                "product_id": 1215,
                "product_name": "incept 2 door storage cabinet"
            },
            {
                "product_id": 37727,
                "product_name": "villante 24 '' single bathroom vanity set"
            },
            {
                "product_id": 24389,
                "product_name": "corwin sideboard"
            },
            {
                "product_id": 5962,
                "product_name": "delois bunching coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 22011,
                "product_name": "darnetha 70.5 '' faux leather pillow top arm convertible sleeper sofa bed"
            },
            {
                "product_id": 9449,
                "product_name": "orpha globe single curtain rod"
            },
            {
                "product_id": 30915,
                "product_name": "monticello 8 ft. w x 8 ft. d greenhouse"
            },
            {
                "product_id": 30919,
                "product_name": "monticello 8 ft. w x 24 ft. d greenhouse"
            },
            {
                "product_id": 30917,
                "product_name": "monticello 8 ft. w x 16 ft. d greenhouse"
            }
        ],
        "query": "medium size chandelier"
    },
    {
        "id": 48,
        "label": [
            {
                "product_id": 19126,
                "product_name": "mcclay 16.1 '' rose gold arched table lamp"
            },
            {
                "product_id": 16694,
                "product_name": "mager 19.5 '' table lamp"
            },
            {
                "product_id": 3013,
                "product_name": "22.5 '' table lamp"
            },
            {
                "product_id": 38507,
                "product_name": "wilkie 13.5 '' table lamp"
            },
            {
                "product_id": 36162,
                "product_name": "offerman 27 '' gold table lamp"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 16974,
                "product_name": "amias 28 '' champagne table lamp"
            },
            {
                "product_id": 33185,
                "product_name": "allesandra 26 '' bedside table lamp"
            },
            {
                "product_id": 19196,
                "product_name": "lorin ceramic 17 '' table lamp set"
            },
            {
                "product_id": 4528,
                "product_name": "21 '' cream table lamp"
            },
            {
                "product_id": 8285,
                "product_name": "lemaster 26 '' plug-in integrated led outdoor table lamp"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            },
            {
                "product_id": 980,
                "product_name": "delato counter height pub table"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 3728,
                "product_name": "bless our home metal sign"
            }
        ],
        "garOutput": [
            {
                "product_id": 3004,
                "product_name": "cheatham 17.5 '' table lamp set with usb"
            },
            {
                "product_id": 36958,
                "product_name": "amarillo 65.5 torchiere floor lamp"
            },
            {
                "product_id": 9304,
                "product_name": "ashton 30.25 '' table lamp"
            },
            {
                "product_id": 17651,
                "product_name": "pedersen 1 - drawer nightstand"
            },
            {
                "product_id": 2323,
                "product_name": "haylie 26 '' wide armchair"
            }
        ],
        "query": "arwen table lamp"
    },
    {
        "id": 349,
        "label": [
            {
                "product_id": 36005,
                "product_name": "room rules hours of operation metal sign funny kids bedroom man cave decor"
            },
            {
                "product_id": 39773,
                "product_name": "wall decor"
            },
            {
                "product_id": 27887,
                "product_name": "new arrivals sign"
            },
            {
                "product_id": 19938,
                "product_name": "lucas street sign childrens name room sign indoor outdoor 18 inch wide"
            },
            {
                "product_id": 3155,
                "product_name": "lon tree welcome sign wall d\u00e9cor"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 25276,
                "product_name": "bathroom street sign"
            },
            {
                "product_id": 33737,
                "product_name": "camper yard garden sign"
            },
            {
                "product_id": 19352,
                "product_name": "swineford billiard room personalized wood home sign wall decor"
            },
            {
                "product_id": 40088,
                "product_name": "whiskey room home bar sign wall d\u00e9cor"
            },
            {
                "product_id": 19943,
                "product_name": "gabriel street sign childrens name room sign indoor outdoor 18 inch wide"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 3728,
                "product_name": "bless our home metal sign"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 21994,
                "product_name": "home decorative heart shaped metal wall sign inscribed `` home sweet home ''"
            },
            {
                "product_id": 42966,
                "product_name": "mershon 2-light outdoor bulkhead light"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            }
        ],
        "garOutput": [
            {
                "product_id": 4445,
                "product_name": "3 piece kitchen canister set"
            },
            {
                "product_id": 18899,
                "product_name": "custom life anchor wall d\u00e9cor"
            },
            {
                "product_id": 36921,
                "product_name": "4 ft. h x 2 ft. w screen series web metal privacy screen"
            },
            {
                "product_id": 36919,
                "product_name": "4 ft. h x 2 ft. w screen series star metal privacy screen"
            },
            {
                "product_id": 33027,
                "product_name": "hiers 1 - light dimmable black armed sconce"
            }
        ],
        "query": "mud room sign"
    },
    {
        "id": 239,
        "label": [
            {
                "product_id": 39483,
                "product_name": "hamlin single hole bathroom faucet"
            },
            {
                "product_id": 6605,
                "product_name": "trinsic voiceiq pull down touch single handle kitchen faucet with touch2o , electronic"
            },
            {
                "product_id": 21829,
                "product_name": "circular widespread bathroom faucet with drain assembly"
            },
            {
                "product_id": 10141,
                "product_name": "ara widespread bathroom faucet with drain assembly and diamond\u2122 seal technology"
            },
            {
                "product_id": 19724,
                "product_name": "dfi waterfall single handle one hole chrome commercial faucets bathroom vessel sink faucet deck mount"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 20772,
                "product_name": "hadley single hole faucet bathroom faucet"
            },
            {
                "product_id": 5232,
                "product_name": "tyrese single hole bathroom faucet"
            },
            {
                "product_id": 22711,
                "product_name": "oletto pull down single handle kitchen faucet"
            },
            {
                "product_id": 19710,
                "product_name": "waterfall commercial vessel sink bathroom faucet"
            },
            {
                "product_id": 37908,
                "product_name": "satro single hole bathroom faucet"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 19717,
                "product_name": "commercial single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 3197,
                "product_name": "double shower curtain hooks"
            },
            {
                "product_id": 19708,
                "product_name": "waterfall single hole bathroom faucet"
            },
            {
                "product_id": 24966,
                "product_name": "nita single hole bathroom faucet with drain assembly"
            },
            {
                "product_id": 25869,
                "product_name": "42 '' single bathroom vanity"
            }
        ],
        "garOutput": [
            {
                "product_id": 2498,
                "product_name": "70 '' h pivot frameless tub door"
            },
            {
                "product_id": 20231,
                "product_name": "30 '' w x 20 '' l farmhouse kitchen sink with sink grid and basket strainer"
            },
            {
                "product_id": 20230,
                "product_name": "33 '' l x 21 '' w farmhouse kitchen sink with sink grid and basket strainer"
            },
            {
                "product_id": 20229,
                "product_name": "30 '' l x 21 '' w farmhouse kitchen sink with sink grid and basket strainer"
            },
            {
                "product_id": 6430,
                "product_name": "widespread faucet bathroom faucet"
            }
        ],
        "query": "bathroom single faucet"
    },
    {
        "id": 256,
        "label": [
            {
                "product_id": 1263,
                "product_name": "lesmurdie metal standard bunk bed by harriet bee"
            },
            {
                "product_id": 6638,
                "product_name": "twin solid wood loft bed by children"
            },
            {
                "product_id": 10075,
                "product_name": "hajrah full over full bunk bed with trundle"
            },
            {
                "product_id": 19183,
                "product_name": "eruh bunk bed"
            },
            {
                "product_id": 8822,
                "product_name": "adamma bunk bed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 2806,
                "product_name": "bunk bed"
            },
            {
                "product_id": 35218,
                "product_name": "tiara twin low loft bed"
            },
            {
                "product_id": 27322,
                "product_name": "amelia twin loft bed with drawers and bookcase"
            },
            {
                "product_id": 18806,
                "product_name": "kalista bed"
            },
            {
                "product_id": 10854,
                "product_name": "ahmad twin over twin bunk bed"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 14495,
                "product_name": "twin over full bunk bed"
            },
            {
                "product_id": 32482,
                "product_name": "krause 16 '' height profile platform bed"
            },
            {
                "product_id": 24198,
                "product_name": "standard bunk bed by mason & marbles"
            },
            {
                "product_id": 18887,
                "product_name": "schwanke twin over full bunk bed by harriet bee"
            },
            {
                "product_id": 10078,
                "product_name": "finbar twin over twin bunk bed"
            }
        ],
        "garOutput": [
            {
                "product_id": 31823,
                "product_name": "peachey bed riser"
            },
            {
                "product_id": 22011,
                "product_name": "darnetha 70.5 '' faux leather pillow top arm convertible sleeper sofa bed"
            },
            {
                "product_id": 35640,
                "product_name": "audra folding wood bed slats"
            },
            {
                "product_id": 4007,
                "product_name": "metal/wire basket"
            },
            {
                "product_id": 22354,
                "product_name": "renan bunk bed"
            }
        ],
        "query": "high weight capacity bunk beds"
    },
    {
        "id": 21,
        "label": [
            {
                "product_id": 6655,
                "product_name": "mid-century pattern x floral semi-sheer thermal rod pocket curtain panels"
            },
            {
                "product_id": 11937,
                "product_name": "ambesonne grunge 2 panel curtain set , geometric pattern antique design elements mosaic style art , lightweight window treatment living room bedroom decor , 56 '' x 63 '' , teal vermilion plum geometric room darkening rod pocket curtain panels"
            },
            {
                "product_id": 38455,
                "product_name": "solid sheer rod pocket curtain panels"
            },
            {
                "product_id": 11978,
                "product_name": "ambesonne yellow curtains , pattern with bees and chamomile daisy flowers in flourishing meadow nature , window treatments 2 panel set for living room bedroom decor , 56 '' x 63 '' , grey yellow white"
            },
            {
                "product_id": 11992,
                "product_name": "ambesonne fox curtains , forest fauna and flora with foxes leaf and mushrooms nature pattern , window treatments 2 panel set for living room bedroom decor , 56 '' x 95 '' , brown orange apple green"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 34333,
                "product_name": "oslo home theater grade solid blackout thermal grommet single curtain panel"
            },
            {
                "product_id": 12018,
                "product_name": "romantic geometric room darkening rod pocket curtain panels"
            },
            {
                "product_id": 11995,
                "product_name": "ambesonne starfish 2 panel curtain set , graphic illustration of summer season inspired aquatic animals tropical climate , lightweight window treatment living room bedroom decor , 56 '' x 63 '' , orange white"
            },
            {
                "product_id": 34332,
                "product_name": "oslo home theater grade 100 % solid blackout thermal rod pocket single curtain panel"
            },
            {
                "product_id": 11942,
                "product_name": "ambesonne grey and white floral room darkening rod pocket curtain panels"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 890,
                "product_name": "sapphira reversible quilt set"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 28774,
                "product_name": "muslin baby sleeping bag"
            },
            {
                "product_id": 21197,
                "product_name": "allivia floral room darkening grommet curtain panels"
            },
            {
                "product_id": 41432,
                "product_name": "happiness wall mounted calendar board"
            }
        ],
        "garOutput": [
            {
                "product_id": 33359,
                "product_name": "rotan shower curtain hooks"
            },
            {
                "product_id": 12442,
                "product_name": "happy day sunflowers 2-sided polyester 18 x 13 in . garden flag"
            },
            {
                "product_id": 1629,
                "product_name": "river 1 3/16 '' length square knob"
            },
            {
                "product_id": 35047,
                "product_name": "weldon plastic hanging planter"
            },
            {
                "product_id": 25861,
                "product_name": "wardlaw mushroom house fairy garden"
            }
        ],
        "query": "living curtains pearl"
    },
    {
        "id": 318,
        "label": [
            {
                "product_id": 1359,
                "product_name": "gillian 3 - bulb 22.75 '' h outdoor wall lantern"
            },
            {
                "product_id": 9319,
                "product_name": "gaen 3 - light lantern geometric chandelier"
            },
            {
                "product_id": 15891,
                "product_name": "kelly 4 - light lantern geometric chandelier"
            },
            {
                "product_id": 31164,
                "product_name": "meunier 1 - bulb outdoor wall lantern"
            },
            {
                "product_id": 24195,
                "product_name": "lotus floating lantern"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 16935,
                "product_name": "ainsworth earth black 1 - bulb outdoor wall lantern"
            },
            {
                "product_id": 33271,
                "product_name": "turquoise powered led outdoor lantern"
            },
            {
                "product_id": 1531,
                "product_name": "agastya 3 - bulb 30.62 '' h outdoor wall lantern"
            },
            {
                "product_id": 30424,
                "product_name": "earlham aged copper 2 -bulb outdoor hanging lantern"
            },
            {
                "product_id": 24584,
                "product_name": "carmen 8 - light lantern tiered chandelier"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            },
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 347,
                "product_name": "1.93 '' solar powered outdoor floating light"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            }
        ],
        "garOutput": [
            {
                "product_id": 2104,
                "product_name": "icelandia 1-light wall sconce"
            },
            {
                "product_id": 13577,
                "product_name": "turquoise painted poppy indoor/outdoor throw pillow"
            },
            {
                "product_id": 42312,
                "product_name": "ceiling fan canoies"
            },
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 35993,
                "product_name": "16.4 ft led strip light , 528 leds cob bendable tape light , 4000k cool white , ul listed"
            }
        ],
        "query": "sunjoy lantern"
    },
    {
        "id": 161,
        "label": [
            {
                "product_id": 42405,
                "product_name": "reclining massage chair with ottoman"
            },
            {
                "product_id": 1610,
                "product_name": "3 pcs folding portable zero gravity reclining lounge chairs table-beige"
            },
            {
                "product_id": 30301,
                "product_name": "coraline 26.5 '' wide manual wing chair recliner"
            },
            {
                "product_id": 30597,
                "product_name": "single reclining japanese chair lazy sofa"
            },
            {
                "product_id": 9191,
                "product_name": "modern soft velvet material brown ergonomics accent chair living room chair bedroom chair home chair with gold legs and adjustable legs for indoor home"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 17880,
                "product_name": "modern soft velvet material yellow ergonomics accent chair living room chair bedroom chair home chair with gold legs and adjustable legs for indoor home"
            },
            {
                "product_id": 41297,
                "product_name": "riedel recliner patio chair"
            },
            {
                "product_id": 16207,
                "product_name": "modern soft velvet material green ergonomics accent chair living room chair bedroom chair home chair with gold legs and adjustable legs for indoor home"
            },
            {
                "product_id": 1510,
                "product_name": "wellersburg 30.06 '' wide manual club recliner"
            },
            {
                "product_id": 3184,
                "product_name": "swivel rocker fabric recliner chair - reclining chair manual , single modern sofa home theater seating for living room ( silver )"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 26887,
                "product_name": "capems 65.35 '' faux leather pillow top arm sleeper"
            },
            {
                "product_id": 19185,
                "product_name": "erzsebet 33 '' wide power lift assist standard recliner"
            },
            {
                "product_id": 11469,
                "product_name": "tiemann 30 '' wide polyester armchair"
            },
            {
                "product_id": 6028,
                "product_name": "gigi rocking chair with cushions"
            },
            {
                "product_id": 31143,
                "product_name": "amilliah tufted upholstered parsons chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 1620,
                "product_name": "radiance bellissima 3 3/4 '' center to center arch pull"
            },
            {
                "product_id": 9228,
                "product_name": "spa leaf decorative shower curtain hooks"
            },
            {
                "product_id": 9219,
                "product_name": "rounds ball shower curtain hooks"
            },
            {
                "product_id": 14296,
                "product_name": "vivian decorative shower curtain hooks"
            },
            {
                "product_id": 13843,
                "product_name": "traditional 48 '' x 96 '' tin backsplash"
            }
        ],
        "query": "accent chair recliner"
    },
    {
        "id": 280,
        "label": [
            {
                "product_id": 37767,
                "product_name": "abrego multipurpose desk"
            },
            {
                "product_id": 32685,
                "product_name": "rolling height adjustable standing desk"
            },
            {
                "product_id": 35062,
                "product_name": "barbary writing desk with hutch"
            },
            {
                "product_id": 15408,
                "product_name": "desk with hutch"
            },
            {
                "product_id": 21782,
                "product_name": "delmi solid wood desk"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 18716,
                "product_name": "home office solid wood l-shape desk"
            },
            {
                "product_id": 5810,
                "product_name": "carl desk"
            },
            {
                "product_id": 4877,
                "product_name": "bransford desk with hutch"
            },
            {
                "product_id": 23638,
                "product_name": "gladstone writing desk"
            },
            {
                "product_id": 38978,
                "product_name": "writing desk with hutch"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 10565,
                "product_name": "askewville i love it when my wife lets me ride my dirt bike funny partner design coffee mug"
            },
            {
                "product_id": 10566,
                "product_name": "libourne funny owl drink to that owl drinking wine pun cartoon coffee mug"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 14106,
                "product_name": "veronica 5 drawer chest"
            }
        ],
        "garOutput": [
            {
                "product_id": 1524,
                "product_name": "rentz 64 '' console table"
            },
            {
                "product_id": 36102,
                "product_name": "leandra 21 '' h x 60 '' w desk hutch"
            },
            {
                "product_id": 8797,
                "product_name": "rottapel writing desk"
            },
            {
                "product_id": 13145,
                "product_name": "2 drawer end table"
            },
            {
                "product_id": 28420,
                "product_name": "bluxome end table"
            }
        ],
        "query": "johan desk by laurel foundry"
    },
    {
        "id": 449,
        "label": [
            {
                "product_id": 35547,
                "product_name": "console table for entryway with drawers and shelf living room furniture ( antique grey )"
            },
            {
                "product_id": 4307,
                "product_name": "modern new soft velvet material ergonomics accent chair with gold legs and more stabler adjustable legs for living room/ bedroom and indoor home"
            },
            {
                "product_id": 41179,
                "product_name": "octavia configurable living room set"
            },
            {
                "product_id": 35918,
                "product_name": "fernley chaise lounge leisure chair rest sofa chaise lounge couch for indoor living room furniture home - blue"
            },
            {
                "product_id": 40411,
                "product_name": "ebert configurable living room set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 11938,
                "product_name": "ambesonne usa 2 panel curtain set , fourth of july independence day traditional abstract square forms illustration , lightweight window treatment living room bedroom decor , 56 '' x 95 '' , indigo red white room darkening rod pocket curtain panels"
            },
            {
                "product_id": 19967,
                "product_name": "solid color velvet accent chair modern soft upholstered chair with armrest in living room home chair with gold legs and adjustable legs"
            },
            {
                "product_id": 1138,
                "product_name": "kendra 2 piece living room set"
            },
            {
                "product_id": 1076,
                "product_name": "allen configurable living room set"
            },
            {
                "product_id": 13852,
                "product_name": "modern shell-shaped backrest accent chair for living room bedroom metal leg , velvet , grey"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 13957,
                "product_name": "galeton tv stand for tvs up to 88 ''"
            },
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 13956,
                "product_name": "deauville coffee table"
            },
            {
                "product_id": 13955,
                "product_name": "erachidia coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 25084,
                "product_name": "mote floor shelf end table with storage"
            },
            {
                "product_id": 35129,
                "product_name": "evane coffee table"
            },
            {
                "product_id": 8937,
                "product_name": "roberts 26 '' w x 34 '' h x 13 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 22509,
                "product_name": "lohan mid-century wall shelf"
            },
            {
                "product_id": 36110,
                "product_name": "kirsty coffee table with storage"
            }
        ],
        "query": "living room designs"
    },
    {
        "id": 194,
        "label": [
            {
                "product_id": 31522,
                "product_name": "7 photograph picture frame"
            },
            {
                "product_id": 15648,
                "product_name": "amilyah single picture frame"
            },
            {
                "product_id": 37248,
                "product_name": "academy of art university diploma picture frame"
            },
            {
                "product_id": 23629,
                "product_name": "rectangle matte picture frame"
            },
            {
                "product_id": 27567,
                "product_name": "radhika 6 piece opening collage board picture frame set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 31526,
                "product_name": "black matted picture frame"
            },
            {
                "product_id": 7356,
                "product_name": "black picture frame"
            },
            {
                "product_id": 18649,
                "product_name": "ezekiel 34 12 max by mark lawrence - 3 piece picture frame graphic art print set on plastic/acrylic"
            },
            {
                "product_id": 40258,
                "product_name": "2 piece adoria hanging picture frame set"
            },
            {
                "product_id": 27568,
                "product_name": "8 piece opening collage board picture frame set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 36454,
                "product_name": "aneka 17 '' table lamp"
            },
            {
                "product_id": 39991,
                "product_name": "bathroom rain volume shower system rough-in valve"
            },
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 36455,
                "product_name": "aneka 6 '' table lamp"
            },
            {
                "product_id": 19246,
                "product_name": "coughlin 47 '' console table"
            }
        ],
        "garOutput": [
            {
                "product_id": 19922,
                "product_name": "shonna 25.2 '' w closet rod"
            },
            {
                "product_id": 28212,
                "product_name": "please seat yourself sign"
            },
            {
                "product_id": 6742,
                "product_name": "green drums graphic art on plaque"
            },
            {
                "product_id": 32471,
                "product_name": "epoxy wire shelving unit"
            },
            {
                "product_id": 5932,
                "product_name": "dametta macaron wooden bead curtain tieback"
            }
        ],
        "query": "black frame 22 x 36"
    },
    {
        "id": 391,
        "label": [
            {
                "product_id": 18033,
                "product_name": "3 drawer writing desk"
            },
            {
                "product_id": 35513,
                "product_name": "bungert writing desk"
            },
            {
                "product_id": 8798,
                "product_name": "pescadero writing desk"
            },
            {
                "product_id": 14526,
                "product_name": "cavanaugh writing desk"
            },
            {
                "product_id": 10360,
                "product_name": "shanitaortia writing desk"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 39097,
                "product_name": "home office 54 '' large computer desk with 10 storage shelves , office desk study table with bookcase , writing desk workstation with hutch bookshelf ( brown )"
            },
            {
                "product_id": 38001,
                "product_name": "flack 26 '' w writing desk and chair set"
            },
            {
                "product_id": 32213,
                "product_name": "antonina desk"
            },
            {
                "product_id": 26349,
                "product_name": "azariyah writing desk with hutch"
            },
            {
                "product_id": 204,
                "product_name": "brookshire writing desk"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 32138,
                "product_name": "cliffland 82 '' etagere bookcase"
            },
            {
                "product_id": 17786,
                "product_name": "30 '' bar & 24 '' counter stool"
            },
            {
                "product_id": 25828,
                "product_name": "home definition 29 in . x 18 in . non-slip outdoor door mat"
            },
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            },
            {
                "product_id": 14106,
                "product_name": "veronica 5 drawer chest"
            }
        ],
        "garOutput": [
            {
                "product_id": 2183,
                "product_name": "17 stories ladder computer desk , small home office writing desk with bookshelf , industrial ladder shelf , sturdy metal frame , space-saving workstation , easy assembly , rustic brown"
            },
            {
                "product_id": 27495,
                "product_name": "1-up hard cover pet brag album"
            },
            {
                "product_id": 2182,
                "product_name": "3-tier printer cart with storage shelf mobile printer stand end table home office"
            },
            {
                "product_id": 27810,
                "product_name": "sayers damask 8 piece comforter set"
            },
            {
                "product_id": 17776,
                "product_name": "crellin solid wood coffee table"
            }
        ],
        "query": "writing desk 48\""
    },
    {
        "id": 297,
        "label": [
            {
                "product_id": 9648,
                "product_name": "outdoor seat cushion"
            },
            {
                "product_id": 18296,
                "product_name": "poppies fiesta indoor/outdoor seat cushion"
            },
            {
                "product_id": 20037,
                "product_name": "home office decor indoor/outdoor seat cushion"
            },
            {
                "product_id": 28349,
                "product_name": "home office seat cushion"
            },
            {
                "product_id": 27334,
                "product_name": "indoor/outdoor seat cushion"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 23598,
                "product_name": "solid deep indoor/outdoor seat/back cushion"
            },
            {
                "product_id": 9631,
                "product_name": "texture outdoor seat/back cushion"
            },
            {
                "product_id": 23694,
                "product_name": "indoor/outdoor sunbrella chair pad"
            },
            {
                "product_id": 9028,
                "product_name": "indoor/outdoor seat/back cushion"
            },
            {
                "product_id": 27871,
                "product_name": "home office soft flax outdoor seat cushion"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 284,
                "product_name": "adjustable 360 degree swivel velvet desk chair office chair with mid-back modern computer chair with arms for home office study room"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            }
        ],
        "garOutput": [
            {
                "product_id": 19467,
                "product_name": "cammack task chair"
            },
            {
                "product_id": 7167,
                "product_name": "abbottsmoor dining chair cushion"
            },
            {
                "product_id": 8416,
                "product_name": "cushioned floor game chair"
            },
            {
                "product_id": 18025,
                "product_name": "woodhollow velvet task chair"
            },
            {
                "product_id": 13905,
                "product_name": "alsdorf task chair"
            }
        ],
        "query": "seat cushions desk"
    },
    {
        "id": 419,
        "label": [
            {
                "product_id": 4012,
                "product_name": "salvatore solid wood end table"
            },
            {
                "product_id": 33140,
                "product_name": "deven 2 piece living room set"
            },
            {
                "product_id": 1865,
                "product_name": "hemphill 104 '' wide reversible sofa & chaise with ottoman"
            },
            {
                "product_id": 16517,
                "product_name": "braemar chaise lounge leisure chair rest sofa chaise lounge couch for indoor living room furniture home"
            },
            {
                "product_id": 14708,
                "product_name": "bladen 3 piece living room set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 11937,
                "product_name": "ambesonne grunge 2 panel curtain set , geometric pattern antique design elements mosaic style art , lightweight window treatment living room bedroom decor , 56 '' x 63 '' , teal vermilion plum geometric room darkening rod pocket curtain panels"
            },
            {
                "product_id": 39124,
                "product_name": "modern new soft velvet material ergonomics accent chair living room chair bedroom chair home chair with gold legs and more stabler adjustable legs for indoor home ( yellow )"
            },
            {
                "product_id": 11950,
                "product_name": "ambesonne london 2 panel curtain set , fun colorful sketch royal guard map rain famous country landmarks and stonehenge , lightweight window treatment living room bedroom decor , 56 '' x 63 '' , multicolor"
            },
            {
                "product_id": 34864,
                "product_name": "steve configurable living room set"
            },
            {
                "product_id": 6506,
                "product_name": "virginia entertainment center for tvs up to 65 ''"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 29241,
                "product_name": "cleotha twin 75 '' wide tufted back convertible sofa"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 35694,
                "product_name": "counter height dining table"
            },
            {
                "product_id": 5959,
                "product_name": "marple 42 '' wide 2 drawer server"
            }
        ],
        "garOutput": [
            {
                "product_id": 22314,
                "product_name": "aadhira recliner patio chair with cushions"
            },
            {
                "product_id": 13002,
                "product_name": "swanton 2- person 17.71 '' long bistro set with cushions"
            },
            {
                "product_id": 33663,
                "product_name": "elienor outdoor adjustable lounge wicker chair with blue cushion"
            },
            {
                "product_id": 30824,
                "product_name": "akinleye 29.5 '' patio bar stool"
            },
            {
                "product_id": 8301,
                "product_name": "home sweet home block letters wall d\u00e9cor"
            }
        ],
        "query": "living room ideas"
    },
    {
        "id": 348,
        "label": [
            {
                "product_id": 30284,
                "product_name": "aps-fillerends8"
            },
            {
                "product_id": 4288,
                "product_name": "brightech skylite led torchiere floor lamp \u2013 bright , high lumen uplight for reading in living rooms & offices - 3 way dimmable to 30 % brightness - tall standing pole light - black"
            }
        ],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "product_id": 11055,
                "product_name": "harcrest writing desk"
            },
            {
                "product_id": 41246,
                "product_name": "rae dunn stem print 3.5 qt . flour canister"
            },
            {
                "product_id": 14379,
                "product_name": "shoe rack , 5-tier shoe storage unit flat & slant adjustable shoe organizer shelf for 16 pairs , durable and stable , for entryway , hallway , closet , dorm room , industrial , rustic brown"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 20718,
                "product_name": "abramowski monitor stand"
            }
        ],
        "garOutput": [
            {
                "product_id": 16326,
                "product_name": "39 '' rectangular fold-in-half portable folding table"
            },
            {
                "product_id": 6546,
                "product_name": "owston 39.37 '' console table"
            },
            {
                "product_id": 7250,
                "product_name": "oval double wicker laundry hamper"
            },
            {
                "product_id": 15904,
                "product_name": "robinswood angle welcome non-slip outdoor door mat"
            },
            {
                "product_id": 9449,
                "product_name": "orpha globe single curtain rod"
            }
        ],
        "query": "one alium way"
    },
    {
        "id": 261,
        "label": [
            {
                "product_id": 446,
                "product_name": "kelly 1-light flush mount"
            },
            {
                "product_id": 23882,
                "product_name": "flush mount lighting linus 1-light 7 '' semi flush mount"
            },
            {
                "product_id": 40769,
                "product_name": "bagatelle 3-light 10.5 '' semi flush mount"
            },
            {
                "product_id": 28583,
                "product_name": "windall 3 - light 12 '' statement drum semi flush mount"
            },
            {
                "product_id": 15848,
                "product_name": "alaya 3 - light 25.13 '' semi flush mount"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 37535,
                "product_name": "fredette 8-light 26 '' semi flush mount"
            },
            {
                "product_id": 1427,
                "product_name": "marion 2 - light 8 '' unique/statement drum flush mount"
            },
            {
                "product_id": 35358,
                "product_name": "turco 3 - light 15 '' shaded drum semi flush mount"
            },
            {
                "product_id": 25192,
                "product_name": "cahoon 3 - light semi flush mount"
            },
            {
                "product_id": 16874,
                "product_name": "maren 4 - light 18 '' lantern drum semi flush mount"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 38224,
                "product_name": "riggins 24 '' single bathroom vanity set"
            },
            {
                "product_id": 34491,
                "product_name": "swipe 49.9 '' console table"
            },
            {
                "product_id": 34594,
                "product_name": "conerly oval 27.6 '' dining table"
            },
            {
                "product_id": 16878,
                "product_name": "sahlberg coffee table"
            },
            {
                "product_id": 16877,
                "product_name": "mendivil coffee table"
            }
        ],
        "garOutput": [
            {
                "product_id": 27541,
                "product_name": "faux leather reclining chair"
            },
            {
                "product_id": 2492,
                "product_name": "bella tealight wall sconce"
            },
            {
                "product_id": 23636,
                "product_name": "shaylee 8 - light candle style empire chandelier"
            },
            {
                "product_id": 7527,
                "product_name": "merlin 3 - light 15.6 '' unique/statement drum semi flush mount"
            },
            {
                "product_id": 23634,
                "product_name": "shaylee 5 - light candle style empire chandelier"
            }
        ],
        "query": "semi flush foyer light"
    },
    {
        "id": 313,
        "label": [
            {
                "product_id": 22359,
                "product_name": "steinhauer sled coffee table"
            },
            {
                "product_id": 32073,
                "product_name": "sperry sled end table with storage"
            },
            {
                "product_id": 40685,
                "product_name": "theo sled coffee table"
            },
            {
                "product_id": 34120,
                "product_name": "tomo sled end table with storage"
            },
            {
                "product_id": 38828,
                "product_name": "calorafield sled coffee table with storage"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 20932,
                "product_name": "malachi sled end table"
            },
            {
                "product_id": 41355,
                "product_name": "salters sled coffee table"
            },
            {
                "product_id": 39954,
                "product_name": "ralph sled coffee table"
            },
            {
                "product_id": 14971,
                "product_name": "malachi end table"
            },
            {
                "product_id": 37332,
                "product_name": "macrae sled coffee table with storage"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 34987,
                "product_name": "shrestha upholstered parsons chair in gray"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            }
        ],
        "garOutput": [
            {
                "product_id": 36013,
                "product_name": "if you sprinkle when you tinkle bathroom bath sign"
            },
            {
                "product_id": 9854,
                "product_name": "henn acrylic wall fountain with light"
            },
            {
                "product_id": 35029,
                "product_name": "barney 3 piece seating group with cushions"
            },
            {
                "product_id": 33124,
                "product_name": "5 drawer chest"
            },
            {
                "product_id": 33123,
                "product_name": "wide storage 5 drawer dresser"
            }
        ],
        "query": "malachi sled"
    },
    {
        "id": 149,
        "label": [
            {
                "product_id": 23824,
                "product_name": "acevedo 90.5 '' square arm sofa"
            },
            {
                "product_id": 41753,
                "product_name": "reed mid-century modern 2-drawer multicolor wood computer desk"
            },
            {
                "product_id": 39777,
                "product_name": "mcquillen 2 door accent cabinet"
            },
            {
                "product_id": 23782,
                "product_name": "pineview upholstered bench"
            },
            {
                "product_id": 11761,
                "product_name": "mid century modern indoor/outdoor throw pillow"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 37188,
                "product_name": "shapleigh kar mid-century modern outdoor rectangular pillow cover & insert"
            },
            {
                "product_id": 40862,
                "product_name": "mid century modern i by becky thorns - wrapped canvas painting print"
            },
            {
                "product_id": 14595,
                "product_name": "clardy mid century 3 piece living room set"
            },
            {
                "product_id": 4306,
                "product_name": "porter 80 '' velvet rolled arm chesterfield sofa"
            },
            {
                "product_id": 39786,
                "product_name": "larson 2 - drawer nightstand"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 30826,
                "product_name": "belgr concrete propane/natural gas fire pit table"
            },
            {
                "product_id": 19646,
                "product_name": "br\u00fcnhild 32 '' rectangle standard ottoman"
            },
            {
                "product_id": 32277,
                "product_name": "sarko velvet side chair in blue"
            },
            {
                "product_id": 706,
                "product_name": "nordquist folding parsons chair"
            },
            {
                "product_id": 41919,
                "product_name": "kerrville tufted velvet upholstered side chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 10087,
                "product_name": "dryden pressure balanced shower faucet with h2okinetic technology"
            },
            {
                "product_id": 14117,
                "product_name": "catalina solid wood dining table"
            },
            {
                "product_id": 15801,
                "product_name": "alesja king bookcase headboard"
            },
            {
                "product_id": 17554,
                "product_name": "foundry 35 '' wide armchair"
            },
            {
                "product_id": 31371,
                "product_name": "ruskin extendable dining table"
            }
        ],
        "query": "mid century modern"
    },
    {
        "id": 130,
        "label": [
            {
                "product_id": 16963,
                "product_name": "eberardo tv stand for tvs up to 65 ''"
            },
            {
                "product_id": 3314,
                "product_name": "void 44 '' solid wood console table"
            },
            {
                "product_id": 32241,
                "product_name": "gibbs console table"
            },
            {
                "product_id": 21893,
                "product_name": "loudoun 24 '' solid wood console table"
            },
            {
                "product_id": 22652,
                "product_name": "natalia 48 '' console table"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 25742,
                "product_name": "almanzar tv stand for tvs up to 43 ''"
            },
            {
                "product_id": 1168,
                "product_name": "forde entertainment center for tvs up to 70 ''"
            },
            {
                "product_id": 32151,
                "product_name": "lowell 52 '' solid wood console table"
            },
            {
                "product_id": 41396,
                "product_name": "15 '' solid wood console table"
            },
            {
                "product_id": 36576,
                "product_name": "the subservient dragon glass topped sculptural console table"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 347,
                "product_name": "1.93 '' solar powered outdoor floating light"
            },
            {
                "product_id": 13820,
                "product_name": "adley tv stand for tvs up to 60 ''"
            },
            {
                "product_id": 28993,
                "product_name": "hardwired combination carbon monoxide and smoke alarm"
            },
            {
                "product_id": 28071,
                "product_name": "hamlin dual monitor height adjustable universal 2 screen desk mount"
            },
            {
                "product_id": 9766,
                "product_name": "ferrigno 6 piece bathroom accessory set"
            }
        ],
        "garOutput": [
            {
                "product_id": 34710,
                "product_name": "av cart with locking cabinet and power outlets"
            },
            {
                "product_id": 33665,
                "product_name": "delasandro metal storage cabinet"
            },
            {
                "product_id": 33664,
                "product_name": "iaconetti storage cabinet"
            },
            {
                "product_id": 35513,
                "product_name": "bungert writing desk"
            },
            {
                "product_id": 30096,
                "product_name": "gurney slade solid wood tv stand for tvs up to 55 ''"
            }
        ],
        "query": "entertainment console"
    },
    {
        "id": 151,
        "label": [
            {
                "product_id": 5350,
                "product_name": "mcgowan 63 '' faux leather pillow top arm loveseat"
            },
            {
                "product_id": 13848,
                "product_name": "henrietta children study 25.6 '' w writing desk and chair set"
            },
            {
                "product_id": 30101,
                "product_name": "kyng 28 '' wide leather match club chair"
            },
            {
                "product_id": 26796,
                "product_name": "cesar velvet upholstered arm chair"
            },
            {
                "product_id": 9383,
                "product_name": "uur porch rocking chair"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 30377,
                "product_name": "fatumata faux leather power reclining heated massage chair"
            },
            {
                "product_id": 8345,
                "product_name": "carlton wood leg upholstered dining chair"
            },
            {
                "product_id": 38983,
                "product_name": "antwerpen 35.5 '' wide linen wingback chair"
            },
            {
                "product_id": 34232,
                "product_name": "pickett genuine leather upholstered dining chair"
            },
            {
                "product_id": 23854,
                "product_name": "azra dining chair"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 32281,
                "product_name": "metal slat back stacking side chair"
            },
            {
                "product_id": 18460,
                "product_name": "adala executive chair"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 18461,
                "product_name": "adalaina executive chair"
            }
        ],
        "garOutput": [
            {
                "product_id": 8983,
                "product_name": "mervyn end table"
            },
            {
                "product_id": 4007,
                "product_name": "metal/wire basket"
            },
            {
                "product_id": 37718,
                "product_name": "badreddine patio 3 piece seating group with cushions"
            },
            {
                "product_id": 22011,
                "product_name": "darnetha 70.5 '' faux leather pillow top arm convertible sleeper sofa bed"
            },
            {
                "product_id": 8785,
                "product_name": "12 '' w x 31.5 '' h x 12 '' d free-standing bathroom cabinet"
            }
        ],
        "query": "leather chair"
    },
    {
        "id": 365,
        "label": [
            {
                "product_id": 18533,
                "product_name": "hardwired smoke alarm with backup"
            },
            {
                "product_id": 6240,
                "product_name": "pro4 series electronic radon gas detector"
            },
            {
                "product_id": 28994,
                "product_name": "carbon monoxide and explosive gas alarm"
            },
            {
                "product_id": 5437,
                "product_name": "auto leak detector"
            },
            {
                "product_id": 18537,
                "product_name": "photoelectric smoke and fire alarm"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 36911,
                "product_name": "metal detector"
            },
            {
                "product_id": 37123,
                "product_name": "kidde fire smoke alarm , white"
            },
            {
                "product_id": 18536,
                "product_name": "plug-in electrochemical explosive gas and carbon monoxide alarm"
            },
            {
                "product_id": 13898,
                "product_name": "dyconn operated water detector alarm"
            },
            {
                "product_id": 18534,
                "product_name": "3-in-1 explosive gas & carbon monoxide alarm"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 32451,
                "product_name": "40w e12 dimmable led candle light bulb"
            },
            {
                "product_id": 12253,
                "product_name": "sydney kitchen cart with butcher block top"
            },
            {
                "product_id": 34604,
                "product_name": "metal bed"
            },
            {
                "product_id": 8596,
                "product_name": "outdoor 18 '' wall clock"
            },
            {
                "product_id": 24966,
                "product_name": "nita single hole bathroom faucet with drain assembly"
            }
        ],
        "garOutput": [
            {
                "product_id": 18528,
                "product_name": "dual-sensor smoke fire alarm"
            },
            {
                "product_id": 18537,
                "product_name": "photoelectric smoke and fire alarm"
            },
            {
                "product_id": 18538,
                "product_name": "ionization/photoelectric sensor smoke and fire alarm"
            },
            {
                "product_id": 30830,
                "product_name": "carbon monoxide meter"
            },
            {
                "product_id": 8009,
                "product_name": "2-light vaporproof light"
            }
        ],
        "query": "gas detector"
    },
    {
        "id": 99,
        "label": [
            {
                "product_id": 32218,
                "product_name": "dunhill fir green/white christmas tree with clear/white lights"
            },
            {
                "product_id": 35273,
                "product_name": "downswept douglas green fir christmas tree with clear/white lights"
            },
            {
                "product_id": 20708,
                "product_name": "mysterious white fir artificial christmas tree"
            },
            {
                "product_id": 17850,
                "product_name": "onfleek green fir artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 20801,
                "product_name": "wooden christmas tree ornament"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 24480,
                "product_name": "wood christmas tree"
            },
            {
                "product_id": 17469,
                "product_name": "wood christmas tree like holiday shaped ornament"
            },
            {
                "product_id": 14774,
                "product_name": "slim canadian power pole green fir artificial christmas tree with clear/white lights"
            },
            {
                "product_id": 28387,
                "product_name": "newberry spruce green artificial christmas tree with clear/white lights"
            },
            {
                "product_id": 28389,
                "product_name": "tinsel trees red pine artificial christmas tree with color & clear lights"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 3728,
                "product_name": "bless our home metal sign"
            },
            {
                "product_id": 21994,
                "product_name": "home decorative heart shaped metal wall sign inscribed `` home sweet home ''"
            },
            {
                "product_id": 40011,
                "product_name": "mitton pineapple statue"
            },
            {
                "product_id": 12350,
                "product_name": "rossmere indoor/outdoor bench cushion"
            },
            {
                "product_id": 419,
                "product_name": "eternity resin stacked stone tabletop fountain"
            }
        ],
        "garOutput": [
            {
                "product_id": 35233,
                "product_name": "tinsel trees white pine artificial christmas tree"
            },
            {
                "product_id": 35232,
                "product_name": "tinsel trees silver pine artificial christmas tree"
            },
            {
                "product_id": 42075,
                "product_name": "meiko 6 bottle tabletop wine bottle rack in gold"
            },
            {
                "product_id": 35274,
                "product_name": "downswept douglas green fir artificial christmas tree with clear/white lights"
            },
            {
                "product_id": 28388,
                "product_name": "green pine artificial christmas tree with clear/white lights"
            }
        ],
        "query": "arched christmas tree"
    },
    {
        "id": 378,
        "label": [
            {
                "product_id": 40750,
                "product_name": "audino 3-light shaded chandelier classic / traditional chandelier"
            },
            {
                "product_id": 32243,
                "product_name": "chandler 4 - light candle style drum chandelier"
            },
            {
                "product_id": 16939,
                "product_name": "ryckman 8 - light candle style classic/traditional chandelier with wrought iron accents"
            },
            {
                "product_id": 40466,
                "product_name": "maynard 3 - light 15 '' unique/statement drum semi flush mount"
            },
            {
                "product_id": 40601,
                "product_name": "cambridge 4-light shaded empire chandelier"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 28581,
                "product_name": "akron 8 - light candle style classic chandelier"
            },
            {
                "product_id": 18624,
                "product_name": "elkins 9 - light unique / statement tiered chandelier"
            },
            {
                "product_id": 22595,
                "product_name": "lotti 5 - light candle style drum chandelier"
            },
            {
                "product_id": 15891,
                "product_name": "kelly 4 - light lantern geometric chandelier"
            },
            {
                "product_id": 25716,
                "product_name": "castlebourne 5 - light sputnik modern linear chandelier"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20085,
                "product_name": "dario 12 - light candle style wagon wheel chandelier"
            },
            {
                "product_id": 6718,
                "product_name": "cornalin 6 - light candle style drum chandelier"
            },
            {
                "product_id": 8573,
                "product_name": "14 '' oil powered outdoor lantern"
            },
            {
                "product_id": 17957,
                "product_name": "resin decorative 4 level log fountain with led light"
            },
            {
                "product_id": 7485,
                "product_name": "incandescent mini vintage 25 light light bulb"
            }
        ],
        "garOutput": [
            {
                "product_id": 15328,
                "product_name": "bret orb cage 3-light unique / statement globe chandelier"
            },
            {
                "product_id": 24581,
                "product_name": "denise 5 - light candle style drum chandelier"
            },
            {
                "product_id": 17406,
                "product_name": "senoia 5 - light candle style classic / traditional chandelier"
            },
            {
                "product_id": 13751,
                "product_name": "madilynn 5 - light unique/statement tiered chandelier"
            },
            {
                "product_id": 39362,
                "product_name": "winkleman 5 blade ceiling fan , light kit included"
            }
        ],
        "query": "cliffside 5 light candle style drum chandelier"
    },
    {
        "id": 87,
        "label": [
            {
                "product_id": 1695,
                "product_name": "dawnelle 120 '' wide symmetrical modular sectional with ottoman"
            },
            {
                "product_id": 11289,
                "product_name": "pepperidge 119 '' wide left hand facing modular corner sectional"
            },
            {
                "product_id": 18539,
                "product_name": "peeples 131 '' wide reversible modular corner sectional with ottoman"
            },
            {
                "product_id": 25217,
                "product_name": "abhisek 78.4 '' wide reversible modular corner sectional with ottoman"
            },
            {
                "product_id": 21928,
                "product_name": "leger 115 '' wide reversible sleeper corner sectional with ottoman"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 40340,
                "product_name": "erable 82 '' wide reversible modular corner sectional"
            },
            {
                "product_id": 380,
                "product_name": "matthew 121 '' wide reversible modular corner sectional with ottoman"
            },
            {
                "product_id": 26817,
                "product_name": "robesonia 158 '' wide symmetrical modular sectional"
            },
            {
                "product_id": 28645,
                "product_name": "samual 123.5 '' wide reversible modular corner sectional with ottoman"
            },
            {
                "product_id": 14800,
                "product_name": "clarita 158 '' wide symmetrical modular corner sectional with ottoman"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 30826,
                "product_name": "belgr concrete propane/natural gas fire pit table"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 20085,
                "product_name": "dario 12 - light candle style wagon wheel chandelier"
            }
        ],
        "garOutput": [
            {
                "product_id": 19817,
                "product_name": "mcgray 83.07 '' wide left hand facing modular sofa & chaise"
            },
            {
                "product_id": 18700,
                "product_name": "colten 75 '' linen square arm loveseat"
            },
            {
                "product_id": 19818,
                "product_name": "ingulu 76 '' wide reversible modular sofa & chaise"
            },
            {
                "product_id": 18788,
                "product_name": "goldnilla 3 piece living room set"
            },
            {
                "product_id": 10835,
                "product_name": "shelton 83 '' wide velvet reversible sleeper sofa & chaise"
            }
        ],
        "query": "vlad modular sectional"
    },
    {
        "id": 465,
        "label": [
            {
                "product_id": 12092,
                "product_name": "2 piece black and white abstract fennel plants with seeds monochrome garden condiment ornament kitchen curtain set"
            },
            {
                "product_id": 12695,
                "product_name": "quickstand white portable floor recessed projector screen"
            }
        ],
        "heuristicsOutput": [],
        "ebrOutput": [
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 42199,
                "product_name": "figlia 19 '' animal flgurine novelty light"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            }
        ],
        "garOutput": [
            {
                "product_id": 19432,
                "product_name": "wild poppies near argenteuil by claude monet painting print on rolled wrapped canvas"
            },
            {
                "product_id": 33830,
                "product_name": "arrow 2 '' x 3 '' ceramic mosaic tile"
            },
            {
                "product_id": 41068,
                "product_name": "family is the anchor wall d\u00e9cor"
            },
            {
                "product_id": 19443,
                "product_name": "`` argenteuil '' by claude monet framed painting print"
            },
            {
                "product_id": 39708,
                "product_name": "silver wood wall decor"
            }
        ],
        "query": "white abstract"
    },
    {
        "id": 335,
        "label": [
            {
                "product_id": 39148,
                "product_name": "`` canadian rockies '' by albert bierstadt print of painting on wrapped canvas"
            },
            {
                "product_id": 28028,
                "product_name": "canadian pine artificial christmas teardrop door swag"
            },
            {
                "product_id": 14775,
                "product_name": "canadian balsam green fir artificial christmas tree with color changing lights"
            },
            {
                "product_id": 8140,
                "product_name": "weyand canadian license plate tissue box cover"
            },
            {
                "product_id": 21271,
                "product_name": "canadian patriotic basket of flowers linen garden flag"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 39144,
                "product_name": "canadian all season goose down comforter"
            },
            {
                "product_id": 25442,
                "product_name": "canadian geese wall d\u00e9cor"
            },
            {
                "product_id": 14074,
                "product_name": "canadian provinces - wrapped canvas graphic art print"
            },
            {
                "product_id": 11449,
                "product_name": "maxxus full spectrum carbon canadian cedar 2 person far infrared sauna"
            },
            {
                "product_id": 19541,
                "product_name": "canadian geese graphic art on canvas"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 30826,
                "product_name": "belgr concrete propane/natural gas fire pit table"
            },
            {
                "product_id": 10349,
                "product_name": "eira trestle coffee table"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 19215,
                "product_name": "vision small fabric shoe storage box"
            }
        ],
        "garOutput": [
            {
                "product_id": 13856,
                "product_name": "burro rider serenades la senorita painting print on wrapped canvas"
            },
            {
                "product_id": 36930,
                "product_name": "moose 5 hook coat rack"
            },
            {
                "product_id": 13868,
                "product_name": "hawaii - graphic art print"
            },
            {
                "product_id": 13872,
                "product_name": "estey organ company graphic art"
            },
            {
                "product_id": 32877,
                "product_name": "star wars - unleashed paper print"
            }
        ],
        "query": "canadian"
    },
    {
        "id": 218,
        "label": [
            {
                "product_id": 38472,
                "product_name": "arrowsic twin over full bunk bed"
            },
            {
                "product_id": 38482,
                "product_name": "sienna rose twin over full bunk bed"
            },
            {
                "product_id": 18811,
                "product_name": "devante full over full bed with trundle"
            },
            {
                "product_id": 18835,
                "product_name": "bundyhill twin over twin metal bunk bed with trundle"
            },
            {
                "product_id": 10801,
                "product_name": "almedacheatham full over full bunk bed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 38512,
                "product_name": "maurice full metal loft bed"
            },
            {
                "product_id": 18805,
                "product_name": "hazzard twin over full solid wood standard bunk bed with bookcase with trundle and with shelves"
            },
            {
                "product_id": 42559,
                "product_name": "adcock twin over twin bunk bed"
            },
            {
                "product_id": 27306,
                "product_name": "fairgrove twin bunk bed"
            },
            {
                "product_id": 18892,
                "product_name": "nevaeh twin over twin bunk bed"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 4644,
                "product_name": "karg 3 piece accent tray set"
            },
            {
                "product_id": 10078,
                "product_name": "finbar twin over twin bunk bed"
            },
            {
                "product_id": 25557,
                "product_name": "connolly tufted upholstered low profile standard bed"
            },
            {
                "product_id": 14495,
                "product_name": "twin over full bunk bed"
            },
            {
                "product_id": 10075,
                "product_name": "hajrah full over full bunk bed with trundle"
            }
        ],
        "garOutput": [
            {
                "product_id": 12169,
                "product_name": "holli zollinger zodiac leo outdoor square pillow cover & insert"
            },
            {
                "product_id": 5502,
                "product_name": "square bed risers"
            },
            {
                "product_id": 2178,
                "product_name": "kara folding 2 piece tray table set"
            },
            {
                "product_id": 2179,
                "product_name": "alison folding 4 piece tray table set"
            },
            {
                "product_id": 27883,
                "product_name": "ailbhe 35.43 '' w rack"
            }
        ],
        "query": "wand bunk beds"
    },
    {
        "id": 473,
        "label": [
            {
                "product_id": 7008,
                "product_name": "cottone 100 % cotton chair pads w/ties ( set of 4 ) | 16 \u201d x 15 \u201d square round | extra-comfortable & soft seat cushions | ergonomic pillows for rocking , dining , patio , camping , kitchen chairs & more , white"
            },
            {
                "product_id": 5837,
                "product_name": "tripp outdoor patio chair with sunbrella cushions"
            },
            {
                "product_id": 30764,
                "product_name": "barbay lounge chair cushion"
            },
            {
                "product_id": 40967,
                "product_name": "panek patio chair with cushions"
            },
            {
                "product_id": 39794,
                "product_name": "shifflett rocking chair with cushions"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 38988,
                "product_name": "sloping coccyx cushion"
            },
            {
                "product_id": 39811,
                "product_name": "santos patio chair with cushions and ottoman"
            },
            {
                "product_id": 4030,
                "product_name": "moura easterling chair pad cushion"
            },
            {
                "product_id": 12202,
                "product_name": "ambesonne pineapple decorative throw pillow case pack of 4 , geometric pineapple bursting into scattering birds flight modern abstract print , cushion cover for couch living room car , 18 '' , multicolor"
            },
            {
                "product_id": 34051,
                "product_name": "portola patio chair with cushion"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 284,
                "product_name": "adjustable 360 degree swivel velvet desk chair office chair with mid-back modern computer chair with arms for home office study room"
            },
            {
                "product_id": 19185,
                "product_name": "erzsebet 33 '' wide power lift assist standard recliner"
            },
            {
                "product_id": 26887,
                "product_name": "capems 65.35 '' faux leather pillow top arm sleeper"
            },
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            }
        ],
        "garOutput": [
            {
                "product_id": 38719,
                "product_name": "briarhill patio reclining chaise lounge with cushion"
            },
            {
                "product_id": 2061,
                "product_name": "winsor 4.5 '' plush innerspring mattress"
            },
            {
                "product_id": 13905,
                "product_name": "alsdorf task chair"
            },
            {
                "product_id": 810,
                "product_name": "scalia indoor/outdoor seat cushion"
            },
            {
                "product_id": 7811,
                "product_name": "cline bless this home anti-fatigue mat"
            }
        ],
        "query": "chair pillow cushion"
    },
    {
        "id": 121,
        "label": [
            {
                "product_id": 38882,
                "product_name": "aimon bed"
            },
            {
                "product_id": 42556,
                "product_name": "bewley junior twin low loft bed with drawers"
            },
            {
                "product_id": 25336,
                "product_name": "jenafir twin over twin bunk bed"
            },
            {
                "product_id": 1907,
                "product_name": "salina tufted upholstered low profile platform bed"
            },
            {
                "product_id": 5510,
                "product_name": "full canopy bed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 1502,
                "product_name": "julie solid wood sleigh bed"
            },
            {
                "product_id": 37669,
                "product_name": "lakeville upholstered platform bed"
            },
            {
                "product_id": 7099,
                "product_name": "johnnie bed led light"
            },
            {
                "product_id": 15879,
                "product_name": "reinhold metal standard bed"
            },
            {
                "product_id": 40494,
                "product_name": "cia 52 '' square arm sofa bed with reversible cushions"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 34104,
                "product_name": "samaira upholstered parsons chair"
            },
            {
                "product_id": 26887,
                "product_name": "capems 65.35 '' faux leather pillow top arm sleeper"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            }
        ],
        "garOutput": [
            {
                "product_id": 37566,
                "product_name": "courtemanche cedric duvet cover set"
            },
            {
                "product_id": 36087,
                "product_name": "electric power lift chair 160 degree recliner chair living room chair single sofa ergonomic with vibration lumbar heating 2 side pockets cup holders usb port"
            },
            {
                "product_id": 12986,
                "product_name": "fredericktown 96 '' wide reversible sofa & chaise with ottoman"
            },
            {
                "product_id": 17301,
                "product_name": "hughes 37.4 '' wide sofa bed with cushions"
            },
            {
                "product_id": 37826,
                "product_name": "kira 13 '' massaging zero gravity adjustable bed with wireless remote"
            }
        ],
        "query": "upholstered bed"
    },
    {
        "id": 487,
        "label": [
            {
                "product_id": 28100,
                "product_name": "ramas coat rack"
            },
            {
                "product_id": 11635,
                "product_name": "belote mid-century modern wall mounted coat rack"
            },
            {
                "product_id": 12273,
                "product_name": "econoco 48 '' w collapsible tubing clothes and garment rack"
            },
            {
                "product_id": 16267,
                "product_name": "gladiator\u00ae ez connect rack 72 '' h x 48 '' w x 18 '' d shelving unit"
            },
            {
                "product_id": 573,
                "product_name": "5 - hook freestanding coat rack"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 7559,
                "product_name": "shelftrack 60 '' h x 48 '' w x 12 '' d storage rack"
            },
            {
                "product_id": 29270,
                "product_name": "34 '' w telescopic garment rack"
            },
            {
                "product_id": 32523,
                "product_name": "36u deep s1 rack"
            },
            {
                "product_id": 35751,
                "product_name": "pineland wall mounted kayak rack"
            },
            {
                "product_id": 8997,
                "product_name": "kimberley freestanding sports rack"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 9624,
                "product_name": "military challenge coin cabinet rack holder display case"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 39991,
                "product_name": "bathroom rain volume shower system rough-in valve"
            },
            {
                "product_id": 3291,
                "product_name": "zootles drawer pull multipack"
            },
            {
                "product_id": 8847,
                "product_name": "alanis low profile standard bed"
            }
        ],
        "garOutput": [
            {
                "product_id": 33664,
                "product_name": "iaconetti storage cabinet"
            },
            {
                "product_id": 33665,
                "product_name": "delasandro metal storage cabinet"
            },
            {
                "product_id": 9973,
                "product_name": "fruit vegetable wire basket set"
            },
            {
                "product_id": 1614,
                "product_name": "bulwell 23.5 '' w x 31.5 '' h x 12 '' d free-standing bathroom cabinet"
            },
            {
                "product_id": 32471,
                "product_name": "epoxy wire shelving unit"
            }
        ],
        "query": "rack glass"
    },
    {
        "id": 20,
        "label": [
            {
                "product_id": 19396,
                "product_name": "gurdon resin pineapple three tier outdoor fountain"
            },
            {
                "product_id": 30341,
                "product_name": "keyondria 3 '' x 12 '' glass brick look subway tile"
            },
            {
                "product_id": 443,
                "product_name": "reposa natural stone cascading fountain with led lights"
            },
            {
                "product_id": 12788,
                "product_name": "citadel 3 '' x 6 '' ceramic brick look subway tile"
            },
            {
                "product_id": 40024,
                "product_name": "garvan cement square fountain with light"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 6160,
                "product_name": "laureano natural stone tabletop fountain with light"
            },
            {
                "product_id": 17963,
                "product_name": "resin rainfall in brick design medallion fountain with light"
            },
            {
                "product_id": 424,
                "product_name": "rhodri 3-tiered resin pedestal water fountain"
            },
            {
                "product_id": 30616,
                "product_name": "rubi resin zen 3 level fountain"
            },
            {
                "product_id": 8722,
                "product_name": "pugliese natural stone fountain with light"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 41246,
                "product_name": "rae dunn stem print 3.5 qt . flour canister"
            },
            {
                "product_id": 20718,
                "product_name": "abramowski monitor stand"
            },
            {
                "product_id": 11168,
                "product_name": "sanya anchor wall hanging"
            },
            {
                "product_id": 14379,
                "product_name": "shoe rack , 5-tier shoe storage unit flat & slant adjustable shoe organizer shelf for 16 pairs , durable and stable , for entryway , hallway , closet , dorm room , industrial , rustic brown"
            }
        ],
        "garOutput": [
            {
                "product_id": 24236,
                "product_name": "glenna barkwood square on twig stand pot planter"
            },
            {
                "product_id": 951,
                "product_name": "bersani resin statue planter"
            },
            {
                "product_id": 34388,
                "product_name": "arabescato mixed penny round mosaic tile"
            },
            {
                "product_id": 34387,
                "product_name": "arabescato penny round mosaic tile"
            },
            {
                "product_id": 42590,
                "product_name": "verwood 10 ft. w x 10 ft. d vinyl pergola"
            }
        ],
        "query": "foutains with brick look"
    },
    {
        "id": 190,
        "label": [
            {
                "product_id": 33337,
                "product_name": "high back big & tall bonded leather office chair large executive desk computer swivel chair - heavy duty metal base , adjustable tilt angle , ergonomic design for lumbar support"
            },
            {
                "product_id": 39004,
                "product_name": "caitriona leather upholstered dining chair"
            },
            {
                "product_id": 4003,
                "product_name": "demetress 35 '' wide power lift assist standard recliner"
            },
            {
                "product_id": 19522,
                "product_name": "astolfo 35.9 '' wide velvet power lift assist standard recliner"
            },
            {
                "product_id": 19186,
                "product_name": "dilean 31 '' wide velvet power lift assist standard recliner"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 36038,
                "product_name": "rimini 35.4 '' wide faux leather power lift assist recliner"
            },
            {
                "product_id": 24147,
                "product_name": "power recliner and lift chair in black pu leather lift recliner chair , heavy duty steel reclining mechanism for home office living room use"
            },
            {
                "product_id": 30110,
                "product_name": "kinsey 23.5 '' w leather lounge chair with metal frame"
            },
            {
                "product_id": 35112,
                "product_name": "allyannah 30.3 '' wide tufted top grain leather lounge chair"
            },
            {
                "product_id": 29744,
                "product_name": "genuine leather reclining adjustable width massage chair"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 16256,
                "product_name": "vidal ultra soft & plush dinosaurs fleece throw"
            },
            {
                "product_id": 32281,
                "product_name": "metal slat back stacking side chair"
            },
            {
                "product_id": 29440,
                "product_name": "sareah adjustable height stool"
            },
            {
                "product_id": 42324,
                "product_name": "loftis 27.6 '' wide velvet wingback chair"
            },
            {
                "product_id": 27333,
                "product_name": "berengere 4 '' cotton futon mattress"
            }
        ],
        "garOutput": [
            {
                "product_id": 41664,
                "product_name": "executive chair"
            },
            {
                "product_id": 2846,
                "product_name": "hadsell 35.8 '' wide faux leather power lift assist standard recliner"
            },
            {
                "product_id": 29994,
                "product_name": "aiken 34 '' wide faux leather power wall hugger standard recliner"
            },
            {
                "product_id": 15595,
                "product_name": "chenley 23.6 '' wide polyester side chair"
            },
            {
                "product_id": 11647,
                "product_name": "bazemore mesh task chair"
            }
        ],
        "query": "benjiamino faux leather power lift chair"
    },
    {
        "id": 71,
        "label": [
            {
                "product_id": 13613,
                "product_name": "amey little woodland friends 8 piece crib bedding set"
            },
            {
                "product_id": 25447,
                "product_name": "folkston 6 piece crib bedding set"
            },
            {
                "product_id": 6157,
                "product_name": "2 piece crib bedding set"
            },
            {
                "product_id": 21021,
                "product_name": "bohemian 5 piece crib bedding set"
            },
            {
                "product_id": 28750,
                "product_name": "cadnite pink medallion 13 piece crib bedding set"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 30143,
                "product_name": "lewis my cute little penguin antarctic baby nursery 5 piece crib bedding set"
            },
            {
                "product_id": 34808,
                "product_name": "bluhm sweet unicorn 4 piece crib bedding set"
            },
            {
                "product_id": 3363,
                "product_name": "caldwell elephant dreams baby 3 piece crib bedding set"
            },
            {
                "product_id": 20984,
                "product_name": "sunflower photo op fitted crib sheet"
            },
            {
                "product_id": 17413,
                "product_name": "bainter 10 piece crib bedding set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 37189,
                "product_name": "metal bed with wood decoration \uff08twin size\uff09"
            },
            {
                "product_id": 8751,
                "product_name": "large kids play house heavy duty canvas cotton with floor mat for bedroom indoor outdoor"
            },
            {
                "product_id": 10075,
                "product_name": "hajrah full over full bunk bed with trundle"
            },
            {
                "product_id": 14495,
                "product_name": "twin over full bunk bed"
            },
            {
                "product_id": 16348,
                "product_name": "lethe 2 piece crib bedding set"
            }
        ],
        "garOutput": [
            {
                "product_id": 30146,
                "product_name": "rawlings outer space galaxy baby nursery 6 piece crib bedding set"
            },
            {
                "product_id": 30145,
                "product_name": "lincoln giraffe animals baby nursery 6 piece crib bedding set"
            },
            {
                "product_id": 30141,
                "product_name": "rasmussen moon and star teddy bear baby nursery 5 piece crib bedding set"
            },
            {
                "product_id": 30142,
                "product_name": "ratcliffe baby nursery 5 piece crib bedding set"
            },
            {
                "product_id": 30143,
                "product_name": "lewis my cute little penguin antarctic baby nursery 5 piece crib bedding set"
            }
        ],
        "query": "infant girl crib bedding"
    },
    {
        "id": 106,
        "label": [
            {
                "product_id": 15949,
                "product_name": "bed raiser"
            },
            {
                "product_id": 11576,
                "product_name": "bengal pom pom trim 15 '' bed skirt"
            },
            {
                "product_id": 34559,
                "product_name": "bed riser"
            },
            {
                "product_id": 20342,
                "product_name": "mathews bed riser"
            },
            {
                "product_id": 33369,
                "product_name": "draeger upholstered wingback bed"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 7541,
                "product_name": "cal-mil reclaimed wood display riser & stand"
            },
            {
                "product_id": 7534,
                "product_name": "cal-mil metal display riser & stand"
            },
            {
                "product_id": 27605,
                "product_name": "calderon monitor stand riser"
            },
            {
                "product_id": 20337,
                "product_name": "marshall bed riser"
            },
            {
                "product_id": 18845,
                "product_name": "full over full bunk bed with twin size trundle , pine wood bunk bed with guardrails for kids and teens"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 33295,
                "product_name": "soper pu velvet queen upholstered platform bed"
            },
            {
                "product_id": 10078,
                "product_name": "finbar twin over twin bunk bed"
            },
            {
                "product_id": 37192,
                "product_name": "wood platform bed with two drawers , full ( white )"
            },
            {
                "product_id": 2857,
                "product_name": "saldana lift top block coffee table with storage"
            },
            {
                "product_id": 3197,
                "product_name": "double shower curtain hooks"
            }
        ],
        "garOutput": [
            {
                "product_id": 5502,
                "product_name": "square bed risers"
            },
            {
                "product_id": 31823,
                "product_name": "peachey bed riser"
            },
            {
                "product_id": 20231,
                "product_name": "30 '' w x 20 '' l farmhouse kitchen sink with sink grid and basket strainer"
            },
            {
                "product_id": 20230,
                "product_name": "33 '' l x 21 '' w farmhouse kitchen sink with sink grid and basket strainer"
            },
            {
                "product_id": 20229,
                "product_name": "30 '' l x 21 '' w farmhouse kitchen sink with sink grid and basket strainer"
            }
        ],
        "query": "bed risers"
    },
    {
        "id": 274,
        "label": [
            {
                "product_id": 288,
                "product_name": "altessa desk"
            },
            {
                "product_id": 18283,
                "product_name": "bjarni desk"
            },
            {
                "product_id": 30206,
                "product_name": "kisner height adjustable standing desk converter"
            },
            {
                "product_id": 2861,
                "product_name": "series c height adjustable l-shape standing desk"
            },
            {
                "product_id": 14341,
                "product_name": "manufactured wood adjustable height open front desk"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 22148,
                "product_name": "erynn desk"
            },
            {
                "product_id": 35473,
                "product_name": "babin height adjustable standing desk"
            },
            {
                "product_id": 19683,
                "product_name": "blondell height adjustable standing desk converter"
            },
            {
                "product_id": 36117,
                "product_name": "marren desk"
            },
            {
                "product_id": 34666,
                "product_name": "lynde height adjustable standing desk"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 27793,
                "product_name": "adjustable height standing desk"
            },
            {
                "product_id": 38552,
                "product_name": "houpt solid wood coffee table with storage"
            },
            {
                "product_id": 20747,
                "product_name": "home office computer desk with hutch , 47 inch rustic office desk and modern writing desk with storage shelves , vintage and black legs"
            },
            {
                "product_id": 16981,
                "product_name": "hafsa velvet task chair"
            },
            {
                "product_id": 30158,
                "product_name": "hershberger buffet table"
            }
        ],
        "garOutput": [
            {
                "product_id": 21883,
                "product_name": "abbigaile l-shape desk"
            },
            {
                "product_id": 13905,
                "product_name": "alsdorf task chair"
            },
            {
                "product_id": 30206,
                "product_name": "kisner height adjustable standing desk converter"
            },
            {
                "product_id": 42002,
                "product_name": "21 pair stackable shoe rack"
            },
            {
                "product_id": 3298,
                "product_name": "5 shelf standard bookcase"
            }
        ],
        "query": "gowdy height adjustable standing desk"
    },
    {
        "id": 354,
        "label": [
            {
                "product_id": 22932,
                "product_name": "amani gray area rug"
            },
            {
                "product_id": 20111,
                "product_name": "truman bohemian medallion textured weave indoor/outdoor teal area rug"
            },
            {
                "product_id": 2136,
                "product_name": "round floral shag pink/blue area rug"
            },
            {
                "product_id": 5207,
                "product_name": "pacifica geometric tufted wool magenta area rug"
            },
            {
                "product_id": 21498,
                "product_name": "shalanda 2 memory foam bath rug"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 37295,
                "product_name": "shayne animal print cowhide off white rug"
            },
            {
                "product_id": 11920,
                "product_name": "ombre hand drawn sea waves blue/green area rug"
            },
            {
                "product_id": 11497,
                "product_name": "lyly animal print tufted wool beige/black area rug"
            },
            {
                "product_id": 271,
                "product_name": "shah oriental hand knotted wool plum/ivory area rug"
            },
            {
                "product_id": 1769,
                "product_name": "cedric 3 piece oriental ebony area rug set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 10300,
                "product_name": "ergonomic computer riser monitor stand with storage"
            },
            {
                "product_id": 28395,
                "product_name": "aspen spruce green artificial christmas tree with multi-color lights"
            },
            {
                "product_id": 8890,
                "product_name": "vervena closet storage"
            },
            {
                "product_id": 25828,
                "product_name": "home definition 29 in . x 18 in . non-slip outdoor door mat"
            },
            {
                "product_id": 5954,
                "product_name": "whisnant 6 - light unique / statement geometric chandelier"
            }
        ],
        "garOutput": [
            {
                "product_id": 4664,
                "product_name": "jana power loom red rug"
            },
            {
                "product_id": 7229,
                "product_name": "wright floral green/yellow/gray/teal blue indoor/outdoor area rug"
            },
            {
                "product_id": 4657,
                "product_name": "mccoole hand-hooked wool beige/black area rug"
            },
            {
                "product_id": 6700,
                "product_name": "samara 2 piece wall shelf set"
            },
            {
                "product_id": 6699,
                "product_name": "samara wall shelf"
            }
        ],
        "query": "tressler rug"
    },
    {
        "id": 441,
        "label": [
            {
                "product_id": 33654,
                "product_name": "hulmeville desk with hutch"
            },
            {
                "product_id": 73,
                "product_name": "hann height adjustable l-shape gaming desk"
            },
            {
                "product_id": 24265,
                "product_name": "mcleod 72 '' w knob drawer writing desk"
            },
            {
                "product_id": 8857,
                "product_name": "albach desk and chair set"
            },
            {
                "product_id": 18940,
                "product_name": "angla glass l-shape desk"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 35491,
                "product_name": "cerqueira writing desk"
            },
            {
                "product_id": 26517,
                "product_name": "enhaut writing desk"
            },
            {
                "product_id": 5923,
                "product_name": "wolak desk"
            },
            {
                "product_id": 19873,
                "product_name": "reversible l-shaped executive desk"
            },
            {
                "product_id": 33538,
                "product_name": "fortinbras desk and chair set"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 19211,
                "product_name": "isabelle & max\u2122 kids desk and chair set , height adjustable student study desk and chair set with large storage drawer , 180\u00b0bookshelf , 60\u00b0tilted desktop , metal hook , double-layer backrest"
            },
            {
                "product_id": 19874,
                "product_name": "emeria reversible l-shaped executive desk"
            },
            {
                "product_id": 18943,
                "product_name": "sink leg"
            },
            {
                "product_id": 32495,
                "product_name": "dederang 2 '' bookshelf"
            },
            {
                "product_id": 41677,
                "product_name": "dyshawn reversible l-shape executive desk"
            }
        ],
        "garOutput": [
            {
                "product_id": 4759,
                "product_name": "kitchen pantry cabinet with 6 adjustable shelves"
            },
            {
                "product_id": 8832,
                "product_name": "47 '' writing desk"
            },
            {
                "product_id": 8653,
                "product_name": "leming 23.62 '' w x 7.49 '' h x 9.45 '' d wall mounted bathroom shelves"
            },
            {
                "product_id": 29998,
                "product_name": "family script laser cut wall d\u00e9cor"
            },
            {
                "product_id": 34534,
                "product_name": "computer desk , walnut"
            }
        ],
        "query": "desk for kids"
    },
    {
        "id": 102,
        "label": [
            {
                "product_id": 10988,
                "product_name": "shatley funky flamingo i art"
            },
            {
                "product_id": 3267,
                "product_name": "flamingos green by cat coquillette - print"
            },
            {
                "product_id": 13575,
                "product_name": "flamingos graphic art"
            },
            {
                "product_id": 34307,
                "product_name": "flamingos cardboard standup"
            },
            {
                "product_id": 13576,
                "product_name": "coral flamingo graphic art"
            }
        ],
        "heuristicsOutput": [
            {
                "product_id": 2777,
                "product_name": "flamingo- unframed print on canvas"
            },
            {
                "product_id": 31126,
                "product_name": "raining color flamingo graphic art"
            },
            {
                "product_id": 12154,
                "product_name": "festive flamingos by turnowsky - graphic art print"
            },
            {
                "product_id": 12151,
                "product_name": "flamingo best by slava fokk - graphic art print"
            },
            {
                "product_id": 37491,
                "product_name": "flamingo pattern framed graphic art"
            }
        ],
        "ebrOutput": [
            {
                "product_id": 11511,
                "product_name": "home sweet home sign"
            },
            {
                "product_id": 20720,
                "product_name": "bianchini large monitor stand"
            },
            {
                "product_id": 6053,
                "product_name": "hot water dispenser"
            },
            {
                "product_id": 11514,
                "product_name": "2 piece fabio couple rooster set"
            },
            {
                "product_id": 11513,
                "product_name": "2 piece fabia couple rooster set"
            }
        ],
        "garOutput": [
            {
                "product_id": 42311,
                "product_name": "cadott peacocks 2 piece garden statue set"
            },
            {
                "product_id": 14421,
                "product_name": "live zamioculcas plant in pot"
            },
            {
                "product_id": 25102,
                "product_name": "alasdhair resin pot planter"
            },
            {
                "product_id": 14420,
                "product_name": "live zamioculcas plant in pot"
            },
            {
                "product_id": 384,
                "product_name": "bird playground parakeet play stand cockatiel play gym conure perch parrot playstand with nesting box feeder cups and ladder"
            }
        ],
        "query": "flamingo"
    }
]