import { height } from '@mui/system';
import React, { useState } from 'react';
import '../../css/blog.css';
// import img_1 from "../assets/img_1.png";
// import img_2 from "../assets/img_2.png";
// import img_3 from "../assets/img_3.png";
// import img_4 from "../assets/img_4.png";
// import img_5 from "../assets/img_5.png";
// import img_6 from "../assets/img_6.png";

const BlogOpenSourceToolkit = () => {
  const post = 
    {
      title: 'Introducing TigerLab AI: Open-Source LLM Toolkit',
      date: 'Mar 16, 2020',
    }


  return (
    <div className="bg-white py-24 sm:py-32 mb-32" style={{paddingBottom: "6em"}}>
      <div className="mx-auto px-6 lg:px-20" style={{maxWidth: "56em"}}>
        <div className="mx-auto max-w-2xl lg:mx-0 flex flex-col justify-center">
          <h1 className=" font-bold text-slate-700 mt-12 not-italic text-center" style={{fontSize: "4em"}}>{post.title}</h1>
          <p class="text-xl font-semibold text-gray-400 mt-12 text-center">
            {post.date}
          </p>
        </div>
        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16  pt-10 sm:mt-16 sm:pt-16 lg:max-w-none" style={{lineHeight:"2.5rem", fontWeight:300}}>
          <p class="text-xl" style={{lineHeight:"2.5rem", fontWeight:300}}>
           Hi there! We’re Lilin and Wendy. After tens of nights and weekends, We're proudly here to open source our github project, TigerLab! (Check it out here: <a href='https://github.com/tigerlab-ai/tiger' class="blog-link">https://github.com/tigerlab-ai/tiger</a>) 
          </p>
          <p class="text-xl mt-8" style={{lineHeight:"2.5rem", fontWeight:300}}>
          🌐 TigerLab: A Framework for Trustworthy LLM Development 
          </p>

          <p class="text-xl mt-8" style={{lineHeight:"2.5rem", fontWeight:300}}>
            Here's what you can unlock with TigerLab's toolkits:
          </p>
          <p class="text-xl" style={{lineHeight:"2.5rem", fontWeight:300}}>
          🔍 TigerRAG: Information Retrieval
          </p>
          <p class="text-xl" style={{lineHeight:"2.5rem", fontWeight:300}}>
          🧠 TigerTune: Model Fine-Tuning
          </p>
          <p class="text-xl" style={{lineHeight:"2.5rem", fontWeight:300}}>
            🔄 TigerDA: Data Augmentation
          </p>
          <p class="text-xl" style={{lineHeight:"2.5rem", fontWeight:300}}>
            🛡 TigerArmor: AI Safety
          </p>


          <img style={{borderRadius: "25px"}} src="../../../assets/github.png" href="https://github.com/tigerlab-ai/tiger"></img>
    
            
          <p class="text-xl mt-8" style={{lineHeight:"2.5rem", fontWeight:300}}>
          I was exploring the capacilities of LLM with my friend Wendy Ran Wei recently. Our exploration revealed a gap—a lack of a systematic approach for achieving high-quality, secure end-to-end LLM implementation. Driven by this insight, we poured our hearts into this project. Today, we proudly open sourced it to you. 
          </p>

          <p class="text-xl mt-8" style={{lineHeight:"2.5rem", fontWeight:300}}>
            To all the dedicated LLM researchers and developers out there, we invite you to explore, contribute, and innovate with TigerLab. Want a closer look? Schedule a call with us (<a href='https://www.tigerlab.ai' class="blog-link">www.tigerlab.ai</a>)!
          </p>

          <p class="text-xl mt-8" style={{lineHeight:"2.5rem", fontWeight:300}}>
            Your insights and feedback are the keystones of our progress. Together, let's make AI more accessible and secure!
          </p>
          
          
        </div>
      </div>
    </div>
  );
};

export default BlogOpenSourceToolkit;
