import { Box, Button, TextField } from "@mui/material";
// import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";

const Settings = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

//   const handleFormSubmit = (values) => {
//     console.log(values);
//   };

  return (
    <Box m="20px">
      <Header title="API keys" subtitle="SDK / API credentials for logging data to Evaly" />

        {/* <h1>API Key</h1> */}
        <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
            <TextField
                fullWidth
                variant="filled"
                type="password"
                label="API Key"
                id="filled-password-input"
                // onBlur={handleBlur}
                // onChange={handleChange}
                value={"kkkkk-kkkkk-kkkkk-kkkkk"}
                name="apiKey"
                InputProps={{
                    readOnly: true,
                  }}
                // error={!!touched.firstName && !!errors.firstName}
                // helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 2" }}
            />
            <Button type="submit" color="secondary" variant="contained" sx={{
              color: "white",
              fontWeight: 600,}}>
            Copy
            </Button>
        </Box>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  contact: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  address1: yup.string().required("required"),
  address2: yup.string().required("required"),
});
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  contact: "",
  address1: "",
  address2: "",
};

export default Settings;
